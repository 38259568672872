import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { Pagination } from 'src/app/domain/views/pagination.view';
import { GuideServiceDetails } from 'src/app/business/models/billing/service-details.model';

@Component({
  selector: 'app-commissions-cards',
  templateUrl: './cards.component.html'
})
export class CommissionsCardsComponent implements OnInit {

  @Input() commissions: Array<GuideServiceDetails>;

  @Input() isAdmin: boolean;
  @Input() isProvider: boolean;
  @Input() isGuide: boolean;

  @Input() pagination: Pagination


  @Output() approveService = new EventEmitter<number>();
  @Output() editService = new EventEmitter<number>();
  @Output() deleteService = new EventEmitter<number>();

  constructor() { }


  ngOnInit() { }


  onApproveService(commissionId: number) {
      this.approveService.emit(commissionId);
  }

  onEditService(commissionId: number) {
    this.editService.emit(commissionId);
  }


  onDeleteService(commissionId: number) {
    this.deleteService.emit(commissionId);
  }


}
