import { BookingGuideConfirmationState } from 'src/app/business/models/book/common/booking-guide-confirmation-state.constants';

export class BookConfirmEditionView {

    private _bookId: number;
    private _bookingGuideConfirmationState: BookingGuideConfirmationState;

    constructor(
      bookId: number,
      bookingGuideConfirmationState: BookingGuideConfirmationState
    ) {

        this._bookId = bookId;
        this._bookingGuideConfirmationState = bookingGuideConfirmationState;

    }

    get bookId(): number {
         return this._bookId;
    }

    get bookingGuideConfirmationState(): BookingGuideConfirmationState {
         return this._bookingGuideConfirmationState;
    }

}