<div class="animated fadeIn">
    <div *ngIf="guide != null" class="row header-dashboard align-items-lg-center align-items-start">
        <div class="col-12">
            <div class="row mb-3 mb-lg-auto">
                <div class="col-12 col-sm-6 col-lg-9 pl-0">
                    <h2 class="font-weight-bold"> {{ guide.companyName }}</h2>
                    <div [ngClass]="'status' | provideGuideStateClass: guide.state">
                        <div class="dot"></div> {{ '' | providerGuideStateName: guide.state | translate }}
                    </div>
                </div>
                <!--
                <div *ngIf="isStatePending" class="col-12 col-sm-6 col-lg-3 text-left text-sm-right my-auto p-0 p-auto">
                    <a (click)="doConfirmAffiliate()" class="link-terciary text-uppercase text-underline cursor-pointer mb-sm-0">{{ 'affiliatedetails.Confirmar cuenta' | translate }}</a>
                </div>
                -->
            </div>

        </div>
    </div>

    <div class="row dashboard-content-section">
        <div class="col-12 px-0">
            <app-profile-guide-details [guide]="guide" (guideDetails)="onGuideDetailsSubmit($event)"></app-profile-guide-details>
        </div>
    </div>

    <div class="row dashboard-content-section">
        <div class="col-12 px-0">
            <app-guide-details-tours-management [guide]="guide"></app-guide-details-tours-management>
        </div>
    </div>

    <div class="row dashboard-content-section">
        <div class="col-12 px-0">
            <app-guide-details-tours-reseller [guide]="guide"></app-guide-details-tours-reseller>
        </div>
    </div>

    <div *ngIf="!isStatePending" class="row mt-3 mb-5 pb-3">
        <div class="col-12 text-center">
            <a (click)="doUnregisterGuide()" class="link-terciary text-uppercase text-underline cursor-pointer mb-sm-0">{{ 'guidedetails.Dar de baja el guía' | translate }}</a>
        </div>
    </div>

    <div *ngIf="isStatePending" class="row mt-3 mb-5 pb-3">
        <div class="col-12 text-center">
            <a (click)="doRegisterGuide()" class="link-terciary text-uppercase text-underline cursor-pointer mb-sm-0">{{ 'guidedetails.Dar de alta el guía' | translate }}</a>
        </div>
    </div>

</div>


<app-message-panel [preloading]="true"></app-message-panel>