import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-admin-profile',
  templateUrl: './profile.component.html'
})
export class AdminProfileComponent {

  constructor() { }


}
