export class SchedulePlanningDay {


    private _day: any;
    private _hours: Array<string>;

    constructor(
      day: any,
      hours: Array<string>
    ) {

        this._day = day;
        this._hours = hours;

    }


    get day(): any {
        return this._day;
    }

    get hours(): Array<string> {
        return this._hours;
    }

}