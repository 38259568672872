<div class="col-12" *ngIf="nextBilling">
  <div class="row">
    <div class="col-12 text-left pl-md-0 pr-md-3 px-0">
      <h2 class="font-weight-bold mb-4">{{ 'invoiceslist.Próxima facturación' | translate }}</h2>
    </div>
    <table class="table table-hover table-dark mb-3">
      <thead class="thead-dark">
        <tr>
          <th scope="col">{{ 'invoiceslist.Tour' | translate }}</th>
          <th scope="col" class="text-center">{{ 'invoiceslist.Idioma' | translate }}</th>
          <th scope="col" class="price-width text-center">{{ 'invoiceslist.Importe' | translate }}</th>
        </tr>
      </thead>
      <tbody class="">

        <tr *ngFor="let tourBilling of nextBilling.tourBillings">
          <td scope="row" class="font-weight-bold">{{ tourBilling.tourResume.name | I18NTranslationSave: tourBilling.tourResume.nameMain }}</td>
          <td class="text-center">{{ tourBilling.tourResume.languageTourGiven.name }}</td>
          <td class="price-width text-center">{{ tourBilling.billingDetails.commission }} €</td>
        </tr>
      </tbody>
    </table>
    <table class="table table-hover table-dark">
      <tbody class="total">
        <tr>
          <td scope="row" colspan="2" class="font-weight-bold text-right pr-5 border-0">{{ 'invoiceslist.Total' | translate }}</td>
          <td class="price-width text-center border-0">{{ nextBilling.total }} €</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>

<div class="col-12 mt-3">
  <div class="row">
    <div class="col-12 text-left pl-md-0 pr-md-3 px-0">
      <h2 class="font-weight-bold mb-4">{{ 'invoiceslist.Historial de facturación' | translate }}</h2>
    </div>
  </div>

  <div class="row empty-section" *ngIf="noResults">
    <div class="col-12 text-center my-auto">
      <img class="logo mb-4" src="assets/img/logo_opacity-21.png">
      <p>{{ 'invoiceslist.No se han encontrado resultados' | translate }}</p>
    </div>
  </div>
</div>
<table class="table table-hover table-dark mb-3" *ngIf="!noResults">
  <thead class="thead-dark">
    <tr>
      <th scope="col">{{ 'invoiceslist.Fecha' | translate }}</th>
      <th scope="col" class="text-center">{{ 'invoiceslist.Número de factura' | translate }}</th>
      <th scope="col" class="text-center">{{ 'invoiceslist.Período de facturación' | translate }}</th>
      <th scope="col" class="price-width text-center">{{ 'invoiceslist.Importe' | translate }}</th>
      <th scope="col" class="text-center">{{ 'invoiceslist.Estado' | translate }}</th>
      <th scope="col" class="text-center">{{ 'invoiceslist.Tipo' | translate }}</th>
      <th scope="col"></th>
    </tr>
  </thead>
  <tbody class="">

    <tr *ngFor="let invoice of invoices | paginate: pagination;">
      <td scope="row" class="font-weight-bold">{{ invoice.invoiceDate }}</td>
      <td class="text-center">{{ invoice.serialNumber }}</td>
      <td class="text-center">{{ invoice.invoiceMonthPeriod }}/{{ invoice.invoiceYearPeriod }}</td>
      <td class="price-width font-weight-bold text-center">{{ invoice.billingDetails.commission }} €</td>
      <td class="text-center">
        <div [ngClass]="'status' | invoiceStateClassPipe: invoice.settledUp">
          <div class="dot"></div> {{ invoice.settledUp | invoiceStatePayment | translate }}
        </div>
      </td>
      <td class="text-center">

        <a *ngIf="invoice.paymentPending && !canReceivePayments" (click)="onGoToProfile()" placement="bottom" ngbTooltip="{{ 'invoiceslist.Cuenta de Stripe no conectada' | translate }}" tooltipClass="tooltip-info">
          <img class="ic-small" src="assets/img/ic_info_outlined_red.svg">
        </a>

        {{ invoice.chargePending | invoicePaymentTypeProviderPipe: invoice.chargeCompleted : invoice.paymentPending : invoice.paymentCompleted | translate }}
        {{ invoice.billingDetails.payment | invoicePaymentTypeAmountPipe }}
      </td>
      <td class="px-0 text-center">
        <label class="btn-more-vert dropdown">
          <div class="dd-button">
            <img class="ic-mid" src="assets/img/ic_more-vert.svg">
          </div>

          <input type="checkbox" class="dd-input" id="test">

          <ul class="dd-menu text-right">
            <li>
              <a (click)="onInvoiceDetails(invoice.id)">
                {{ 'invoiceslist.Ver detalles' | translate }}
              </a>
            </li>
            <li class="divider"></li>

            <li>
              <a (click)="onInvoiceDownload(invoice)">
                {{ 'invoiceslist.Descargar factura' | translate }}
              </a>
            </li>
          </ul>

        </label>
      </td>
    </tr>
  </tbody>
</table>