import { TourDetailsFormView } from './details-form.view';
import { TourUsersFormView } from './users-form.view';
import { TourLocationFormView } from './location-form.view';
import { TourScheduleFormView } from './schedule-form.view';

export class TourFormView {

  private _id: number;
  private _languageIdI18N: number;
  private _providerId: number;
  private _tourDetails: TourDetailsFormView;
  private _tourUsers: TourUsersFormView;
  private _tourLocation: TourLocationFormView;
  private _tourSchedule: TourScheduleFormView;


  constructor() { }

  get id(): number {
    return this._id;
  }

  set id(id: number) {
    this._id = id;
  }

  get languageIdI18N(): number {
    return this._languageIdI18N;
  }

  set languageIdI18N(languageIdI18N: number) {
    this._languageIdI18N = languageIdI18N;
  }

  get providerId(): number {
   return this._providerId;
  }

  set providerId(providerId: number) {
   this._providerId = providerId;
  }

  get tourDetails(): TourDetailsFormView {
    return this._tourDetails;
  }

  set tourDetails(tourDetails: TourDetailsFormView) {
    this._tourDetails = tourDetails;
  }

  get tourUsers(): TourUsersFormView {
    return this._tourUsers;
  }

  set tourUsers(tourDetails: TourUsersFormView) {
    this._tourUsers = tourDetails;
  }

  get tourLocation(): TourLocationFormView {
    return this._tourLocation;
  }

  set tourLocation(tourLocation: TourLocationFormView) {
    this._tourLocation = tourLocation;
  }

  get tourSchedule(): TourScheduleFormView {
    return this._tourSchedule;
  }

  set tourSchedule(tourSchedule: TourScheduleFormView) {
    this._tourSchedule = tourSchedule;
  }


}