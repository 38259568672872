import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AdminModule } from './ui/components/pages/admin/admin.module';
import { LoginComponent } from './ui/components/pages/access/login/login.component';
import { RememberPasswordComponent } from './ui/components/pages/access/remember-password/remember-password.component';

import { AppInterceptor } from './business/api/api.interceptor';
import { SharedModule } from './shared.module';
import { ProviderModule } from './ui/components/pages/provider/provider.module';
import { SingupComponent } from './ui/components/pages/access/singup/singup.component';
import { RememberPasswordConfirmationComponent } from './ui/components/pages/access/remember-password/remember-password-confirmation.component';
import { HomeComponent } from './ui/components/pages/public/home/home.component';
import { PlansComponent } from './ui/components/pages/public/plans/plans.component';
import { HeaderLandingComponent } from './ui/components/pages/public/shared/header/header.component';
import { FooterLandingComponent } from './ui/components/pages/public/shared/footer/footer.component';
import { TermsComponent } from './ui/components/pages/public/privacypolicy/terms.component';
import { ForbiddenComponent } from './ui/components/pages/public/error/forbidden/forbidden.component';
import { NotFoundComponent } from './ui/components/pages/public/error/not-found/not-found.component';
import { BookingCancelationComponent } from './ui/components/pages/public/booking/cancel/booking-cancelation.component';
import { AccessSidePanelComponent } from './ui/components/pages/access/shared/side-panel/side-panel.component';
import { PublicNavigationComponent } from './ui/components/pages/access/shared/public-navigation/public-navigation.component';
import { ReviewComponent } from './ui/components/pages/public/booking/review/review.component';
import { ReviewThanksComponent } from './ui/components/pages/public/booking/review/thanks.component';
import { SingupAffiliateComponent } from './ui/components/pages/access/singup-affiliate/singup.component';
import { AffiliateModule } from './ui/components/pages/affiliate/affiliate.module';
import { BookingPaymentComponent } from './ui/components/pages/public/booking/payment/booking-payment.component';
import { GuideModule } from './ui/components/pages/guide/guide.module';


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    NotFoundComponent,
    ForbiddenComponent,
    SingupComponent,
    SingupAffiliateComponent,
    RememberPasswordComponent,
    RememberPasswordConfirmationComponent,
    HomeComponent,
    PlansComponent,
    TermsComponent,
    BookingCancelationComponent,
    BookingPaymentComponent,
    HeaderLandingComponent,
    FooterLandingComponent,
    AccessSidePanelComponent,
    PublicNavigationComponent,
    ReviewComponent,
    ReviewThanksComponent
  ],
  imports: [
    BrowserModule,
    SharedModule,
    AdminModule,
    ProviderModule,
    AffiliateModule,
    GuideModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AppInterceptor, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
