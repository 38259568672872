import { TourBilling } from './tour-billing.model';

export class NextBilling {

  private _tourBillings: Array<TourBilling>;
  private _total: number;


  constructor(
    tourBillings: Array<TourBilling>,
    total: number
  ) {
    this._tourBillings = tourBillings;
    this._total = total;
  }

  get tourBillings(): Array<TourBilling> {
    return this._tourBillings;
  }

  get total(): number {
    return this._total;
  }

}
