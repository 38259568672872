import { Component, Input, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Review } from 'src/app/business/models/review/review.model';
import { AppConstants } from 'src/app/domain/common/app.constants';
import { AdminBookInteractor } from 'src/app/domain/interactor/admin/book.interactor';
import { MessagePanelComponent } from 'src/app/ui/components/shared/message-panel/message-panel.component';
import { marker as I18N }               from '@biesbjerg/ngx-translate-extract-marker';
import { ServerErrorManager } from 'src/app/ui/components/utils/server-error-manager.utils';
import { ReviewUpdatedView } from 'src/app/domain/views/book/review-updated.view';
import { GenericException } from 'src/app/domain/exceptions/generic.exception';


@Component({
  selector: 'app-admin-edit-review-modal-component',
  templateUrl: './edit-review-modal.component.html'
})

export class AdminEditReviewModalComponent {

  @ViewChild(MessagePanelComponent, { static: false })
  private messagePanelComponent: MessagePanelComponent;

  reviewForm: FormGroup;
  refreshValidators: boolean

  currentRating: number;

  @Input()
  set review(review: Review) {
    if (review) {
      this.setReviewFormValues(review);
    }
  }

  constructor(
      private adminBookInteractor: AdminBookInteractor,
      private serverErrorManager: ServerErrorManager,
      private modal: NgbActiveModal) {

    this.refreshValidators = false;

    this.reviewForm = new FormGroup({
        'orderBookingId': new FormControl(''),
        'rating': new FormControl('', [ Validators.required, Validators.min(1), Validators.max(5) ]),
        'comments': new FormControl('', [ ])
    })


  }

  closeModal() {
    this.modal.close();
  }


  saveReview() {
    this.refreshValidators = true;

    if (this.reviewForm.valid) {

      this.messagePanelComponent.showLoading();

      const orderBookingId: number = this.reviewForm.get('orderBookingId').value;
      const comments: string = this.reviewForm.get('comments').value;
      const rating: number = this.reviewForm.get('rating').value;

      this.adminBookInteractor.updateReview(orderBookingId, comments, rating)
        .subscribe(
          () => {

            MessagePanelComponent.hideLoading();
            this.messagePanelComponent.showSuccessMessage(I18N('editReview.Información actualizada'));
            this.modal.close(new ReviewUpdatedView(rating, comments));

          },
          (error: GenericException) => {

               MessagePanelComponent.hideLoading();
               this.serverErrorManager.manageErrorCode(error, this.messagePanelComponent);

          }
        )

    }

  }



  onRatingChanged(newRate: number) {
     this.reviewForm.get('rating').setValue(newRate);
 }


  setReviewFormValues(review: Review) {
    this.reviewForm.get('orderBookingId').setValue(review.orderBookingId);
    this.reviewForm.get('comments').setValue(review.comments);
    this.reviewForm.get('rating').setValue(review.rating);

    this.currentRating = review.rating;

  }

}




