import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { NavigationConstants } from 'src/app/ui/commons/navigation.constants';

@Component({
  selector: 'app-footer-landing',
  templateUrl: './footer.component.html'
})
export class FooterLandingComponent {

  constructor( private router: Router) {

  }

}
