<div class="animated fadeIn">
    <div class="row header-dashboard align-items-lg-center align-items-start p-relative z-index-1">
        <div class="col-12 pl-0">
            <h2 class="font-weight-bold">{{ 'tutiorial.Tutorial' | translate }}</h2>
            <p>{{ 'tutiorial.Todo lo que necesitas en una única plataforma' | translate }}</p>
        </div>
    </div>
    <div class="row dashboard-content-section pb-5 pb-md-auto">
        <!-- <div class="col-12">
            <h5 class="font-weight-bold text-uppercase primary-color">{{ 'tutiorial.Vídeo de presentación' | translate }}</h5>
            <h3 class="mb-3">{{ 'tutiorial.Todo lo que necesitas en una única plataforma' | translate }}</h3>
            <p class="mb-5">{{ 'tutiorial.Todos los pormenores de la aplicación en este sencillo tutorial.' | translate }}</p>
        </div> -->
        <div class="col-12">
            <div class="row">
                <div class="col-12 col-lg pl-0">
                    <yt-player class="tutorial" [videoId]="'c7xVNr4k7J4'"></yt-player>
                </div>
                <div class="col-12 col-lg mt-3 mt-lg-auto">
                    <p class="mb-3 font-weight-bold">{{ 'tutiorial.Puntos del Tutorial:' | translate }}</p>
                    <p class="mb-1"><a class="link-menu-video" (click)="onSeek(0)">{{ 'tutiorial.Introducción' | translate }}</a></p>
                    <p class="mb-1"><a class="link-menu-video" (click)="onSeek(54)">{{ 'tutiorial.Cómo cerrar un horario' | translate }}</a></p>
                    <p class="mb-1"><a class="link-menu-video" (click)="onSeek(76)">{{ 'tutiorial.Modificar los asistentes' | translate }}</a></p>
                    <p class="mb-1"><a class="link-menu-video" (click)="onSeek(142)">{{ 'tutiorial.Configurar las pujas' | translate }}</a></p>
                    <p class="mb-1"><a class="link-menu-video" (click)="onSeek(158)">{{ 'tutiorial.Bloquear las reservas para un tour' | translate }}</a></p>
                    <p class="mb-1"><a class="link-menu-video" (click)="onSeek(181)">{{ 'tutiorial.Añadir o cambiar las imágenes del tour' | translate }}</a></p>
                    <p class="mb-1"><a class="link-menu-video" (click)="onSeek(227)">{{ 'tutiorial.Crear / Editar un tours' | translate }}</a></p>
                    <p class="mb-1"><a class="link-menu-video" (click)="onSeek(309)">{{ 'tutiorial.Fijar capacidad máxima del tour y bloquear grupos' | translate }}</a></p>
                    <p class="mb-1"><a class="link-menu-video" (click)="onSeek(365)">{{ 'tutiorial.Establecer un precio por persona en grupos grandes' | translate }}</a></p>
                    <p class="mb-1"><a class="link-menu-video" (click)="onSeek(425)">{{ 'tutiorial.Modificar Meeting Point' | translate }}</a></p>
                    <p class="mb-1"><a class="link-menu-video" (click)="onSeek(470)">{{ 'tutiorial.Añadir o Modificar horarios del tour' | translate }}</a></p>
                    <p class="mb-1"><a class="link-menu-video" (click)="onSeek(584)">{{ 'tutiorial.Cambiar contraseña, idioma o datos fiscales' | translate }}</a></p>

                </div>
            </div>
        </div>
    </div>