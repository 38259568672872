import { Component, OnInit, Output, EventEmitter, Input, ViewChild } from '@angular/core';
import { FormGroup, Validators, FormControl, AbstractControl } from '@angular/forms';
import { TourStepFormView } from 'src/app/domain/views/provider/tour/sept-form.view';
import { ServerErrorManager } from 'src/app/ui/components/utils/server-error-manager.utils';
import { MessagePanelComponent } from 'src/app/ui/components/shared/message-panel/message-panel.component';
import { GenericException } from 'src/app/domain/exceptions/generic.exception';
import { AdminProviderInteractor } from 'src/app/domain/interactor/admin/provider.interactor';
import { CompanyName } from 'src/app/business/models/master/company/company-name.model';

@Component({
  selector: 'app-create-tour-provider',
  templateUrl: './provider.component.html'
})
export class CreateTourProviderComponent implements OnInit {

  @ViewChild(MessagePanelComponent)
  private messagePanelComponent: MessagePanelComponent;

  createTourProviderForm: FormGroup;
  refreshValidators: boolean;

  providers: Array<CompanyName>;

  i18nTraslation: boolean;
  providerIdInput: number;


  @Input()
  set isI18nTraslation(isI18nTraslation: boolean) {
      this.i18nTraslation = isI18nTraslation;
      this.refreshCreateTourProviderForm();
  }


  @Input()
  set providerId(providerId: number) {
      this.providerIdInput = providerId;
      this.refreshCreateTourProviderForm();
  }


  refreshCreateTourProviderForm() {

    if ( this.providerIdInput ) {

        this.createTourProviderForm.get('providerId').setValue(this.providerIdInput);
        if ( this.i18nTraslation || this.providerIdInput ) {
            this.createTourProviderForm.get('providerId').disable();
        } else {
            this.createTourProviderForm.get('providerId').enable();
        }

    }
  }


  @Output() tourProviderEmitter = new EventEmitter<TourStepFormView<number>>(true);


  constructor(
    private adminProviderInteractor: AdminProviderInteractor,
    private serverErrorManager: ServerErrorManager ) {

      this.refreshValidators = false;

      this.createTourProviderForm = new FormGroup({
          'providerId': new FormControl('', [Validators.required] )
     });

  }



  ngOnInit() {

      this.adminProviderInteractor.getProvidersNames()
        .subscribe(
            ( providers: Array<CompanyName> ) => {
                this.providers = providers;
            },
            (error: GenericException) => {
                this.serverErrorManager.manageErrorCode(error, this.messagePanelComponent);
            }
        )

  }

  saveProvider() {

      this.refreshValidators = true;

      const providerFormControl: AbstractControl = this.createTourProviderForm.get('providerId');

      //When only a field and this field is disabled, Angular set all form as disabled and it is not valid by himself
      //In such a case (I18N translation) we check field is valid
      if ( this.createTourProviderForm.valid || ( providerFormControl.disabled && providerFormControl.errors == null ) ) {
          this.emitEventWithFormProvider(true);
      }
  }


  private emitEventWithFormProvider(isNextStep: boolean) {

      const providerId = this.createTourProviderForm.get('providerId').value;

      this.tourProviderEmitter.emit(new TourStepFormView(isNextStep, providerId));

  }


}
