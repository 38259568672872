export class TourLocationFormView {


    private _meetingPoint: string;
    private _meetingPointMain: string;
    private _locationCoordX: number;
    private _locationCoordY: number;
    private _locationIndications: string;
    private _locationIndicationsMain: string;
    private _locationMoreIndications: string;
    private _locationMoreIndicationsMain: string;

    constructor(
      meetingPoint: string,
      meetingPointMain: string,
      locationCoordX: number,
      locationCoordY: number,
      locationIndications: string,
      locationIndicationsMain: string,
      locationMoreIndications: string,
      locationMoreIndicationsMain: string
    ) {

        this._meetingPoint = meetingPoint;
        this._meetingPointMain = meetingPointMain;
        this._locationCoordX = locationCoordX;
        this._locationCoordY = locationCoordY;
        this._locationIndications = locationIndications;
        this._locationIndicationsMain = locationIndicationsMain;
        this._locationMoreIndications = locationMoreIndications;
        this._locationMoreIndicationsMain = locationMoreIndicationsMain;

    }


    get meetingPoint(): string {
        return this._meetingPoint;
    }

    get meetingPointMain(): string {
        return this._meetingPointMain;
    }

    get locationCoordX(): number {
        return this._locationCoordX;
    }

    get locationCoordY(): number {
        return this._locationCoordY;
    }

    get locationIndications(): string {
        return this._locationIndications;
    }

    get locationIndicationsMain(): string {
        return this._locationIndicationsMain;
    }

    get locationMoreIndications(): string {
        return this._locationMoreIndications;
    }

    get locationMoreIndicationsMain(): string {
        return this._locationMoreIndicationsMain;
    }


}