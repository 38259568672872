import { GuideServiceState } from "src/app/business/models/billing/common/guide-service-state.constants";

export class FindServicesFilterView {

     private _providerId: number;
     private _guideId: number;
     private _month: number;
     private _year: number;
     private _state: GuideServiceState;
     private _pageNumber: number;

     constructor(
          providerId: number,
          guideId: number,
          month: number,
          year: number,
          state: GuideServiceState,
          pageNumber: number
     ) {

          this._providerId = providerId;
          this._guideId = guideId;
          this._month = month;
          this._year = year;
          this._state = state;
          this._pageNumber = pageNumber;

     }

     get providerId(): number {
          return this._providerId;
     }

     get guideId(): number {
          return this._guideId;
     }

     get month(): number {
          return this._month;
     }

     get year(): number {
          return this._year;
     }

     get state(): GuideServiceState {
          return this._state;
     }

     get pageNumber(): number {
          return this._pageNumber;
     }

}