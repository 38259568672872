export class BillingDetails {

     private _people: number;
     private _shows: number;
     private _price: number;
     private _payment: number;
     private _commission: number;

    constructor(
    people: number,
    shows: number,
    price: number,
    payment: number,
    commission: number
  ) {
    this._people = people;
    this._shows = shows;
    this._price = price;
    this._payment = payment;
    this._commission = commission;
  }

  get people(): number {
    return this._people;
  }

  get shows(): number {
    return this._shows;
  }

  get price(): number {
    return this._price;
  }

  get payment(): number {
    return this._payment;
  }

  get commission(): number {
    return this._commission;
  }

}
