import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { BookEvent } from 'src/app/business/models/book/book-event.model';
import { GenericException } from 'src/app/domain/exceptions/generic.exception';
import { ProviderBookInteractor } from 'src/app/domain/interactor/provider/book.interactor';
import { ChunkGeneric } from 'src/app/domain/views/chunk-generic.view';
import { Pagination } from 'src/app/domain/views/pagination.view';
import { NavigationConstants } from 'src/app/ui/commons/navigation.constants';
import { MessagePanelComponent } from 'src/app/ui/components/shared/message-panel/message-panel.component';
import { ServerErrorManager } from 'src/app/ui/components/utils/server-error-manager.utils';
import { marker as I18N } from '@biesbjerg/ngx-translate-extract-marker';
import { TranslateService } from '@ngx-translate/core';



@Component({
     selector: 'app-provider-list-booking',
     templateUrl: './list.component.html'
})
export class ProviderListBookingComponent implements OnInit {

     @ViewChild(MessagePanelComponent)
     private messagePanelComponent: MessagePanelComponent;

     pagination: Pagination;
     pageNumber: number;

     dateAsString: string;
     countryId: number;
     cityId: number;
     tourId: number;

     bookEvents: Array<BookEvent>;

     noResults: boolean;

     constructor(
          private router: Router,
          private providerBookInteractor: ProviderBookInteractor,
          private translateService: TranslateService,
          private serverErrorManager: ServerErrorManager
     ) {

          this.pagination = new Pagination(0, 1, 20);
          this.pageNumber = this.pagination.currentPage;

          this.noResults = true;
     }

     ngOnInit() {
     }


     findBookEvents() {

          if (this.messagePanelComponent) {
               this.messagePanelComponent.showLoading();
          }

          this.providerBookInteractor.findBookEvents(this.dateAsString, this.countryId, this.cityId, this.tourId, this.pageNumber)
          .subscribe(
               (chunkTours: ChunkGeneric<BookEvent>) => {
                    this.pagination = new Pagination(chunkTours.pagination.totalItems, chunkTours.pagination.currentPage, chunkTours.pagination.itemsPerPage);
                    this.bookEvents = chunkTours.items;
                    this.noResults = this.bookEvents.length == 0;

                    MessagePanelComponent.hideLoading();
               },
               (error: GenericException) => {
                    MessagePanelComponent.hideLoading();
                    this.serverErrorManager.manageErrorCode(error, this.messagePanelComponent);
               }
          )
     }


     onDateAsStringSelected(dateAsString: string) {

          this.dateAsString = dateAsString;
          this.findBookEvents();
     }

     onCountryIdSelected(countryId: number) {
          this.countryId = countryId;
          this.findBookEvents();
     }

     onCityIdSelected(cityId: number) {
          this.cityId = cityId;
          this.findBookEvents();
     }

     onTourIdSelected(tourId: number) {
          this.tourId = tourId;
          this.findBookEvents();
     }

     onPageNumberSelected(pageNumber: number) {
          this.pageNumber = pageNumber;
          this.findBookEvents();
     }

     onViewBookDetails(bookEvent: BookEvent) {

          this.router.navigateByUrl(NavigationConstants.redirectUrl(NavigationConstants.getNavigationProviderDetailBooking(bookEvent.event.tourId, bookEvent.event.day, bookEvent.event.hour)));

     }


     onAllowBooking(bookEvent: BookEvent) {

          this.messagePanelComponent.showLoading();

          this.providerBookInteractor.allowEventBooking(bookEvent.event.tourId, bookEvent.event.day, bookEvent.event.hour)
          .subscribe(
               () => {
                    this.messagePanelComponent.showSuccessMessage(I18N('bookEvent.Se han abierto las reservas para este evento.'), () => this.findBookEvents());
               }, (error: GenericException) => {
                    MessagePanelComponent.hideLoading();
                    this.serverErrorManager.manageErrorCode(error, this.messagePanelComponent);
               }
          )
     }
     onDisallowBooking(bookEvent: BookEvent) {

          this.messagePanelComponent.showLoading();

          this.providerBookInteractor.disallowEventBooking(bookEvent.event.tourId, bookEvent.event.day, bookEvent.event.hour)
          .subscribe(
               () => {
                    this.messagePanelComponent.showSuccessMessage(I18N('bookEvent.Se han cerrado las reservas para este evento.'), () => this.findBookEvents());
               }, (error: GenericException) => {
                    MessagePanelComponent.hideLoading();
                    this.serverErrorManager.manageErrorCode(error, this.messagePanelComponent);
               }
          )
     }


     onShowUpdateMaxBookings(bookEvent: BookEvent) {

          this.translateService.get(I18N('bookEvent.Modifica la capacidad del evento'), {})
          .subscribe(
               (updateMaxBookingsMessageTranslated: string) => {
                    this.messagePanelComponent.showInputMessage(
                      updateMaxBookingsMessageTranslated,
                      updateMaxBookingsMessageTranslated,
                      MessagePanelComponent.MESSAGE_INPUT_TYPE_NUMBER,
                      bookEvent.capacity.toString(),
                      null,
                      (value: string) => {
                           this.messagePanelComponent.showLoading();

                           var valueNumeric: number = null;
                           if ( value.trim().length != 0 ) {
                                valueNumeric = Number(value);
                           }

                           this.providerBookInteractor.updateEventMaxBooking(bookEvent.event.tourId, bookEvent.event.day, bookEvent.event.hour, valueNumeric)
                           .subscribe(
                                () => {
                                     this.messagePanelComponent.showSuccessMessage(I18N('bookEvent.Se ha actualizado la capacidad de este evento.'), () => this.findBookEvents());
                                }, (error: GenericException) => {
                                     MessagePanelComponent.hideLoading();
                                     this.serverErrorManager.manageErrorCode(error, this.messagePanelComponent);
                                }
                           )

                      }
                  )
              }
          )
     }



}
