<!-- <div class="container-fluid">
    <div class="container">
        <div class="row">
            <div class="col-md-3">
                <app-sidebar></app-sidebar>
            </div>


            <div class="col-md-9">
                <app-header></app-header>
                <router-outlet></router-outlet>
            </div>
            
        </div>
    </div>
</div> -->
<div class="d-flex">

    <app-sidebar></app-sidebar>
    <div class="dashboard-panel">
        <app-header></app-header>

        <div class="container-fluid">
            <router-outlet></router-outlet>
        </div>
    </div>

</div>