<nav class="navbar navbar-expand-lg nav-dashboard bg-primary-color">
    <a class="d-lg-none mx-auto pl-4" (click)="goToDashboard()">
        <img class="logo" src="assets/img/logo-white.png">
    </a>

    <div class="navbar-collapse collapse">
        <!-- <app-admin-header-public-navigation></app-admin-header-public-navigation> -->
        <ul class="navbar-nav ml-auto mt-2 mt-lg-0">
            <li class="nav-item dropdown">

                <a (click)="onProfileMenu()" class="navbar-profile-link nav-link dropdown-toggle" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                     <img class="ic-user" src="assets/img/ic_user-white.svg">
                     <span class="navbar-profile align-bottom d-none d-lg-inline">
                          <span class="navbar-name" *ngIf="name">
                               {{ name }}
                               <br/>
                          </span>
                          {{ email }}
                     </span>
                </a>
                <div *ngIf="showProfileMenu" class="dropdown-menu" aria-labelledby="navbarDropdown">
                     <a (click)="goToProfile()" class="dropdown-item">{{ 'header.Datos de perfil' | translate}}</a>
                     <div class="dropdown-divider"></div>
                     <a (click)="logout()" class="dropdown-item">{{ 'header.Cerrar sesión' | translate}}</a>
                </div>
            </li>
        </ul>
    </div>
</nav>