export class ServiceTourEventFilter {

     private _tourId: number;
     private _day: string;

     constructor(
          tourId: number,
          day: string
     ) {

          this._tourId = tourId;
          this._day = day;
     }


     public get tourId(): number {
          return this._tourId;
     }

     public get day(): string {
          return this._day;
     }     

}