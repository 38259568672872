import { Component, Output, EventEmitter, OnInit, Input } from '@angular/core';
import { FormGroup, Validators, FormControl, FormArray } from '@angular/forms';
import { TourUsersFormView } from 'src/app/domain/views/provider/tour/users-form.view';
import { TourFareFormView } from 'src/app/domain/views/provider/tour/fare-form.view';
import { TourStepFormView } from 'src/app/domain/views/provider/tour/sept-form.view';
import { AppTourConstants } from 'src/app/domain/common/tour/tour.constants';
import { AppConstants } from 'src/app/domain/common/app.constants';

@Component({
  selector: 'app-create-tour-users',
  templateUrl: './users.component.html'
})
export class CreateTourUsersComponent implements OnInit {

  createTourUsersForm: FormGroup;

  isFreeSelected: boolean;

  refreshValidators: boolean;

  i18nTraslation: boolean;
  tourUsersInput: TourUsersFormView;

  @Input()
  set isI18nTraslation(isI18nTraslation: boolean) {
      this.i18nTraslation = isI18nTraslation;

      this.refreshCreateTourDetailsForm();
  }

  @Input()
  set tourUsers(tourUsers: TourUsersFormView) {
      this.tourUsersInput = tourUsers;
      this.refreshCreateTourDetailsForm();
  }


  refreshCreateTourDetailsForm() {
      if ( this.tourUsersInput ) {
           if ( this.tourUsersInput.minPax != 0 ) {
               this.createTourUsersForm.get('minPax').setValue(this.tourUsersInput.minPax);
           }
          if ( this.i18nTraslation ) {
              this.createTourUsersForm.get('minPax').disable();
          } else {
              this.createTourUsersForm.get('minPax').enable();
          }

          this.createTourUsersForm.get('maxPax').setValue(this.tourUsersInput.maxPax)
          if ( this.i18nTraslation ) {
              this.createTourUsersForm.get('maxPax').disable();
          } else {
              this.createTourUsersForm.get('maxPax').enable();
          }

          this.createTourUsersForm.get('cancellationLimit').setValue(this.tourUsersInput.cancellationLimit)
          if ( this.i18nTraslation ) {
              this.createTourUsersForm.get('cancellationLimit').disable();
          } else {
              this.createTourUsersForm.get('cancellationLimit').enable();
          }

          this.createTourUsersForm.get('bookingLimit').setValue(this.tourUsersInput.bookingLimit)
          if ( this.i18nTraslation ) {
              this.createTourUsersForm.get('bookingLimit').disable();
          } else {
              this.createTourUsersForm.get('bookingLimit').enable();
          }

          this.createTourUsersForm.get('maxPeoplePerBooking').setValue(this.tourUsersInput.maxPeoplePerBooking)
          if ( this.i18nTraslation ) {
              this.createTourUsersForm.get('maxPeoplePerBooking').disable();
          } else {
              this.createTourUsersForm.get('maxPeoplePerBooking').enable();
          }

          this.createTourUsersForm.get('isFree').setValue(this.tourUsersInput.isFree)

          this.createTourUsersForm.get('maxFreePeople').setValue(this.tourUsersInput.maxFreePeople)
          if ( this.i18nTraslation ) {
              this.createTourUsersForm.get('maxFreePeople').disable();
          } else {
              this.createTourUsersForm.get('maxFreePeople').enable();
          }

          this.createTourUsersForm.get('maxFreePeoplePrice').setValue(this.tourUsersInput.maxFreePeoplePrice)
          if ( this.i18nTraslation ) {
              this.createTourUsersForm.get('maxFreePeoplePrice').disable();
          } else {
              this.createTourUsersForm.get('maxFreePeoplePrice').enable();
          }

          this.createTourUsersForm.get('maxFreePeopleAge').setValue(this.tourUsersInput.maxFreePeopleAge)
          if ( this.i18nTraslation ) {
              this.createTourUsersForm.get('maxFreePeopleAge').disable();
          } else {
              this.createTourUsersForm.get('maxFreePeopleAge').enable();
          }


          this.fares.clear()
          if ( this.tourUsersInput.fares ) {
              for ( const fare of this.tourUsersInput.fares ) {
                  const fareFormGroup = new FormGroup({
                    'fareId': new FormControl(fare.id),
                    'fareName': new FormControl(fare.name, [Validators.required] ),
                    'fareNameMain': new FormControl(fare.nameMain),
                    'farePrice': new FormControl('', [Validators.min(0)] )
                  })

                  if ( !this.tourUsersInput.isFree && fare.price != 0 ) {
                      fareFormGroup.get('farePrice').setValue(fare.price);
                  }

                  if ( this.i18nTraslation || this.tourUsersInput.isFree ) {
                      fareFormGroup.get('farePrice').disable();
                  }

                  this.fares.push(fareFormGroup);
              }
          }

          this.isFreeSelected = this.tourUsersInput.isFree;
          if (this.isFreeSelected) {
               this.onFreeSelected();
          } else {
               this.onNotFreeSelected();
          }

      }
  }

  @Output() tourUsersEmitter = new EventEmitter<TourStepFormView<TourUsersFormView>>(true)

  constructor() {

      this.isFreeSelected = true
      this.refreshValidators = false;

      this.createTourUsersForm = new FormGroup({
          'minPax': new FormControl('', [Validators.min(1), Validators.max(AppConstants.NUMBER_MAX_VALE)]),
          'maxPax': new FormControl('', [Validators.min(1), Validators.max(AppConstants.NUMBER_MAX_VALE), Validators.required]),
          'cancellationLimit': new FormControl(AppTourConstants.CREATE_TOUR_DEFAULT_CANCELATION_LIMIT),
          'bookingLimit': new FormControl(AppTourConstants.CREATE_TOUR_DEFAULT_BOOKING_LIMIT, [Validators.required]),
          'maxPeoplePerBooking': new FormControl('', [Validators.required, Validators.min(1), Validators.max(AppConstants.NUMBER_MAX_VALE)]),
          'isFree': new FormControl(true),
          'maxFreePeople': new FormControl('', [Validators.min(1), Validators.max(AppConstants.NUMBER_MAX_VALE)]),
          'maxFreePeoplePrice': new FormControl('', [Validators.min(1), Validators.max(AppConstants.NUMBER_MAX_VALE)]),
          'maxFreePeopleAge': new FormControl('', [Validators.required, Validators.min(1), Validators.max(18)]),
          'maxFreePeopleValid': new FormControl(''),
          'fares': new FormArray([])
     }, [this.maxFreePeopleValid]);

     this.onFreeSelected();


  }


  ngOnInit() {

  }


  get fares(): FormArray {
      return this.createTourUsersForm.get('fares') as FormArray;
  }

  addFare() {
      let existEmptyFares = false;
      this.fares.controls.map( (fareGroup: FormGroup) => {
          if ( fareGroup.get('fareName').value == '' )  {
              existEmptyFares = true;
          }
      });
      if ( !existEmptyFares ) {
          this.fares.push(new FormGroup({
            'fareId': new FormControl(''),
            'fareName': new FormControl('', [Validators.required, Validators.maxLength(AppConstants.TEXT_DEFAULT_MAX_LENGTH)] ),
            'fareNameMain': new FormControl(''),
            'farePrice': new FormControl('', [Validators.min(0), Validators.max(AppConstants.NUMBER_MAX_VALE)] )
          }))
      }
  }

  removeFare(index: number) {
      this.fares.removeAt(index)
  }


  saveUsers() {
      this.refreshValidators = true;
      if ( this.createTourUsersForm.valid ) {
          this.emitEventWithFormDetails(true)
      }
  }



  private emitEventWithFormDetails(isNextStep: boolean) {

      const fares = new Array<TourFareFormView>();
      const faresFormArray = this.createTourUsersForm.get('fares') as FormArray;
      faresFormArray.controls.map( (fareFormGroup: FormGroup) => {
          const id = fareFormGroup.get('fareId').value;
          const name = fareFormGroup.get('fareName').value;
          const nameMain = fareFormGroup.get('fareNameMain').value;
          let price = fareFormGroup.get('farePrice').value;
          if ( price == "" || price == null ) {
              price = 0;
          }
          fares.push(new TourFareFormView(id, name, nameMain, price));
      });

      const tourUsers = new TourUsersFormView(
          this.createTourUsersForm.get('minPax').value,
          this.createTourUsersForm.get('maxPax').value,
          this.createTourUsersForm.get('cancellationLimit').value,
          this.createTourUsersForm.get('bookingLimit').value,
          this.createTourUsersForm.get('maxPeoplePerBooking').value,
          this.createTourUsersForm.get('isFree').value,
          this.createTourUsersForm.get('maxFreePeople').value,
          this.createTourUsersForm.get('maxFreePeoplePrice').value,
          this.createTourUsersForm.get('maxFreePeopleAge').value,
          fares
      )

      this.tourUsersEmitter.emit(new TourStepFormView(isNextStep, tourUsers));

  }




  onBackButton() {
      this.emitEventWithFormDetails(false);
  }


  onFreeSelected() {
      if ( !this.i18nTraslation ) {
          this.isFreeSelected = true;
          this.createTourUsersForm.get('isFree').setValue(this.isFreeSelected);
          this.createTourUsersForm.get('maxFreePeopleAge').setValidators([Validators.required, Validators.min(1), Validators.max(18)]);
          this.fares.clear();

      }
  }

  onNotFreeSelected() {
      if ( !this.i18nTraslation ) {
          this.isFreeSelected = false
          this.createTourUsersForm.get('isFree').setValue(this.isFreeSelected);
          this.createTourUsersForm.get('maxFreePeople').setValue('');
          this.createTourUsersForm.get('maxFreePeoplePrice').setValue('');
          this.createTourUsersForm.get('maxFreePeopleAge').setValue('');
          this.createTourUsersForm.get('maxFreePeopleAge').setErrors(null);
          this.createTourUsersForm.get('maxFreePeopleAge').setValidators(null);

          if ( this.fares.length == 0 ) {
               this.addFare();
          }

      }
  }


  maxFreePeopleValid( formGroup: FormGroup ): { [s:string]:boolean } {
      if ( formGroup.get('maxFreePeople') && formGroup.get('maxFreePeoplePrice') ) {
          const maxFreePeople = formGroup.get('maxFreePeople').value;
          const maxFreePeoplePrice = formGroup.get('maxFreePeoplePrice').value;
          const maxPeoplePerBooking = formGroup.get('maxPeoplePerBooking').value;

          if ( maxFreePeople && !maxFreePeoplePrice) {
              formGroup.get('maxFreePeopleValid').setErrors({invalidPrice: true});
          } else if ( !maxFreePeople && maxFreePeoplePrice ) {
              formGroup.get('maxFreePeopleValid').setErrors({invalidPeople: true});
          } else if ( maxFreePeople && maxPeoplePerBooking && maxFreePeople > maxPeoplePerBooking ) {
                formGroup.get('maxFreePeopleValid').setErrors({invalidLimit: true});
          } else {
              formGroup.get('maxFreePeopleValid').setErrors(null);
          }

      }
      return null;
  }


}
