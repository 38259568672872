<div class="animated fadeIn" *ngIf="affiliateStatsDetails">
     <div class="row dashboard-content-section-first dashboard-content-section dashboard-affiliate">
          <div class="col-12 col-md-4 mb-3">
               <div class="row moderncard bg-primary-color mr-md-1 text-center text-md-left">
                    <div class="col-12 col-md-4 col-xl-3 mb-2">
                         <img class="moderncard-image" src="assets/img/ic_payment_white.svg">
                    </div>
                    <div class="col-12 col-md-8 mb-2">
                         <div class="moderncard-title">{{ 'affiliatedashboard.Total comisiones generadas' | translate }}</div>
                         <div class="moderncard-content">{{ affiliateStatsDetails.totalCommissionAndVat }} €</div>
                    </div>
               </div>
          </div>
          <div class="col-12 col-md-4 mb-3">
               <div class="row moderncard ml-md-1 mr-md-1 text-center text-md-left">
                         <div class="col-12 col-md-4 col-xl-3 mb-2">
                              <img class="moderncard-image" src="assets/img/ic_calendar-modern.svg">
                         </div>
                         <div class="col-12 col-md-8 mb-2">
                              <div class="moderncard-title">{{ 'affiliatedashboard.Próxima facuración' | translate }}</div>
                              <div class="moderncard-content">{{ affiliateStatsDetails.nextBillingStats.totalCommissionAndVat }} €</div>
                         </div>
               </div>
          </div>
          <div class="col-12 col-md-4 mb-3">
               <div class="row moderncard ml-md-1 text-center text-md-left">
                         <div class="col-12 col-md-4 col-xl-3 mb-2">
                              <img class="moderncard-image" src="assets/img/ic_input_benefits.svg">
                         </div>
                         <div class="col-12 col-md-8 mb-2">
                              <div class="moderncard-title">{{ 'affiliatedashboard.Nº de reservas este mes' | translate }}</div>
                              <div class="moderncard-content">{{ affiliateStatsDetails.nextBillingStats.totalBookingsNumber }}</div>
                         </div>
               </div>
          </div>
     </div>
     <div class="row dashboard-content-section dashboard-affiliate mt-3 mt-md-5">
          <div class="col-12 col-md-12">
               <div class="row">
                    <div class="col-12 card card-list">
                         <div class="row pl-2 pr-2">
                              <div class="col-12 col-md-12 mt-4">
                                   <h5 class="font-weight-bold">{{ 'affiliatedashboard.Comisiones diarias del mes' | translate }}</h5>
                              </div>
                              <div class="col-12 col-md-6 mt-1 mb-1">
                                   <form class="form-boxes m-0">
                                        <div class="row">
                                             <div class="dashboard-affiliate-year-container col-12 col-md-6 col-lg-4 px-0 px-md-auto ml-md-3">
                                                 <div class="field pb-2">
                                                     <select [(ngModel)]="year" (change)="onYearSelected()" name="year">
                                                        <option *ngFor="let year of years" value="{{ year }}">{{ year }}</option>
                                                     </select>
                                                 </div>
                                             </div>

                                             <div class="dashboard-affiliate-month-container col-12 col-md-6 col-lg-4 px-0 px-md-auto ml-md-3">
                                                 <div class="field pb-2">
                                                     <select [(ngModel)]="month" (change)="onMonthSelected()" name="month">
                                                        <option *ngFor="let month of months" value="{{ month }}">{{ month | i18NMonthFromNumber | translate }}</option>
                                                     </select>
                                                 </div>
                                             </div>
                                        </div>
                                   </form>
                              </div>
                              <div class="col-12 col-md-6 mt-1 mb-1 text-left text-md-right">
                                   <b>{{ 'affiliatedashboard.Total comisiones' | translate }}:</b> {{ chartStats.totalCommissionAndVat }} €
                                   <br/>
                                   <b>{{ 'affiliatedashboard.Total reservas' | translate }}:</b> {{ chartStats.totalBookingsNumber }}
                              </div>
                              <div class="col-12">
                                   <div class="chart-container moderncard">
                                        <ngx-charts-area-chart
                                          [scheme]="colorScheme"
                                          [legend]="false"
                                          [showXAxisLabel]="false"
                                          [showYAxisLabel]="false"
                                          [xAxis]="true"
                                          [yAxis]="true"
                                          [timeline]="true"
                                          [gradient]="true"
                                          [results]="chartData"
                                          >
                                        </ngx-charts-area-chart>
                                   </div>
                              </div>
                         </div>
                    </div>
               </div>
          </div>
     </div>


     <div class="row dashboard-content-section mt-3 mt-md-5">
          <div class="col-12 card card-list">
               <div class="col-12">
                    <div class="row">
                         <div class="col-12 col-md-8 mt-4 mb-3">
                              <h5 class="font-weight-bold">{{ 'affiliatedashboard.Últimas reservas' | translate }}</h5>
                         </div>
                         <div class="col-12 col-md-4 mt-4 mb-3 text-right">
                              <a (click)="goToAffiliationBilling()">{{ 'affiliatedashboard.ver todas' | translate }}</a>
                         </div>
                         <div *ngIf="noResults" class="col-12 text-center">
                             <img class="logo mb-4" src="assets/img/logo_opacity-21.png">
                             <p>{{ 'affiliatedashboard.No se han encontrado resultados' | translate }}</p>
                         </div>
                         <div *ngIf="!noResults" class="col-12 px-0">
                             <app-billing-table *ngIf="desktopVersion" [bookingBillings]="latestBookings" [isAffiliateBilling]="true" [pagination]="pagination"></app-billing-table>
                             <app-billing-cards *ngIf="!desktopVersion" [bookingBillings]="latestBookings" [isAffiliateBilling]="true" [pagination]="pagination"></app-billing-cards>
                         </div>
                    </div>

               </div>
          </div>

     </div>

</div>

<app-message-panel [preloading]="false"></app-message-panel>

<app-window-size-controller [initialSize]="initialWindowSize" (isDesktopVersion)="onDesktopVersionUpdated($event)"></app-window-size-controller>