import { Injectable }                       from '@angular/core';
import { Observable, Observer } from 'rxjs';
import { AdminProviderService } from 'src/app/business/services/admin/provider.service';
import { ChunkGeneric } from '../../views/chunk-generic.view';
import { ProviderResume } from 'src/app/business/models/provider/provider-resume.model';
import { Provider } from 'src/app/business/models/provider/provider.model';
import { ProviderDetailsView } from '../../views/provider/provider-details.view';
import { GenericException } from '../../exceptions/generic.exception';
import { ProfilePaymentView } from '../../views/profile-payment.view';
import { CompanyName } from 'src/app/business/models/master/company/company-name.model';


@Injectable({
  providedIn: 'root'
})
export class AdminProviderInteractor {

    constructor(private adminProviderService: AdminProviderService) {
    }

    createProvider(companyName: string, email: string, languageId: number): Observable<boolean> {

        return Observable.create((observer: Observer<boolean>) => {
            this.adminProviderService.createProvider(companyName, email, languageId)
                  .subscribe(
                      (success: boolean) => {

                          observer.next(success);
                          observer.complete();
                      },
                      (error: GenericException) => {
                          observer.error(error);
                      }
                  )

        });
    }



    findProviders( page: number ): Observable<ChunkGeneric<ProviderResume>> {

        return Observable.create((observer: Observer<ChunkGeneric<ProviderResume>>) => {
            this.adminProviderService.findProviders(page)
                  .subscribe(
                      (chunkResult: ChunkGeneric<ProviderResume>) => {

                          observer.next(chunkResult);
                          observer.complete();
                      },
                      (error: GenericException) => {
                          observer.error(error);
                      }
                  )

        });
    }



    findAllProviders(): Observable<Array<ProviderResume>> {

        return Observable.create((observer: Observer<Array<ProviderResume>>) => {
            this.adminProviderService.findAllProviders()
                  .subscribe(
                      (providers: Array<ProviderResume>) => {

                          observer.next(providers);
                          observer.complete();
                      },
                      (error: GenericException) => {
                          observer.error(error);
                      }
                  )

        });
    }


    getProvidersNames(): Observable<Array<CompanyName>> {

        return Observable.create((observer: Observer<Array<CompanyName>>) => {
            this.adminProviderService.getProvidersNames()
                  .subscribe(
                      (providers: Array<CompanyName>) => {

                          observer.next(providers);
                          observer.complete();
                      },
                      (error: GenericException) => {
                          observer.error(error);
                      }
                  )

        });
    }



    getProvidersNamesWithGuideManagement(): Observable<Array<CompanyName>> {

        return Observable.create((observer: Observer<Array<CompanyName>>) => {
            this.adminProviderService.getProvidersNamesWithGuideManagement()
                  .subscribe(
                      (providers: Array<CompanyName>) => {

                          observer.next(providers);
                          observer.complete();
                      },
                      (error: GenericException) => {
                          observer.error(error);
                      }
                  )

        });
    }


    


    getProviderDetails( providerId: number ): Observable<Provider> {

        return Observable.create((observer: Observer<Provider>) => {
            this.adminProviderService.getProviderDetalis(providerId)
                  .subscribe(
                      (provider: Provider) => {
                          observer.next(provider);
                          observer.complete();
                      },
                      (error: GenericException) => {
                          observer.error(error);
                      }
                  )

        });
    }



    resendWelcomeEmail( providerId: number ): Observable<boolean> {

        return Observable.create((observer: Observer<boolean>) => {
            this.adminProviderService.resendWelcomeEmail(providerId)
                  .subscribe(
                      () => {
                          observer.next(true);
                          observer.complete();
                      },
                      (error: GenericException) => {
                          observer.error(error);
                      }
                  )

        });
    }



    updateProviderDetails(providerDetails: ProviderDetailsView): Observable<boolean> {

        return Observable.create((observer: Observer<boolean>) => {
            this.adminProviderService.updateProviderDetails(
                              providerDetails.id, providerDetails.companyName,
                              providerDetails.web, providerDetails.phone, providerDetails.country,
                              providerDetails.city, providerDetails.contactPerson, providerDetails.contactEmail)
                  .subscribe(
                      () => {
                          observer.next(true);
                          observer.complete();
                      },
                      (error: GenericException) => {
                          observer.error(error);
                      }
                  )

        });
    }



    updateProviderPayment(providerPayment: ProfilePaymentView): Observable<boolean> {

        return Observable.create((observer: Observer<boolean>) => {
            this.adminProviderService.updateProviderPayment(
                        providerPayment.id, providerPayment.businessName, providerPayment.businessDocumentType,
                        providerPayment.businessDocument, providerPayment.businessAddress, providerPayment.businessZeroVat)
                  .subscribe(
                      () => {
                          observer.next(true);
                          observer.complete();
                      },
                      (error: GenericException) => {
                          observer.error(error);
                      }
                  )

        });
    }



    confirmProvider(providerId: number): Observable<boolean> {

        return Observable.create((observer: Observer<boolean>) => {
            this.adminProviderService.confirmProvider(providerId)
                  .subscribe(
                      () => {
                          observer.next(true);
                          observer.complete();
                      },
                      (error: GenericException) => {
                          observer.error(error);
                      }
                  )

        });
    }


    rejectProvider(providerId: number, message: string): Observable<boolean> {

        return Observable.create((observer: Observer<boolean>) => {
            this.adminProviderService.rejectProvider(providerId, message)
                  .subscribe(
                      () => {
                          observer.next(true);
                          observer.complete();
                      },
                      (error: GenericException) => {
                          observer.error(error);
                      }
                  )

        });
    }



    unregisterProvider(providerId: number): Observable<boolean> {

        return Observable.create((observer: Observer<boolean>) => {
            this.adminProviderService.unregisterProvider(providerId)
                  .subscribe(
                      () => {
                          observer.next(true);
                          observer.complete();
                      },
                      (error: GenericException) => {
                          observer.error(error);
                      }
                  )

        });
    }


    registerProvider(providerId: number): Observable<boolean> {

        return Observable.create((observer: Observer<boolean>) => {
            this.adminProviderService.registerProvider(providerId)
                  .subscribe(
                      () => {
                          observer.next(true);
                          observer.complete();
                      },
                      (error: GenericException) => {
                          observer.error(error);
                      }
                  )

        });
    }


    createStripeAccount(providerId: number): Observable<string> {

        return Observable.create((observer: Observer<string>) => {
            this.adminProviderService.createStripeAccount(providerId)
                  .subscribe(
                      (stripeAccountUrl: string) => {
                          observer.next(stripeAccountUrl);
                          observer.complete();
                      },
                      (error: GenericException) => {
                          observer.error(error);
                      }
                  )

        });
    }


    removeStripeAccount(providerId: number): Observable<boolean> {

        return Observable.create((observer: Observer<boolean>) => {
            this.adminProviderService.removeStripeAccount(providerId)
                  .subscribe(
                      () => {
                          observer.next(true);
                          observer.complete();
                      },
                      (error: GenericException) => {
                          observer.error(error);
                      }
                  )

        });
    }


    updateStripeAccount(providerId: number, stripeAccountId: string): Observable<boolean> {

        return Observable.create((observer: Observer<boolean>) => {
            this.adminProviderService.updateStripeAccount(providerId, stripeAccountId)
                  .subscribe(
                      () => {
                          observer.next(true);
                          observer.complete();
                      },
                      (error: GenericException) => {
                          observer.error(error);
                      }
                  )

        });
    }





}
