<form class="form-boxes m-0 p-0" action="" [formGroup]="bidForm">
    <div class="modal-header pb-0">
        <button type="button" class="close" aria-label="Close" (click)="closeModal()">
            <span aria-hidden="true">&times;</span>
          </button>
    </div>
    <div class="modal-body mb-5">
        <h5 class="modal-title">{{ 'editbid.Modifica el precio de la puja' | translate}}</h5>
        <p>{{ 'editbid.El valor mínimo de la ciudad es 2' | translate: minBidLabelValue }}</p>
        <div class="row mt-5">
            <div class="col-12 col-md-6">
                <div class="field pb-2">
                    <input formControlName="bid" type="number">
                    <label for="language">{{ 'editbid.Puja' | translate }}</label>
                </div>
                <app-feedback [formGroup]="bidForm" field="bid" validator="required" feedback="{{ 'editbid.Por favor, indica una puja válida.' | translate }}" [refresh]="refreshValidators"></app-feedback>
                <app-feedback [formGroup]="bidForm" field="bid" validator="max" feedback="{{ 'editbid.Número demasiado grande' | translate }}" [refresh]="refreshValidators"></app-feedback>
            </div>
            <div class="col-12 col-md-12 d-flex">
                <div class="checkbox-custom mr-2">
                    <input formControlName="bidBlocked" type="checkbox" id="contactEmail" />
                    <div class="checkbox-custom-visible"></div>
                </div>
                <label for="contactEmail">{{ 'editbid.Bloquear edición al proveedor' | translate }}</label>

            </div>
        </div>
    </div>
    <div class="modal-footer border-top-0">
        <input (click)="saveBid()" type="submit" value="{{ 'editbid.Guardar datos' | translate }}" class="btn bg-primary-color mb-3 mb-sm-0 mr-4 modal-confirm" />

        <input (click)="closeModal()" type="submit" value="{{ 'editbid.Cancelar' | translate }}" class="btn mb-3 mb-sm-0 modal-cancel" />

    </div>


</form>



<app-message-panel></app-message-panel>