<table class="table table-hover table-dark">
    <thead class="thead-dark">
        <tr>
            <th scope="col">{{ 'providerslist.NOMBRE' | translate }}</th>
            <th scope="col">{{ 'providerslist.EMAIL' | translate }}</th>
            <th scope="col">{{ 'providerslist.NOMBRE CONTACTO' | translate }}</th>
            <th scope="col">{{ 'providerslist.EMAIL CONTACTO' | translate }}</th>
            <th scope="col">{{ 'providerslist.PAÍS' | translate }}</th>
            <th scope="col">{{ 'providerslist.ESTADO' | translate }}</th>
        </tr>
    </thead>
    <tbody class="row-clickable">
        <tr *ngFor="let provider of providers | paginate: pagination;" (click)="onProviderClick(provider.id)">
            <td scope="row ">{{ provider.companyName }}</td>
            <td>{{ provider.username }}</td>
            <td>{{ provider.contactPerson }}</td>
            <td>{{ provider.email }}</td>
            <td>{{ provider.city }}, {{ provider.country }}</td>

            <td [ngClass]="'status' | adminProviderStateClass: provider.registrationState : provider.state">
                <div class="dot"></div> {{ '' | adminProviderStateName: provider.registrationState : provider.state | translate }}
            </td>

        </tr>

    </tbody>
</table>