import { Injectable } from '@angular/core';
import { Observable, Observer } from 'rxjs';
import { GenericException } from '../../exceptions/generic.exception';
import { ChunkGeneric } from '../../views/chunk-generic.view';
import { SessionInteractor } from '../session.interactor';
import { GuideTourService } from 'src/app/business/services/guide/tour.service';
import { TourResume } from 'src/app/business/models/tour/tour-resume.model';
import { EventBasic } from 'src/app/business/models/book/event-basic.model';


@Injectable({
     providedIn: 'root'
})
export class GuideTourInteractor {

     constructor(private guideTourService: GuideTourService, private sessionInteractor: SessionInteractor) {
     }

     findToursForReselling(search: string, countryId: number, cityId: number, page: number): Observable<ChunkGeneric<TourResume>> {

        return Observable.create((observer: Observer<ChunkGeneric<TourResume>>) => {
              this.guideTourService.findToursForReselling(this.sessionInteractor.getCurrentLanguage(), search, countryId, cityId, page)
                    .subscribe(
                        (chunkTours: ChunkGeneric<TourResume>) => {

                            observer.next(chunkTours);
                            observer.complete();
                        },
                        (error: GenericException) => {
                            observer.error(error);
                        }
                    )

        });

    }

    
    findAllToursForManagement(): Observable<Array<TourResume>> {

        return Observable.create((observer: Observer<Array<TourResume>>) => {
              this.guideTourService.findAllToursForManagement(this.sessionInteractor.getCurrentLanguage())
                    .subscribe(
                        (tours: Array<TourResume>) => {

                            observer.next(tours);
                            observer.complete();
                        },
                        (error: GenericException) => {
                            observer.error(error);
                        }
                    )

        });

    }



    findEvents(tourId: number, day: string): Observable<Array<EventBasic>> {
        return Observable.create((observer: Observer<Array<EventBasic>>) => {
            this.guideTourService.findEvents(tourId, day)
                  .subscribe(
                      (events: Array<EventBasic>) => {
                          observer.next(events);
                          observer.complete();
                      },
                      (error: GenericException) => {
                          observer.error(error);
                      }
                  )

      });
  }


}
