<div class="row">
  <div class="col-12 text-left">
      <h1 class="d-none d-lg-flex dark-color">{{ 'rememberpasssword.Bienvenido a Booqlever' | translate }},</h1>
      <h3 class="d-none d-lg-flex font-weight-normal">{{ 'rememberpasssword.una solución inteligente donde centralizar todas tus reservas' | translate }}</h3>
  </div>
</div>
<div class="row mt-auto p-2">
  <div class="mr-auto p-2">
       <app-public-navigation></app-public-navigation>
  </div>
  <div class="p-2">
      <app-language-picker [extraClass]="'d-lg-none white-color'"></app-language-picker>
  </div>
</div>
