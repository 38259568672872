import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BaseService } from '../base.service';
import { Review } from '../../models/review/review.model';
import { ReviewsReport } from '../../models/review/reviews-report.model';
import { ChunkGeneric } from 'src/app/domain/views/chunk-generic.view';
import { Pagination } from 'src/app/domain/views/pagination.view';
import { TourUtils } from '../../models/tour/common/tour.utils';
import { BookEvent } from '../../models/book/book-event.model';
import { BookingUtils } from '../../models/book/common/booking.utils';
import { EventDetails } from '../../models/book/event-details.model';
import { CancelationReason } from '../../models/master/booking/cancelation-reason/cancelaltion-reason.constants';
import { BookFareEditionView } from 'src/app/domain/views/book/book-fare-tuple.view';
import { AdminTourApiEndpointsUtils } from '../../api/endpoints/admin-tour-api-endponints.utils';

@Injectable({
     providedIn: 'root'
})
export class AdminBookService extends BaseService {




     findBookEvents(day: string, countryId: number, cityId: number, tourId: number, providerId: number, page: number): Observable<ChunkGeneric<BookEvent>> {

          return super.sendGetRequestWithRefresh(AdminTourApiEndpointsUtils.getAdminFindBookEventsEndpoint(day, countryId, cityId, tourId, providerId, page), AdminTourApiEndpointsUtils.getAdminFindBookEventsEndpointAcceptVersion())
          .pipe(
               map((response: any) => {

                    const bookEvents = new Array<BookEvent>();

                    response.items.map((bookEventApi: any) => {

                         bookEvents.push(BookingUtils.getBookEventFromApi(bookEventApi));
                    })

                    return new ChunkGeneric<BookEvent>(bookEvents, new Pagination(response.total, response.currentPage, response.chunkSize));

               })
          )
     }


     findBookings(tourId: number, date: string, hour: string, keywords: string): Observable<EventDetails> {

          return super.sendGetRequestWithRefresh(AdminTourApiEndpointsUtils.getAdminFindBookingsEndpoint(tourId, date, hour, keywords), AdminTourApiEndpointsUtils.getAdminFindBookingsEndpointAcceptVersion())
          .pipe(
               map((bookEventDetailsApi: any) => {

                    return BookingUtils.getBookEventDetailsFromApi(bookEventDetailsApi);

               })
          )
     }

     findReviews(localeI18N: string, providerId: number, tourId: number, page: number): Observable<ReviewsReport> {
          return super.sendGetRequest(AdminTourApiEndpointsUtils.getAdminReviewsEndpoint(providerId, tourId, page), AdminTourApiEndpointsUtils.getAdminReviewsEndpointAcceptVersion())
          .pipe(
               map((response: any) => {


                    const reviews = new Array<Review>();
                    response.orderBookingRatingCOS.items.map((reviewApi: any) => {
                         const review: Review = new Review(
                              reviewApi.id,
                              reviewApi.name,
                              reviewApi.rating,
                              reviewApi.comments,
                              reviewApi.orderBooking.id,
                              TourUtils.getTourResumeFromApi(reviewApi.tour, localeI18N)
                         );

                         reviews.push(review);
                    });


                    const reviewsReport = new ReviewsReport(
                         response.travelers,
                         response.averageRating,
                         response.opinions,
                         new ChunkGeneric<Review>(reviews, new Pagination(response.orderBookingRatingCOS.total, response.orderBookingRatingCOS.currentPage, response.orderBookingRatingCOS.chunkSize))
                    );

                    return reviewsReport;

               })
          )
     }


     updateReview(orderBookingId: number, comments: string, rating: number): Observable<boolean> {
          ///api/admin/bookings/{{orderBookingId}}/rate
          return super.sendPutRequest(AdminTourApiEndpointsUtils.getAdminEditReviewsEndpoint(orderBookingId), AdminTourApiEndpointsUtils.getAdminEditReviewsEndpointObject(comments, rating), AdminTourApiEndpointsUtils.getAdminReviewsEndpointAcceptVersion())
          .pipe(
               map(() => {

                    return true;

               })
          )
     }


     blockEventBooking(block: boolean, tourId: number, date: string, hour: string): Observable<boolean> {

          return super.sendPostRequestWithRefresh(AdminTourApiEndpointsUtils.getAdminBlockEventBookingEndpoint(tourId, date, hour),
          AdminTourApiEndpointsUtils.getAdminBlockEventBookingEndpointObject(block),
          AdminTourApiEndpointsUtils.getAdminBlockEventBookingEndpointAcceptVersion())
          .pipe(
               map(() => {
                    return true;
               })
          )
     }



     updateEventMaxBooking(tourId: number, date: string, hour: string, maxBookings: number): Observable<boolean> {

          return super.sendPutRequestWithRefresh(AdminTourApiEndpointsUtils.getAdminEventMaxBookingEndpoint(tourId, date, hour),
          AdminTourApiEndpointsUtils.getAdminEventMaxBookingObject(maxBookings),
          AdminTourApiEndpointsUtils.getAdminEventMaxBookingEndpointAcceptVersion())
          .pipe(
               map(() => {
                    return true;
               })
          )
     }



     updateEventBookContactDetails(bookId: number, email: string, name: string, surname: string, phone: string): Observable<boolean> {

          return super.sendPutRequestWithRefresh(AdminTourApiEndpointsUtils.getAdminUpdateBookingsContactDetailsEndpoint(bookId),
          AdminTourApiEndpointsUtils.getAdminUpdateBookingsContactDetailsObject(email, name, surname, phone),
          AdminTourApiEndpointsUtils.getAdminUpdateBookingsContactDetailsEndpointAcceptVersion())
          .pipe(
               map(() => {
                    return true;
               })
          )
     }


     resendConfirmationEmail(bookId: number): Observable<boolean> {

          return super.sendPostRequestWithRefresh(AdminTourApiEndpointsUtils.getAdminResendBookConfirmationEmailEndpoint(bookId),
          {},
          AdminTourApiEndpointsUtils.getAdminResendBookConfirmationEmailEndpointAcceptVersion())
          .pipe(
               map(() => {
                    return true;
               })
          )
     }


     cancelBooking(bookId: number, message: string, cancelationReason: CancelationReason): Observable<boolean> {

          return super.sendPostRequestWithRefresh(AdminTourApiEndpointsUtils.getAdminCancelBookingEndpoint(bookId),
          AdminTourApiEndpointsUtils.getAdminCancelBookingEndpointObject(message, cancelationReason),
          AdminTourApiEndpointsUtils.getAdminCancelBookingEndpointAcceptVersion())
          .pipe(
               map(() => {
                    return true;
               })
          )
     }


     updateEventBookFareShows(bookFareEditionView: BookFareEditionView): Observable<boolean> {

          return super.sendPostRequestWithRefresh(AdminTourApiEndpointsUtils.getAdminUpdateBookingsEndpoint(bookFareEditionView.tourId, bookFareEditionView.date, bookFareEditionView.hour, bookFareEditionView.bookId),
          AdminTourApiEndpointsUtils.getAdminUpdateBookingsObject(bookFareEditionView.isChildren, bookFareEditionView.isAdult, bookFareEditionView.fareId, bookFareEditionView.shows),
          AdminTourApiEndpointsUtils.getAdminUpdateBookingsEndpointAcceptVersion())
          .pipe(
               map(() => {
                    return true;
               })
          )
     }


}
