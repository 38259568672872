export class AffiliateStats {

     private _totalCommissionAndVat: number;
     private _totalBookingsNumber: number;

     constructor(
          totalCommissionAndVat: number,
          totalBookingsNumber: number
     ) {

          this._totalCommissionAndVat = totalCommissionAndVat;
          this._totalBookingsNumber = totalBookingsNumber;

     }

     get totalCommissionAndVat(): number {
          return this._totalCommissionAndVat;
     }

     get totalBookingsNumber(): number {
          return this._totalBookingsNumber;
     }

}
