import { Pipe, PipeTransform } from '@angular/core';
import { ProviderRegistrationState } from 'src/app/business/models/provider/common/provider-registration-state.constants';
import { ProviderState } from 'src/app/business/models/provider/common/provider-state.constants';

@Pipe({
     name: 'adminProviderStateClass'
})
export class AdminProviderStateClassPipe implements PipeTransform {

     transform(classLabels: string, registrationState: ProviderRegistrationState, state: ProviderState): String {

          let result = classLabels;
          if ( registrationState == ProviderRegistrationState.RegistrationConfirmed ) {
               result = result + " " + 'normal';
          } else if ( registrationState == ProviderRegistrationState.PendingSingUp ) {
               result = result + " " + 'pending-normal';
          } else if ( registrationState == ProviderRegistrationState.PendingAproval ) {
               result = result + " " + 'pending-aproval';
          } else if ( registrationState == ProviderRegistrationState.UnregistrationRequested ) {
               result = result + " " + 'pending-remove';
          } else if ( registrationState == ProviderRegistrationState.UnregistrationConfirmed ) {
               result = result + " " + 'remove';
          }

          return result ;

     }

}
