import { TourResume } from '../tour/tour-resume.model';
import { BillingDetails } from './billing-details.model';

export class TourBilling {

  private _tourResume: TourResume;
  private _billingDetails: BillingDetails;


  constructor(
    tourResume: TourResume,
    billingDetails: BillingDetails
  ) {
    this._tourResume = tourResume;
    this._billingDetails = billingDetails;
  }

  get tourResume(): TourResume {
    return this._tourResume;
  }

  get billingDetails(): BillingDetails {
    return this._billingDetails;
  }

}
