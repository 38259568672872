import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BaseService } from '../base.service';
import { ChunkGeneric } from 'src/app/domain/views/chunk-generic.view';
import { Pagination } from 'src/app/domain/views/pagination.view';
import { BookEvent } from '../../models/book/book-event.model';
import { EventDetails } from '../../models/book/event-details.model';
import { BookFareEditionView } from 'src/app/domain/views/book/book-fare-tuple.view';
import { BookingUtils } from '../../models/book/common/booking.utils';
import { GuideApiEndpointsUtils } from '../../api/endpoints/guide-api-endponints.utils';
import { BookingGuideConfirmationState } from '../../models/book/common/booking-guide-confirmation-state.constants';

@Injectable({
     providedIn: 'root'
})
export class GuideBookService extends BaseService {


     findBookEvents(day: string, page: number): Observable<ChunkGeneric<BookEvent>> {

          return super.sendGetRequestWithRefresh(GuideApiEndpointsUtils.getGuideFindBookEventsEndpoint(day, page), GuideApiEndpointsUtils.getGuideFindBookEventsEndpointAcceptVersion())
          .pipe(
               map((response: any) => {

                    const bookEvents = new Array<BookEvent>();

                    response.items.map((bookEventApi: any) => {

                         bookEvents.push(BookingUtils.getBookEventFromApi(bookEventApi));

                    })

                    return new ChunkGeneric<BookEvent>(bookEvents, new Pagination(response.total, response.currentPage, response.chunkSize));

               })
          )
     }

     findBookings(tourId: number, date: string, hour: string, keywords: string): Observable<EventDetails> {

          return super.sendGetRequestWithRefresh(GuideApiEndpointsUtils.getGuideFindBookingsEndpoint(tourId, date, hour, keywords), GuideApiEndpointsUtils.getGuideFindBookingsEndpointAcceptVersion())
          .pipe(
               map((bookEventDetailsApi: any) => {

                    return BookingUtils.getBookEventDetailsFromApi(bookEventDetailsApi);

               })
          )
     }


     findBookEvent(tourId: number, date: string, hour: string): Observable<BookEvent> {

          return super.sendGetRequestWithRefresh(GuideApiEndpointsUtils.getGuideFindBookingsEndpoint(tourId, date, hour, null), GuideApiEndpointsUtils.getGuideFindBookingsEndpointAcceptVersion())
          .pipe(
               map((bookEventDetailsApi: any) => {

                    return BookingUtils.getBookEventFromApi(bookEventDetailsApi.eventBooking);

               })
          )
     }



     updateEventBookFareShows(bookFareEditionView: BookFareEditionView): Observable<boolean> {

          return super.sendPostRequestWithRefresh(GuideApiEndpointsUtils.getGuideUpdateBookingsEndpoint(bookFareEditionView.tourId, bookFareEditionView.date, bookFareEditionView.hour, bookFareEditionView.bookId),
          GuideApiEndpointsUtils.getGuideUpdateBookingsObject(bookFareEditionView.isChildren, bookFareEditionView.isAdult, bookFareEditionView.fareId, bookFareEditionView.shows),
          GuideApiEndpointsUtils.getGuideUpdateBookingsEndpointAcceptVersion())
          .pipe(
               map(() => {
                    return true;
               })
          )
     }


     updateEventBookAllFaresShows(tourId: number, date: string, hour: string, bookId: number, bookingGuideConfirmationState: BookingGuideConfirmationState): Observable<boolean> {

          return super.sendPostRequestWithRefresh(
               GuideApiEndpointsUtils.getGuideUpdateBookingsAllFaresEndpoint(tourId, date, hour, bookId),
               GuideApiEndpointsUtils.getGuideUpdateBookingsAllFaresObject(bookingGuideConfirmationState),
               GuideApiEndpointsUtils.getGuideUpdateBookingsAllFaresEndpointAcceptVersion())
          .pipe(
               map(() => {
                    return true;
               })
          )
     }



}
