<div *ngIf="weekday" [formGroup]="weekday" class="row">
    <div class="col-12">
        <div class="row">
            <div class="col-12">
                <label for="">{{ 'createtourdate.Start time' | translate }}</label>
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-sm-4 col-md-3 col-lg-3 mb-2" formArrayName="hours" *ngFor="let hour of hours.controls; let hourIndex=index">
                <div class="field pb-2 d-inline">
                    <input (change)="onHourUpdated(hourIndex)" [formControl]="hour" type="time" name="starTime">
                    <img *ngIf="!i18nTraslation" (click)="onHourDeleted(hourIndex)" class="ic-mid opacity-3 ml-3" src="assets/img/ic_remove.svg">
                </div>
            </div>
            <div class="col-12 col-sm-4 col-md-3 col-lg-3 mb-4">
                <a *ngIf="!i18nTraslation" (click)="onAddHour()" class="btn w-md-50 w-100 btn-tertiary mb-3 mb-sm-0 white-color">
                    <img class="ic-mid" src="assets/img/ic_add-white.svg">
                </a>
            </div>
        </div>
    </div>
</div>