import { Injectable }                       from '@angular/core';
import { ProviderGuidesManagement } from 'src/app/business/models/provider/common/provider-guides-management.constants';
import { UserRole } from 'src/app/business/models/user/common/user-role.constants';


@Injectable({
     providedIn: 'root'
})

// A class responsible for managing an user session in the Local Storage
// It would be injectable (like a service) in other components
export class SessionManager {


     private readonly CT_JWT_EMAIL = 'RFT_EMAIL';
     private readonly CT_JWT_USER_NAME = 'RFT_USER_NAME';
     private readonly CT_JWT_GUIDES_MANAGEMENT = 'RFT_GUIDES_MANAGEMENT';
     private readonly CT_JWT_USER_ID = 'RFT_USER_ID';
     private readonly CT_JWT_ROLES = 'RFT_JWT_ROLES';
     private readonly CT_JWT_STATE = 'RFT_JWT_STATE';
     private readonly CT_JWT_TOKEN = 'RFT_JWT_TOKEN';
     private readonly CT_JWT_REFRESH = 'RFT_JWT_REFRESH';


     constructor() {}


     saveCredentials(email: string, roles: Array<UserRole>, state: number, token: string, refreshToken: string) {
          localStorage.setItem(this.CT_JWT_EMAIL, email);
          localStorage.setItem(this.CT_JWT_ROLES, JSON.stringify(roles));
          localStorage.setItem(this.CT_JWT_STATE, state.toString());
          localStorage.setItem(this.CT_JWT_TOKEN, token);
          localStorage.setItem(this.CT_JWT_REFRESH, refreshToken);
     }


     updateCredentials(token: string, refreshToken: string) {
          localStorage.setItem(this.CT_JWT_TOKEN, token);
          localStorage.setItem(this.CT_JWT_REFRESH, refreshToken);
     }

     removeCredentials() {
          localStorage.removeItem(this.CT_JWT_EMAIL);
          localStorage.removeItem(this.CT_JWT_USER_NAME);
          localStorage.removeItem(this.CT_JWT_GUIDES_MANAGEMENT);
          localStorage.removeItem(this.CT_JWT_USER_ID);
          localStorage.removeItem(this.CT_JWT_ROLES);
          localStorage.removeItem(this.CT_JWT_STATE);
          localStorage.removeItem(this.CT_JWT_TOKEN);
          localStorage.removeItem(this.CT_JWT_REFRESH);
     }

     isLoggedIn(): boolean {
          if ( this.hasValidRoles() && this.getEmail() ) {
               return true;
          }

          return false;
     }

     getEmail(): string | undefined {
          return localStorage.getItem(this.CT_JWT_EMAIL);
     }

     getUserId(): number | undefined {
          const userIdAsString = localStorage.getItem(this.CT_JWT_USER_ID);
          if ( userIdAsString ) {
               const userIdAsNumber = Number(userIdAsString);
               if( !isNaN(userIdAsNumber) ) {
                    return userIdAsNumber;
               }
          }
          return undefined;
     }

     updateUserId(userId: number) {
          localStorage.setItem(this.CT_JWT_USER_ID, userId.toString());
     }

     getUserName(): string | undefined {
          return localStorage.getItem(this.CT_JWT_USER_NAME);
     }

     updateUserName(userName: string) {
          localStorage.setItem(this.CT_JWT_USER_NAME, userName);
     }

     updateGuidesManagement(guidesManagement: number | null) {
          if ( guidesManagement ) {
               localStorage.setItem(this.CT_JWT_GUIDES_MANAGEMENT, guidesManagement.toString());
          } else {
               localStorage.setItem(this.CT_JWT_GUIDES_MANAGEMENT, ProviderGuidesManagement.Disabled.toString());
          }
     }

     getGuidesManagement(): number | undefined {
          const guidesManagementAsString = localStorage.getItem(this.CT_JWT_GUIDES_MANAGEMENT);
          if ( guidesManagementAsString ) {
               const guidesManagementAsNumber = Number(guidesManagementAsString);
               if( !isNaN(guidesManagementAsNumber) ) {
                    return guidesManagementAsNumber;
               }
          }
          return undefined;
     }

     getToken(): string | undefined {
          return localStorage.getItem(this.CT_JWT_TOKEN);
     }

     getRefreshtoken(): string | undefined {
          return localStorage.getItem(this.CT_JWT_REFRESH);
     }

     private hasRole(userRole: UserRole): boolean {
          const rolesAsString = localStorage.getItem(this.CT_JWT_ROLES);
          if ( rolesAsString ) {
               return rolesAsString.includes(userRole.toString());
          }
          return false;
     }

     private hasValidRoles(): boolean {
          const rolesAsString = localStorage.getItem(this.CT_JWT_ROLES);
          return rolesAsString &&
               (rolesAsString.includes(UserRole.Admin.toString())
               || rolesAsString.includes(UserRole.Provider.toString())
               || rolesAsString.includes(UserRole.Affiliate.toString())
               || rolesAsString.includes(UserRole.Guide.toString()));
     }

     isAdminRole(): boolean {
          return this.isLoggedIn() && this.hasRole(UserRole.Admin);
     }

     isProviderRole(): boolean {
          return this.isLoggedIn() && this.hasRole(UserRole.Provider);
     }

     isAffiliateRole(): boolean {
          return this.isLoggedIn() && this.hasRole(UserRole.Affiliate);
     }

     isGuideRole(): boolean {
          return this.isLoggedIn() && this.hasRole(UserRole.Guide);
     }


}