
export class BookFareEditionView {

    private _tourId: number;
    private _date: string;
    private _hour: string;
    private _bookId: number;
    private _fareId: number;
    private _isChildren: boolean;
    private _isAdult: boolean;
    private _people: number;
    private _shows: number;

    constructor(
      tourId: number,
      date: string,
      hour: string,
      bookId: number,
      fareId: number,
      isChildren: boolean,
      isAdult: boolean,
      people: number,
      shows: number,

    ) {

        this._tourId = tourId;
        this._date = date;
        this._hour = hour;
        this._bookId = bookId;
        this._fareId = fareId;
        this._isChildren = isChildren;
        this._isAdult = isAdult;
        this._people = people;
        this._shows = shows;

    }

    get tourId(): number {
         return this._tourId;
    }

    get date(): string {
         return this._date;
    }

    get hour(): string {
         return this._hour;
    }

    get bookId(): number {
         return this._bookId;
    }

    get fareId(): number {
         return this._fareId;
    }

    get isChildren(): boolean {
         return this._isChildren;
    }

    get isAdult(): boolean {
         return this._isAdult;
    }

    get people(): number {
         return this._people;
    }

    get shows(): number {
         return this._shows;
    }

}