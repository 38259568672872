export class Language {

    private _id: number;
    private _code: string;
    private _name: string;

    constructor(
      id: number,
      code: string,
      name: string
    ) {

        this._id = id;
        this._code = code;
        this._name = name;

    }

    get id(): number {
        return this._id;
    }

    get code(): string {
        return this._code;
    }

    get name(): string {
        return this._name;
    }

}
