import { Pipe, PipeTransform } from '@angular/core';
import { marker as I18N } from '@biesbjerg/ngx-translate-extract-marker';
import { GuideState } from 'src/app/business/models/guide/common/guide-state.constants';

@Pipe({
  name: 'providerGuideStateName'
})
export class ProviderGuideStateNamePipe implements PipeTransform {

  transform(_defaultValue: string, state: GuideState): string {

    let result = _defaultValue
    if ( state == GuideState.Active ) {
        result = I18N('guidestate.Activo')
   } else if ( state == GuideState.Inactive ) {
        result = I18N('guidestate.Inactivo')
    }

    return result ;

  }

}
