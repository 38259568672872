<div *ngIf="eventDetails" class="animated fadeIn">
    <div class="row header-dashboard align-items-lg-center align-items-start">
        <div class="col-11 col-lg-6 col-xl-6 pl-0 order-1 order-lg-1">
            <h2 class="font-weight-bold">{{ eventDetails.name }} | {{ eventDetails.languageTourGiven.name }}</h2>
            <p>{{ eventDetails.day }} - {{ eventDetails.hour }}</p>
        </div>

        <!--
        <div class="col-1 col-lg-6 col-xl-3 text-right px-0 px-lg-auto order-2 order-lg-3">
            <button class="btn btn-primary" name="{{ 'listbooking.Añadir reserva' | translate }}">
                <img class="mr-lg-2" src="assets/img/ic_add-white.svg">
                <span class="d-none d-lg-inline">{{ 'listbooking.Reserva' | translate }}</span>
            </button>
        </div>
        -->
        <div class="col-12 col-lg-6 col-xl-6 text-left text-lg-right px-0 px-lg-auto mt-3 mt-lg-0 order-3 order-lg-2">
            <input [(ngModel)]="keywords" (change)="onKeywordsUpdated()" class="searcher-primary w-100 w-lg-50" type="search" placeholder="{{ 'detailbooking.Buscar reserva...' | translate }}">
        </div>
    </div>

    <div class="row dashboard-content-section">

        <div class="col-12 px-0">
             
            <app-details-booking-table *ngIf="desktopVersion"
               [eventDetails]="eventDetails"
               (updateShows)="onUpdateShows($event)"
               (updateContactDetails)="onUpdateContactDetails($event)"
               (resendConfirmationEmail)="onResendConfirmationEmail($event)"
               (cancelBooking)="onCancelBooking($event)"></app-details-booking-table>

            <app-details-booking-card *ngIf="!desktopVersion"
               [eventDetails]="eventDetails"
               (updateShows)="onUpdateShows($event)"
               (updateContactDetails)="onUpdateContactDetails($event)"
               (resendConfirmationEmail)="onResendConfirmationEmail($event)"
               (cancelBooking)="onCancelBooking($event)"></app-details-booking-card>
        </div>

    </div>

</div>


<app-message-panel [preloading]="true"></app-message-panel>


<app-window-size-controller [initialSize]="initialWindowSize" (isDesktopVersion)="onDesktopVersionUpdated($event)"></app-window-size-controller>