import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { BookEvent } from 'src/app/business/models/book/book-event.model';
import { GenericException } from 'src/app/domain/exceptions/generic.exception';
import { AdminBookInteractor } from 'src/app/domain/interactor/admin/book.interactor';
import { AdminProviderInteractor } from 'src/app/domain/interactor/admin/provider.interactor';
import { ChunkGeneric } from 'src/app/domain/views/chunk-generic.view';
import { Pagination } from 'src/app/domain/views/pagination.view';
import { NavigationConstants } from 'src/app/ui/commons/navigation.constants';
import { MessagePanelComponent } from 'src/app/ui/components/shared/message-panel/message-panel.component';
import { ServerErrorManager } from 'src/app/ui/components/utils/server-error-manager.utils';
import { marker as I18N } from '@biesbjerg/ngx-translate-extract-marker';
import { CompanyName } from 'src/app/business/models/master/company/company-name.model';
import { TranslateService } from '@ngx-translate/core';



@Component({
     selector: 'app-admin-list-booking',
     templateUrl: './list.component.html'
})
export class AdminListBookingComponent implements OnInit {

     @ViewChild(MessagePanelComponent)
     private messagePanelComponent: MessagePanelComponent;

     pagination: Pagination;
     pageNumber: number;

     dateAsString: string;
     countryId: number;
     cityId: number;
     tourId: number;
     providerId: number;

     providerNames: Array<CompanyName>;
     bookEvents: Array<BookEvent>;

     noResults: boolean;

     constructor(
          private router: Router,
          private adminBookInteractor: AdminBookInteractor,
          private serverErrorManager: ServerErrorManager,
          private translateService: TranslateService,
          private adminProviderInteractor: AdminProviderInteractor
     ) {

          this.pagination = new Pagination(0, 1, 20);
          this.pageNumber = this.pagination.currentPage;

          this.noResults = true;
     }

     ngOnInit() {
          this.adminProviderInteractor.getProvidersNames()
               .subscribe(
                    (providerNames: Array<CompanyName>) => {
                         this.providerNames = providerNames;
                    },
                    (error: GenericException) => {
                         this.serverErrorManager.manageErrorCode(error, this.messagePanelComponent);
                    }
               )
     }


     findBookEvents() {

          if (this.messagePanelComponent) {
               this.messagePanelComponent.showLoading();
          }

          this.adminBookInteractor.findBookEvents(this.dateAsString, this.countryId, this.cityId, this.tourId, this.providerId, this.pageNumber)
          .subscribe(
               (chunkTours: ChunkGeneric<BookEvent>) => {
                    this.pagination = new Pagination(chunkTours.pagination.totalItems, chunkTours.pagination.currentPage, chunkTours.pagination.itemsPerPage);
                    this.bookEvents = chunkTours.items;
                    this.noResults = this.bookEvents.length == 0;

                    MessagePanelComponent.hideLoading();
               },
               (error: GenericException) => {
                    MessagePanelComponent.hideLoading();
                    this.serverErrorManager.manageErrorCode(error, this.messagePanelComponent);
               }
          )
     }


     onDateAsStringSelected(dateAsString: string) {

          this.dateAsString = dateAsString;
          this.findBookEvents();
     }

     onCountryIdSelected(countryId: number) {
          this.countryId = countryId;
          this.findBookEvents();
     }

     onCityIdSelected(cityId: number) {
          this.cityId = cityId;
          this.findBookEvents();
     }

     onTourIdSelected(tourId: number) {
          this.tourId = tourId;
          this.findBookEvents();
     }

     onProviderIdSelected(providerId: number) {
          this.providerId = providerId;
          this.findBookEvents();
     }

     onPageNumberSelected(pageNumber: number) {
          this.pageNumber = pageNumber;
          this.findBookEvents();
     }

     onViewBookDetails(bookEvent: BookEvent) {

          this.router.navigateByUrl(NavigationConstants.redirectUrl(NavigationConstants.getNavigationAdminDetailBooking(bookEvent.event.tourId, bookEvent.event.day, bookEvent.event.hour)));

     }

     onAllowBooking(bookEvent: BookEvent) {

          this.messagePanelComponent.showLoading();

          this.adminBookInteractor.allowEventBooking(bookEvent.event.tourId, bookEvent.event.day, bookEvent.event.hour)
          .subscribe(
               () => {
                    this.messagePanelComponent.showSuccessMessage(I18N('bookEvent.Se han abierto las reservas para este evento.'), () => this.findBookEvents());
               }, (error: GenericException) => {
                    this.serverErrorManager.manageErrorCode(error, this.messagePanelComponent);
               }
          )
     }
     onDisallowBooking(bookEvent: BookEvent) {

          this.messagePanelComponent.showLoading();

          this.adminBookInteractor.disallowEventBooking(bookEvent.event.tourId, bookEvent.event.day, bookEvent.event.hour)
          .subscribe(
               () => {
                    this.messagePanelComponent.showSuccessMessage(I18N('bookEvent.Se han cerrado las reservas para este evento.'), () => this.findBookEvents());
               }, (error: GenericException) => {
                    this.serverErrorManager.manageErrorCode(error, this.messagePanelComponent);
               }
          )
     }


     onShowUpdateMaxBookings(bookEvent: BookEvent) {
          this.translateService.get(I18N('bookEvent.Deja en blanco para restaurar el valor por defecto'), {})
          .subscribe(
               (updateMaxBookingsExtraInfoTranslated: string) => {
                    this.translateService.get(I18N('bookEvent.Modifica la capacidad del evento'), {})
                    .subscribe(
                         (updateMaxBookingsMessageTranslated: string) => {
                              this.messagePanelComponent.showInputMessage(
                                updateMaxBookingsMessageTranslated,
                                updateMaxBookingsExtraInfoTranslated,
                                MessagePanelComponent.MESSAGE_INPUT_TYPE_NUMBER,
                                bookEvent.capacity.toString(),
                                null,
                                (value: string) => {
                                     this.messagePanelComponent.showLoading();

                                     var valueNumeric: number = null;
                                     if ( value.trim().length != 0 ) {
                                          valueNumeric = Number(value);
                                     }

                                     this.adminBookInteractor.updateEventMaxBooking(bookEvent.event.tourId, bookEvent.event.day, bookEvent.event.hour, valueNumeric)
                                     .subscribe(
                                          () => {
                                               this.messagePanelComponent.showSuccessMessage(I18N('bookEvent.Se ha actualizado la capacidad de este evento.'), () => this.findBookEvents());
                                          }, (error: GenericException) => {
                                               MessagePanelComponent.hideLoading();
                                               this.serverErrorManager.manageErrorCode(error, this.messagePanelComponent);
                                          }
                                     )

                                }
                            )
                        }
                    )
               }
          )
     }


}
