import { Component, ViewChild } from '@angular/core';
import { AdminAffiliateInteractor } from 'src/app/domain/interactor/admin/affiliate.interactor';
import { ActivatedRoute } from '@angular/router';
import { NavigationConstants } from 'src/app/ui/commons/navigation.constants';
import { MessagePanelComponent } from 'src/app/ui/components/shared/message-panel/message-panel.component';
import { marker as I18N } from '@biesbjerg/ngx-translate-extract-marker';
import { ServerErrorManager } from 'src/app/ui/components/utils/server-error-manager.utils';
import { GenericException } from 'src/app/domain/exceptions/generic.exception';
import { TranslateService } from '@ngx-translate/core';
import { NavigatorUtils } from 'src/app/ui/utils/navigator.utils';
import { AppConstants } from 'src/app/domain/common/app.constants';
import { Affiliate } from 'src/app/business/models/affiliate/affiliate.model';
import { ProfilePaymentView } from 'src/app/domain/views/profile-payment.view';
import { AffiliateDetailsView } from 'src/app/domain/views/affiliate/affiliate-details.view';
import { AffiliateState } from 'src/app/business/models/affiliate/common/affiliate-state.constants';

@Component({
  selector: 'app-detail-affiliate',
  templateUrl: './detail.component.html'
})
export class DetailAffiliateComponent {

  @ViewChild(MessagePanelComponent)
  private messagePanelComponent: MessagePanelComponent;

  affiliate: Affiliate;
  isStatePending: boolean;

  constructor(
    private adminAffiliateInteractor: AdminAffiliateInteractor,
    private activatedRoute: ActivatedRoute,
    private serverErrorManager: ServerErrorManager,
    private translateService: TranslateService) {


    this.activatedRoute.params.subscribe(params => {
      const affiliateId = params[NavigationConstants.NAVIGATION_ADMIN_AFFILIATE_DETAIL_ID]
      this.getAffiliateDetails(affiliateId)
    });
  }





  getAffiliateDetails(affiliateId: number) {

    this.adminAffiliateInteractor.getAffiliateDetails(affiliateId)
      .subscribe(
        (response: Affiliate) => {
          this.affiliate = response;

          MessagePanelComponent.hideLoading();

          this.isStatePending = this.affiliate.state == AffiliateState.Inactive;

        },
        (error: GenericException) => {

          MessagePanelComponent.hideLoading();
          this.serverErrorManager.manageErrorCode(error, this.messagePanelComponent);

        }
      )
  }

  doUnregisterAffiliate() {

    this.messagePanelComponent.showLoading();
    this.adminAffiliateInteractor.unregisterAffiliate(this.affiliate.id)
      .subscribe(
        () => {
          MessagePanelComponent.hideLoading();
          this.messagePanelComponent.showSuccessMessage(I18N('affiliatedetails.Afiliado dado de baja y notificado por correo.'), () => this.getAffiliateDetails(this.affiliate.id));

        }, (error: GenericException) => {

          MessagePanelComponent.hideLoading();
          this.serverErrorManager.manageErrorCode(error, this.messagePanelComponent);
        }
      )
  }


  doRegisterAffiliate() {
    this.messagePanelComponent.showLoading();
    this.adminAffiliateInteractor.registerAffiliate(this.affiliate.id)
      .subscribe(
        () => {
          MessagePanelComponent.hideLoading();
          this.messagePanelComponent.showSuccessMessage(I18N('affiliatedetails.Afiliado dado de alta correctamente.'), () => this.getAffiliateDetails(this.affiliate.id));

        }, (error: GenericException) => {

          MessagePanelComponent.hideLoading();
          this.serverErrorManager.manageErrorCode(error, this.messagePanelComponent);
        }
      )
  }


  onAffiliateDetailsSubmit(affiliateDetails: AffiliateDetailsView) {

    this.messagePanelComponent.showLoading();
    this.adminAffiliateInteractor.updateAffiliateDetails(affiliateDetails)
      .subscribe(
        () => {
          MessagePanelComponent.hideLoading();
          this.messagePanelComponent.showSuccessMessage(I18N('affiliatedetails.Datos actualizados'));

        }, (error: GenericException) => {

          MessagePanelComponent.hideLoading();
          this.serverErrorManager.manageErrorCode(error, this.messagePanelComponent);
        }
      )
  }


  onCreateConnectedStripeAccount() {
    this.messagePanelComponent.showLoading();

    this.adminAffiliateInteractor.createStripeAccount(this.affiliate.id)
      .subscribe(
        () => {
          MessagePanelComponent.hideLoading();
          this.messagePanelComponent.showSuccessMessage(
            I18N('affiliatedetails.Cuenta de Stripe creada correctamente. A continuación será necesario que aceptes la vinculación de la cuenta para recibir pagos.')
            , () => {
              NavigatorUtils.reloadApplication();
            }
          );

        }, (error: GenericException) => {

          MessagePanelComponent.hideLoading();
          this.serverErrorManager.manageErrorCode(error, this.messagePanelComponent);
        }
      )
  }


  onRemoveStripeAccount() {
    this.messagePanelComponent.showLoading();

    this.adminAffiliateInteractor.removeStripeAccount(this.affiliate.id)
      .subscribe(
        () => {
          MessagePanelComponent.hideLoading();
          this.messagePanelComponent.showSuccessMessage(
            I18N('affiliatedetails.Cuenta de Stripe desvinvulada correctamente.')
            , () => {
              NavigatorUtils.reloadApplication();
            }
          );

        }, (error: GenericException) => {

          MessagePanelComponent.hideLoading();
          this.serverErrorManager.manageErrorCode(error, this.messagePanelComponent);
        }
      )
  }

  onUpdateStripeAccount() {

    this.messagePanelComponent.showLoading();

    this.translateService.get('affiliatedetails.Actualiza tu información de Stripe').subscribe(
      (titleMessageTranslated: string) => {
        this.translateService.get(I18N('affiliatedetails.Indica el ID de tu cuenta de Stripe conectada'), {})
          .subscribe(
            (subtitleMessageTranslated: string) => {
              this.translateService.get(I18N('affiliatedetails.Texto demasiado largo'), {})
                .subscribe(
                  (validationTooLongMessageTranslated: string) => {

                    MessagePanelComponent.hideLoading();

                    this.messagePanelComponent.showInputMessage(
                      titleMessageTranslated,
                      subtitleMessageTranslated,
                      MessagePanelComponent.MESSAGE_INPUT_TYPE_TEXT,
                      null,
                      (stripeAccountId: string) => {
                        if (stripeAccountId.length > AppConstants.TEXT_DEFAULT_MAX_LENGTH) {
                          return validationTooLongMessageTranslated;
                        }
                      },
                      (stripeAccountId: string) => {

                        this.messagePanelComponent.showLoading();

                        this.adminAffiliateInteractor.updateStripeAccount(this.affiliate.id, stripeAccountId)
                          .subscribe(
                            () => {

                              MessagePanelComponent.hideLoading();
                              this.messagePanelComponent.showSuccessMessage(I18N('affiliatedetails.Información actualizada'), () => { NavigatorUtils.reloadApplication(); });

                            },
                            (error: GenericException) => {
                              MessagePanelComponent.hideLoading();
                              this.serverErrorManager.manageErrorCode(error, this.messagePanelComponent);
                            }
                          )
                      }
                    )

                  })
            })
      })
  }




}
