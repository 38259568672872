import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BaseService } from '../base.service';
import { Language } from '../../models/master/language/language.model';
import { Country } from '../../models/master/country/country.model';
import { City } from '../../models/master/city/city.model';
import { CommonApiEndpointsUtils } from '../../api/endpoints/common-api-endponints.utils';

@Injectable({
  providedIn: 'root'
})
export class MasterService extends BaseService {


  getLanguages(languageCode: string): Observable<Array<Language>> {
    return super.sendGetRequest(CommonApiEndpointsUtils.getLanguagesByLanguage(languageCode), CommonApiEndpointsUtils.getLanguagesByLanguageAcceptVersion())
      .pipe(
        map((response: any) => {

          const languages: Array<Language> = response.map((language: any) => {
            return new Language(
              language.id,
              language.code,
              language.name
            )
          })

          return languages;
        })
      )
  }


  getCountries(languageId: number): Observable<Array<Country>> {
    return super.sendGetRequest(CommonApiEndpointsUtils.getCountriesByLanguage(languageId), CommonApiEndpointsUtils.getCountriesByLanguageAcceptVersion())
      .pipe(
        map((response: any) => {

          const country: Array<Country> = response.map((country: any) => {
            return new Country(
              country.id,
              country.code,
              country.name
            )
          })

          return country;
        })
      )
  }


  getCitiesByCountry(countryId: number, languageId: number): Observable<Array<City>> {
    return super.sendGetRequest(CommonApiEndpointsUtils.getCityByCountryAndLanguage(countryId, languageId), CommonApiEndpointsUtils.getCityByCountryAndLanguageAcceptVersion())
      .pipe(
        map((response: any) => {

          const city: Array<City> = response.map((city: any) => {
            return new City(
              city.id,
              city.name
            )
          })

          return city;
        })
      )
  }


  getPaymentCurrencies(): Observable<Array<string>> {
    return super.sendGetRequest(CommonApiEndpointsUtils.getPaymentCurrenciesEndpoint(), CommonApiEndpointsUtils.getPaymentCurrenciesEndpointAcceptVersion())
      .pipe(
        map((response: any) => {

          const currencies: Array<string> = response.map((currency: any) => {
               return currency;
          })

          return currencies;
        })
      )
  }



}