import { Component, ViewChild }         from '@angular/core';
import { FormGroup }                    from '@angular/forms';
import { ActivatedRoute }               from '@angular/router';
import { marker as I18N }               from '@biesbjerg/ngx-translate-extract-marker';

import { AdminTourInteractor }          from 'src/app/domain/interactor/admin/tour.interactor';
import { ImageDetails }                 from 'src/app/business/models/image/image-details.model';
import { ImageSize }                    from 'src/app/business/models/image/common/image-size.constants';

import { AppConstants }                 from 'src/app/domain/common/app.constants';
import { NavigationConstants }          from 'src/app/ui/commons/navigation.constants';
import { MessagePanelComponent }        from 'src/app/ui/components/shared/message-panel/message-panel.component';
import { ServerErrorManager }           from 'src/app/ui/components/utils/server-error-manager.utils';
import { GenericException } from 'src/app/domain/exceptions/generic.exception';


@Component({
  selector: 'app-tour-gallery-managment',
  templateUrl: './gallery-managment.component.html'
})
export class AdminTourGalleryManagmentComponent {

    @ViewChild(MessagePanelComponent)
    private messagePanelComponent: MessagePanelComponent;

    galleryManagment: FormGroup;
    fileInputError: string;
    tourId: number;

    tourImages: Array<ImageDetails>;

    imageMediumSize: ImageSize = ImageSize.Medium;

    constructor(

      private adminTourInteractor: AdminTourInteractor,
      private activatedRoute: ActivatedRoute,
      private serverErrorManager: ServerErrorManager) {

      this.activatedRoute.params.subscribe( params => {
          this.tourId = params[ NavigationConstants.NAVIGATION_ADMIN_TOUR_GALLLERY_MANAGMENT_ID ];
          this.adminTourInteractor.getTourImages(this.tourId)
            .subscribe(
                (tourImages: Array<ImageDetails>) => {
                    this.tourImages = tourImages;
                },
                (error: GenericException) => {
                    this.serverErrorManager.manageErrorCode(error, this.messagePanelComponent);
                }
            )
      });

    }


    onImageSelected(event: any) {
        this.fileInputError = null;
        if ( event.target.files.length > 0 ) {
            if ( this.tourImages.length >= AppConstants.TOUR_GALLERY_MAX_IMAGES_NUMBER ) {
              this.fileInputError = I18N('tourgallery.Has añadido el máximo de imágenes para un tour.');
            } else {
                const image: File = event.target.files[0];
                if ( image.size > AppConstants.MAX_FILE_UPLOAD_SIZE ) {
                    this.fileInputError = I18N('tourgallery.El tamaño máximo de archivo son 8 MB');
                } else {
                    this.messagePanelComponent.showLoading();
                    this.adminTourInteractor.uploadImageTour(this.tourId, image)
                        .subscribe(
                            (tourImage: ImageDetails) => {
                                MessagePanelComponent.hideLoading();
                                this.tourImages.push(tourImage);
                            },
                            (error: GenericException) => {
                                MessagePanelComponent.hideLoading();
                                this.serverErrorManager.manageErrorCode(error, this.messagePanelComponent);
                            }
                        )
                }
            }
        }
    }


    onDeleteImage(imageId: number) {
        this.messagePanelComponent.showLoading();
        this.adminTourInteractor.deleteTourImage(this.tourId, imageId)
            .subscribe(
                () => {
                    this.tourImages = this.tourImages.filter( (tourImage: ImageDetails) => {
                        return tourImage.id != imageId;
                    });
                    MessagePanelComponent.hideLoading();
                },
                (error: GenericException) => {
                    MessagePanelComponent.hideLoading();
                    this.serverErrorManager.manageErrorCode(error, this.messagePanelComponent);
                }
            )
    }

}
