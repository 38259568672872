import { TourFareFormView } from './fare-form.view';

export class TourUsersFormView {


    private _minPax: number;
    private _maxPax: number;
    private _cancellationLimit: number;
    private _bookingLimit: number;
    private _maxPeoplePerBooking: number;
    private _isFree: boolean;
    private _maxFreePeople: number;
    private _maxFreePeoplePrice: number;
    private _maxFreePeopleAge: number;
    private _fares: Array<TourFareFormView>

    constructor(
      minPax: number,
      maxPax: number,
      cancellationLimit: number,
      bookingLimit: number,
      maxPeoplePerBooking: number,
      isFree: boolean,
      maxFreePeople: number,
      maxFreePeoplePrice: number,
      maxFreePeopleAge: number,
      fares: Array<TourFareFormView>
    ) {

        this._minPax = minPax;
        this._maxPax = maxPax;
        this._cancellationLimit = cancellationLimit;
        this._bookingLimit = bookingLimit;
        this._maxPeoplePerBooking = maxPeoplePerBooking;
        this._isFree = isFree;
        this._maxFreePeople = maxFreePeople;
        this._maxFreePeoplePrice = maxFreePeoplePrice;
        this._maxFreePeopleAge = maxFreePeopleAge;
        this._fares = fares;

    }


    get minPax(): number {
        return this._minPax;
    }

    get maxPax(): number {
        return this._maxPax;
    }

    get cancellationLimit(): number {
        return this._cancellationLimit;
    }

    get bookingLimit(): number {
        return this._bookingLimit;
    }

    get maxPeoplePerBooking(): number {
        return this._maxPeoplePerBooking;
    }

    get isFree(): boolean {
        return this._isFree;
    }

    get maxFreePeople(): number {
        return this._maxFreePeople;
    }

    get maxFreePeoplePrice(): number {
        return this._maxFreePeoplePrice;
    }

    get maxFreePeopleAge(): number {
        return this._maxFreePeopleAge;
    }

    get fares(): Array<TourFareFormView> {
        return this._fares;
    }

}