import { TourSchedulePlanningFormView } from './schedule-planning-form.view';
import { TourScheduleSeasonFormView } from './schedule-season-form.view';

export class TourScheduleFormView {


    private _type: number;
    private _fixed: TourSchedulePlanningFormView;
    private _seasons: Array<TourScheduleSeasonFormView>;

    constructor(
      type: number,
      fixed: TourSchedulePlanningFormView,
      seasons: Array<TourScheduleSeasonFormView>
    ) {

        this._type = type;
        this._fixed = fixed;
        this._seasons = seasons;

    }


    get type(): number {
        return this._type;
    }

    get fixed(): TourSchedulePlanningFormView {
        return this._fixed;
    }

    get seasons(): Array<TourScheduleSeasonFormView> {
        return this._seasons;
    }

}