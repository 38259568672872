import { Component, OnInit, ViewChild } from '@angular/core';
import { MessagePanelComponent } from 'src/app/ui/components/shared/message-panel/message-panel.component';
import { Router } from '@angular/router';
import { AdminProviderInteractor } from 'src/app/domain/interactor/admin/provider.interactor';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { NavigationConstants } from 'src/app/ui/commons/navigation.constants';
import { AppConstants } from 'src/app/domain/common/app.constants';
import { Language } from 'src/app/business/models/master/language/language.model';
import { marker as I18N } from '@biesbjerg/ngx-translate-extract-marker';
import { ServerErrorManager } from 'src/app/ui/components/utils/server-error-manager.utils';
import { ServerErrorExceptionCase } from 'src/app/domain/views/server-error-manager-exception-case.view';
import { GenericException } from 'src/app/domain/exceptions/generic.exception';
import { UserMasterInteractor } from 'src/app/domain/interactor/user/user-master.interactor';

@Component({
  selector: 'app-create-provider',
  templateUrl: './create.component.html'
})
export class CreateProviderComponent implements OnInit {

  @ViewChild(MessagePanelComponent)
  private messagePanelComponent: MessagePanelComponent;

  createProviderForm: FormGroup;
  languages: Array<Language>;
  refreshValidators: boolean;

  constructor(
    private adminProviderInteractor: AdminProviderInteractor,
    private userMasterInteractor: UserMasterInteractor,
    private router: Router,
    private serverErrorManager: ServerErrorManager ) {

      this.refreshValidators = false;

      this.createProviderForm = new FormGroup({
        'companyName': new FormControl('', [Validators.required, Validators.maxLength(AppConstants.TEXT_DEFAULT_MAX_LENGTH)] ),
        'email':  new FormControl(
                  '',
                  [
                    Validators.required,
                    Validators.email,
                    Validators.maxLength(AppConstants.TEXT_DEFAULT_MAX_LENGTH)
                  ]),
        'language': new FormControl('', [Validators.required] )
      });

      this.languages = new Array()
  }

  ngOnInit() {

      this.userMasterInteractor.getLanguagesByUser()
        .subscribe(
            (languages: Array<Language>) => {
                this.languages = languages
            },
            (error: GenericException) => {
                this.serverErrorManager.manageErrorCode(error, this.messagePanelComponent);
            }
        )
  }

  createProvider() {

    this.refreshValidators = true;

    if ( this.createProviderForm.valid ) {

      this.messagePanelComponent.showLoading();

      this.adminProviderInteractor.createProvider(this.createProviderForm.value.companyName, this.createProviderForm.value.email, this.createProviderForm.value.language)
          .subscribe(
              (success: boolean) => {

                  MessagePanelComponent.hideLoading();

                  if ( success ) {
                      this.messagePanelComponent.showSuccessMessage(I18N('createprovider.Proveedor creado correctamente'), () => this.goToProviders());
                  } else {
                      this.messagePanelComponent.showErrorMessage(I18N('createprovider.No ha sido posible crear el proveedor'));
                  }
              },
              (error: GenericException) => {

                  MessagePanelComponent.hideLoading();

                  const exceptionCases = new Array<ServerErrorExceptionCase>();
                  const exceptionCodes = new Array<number>();
                  exceptionCodes.push(AppConstants.ERROR_CLIENT_BAD_REQUEST);
                  exceptionCases.push( new ServerErrorExceptionCase(
                      exceptionCodes,
                      new Array<string>(),
                      () => {
                          this.messagePanelComponent.showErrorMessage(I18N('createprovider.La cuenta de correo ya existe'))
                      }
                  ));

                  this.serverErrorManager.manageErrorCode(error, this.messagePanelComponent, exceptionCases);

              }
          )
    }


  }

  goToProviders() {
      this.router.navigate( [ NavigationConstants.getNavigationAdminListProviders() ] );
  }



}
