export class ApiConstants {


     public static ERROR_SERVER_UNKNOWN  = 0;
     public static ERROR_CLIENT_BAD_CREDENTIALS  = 999;
     public static ERROR_CLIENT_BAD_REQUEST      = 400;
     public static ERROR_CLIENT_UNAUTHORIZED     = 401;  // Access denied
     public static ERROR_CLIENT_FORBIDDEN        = 403;
     public static ERROR_CLIENT_NOT_FOUND        = 404;
     public static ERROR_SERVER_FAILURE          = 500;


     public static API_USER_SCOPE_ADMIN = 'ADMIN_A';
     public static API_USER_SCOPE_PROVIDER = 'PROVIDER_A';
     public static API_USER_SCOPE_AFFILIATE = 'AFFILIATE_A';
     public static API_USER_SCOPE_GUIDE = 'GUIDE_A';


     //PROVIDER STATE USER
     public static API_PROVIDER_STATE_INACTIVE = 0;
     public static API_PROVIDER_STATE_ACTIVE = 1;

     //PROVIDER STATE REGISTRATION STEP
     public static API_PROVIDER_STATE_REGISTRATION_PENDING_SIGN_UP = 0;
     public static API_PROVIDER_STATE_REGISTRATION_PENDING_APPROVAL = 1;
     public static API_PROVIDER_STATE_REGISTRATION_CONFIRMED = 2;
     public static API_PROVIDER_STATE_UNREGISTRATION_REQUESTED = 3;
     public static API_PROVIDER_STATE_UNREGISTRATION_CONFIRMED = 4;


     //PROVIDER GUIDES MANAGEMENT TYPES
     public static API_PROVIDER_GUIDES_MANAGEMENT_DISABLED = 0;
     public static API_PROVIDER_GUIDES_MANAGEMENT_FULL = 1;


     //AFFILIATE STATE USER
     public static API_AFFILIATE_STATE_INACTIVE = 0;
     public static API_AFFILIATE_STATE_ACTIVE = 1;

     //AFFILIATE STATE REGISTRATION STEP
     public static API_AFFILIATE_STATE_REGISTRATION_PENDING_SIGN_UP = 0;
     public static API_AFFILIATE_STATE_REGISTRATION_PENDING_APPROVAL = 1;
     public static API_AFFILIATE_STATE_REGISTRATION_CONFIRMED = 2;
     public static API_AFFILIATE_STATE_UNREGISTRATION_REQUESTED = 3;
     public static API_AFFILIATE_STATE_UNREGISTRATION_CONFIRMED = 4;


     //GUIDE STATE USER
     public static API_GUIDE_STATE_INACTIVE = 0;
     public static API_GUIDE_STATE_ACTIVE = 1;



     //GUIDE STATE USER
     public static API_GUIDE_COMMISSION_TYPE_PEOPLE = 0;
     public static API_GUIDE_COMMISSION_TYPE_TOTAL = 1;

     //GUIDE SERVICE STATE
     public static API_GUIDE_SERVICE_STATE_PENDING = 0;
     public static API_GUIDE_SERVICE_STATE_APPROVED = 1;
     public static API_GUIDE_SERVICE_STATE_BILLED = 2;
     public static API_GUIDE_SERVICE_STATE_DELETED = 3;



     public static API_CANCELATION_REASON_GUIDE_PROBLEM = -3;
     public static API_CANCELATION_REASON_DUPLICATED = -4;
     public static API_CANCELATION_REASON_FRAUDULENT = -5;
     public static API_CANCELATION_REASON_CUSTOMER_REQUEST = -6;



     public static API_BOOKING_STATE_PENDING = 0;
     public static API_BOOKING_STATE_CONFIRMED = 1;
     public static API_BOOKING_STATE_CANCELED_BY_USER = -1;
     public static API_BOOKING_STATE_CANCELED_BY_PROVIDER = -2;
     public static API_BOOKING_STATE_CANCELED_BY_PROVIDER_GUIDE_PROBLEM = -3;
     public static API_BOOKING_STATE_CANCELED_BY_PROVIDER_DUPLICATED_BOOKING = -4;
     public static API_BOOKING_STATE_CANCELED_BY_PROVIDER_FRAUD = -5;
     public static API_BOOKING_STATE_CANCELED_BY_PROVIDER_IN_THE_NAME_OF_CUSTOMER = -6;


     public static API_BOOKING_GUIDE_CONFIRMATION_STATE_SHOWED = 1;
     public static API_BOOKING_GUIDE_CONFIRMATION_STATE_NOT_SHOWED = 2;

     public static API_AFFILIATE_COMMISSION_STATE_CONFIRMED = 1;
     public static API_AFFILIATE_COMMISSION_STATE_CANCELED = -1;


     public static API_GUIDE_COMMISSION_STATE_CONFIRMED = 1;
     public static API_GUIDE_COMMISSION_STATE_PENDING = 2;
     public static API_GUIDE_COMMISSION_STATE_CANCELED = -1;

     public static API_ORDER_BOOKING_STATE_PENDING = 0;
     public static API_ORDER_BOOKING_STATE_COMPLETED = 1;

     public static API_BOOK_PAYMENT_STATE_PENDING = 0;
     public static API_BOOK_PAYMENT_STATE_COMPLETED = 1;

     public static API_TOUR_SCHEDULE_TYPE_FIXED = 0;
     public static API_TOUR_SCHEDULE_TYPE_SEASON = 1;

     public static API_TOUR_STATE_ACTIVE = 0;
     public static API_TOUR_STATE_INACTIVE = 1;

     public static API_IMAGE_SIZE_ORIGINAL = 'ORIGINAL';
     public static API_IMAGE_SIZE_BIG = 'BIG';
     public static API_IMAGE_SIZE_MEDIUM = 'MEDIUM';
     public static API_IMAGE_SIZE_SMALL = 'SMALL';


     public static API_INVOICE_STATE_INVOICED = 'INVOICED';
     public static API_INVOICE_STATE_PENDING = 'PENDING';



     //PAYMENT PLATFORM
     public static API_PAYMENT_PLATFORM_STRIPE = 1;
     public static API_PAYMENT_PLATFORM_WISE = 2;
     public static API_PAYMENT_PLATFORM_TRANSFERENCE = 3;



     public static API_SCHEDULE_WEEKDAY_MO = 2;
     public static API_SCHEDULE_WEEKDAY_TU = 3;
     public static API_SCHEDULE_WEEKDAY_WE = 4;
     public static API_SCHEDULE_WEEKDAY_TH = 5;
     public static API_SCHEDULE_WEEKDAY_FR = 6;
     public static API_SCHEDULE_WEEKDAY_SA = 7;
     public static API_SCHEDULE_WEEKDAY_SU = 1;


     public static API_DATE_FORMAT_DD_MM_YYYY = "DD-MM-YYYY";


     public static API_HEADER_ACCEPT = "Accept";
     public static API_HEADER_ACCEPT_V_1_0_1 = "application/vnd.reservafreetour.v1.0.1+json";
     public static API_HEADER_ACCEPT_V_1_0_1_PDF = "application/vnd.reservafreetour.v1.0.1+pdf";


     public static API_ENDPOINTS_CLIENT_ANGULAR_PATH = "clientangular";



     public static API_ERROR_CAUSE_BILLING_PAYMENT_PROVIDER_NOT_STRIPE_ACCOUNT = 'BILLING_PAYMENT_PROVIDER_NOT_STRIPE_ACCOUNT';
     public static API_ERROR_CAUSE_DUPLICATE_INSTANCE_FOUND = 'DUPLICATE_INSTANCE_FOUND';
     public static PROFILE_PAYMENT_PLATFORM_INVALID_CHANGE = 'PROFILE_PAYMENT_PLATFORM_INVALID_CHANGE';




}