import { Component, Input, EventEmitter, Output } from '@angular/core';
import { FormArray, FormGroup, FormControl } from '@angular/forms';

@Component({
  selector: 'app-create-tour-schedule-weekday-hours',
  templateUrl: './weekday-hours.component.html'
})
export class CreateTourScheduleWeekdayHoursComponent {

  @Output() weekdayHoursDefined = new EventEmitter<boolean>(true);

  @Output() addHourEvent = new EventEmitter<boolean>(true);


  i18nTraslation: boolean;

  @Input()
  set isI18nTraslation(isI18nTraslation: boolean) {
      this.i18nTraslation = isI18nTraslation;
  }


  weekday: FormGroup;

  @Input()
  set weekdayFormGroup(weekday: FormGroup) {

      if ( weekday ) {

          this.weekday = weekday;

          let filteredEmptyControls = this.weekday.get('hours') as FormArray;
          filteredEmptyControls.controls = filteredEmptyControls.controls.filter( function(control: FormControl) {
              return control.value != '';
          });

          if ( this.i18nTraslation ) {
              filteredEmptyControls.controls.map( (control: FormControl) => {
                  control.disable();
              })
          }


          if ( !this.i18nTraslation && filteredEmptyControls.controls.length == 0) {
              this.onAddHour();
          }
      }

  }

  constructor() {
  }

  get hours(): FormArray {
      return this.weekday.get('hours') as FormArray;
  }

  onAddHour() {
      let existEmptyHours = false;
      this.hours.controls.map( (hourControl: FormControl) => {
          if ( hourControl.value == '' ) {
              existEmptyHours = true;
          }
      })
      if ( !existEmptyHours ) {
          this.hours.push(new FormControl(''));
      }
  }

  onHourUpdated(hourIndex: number) {

      //Remove if not the last item
      if (this.hours.length > 1 && this.hours.at(hourIndex).value == '') {
          this.hours.removeAt(hourIndex);
      }

      this.weekdayHoursDefined.emit(this.checkHoursHasContent());

  }

  onHourDeleted(hourIndex: number) {

      //Remove if not the last item and rest otherwise
      if (this.hours.length == 1) {
          this.hours.at(hourIndex).setValue('');
      } else {
          this.hours.removeAt(hourIndex);
      }

      this.weekdayHoursDefined.emit(this.checkHoursHasContent());
  }


  //Check info to update controls
  private checkHoursHasContent(): boolean {
      let hasValues = false;
      this.hours.controls.forEach( control => {
          if ( control.value != '' ) {
              hasValues = true;
          }
      });

      return hasValues;
  }

}
