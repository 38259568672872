import { Pipe, PipeTransform } from '@angular/core';
import { marker as I18N } from '@biesbjerg/ngx-translate-extract-marker';
import { ProviderRegistrationState } from 'src/app/business/models/provider/common/provider-registration-state.constants';
import { ProviderState } from 'src/app/business/models/provider/common/provider-state.constants';

@Pipe({
  name: 'adminProviderStateName'
})
export class AdminProviderStateNamePipe implements PipeTransform {

  transform(_defaultValue: string, registrationState: ProviderRegistrationState, state: ProviderState): string {

    let result = _defaultValue;
    if ( registrationState == ProviderRegistrationState.RegistrationConfirmed ) {
        result = I18N('providerstate.Normal');
    } else if ( registrationState == ProviderRegistrationState.PendingSingUp ) {
        result = I18N('providerstate.Pte. Alta');
    } else if ( registrationState == ProviderRegistrationState.PendingAproval ) {
          result = I18N('providerstate.Pte. Confirmar');
    } else if ( registrationState == ProviderRegistrationState.UnregistrationRequested ) {
        result = I18N('providerstate.Pte. Baja');
    } else if ( registrationState == ProviderRegistrationState.UnregistrationConfirmed ) {
        result = I18N('providerstate.Baja');
    }

    return result ;

  }

}
