import { ApiConstants } from '../api.constants';

export class MastersApiEndpointsUtils {

     public static getUserMasterLanguagesEndpoint(): string {
          return `user/languagesdashboard`;
     }

     public static getUserMasterLanguagesEndpointAcceptVersion(): string {
          return ApiConstants.API_HEADER_ACCEPT_V_1_0_1;
     }

     public static getUserMasterTourDisplayedLanguagesEndpoint(): string {
          return `user/languagestourdisplayed`;
     }

     public static getUserMasterTourDisplayedLanguagesEndpointAcceptVersion(): string {
          return ApiConstants.API_HEADER_ACCEPT_V_1_0_1;
     }

     public static getUserMasterTourGuivenLanguagesEndpoint(): string {
          return `user/languagestourgiven`;
     }

     public static getUserMasterTourGuivenLanguagesEndpointAcceptVersion(): string {
          return ApiConstants.API_HEADER_ACCEPT_V_1_0_1;
     }

     public static getUserMasterCountriesEndpoint(): string {
          return `user/countries`;
     }

     public static getUserMasterCountriesEndpointAcceptVersion(): string {
          return ApiConstants.API_HEADER_ACCEPT_V_1_0_1;
     }

     public static getUserMasterCitiesByCountryEndpoint(countryId: number): string {
          return `user/countries/${countryId}/cities`;
     }

     public static getUserMasterCitiesByCountryEndpointAcceptVersion(): string {
          return ApiConstants.API_HEADER_ACCEPT_V_1_0_1;
     }

     public static getProviderTourTypesEndpoint(): string {
          return `user/tours/types`;
     }

     public static getProviderTourTypesEndpointAcceptVersion(): string {
          return ApiConstants.API_HEADER_ACCEPT_V_1_0_1;
     }

     public static getBookingCancelationReasonsEndpoint(): string {
          return `user/bookings/cancel`;
     }

     public static getBookingCancelationReasonsEndpointAcceptVersion(): string {
          return ApiConstants.API_HEADER_ACCEPT_V_1_0_1;
     }     

}