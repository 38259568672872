<div class="animated fadeIn">
    <div class="row header-dashboard align-items-lg-center align-items-start">
        <div class="col-12 pl-0">
            <h2 class="font-weight-bold">{{ 'createprovider.Alta de proveedor' | translate }}</h2>
            <p>{{ 'createprovider.Descripción sobre prealta de proveedor' | translate }}</p>
        </div>
    </div>

    <form class="form-boxes m-0 p-0">
        <div class="row dashboard-content-section">
            <div class="col-12 px-0">
                <form action="" [formGroup]="createProviderForm" class="form-boxes">
                    <div class="row">
                        <div class="col-12 col-md-4">
                            <div class="field pb-2">
                                <input formControlName="companyName" name="companyName" type="text" id="companyName" placeholder="{{ 'createprovider.Nombre de la empresa' | translate }}" value="">
                                <label for="companyName">{{ 'createprovider.Nombre de la empresa' | translate }}</label>
                            </div>
                            <app-feedback [formGroup]="createProviderForm" field="companyName" validator="required" feedback="{{ 'createprovider.Company name required' | translate }}" [refresh]="refreshValidators"></app-feedback>
                            <app-feedback [formGroup]="createProviderForm" field="companyName" validator="maxlength" feedback="{{ 'createtourdetails.Texto demasiado largo' | translate }}" [refresh]="refreshValidators"></app-feedback>
                        </div>
                        <div class="col-12 col-md-4">
                            <div class="field">
                                <input formControlName="email" name="email" type="text" id="email" placeholder="{{ 'createprovider.Email' | translate }}" value="">
                                <label for="email">{{ 'createprovider.Email' | translate }}</label>
                            </div>
                            <app-feedback [formGroup]="createProviderForm" field="email" validator="required" feedback="{{ 'createprovider.Email required' | translate }}" [refresh]="refreshValidators"></app-feedback>
                            <app-feedback [formGroup]="createProviderForm" field="email" validator="email" feedback="{{ 'createprovider.Email not valid' | translate }}" [refresh]="refreshValidators"></app-feedback>
                            <app-feedback [formGroup]="createProviderForm" field="email" validator="maxlength" feedback="{{ 'createtourdetails.Texto demasiado largo' | translate }}" [refresh]="refreshValidators"></app-feedback>
                        </div>
                        <div class="col-12 col-md-4">
                            <div class="field pb-2">
                                <select formControlName="language" name="language">
                                    <option *ngFor="let language of languages" value="{{language.id}}">{{language.name}}</option>
                                </select>
                                <label for="language">{{ 'createprovider.Idioma' | translate }}</label>
                            </div>
                            <app-feedback [formGroup]="createProviderForm" field="language" validator="required" feedback="{{ 'createprovider.Idioma required' | translate }}" [refresh]="refreshValidators"></app-feedback>
                        </div>
                    </div>

                    <div class="row mt-3">
                        <div class="col-12 col-sm-6 col-md-4 offset-md-4 col-lg-3 offset-lg-6">
                            <input type="submit" (click)="createProvider()" value="{{ 'createprovider.Crear alta' | translate }}" class="btn btn-full-width btn-primary mb-3 mb-sm-0" />
                        </div>
                        <div class="col-12 col-sm-6 col-md-4 col-lg-3">
                            <a class="btn btn-full-width btn-secondary" (click)="goToProviders()">{{ 'createprovider.Cancelar' | translate }}</a>
                        </div>
                    </div>
                </form>

            </div>
        </div>
    </form>
</div>


<app-message-panel></app-message-panel>