
export class ReviewUpdatedView {

    private _rating: number;
    private _comments: string;


    constructor(
      rating: number,
      comments: string

    ) {

        this._rating = rating;
        this._comments = comments;

    }

    get rating(): number {
         return this._rating;
    }

    get comments(): string {
         return this._comments;
    }

}