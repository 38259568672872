import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'invoicePaymentTypeAmountPipe'
})
export class InvoicePaymentTypeAmountPipe implements PipeTransform {

  transform(payment: number): string {

    if ( payment == 0 ) {
      return '';
    } else {
      return Math.abs(payment) + ' €';
    }

  }

}
