
export class AffiliateDetailsView {


    private _id: number;

    private _companyName: string;
    private _phone: string;
    private _country: number;
    private _city: number;
    private _address: string;
    private _businessDocumentType: string;
    private _businessDocument: string;
    private _businessZeroVat: boolean;
    private _websites: Array<string>;


    constructor(
      id: number,

      companyName: string,
      phone: string,
      country: number,
      city: number,
      address: string,
      businessDocumentType: string,
      businessDocument: string,
      businessZeroVat: boolean,

      websites: Array<string>,

    ) {

        this._id = id;

        this._companyName = companyName;
        this._phone = phone;
        this._country = country;
        this._city = city;
        this._address = address;
        this._businessDocumentType = businessDocumentType;
        this._businessDocument = businessDocument;
        this._businessZeroVat = businessZeroVat;
        this._websites = websites;

    }


    get id(): number {
        return this._id;
    }

    get companyName(): string {
        return this._companyName;
    }

    get phone(): string {
        return this._phone;
    }

    get country(): number {
        return this._country;
    }

    get city(): number {
        return this._city;
    }

    get address(): string {
        return this._address;
    }

    get businessDocumentType(): string {
        return this._businessDocumentType;
    }

    get businessDocument(): string {
        return this._businessDocument;
    }

    get businessZeroVat(): boolean {
        return this._businessZeroVat;
    }

    get websites(): Array<string> {
        return this._websites;
    }

}