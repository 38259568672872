import { Injectable }                       from '@angular/core';
import { Observable, Observer } from 'rxjs';
import { GenericException } from '../../exceptions/generic.exception';
import { CommissionType } from 'src/app/business/models/billing/common/commission-type.constants';
import { ChunkGeneric } from '../../views/chunk-generic.view';
import { GuideServiceDetails } from 'src/app/business/models/billing/service-details.model';
import { SessionInteractor } from '../session.interactor';
import { GuideServiceState } from 'src/app/business/models/billing/common/guide-service-state.constants';
import { GuideServiceService } from 'src/app/business/services/guide/service.service';
import { GuideServiceStats } from 'src/app/business/models/billing/service-stats.model';


@Injectable({
  providedIn: 'root'
})
export class GuideServiceInteractor {

    constructor(
         private guideServiceService: GuideServiceService,
         private sessionInteractor: SessionInteractor
    ) {
    }



    getGuideCommissionTypes(): Array<CommissionType> {
         const types = new Array<CommissionType>();
         types.push(CommissionType.People);
         types.push(CommissionType.Total);

         return types;
    }


    saveGuideService(guideServiceId: number, tourId: number, tourName: string, date: Date, hour: string, people: number, amount: number): Observable<boolean> {

        return Observable.create((observer: Observer<boolean>) => {

            if ( guideServiceId == null ) {
                this.guideServiceService.createService(tourId, tourName, date, hour, people, amount)
                    .subscribe(
                        (success: boolean) => {

                            observer.next(success);
                            observer.complete();
                        },
                        (error: GenericException) => {
                            observer.error(error);
                        }
                    )
            } else {

                this.guideServiceService.updateGuideService(guideServiceId, tourId, tourName, date, hour, people, amount)
                .subscribe(
                    (success: boolean) => {

                        observer.next(success);
                        observer.complete();
                    },
                    (error: GenericException) => {
                        observer.error(error);
                    }
                )
            }
        
        
        });
    }



    findGuideService(guideServiceId: number): Observable<GuideServiceDetails> {

        return Observable.create((observer: Observer<GuideServiceDetails>) => {
              this.guideServiceService.findGuideService(guideServiceId, this.sessionInteractor.getCurrentLanguage())
                    .subscribe(
                       (guideServiceDetails: GuideServiceDetails) => {

                           observer.next(guideServiceDetails);
                           observer.complete();
                       },
                       (error: GenericException) => {
                           observer.error(error);
                       }
                    )

        });

    }


    findGuideServices(month: number, year: number, state: GuideServiceState, page: number): Observable<ChunkGeneric<GuideServiceDetails>> {

        return Observable.create((observer: Observer<ChunkGeneric<GuideServiceDetails>>) => {
              this.guideServiceService.findGuideServices(month, year, state, page, this.sessionInteractor.getCurrentLanguage())
                    .subscribe(
                       (chunkCommissions: ChunkGeneric<GuideServiceDetails>) => {

                           observer.next(chunkCommissions);
                           observer.complete();
                       },
                       (error: GenericException) => {
                           observer.error(error);
                       }
                    )

        });

    }


    findGuideServicesStats(month: number, year: number): Observable<GuideServiceStats> {

        return Observable.create((observer: Observer<GuideServiceStats>) => {
            this.guideServiceService.findGuideServicesStats(month, year, this.sessionInteractor.getCurrentLanguage())
                  .subscribe(
                     (guideServiceStats: GuideServiceStats) => {

                         observer.next(guideServiceStats);
                         observer.complete();
                     },
                     (error: GenericException) => {
                         observer.error(error);
                     }
                  )

      });

    }



    deleteService(guideServiceId: number): Observable<boolean> {
        return Observable.create((observer: Observer<boolean>) => {
            this.guideServiceService.updateGuideServiceState(guideServiceId, GuideServiceState.Deleted)
                  .subscribe(
                     (result: boolean) => {
                         observer.next(result);
                         observer.complete();
                     },
                     (error: GenericException) => {
                         observer.error(error);
                     }
                  )

      });
    }



}
