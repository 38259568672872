import { Pipe, PipeTransform } from '@angular/core';
import { marker as I18N } from '@biesbjerg/ngx-translate-extract-marker';
import { AffiliateState } from 'src/app/business/models/affiliate/common/affiliate-state.constants';

@Pipe({
  name: 'adminAffiliateStateName'
})
export class AdminAffiliateStateNamePipe implements PipeTransform {

  transform(_defaultValue: string, state: AffiliateState): string {

    let result = _defaultValue
    if ( state == AffiliateState.Active ) {
        result = I18N('affiliatestate.Activo')
   } else if ( state == AffiliateState.Inactive ) {
        result = I18N('affiliatestate.Inactivo')
    }

    return result ;

  }

}
