import { Component, OnInit, ViewChild } from '@angular/core';
import { MessagePanelComponent } from 'src/app/ui/components/shared/message-panel/message-panel.component';
import { Router } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { NavigationConstants } from 'src/app/ui/commons/navigation.constants';
import { AppConstants } from 'src/app/domain/common/app.constants';
import { Language } from 'src/app/business/models/master/language/language.model';
import { marker as I18N } from '@biesbjerg/ngx-translate-extract-marker';
import { ServerErrorManager } from 'src/app/ui/components/utils/server-error-manager.utils';
import { ServerErrorExceptionCase } from 'src/app/domain/views/server-error-manager-exception-case.view';
import { GenericException } from 'src/app/domain/exceptions/generic.exception';
import { UserMasterInteractor } from 'src/app/domain/interactor/user/user-master.interactor';
import { AdminAffiliateInteractor } from 'src/app/domain/interactor/admin/affiliate.interactor';
import { Country } from 'src/app/business/models/master/country/country.model';
import { City } from 'src/app/business/models/master/city/city.model';

@Component({
     selector: 'app-create-affiliate',
     templateUrl: './create.component.html'
})
export class CreateAffiliateComponent implements OnInit {

     @ViewChild(MessagePanelComponent)
     private messagePanelComponent: MessagePanelComponent;

     createAffiliateForm: FormGroup;
     languages: Array<Language>;
     refreshValidators: boolean;
     countries: Array<Country>;
     cities: Array<City>;

     constructor(
          private adminAffiliateInteractor: AdminAffiliateInteractor,
          private userMasterInteractor: UserMasterInteractor,
          private router: Router,
          private serverErrorManager: ServerErrorManager ) {

               this.refreshValidators = false;

               this.createAffiliateForm = new FormGroup({
                    'companyName': new FormControl('', [Validators.required, Validators.maxLength(AppConstants.TEXT_DEFAULT_MAX_LENGTH)] ),
                    'email':  new FormControl(
                         '',
                         [
                              Validators.required,
                              Validators.email,
                              Validators.maxLength(AppConstants.TEXT_DEFAULT_MAX_LENGTH)
                         ]),
                         'language': new FormControl('', [Validators.required] ),
                         'country': new FormControl('', [Validators.required] ),
                         'city': new FormControl('', [Validators.required] )
                    });

                    this.languages = new Array()
               }

               ngOnInit() {

                    this.userMasterInteractor.getLanguagesByUser()
                    .subscribe(
                         (languages: Array<Language>) => {
                              this.languages = languages
                         },
                         (error: GenericException) => {
                              this.serverErrorManager.manageErrorCode(error, this.messagePanelComponent);
                         }
                    )


                    this.userMasterInteractor.getCountriesByUser()
                    .subscribe(
                         (countries: Array<Country>) => {
                              this.countries = countries
                         },
                         (error: GenericException) => {
                              this.serverErrorManager.manageErrorCode(error, this.messagePanelComponent);
                         }
                    );
               }

               createAffiliate() {

                    this.refreshValidators = true;

                    if ( this.createAffiliateForm.valid ) {

                         this.messagePanelComponent.showLoading();

                         const companyName = this.createAffiliateForm.get('companyName').value;
                         const email = this.createAffiliateForm.get('email').value;
                         const language = this.createAffiliateForm.get('language').value;
                         const cityId = this.createAffiliateForm.get('city').value;

                         this.adminAffiliateInteractor.createAffiliate(companyName, email, language, cityId)
                         .subscribe(
                              (success: boolean) => {

                                   MessagePanelComponent.hideLoading();

                                   if ( success ) {
                                        this.messagePanelComponent.showSuccessMessage(I18N('createaffiliate.Afiliado creado correctamente'), () => this.goToAffiliates());
                                   } else {
                                        this.messagePanelComponent.showErrorMessage(I18N('createaffiliate.No ha sido posible crear el afiliado'));
                                   }
                              },
                              (error: GenericException) => {

                                   MessagePanelComponent.hideLoading();

                                   const exceptionCases = new Array<ServerErrorExceptionCase>();
                                   const exceptionCodes = new Array<number>();
                                   exceptionCodes.push(AppConstants.ERROR_CLIENT_BAD_REQUEST);
                                   exceptionCases.push( new ServerErrorExceptionCase(
                                        exceptionCodes,
                                        new Array<string>(),
                                        () => {
                                             this.messagePanelComponent.showErrorMessage(I18N('createaffiliate.La cuenta de correo ya existe'))
                                        }
                                   ));

                                   this.serverErrorManager.manageErrorCode(error, this.messagePanelComponent, exceptionCases);

                              }
                         )
                    }


               }

               goToAffiliates() {
                    this.router.navigate( [ NavigationConstants.getNavigationAdminListAffiliates() ] );
               }


               onCountrySelected() {

                    const selectedCountryId = this.createAffiliateForm.get('country').value;
                    this.cities = new Array<City>()
                    this.createAffiliateForm.get('city').setValue(null);

                    if (selectedCountryId != null) {

                         this.userMasterInteractor.getCitiesByUser(selectedCountryId)
                         .subscribe(
                              (cities: Array<City>) => {
                                   this.cities = cities
                              },
                              (error: GenericException) => {
                                   this.serverErrorManager.manageErrorCode(error, this.messagePanelComponent);
                              }
                         )

                    }

               }


          }
