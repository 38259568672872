export class TourAffiliateLink {

    private _link: string;
    private _languageName: string;


    constructor(
        link: string,
        languageName: string
    ) {
        this._link = link;
        this._languageName = languageName;
    }


    get link(): string {
      return this._link;
    }

    get languageName(): string {
      return this._languageName;
    }

}