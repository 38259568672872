import { Component, ViewChild } from '@angular/core';
import { MessagePanelComponent } from '../../../shared/message-panel/message-panel.component';
import { ProfileInteractor } from 'src/app/domain/interactor/profile/profile.interactor';
import { marker as I18N } from '@biesbjerg/ngx-translate-extract-marker';
import { ServerErrorManager } from '../../../utils/server-error-manager.utils';
import { GenericException } from 'src/app/domain/exceptions/generic.exception';
import { Guide } from 'src/app/business/models/guide/guide.model';

@Component({
     selector: 'app-profile-guide',
     templateUrl: './profile.component.html'
})
export class GuideProfileComponent {


     @ViewChild(MessagePanelComponent)
     private messagePanelComponent: MessagePanelComponent;

     guide: Guide;

     constructor(
          private profileInteractor: ProfileInteractor,
          private serverErrorManager: ServerErrorManager
     ) {

          this.profileInteractor.getGuideDetails()
          .subscribe(
               (response: Guide) => {

                    MessagePanelComponent.hideLoading();

                    this.guide = response;

               },
               (error: GenericException) => {

                    MessagePanelComponent.hideLoading();
                    this.serverErrorManager.manageErrorCode(error, this.messagePanelComponent);

               }
          )
     }


}

