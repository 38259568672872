import { ApiConstants } from './api.constants';
import { TourFormView } from 'src/app/domain/views/provider/tour/tour-form.view';
import { TourAttractionFormView } from 'src/app/domain/views/provider/tour/attraction-form.view';
import { TourUtils } from '../models/tour/common/tour.utils';
import { TourScheduleSeasonFormView } from 'src/app/domain/views/provider/tour/schedule-season-form.view';
import { CalendarUtils } from 'src/app/ui/utils/calendar.utils';
import { TourSchedulePlanningFormView } from 'src/app/domain/views/provider/tour/schedule-planning-form.view';
import { TourFareFormView } from 'src/app/domain/views/provider/tour/fare-form.view';

export class ApiBuildUtils {


     public static buildRequestTourObject(tourForm: TourFormView): any {
          const tourRequest: any = {};

          if ( tourForm.providerId ) {
               tourRequest.providerId = tourForm.providerId;
          }

          tourRequest.languageIdI18N = tourForm.languageIdI18N;

          if (tourForm.tourDetails) {

               tourRequest.name = tourForm.tourDetails.name;
               tourRequest.languageTourGivenId = tourForm.tourDetails.languageTourGivenId;
               tourRequest.typeId = tourForm.tourDetails.typeId;
               tourRequest.duration = tourForm.tourDetails.duration;
               tourRequest.cityId = tourForm.tourDetails.cityId;
               tourRequest.description = tourForm.tourDetails.description;
               tourRequest.descriptionShort = tourForm.tourDetails.descriptionShort;
               tourRequest.additionalInformation = tourForm.tourDetails.additionalInformation;

               tourRequest.attractions = new Array();
               tourForm.tourDetails.attractions.map((attraction: TourAttractionFormView) => {
                    tourRequest.attractions.push({ 'id': attraction.id, 'name': attraction.name });
               });

          }

          if (tourForm.tourUsers) {

               tourRequest.minPeople = tourForm.tourUsers.minPax;
               tourRequest.maxPeople = tourForm.tourUsers.maxPax;
               tourRequest.bookingLimit = tourForm.tourUsers.bookingLimit;
               tourRequest.maxPeoplePerBooking = tourForm.tourUsers.maxPeoplePerBooking;
               tourRequest.cancellationLimit = tourForm.tourUsers.cancellationLimit;
               tourRequest.isFree = tourForm.tourUsers.isFree;
               if (tourRequest.isFree) {
                    tourRequest.maxFreePeople = tourForm.tourUsers.maxFreePeople;
                    tourRequest.maxFreePeoplePrice = tourForm.tourUsers.maxFreePeoplePrice;
                    tourRequest.maxFreePeopleAge = tourForm.tourUsers.maxFreePeopleAge;
               }

               tourRequest.fares = new Array();
               tourForm.tourUsers.fares.map((fare: TourFareFormView) => {
                    tourRequest.fares.push({ 'id': fare.id, 'name': fare.name, 'price': fare.price, 'freeBookingRelated': false });
               });

          }


          if (tourForm.tourLocation) {

               tourRequest.meetingPoint = tourForm.tourLocation.meetingPoint;
               tourRequest.locationCoordX = tourForm.tourLocation.locationCoordX;
               tourRequest.locationCoordY = tourForm.tourLocation.locationCoordY;
               tourRequest.locationIndications = tourForm.tourLocation.locationIndications;
               tourRequest.locationMoreIndications = tourForm.tourLocation.locationMoreIndications;

          }

          if (tourForm.tourSchedule) {

               const schedule: any = {};
               schedule.type = TourUtils.getApiTypeFromTourScheduleType(tourForm.tourSchedule.type);

               if (schedule.type == ApiConstants.API_TOUR_SCHEDULE_TYPE_FIXED) {
                    schedule.fixed = this.prepareCreateTourObjectDays(tourForm.tourSchedule.fixed);

               } else if (schedule.type == ApiConstants.API_TOUR_SCHEDULE_TYPE_SEASON) {
                    schedule.seasons = new Array();
                    tourForm.tourSchedule.seasons.map((season: TourScheduleSeasonFormView) => {

                         const startDayFormatted = CalendarUtils.fromDateDDMMYYYYToFormatString(season.startDay, ApiConstants.API_DATE_FORMAT_DD_MM_YYYY);
                         const finishDayFormatted = CalendarUtils.fromDateDDMMYYYYToFormatString(season.finishDay, ApiConstants.API_DATE_FORMAT_DD_MM_YYYY);

                         schedule.seasons.push({ 'startDay': startDayFormatted, 'finishDay': finishDayFormatted, 'details': this.prepareCreateTourObjectDays(season.details) });

                    })
               }

               tourRequest.schedule = schedule;

          }

          return tourRequest;
     }


     private static prepareCreateTourObjectDays(details: TourSchedulePlanningFormView): any {

          const result = { 'days': new Array(), 'dayExceptions': new Array() };

          if (details) {
               if (details.days) {
                    details.days.map((day: any) => {

                         const apiWeekday = TourUtils.getApiWeekdayFromTourScheduleWeekday(day.day);
                         result.days.push({ 'day': apiWeekday, 'hours': day.hours });
                    });
               }

               if (details.dayExceptions) {
                    details.dayExceptions.map((day: any) => {
                         const dayFormatted = CalendarUtils.fromDateDDMMYYYYToFormatString(day.day, ApiConstants.API_DATE_FORMAT_DD_MM_YYYY);
                         result.dayExceptions.push({ 'day': dayFormatted, 'hours': day.hours });
                    });
               }
          }

          return result;
     }


     public static buildRequestTourI18NObject(tourForm: TourFormView): any {
          const tourRequest: any = {};

          tourRequest.languageIdI18N = tourForm.languageIdI18N;

          if (tourForm.tourDetails) {

               tourRequest.name = tourForm.tourDetails.name;
               tourRequest.description = tourForm.tourDetails.description;
               tourRequest.descriptionShort = tourForm.tourDetails.descriptionShort;
               tourRequest.additionalInformation = tourForm.tourDetails.additionalInformation;

               tourRequest.attractions = new Array();
               tourForm.tourDetails.attractions.map((attraction: TourAttractionFormView) => {
                    tourRequest.attractions.push({ 'id': attraction.id, 'name': attraction.name });
               });

          }

          if (tourForm.tourUsers) {

               tourRequest.fares = new Array();
               tourForm.tourUsers.fares.map((fare: TourFareFormView) => {
                    tourRequest.fares.push({ 'id': fare.id, 'name': fare.name });
               });

          }


          if (tourForm.tourLocation) {

               tourRequest.meetingPoint = tourForm.tourLocation.meetingPoint;
               tourRequest.locationIndications = tourForm.tourLocation.locationIndications;
               tourRequest.locationMoreIndications = tourForm.tourLocation.locationMoreIndications;

          }

          return tourRequest;
     }


}
