export class LoggedInUserView {

    private _id: number;
    private _email: string;
    private _name: string;
    private _guidesManagement: number;

    constructor(
        id: number,
        email: string,
        name: string,
        guidesManagement: number
    ) {

        this._id = id;
        this._email = email;
        this._name = name;
        this._guidesManagement = guidesManagement;
    }


    get id(): number {
        return this._id;
    }

    get email(): string {
        return this._email;
    }

    get name(): string {
        return this._name;
    }

    get guidesManagement(): number {
        return this._guidesManagement;
    }

}
