import { Language } from '../master/language/language.model';
import { TourType } from '../master/tour/type/type.model';
import { City } from '../master/city/city.model';
import { Country } from '../master/country/country.model';
import { Fare } from './fare.model';
import { TourState } from './common/tour-state.constants';
import { ImageDetails } from '../image/image-details.model';
import { TourResume } from './tour-resume.model';

export class TourResumeAdmin extends TourResume {

    private _providerId: number;
    private _providerName: string;

    private _customAffiliateCommission: number;
    private _customAffiliateCommissionBidFare: number;
    private _affiliateCommission: number;


    constructor(
        id: number,
        name: string,
        nameMain: string,
        descriptionShort: string,
        descriptionShortMain: string,
        languageTourGiven: Language,
        type: TourType,
        country: Country,
        city: City,
        duration: number,
        isFree: boolean,
        fares: Array<Fare>,
        image: ImageDetails,
        bid: number,
        bidMin: number,
        bidBlocked: boolean,
        customCommissionFare: number,
        defaultCommissionFare: number,
        customAffiliateCommission: number,
        customAffiliateCommissionBidFare: number,
        affiliateCommission: number,
        guideSalesCommission: number,
        guideServiceFee: number,
        state: TourState,
        providerId: number,
        providerName: string

    ) {
        super(id, name, nameMain, descriptionShort, descriptionShortMain, languageTourGiven, type, country, city, duration, isFree, fares, image, bid, bidMin, bidBlocked, customCommissionFare, defaultCommissionFare, guideSalesCommission, guideServiceFee, state);

        this._providerId = providerId;
        this._providerName = providerName;
        this._customAffiliateCommission = customAffiliateCommission;
        this._customAffiliateCommissionBidFare = customAffiliateCommissionBidFare;
        this._affiliateCommission = affiliateCommission;

    }



    get providerId(): number {
      return this._providerId;
    }

    get providerName(): string {
      return this._providerName;
    }

    set providerId(providerId: number) {
      this._providerId = providerId;
    }

    set providerName(providerName: string) {
        this._providerName = providerName;
    }

    get customAffiliateCommission(): number {
         return this._customAffiliateCommission;
    }

    set customAffiliateCommission(customAffiliateCommission: number) {
        this._customAffiliateCommission = customAffiliateCommission;
    }

    get customAffiliateCommissionBidFare(): number {
         return this._customAffiliateCommissionBidFare;
    }

    set customAffiliateCommissionBidFare(customAffiliateCommissionBidFare: number) {
        this._customAffiliateCommissionBidFare = customAffiliateCommissionBidFare;
    }

    get affiliateCommission(): number {
         return this._affiliateCommission;
    }

    set affiliateCommission(affiliateCommission: number) {
        this._affiliateCommission = affiliateCommission;
    }

}
