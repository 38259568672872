<div class="p-relative z-index-3">
    <div class="sidebar animated fadeInLeft" [ngClass]="{ 'show': navbarOpen }">

        <button class="sidebar-collapse-open text-left px-0 mt-5" type="button" (click)="toggleNavbar()">
        <img class="mr-2" src="assets/img/ic_menu-hamburguer-white.svg">
    </button>

        <button class="sidebar-collapse-close text-left px-0 mt-5" type="button" (click)="toggleNavbar()">
        <img class="mr-2" src="assets/img/ic_close.svg">
    </button>
        <div class="sidebar-heading">
            <a (click)="goToDashboard()">
                <img class="logo" src="assets/img/logo.png">
            </a>
        </div>

        <div class="list-group dropdown">
            <ul>
                <li *ngIf="isAdminRole">
                    <a (click)="goToProviders()" class="list-group-item" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" [routerLink]="'/' + NavigationConstants.getNavigationAdminListProviders()">
                        <img class="mr-2 ic-arrow" src="assets/img/ic_menu_providers.svg">
                        <span>{{ 'Sidebarmenu.Proveedores' | translate }}</span>
                    </a>
                </li>
                <li *ngIf="isAdminRole">
                    <a (click)="goToAdminTours()" class="list-group-item" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" [routerLink]="'/' + NavigationConstants.getNavigationAdminListTours()">
                        <img class="mr-2 ic-arrow" src="assets/img/ic_menu_tour.svg">
                        <span>{{ 'Sidebarmenu.Tours' | translate }}</span>
                    </a>
                </li>
                <li *ngIf="isAdminRole">
                    <a (click)="goToAdminBooking()" class="list-group-item" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" [routerLink]="'/' + NavigationConstants.getNavigationAdminListBooking()">
                        <img class="mr-2" src="assets/img/ic_menu_calendar.svg">
                        <span>{{ 'Sidebarmenu.Reservas' | translate }}</span>
                    </a>
                </li>
                <li *ngIf="isAdminRole">
                    <a (click)="goToAdminReviews()" class="list-group-item" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" [routerLink]="'/' + NavigationConstants.getNavigationAdminReviews()">
                        <img class="mr-2" src="assets/img/ic_menu_reviews.svg">
                        <span>{{ 'Sidebarmenu.Reviews' | translate }}</span>
                    </a>
                </li>
                <div class="list-group dropdown" *ngIf="isAdminRole">
                    <ul>
                        <li>
                            <a class="list-group-item">
                                <input class="list-group-item" id="adminBilling" type="checkbox">
                                <label for="adminBilling">
                                    <img class="mr-2" src="assets/img/ic_menu_billing.svg">
                                    <span>{{ 'Sidebarmenu.Billing' | translate }}</span>
                                    <div class="ml-1 ic-arrow"></div>
                                </label>
                                <ul class="list-subgroup">
                                    <li>
                                        <a class="list-subgroup-item" (click)="goToAdminBilling()" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" [routerLink]="'/' + NavigationConstants.getNavigationAdminBilling()">
                                            <span class="">{{ 'Sidebarmenu.Billing' | translate }}</span>
                                        </a>
                                    </li>
                                    <li>
                                        <a class="list-subgroup-item" (click)="goToAdminInvoices()" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" [routerLink]="'/' + NavigationConstants.getNavigationAdminInvoices()">
                                            <span class="">{{ 'Sidebarmenu.Invoices' | translate }}</span>
                                        </a>
                                    </li>
                                </ul>
                            </a>
                        </li>
                    </ul>
                </div>
                <div class="list-group dropdown" *ngIf="isAdminRole">
                    <ul>
                        <li>
                            <a class="list-group-item">
                                <input class="list-group-item" id="adminAffiliate" type="checkbox">
                                <label for="adminAffiliate">
                                    <img class="mr-2" src="assets/img/ic_menu_affiliation.svg">
                                    <span>{{ 'Sidebarmenu.Afiliación' | translate }}</span>
                                    <div class="ml-1 ic-arrow"></div>
                                </label>
                                <ul class="list-subgroup">
                                    <li>
                                        <a class="list-subgroup-item" (click)="goToAffiliates()" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" [routerLink]="'/' + NavigationConstants.getNavigationAdminListAffiliates()">
                                            <span class="">{{ 'Sidebarmenu.Afiliados' | translate }}</span>
                                        </a>
                                    </li>
                                    <li>
                                        <a class="list-subgroup-item" (click)="goToAdminAffiliateBilling()" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" [routerLink]="'/' + NavigationConstants.getNavigationAdminAffiliateBilling()">
                                            <span class="">{{ 'Sidebarmenu.Commissions' | translate }}</span>
                                        </a>
                                    </li>
                                    <li>
                                        <a class="list-subgroup-item" (click)="goToAdminAffiliationInvoices()" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" [routerLink]="'/' + NavigationConstants.getNavigationAdminAffiliateInvoices()">
                                            <span class="">{{ 'Sidebarmenu.Payments' | translate }}</span>
                                        </a>
                                    </li>
                                </ul>
                            </a>
                        </li>
                    </ul>
                </div>


                <div class="list-group dropdown" *ngIf="isAdminRole">
                    <ul>
                        <li>
                            <a class="list-group-item">
                                <input class="list-group-item" id="providerGuides" type="checkbox">
                                <label for="providerGuides">
                                    <img class="mr-2" src="assets/img/ic_menu_guides.svg">
                                    <span>{{ 'Sidebarmenu.Guías' | translate }}</span>
                                    <div class="ml-1 ic-arrow"></div>
                                </label>
                                <ul class="list-subgroup">
                                    <li>
                                        <a class="list-subgroup-item" (click)="goToAdminGuidesBilling()" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" [routerLink]="'/' + NavigationConstants.getNavigationAdminGuideBilling()">
                                            <span class="">{{ 'Sidebarmenu.Commissions' | translate }}</span>
                                        </a>
                                    </li>
                                    <li>
                                        <a class="list-subgroup-item" (click)="goToAdminGuideServices()" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" [routerLink]="'/' + NavigationConstants.getNavigationAdminGuideServices()">
                                            <span class="">{{ 'Sidebarmenu.Services' | translate }}</span>
                                        </a>
                                    </li>
                                </ul>
                            </a>
                        </li>
                    </ul>
                </div>


                <li *ngIf="isProviderRole">
                    <a (click)="goToTours()" class="list-group-item" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" [routerLink]="'/' + NavigationConstants.getNavigationProviderListTours()">
                        <img class="mr-2 ic-arrow" src="assets/img/ic_menu_tour.svg">
                        <span>{{ 'Sidebarmenu.Tours' | translate }}</span>
                    </a>
                </li>
                <li *ngIf="isProviderRole">
                    <a (click)="goToBooking()" class="list-group-item" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" [routerLink]="'/' + NavigationConstants.getNavigationProviderListBooking()">
                        <img class="mr-2" src="assets/img/ic_menu_calendar.svg">
                        <span>{{ 'Sidebarmenu.Reservas' | translate }}</span>
                    </a>
                </li>

                <div class="list-group dropdown" *ngIf="isProviderRole">
                    <ul>
                        <li>
                            <a class="list-group-item">
                                <input class="list-group-item" id="providerBilling" type="checkbox">
                                <label for="providerBilling">
                                    <img class="mr-2" src="assets/img/ic_menu_billing.svg">
                                    <span>{{ 'Sidebarmenu.Billing' | translate }}</span>
                                    <div class="ml-1 ic-arrow"></div>
                                </label>
                                <ul class="list-subgroup">
                                    <li>
                                        <a class="list-subgroup-item" (click)="goToBilling()" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" [routerLink]="'/' + NavigationConstants.getNavigationProviderBilling()">
                                            <span class="">{{ 'Sidebarmenu.Billing' | translate }}</span>
                                        </a>
                                    </li>
                                    <li>
                                        <a class="list-subgroup-item" (click)="goToInvoices()" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" [routerLink]="'/' + NavigationConstants.getNavigationProviderInvoices()">
                                            <span class="">{{ 'Sidebarmenu.Invoices' | translate }}</span>
                                        </a>
                                    </li>
                                </ul>
                            </a>
                        </li>
                    </ul>
                </div>


                <div class="list-group dropdown" *ngIf="isProviderRole && isAffiliateRole">
                    <ul>
                        <li>
                            <a class="list-group-item">
                                <input class="list-group-item" id="affiliatePanel" type="checkbox">
                                <label for="affiliatePanel">
                                    <img class="mr-2" src="assets/img/ic_menu_affiliation.svg">
                                    <span>{{ 'Sidebarmenu.Afiliación' | translate }}</span>
                                    <div class="ml-1 ic-arrow"></div>
                                </label>
                                <ul class="list-subgroup">
                                     <li>
                                         <a class="list-subgroup-item" (click)="goToAffiliationDashboard()" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" [routerLink]="'/' + NavigationConstants.getNavigationAffiliateDashboard()">
                                             <span class="">{{ 'Sidebarmenu.Panel de control' | translate }}</span>
                                         </a>
                                     </li>
                                    <li>
                                        <a class="list-subgroup-item" (click)="goToAffiliationTours()" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" [routerLink]="'/' + NavigationConstants.getNavigationAffiliateListTours()">
                                            <span class="">{{ 'Sidebarmenu.Tours' | translate }}</span>
                                        </a>
                                    </li>
                                    <li>
                                        <a class="list-subgroup-item" (click)="goToAffiliationBilling()" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" [routerLink]="'/' + NavigationConstants.getNavigationAffiliateBilling()">
                                            <span class="">{{ 'Sidebarmenu.Commissions' | translate }}</span>
                                        </a>
                                    </li>
                                    <li>
                                        <a class="list-subgroup-item" (click)="goToAffiliationInvoices()" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" [routerLink]="'/' + NavigationConstants.getNavigationAffiliateInvoices()">
                                            <span class="">{{ 'Sidebarmenu.Payments' | translate }}</span>
                                        </a>
                                    </li>
                                </ul>
                            </a>
                        </li>
                    </ul>
                </div>

                <div class="list-group dropdown" *ngIf="isProviderRole && guidesManagement == guidesManagementFull">
                    <ul>
                        <li>
                            <a class="list-group-item">
                                <input class="list-group-item" id="providerGuides" type="checkbox">
                                <label for="providerGuides">
                                    <img class="mr-2" src="assets/img/ic_menu_guides.svg">
                                    <span>{{ 'Sidebarmenu.Guías' | translate }}</span>
                                    <div class="ml-1 ic-arrow"></div>
                                </label>
                                <ul class="list-subgroup">
                                    <li>
                                        <a class="list-subgroup-item" (click)="goToGuides()" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" [routerLink]="'/' + NavigationConstants.getNavigationProviderListGuides()">
                                            <span class="">{{ 'Sidebarmenu.Guías' | translate }}</span>
                                        </a>
                                    </li>
                                    <li>
                                        <a class="list-subgroup-item" (click)="goToProviderGuidesBilling()" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" [routerLink]="'/' + NavigationConstants.getNavigationProviderGuideBilling()">
                                            <span class="">{{ 'Sidebarmenu.Commissions' | translate }}</span>
                                        </a>
                                    </li>
                                    <li>
                                        <a class="list-subgroup-item" (click)="goToProviderGuidesServices()" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" [routerLink]="'/' + NavigationConstants.getNavigationProviderGuideServices()">
                                            <span class="">{{ 'Sidebarmenu.Services' | translate }}</span>
                                        </a>
                                    </li>
                                </ul>
                            </a>
                        </li>
                    </ul>
                </div>


                <li *ngIf="isAffiliateRole && !isProviderRole">
                    <a (click)="goToDashboard()" class="list-group-item" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" [routerLink]="'/' + NavigationConstants.getNavigationAffiliateDashboard()">
                        <img class="mr-2" src="assets/img/ic_menu_controlpanel.svg">
                        <span>{{ 'Sidebarmenu.Panel de control' | translate }}</span>
                    </a>
                </li>

                <li *ngIf="isAffiliateRole && !isProviderRole">
                    <a (click)="goToAffiliationTours()" class="list-group-item" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" [routerLink]="'/' + NavigationConstants.getNavigationAffiliateListTours()">
                        <img class="mr-2" src="assets/img/ic_menu_tour.svg">
                        <span>{{ 'Sidebarmenu.Tours' | translate }}</span>
                    </a>
                </li>

                <div class="list-group dropdown" *ngIf="isAffiliateRole && !isProviderRole">
                    <ul>
                        <li>
                            <a class="list-group-item">
                                <input class="list-group-item" id="affiliateBilling" type="checkbox">
                                <label for="affiliateBilling">
                                    <img class="mr-2" src="assets/img/ic_menu_billing.svg">
                                    <span>{{ 'Sidebarmenu.Billing' | translate }}</span>
                                    <div class="ml-1 ic-arrow"></div>
                                </label>
                                <ul class="list-subgroup">
                                    <li>
                                        <a class="list-subgroup-item" (click)="goToAffiliationBilling()" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" [routerLink]="'/' + NavigationConstants.getNavigationAffiliateBilling()">
                                            <span class="">{{ 'Sidebarmenu.Commissions' | translate }}</span>
                                        </a>
                                    </li>
                                    <li>
                                        <a class="list-subgroup-item" (click)="goToAffiliationInvoices()" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" [routerLink]="'/' + NavigationConstants.getNavigationAffiliateInvoices()">
                                            <span class="">{{ 'Sidebarmenu.Payments' | translate }}</span>
                                        </a>
                                    </li>
                                </ul>
                            </a>
                        </li>
                    </ul>
                </div>


                <li *ngIf="isGuideRole">
                    <a (click)="goToGuideBookings()" class="list-group-item" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" [routerLink]="'/' + NavigationConstants.getNavigationGuideListBookings()">
                         <img class="mr-2" src="assets/img/ic_menu_calendar.svg">
                         <span>{{ 'Sidebarmenu.Calendario' | translate }}</span>
                    </a>
                </li>

                <li *ngIf="isGuideRole">
                    <a (click)="goToGuideBilling()" class="list-group-item" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" [routerLink]="'/' + NavigationConstants.getNavigationGuideBilling()">
                         <img class="mr-2" src="assets/img/ic_menu_billing.svg">
                        <span class="">{{ 'Sidebarmenu.Commissions' | translate }}</span>
                    </a>
                </li>

                <li *ngIf="isGuideRole">
                    <a (click)="goToGuideServices()" class="list-group-item" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" [routerLink]="'/' + NavigationConstants.getNavigationGuideServices()">
                        <img class="mr-2" src="assets/img/ic_menu_guides.svg">
                        <span class="">{{ 'Sidebarmenu.Services' | translate }}</span>
                    </a>
                </li>

            </ul>
        </div>

        <div class="list-group list-group-contact d-lg-none">
            <hr class="divider-secondary">
            <a *ngIf="isAdminRole" (click)="goToProfile()" class="list-group-item" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" [routerLink]="'/' + NavigationConstants.getNavigationAdminProfile()">
                <img class="mr-2" src="assets/img/ic_arrow_right.svg">
                <span class="">{{ 'Sidebarmenu.Datos de perfil' | translate }}</span>
            </a>
            <a *ngIf="isProviderRole" (click)="goToProfile()" class="list-group-item" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" [routerLink]="'/' + NavigationConstants.getNavigationProviderProfile()">
                <img class="mr-2" src="assets/img/ic_arrow_right.svg">
                <span class="">{{ 'Sidebarmenu.Datos de perfil' | translate }}</span>
            </a>
            <a *ngIf="isAffiliateRole && !isProviderRole" (click)="goToProfile()" class="list-group-item" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" [routerLink]="'/' + NavigationConstants.getNavigationAffiliateProfile()">
                <img class="mr-2" src="assets/img/ic_arrow_right.svg">
                <span class="">{{ 'Sidebarmenu.Datos de perfil' | translate }}</span>
            </a>
            <a *ngIf="isGuideRole" (click)="goToProfile()" class="list-group-item" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" [routerLink]="'/' + NavigationConstants.getNavigationGuideProfile()">
                <img class="mr-2" src="assets/img/ic_arrow_right.svg">
                <span class="">{{ 'Sidebarmenu.Datos de perfil' | translate }}</span>
            </a>
            <a (click)="logout()" class="list-group-item">
                <img class="mr-2" src="assets/img/ic_arrow_right.svg">
                <span class="">{{ 'Sidebarmenu.Cerrar sesión' | translate }}</span>
            </a>
        </div>



    </div>
</div>
<div class="overlay" (click)="toggleNavbar()"></div>