import { Component, ViewChild, Input } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { MessagePanelComponent } from 'src/app/ui/components/shared/message-panel/message-panel.component';

import { Book } from 'src/app/business/models/book/book.model';
import { AppConstants } from 'src/app/domain/common/app.constants';
import { BookContactDetailsView } from 'src/app/domain/views/book/book-contact-details.view';


@Component({
     selector: 'app-edit-book-contact-details-modal-component',
     templateUrl: './edit-book-contact-details-modal.component.html'
})

export class EditBookContactDetailsModalComponent {

     @ViewChild(MessagePanelComponent, { static: false })
     private messagePanelComponent: MessagePanelComponent;

     bookContactDetailsForm: FormGroup;
     minBidLabelValue: any= { value: null};

     refreshValidators: boolean


     @Input()
     set book(book: Book) {
          if (book) {
               this.setBookContactDetailsFormValues(book);
          }
     }



     constructor(
          private modal: NgbActiveModal) {

               this.refreshValidators = false;

               this.bookContactDetailsForm = new FormGroup({
                    'bookId': new FormControl(''),
                    'email': new FormControl('', [Validators.required, Validators.email, Validators.maxLength(AppConstants.TEXT_DEFAULT_MAX_LENGTH)]),
                    'name': new FormControl('', [Validators.required, Validators.maxLength(AppConstants.TEXT_DEFAULT_MAX_LENGTH)]),
                    'surname': new FormControl('', [Validators.required, Validators.maxLength(AppConstants.TEXT_DEFAULT_MAX_LENGTH)]),
                    'phone': new FormControl('', [Validators.required, Validators.maxLength(AppConstants.TEXT_DEFAULT_MAX_LENGTH)]),
               })

          }

          ngOnInit() {
          }


          closeModal() {
               this.modal.close();
          }


          saveBookingContactDetails() {

               this.refreshValidators = true;

               if (this.bookContactDetailsForm.valid) {

                    this.messagePanelComponent.showLoading();

                    const bookId: number = this.bookContactDetailsForm.get('bookId').value;
                    const email: string = this.bookContactDetailsForm.get('email').value;
                    const name: string = this.bookContactDetailsForm.get('name').value;
                    const surname: string = this.bookContactDetailsForm.get('surname').value;
                    const phone: string = this.bookContactDetailsForm.get('phone').value;

                    const bookContactDetailsView: BookContactDetailsView = new BookContactDetailsView(bookId, email, name, surname, phone);
                    this.modal.close(bookContactDetailsView);

               }

          }

          setBookContactDetailsFormValues(book: Book) {
               this.bookContactDetailsForm.get('bookId').setValue(book.id);
               this.bookContactDetailsForm.get('email').setValue(book.email);
               this.bookContactDetailsForm.get('name').setValue(book.name);
               this.bookContactDetailsForm.get('surname').setValue(book.surname);
               this.bookContactDetailsForm.get('phone').setValue(book.phone);
          }

     }




