import { BookFare } from 'src/app/business/models/book/book-fare.model';
import { environment }                      from 'src/environments/environment';

export class NavigatorUtils {

    public static reloadApplication() {
        window.location.reload();
    }


    public static goToReservaFreeTourPage() {
        window.location.href = 'https://reservafreetour.com/';
    }

    public static goToExternalURL(externalUrl: string) {
        window.location.href = externalUrl;
    }

    public static copyToClipboard(text: string) {
         navigator.clipboard.writeText(text);
    }

    public static downloadFile(fileBlob: Blob, fileName: string) {
         // IE doesn't allow using a blob object directly as link href
         // instead it is necessary to use msSaveOrOpenBlob
         if (window.navigator && window.navigator.msSaveOrOpenBlob) {
              window.navigator.msSaveBlob(fileBlob);
              return;
         }

         // For other browsers:
         // Create a link pointing to the ObjectURL containing the blob.
         const downloadURL = URL.createObjectURL(fileBlob);

         var anchor = document.createElement("a");
         anchor.download = fileName;
         anchor.href = downloadURL;
         anchor.click();
    }


    public static goToWidgetAsExternalAffiliateIdentity(tourId: number, langcode: string, affiliateId: number | null) {
         var targetURL = NavigatorUtils.prepareWidgetExternalIdentityParamsRequest(tourId, langcode);

         if ( affiliateId ) {
              targetURL += '&bqaffid=' + affiliateId;
         }

         window.open(targetURL, '_blank');
    }


    public static goToWidgetAsExternalGuideIdentity(tourId: number, langcode: string, day: string, hour: string, guideId: number | null) {
         var targetURL = NavigatorUtils.prepareWidgetExternalIdentityParamsRequest(tourId, langcode);

         if ( day && hour ) {
             targetURL += '&day=' + encodeURIComponent(day);
             targetURL += '&hour=' + encodeURIComponent(hour);
         }

         if ( guideId ) {
              targetURL += '&bqguid=' + guideId;
         }

         window.open(targetURL, '_blank');
    }


    public static goToWidgetAsExternalGuideIdentityWithContactDetails(tourId: number, langcode: string, email: string, name: string, surname: string, phone: string, guideId: number | null) {

         var targetURL = NavigatorUtils.prepareWidgetExternalIdentityParamsRequest(tourId, langcode);
         if ( email ) {
             targetURL += '&email=' + encodeURIComponent(email);
         }
         if ( name ) {
             targetURL += '&name=' + encodeURIComponent(name);
         }
         if ( surname ) {
             targetURL += '&surname=' + encodeURIComponent(surname);
         }
         if ( phone ) {
             targetURL += '&phone=' + encodeURIComponent(phone);
         }

         if ( guideId ) {
              targetURL += '&bqguid=' + guideId;
         }

         window.open(targetURL, '_blank');
    }


    public static prepareWidgetExternalIdentityParamsRequest(tourId: number, langcode: string): string {
         var targetURL = environment.WIDGET_URL + '/#/book/' + tourId;
         targetURL += '?apikey=' + environment.WIDGET_API_KEY;
         targetURL += '&lan=' + langcode;
         targetURL += '&page=2';
         targetURL += '&external=1';

         return targetURL;

    }

}
