<form class="form-boxes m-0 p-0" action="" [formGroup]="commissionForm">
    <div class="modal-header pb-0">
        <button type="button" class="close" aria-label="Close" (click)="closeModal()">
            <span aria-hidden="true">&times;</span>
          </button>
    </div>
    <div class="modal-body mb-5">
        <h5 class="modal-title">{{ 'editcommission.Modifica el precio de la comisión' | translate}}</h5>
        <div class="row mt-5">
            <div class="col-12 col-md-6">
                <div class="field pb-2">
                    <input formControlName="commission" type="number" placeholder="{{defaultCommissionFare}}">
                    <label for="language">{{ 'editcommission.Comisión' | translate }} (€)</label>
                </div>
                <app-feedback [formGroup]="commissionForm" field="commission" validator="max" feedback="{{ 'editcommission.Indica un número entero entre 0 y 100' | translate }}" [refresh]="refreshValidators"></app-feedback>
                <app-feedback [formGroup]="commissionForm" field="commission" validator="min" feedback="{{ 'editcommission.Indica un número entero entre 0 y 100' | translate }}" [refresh]="refreshValidators"></app-feedback>
                <app-feedback [formGroup]="commissionForm" field="commission" validator="pattern" feedback="{{ 'editcommission.Indica un número entero entre 0 y 100' | translate }}" [refresh]="refreshValidators"></app-feedback>
            </div>
        </div>
    </div>
    <div class="modal-footer border-top-0">
        <input (click)="saveCommission()" type="submit" value="{{ 'editcommission.Guardar datos' | translate }}" class="btn bg-primary-color mb-3 mb-sm-0 mr-4 modal-confirm" />

        <input (click)="closeModal()" type="submit" value="{{ 'editcommission.Cancelar' | translate }}" class="btn mb-3 mb-sm-0 modal-cancel" />

    </div>


</form>



<app-message-panel></app-message-panel>