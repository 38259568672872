import { Component, ViewChild } from '@angular/core';
import { MessagePanelComponent } from '../../../shared/message-panel/message-panel.component';
import { ProfileInteractor } from 'src/app/domain/interactor/profile/profile.interactor';
import { AffiliateDetailsView } from 'src/app/domain/views/affiliate/affiliate-details.view';
import { marker as I18N } from '@biesbjerg/ngx-translate-extract-marker';
import { ServerErrorManager } from '../../../utils/server-error-manager.utils';
import { GenericException } from 'src/app/domain/exceptions/generic.exception';
import { TranslateService } from '@ngx-translate/core';
import { Affiliate } from 'src/app/business/models/affiliate/affiliate.model';
import { NavigatorUtils } from 'src/app/ui/utils/navigator.utils';
import { ServerErrorExceptionCase } from 'src/app/domain/views/server-error-manager-exception-case.view';
import { AppConstants } from 'src/app/domain/common/app.constants';

@Component({
     selector: 'app-profile-affiliate',
     templateUrl: './profile.component.html'
})
export class AffiliateProfileComponent {


     @ViewChild(MessagePanelComponent)
     private messagePanelComponent: MessagePanelComponent;

     affiliate: Affiliate;

     constructor(
          private profileInteractor: ProfileInteractor,
          private serverErrorManager: ServerErrorManager,
          private translateService: TranslateService ) {

               this.profileInteractor.getAffiliateDetails()
               .subscribe(
                    (response: Affiliate) => {

                         MessagePanelComponent.hideLoading();

                         this.affiliate = response;

                    },
                    (error: GenericException) => {

                         MessagePanelComponent.hideLoading();
                         this.serverErrorManager.manageErrorCode(error, this.messagePanelComponent);

                    }
               )
          }


          onAffiliateDetailsSubmit(affiliateDetails: AffiliateDetailsView) {
               this.messagePanelComponent.showLoading();
               this.profileInteractor.updateAffiliateDetails(affiliateDetails)
               .subscribe(
                    () => {
                         MessagePanelComponent.hideLoading();
                         this.messagePanelComponent.showSuccessMessage(I18N('affiliateprofile.Datos actualizados'))

                    }, (error: GenericException) => {

                         MessagePanelComponent.hideLoading();
                         this.serverErrorManager.manageErrorCode(error, this.messagePanelComponent);
                    }
               )
          }

          onCreateConnectedStripeAccount() {
               this.messagePanelComponent.showLoading();

               this.profileInteractor.createAffiliateStripeAccount()
               .subscribe(
                    (stripeAccountUrl: string) => {
                         MessagePanelComponent.hideLoading();
                         this.messagePanelComponent.showSuccessMessage(
                              I18N('affiliateprofile.Cuenta de Stripe creada correctamente. A continuación será necesario que aceptes la vinculación de la cuenta para recibir pagos.')
                              , () => {
                                   NavigatorUtils.goToExternalURL(stripeAccountUrl);
                              }
                         );

                    }, (error: GenericException) => {

                         MessagePanelComponent.hideLoading();
                         this.serverErrorManager.manageErrorCode(error, this.messagePanelComponent);
                    }
               )
          }


          onConnectAccount() {
               this.messagePanelComponent.showLoading();

               this.profileInteractor.connectAffiliateStripeAccount()
               .subscribe(
                    (stripeAccountUrl: string) => {
                         MessagePanelComponent.hideLoading();
                         this.messagePanelComponent.showSuccessMessage(
                              I18N('providerprofile.A continuación será necesario que aceptes la vinculación de la cuenta para recibir pagos.')
                              , () => {
                                   NavigatorUtils.goToExternalURL(stripeAccountUrl);
                              }
                         );

                    }, (error: GenericException) => {

                         MessagePanelComponent.hideLoading();

                         const exceptionCases = new Array<ServerErrorExceptionCase>();

                         const exceptionCodes = new Array<number>();
                         exceptionCodes.push(AppConstants.ERROR_CLIENT_BAD_REQUEST);
                         exceptionCases.push( new ServerErrorExceptionCase(
                              exceptionCodes,
                              new Array<string>(),
                              () => {
                                   this.messagePanelComponent.showErrorMessage(
                                        I18N('providerprofile.No hemos podido finalizar la acción. Es posible que la información se esté actualizando. Por favor, inténtalo de nuevo.'),
                                        () => {
                                             NavigatorUtils.reloadApplication();
                                        })
                                   }
                              )
                         );

                         this.serverErrorManager.manageErrorCode(error, this.messagePanelComponent, exceptionCases);

                    }
               )
          }


          onRemoveStripeAccount() {
               this.messagePanelComponent.showLoading();

               this.profileInteractor.removeAffiliateStripeAccount()
               .subscribe(
                    () => {
                         MessagePanelComponent.hideLoading();
                         this.messagePanelComponent.showSuccessMessage(
                              I18N('providerprofile.Cuenta de Stripe desvinvulada correctamente.')
                              , () => {
                                   NavigatorUtils.reloadApplication();
                              }
                         );

                    }, (error: GenericException) => {

                         MessagePanelComponent.hideLoading();
                         this.serverErrorManager.manageErrorCode(error, this.messagePanelComponent);
                    }
               )
          }



          onUpdateStripeAccount() {

               this.messagePanelComponent.showLoading();

               this.translateService.get('providerprofile.Actualiza tu información de Stripe').subscribe(
                    (titleMessageTranslated: string) => {
                         this.translateService.get(I18N('providerprofile.Indica el ID de tu cuenta de Stripe conectada'), {})
                         .subscribe(
                              (subtitleMessageTranslated: string) => {
                                   this.translateService.get(I18N('providerprofile.Texto demasiado largo'), {})
                                   .subscribe(
                                        (validationTooLongMessageTranslated: string) => {

                                             MessagePanelComponent.hideLoading();

                                             this.messagePanelComponent.showInputMessage(
                                                  titleMessageTranslated,
                                                  subtitleMessageTranslated,
                                                  MessagePanelComponent.MESSAGE_INPUT_TYPE_TEXT,
                                                  null,
                                                  (stripeAccountId: string) => {
                                                       if (stripeAccountId.length > AppConstants.TEXT_DEFAULT_MAX_LENGTH) {
                                                            return validationTooLongMessageTranslated;
                                                       }
                                                  },
                                                  (stripeAccountId: string) => {

                                                       this.messagePanelComponent.showLoading();

                                                       this.profileInteractor.updateAffiliateStripeAccount(stripeAccountId)
                                                       .subscribe(
                                                            () => {

                                                                 MessagePanelComponent.hideLoading();
                                                                 this.messagePanelComponent.showSuccessMessage(I18N('providerprofile.Información actualizada'), () => { NavigatorUtils.reloadApplication(); });

                                                            },
                                                            (error: GenericException) => {
                                                                 MessagePanelComponent.hideLoading();
                                                                 this.serverErrorManager.manageErrorCode(error, this.messagePanelComponent);
                                                            }
                                                       )
                                                  }
                                             )

                                        }
                                   )
                              }
                         )
                    }
               )
          }


          onUnregisterRequest() {

               this.messagePanelComponent.showLoading();

               this.profileInteractor.requestAffiliateUnregister()
               .subscribe(
                    () => {
                         MessagePanelComponent.hideLoading();
                         this.messagePanelComponent.showSuccessMessage(I18N('affiliateprofile.Solicitud enviada. Pronto recibirás la confirmación en tu email'))

                    }, (error: GenericException) => {

                         MessagePanelComponent.hideLoading();
                         this.serverErrorManager.manageErrorCode(error, this.messagePanelComponent);
                    }
               )
          }

}



