
export class GuideDetailsView {

    private _id: number;
    private _bussinesName: string;
    private _businessAddress: string;
    private _businessDocumentType: string;
    private _businessDocument: string;
    private _businessZeroVat: boolean;
    private _phone: string;
    private _country: number;
    private _city: number;

    constructor(
      id: number,
      bussinesName: string,
      businessAddress: string,
      businessDocumentType: string,
      businessDocument: string,
      businessZeroVat: boolean,
      phone: string,
      country: number,
      city: number,
    ) {

        this._id = id;
        this._bussinesName = bussinesName;
        this._businessAddress = businessAddress;
        this._businessDocumentType = businessDocumentType;
        this._businessDocument = businessDocument;
        this._businessZeroVat = businessZeroVat;
        this._phone = phone;
        this._country = country;
        this._city = city;
    }


    get id(): number {
        return this._id;
    }

    get bussinesName(): string {
        return this._bussinesName;
    }

    get businessAddress(): string {
        return this._businessAddress;
    }

    get businessDocumentType(): string {
        return this._businessDocumentType;
    }

    get businessDocument(): string {
        return this._businessDocument;
    }

    get businessZeroVat(): boolean {
        return this._businessZeroVat;
    }

    get phone(): string {
        return this._phone;
    }

    get country(): number {
        return this._country;
    }

    get city(): number {
        return this._city;
    }

}