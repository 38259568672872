import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { Pagination } from 'src/app/domain/views/pagination.view';
import { TourAffiliateLink } from 'src/app/business/models/tour/tour-affiliate-link.model';
import { TourResumeAffiliate } from 'src/app/business/models/tour/tour-resume-affiliate.model';
import { TourState } from 'src/app/business/models/tour/common/tour-state.constants';

@Component({
     selector: 'app-affiliate-tours-cards',
     templateUrl: './cards.component.html'
})
export class AffiliateToursCardsComponent implements OnInit {

     @Input() tours: Array<TourResumeAffiliate>;
     @Input() tourStateActive: TourState;
     @Input() pagination: Pagination;
     @Output() viewTourLink = new EventEmitter<Array<TourAffiliateLink>>();
     @Output() createBookingLink = new EventEmitter<number>();

     constructor() { }

     ngOnInit() { }

     onViewTourLink(affiliateLinks: Array<TourAffiliateLink>) {
          this.viewTourLink.emit(affiliateLinks);
     }

     onCreateBookingLink(tourId: number) {
          this.createBookingLink.emit(tourId);
     }


}
