export class ProfilePaymentView {

    private _id: number;
    private _businessName: string;
    private _businessDocumentType: string;
    private _businessDocument: string;
    private _businessAddress: string;
    private _businessZeroVat: boolean;


    constructor(
        id: number,
        businessName: string,
        businessDocumentType: string,
        businessDocument: string,
        businessAddress: string,
        businessZeroVat: boolean,
    ) {

        this._id = id
        this._businessName = businessName
        this._businessDocumentType = businessDocumentType
        this._businessDocument = businessDocument
        this._businessAddress = businessAddress
        this._businessZeroVat = businessZeroVat;
    }


    get id(): number {
        return this._id;
    }

    get businessName(): string {
        return this._businessName;
    }

    get businessDocumentType(): string {
        return this._businessDocumentType;
    }

    get businessDocument(): string {
        return this._businessDocument;
    }

    get businessAddress(): string {
        return this._businessAddress;
    }

    get businessZeroVat(): boolean {
        return this._businessZeroVat;
    }

}
