import { Component, OnInit } from '@angular/core';
import { ChunkGeneric } from 'src/app/domain/views/chunk-generic.view';

import { Router } from '@angular/router';
import { NavigationConstants } from 'src/app/ui/commons/navigation.constants';
import { Pagination } from 'src/app/domain/views/pagination.view';
import { TranslateService } from '@ngx-translate/core';
import { marker as I18N } from '@biesbjerg/ngx-translate-extract-marker';
import { AdminAffiliateInteractor } from 'src/app/domain/interactor/admin/affiliate.interactor';
import { AffiliateResume } from 'src/app/business/models/affiliate/affiliate-resume.model';


@Component({
  selector: 'app-list-affiliates',
  templateUrl: './list.component.html'
})
export class ListAffiliatesComponent implements OnInit  {

  affiliates: Array<AffiliateResume>;
  pagination: Pagination;

  desktopVersion: boolean;
  initialWindowSize: number;

  paginationNextLabel: string;
  paginationPreviousLabel: string;


  constructor(
       private adminAffiliateInteractor: AdminAffiliateInteractor,
       private translateService: TranslateService,
       private router: Router ) {

      this.pagination = new Pagination(0, 1, 20);
      this.affiliates = new Array<AffiliateResume>();
      this.desktopVersion = true;

  }



  ngOnInit() {

      this.initialWindowSize = window.innerWidth;
      this.loadProviders(1);

      this.translateService.get(I18N('pagination.Siguiente')).subscribe(
           (messageTranslated: string) => {
                this.paginationNextLabel = messageTranslated;
           }
      )

      this.translateService.get(I18N('pagination.Previous')).subscribe(
           (messageTranslated: string) => {
                this.paginationPreviousLabel = messageTranslated;
           }
      )


  }


  onDesktopVersionUpdated( isDesktopVersion: boolean ) {
      this.desktopVersion = isDesktopVersion;
  }



  createAffiliate() {
      this.router.navigate( [ NavigationConstants.getNavigationAdminCreateAffiliate() ] );
  }


  onPageChanged( currentPage: number ) {
      this.loadProviders(currentPage);
  }


  loadProviders(currentPage: number) {
      this.adminAffiliateInteractor.findAffiliates(currentPage)
        .subscribe(
            (chunkResult: ChunkGeneric<AffiliateResume>) => {

                this.pagination = new Pagination(chunkResult.pagination.totalItems, chunkResult.pagination.currentPage, chunkResult.pagination.itemsPerPage);
                this.affiliates = chunkResult.items

            },
            () => {

            }

        )
  }


  onAffiliateClick( affiliateId: number ) {

      this.router.navigate([ NavigationConstants.getNavigationAdminAffiliateDetail(affiliateId) ]);

  }

}
