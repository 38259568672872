import { FormGroup, FormControl, FormArray } from '@angular/forms';
import { TourSchedulePlanningFormView } from 'src/app/domain/views/provider/tour/schedule-planning-form.view';
import { TourScheduleWeekdaysType } from 'src/app/domain/common/tour/tour-schedule-weekdays.constants';
import { TourSchedulePlanningDayFormView } from 'src/app/domain/views/provider/tour/schedule-planning-day-form.view';
import { TourFormView } from 'src/app/domain/views/provider/tour/tour-form.view';
import { Tour } from 'src/app/business/models/tour/tour.model';
import { TourDetailsFormView } from 'src/app/domain/views/provider/tour/details-form.view';
import { TourAttractionFormView } from 'src/app/domain/views/provider/tour/attraction-form.view';
import { Attraction } from 'src/app/business/models/tour/attraction.model';
import { TourUsersFormView } from 'src/app/domain/views/provider/tour/users-form.view';
import { TourFareFormView } from 'src/app/domain/views/provider/tour/fare-form.view';
import { Fare } from 'src/app/business/models/tour/fare.model';
import { TourLocationFormView } from 'src/app/domain/views/provider/tour/location-form.view';
import { TourScheduleFormView } from 'src/app/domain/views/provider/tour/schedule-form.view';
import { TourScheduleSeasonFormView } from 'src/app/domain/views/provider/tour/schedule-season-form.view';
import { SchedulePlanningDay } from 'src/app/business/models/tour/schedule-planning-day.model';
import { ScheduleSeason } from 'src/app/business/models/tour/schedule-season.model';

export class TourUtils {



  public static createWeekdayFormGroup(day: any) {

      return new FormGroup({
          'day': new FormControl(day),
          'hours': new FormArray([new FormControl('')])
      })
  }


  public static getFormGroupsFromTourSchedulePlanningFormView(schedulePlanningView: TourSchedulePlanningFormView): [Array<FormGroup>, Array<FormGroup>] {

      //Week days
      const days: Array<FormGroup> = new Array<FormGroup>();
      schedulePlanningView.days.map( (day: TourSchedulePlanningDayFormView ) => {

            const hoursFormArray = new FormArray([]);
            day.hours.map( (hour: string) => {
                  hoursFormArray.push(new FormControl(hour));
            });

            days.push(new FormGroup({
                'day': new FormControl(day.day),
                'hours': hoursFormArray
            }));

      });


      //Exceptions
      const dayExceptions: Array<FormGroup> = new Array<FormGroup>();
      schedulePlanningView.dayExceptions.map( (dayException: TourSchedulePlanningDayFormView ) => {

          const hoursFormArray = new FormArray([]);
          dayException.hours.map( (hour: string) => {
                if ( hour != '' ) {
                    hoursFormArray.push(new FormControl(hour));
                }
          });

          dayExceptions.push(new FormGroup({
              'day': new FormControl(dayException.day),
              'hours': hoursFormArray
          }));

      });

      return [days, dayExceptions];
  }


  public static getDaysFromTourSchedulePlanningFormArray(schedulePlanningFormArray: FormArray): Array<TourScheduleWeekdaysType> {

      const days: Array<TourScheduleWeekdaysType> = new Array();
      schedulePlanningFormArray.controls.map( (formGroup: FormGroup) => {
          days.push(formGroup.value.day);
      })

      return days;

  }

  public static getExceptionDaysFromTourSchedulePlanningFormArray(schedulePlanningFormArray: FormArray): Array<string> {

      const exceptionDays: Array<string> = new Array();
      schedulePlanningFormArray.controls.map( (formGroup: FormGroup) => {
          exceptionDays.push(formGroup.value.day);
      })

      return exceptionDays;

  }

  public static getSchedulePlanningFormArrayFromDays(schedulePlanningFormArray: FormArray): Array<string> {

      const exceptionDays: Array<string> = new Array();
      schedulePlanningFormArray.controls.map( (formGroup: FormGroup) => {
          exceptionDays.push(formGroup.value.day);
      })

      return exceptionDays;

  }


  public static getTourFromViewToUpdate(tour: Tour, providerId: number): TourFormView {

      const tourFormView: TourFormView = new TourFormView();

      tourFormView.id = tour.id;
      tourFormView.languageIdI18N = tour.languageI18N.id;
      tourFormView.providerId = providerId;

      //DETAILS
      const attractions = new Array<TourAttractionFormView>();
      tour.attractions.map( (attraction: Attraction) => {
          attractions.push(new TourAttractionFormView(attraction.id, attraction.name, attraction.nameMain));
      })
      tourFormView.tourDetails = new TourDetailsFormView(
          tour.name,
          tour.nameMain,
          tour.languageTourGiven.id,
          tour.type.id,
          tour.duration,
          tour.country.id,
          tour.city.id,
          tour.description,
          tour.descriptionMain,
          tour.descriptionShort,
          tour.descriptionShortMain,
          tour.additionalInformation,
          tour.additionalInformationMain,
          attractions
      );



      //USERS
      const fares: Array<TourFareFormView> = new Array<TourFareFormView>();
      tour.fares.map( (fare: Fare) => {
        fares.push(new TourFareFormView(fare.id, fare.name, fare.nameMain, fare.price));
      })
      tourFormView.tourUsers = new TourUsersFormView(
          tour.minPeople,
          tour.maxPeople,
          tour.cancellationLimit,
          tour.bookingLimit,
          tour.maxPeoplePerBooking,
          tour.isFree,
          tour.maxFreePeople,
          tour.maxFreePeoplePrice,
          tour.maxFreePeopleAge,
          fares
      );

      //LOCATION
      tourFormView.tourLocation = new TourLocationFormView(
          tour.meetingPoint,
          tour.meetingPointMain,
          tour.locationCoordX,
          tour.locationCoordY,
          tour.locationIndications,
          tour.locationIndicationsMain,
          tour.locationMoreIndications,
          tour.locationMoreIndicationsMain
      );


      const fixedDays = new Array<TourSchedulePlanningDayFormView>();
      const fixedDayExceptions = new Array<TourSchedulePlanningDayFormView>();
      if ( tour.schedule.fixed ) {
          tour.schedule.fixed.days.map( (day: SchedulePlanningDay) => {
              fixedDays.push(new TourSchedulePlanningDayFormView(day.day, day.hours));
          });
          tour.schedule.fixed.dayExceptions.map( (dayException: SchedulePlanningDay) => {
              fixedDayExceptions.push(new TourSchedulePlanningDayFormView(dayException.day, dayException.hours));
          });
      }

      const seasons = new Array<TourScheduleSeasonFormView>();
      if ( tour.schedule.seasons ) {
          tour.schedule.seasons.map( (season: ScheduleSeason) => {
              const seasonDays = new Array<TourSchedulePlanningDayFormView>();
              const seasonDayExceptions = new Array<TourSchedulePlanningDayFormView>();

              season.details.days.map( (day: SchedulePlanningDay) => {
                  seasonDays.push(new TourSchedulePlanningDayFormView(day.day, day.hours));
              });
              season.details.dayExceptions.map( (dayException: SchedulePlanningDay) => {
                  seasonDayExceptions.push(new TourSchedulePlanningDayFormView(dayException.day, dayException.hours));
              });

              seasons.push(new TourScheduleSeasonFormView(season.startDay, season.finishDay, new TourSchedulePlanningFormView(seasonDays, seasonDayExceptions)));
          });
      }



      tourFormView.tourSchedule = new TourScheduleFormView(
          tour.schedule.type,
          new TourSchedulePlanningFormView(fixedDays, fixedDayExceptions),
          seasons
      );

      return tourFormView;

  }


}