<form class="form-boxes m-0 p-0" [formGroup]="guideDetailsForm">

     <!-- Empresa -->
     <div class="row mb-2">
          <div class="col-10">
               <h2 class="font-weight-bold d-flex"><img class="ic-mid mr-2" src="assets/img/ic_user.svg">{{ 'guidedetails.Tus datos' | translate }}</h2>
               <h5>{{ 'guidedetails.Información de contacto y notificaciones' | translate }}</h5>
               <hr class="w-10 float-left mt-2 mb-4">
          </div>
          <div class="col-2 text-right">
               <a class="cursor-pointer" (click)="toggleEdition()">
                    <img class="ic-mid mr-2" src="{{ isDisabled ? 'assets/img/ic_edit.svg' : 'assets/img/ic_edit-disabled.svg'}}">
               </a>
          </div>
     </div>

     <div class="row mb-3">
          <div class="col-12 col-md-6">
               <div class="field pb-2">
                    <input formControlName="companyName" type="text" name="companyName" id="companyName" placeholder="{{ 'guidedetails.Nombre completo' | translate }}" value="">
                    <label for="companyName">{{ 'guidedetails.Nombre completo' | translate }}</label>
               </div>
               <app-feedback [formGroup]="guideDetailsForm" field="companyName" validator="required" feedback="{{ 'guidedetails.Nombre requerido' | translate }}" [refresh]="refreshValidators"></app-feedback>
               <app-feedback [formGroup]="guideDetailsForm" field="companyName" validator="maxlength" feedback="{{ 'guidedetails.Texto demasiado largo' | translate }}" [refresh]="refreshValidators"></app-feedback>
          </div>

          <div class="col-12 col-md-6">
             <div class="field pb-2">
                 <input formControlName="address" type="text" name="address" id="address" placeholder="{{ 'guidedetails.Dirección' | translate }}" value="">
                 <label for="address">{{ 'guidedetails.Dirección' | translate }}</label>
             </div>
             <app-feedback [formGroup]="guideDetailsForm" field="address" validator="required" feedback="{{ 'guidedetails.Dirección requerida' | translate }}" [refresh]="refreshValidators"></app-feedback>
         </div>

          <div class="col-12 col-md-6">
               <div class="field pb-2">
                    <select formControlName="country" name="country" (change)="onCountrySelected()">
                         <option [ngValue]="null">{{ 'guidedetails.Selecciona la opción' | translate }}</option>
                         <option *ngFor="let country of countries" value="{{ country.id }}">{{ country.name }}</option>
                    </select>
                    <label for="country">{{ 'guidedetails.País' | translate }}</label>
               </div>
               <app-feedback [formGroup]="guideDetailsForm" field="country" validator="required" feedback="{{ 'guidedetails.País requerido' | translate }}" [refresh]="refreshValidators"></app-feedback>
          </div>

          <div class="col-12 col-md-6">
               <div class="field pb-2">
                    <select formControlName="city" name="city">
                         <option [ngValue]="null">{{ 'guidedetails.Selecciona la opción' | translate }}</option>
                         <option *ngFor="let city of cities" value="{{ city.id }}">{{ city.name }}</option>
                    </select>
                    <label for="city">{{ 'guidedetails.Ciudad' | translate }}</label>
               </div>
               <app-feedback [formGroup]="guideDetailsForm" field="city" validator="required" feedback="{{ 'guidedetails.Ciudad requerida' | translate }}" [refresh]="refreshValidators"></app-feedback>
          </div>

          <div class="col-12 col-md-6">
             <div class="field pb-2">
                  <select formControlName="businessDocumentType" name="businessDocumentType">
                     <option value="">{{ 'guidedetails.Selecciona la opción' | translate }}</option>
                     <option value="CIF">{{ 'providerpayment.CIF' | translate }}</option>
                     <option value="VAT">{{ 'providerpayment.VAT' | translate }}</option>
                     <option value="Pasport">{{ 'guidedetails.Pasaporte' | translate }}</option>
                  </select>
                 <label for="businessDocumentType">{{ 'guidedetails.Tipo de documento' | translate }}</label>
             </div>
             <app-feedback [formGroup]="guideDetailsForm" field="businessDocumentType" validator="required" feedback="{{ 'guidedetails.Tipo de documento requerido' | translate }}" [refresh]="refreshValidators"></app-feedback>
         </div>

         <div class="col-12 col-md-6">
            <div class="field pb-2">
                <input formControlName="businessDocument" type="text" name="businessDocument" id="businessDocument" placeholder="{{ 'providerpayment.Número de documento' | translate }}" value="">
                <label for="businessDocument">{{ 'guidedetails.Número de documento' | translate }}</label>
            </div>
            <app-feedback [formGroup]="guideDetailsForm" field="businessDocument" validator="required" feedback="{{ 'guidedetails.Número de documento requerido' | translate }}" [refresh]="refreshValidators"></app-feedback>
        </div>

         <div class="col-12 col-md-6">
              <div class="field pb-2">
                   <input formControlName="phone" type="phone" name="phone" id="phone" placeholder="{{ 'guidedetails.Teléfono' | translate }}" value="">
                   <label for="phone">{{ 'guidedetails.Teléfono' | translate }}</label>
              </div>
              <app-feedback [formGroup]="guideDetailsForm" field="phone" validator="required" feedback="{{ 'guidedetails.Teléfono requerido' | translate }}" [refresh]="refreshValidators"></app-feedback>
              <app-feedback [formGroup]="guideDetailsForm" field="phone" validator="maxlength" feedback="{{ 'createtourdetails.Texto demasiado largo' | translate }}" [refresh]="refreshValidators"></app-feedback>
         </div>


         <div class="col-12 col-md-6 d-flex mt-4 pt-3">
            <div class="checkbox-custom mr-2">
                <input formControlName="businessZeroVat" type="checkbox" name="businessZeroVat" id="businessZeroVat" value="">
                <div class="checkbox-custom-visible"></div>
            </div>
            <label for="businessZeroVat">{{ 'guidedetails.Exento de IVA' | translate }}</label>
        </div>

     </div>

     <div class="row mb-5 pb-5">
          <div class="col-12 col-md-6 offset-md-6 col-lg-4 offset-lg-8">
               <input (click)="saveDetails()" type="submit" value="{{ 'guidedetails.Guardar datos' | translate }}" class="btn btn-full-width btn-primary mb-3 mb-sm-0" [disabled]="isDisabled" />
          </div>
     </div>
</form>

<app-message-panel [preloading]="false"></app-message-panel>