import { Pipe, PipeTransform } from '@angular/core';
import { TourScheduleWeekdaysType } from 'src/app/domain/common/tour/tour-schedule-weekdays.constants';
import { marker as I18N } from '@biesbjerg/ngx-translate-extract-marker';

@Pipe({
  name: 'createTourWeekdayLabel'
})
export class ProviderCreateTourWeekdayLabelPipe implements PipeTransform {

  transform(label: string, weekday: TourScheduleWeekdaysType): String {

    let result = label
    if ( weekday == TourScheduleWeekdaysType.Monday ) {
        result = I18N('L');
    } else if ( weekday == TourScheduleWeekdaysType.Tuesday ) {
        result = I18N('M');
    } else if ( weekday == TourScheduleWeekdaysType.Wednesday ) {
        result = I18N('M');
    } else if ( weekday == TourScheduleWeekdaysType.Thursday ) {
        result = I18N('J');
    } else if ( weekday == TourScheduleWeekdaysType.Friday ) {
        result = I18N('V');
    } else if ( weekday == TourScheduleWeekdaysType.Saturday ) {
        result = I18N('S');
    } else if ( weekday == TourScheduleWeekdaysType.Sunday ) {
        result = I18N('D');
    }

    return result ;

  }

}
