import { Country } from '../master/country/country.model';
import { City } from '../master/city/city.model';
import { Language } from '../master/language/language.model';
import { ImageDetails } from '../image/image-details.model';
import { ProviderResume } from '../provider/provider-resume.model';

export class Event {

     private _tourId: number;
     private _name: string;
     private _languageTourGiven: Language;
     private _country: Country;
     private _city: City;
     private _isFree: boolean;
     private _image: ImageDetails;
     private _day: string;
     private _hour: string;
     private _state: number;
     private _provider: ProviderResume;

     constructor(
          tourId: number,
          name: string,
          languageTourGiven: Language,
          country: Country,
          city: City,
          isFree: boolean,
          image: ImageDetails,
          day: string,
          hour: string,
          state: number,
          provider: ProviderResume
     ) {
          this._tourId = tourId;
          this._name = name;
          this._languageTourGiven = languageTourGiven;
          this._country = country;
          this._city = city;
          this._isFree = isFree;
          this._image = image;
          this._day = day;
          this._hour = hour;
          this._state = state;
          this._provider = provider;
     }


     get tourId(): number {
          return this._tourId;
     }

     get name(): string {
          return this._name;
     }

     get languageTourGiven(): Language {
          return this._languageTourGiven;
     }

     get country(): Country {
          return this._country;
     }

     get city(): City {
          return this._city;
     }

     get isFree(): boolean {
          return this._isFree;
     }

     get image(): ImageDetails {
          return this._image;
     }

     get day(): string {
          return this._day;
     }

     get hour(): string {
          return this._hour;
     }

     get state(): number {
          return this._state;
     }

     get provider(): ProviderResume {
          return this._provider;
     }

}