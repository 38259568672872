<div id="publicLanding">

    <div class="row bg-grey-color header-simple">
        <div class="col-7 col-md-10">
            <a (click)="onHeaderItemSelected()"><img class="logo pr-4 pr-lg-5" src="assets/img/logo-white.png"></a>
            <!-- <app-public-navigation></app-public-navigation> -->
        </div>
    </div>


    <!-- Content -->
    <div *ngIf="tour" class="landing-page bg-section-gray h-100">
        <div class="row section-review">
            <div class="col-12 col-lg-8 offset-lg-2 text-left text-lg-center  white-color dark-lg-color px-0">
                <h1>{{ 'review.Tu opinión importa' | translate }}</h1>
            </div>
        </div>
        <div class="animated fadeIn row row-overlay">
            <div class="col-12 col-lg-8 offset-lg-2 card card-primary">
                <div class="row mb-2">
                    <div class="col-12">
                        <h3 class="font-weight-bold">{{ 'review.¿Cómo calificarías tu experiencia en el tour?' | translate }}</h3>
                        <p class="mb-0">{{ 'review.Gracias por confiar en nosotros. Queremos contar con tu opinión para seguir mejorando.' | translate }}</p>
                        <hr class="w-10 float-left mb-4">
                    </div>
                </div>
                <div class="row" if>
                    <div class="col-12 card card-list" [formGroup]="reviewForm">
                        <div class="row">
                            <div class="col-12 col-lg-3">
                                <img class="img-card-list h-100" src="assets/img/listado-tours_example_1.png">
                            </div>
                            <div class="col-12 col-lg-9">
                                <div class="row">
                                    <div class="col-12 mt-2">
                                        <h5 class="font-weight-bold">{{ tour.name | I18NTranslationSave: tour.nameMain }}</h5>
                                        <p class="text-body-small grey-color">{{ tour.city.name }}, {{ tour.country.name }}</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12">
                                        <p class="text-body-mid mb-0">{{ tour.descriptionShort | I18NTranslationSave: tour.descriptionShortMain }}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row mt-4">
                            <div class="col-12 d-flex justify-content-center">
                                <div class="rating">
                                    <!--elements are in reversed order, to allow "previous sibling selectors" in CSS-->
                                    <input formControlName="rating" type="radio" name="rating" value="5" id="5"><label for="5"><img src="assets/img/ic_star.svg"></label>
                                    <input formControlName="rating" type="radio" name="rating" value="4" id="4"><label for="4"><img src="assets/img/ic_star.svg"></label>
                                    <input formControlName="rating" type="radio" name="rating" value="3" id="3"><label for="3"><img src="assets/img/ic_star.svg"></label>
                                    <input formControlName="rating" type="radio" name="rating" value="2" id="2"><label for="2"><img src="assets/img/ic_star.svg"></label>
                                    <input formControlName="rating" type="radio" name="rating" value="1" id="1"><label for="1"><img src="assets/img/ic_star.svg"></label>
                                </div>
                            </div>
                            <app-feedback [formGroup]="reviewForm" field="rating" validator="required" feedback="{{ 'review.Califica la experiencia' | translate }}" [refresh]="refreshValidators" class="mx-auto pt-3"></app-feedback>
                            <app-feedback [formGroup]="reviewForm" field="rating" validator="min" feedback="{{ 'review.Califica la experiencia' | translate }}" [refresh]="refreshValidators" class="mx-auto pt-3"></app-feedback>
                            <app-feedback [formGroup]="reviewForm" field="rating" validator="max" feedback="{{ 'review.Califica la experiencia' | translate }}" [refresh]="refreshValidators" class="mx-auto pt-3"></app-feedback>
                        </div>

                        <div class="row mt-5">
                            <!-- <form class="form-boxes m-0 p-0"> -->
                            <div class="col-12">
                                <div class="field pb-2 d-grid">
                                    <label for="comments">{{ 'review.Escribe tu opinión:' | translate }}<span class="font-italic font-weight-normal">{{ '(Opcional)' | translate }}</span></label>
                                    <textarea formControlName="comments" type="text" name="comments" id="comments" rows="3" cols=""></textarea>
                                </div>
                                <app-feedback [formGroup]="reviewForm" field="comments" validator="maxlength" feedback="{{ 'review.Texto demasiado largo' | translate }}" [refresh]="refreshValidators"></app-feedback>
                            </div>
                            <!-- </form> -->
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-12">
                        <input type="submit" value="{{ 'review.Enviar' | translate }}" (click)="sendReview()" class="btn btn-full-width btn-primary btn-rating mb-3 mb-sm-0" />
                    </div>
                </div>
            </div>
        </div>

    </div>
    <!-- end content -->
    <!--
    <div class="row footer-simple bg-grey-color">
        <div class="col-12 text-right">
            <a class="mr-3"><img class="img-fluid" src="assets/img/ic_social-twitter-simple.svg"></a>
            <a class="mr-3"><img class="img-fluid" src="assets/img/ic_social-facebook-simple.svg"></a>
            <a class="mr-3"><img class="img-fluid" src="assets/img/ic_social-linkedin-simple.svg"></a>
        </div>
    </div>
    -->

    <app-message-panel [preloading]="true"></app-message-panel>

</div>