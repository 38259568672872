import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { JwtHelperService } from '@auth0/angular-jwt';
import { BaseService } from './base.service';
import { User } from '../models/user/user.model';
import { UserUtils } from '../models/user/common/user.utils';
import { CommonApiEndpointsUtils } from '../api/endpoints/common-api-endponints.utils';

@Injectable({
     providedIn: 'root'
})
export class AuthenticateService extends BaseService {


     loginUser(email: string, password: string): Observable<User> {
          return super.sendPostRequest(CommonApiEndpointsUtils.getLoginEndpoint(), { 'username': email, 'password': password }, CommonApiEndpointsUtils.getLoginEndpointAcceptVersion())
          .pipe(
               map((response: any) => {

                    const jwtService = new JwtHelperService();
                    const jwtDecoded = jwtService.decodeToken(response.access_token);

                    return new User(jwtDecoded.username, UserUtils.getUserRoleFromApi(response.scope), 1, response.access_token, response.refresh_token, jwtDecoded.languageCode);
               })
          )
     }



     rememberPassword(email: string): Observable<boolean> {

          return super.sendPostRequest(CommonApiEndpointsUtils.getRememberPasswordEndpoint(), { 'username': email }, CommonApiEndpointsUtils.getRememberPasswordEndpointAcceptVersion())
          .pipe(
               map(() => {
                    return true
               })
          )


     }



     changePasswordRemembered(username: string, verificationCode: string, newPassword: string, newPasswordConfirm: string): Observable<boolean> {

          return super.sendPutRequest(CommonApiEndpointsUtils.getRememberPasswordConfirmationEndpoint(), { 'username': username, 'verificationCode': verificationCode, 'newPassword': newPassword, 'newPasswordConfirm': newPasswordConfirm }, CommonApiEndpointsUtils.getRememberPasswordConfirmationEndpointAcceptVersion())
          .pipe(
               map(() => {
                    return true
               })
          )


     }





     singupProvider(
          email: string,
          verificationCode: string,
          newPassword: string,
          newPasswordConfirm: string,
          languageId: number,
          web: string,
          phone: string,
          country: number,
          city: number,
          contactPerson: string,
          contactEmail: string,
          bookingNotificationEmailActive: boolean,
          bookingNotificationEmail: string,
          bookingNotificationEmail2: string,
          businessName: string,
          businessDocumentType: string,
          businessDocument: string,
          businessAddress: string,
          businessZeroVat: boolean
     ) {
          return super.sendPutRequest(CommonApiEndpointsUtils.getSingupProviderEndpoint(),
               CommonApiEndpointsUtils.getSingupProviderObject(
                    email, verificationCode, newPassword, newPasswordConfirm,
                    languageId, web, phone, country, city, contactPerson,
                    contactEmail, bookingNotificationEmailActive, bookingNotificationEmail, bookingNotificationEmail2,
                    businessName, businessDocumentType, businessDocument, businessAddress, businessZeroVat
               ),
               CommonApiEndpointsUtils.getSingupProviderEndpointAcceptVersion()
          )
          .pipe(
               map(() => {
                    return true
               })
          )
     }




     singupAffiliate(
          email: string,
          newPassword: string,
          newPasswordConfirm: string,
          languageId: number,
          websites: Array<string>,
          phone: string,
          country: number,
          city: number,
          companyName: string,
          businessDocumentType: string,
          businessDocument: string,
          businessAddress: string,
          businessZeroVat: boolean
     ) {
          return super.sendPostRequest(CommonApiEndpointsUtils.getSingupAffiliateEndpoint(),
               CommonApiEndpointsUtils.getSingupAffiliateObject(email, newPassword, newPasswordConfirm, languageId, websites, phone,
                    country, city, companyName, businessDocumentType, businessDocument, businessAddress, businessZeroVat),
               CommonApiEndpointsUtils.getSingupProviderEndpointAcceptVersion()
          )
          .pipe(
               map(() => {
                    return true
               })
          )
     }

}
