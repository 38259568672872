<div *ngIf="!isGuideRole" class="card table-responsive bg-grey-light-card-color">
    <div class="row">
        <div class="offset-3 col-5 offset-sm-6 col-sm-3 title-secondary text-center mb-3 pr-0 pr-sm-3">
            {{ 'detailbooking.Reservas' | translate }}
        </div>

        <div class="col-4 col-sm-2 title-secondary text-center mb-3">
            {{ 'detailbooking.Shows' | translate }}
        </div>
        <div class="col-sm-1"></div>
    </div>
    <div class="row">
        <div class="col-3 col-sm-6 text-left pr-0 pr-sm-3">
            <p class="name-provider mb-2">TOTAL</p>
        </div>
        <div class="col-5 col-sm-3 text-center text-body-secondary  pr-0 pr-sm-3">
            {{ eventDetails.people }}
        </div>
        <div class="col-4 col-sm-2 text-center text-body-secondary green-color">
            {{ eventDetails.shows }}
        </div>
        <div class="col-sm-1"></div>
    </div>
</div>

<div class="row empty-section" *ngIf="!eventDetails.bookings.length">
    <div class="col-12 text-center my-auto">
        <img class="logo mb-4" src="assets/img/logo_opacity-21.png">
        <p>{{ 'detailbooking.Todavía no existen reservas para el día seleccionado' | translate }}</p>
    </div>
</div>

<div class="card table-responsive"
     [class.bg-light]="stateConfirmed != book.state"
     [class.bg-green-light]="isGuideRole && book.guideConfirmationState == bookingGuideConfirmationStateShowed"
     [class.bg-red-light]="isGuideRole && book.guideConfirmationState == bookingGuideConfirmationStateNotShowed"
     *ngFor="let book of eventDetails.bookings">

    <div *ngIf="!isGuideRole" class="row mb-3">
        <div class="col-12 text-right">
            <a *ngIf="stateConfirmed == book.state" (click)="onResendConfirmationEmail(book)" placement="left" triggers="hover" [autoClose]="'outside'" ngbTooltip="{{ 'detailbooking.Reenviar email de confirmación' | translate }}" tooltipClass="tooltip-info">
                <img class="ic-mid mr-3" src="assets/img/ic_resend-mail.svg">
            </a>
            <a *ngIf="stateConfirmed == book.state" (click)="onEditContactDetails(book)" class="d-inline" ngbTooltip="{{ 'detailbooking.Editar datos de contacto' | translate }}" tooltipClass="tooltip-info">
                <img class="ic-mid" src="assets/img/ic_edit.svg">
            </a>
        </div>
    </div>

    <div class="row">
        <div class="col-8 mb-3">
            <p class="email-provider d-block mb-3">{{ book.name }} {{ book.surname }}</p>
            <p class="location-provider d-block mb-2">{{ book.email }}</p>
            <p class="location-provider d-block mb-2">{{ book.phone }}</p>
        </div>
        <div class="col-4 mb-3 pl-0">
             <div class="title-secondary text-right">
                 {{ 'detailbooking.Reservas' | translate }}: {{ book.people }}
             </div>
             <div class="title-secondary text-right">
                 {{ 'detailbooking.Shows' | translate }}: {{ book.shows }}
             </div>
        </div>
    </div>




    <ng-container *ngIf="stateConfirmed == book.state">
         <div class="row pb-3 mt-2 no-gutters" *ngFor="let fare of book.fares">
             <div class="col-5 text-body-small font-weight-bold opacity-50">
                 <span *ngIf="fare.isChildren">{{ 'detailbooking.Niños' | translate }}</span>
                 <span *ngIf="fare.isAdult">{{ 'detailbooking.Adultos' | translate }}</span>
                 <span *ngIf="fare.name">{{ fare.name | I18NTranslationSave: fare.nameMain }}</span>
             </div>
             <div class="col-1 text-center">
                 {{ fare.quantity }}
             </div>
             <div class="col-5 text-center">
                  <a *ngIf="isGuideRole && eventDetails.showsCanBeUpdated" (click)="onDecreaseShowsForBooking(book, fare)" class="guide-booking-modify-show-action mr-2">
                     <img src="assets/img/ic_minus.svg">
                </a>
                <span class="shows-number">{{ fare.shows }}</span>
                <a *ngIf="isGuideRole && eventDetails.showsCanBeUpdated" (click)="onIncreaseShowsForBooking(book, fare)" class="guide-booking-modify-show-action ml-2">
                     <img src="assets/img/ic_plus.svg">
                </a>
             </div>
             <div class="col-1 text-right">
                 <a *ngIf="eventDetails.showsCanBeUpdated" (click)="onEditShows(book, fare)">
                     <img class="ic-mid" src="assets/img/ic_edit.svg">
                 </a>
                 <a *ngIf="!eventDetails.showsCanBeUpdated" placement="left" triggers="click" [autoClose]="'outside'" ngbTooltip="{{ 'detailbooking.Ha expirado el tiempo de edición' | translate }}" tooltipClass="tooltip-info-shows">
                     <img class="ic-mid" src="assets/img/ic_info_outlined.svg">
                 </a>
             </div>
         </div>
        </ng-container>
    <div class="row">
        <div class="col-12 text-right">
            <hr/>

            <a *ngIf="stateConfirmed != book.state" placement="left" [autoClose]="'outside'"  ngbTooltip="{{ 'detailbooking.Reserva cancelada' | translate }}" tooltipClass="tooltip-info-shows">
               <img class="ic-mid" src="assets/img/ic_info_outlined.svg">
            </a>

            <ng-container *ngIf="stateConfirmed == book.state && !isGuideRole">
                 <a *ngIf="eventDetails.bookingsCanBeCancelled" (click)="onCancelBooking(book)" class=" link-primary ml-2">{{ 'detailbooking.Cancelar reserva' | translate }}</a>
                 <a *ngIf="!eventDetails.bookingsCanBeCancelled" placement="left" [autoClose]="'outside'"  ngbTooltip="{{ 'detailbooking.Ha expirado el tiempo de cancelación' | translate }}" tooltipClass="tooltip-info-shows">
                    <img class="ic-mid" src="assets/img/ic_info_outlined.svg">
                 </a>
            </ng-container>

            <ng-container *ngIf="stateConfirmed == book.state && isGuideRole">
                 <div class="row no-gutters">
                      <div class="col-auto text-left">
                           <a (click)="onGuideSetAsShowed(book)" placement="top" [autoClose]="'outside'" ngbTooltip="{{ 'detailbooking.Confirmar asistencia' | translate }}" tooltipClass="tooltip-info-shows">
                              <img  class="pt-1 mr-1 guide-booking-confirm-show-action" src="assets/img/ic_list.svg">
                           </a>
                           <a (click)="onGuideSetAsNotShowed(book)" placement="top" [autoClose]="'outside'" ngbTooltip="{{ 'detailbooking.No presentados' | translate }}" tooltipClass="tooltip-info-shows">
                              <img class="pt-1 ml-1 guide-booking-confirm-show-action" src="assets/img/ic_cancel.svg">
                           </a>
                      </div>

                      <div class="col text-right">
                           <a class="btn btn-outline-primary" (click)="onNewBooking(book)" placement="top" [autoClose]="'outside'" ngbTooltip="{{ 'detailbooking.Reservar otro tour' | translate }}" tooltipClass="tooltip-info-shows">
                                <span class="font-weight-bold primary-color">{{ 'bookEvent.Reservar' | translate }}</span>
                                <img class="ml-2 align-text-top" style="width: 20px" src="assets/img/ic_calendar_corporative.svg">
                           </a>
                      </div>
                 </div>
            </ng-container>



            <!-- If cancel booking -->
            <!-- <span class="tag-primary cancelled-booking">{{ 'detailbooking.Cancelada' | translate }}</span> -->

        </div>
    </div>

</div>