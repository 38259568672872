import { Injectable }                       from '@angular/core';
import { Observable, Observer } from 'rxjs';
import { GenericException } from '../../exceptions/generic.exception';
import { ProviderGuideService } from 'src/app/business/services/provider/guide.service';
import { ChunkGeneric } from '../../views/chunk-generic.view';
import { GuideResume } from 'src/app/business/models/guide/guide-resume.model';
import { Guide } from 'src/app/business/models/guide/guide.model';
import { GuideDetailsView } from '../../views/guide/guide-details.view';
import { CompanyName } from 'src/app/business/models/master/company/company-name.model';
import { TourResume } from 'src/app/business/models/tour/tour-resume.model';
import { SessionInteractor } from '../session.interactor';


@Injectable({
  providedIn: 'root'
})
export class ProviderGuideInteractor {

    constructor(
         private providerGuideService: ProviderGuideService,
         private sessionInteractor: SessionInteractor
    ) {
    }


    createGuide(name: string, email: string, languageId: number, cityId: number): Observable<boolean> {

        return Observable.create((observer: Observer<boolean>) => {
            this.providerGuideService.createGuide(name, email, languageId, cityId)
                  .subscribe(
                      (success: boolean) => {

                          observer.next(success);
                          observer.complete();
                      },
                      (error: GenericException) => {
                          observer.error(error);
                      }
                  )

        });
    }

    findGuides( page: number ): Observable<ChunkGeneric<GuideResume>> {

        return Observable.create((observer: Observer<ChunkGeneric<GuideResume>>) => {
            this.providerGuideService.findGuides(page)
                  .subscribe(
                      (chunkResult: ChunkGeneric<GuideResume>) => {

                          observer.next(chunkResult);
                          observer.complete();
                      },
                      (error: GenericException) => {
                          observer.error(error);
                      }
                  )

        });
    }


    getGuideDetails( guideId: number ): Observable<Guide> {

        return Observable.create((observer: Observer<Guide>) => {
            this.providerGuideService.getGuideDetalis(this.sessionInteractor.getCurrentLanguage(), guideId)
                  .subscribe(
                      (guide: Guide) => {
                          observer.next(guide);
                          observer.complete();
                      },
                      (error: GenericException) => {
                          observer.error(error);
                      }
                  )

        });
    }


    unregisterGuide(guideId: number): Observable<boolean> {

        return Observable.create((observer: Observer<boolean>) => {
            this.providerGuideService.unregisterGuide(guideId)
                  .subscribe(
                      () => {
                          observer.next(true);
                          observer.complete();
                      },
                      (error: GenericException) => {
                          observer.error(error);
                      }
                  )

        });
    }


    registerGuide(guideId: number): Observable<boolean> {

        return Observable.create((observer: Observer<boolean>) => {
            this.providerGuideService.registerGuide(guideId)
                  .subscribe(
                      () => {
                          observer.next(true);
                          observer.complete();
                      },
                      (error: GenericException) => {
                          observer.error(error);
                      }
                  )

        });
    }


    updateGuideDetails(guideDetails: GuideDetailsView): Observable<boolean> {

        return Observable.create((observer: Observer<boolean>) => {
            this.providerGuideService.updateGuideDetails(
                              guideDetails.id, guideDetails.bussinesName,
                              guideDetails.businessAddress, guideDetails.businessDocumentType,
                              guideDetails.businessDocument, guideDetails.businessZeroVat,
                              guideDetails.phone, guideDetails.country,
                              guideDetails.city)
                  .subscribe(
                      () => {
                          observer.next(true);
                          observer.complete();
                      },
                      (error: GenericException) => {
                          observer.error(error);
                      }
                  )

        });
    }


    updateGuideManagedTours(guideId: number, managedTours: Array<TourResume>): Observable<boolean> {

        return Observable.create((observer: Observer<boolean>) => {
            this.providerGuideService.updateGuideManagedTours(guideId, managedTours)
                  .subscribe(
                      () => {
                          observer.next(true);
                          observer.complete();
                      },
                      (error: GenericException) => {
                          observer.error(error);
                      }
                  )

        });
    }


    updateGuideResellerTours(guideId: number, resellerTours: Array<TourResume>): Observable<boolean> {

        return Observable.create((observer: Observer<boolean>) => {
            this.providerGuideService.updateGuideResellerTours(guideId, resellerTours)
                  .subscribe(
                      () => {
                          observer.next(true);
                          observer.complete();
                      },
                      (error: GenericException) => {
                          observer.error(error);
                      }
                  )

        });
    }



    findToursForReselling(search: string, countryId: number, cityId: number, page: number): Observable<ChunkGeneric<TourResume>> {

        return Observable.create((observer: Observer<ChunkGeneric<TourResume>>) => {
              this.providerGuideService.findToursForReselling(this.sessionInteractor.getCurrentLanguage(), search, countryId, cityId, page)
                    .subscribe(
                        (chunkTours: ChunkGeneric<TourResume>) => {

                            observer.next(chunkTours);
                            observer.complete();
                        },
                        (error: GenericException) => {
                            observer.error(error);
                        }
                    )

        });

    }



    findAllToursForManagement(guideId: number): Observable<Array<TourResume>> {

        return Observable.create((observer: Observer<Array<TourResume>>) => {
              this.providerGuideService.findAllToursForManagement(guideId, this.sessionInteractor.getCurrentLanguage())
                    .subscribe(
                        (tours: Array<TourResume>) => {

                            observer.next(tours);
                            observer.complete();
                        },
                        (error: GenericException) => {
                            observer.error(error);
                        }
                    )

        });

    }


    getGuidesNames(): Observable<Array<CompanyName>> {

        return Observable.create((observer: Observer<Array<CompanyName>>) => {
            this.providerGuideService.getGuidesNames()
                  .subscribe(
                      (guides: Array<CompanyName>) => {

                          observer.next(guides);
                          observer.complete();
                      },
                      (error: GenericException) => {
                          observer.error(error);
                      }
                  )

        });
    }
    

}
