import { Component, ViewChild, OnInit } from '@angular/core';

import { MessagePanelComponent } from 'src/app/ui/components/shared/message-panel/message-panel.component';
import { ServerErrorManager } from 'src/app/ui/components/utils/server-error-manager.utils';

import { ActivatedRoute, Router } from '@angular/router';
import { NavigationConstants } from 'src/app/ui/commons/navigation.constants';
import { GenericException } from 'src/app/domain/exceptions/generic.exception';
import { InvoiceFullDetails } from 'src/app/business/models/billing/invoice-full-details.model';
import { NavigatorUtils } from 'src/app/ui/utils/navigator.utils';
import { BillingUtils } from 'src/app/ui/utils/billing.utils';
import { ProfileInteractor } from 'src/app/domain/interactor/profile/profile.interactor';
import { Affiliate } from 'src/app/business/models/affiliate/affiliate.model';
import { SessionInteractor } from 'src/app/domain/interactor/session.interactor';
import { AffiliateBillingInteractor } from 'src/app/domain/interactor/affiliate/billing.interactor';


@Component({
     selector: 'app-affiliate-invoice-details',
     templateUrl: './invoice-details.component.html'
})

export class AffiliateInvoicdeDetailsComponent implements OnInit {

     @ViewChild(MessagePanelComponent, { static: false })
     private messagePanelComponent: MessagePanelComponent;

     affiliate: Affiliate;

     invoiceId: number;
     invoiceFullDetails: InvoiceFullDetails;


     constructor(
          private affiliateBillingInteractor: AffiliateBillingInteractor,
          private serverErrorManager: ServerErrorManager,
          private sessionInteractor: SessionInteractor,
          private profileInteractor: ProfileInteractor,
          private router: Router,
          private activatedRoute: ActivatedRoute) {


               this.activatedRoute.params.subscribe(params => {
                    this.invoiceId = params[NavigationConstants.NAVIGATION_ADMIN_INVOICE_ID];

                    if (this.messagePanelComponent) {
                         this.messagePanelComponent.showLoading();
                    }

                    this.affiliateBillingInteractor.findInvoice(this.invoiceId)
                    .subscribe(
                         (invoiceFullDetails: InvoiceFullDetails) => {
                              MessagePanelComponent.hideLoading();
                              this.invoiceFullDetails = invoiceFullDetails;

                         },
                         (error: GenericException) => {
                              MessagePanelComponent.hideLoading();
                              this.serverErrorManager.manageErrorCode(error, this.messagePanelComponent);
                         }
                    )
               });



               this.profileInteractor.getAffiliateDetails()
               .subscribe(
                    (affiliate: Affiliate) => {
                         this.affiliate = affiliate;
                    },
                    (error: GenericException) => {
                         this.serverErrorManager.manageErrorCode(error, this.messagePanelComponent);

                    }
               )


          }

          ngOnInit() {
          }


          onInvoiceDownload() {

               this.messagePanelComponent.showLoading();

               this.affiliateBillingInteractor.invoiceDownload(this.invoiceId)
               .subscribe(
                    (invoiceBlob: Blob) => {

                         MessagePanelComponent.hideLoading();
                         NavigatorUtils.downloadFile(invoiceBlob, BillingUtils.getInvoiceNameToDownload(this.invoiceFullDetails.invoiceDetails));

                    },
                    (error: GenericException) => {
                         MessagePanelComponent.hideLoading();
                         this.serverErrorManager.manageErrorCode(error, this.messagePanelComponent);
                    }
               )
          }


          onGoToProfile() {
               if ( this.sessionInteractor.isProviderRole() ) {
                    this.router.navigate([NavigationConstants.getNavigationProviderProfile()]);
               } else {
                    this.router.navigate([NavigationConstants.getNavigationAffiliateProfile()]);
               }
          }

     }




