import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { CalendarUtils } from 'src/app/ui/utils/calendar.utils';

import { ChunkGeneric }                 from 'src/app/domain/views/chunk-generic.view';
import { Pagination }                   from 'src/app/domain/views/pagination.view';

import { TranslateService } from '@ngx-translate/core';
import { marker as I18N } from '@biesbjerg/ngx-translate-extract-marker';
import { CompanyName } from 'src/app/business/models/master/company/company-name.model';
import { GuideServiceDetails } from 'src/app/business/models/billing/service-details.model';
import { FindServicesFilterView } from 'src/app/domain/views/services/find-services-filter.view';
import { GuideServiceState } from 'src/app/business/models/billing/common/guide-service-state.constants';
import { GuideServiceUtils } from 'src/app/business/models/billing/common/guide-service.utils';
import { GuideServiceStats } from 'src/app/business/models/billing/service-stats.model';


@Component({
     selector: 'app-list-services',
     templateUrl: './list.component.html'
})
export class ListServicesComponent implements OnInit {

     @Output() filterCommissions = new EventEmitter<FindServicesFilterView>();
     @Output() providerSelectedTrigger = new EventEmitter<number>();
     @Output() createCommission = new EventEmitter<boolean>();


     @Output() approveService = new EventEmitter<number>();
     @Output() editService = new EventEmitter<number>();
     @Output() deleteService = new EventEmitter<number>();


     @Input()
     set chunkCommissions(chunkCommissions: ChunkGeneric<GuideServiceDetails>) {
          if ( chunkCommissions ) {
               this.pagination = new Pagination(chunkCommissions.pagination.totalItems, chunkCommissions.pagination.currentPage, chunkCommissions.pagination.itemsPerPage);
               this.pageNumber = this.pagination.currentPage;
               this.commissions = chunkCommissions.items;
               this.noResults = this.commissions.length == 0;
          }
     }

     @Input() guideServiceStats: GuideServiceStats;

     @Input() guides: Array<CompanyName>;
     @Input() providers: Array<CompanyName>;


     @Input() isAdmin: boolean;
     @Input() isProvider: boolean;
     @Input() isGuide: boolean;

     commissions: Array<GuideServiceDetails>;

     months: Array<number>;
     years: Array<number>;

     states: Array<GuideServiceState>;

     providerId: number;
     guideId: number;
     month: number;
     year: number;
     state: GuideServiceState;

     pageNumber: number;
     pagination: Pagination;

     noResults: boolean;

     desktopVersion: boolean;
     initialWindowSize: number;

     paginationNextLabel: string;
     paginationPreviousLabel: string;

     constructor(
          private translateService: TranslateService
     ) {

          this.pagination = new Pagination(0, 1, 20);
          this.pageNumber = this.pagination.currentPage;
          this.noResults = true;

          this.years = CalendarUtils.getYearsAvailablesForBilling();
          this.year = CalendarUtils.getCurrentYear();

          this.months = CalendarUtils.getMonthsAvailablesForBillingFromYear(this.year);
          this.month = CalendarUtils.getCurrentMonth(true);

          this.state = null;
          this.states = GuideServiceUtils.getGuideServiceStates();

          this.guideId = null;
          this.providerId = null;

          this.desktopVersion = true;

     }



     ngOnInit() {

          this.initialWindowSize = window.innerWidth;

          this.findCommissions();

          this.translateService.get(I18N('pagination.Siguiente')).subscribe(
               (messageTranslated: string) => {
                    this.paginationNextLabel = messageTranslated;
               }
         )

         this.translateService.get(I18N('pagination.Previous')).subscribe(
               (messageTranslated: string) => {
                    this.paginationPreviousLabel = messageTranslated;
               }
         )

     }

     onDesktopVersionUpdated( isDesktopVersion: boolean ) {
          this.desktopVersion = isDesktopVersion;
     }



     findCommissions() {
          this.filterCommissions.emit(new FindServicesFilterView(this.providerId, this.guideId, this.month, this.year, this.state, this.pageNumber));
     }


     onPageChanged(currentPage: number) {
          this.pageNumber = currentPage;
          this.findCommissions();
     }


     onProviderSelected() {
          this.providerSelectedTrigger.emit(this.providerId);
          this.findCommissions();
     }

     onGuideSelected() {
          this.findCommissions();
     }

     onYearSelected() {
          this.months = new Array<number>();
          this.month = null;
          if ( this.year != null ) {
               this.months = CalendarUtils.getMonthsAvailablesForBillingFromYear(this.year);
          }

          this.month = this.months[0];
          this.findCommissions();
     }

     onMonthSelected() {
          this.findCommissions();
     }

     onStateSelected() {
          this.findCommissions();
     }


     onCreateCommission() {
          this.createCommission.emit(true);
     }

     onApproveService(commissionId: number) {
          this.approveService.emit(commissionId);
     }

     onEditService(commissionId: number) {
          this.editService.emit(commissionId);
     }

     onDeleteService(commissionId: number) {
          this.deleteService.emit(commissionId);
     }


}
