<div class="animated fadeIn">
  <div class="row header-dashboard align-items-lg-center align-items-start">
    <div class="col-12 col-sm-8 col-lg-6 col-xl-6 pl-0 order-1 order-lg-1">
      <h2 class="font-weight-bold">{{ 'invoicepayment.Detalles de factura' | translate }}</h2>
      <p>{{ 'invoicepayment.Resumen del estado de la factura' | translate }}</p>
    </div>
  </div>


  <div class="row dashboard-content-section" *ngIf="invoiceFullDetails">

    <div class="col-12 card card-list">
      <div class="row padding-lg-inside">
        <div class="col-12 col-lg-12 px-auto px-lg-0 padding-xs-inside">
          <div class="row">
            <div class="col-12 mt-0 mt-lg-2 text-right">
              <label class="btn-more-vert dropdown">
                <div class="dd-button">
                  <img class="ic-mid" src="assets/img/ic_more-vert.svg">
                </div>

                <input type="checkbox" class="dd-input" id="test">

                <ul class="dd-menu">

                  <li>
                    <a (click)="onInvoiceDownload()">
                      {{ 'invoiceslist.Descargar factura' | translate }}
                    </a>
                  </li>

                </ul>

              </label>
            </div>
          </div>
          <div class="row">
            <div class="col-12 mt-0 mt-lg-2">
              <h5 class="font-weight-bold">{{ 'invoicepayment.Factura número '| translate }} {{ invoiceFullDetails.invoiceDetails.serialNumber }}</h5>
              <p class="text-body-small grey-color">{{ 'invoicepayment.Período ' | translate }} {{ invoiceFullDetails.invoiceDetails.invoiceMonthPeriod }}/{{ invoiceFullDetails.invoiceDetails.invoiceYearPeriod }}</p>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-8 col-xs-12">
              <p class="text-body-mid">
                {{ invoiceFullDetails.issuer.name }}
                <br />
                {{ 'invoicepayment.Registro ' | translate }} {{ invoiceFullDetails.issuer.registration }}
                <br />
                {{ invoiceFullDetails.issuer.vat }}
                <br />
                {{ invoiceFullDetails.issuer.email }}
                <br />
                {{ invoiceFullDetails.issuer.address1 }}
                <br />
                {{ invoiceFullDetails.issuer.address2 }}
              </p>
            </div>
            <div class="col-lg-4 col-xs-12">
              <div class="row">
                <div class="col-6 col-sm-6 col-md-auto">
                  <p class="text-body-small grey-color font-weight-semibold">{{ 'invoicepayment.Personas' | translate }}</p>
                  <p class="text-body-small font-weight-semibold text-center">{{ invoiceFullDetails.invoiceDetails.billingDetails.people }}</p>
                </div>
                <div class="col-6 col-sm-6 col-md-auto">
                  <p class="text-body-small grey-color font-weight-semibold">{{ 'invoicepayment.Shows' | translate }}</p>
                  <p class="text-body-small font-weight-semibold text-center">{{ invoiceFullDetails.invoiceDetails.billingDetails.shows }}</p>
                </div>
                <div class="col-6 col-sm-6 col-md-auto">
                  <p class="text-body-small grey-color font-weight-semibold">{{ 'invoicepayment.Importe' | translate }}</p>
                  <p class="text-body-small font-weight-semibold text-center">{{ invoiceFullDetails.invoiceDetails.billingDetails.commission }} €</p>
                </div>
              </div>
              <div class="row">
                <div class="col-6 col-sm-6 col-md-auto">
                  <p class="text-body-small grey-color font-weight-semibold">{{ 'invoicepayment.Estado' | translate }}</p>
                  <div class="text-body-small font-weight-semibold">
                    <div [ngClass]="'status' | invoiceStateClassPipe: invoiceFullDetails.invoiceDetails.settledUp">
                      <div class="dot"></div> {{ invoiceFullDetails.invoiceDetails.settledUp | invoiceStatePayment | translate }}
                    </div>
                  </div>
                </div>
                <div class="col-6 col-sm-6 col-md-auto">
                  <p class="text-body-small grey-color font-weight-semibold">{{ 'invoicepayment.Tipo' | translate }}</p>
                  <p class="text-body-small font-weight-semibold">

                    <a *ngIf="invoiceFullDetails.invoiceDetails.paymentPending && !provider.canReceivePayments" (click)="onGoToProfile()" placement="bottom" ngbTooltip="{{ 'invoicepayment.Cuenta de Stripe no conectada' | translate }}"
                         tooltipClass="tooltip-info">
                      <img class="ic-small" src="assets/img/ic_info_outlined_red.svg">
                    </a>

                    {{ invoiceFullDetails.invoiceDetails.chargePending | invoicePaymentTypeProviderPipe: invoiceFullDetails.invoiceDetails.chargeCompleted : invoiceFullDetails.invoiceDetails.paymentPending : invoiceFullDetails.invoiceDetails.paymentCompleted | translate }}
                    {{ invoiceFullDetails.invoiceDetails.billingDetails.payment | invoicePaymentTypeAmountPipe }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>


  <div *ngIf="invoiceFullDetails && invoiceFullDetails.invoiceDetails.chargePending" class="row dashboard-content-section mb-5 mb-md-auto">
    <div class="col-12">
      <div class="row">
        <div class="col-12 text-left pl-md-0 pr-md-3 px-0">
          <h2 class="font-weight-bold mb-4">{{ 'invoicepayment.Datos de pago' | translate}}</h2>
        </div>
      </div>
      <div class="row">
        <form [formGroup]="invoicePaymentForm" class="form-boxes d-block d-md-flex m-0 p-0">
          <div class="col-12 col-md-6 pr-md-3 pl-md-0 px-0">
            <div class="field pb-2">
              <input formControlName="creditCardOwner" name="creditCardOwner" type="text" id="creditCardOwner" placeholder="{{ 'invoicepayment.Titular de la tarjeta' | translate }}" value="">
              <label for="titularName">{{ 'invoicepayment.Titular de la tarjeta' | translate }}</label>
            </div>
            <app-feedback [formGroup]="invoicePaymentForm" field="creditCardOwner" validator="required" feedback="{{ 'invoicepayment.Titular de la tarjeta requerido' | translate }}" [refresh]="refreshValidators"></app-feedback>
          </div>
          <div class="col-12 col-md-6 pr-md-3 pl-md-0 px-0">
            <div class="field pb-2">
              <div class="d-md-flex mt-2 my-md-auto secure-payment">
                <img class="img-fluid ic-payment" src="assets/img/ic_lock.svg" />
                <span class="small">{{ 'invoicepayment.Pago de forma ' | translate }}</span>
              </div>
              <div id="card-element">
                <!-- Elements will create input elements here -->
              </div>
              <label for="targetNum">{{ 'invoicepayment.Número de tarjeta' | translate }}</label>

              <div *ngIf="paymentErrors" class="paymentErrors">{{ paymentErrors }}</div>
            </div>
            <app-feedback [formGroup]="invoicePaymentForm" field="creditCard" validator="required" feedback="{{ 'invoicepayment.Datos de la tarjeta requeridos' | translate }}" [refresh]="refreshValidators"></app-feedback>
          </div>
        </form>
      </div>
    </div>
  </div>

  <div *ngIf="invoiceFullDetails && invoiceFullDetails.invoiceDetails.chargePending" class="row mb-5 pb-5 control-footer">
    <div class="col-6 col-md-6 offset-md-6 col-lg-4 offset-lg-8">
      <button (click)="onConfirmPayment()" type="submit" value="{{ 'invoicepayment.Confirmar' | translate }}" class="btn btn-full-width btn-primary mb-3 mb-sm-0 white-color">
        {{ 'invoicepayment.Confirmar' | translate }}
      </button>
    </div>
  </div>


  <app-message-panel></app-message-panel>


</div>