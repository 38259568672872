import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ProfileInteractor } from 'src/app/domain/interactor/profile/profile.interactor';
import { MasterInteractor } from 'src/app/domain/interactor/public/master.interactor';
import { Language } from 'src/app/business/models/master/language/language.model';
import { SessionInteractor } from 'src/app/domain/interactor/session.interactor';
import { NavigatorUtils } from 'src/app/ui/utils/navigator.utils';
import { marker as I18N } from '@biesbjerg/ngx-translate-extract-marker';
import { AppConstants } from 'src/app/domain/common/app.constants';
import { Provider } from 'src/app/business/models/provider/provider.model';
import { MessagePanelComponent } from 'src/app/ui/components/shared/message-panel/message-panel.component';
import { ServerErrorManager } from 'src/app/ui/components/utils/server-error-manager.utils';
import { FormUtils } from 'src/app/ui/components/utils/form.utils';
import { GenericException } from 'src/app/domain/exceptions/generic.exception';
import { PaymentPlatform } from 'src/app/business/models/master/payment-platform/payment-platform.constants';
import { ServerErrorExceptionCase } from 'src/app/domain/views/server-error-manager-exception-case.view';
import { ApiConstants } from 'src/app/business/api/api.constants';

@Component({
     selector: 'app-provider-profile-settings',
     templateUrl: './settings.component.html'
})
export class ProviderProfileSettingsComponent implements OnInit {

     @ViewChild(MessagePanelComponent)
     private messagePanelComponent: MessagePanelComponent;


     @Input()
     set provider(provider: Provider) {
          if (provider) {
               this.setProviderFormValues(provider);
          }
     }

     languages: Array<Language>;
     currencies: Array<string>;

     settingsForm: FormGroup;
     isDisabled = true;
     refreshValidators: boolean;

     paymentPlatformWise: PaymentPlatform = PaymentPlatform.Wise;
     paymentPlatformStripe: PaymentPlatform = PaymentPlatform.Stripe;
     paymentPlatformTransference: PaymentPlatform = PaymentPlatform.Transference;

     isPaymentPlatformWise: boolean;
     isPaymentPlatformTransference: boolean;

     constructor(
          private profileInteractor: ProfileInteractor,
          private masterInteractor: MasterInteractor,
          private sessionInteractor: SessionInteractor,
          private serverErrorManager: ServerErrorManager) {

               this.refreshValidators = false;
               this.isPaymentPlatformWise = false;
               this.isPaymentPlatformTransference = false;

               this.settingsForm = new FormGroup({
                    'language': new FormControl({ value: this.sessionInteractor.getCurrentLanguage(), disabled: true }, [Validators.required]),
                    'bookingNotificationEmailActive': new FormControl({ value: '', disabled: true }, []),
                    'bookingNotificationEmail': new FormControl({ value: '', disabled: true }, [Validators.required, Validators.email, Validators.maxLength(AppConstants.TEXT_DEFAULT_MAX_LENGTH)]),
                    'bookingNotificationEmail2': new FormControl({ value: '', disabled: true }, [Validators.email, Validators.maxLength(AppConstants.TEXT_DEFAULT_MAX_LENGTH), Validators.minLength(1)]),
                    'paymentPlatform': new FormControl({ value: '', disabled: true }, [Validators.required]),
                    'currency': new FormControl({ value: '', disabled: true }, []),
                    'iban': new FormControl({ value: '', disabled: true }, [])
               })

          }



          ngOnInit() {

               this.masterInteractor.getLanguagesLocalized()
               .subscribe(
                    (languages: Array<Language>) => {
                         this.languages = languages
                    },
                    (error: GenericException) => {
                         this.serverErrorManager.manageErrorCode(error, this.messagePanelComponent);
                    }
               );

               this.masterInteractor.getPaymentCurrencies()
               .subscribe(
                    (currencies: Array<string>) => {
                         this.currencies = currencies;
                    },
                    (error: GenericException) => {
                         this.serverErrorManager.manageErrorCode(error, this.messagePanelComponent);
                    }
               );

          }


          toggleEdition() {

               this.isDisabled = !this.isDisabled

               if (this.isDisabled) {
                    FormUtils.disableAllControls(this.settingsForm)
               } else {
                    FormUtils.enableAllControls(this.settingsForm)
               }
          }


          saveSettings() {

               this.refreshValidators = true;

               if (this.settingsForm.valid) {

                    this.messagePanelComponent.showLoading();

                    const languageCodeSelected = this.settingsForm.value.language;
                    const bookingNotificationEmailActive = this.settingsForm.value.bookingNotificationEmailActive;
                    const bookingNotificationEmail = this.settingsForm.value.bookingNotificationEmail;

                    let bookingNotificationEmail2 = null;
                    if ( this.settingsForm.value.bookingNotificationEmail2 && this.settingsForm.value.bookingNotificationEmail2.trim() != "") {
                         bookingNotificationEmail2 = this.settingsForm.value.bookingNotificationEmail2;
                    }

                    const paymentPlatform = this.settingsForm.value.paymentPlatform;
                    const paymentCurrency = this.settingsForm.value.currency;
                    const iban = this.settingsForm.value.iban;

                    this.profileInteractor.updateProviderSettings(languageCodeSelected, bookingNotificationEmailActive, bookingNotificationEmail, bookingNotificationEmail2, paymentPlatform, paymentCurrency, iban)
                    .subscribe(
                         () => {

                              MessagePanelComponent.hideLoading();

                              this.sessionInteractor.setCurrentLanguage(languageCodeSelected)
                              this.messagePanelComponent.showSuccessMessage(I18N('settingsprofile.Configuración actualizada'), () => NavigatorUtils.reloadApplication())

                              this.toggleEdition()

                         },
                         (error: GenericException) => {

                              const exceptionCases = new Array<ServerErrorExceptionCase>();

                              const exceptionCodes = new Array<number>();
                              exceptionCodes.push(AppConstants.ERROR_CLIENT_BAD_REQUEST);

                              const exceptionCauses = new Array<string>();
                              exceptionCauses.push(ApiConstants.PROFILE_PAYMENT_PLATFORM_INVALID_CHANGE);


                              exceptionCases.push(new ServerErrorExceptionCase(
                                   exceptionCodes,
                                   exceptionCauses,
                                   () => {
                                        this.messagePanelComponent.showErrorMessage(I18N('settingsprofile.No puedes cambiar tu método de pago mientras haya trasnferencias en curso.'));
                                   }
                              ));


                              this.serverErrorManager.manageErrorCode(error, this.messagePanelComponent, exceptionCases);

                         }
                    )
               }

          }

          updatePaymentPlatform() {
               this.isPaymentPlatformWise = this.settingsForm.controls['paymentPlatform'].value == this.paymentPlatformWise;
               this.isPaymentPlatformTransference = this.settingsForm.controls['paymentPlatform'].value == this.paymentPlatformTransference;

               this.settingsForm.controls['iban'].clearValidators();
               this.settingsForm.controls['currency'].clearValidators();

               if ( this.isPaymentPlatformWise || this.isPaymentPlatformTransference ) {
                    this.settingsForm.controls['currency'].setValidators([Validators.required]);
               }

               if ( this.isPaymentPlatformTransference ) {
                    this.settingsForm.controls['iban'].setValidators([Validators.required, Validators.pattern(AppConstants.REGULAR_EXPRESSION_VALIDATE_IBAN)]);
               }
          }


          onUpdatedPaymentPlatform() {
               this.updatePaymentPlatform();
          }

          onIbanUpdated() {
               this.updateIBANFormat();
          }

          private updateIBANFormat() {
               const iban: string = this.settingsForm.controls['iban'].value;
               this.settingsForm.controls['iban'].setValue(iban.toUpperCase().replace(new RegExp(" ", 'g'), "").replace(new RegExp("-", 'g'), ""));
          }


          private setProviderFormValues(provider: Provider) {

               this.settingsForm.controls['bookingNotificationEmailActive'].setValue(provider.bookingNotificationEmailActive);
               this.settingsForm.controls['bookingNotificationEmail'].setValue(provider.bookingNotificationEmail);
               this.settingsForm.controls['bookingNotificationEmail2'].setValue(provider.bookingNotificationEmail2);
               this.settingsForm.controls['paymentPlatform'].setValue(provider.paymentPlatform);
               this.settingsForm.controls['currency'].setValue(provider.paymentCurrency);
               this.settingsForm.controls['iban'].setValue(provider.iban);

               this.updatePaymentPlatform();

          }


     }
