<form class="form-boxes m-0 p-0" action="" [formGroup]="serviceFeeForm">
    <div class="modal-header pb-0">
        <button type="button" class="close" aria-label="Close" (click)="closeModal()">
            <span aria-hidden="true">&times;</span>
          </button>
    </div>
    <div class="modal-body mb-5">
        <h5 class="modal-title">{{ 'editcommission.Modifica el fee por persona' | translate}}</h5>
        <div class="row mt-5">
            <div class="col-12 col-md-6">
                <div class="field pb-2">
                    <input formControlName="fee" type="number" placeholder="">
                    <label for="language">{{ 'editcommission.Fee' | translate }} (€)</label>
                </div>
                <app-feedback [formGroup]="serviceFeeForm" field="fee" validator="min" feedback="{{ 'editcommission.Indica un número entero entre 0 y 100' | translate }}" [refresh]="refreshValidators"></app-feedback>
            </div>
        </div>
        <div class="row mt-0">
            <div class="col-12">
                ℹ️ {{ 'editcommission.Establece el importe a pagar por el guía por persona en este tour' | translate }}
            </div>
        </div>
    </div>
    <div class="modal-footer border-top-0">
        <input (click)="saveFee()" type="submit" value="{{ 'editcommission.Guardar datos' | translate }}" class="btn bg-primary-color mb-3 mb-sm-0 mr-4 modal-confirm" />

        <input (click)="closeModal()" type="submit" value="{{ 'editcommission.Cancelar' | translate }}" class="btn mb-3 mb-sm-0 modal-cancel" />

    </div>


</form>



<app-message-panel></app-message-panel>