<div class="animated fadeIn">
    <div *ngIf="affiliate != null" class="row header-dashboard align-items-lg-center align-items-start">
        <div class="col-12">
            <div class="row mb-3 mb-lg-auto">
                <div class="col-12 col-sm-6 col-lg-9 pl-0">
                    <h2 class="font-weight-bold"> {{ affiliate.companyName }}</h2>
                    <div [ngClass]="'status' | adminAffiliateStateClass: affiliate.state">
                        <div class="dot"></div> {{ '' | adminAffiliateStateName: affiliate.state | translate }}
                    </div>
                </div>
                <!--
                <div *ngIf="isStatePending" class="col-12 col-sm-6 col-lg-3 text-left text-sm-right my-auto p-0 p-auto">
                    <a (click)="doConfirmAffiliate()" class="link-terciary text-uppercase text-underline cursor-pointer mb-sm-0">{{ 'affiliatedetails.Confirmar cuenta' | translate }}</a>
                </div>
                -->
            </div>
            <!--
            <div class="row">
                <div *ngIf="isStatePending" class="col-12 text-left text-sm-right mt-auto pb-2 p-0 p-auto pt-2">
                    <a (click)="doRejectAffiliate()" class="link-terciary cursor-pointer mb-sm-0">
                        <img class="ic-mid mb-1 ic-report" src="assets/img/ic_report.svg"> {{ 'affiliatedetails.Solicitar información adicional' | translate }}
                    </a>
                </div>
            </div>
            -->
        </div>
    </div>

    <div class="row dashboard-content-section">
        <div class="col-12 px-0">

            <app-profile-affiliate-details [affiliate]="affiliate" (affiliateDetails)="onAffiliateDetailsSubmit($event)"></app-profile-affiliate-details>
            <app-stripe-profile-payment [provider]="affiliate" [isAdmin]="true"
                    (createConnectedAccount)="onCreateConnectedStripeAccount()"
                    (removeAccount)="onRemoveStripeAccount()"
                    (updateAccount)="onUpdateStripeAccount()">
            </app-stripe-profile-payment>

        </div>
    </div>

    <div *ngIf="!isStatePending" class="row mt-3 mb-5 pb-3">
        <div class="col-12 text-center">
            <a (click)="doUnregisterAffiliate()" class="link-terciary text-uppercase text-underline cursor-pointer mb-sm-0">{{ 'affiliatedetails.Dar de baja el afiliado' | translate }}</a>
        </div>
    </div>

    <div *ngIf="isStatePending" class="row mt-3 mb-5 pb-3">
        <div class="col-12 text-center">
            <a (click)="doRegisterAffiliate()" class="link-terciary text-uppercase text-underline cursor-pointer mb-sm-0">{{ 'affiliatedetails.Dar de alta el afiliado' | translate }}</a>
        </div>
    </div>

</div>


<app-message-panel [preloading]="true"></app-message-panel>