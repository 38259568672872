<div class="select-language" [ngClass]="extraClass">
    <ul>
        <li>
            <div class="font-weight-bold"> <span *ngIf="separator" class="mr-2">|</span> {{ currentLanguageCode }}</div>
            <i class="fa fa-angle-down" aria-hidden="true">

            </i>
            <div class="triangle"></div>
            <ul>
                <li *ngFor="let language of languages">
                    <span (click)="onChangeLanguage(language.code)" [ngClass]="{ 'active': language.code == currentLanguageCode }">{{ language.name }}</span>
                </li>
            </ul>
        </li>
    </ul>
</div>

<app-message-panel></app-message-panel>