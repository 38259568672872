<div class="animated fadeIn">
  <div class="row header-dashboard align-items-lg-center align-items-start">
    <div class="col-12 col-sm-8 col-lg-6 col-xl-6 pl-0 order-1 order-lg-1">
      <h2 class="font-weight-bold">{{ 'invoicepayment.Detalles de factura' | translate }}</h2>
      <p>{{ 'invoicepayment.Resumen del estado de la factura' | translate }}</p>
    </div>
  </div>


  <div class="row dashboard-content-section" *ngIf="invoiceFullDetails">

    <div class="col-12 card card-list">
      <div class="row padding-lg-inside">
        <div class="col-12 col-lg-12 px-auto px-lg-0 padding-xs-inside">
          <div class="row">
            <div class="col-12 mt-0 mt-lg-2 text-right">
              <label class="btn-more-vert dropdown">
                <div class="dd-button">
                  <img class="ic-mid" src="assets/img/ic_more-vert.svg">
                </div>

                <input type="checkbox" class="dd-input" id="test">

                <ul class="dd-menu">

                  <li>
                    <a (click)="onInvoiceDownload()">
                      {{ 'invoiceslist.Descargar factura' | translate }}
                    </a>
                  </li>

                </ul>

              </label>
            </div>
          </div>
          <div class="row">
            <div class="col-12 mt-0 mt-lg-2">
              <h5 class="font-weight-bold">{{ 'invoicepayment.Factura número '| translate }} {{ invoiceFullDetails.invoiceDetails.serialNumber }}</h5>
              <p class="text-body-small grey-color">{{ 'invoicepayment.Período ' | translate }} {{ invoiceFullDetails.invoiceDetails.invoiceMonthPeriod }}/{{ invoiceFullDetails.invoiceDetails.invoiceYearPeriod }}</p>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-8 col-xs-12">
              <p class="text-body-mid">
                {{ invoiceFullDetails.issuer.name }}
                <br />
                {{ 'invoicepayment.Registro ' | translate }} {{ invoiceFullDetails.issuer.registration }}
                <br />
                {{ invoiceFullDetails.issuer.vat }}
                <br />
                {{ invoiceFullDetails.issuer.email }}
                <br />
                {{ invoiceFullDetails.issuer.address1 }}
                <br />
                {{ invoiceFullDetails.issuer.address2 }}
              </p>
            </div>
            <div class="col-lg-4 col-xs-12">
              <div class="row">
                <div class="col-6 col-sm-6 col-md-auto">
                  <p class="text-body-small grey-color font-weight-semibold">{{ 'invoicepayment.Importe' | translate }}</p>
                  <p class="text-body-small font-weight-semibold text-center">{{ invoiceFullDetails.invoiceDetails.billingDetails.commission }} €</p>
                </div>
              </div>
              <div class="row">
                <div class="col-6 col-sm-6 col-md-auto">
                  <p class="text-body-small grey-color font-weight-semibold">{{ 'invoicepayment.Estado' | translate }}</p>
                  <div class="text-body-small font-weight-semibold">
                    <div [ngClass]="'status' | invoiceStateClassPipe: invoiceFullDetails.invoiceDetails.settledUp">
                      <div class="dot"></div> {{ invoiceFullDetails.invoiceDetails.settledUp | invoiceStatePayment | translate }}
                    </div>
                  </div>
                </div>
                <div class="col-6 col-sm-6 col-md-auto">
                  <p class="text-body-small grey-color font-weight-semibold">{{ 'invoicepayment.Tipo' | translate }}</p>
                  <p class="text-body-small font-weight-semibold">

                    <a *ngIf="invoiceFullDetails.invoiceDetails.paymentPending && !affiliate.canReceivePayments" (click)="onGoToProfile()" placement="bottom" ngbTooltip="{{ 'invoicepayment.Cuenta de Stripe no conectada' | translate }}"
                         tooltipClass="tooltip-info">
                      <img class="ic-small" src="assets/img/ic_info_outlined_red.svg">
                    </a>

                    {{ invoiceFullDetails.invoiceDetails.chargePending | invoicePaymentTypeProviderPipe: invoiceFullDetails.invoiceDetails.chargeCompleted : invoiceFullDetails.invoiceDetails.paymentPending : invoiceFullDetails.invoiceDetails.paymentCompleted | translate }}
                    {{ invoiceFullDetails.invoiceDetails.billingDetails.payment | invoicePaymentTypeAmountPipe }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>


  <app-message-panel></app-message-panel>


</div>