import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { FormGroup, Validators, FormControl, FormArray } from '@angular/forms';

import { TourSchedulePlanningDayFormView } from 'src/app/domain/views/provider/tour/schedule-planning-day-form.view';
import { TourSchedulePlanningFormView } from 'src/app/domain/views/provider/tour/schedule-planning-form.view';
import { TourScheduleSeasonFormView } from 'src/app/domain/views/provider/tour/schedule-season-form.view';
import { TourScheduleFormView } from 'src/app/domain/views/provider/tour/schedule-form.view';
import { TourStepFormView } from 'src/app/domain/views/provider/tour/sept-form.view';
import { TourScheduleType } from 'src/app/business/models/tour/common/tour-schedule-type.constants';
import { CalendarUtils } from 'src/app/ui/utils/calendar.utils';


@Component({
  selector: 'app-create-tour-schedule',
  templateUrl: './schedule.component.html'
})
export class CreateTourScheduleComponent implements OnInit {


  inputFixedDetails: TourSchedulePlanningFormView;
  inputSeasonsDetails: Array<TourScheduleSeasonFormView>;

  i18nTraslation: boolean;

  @Input()
  set isI18nTraslation(isI18nTraslation: boolean) {
      this.i18nTraslation = isI18nTraslation;
  }

  @Input()
  set tourSchedule(tourSchedule: TourScheduleFormView) {

      if ( tourSchedule ) {

          if ( tourSchedule.type == TourScheduleType.fixed ) {
              this.consigureFixedTabIfNeeded();
              this.inputFixedDetails = tourSchedule.fixed;

          } else {
              this.configureSeasonTabIfNeeded();
              this.inputSeasonsDetails = tourSchedule.seasons;
          }
      }

  }


  @Output() tourScheduleEmitter = new EventEmitter<TourStepFormView<TourScheduleFormView>>(true)

  createTourScheduleForm: FormGroup;
  refreshValidators: boolean

  isFixedType: boolean;




  constructor() {

      //Initial form config
      this.refreshValidators = false;

      this.onFixedTypeSelected();



  }


  ngOnInit() {
  }


  get fixedFormGroup(): FormGroup {
    return this.createTourScheduleForm.get('fixed') as FormGroup;
  }

  get seasonsFormArray(): FormArray {
    return this.createTourScheduleForm.get('seasons') as FormArray;
  }

  saveDate() {

      this.refreshValidators = true;

      if ( this.createTourScheduleForm.valid ) {
          this.emitEventWithFormDetails(true);
      }
  }


  onBackButton() {
      this.emitEventWithFormDetails(false);
  }


  onFixedTypeSelected() {
    if ( !this.i18nTraslation ) {
        this.consigureFixedTabIfNeeded();
    }
  }

  onSeasonTypeSelected() {
      if ( !this.i18nTraslation ) {
          this.configureSeasonTabIfNeeded();
      }
  }


  consigureFixedTabIfNeeded() {
      if ( !this.isFixedType ) {
          this.isFixedType = true;
          this.refreshValidators = false;

          this.createTourScheduleForm = new FormGroup({
              'type': new FormControl(TourScheduleType.fixed, [Validators.required] ),
              'fixed': new FormGroup({})
          });
      }
  }

  configureSeasonTabIfNeeded() {
      if ( this.isFixedType ) {
          this.isFixedType = false;
          this.refreshValidators = false;

          this.createTourScheduleForm = new FormGroup({
              'type': new FormControl(TourScheduleType.seasons, [Validators.required] ),
              'seasons': new FormArray([])
          });
      }
  }


  private emitEventWithFormDetails(isNextStep: boolean) {

      const tourLocation = new TourScheduleFormView(
          this.createTourScheduleForm.get('type').value,
          this.processDaysConfiguration(this.createTourScheduleForm.get('fixed') as FormGroup),
          this.processSeasonsConfiguration(this.createTourScheduleForm.get('seasons') as FormArray),
      );

      this.tourScheduleEmitter.emit(new TourStepFormView(isNextStep, tourLocation));

  }



  processDaysConfiguration(details: FormGroup) {

      const days: Array<TourSchedulePlanningDayFormView> = new Array<TourSchedulePlanningDayFormView>();
      const dayExceptions: Array<TourSchedulePlanningDayFormView> = new Array<TourSchedulePlanningDayFormView>();


      if ( details ) {
          const daysFormArray = details.get('days') as FormArray;
          if ( daysFormArray ) {
              daysFormArray.controls.map( (day: FormGroup) => {
                  const hours: Array<string> = new Array<string>();
                  const hoursFormArray = day.get('hours') as FormArray;
                  hoursFormArray.controls.map( (hourFormGroup: FormGroup) => {
                      if ( hourFormGroup.value && hourFormGroup.value != "" ) {
                           hours.push(hourFormGroup.value);
                      }
                  })
                  days.push(new TourSchedulePlanningDayFormView(day.get('day').value, hours));
              });
          }

          const dayExceptionsFormArray = details.get('dayExceptions') as FormArray;
          if ( dayExceptionsFormArray ) {
              dayExceptionsFormArray.controls.map( (dayException: FormGroup) => {
                  const hours: Array<string> = new Array<string>();
                  const hoursFormArray = dayException.get('hours') as FormArray;
                  hoursFormArray.controls.map( (hourFormGroup: FormGroup) => {
                      if ( hourFormGroup.value && hourFormGroup.value != "" ) {
                          hours.push(hourFormGroup.value);
                      }
                  })
                  dayExceptions.push(new TourSchedulePlanningDayFormView(dayException.get('day').value, hours));
              })
          }

      }

      return new TourSchedulePlanningFormView(days, dayExceptions);
  }



  processSeasonsConfiguration(seasons: FormArray) {

      const seasonsViews: Array<TourScheduleSeasonFormView> = new Array<TourScheduleSeasonFormView>();
      if ( seasons ) {
          seasons.controls.map( (season: FormGroup) => {
              const startDay = CalendarUtils.fromDateHtmlInputToDateDDMMYYYY(season.get('startDay').value);
              const finishDay = CalendarUtils.fromDateHtmlInputToDateDDMMYYYY(season.get('finishDay').value);
              seasonsViews.push(new TourScheduleSeasonFormView(startDay, finishDay, this.processDaysConfiguration(season.get('details') as FormGroup)));
          })
      }

      return seasonsViews;

  }


}
