import { Component, AfterViewInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NavigationConstants } from 'src/app/ui/commons/navigation.constants';
import { ViewportScroller } from '@angular/common';

@Component({
     selector: 'app-review-thanks',
     templateUrl: './thanks.component.html'
})
export class ReviewThanksComponent implements AfterViewInit {

     targetAnchor: string;

     constructor(
          private router: Router,
          private viewportScroller: ViewportScroller,
          private activatedRoute: ActivatedRoute) {

               this.activatedRoute.fragment.subscribe((fragment: string) => {
                    this.targetAnchor = fragment;
               });
          }

     ngAfterViewInit() {
          if ( this.targetAnchor ) {
               this.viewportScroller.scrollToAnchor(this.targetAnchor);
          }
     }


     onHeaderItemSelected() {
          this.router.navigate( [ NavigationConstants.getNavigationHome() ] );
     }

}
