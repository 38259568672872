<table class="table table-hover table-dark">
    <thead class="thead-dark">
        <tr>
            <th scope="col">{{ 'commissionslist.Fecha' | translate }}</th>
            <th scope="col">{{ 'commissionslist.Tour' | translate }}</th>
            <th class="text-center" scope="col">{{ 'commissionslist.Nº Personas' | translate }}</th>
            <th class="text-center" scope="col">{{ 'commissionslist.Importe' | translate }}</th>
            <th scope="col">{{ 'commissionslist.Guía' | translate }}</th>
            <th scope="col">{{ 'commissionslist.Estado' | translate }}</th>
            <th scope="col"></th>
        </tr>
    </thead>
    <tbody class="">

        <tr *ngFor="let commission of commissions | paginate: pagination;">
            <td scope="row ">{{ commission.day }} | {{ commission.hour }}</td>
            <td>{{ commission.tourNameTranslated }}</td>
            <td class="text-center">{{ commission.people }}</td>
            <td class="text-center">{{ commission.price }} €</td>
            <td>{{ commission.guide.companyName }}</td>
            <td>
                <div [ngClass]="'status' | serviceStateClassPipe: commission.state">
                    <div class="dot"></div> {{ commission.state | serviceStateLabelPipe | translate }}
                </div>
            </td>
            <td class="px-0 text-center">

                <a *ngIf="commission.canEdit" class="table-action-icon" (click)="onEditService(commission.id)">
                    <img src="assets/img/action_icon_edit.svg" />
                </a>

                <a *ngIf="commission.canRemove" class="table-action-icon action-icon-delete" (click)="onDeleteService(commission.id)">
                    <img src="assets/img/action_icon_remove.svg" />
                </a>

                <a *ngIf="commission.canApprove" class="table-action-icon action-icon-check" (click)="onApproveService(commission.id)">
                    <img src="assets/img/action_icon_approve.svg" />
                </a>

              </td>
        </tr>

    </tbody>
</table>