import { ApiConstants } from 'src/app/business/api/api.constants';
import { PaymentPlatformUtils } from '../../master/payment-platform/payment-platform.utils';
import { AffiliateDailyStats } from '../affiliate-daily-stats.model';
import { AffiliateResume } from '../affiliate-resume.model';
import { AffiliateStatsDetails } from '../affiliate-stats-details.model';
import { AffiliateStats } from '../affiliate-stats.model';
import { Affiliate } from '../affiliate.model';
import { AffiliateState } from './affiliate-state.constants';
import { CalendarUtils } from 'src/app/ui/utils/calendar.utils';
import { AppConstants } from 'src/app/domain/common/app.constants';
import { AffiliateDayStats } from '../affiliate-day-stats.model';

export class AffiliateUtils {

     public static getStateFromApi(state: number): AffiliateState {

          if ( state == ApiConstants.API_AFFILIATE_STATE_ACTIVE ) {
               return AffiliateState.Active;
          } else {
               return AffiliateState.Inactive;
          }
     }

     public static getWebsFromApi(webs: Array<any>): Array<string> {
          const affiliateWebs = new Array<string>();
          webs.map( (web: any) => {
               affiliateWebs.push(web.web);
          })

          return affiliateWebs;
     }



     public static getAffiliateFromApi(affiliateFromApi: any): Affiliate {

          return new Affiliate(
               affiliateFromApi.id,
               affiliateFromApi.username,
               affiliateFromApi.languageId,
               affiliateFromApi.companyName,
               affiliateFromApi.phone,
               affiliateFromApi.countryId,
               affiliateFromApi.countryName,
               affiliateFromApi.cityId,
               affiliateFromApi.cityName,
               AffiliateUtils.getWebsFromApi(affiliateFromApi.webs),
               affiliateFromApi.businessName,
               affiliateFromApi.businessDocumentType,
               affiliateFromApi.businessDocument,
               affiliateFromApi.businessAddress,
               affiliateFromApi.businessZeroVat,
               affiliateFromApi.stripeAccountId,
               PaymentPlatformUtils.getPaymentPlatformFromApi(affiliateFromApi.paymentPlatform),
               affiliateFromApi.paymentCurrency,
               affiliateFromApi.iban,
               AffiliateUtils.getStateFromApi(affiliateFromApi.state),
               affiliateFromApi.canReceivePayments,
               affiliateFromApi.canCreateStripeAccount,
               affiliateFromApi.canGetStripeLink,
               affiliateFromApi.canUpdateStripeAccount,
               affiliateFromApi.canRemoveStripeAccount
          )

     }

     public static getAffiliateResumeFromApi(affiliateResumeFromApi: any): AffiliateResume {
          return new AffiliateResume(
               affiliateResumeFromApi.id,
               affiliateResumeFromApi.username,
               affiliateResumeFromApi.email,
               affiliateResumeFromApi.companyName,
               affiliateResumeFromApi.countryName,
               affiliateResumeFromApi.cityName,
               affiliateResumeFromApi.contactPerson,
               affiliateResumeFromApi.phone,
               AffiliateUtils.getStateFromApi(affiliateResumeFromApi.state),
          )
     }


     public static getAffiliateStatsFromApi(affiliateStatsDetailsFromApi: any): AffiliateStatsDetails {

          return new AffiliateStatsDetails(
               affiliateStatsDetailsFromApi.totalCommissionAndVat,
               new AffiliateStats(
                    affiliateStatsDetailsFromApi.nextBillingStats.totalCommissionAndVat,
                    affiliateStatsDetailsFromApi.nextBillingStats.totalBookingsNumber
               ),
               AffiliateUtils.getAffiliateDailyStatsFromApi(affiliateStatsDetailsFromApi.dailyStats)
          )
     }


     public static getAffiliateDailyStatsFromApi(affiliateStatsDailyFromApi: any): AffiliateDailyStats {

          const daysStats = new Array<AffiliateDayStats>();

          if ( affiliateStatsDailyFromApi ) {
               affiliateStatsDailyFromApi.daysStats.map( ( affiliateDayStaFromApi: any ) => {
                    daysStats.push(new AffiliateDayStats(
                         CalendarUtils.fromDateDDMMYYYYToFormatString(affiliateDayStaFromApi.date, AppConstants.APP_DATE_DAY_FORMAT_DD),
                         new AffiliateStats(
                              affiliateDayStaFromApi.stats.totalCommissionAndVat,
                              affiliateDayStaFromApi.stats.totalBookingsNumber
                         )
                    ))
               });
          }

          return new AffiliateDailyStats(new AffiliateStats(affiliateStatsDailyFromApi.stats.totalCommissionAndVat, affiliateStatsDailyFromApi.stats.totalBookingsNumber), daysStats);
     }



}
