
export class ProviderDetailsView {


    private _id: number;

    private _companyName: string;
    private _contactPerson: string;
    private _phone: string;
    private _country: string;
    private _city: string;
    private _web: string;
    private _contactEmail: string;


    constructor(
      id: number,

      companyName: string,
      contactPerson: string,
      phone: string,
      country: string,
      city: string,
      web: string,
      contactEmail: string

    ) {

        this._id = id;

        this._companyName = companyName;
        this._contactPerson = contactPerson;
        this._phone = phone;
        this._country = country;
        this._city = city;
        this._web = web;
        this._contactEmail = contactEmail;

    }


    get id(): number {
        return this._id;
    }

    get companyName(): string {
        return this._companyName;
    }

    get contactPerson(): string {
        return this._contactPerson;
    }

    get phone(): string {
        return this._phone;
    }

    get country(): string {
        return this._country;
    }

    get city(): string {
        return this._city;
    }

    get web(): string {
        return this._web;
    }

    get contactEmail(): string {
        return this._contactEmail;
    }

}