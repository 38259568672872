<div class="row">
    <div class="col-12">
        <nav class="nav nav-pills flex-column flex-md-row tabs-form text-center text-md-center">
            <a (click)="onSeasonSelected(seasonIndex)" *ngFor="let season of seasonsForm.controls; index as seasonIndex" [formGroup]="season" [ngClass]="{'invalid': refreshValidators && (season.status == invalidStateForm), 'active': seasonIndex == currentSeasonIndex}"
                class="nav-link">
                <span class="float-left float-md-none">{{ 'Temporada' | translate:seasonIndex }}</span>
                <span *ngIf="!i18nTraslation" (click)="onDeleteSeason(seasonIndex)" class="ic-tab icon-delete ml-4 float-right float-md-none"></span>
                <!-- <span (click)="onCopySeason(seasonIndex)" class="ic-tab icon-copy ml-3 float-right float-md-none"></span> -->
            </a>
            <a *ngIf="!i18nTraslation" (click)="onAddSeasson()" class="nav-link text-left text-md-center">
                <span class="ic-tab icon-add"></span>
            </a>
        </nav>
    </div>
</div>
<div *ngIf="currentSeasonSelected" class="row" [formGroup]="currentSeasonSelected">
    <div class="col-12 col-md-4">
        <div class="field pb-2">
            <input formControlName="startDay" type="date" name="startDay">
            <label for="startDay">{{ 'createtourdate.Desde' | translate }}</label>
        </div>
        <app-feedback [formGroup]="currentSeasonSelected" field="startDay" validator="required" feedback="{{ 'createtourdate.Fecha inicio requerida' | translate }}" [refresh]="refreshValidators"></app-feedback>
    </div>
    <div class="col-12 col-md-4">
        <div class="field pb-2">
            <input formControlName="finishDay" type="date" name="finishDay">
            <label for="dateTo">{{ 'createtourdate.Hasta' | translate }}</label>
        </div>
        <app-feedback [formGroup]="currentSeasonSelected" field="finishDay" validator="required" feedback="{{ 'createtourdate.Fecha fin requerida' | translate }}" [refresh]="refreshValidators"></app-feedback>
    </div>
    <div class="col-12">
        <app-feedback [formGroup]="currentSeasonSelected" field="inconsistentdates" validator="inconsistentdates" feedback="{{ 'singup.Las fechas son inconsistentes' | translate }}" [refresh]="refreshValidators"></app-feedback>
    </div>
    <div class="col-12">
        <app-feedback [formGroup]="currentSeasonSelected" field="overlapping" validator="overlapping" feedback="{{ 'singup.Las temporadas no pueden solaparse en el tiempo' | translate }}" [refresh]="refreshValidators"></app-feedback>
    </div>
    <div class="col-12">
        <app-feedback [formGroup]="currentSeasonSelected" field="inconsistentexceptiondates" validator="inconsistentexceptiondates" feedback="{{ 'singup.Hay excepciones configuradas fuera de plazo' | translate }}" [refresh]="refreshValidators"></app-feedback>
    </div>

</div>


<app-create-tour-schedule-details *ngIf="seasonDetailsFormGroup" [isI18nTraslation]="i18nTraslation" [formGroupDetails]="seasonDetailsFormGroup" [minDay]="currentSeasonStartDay" [maxDay]="currentSeasonFinishDay" [refreshValidators]="refreshValidators"></app-create-tour-schedule-details>
