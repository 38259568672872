import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { AffiliateResume } from 'src/app/business/models/affiliate/affiliate-resume.model';
import { Pagination } from 'src/app/domain/views/pagination.view';

@Component({
  selector: 'app-affiliates-cards',
  templateUrl: './cards.component.html'
})
export class AffiliatesCardsComponent implements OnInit {

  @Input() affiliates: Array<AffiliateResume>;

  @Input() pagination: Pagination;

  @Output() affiliateTrigger = new EventEmitter<number>();

  constructor() { }

  ngOnInit() {
  }


  onAffiliateClick(affiliateId: number) {
      this.affiliateTrigger.emit(affiliateId);
  }

}
