import { ApiConstants } from '../api.constants';
import { ApiUtils } from '../api.utils';

export class AdminAffiliateApiEndpointsUtils {



     public static getCreateAffiliateEndpoint(): string {
          return `admin/affiliates`;
     }

     public static getCreateAffiliateObject(
          companyName: string, email: string, languageId: number, cityId: number
     ): any {

          return {
            'companyName': companyName,
            'email': email,
            'languageId': languageId,
            'cityId': cityId
          }
     }

     public static getCreateAffiliateEndpointAcceptVersion(): string {
          return ApiConstants.API_HEADER_ACCEPT_V_1_0_1;
     }



     public static getAffiliatesEndpoint(page: number): string {
          return ApiUtils.prepareQueryRequest('admin/affiliates', { 'page': page });
     }

     public static getAffiliatesEndpointAcceptVersion(): string {
          return ApiConstants.API_HEADER_ACCEPT_V_1_0_1;
     }

     public static getAllAffiliatesEndpoint(): string {
          return 'admin/affiliates';
     }

     public static getAllAffiliatesEndpointAcceptVersion(): string {
          return ApiConstants.API_HEADER_ACCEPT_V_1_0_1;
     }

     public static getAffiliateDetailsEndpoint(affiliateId: number): string {
          return `admin/affiliates/${affiliateId}`;
     }

     public static getAffiliateDetailsEndpointAcceptVersion(): string {
          return ApiConstants.API_HEADER_ACCEPT_V_1_0_1;
     }

     public static getAdminAffiliatesNamesEndpoint(): string {
          return `admin/affiliates/names`;
     }

     public static getAdminAffiliatesNamesEndpointAcceptVersion(): string {
          return ApiConstants.API_HEADER_ACCEPT_V_1_0_1;
     }

     public static getResendAffiliateWelcomeEndpoint(affiliateId: number): string {
          return `admin/affiliates/${affiliateId}/resendsignupemail`;
     }

     public static getResendAffiliateWelcomeEndpointAcceptVersion(): string {
          return ApiConstants.API_HEADER_ACCEPT_V_1_0_1;
     }

     public static getUpdateAffiliateDetailsEndpoint(affiliateId: number): string {
          return `admin/affiliates/${affiliateId}/companydetails`;
     }

     public static getUpdateAffiliateDetailsObject(
          companyName: string,
          websites: Array<string>,
          phone: string,
          country: number,
          city: number,
          address: string,
          businessDocumentType: string,
          businessDocument: string,
          businessZeroVat: boolean
     ): any {

          const webs = new Array<any>();
          websites.forEach( (website: string) => {
               webs.push({ 'web': website});
          })

          return {
            'companyName': companyName,
            'webs': websites,
            'phone': phone,
            'countryId': country,
            'cityId': city,
            'address': address,
            'businessDocumentType': businessDocumentType,
            'businessDocument': businessDocument,
            'businessZeroVat': businessZeroVat
          }
     }

     public static getUpdateAffiliateDetailsEndpointAcceptVersion(): string {
          return ApiConstants.API_HEADER_ACCEPT_V_1_0_1;
     }

     public static getConfirmAffiliateSingupEndpoint(affiliateId: number): string {
          return `admin/affiliates/${affiliateId}/confirm`;
     }

     public static getConfirmAffiliateSingupEndpointAcceptVersion(): string {
          return ApiConstants.API_HEADER_ACCEPT_V_1_0_1;
     }

     public static getRejectAffiliateSingupEndpoint(affiliateId: number): string {
          return `admin/affiliates/${affiliateId}/reject`;
     }

     public static getRejectAffiliateSingupEndpointAcceptVersion(): string {
          return ApiConstants.API_HEADER_ACCEPT_V_1_0_1;
     }

     public static getUnregisterAffiliateEndpoint(affiliateId: number): string {
          return `admin/affiliates/${affiliateId}/unregister`;
     }

     public static getUnregisterAffiliateEndpointAcceptVersion(): string {
          return ApiConstants.API_HEADER_ACCEPT_V_1_0_1;
     }

     public static getRegisterAffiliateEndpoint(affiliateId: number): string {
          return `admin/affiliates/${affiliateId}/register`;
     }

     public static getRegisterAffiliateEndpointAcceptVersion(): string {
          return ApiConstants.API_HEADER_ACCEPT_V_1_0_1;
     }

     public static getCreateAffiliateStripeAccountEndpoint(affiliateId: number): string {
          return `admin/affiliates/${affiliateId}/stripe/account`;
     }

     public static getCreateAffiliateStripeAccountEndpointAcceptVersion(): string {
          return ApiConstants.API_HEADER_ACCEPT_V_1_0_1;
     }


     public static getRemoveAffiliateStripeAccountEndpoint(affiliateId: number): string {
          return `admin/affiliates/${affiliateId}/stripe/account`;
     }

     public static getRemoveAffiliateStripeAccountEndpointAcceptVersion(): string {
          return ApiConstants.API_HEADER_ACCEPT_V_1_0_1;
     }

     public static getUpdateAffiliateStripeAccountEndpoint(affiliateId: number): string {
          return `admin/affiliates/${affiliateId}/stripe/account`;
     }

     public static getUpdateAffiliateStripeAccountObject(stripeAccountId: string): any {
          return {
               "stripeAccountId": stripeAccountId
          };
     }

     public static getUpdateAffiliateStripeAccountEndpointAcceptVersion(): string {
          return ApiConstants.API_HEADER_ACCEPT_V_1_0_1;
     }

}


