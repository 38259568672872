import { Component, OnInit, ViewChild } from '@angular/core';

import { MessagePanelComponent } from 'src/app/ui/components/shared/message-panel/message-panel.component';
import { ServerErrorManager } from 'src/app/ui/components/utils/server-error-manager.utils';

import { ChunkGeneric } from 'src/app/domain/views/chunk-generic.view';
import { Pagination } from 'src/app/domain/views/pagination.view';

import { GenericException } from 'src/app/domain/exceptions/generic.exception';
import { AdminBillingInteractor } from 'src/app/domain/interactor/admin/billing.interactor';
import { InvoiceFullDetails } from 'src/app/business/models/billing/invoice-full-details.model';
import { AdminProviderInteractor } from 'src/app/domain/interactor/admin/provider.interactor';
import { Router } from '@angular/router';
import { NavigationConstants } from 'src/app/ui/commons/navigation.constants';

import { marker as I18N } from '@biesbjerg/ngx-translate-extract-marker';
import { AppConstants } from 'src/app/domain/common/app.constants';
import { ServerErrorExceptionCase } from 'src/app/domain/views/server-error-manager-exception-case.view';
import { ApiConstants } from 'src/app/business/api/api.constants';
import { NavigatorUtils } from 'src/app/ui/utils/navigator.utils';
import { BillingUtils } from 'src/app/ui/utils/billing.utils';
import { InvoiceDetails } from 'src/app/business/models/billing/invoice-details.model';
import { TranslateService } from '@ngx-translate/core';
import { CalendarUtils } from 'src/app/ui/utils/calendar.utils';
import { CompanyName } from 'src/app/business/models/master/company/company-name.model';


@Component({
     selector: 'app-list-invoices-admin',
     templateUrl: './list.component.html'
})
export class AdminListInvoicesComponent implements OnInit {

     @ViewChild(MessagePanelComponent)
     private messagePanelComponent: MessagePanelComponent;


     providerId: number;
     month: number;
     year: number;
     pageNumber: number;


     pagination: Pagination;

     invoices: Array<InvoiceFullDetails>;
     providers: Array<CompanyName>;
     months: Array<number>;
     years: Array<number>;

     noResults: boolean;

     desktopVersion: boolean;
     initialWindowSize: number;

     paginationNextLabel: string;
     paginationPreviousLabel: string;

     constructor(
          private adminBillingInteractor: AdminBillingInteractor,
          private adminProviderInteractor: AdminProviderInteractor,
          private translateService: TranslateService,
          private router: Router,
          private serverErrorManager: ServerErrorManager) {

               this.pagination = new Pagination(0, 1, 20);
               this.pageNumber = this.pagination.currentPage;
               this.noResults = true;

               this.desktopVersion = true;

          }



          ngOnInit() {
               this.initialWindowSize = window.innerWidth;

               this.findInvoices();


               this.adminProviderInteractor.getProvidersNames()
               .subscribe(
                    (providers: Array<CompanyName>) => {

                         this.providers = providers;
                    },
                    (error: GenericException) => {
                         this.serverErrorManager.manageErrorCode(error, this.messagePanelComponent);

                    }
               )


               this.translateService.get(I18N('pagination.Siguiente')).subscribe(
                    (messageTranslated: string) => {
                         this.paginationNextLabel = messageTranslated;
                    }
               )

               this.translateService.get(I18N('pagination.Previous')).subscribe(
                    (messageTranslated: string) => {
                         this.paginationPreviousLabel = messageTranslated;
                    }
               )

               this.years = CalendarUtils.getYearsAvailablesForBilling();

          }


          onDesktopVersionUpdated(isDesktopVersion: boolean) {
               this.desktopVersion = isDesktopVersion;
          }


          findInvoices() {

               if (this.messagePanelComponent) {
                    this.messagePanelComponent.showLoading();
               }


               this.adminBillingInteractor.findInvoices(this.providerId, this.year, this.month, this.pageNumber)
               .subscribe(
                    (chunkInvoices: ChunkGeneric<InvoiceFullDetails>) => {

                         this.pagination = new Pagination(chunkInvoices.pagination.totalItems, chunkInvoices.pagination.currentPage, chunkInvoices.pagination.itemsPerPage);
                         this.invoices = chunkInvoices.items;
                         this.noResults = this.invoices.length == 0;

                         MessagePanelComponent.hideLoading();
                    },
                    (error: GenericException) => {
                         MessagePanelComponent.hideLoading();
                         this.serverErrorManager.manageErrorCode(error, this.messagePanelComponent);
                    }
               )
          }



          onPageChanged(currentPage: number) {
               this.pageNumber = currentPage;
               this.findInvoices();
          }

          onProviderSelected() {
               this.findInvoices();
          }



          onResendPaymentRequest(invoiceId: number) {

               this.messagePanelComponent.showLoading();

               this.adminBillingInteractor.invoicePaymentRemember(invoiceId)
               .subscribe(
                    () => {

                         MessagePanelComponent.hideLoading();
                         this.messagePanelComponent.showSuccessMessage(I18N('invoiceslist.Mensaje enviado correctamente'));

                    },
                    (error: GenericException) => {

                         MessagePanelComponent.hideLoading();
                         this.serverErrorManager.manageErrorCode(error, this.messagePanelComponent);

                    }
               )

          }


          onInvoiceDownload(invoiceDetails: InvoiceDetails) {

               this.messagePanelComponent.showLoading();

               this.adminBillingInteractor.invoiceDownload(invoiceDetails.id)
               .subscribe(
                    (invoiceBlob: Blob) => {

                         MessagePanelComponent.hideLoading();

                         // IE doesn't allow using a blob object directly as link href
                         // instead it is necessary to use msSaveOrOpenBlob
                         if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                              window.navigator.msSaveBlob(invoiceBlob);
                              return;
                         }

                         // For other browsers:
                         // Create a link pointing to the ObjectURL containing the blob.
                         const downloadURL = URL.createObjectURL(invoiceBlob);

                         var anchor = document.createElement("a");
                         anchor.download = BillingUtils.getInvoiceNameToDownload(invoiceDetails);
                         anchor.href = downloadURL;
                         anchor.click();


                    },
                    (error: GenericException) => {
                         MessagePanelComponent.hideLoading();
                         this.serverErrorManager.manageErrorCode(error, this.messagePanelComponent);
                    }
               )
          }


          onInvoiceDetails(invoiceId: number) {

               this.router.navigate([NavigationConstants.getNavigationAdminInvoiceDetails(invoiceId)]);

          }


          onPaymentToProvider(invoiceId: number) {


               this.messagePanelComponent.showLoading();

               this.adminBillingInteractor.invoicePaymentToProvider(invoiceId)
               .subscribe(
                    () => {

                         MessagePanelComponent.hideLoading();
                         this.messagePanelComponent.showSuccessMessage(I18N('invoiceslist.Pago realizado correctamente'), () => {
                              NavigatorUtils.reloadApplication();
                         });

                    },
                    (error: GenericException) => {

                         MessagePanelComponent.hideLoading();

                         const exceptionCases = new Array<ServerErrorExceptionCase>();

                         const exceptionCodes = new Array<number>();
                         exceptionCodes.push(AppConstants.ERROR_CLIENT_BAD_REQUEST);

                         const exceptionCauses = new Array<string>();
                         exceptionCauses.push(ApiConstants.API_ERROR_CAUSE_BILLING_PAYMENT_PROVIDER_NOT_STRIPE_ACCOUNT);


                         exceptionCases.push(new ServerErrorExceptionCase(
                              exceptionCodes,
                              exceptionCauses,
                              () => {
                                   this.messagePanelComponent.showErrorMessage(I18N('invoiceslist.El proveedor no tiene su cuenta de pagos correctamente configurada.'));
                              }
                         ));


                         exceptionCases.push(new ServerErrorExceptionCase(
                              exceptionCodes,
                              new Array<string>(),
                              () => {
                                   this.messagePanelComponent.showErrorMessage(I18N('invoiceslist.No se ha podido completar el pago'));
                              }
                         ));


                         this.serverErrorManager.manageErrorCode(error, this.messagePanelComponent, exceptionCases);

                    }
               )

          }




          onManualSettleUp(invoiceId: number) {

               this.messagePanelComponent.showConfirmationMessage(I18N('invoiceslist.La factura se marcará como pagada y la acción no podrá deshacerse.'), () => {

                    this.messagePanelComponent.showLoading();

                    this.adminBillingInteractor.invoiceSettleUp(invoiceId)
                    .subscribe(
                         () => {

                              MessagePanelComponent.hideLoading();
                              this.messagePanelComponent.showSuccessMessage(I18N('invoiceslist.Factura marcada como pagada.'), () => {
                                   NavigatorUtils.reloadApplication();
                              });

                         },
                         (error: GenericException) => {

                              MessagePanelComponent.hideLoading();
                              this.serverErrorManager.manageErrorCode(error, this.messagePanelComponent);

                         }
                    )


               });

          }

          onYearSelected() {
               this.months = new Array<number>();
               this.month = null;
               if ( this.year != null ) {
                    this.months = CalendarUtils.getMonthsAvailablesForBillingFromYear(this.year);
               }
               this.findInvoices();
          }

          onMonthSelected() {
               this.findInvoices();
          }

     }
