import { Injectable }                       from '@angular/core';
import { Observable, Observer } from 'rxjs';
import { GenericException } from '../../exceptions/generic.exception';
import { Tour } from 'src/app/business/models/tour/tour.model';
import { PublicTourService } from 'src/app/business/services/public/tour.service';
import { SessionInteractor } from '../session.interactor';


@Injectable({
  providedIn: 'root'
})
export class TourInteractor {


    constructor(private publicTourService: PublicTourService, private sessionInteractor: SessionInteractor ) {}


    getTour(tourId: number, tourI18NDisplay: number): Observable<Tour> {

        return Observable.create((observer: Observer<Tour>) => {
              this.publicTourService.getTour(tourId, this.sessionInteractor.getCurrentLanguage(), tourI18NDisplay)
                    .subscribe(
                        (tour: Tour) => {

                            observer.next(tour);
                            observer.complete();
                        },
                        (error: GenericException) => {
                            observer.error(error);
                        }
                    )

        });

    }





}
