import { Injectable } from '@angular/core';
import { Observable, Observer } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ApiUtils } from '../api/api.utils';
import { map } from 'rxjs/operators';
import { GenericException } from 'src/app/domain/exceptions/generic.exception';
import { SessionManager } from 'src/app/domain/managers/session-manager';
import { ApiConstants } from '../api/api.constants';
import { CommonApiEndpointsUtils } from '../api/endpoints/common-api-endponints.utils';

@Injectable({
  providedIn: 'root'
})
export class BaseService {

  constructor(private http: HttpClient, private sessionManager: SessionManager) { }


  sendPostRequestWithRefresh(endpoint: string, data: any, version: string): Observable<any> {
    return Observable.create((observer: Observer<any>) => {
      this.sendPostRequest(endpoint, data, version)
        .subscribe(
          (reponse: any) => {
            observer.next(reponse);
            observer.complete();
          },
          (error: GenericException) => {
            if (error.code == ApiConstants.ERROR_CLIENT_UNAUTHORIZED) {

              this.refreshToken().subscribe(
                () => {
                  this.sendPostRequest(endpoint, data, version)
                    .subscribe(
                      (reponse: any) => {
                        observer.next(reponse);
                        observer.complete();
                      }, (error: GenericException) => {
                        observer.error(error);
                      }
                    )
                }, (error: GenericException) => {
                  observer.error(error);
                }

              )

            } else {
              observer.error(error);
            }
          }
        )
    })
  }



  sendPostRequest(endpoint: string, data: any, version: string): Observable<any> {
    return ApiUtils.sendPostRequest<any>(this.http, endpoint, data, version)
  }

  sendGetRequest(endpoint: string, version: string): Observable<any> {
    return ApiUtils.sendGetRequest<any>(this.http, endpoint, version)
  }

  sendDeleteRequest(endpoint: string, version: string): Observable<any> {
    return ApiUtils.sendDeleteRequest<any>(this.http, endpoint, version)
  }


  sendBlobRequest(endpoint: string, version: string): Observable<any> {
    return ApiUtils.sendBlobRequest<any>(this.http, endpoint, version)
  }



  sendGetRequestWithRefresh(endpoint: string, version: string): Observable<any> {
    return Observable.create((observer: Observer<any>) => {
      this.sendGetRequest(endpoint, version)
        .subscribe(
          (reponse: any) => {
            observer.next(reponse);
            observer.complete();
          },
          (error: GenericException) => {

            if (error.code == ApiConstants.ERROR_CLIENT_UNAUTHORIZED || error.code == ApiConstants.ERROR_SERVER_UNKNOWN) {

              this.refreshToken().subscribe(
                () => {
                  this.sendGetRequest(endpoint, version)
                    .subscribe(
                      (reponse: any) => {
                        observer.next(reponse);
                        observer.complete();
                      }, (error: GenericException) => {
                        observer.error(error);
                      }
                    )
                }, (error: GenericException) => {
                  observer.error(error);
                }

              )

            } else {
              observer.error(error);
            }
          }
        )
    })
  }




  sendDeleteRequestWithRefresh(endpoint: string, version: string): Observable<any> {
    return Observable.create((observer: Observer<any>) => {
      this.sendDeleteRequest(endpoint, version)
        .subscribe(
          (reponse: any) => {
            observer.next(reponse);
            observer.complete();
          },
          (error: GenericException) => {

            if (error.code == ApiConstants.ERROR_CLIENT_UNAUTHORIZED || error.code == ApiConstants.ERROR_SERVER_UNKNOWN) {

              this.refreshToken().subscribe(
                () => {
                  this.sendDeleteRequest(endpoint, version)
                    .subscribe(
                      (reponse: any) => {
                        observer.next(reponse);
                        observer.complete();
                      }, (error: GenericException) => {
                        observer.error(error);
                      }
                    )
                }, (error: GenericException) => {
                  observer.error(error);
                }

              )

            } else {
              observer.error(error);
            }
          }
        )
    })
  }



  sendPutRequest(endpoint: string, data: any, version: string): Observable<any> {
    return ApiUtils.sendPutRequest<any>(this.http, endpoint, data, version)
  }


  sendPutRequestWithRefresh(endpoint: string, data: any, version: string): Observable<any> {
    return Observable.create((observer: Observer<any>) => {
      this.sendPutRequest(endpoint, data, version)
        .subscribe(
          (reponse: any) => {
            observer.next(reponse);
            observer.complete();
          },
          (error: GenericException) => {
            if (error.code == ApiConstants.ERROR_CLIENT_UNAUTHORIZED) {

              this.refreshToken().subscribe(
                () => {
                  this.sendPutRequest(endpoint, data, version)
                    .subscribe(
                      (reponse: any) => {
                        observer.next(reponse);
                        observer.complete();
                      }, (error: GenericException) => {
                        observer.error(error);
                      }
                    )
                }, (error: GenericException) => {
                  observer.error(error);
                }

              )

            } else {
              observer.error(error);
            }
          }
        )
    })
  }




  sendBlobRequestWithRefresh(endpoint: string, version: string): Observable<any> {
    return Observable.create((observer: Observer<any>) => {
     this.sendBlobRequest(endpoint, version)
        .subscribe(
          (reponse: any) => {
            observer.next(reponse);
            observer.complete();
          },
          (error: GenericException) => {

            if (error.code == ApiConstants.ERROR_CLIENT_UNAUTHORIZED || error.code == ApiConstants.ERROR_SERVER_UNKNOWN) {

              this.refreshToken().subscribe(
                () => {
                  this.sendBlobRequest(endpoint, version)
                    .subscribe(
                      (reponse: any) => {
                       observer.next(reponse);
                       observer.complete();
                      }, (error: GenericException) => {
                       observer.error(error);
                      }
                    )
                }, (error: GenericException) => {
                  observer.error(error);
                }

              )

            } else {
              observer.error(error);
            }
          }
        )
    })
  }






  private refreshToken(): Observable<boolean> {

    return this.sendPostRequest(CommonApiEndpointsUtils.getRefreshTokenEndpoint(), { 'refresh_token': this.sessionManager.getRefreshtoken() }, CommonApiEndpointsUtils.getRefreshTokenEndpointAcceptVersion())
      .pipe(
        map((response: any) => {
          this.sessionManager.updateCredentials(response.access_token, response.refresh_token);
          return true;
        })
      )
  }

}
