import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ServerErrorManager } from 'src/app/ui/components/utils/server-error-manager.utils';
import { GenericException } from 'src/app/domain/exceptions/generic.exception';
import { MessagePanelComponent } from 'src/app/ui/components/shared/message-panel/message-panel.component';
import { ProviderTourInteractor } from 'src/app/domain/interactor/provider/tour.interactor';
import { TourResume } from 'src/app/business/models/tour/tour-resume.model';
import { Pagination } from 'src/app/domain/views/pagination.view';
import { ChunkGeneric } from 'src/app/domain/views/chunk-generic.view';
import { GuideToursFilterView } from 'src/app/domain/views/guide/guide-tours-filter.view';
import { ProviderGuideInteractor } from 'src/app/domain/interactor/provider/guide.interactor';
import { Guide } from 'src/app/business/models/guide/guide.model';
import { marker as I18N } from '@biesbjerg/ngx-translate-extract-marker';

@Component({
     selector: 'app-guide-details-tours-management',
     templateUrl: './tours-management.component.html'
})
export class GuideDetailsToursManagementComponent implements OnInit{

     @ViewChild(MessagePanelComponent)
     private messagePanelComponent: MessagePanelComponent;

     @Input()
     set guide( guide: Guide ) {
          if ( guide ) {
               this.guideId = guide.id;
               this.providerToursSelected = guide.managedTours;
          }
     }

     guideId: number;
     providerToursSelected: Array<TourResume>;
     providerTours: Array<TourResume>;
     providerToursPagination: Pagination;
     isDisabled: boolean;


     constructor(
          private providerTourInteractor: ProviderTourInteractor,
          private providerGuideInteractor: ProviderGuideInteractor,
          private serverErrorManager: ServerErrorManager,
     ) {

              this.guideId = null;
              this.providerTours = new Array<TourResume>();
              this.providerToursPagination = new Pagination(0, 1, 20);
              this.providerToursSelected = new Array<TourResume>();

              this.isDisabled = true;
     }


     ngOnInit() {

     }


     toggleEdition() {
          this.isDisabled = !this.isDisabled
     }


     saveDetails() {

          this.messagePanelComponent.showLoading();

          this.providerGuideInteractor.updateGuideManagedTours(this.guideId, this.providerToursSelected)
               .subscribe(
                    () => {
                      MessagePanelComponent.hideLoading();
                      this.messagePanelComponent.showSuccessMessage(I18N('guidedetails.Datos actualizados'));

                      this.toggleEdition();

                    }, (error: GenericException) => {

                      MessagePanelComponent.hideLoading();
                      this.serverErrorManager.manageErrorCode(error, this.messagePanelComponent);
                    }
               );

     }


     findProviderTours(keywords: string, countryId: number, cityId: number, pageNumber: number) {
          this.providerTourInteractor.findTours(keywords, countryId, cityId, pageNumber)
             .subscribe(
                  (toursChunk: ChunkGeneric<TourResume>) => {
                       this.providerTours = toursChunk.items;
                       this.providerToursPagination = toursChunk.pagination
                  },
                  (error: GenericException) => {

                    MessagePanelComponent.hideLoading();
                    this.serverErrorManager.manageErrorCode(error, this.messagePanelComponent);

                  }
             )
    }

     onProviderTourFilter(guideToursFilterView: GuideToursFilterView) {
          this.findProviderTours(guideToursFilterView.keywords, guideToursFilterView.countryId, guideToursFilterView.cityId, guideToursFilterView.pageNumber);
     }

     onProviderTourSelected(tourResume: TourResume) {
          const tourResumeIndex = this.providerToursSelected.findIndex( (tourResumeSelected: TourResume) => {
               return tourResumeSelected.id == tourResume.id;
          });
          if ( tourResumeIndex < 0 ) {
               this.providerToursSelected.push(tourResume);
          }
     }

     onProviderTourRemove(tourResume: TourResume) {
          this.providerToursSelected = this.providerToursSelected.filter( (tourResumeSelected: TourResume) => {
               return tourResumeSelected.id != tourResume.id;
          });
     }

}
