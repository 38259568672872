export class EventBasic {

     private _tourId: number;
     private _day: string;
     private _hour: string;

     constructor(
          tourId: number,
          day: string,
          hour: string,
     ) {
          this._tourId = tourId;
          this._day = day;
          this._hour = hour;
     }

     get tourId(): number {
          return this._tourId;
     }

     get day(): string {
          return this._day;
     }

     get hour(): string {
          return this._hour;
     }

}