<form class="form-boxes m-0 p-0" [formGroup]="profilePaymentForm">

    <!-- Empresa -->
    <div class="row mb-2">
        <div class="col-10">
            <h2 class="font-weight-bold d-flex">
                <img class="ic-mid mr-2" src="assets/img/ic_verified_user.svg">{{ 'providerpayment.Datos fiscales' | translate }}
            </h2>
            <h5>{{ 'providerpayment.Información de la empresa' | translate }}</h5>
            <hr class="w-10 float-left mt-2 mb-4">
        </div>
        <div class="col-2 text-right">
            <a class="cursor-pointer" (click)="toggleEdition()">
                <img class="ic-mid mr-2" src="{{ isDisabled ? 'assets/img/ic_edit.svg' : 'assets/img/ic_edit-disabled.svg'}}">
            </a>
        </div>
    </div>

    <div class="row mb-3">
        <div class="col-12 col-md-6">
            <div class="field pb-2">
                <input formControlName="businessName" type="text" name="businessName" id="businessName" placeholder="{{ 'providerpayment.Nombre fiscal' | translate }}" value="">
                <label for="businessName">{{ 'providerpayment.Nombre fiscal' | translate }}</label>
            </div>
            <app-feedback [formGroup]="profilePaymentForm" field="businessName" validator="required" feedback="{{ 'providerpayment.Nombre fiscal requerido' | translate }}" [refresh]="refreshValidators"></app-feedback>
            <app-feedback [formGroup]="profilePaymentForm" field="businessName" validator="maxlength" feedback="{{ 'providerpayment.Texto demasiado largo' | translate }}" [refresh]="refreshValidators"></app-feedback>
        </div>
        <div class="col-12 col-md-6">
            <div class="field pb-2">
                <input formControlName="businessAddress" type="text" name="businessAddress" id="businessAddress" placeholder="{{ 'providerpayment.Dirección fiscal' | translate }}" value="">
                <label for="businessAddress">{{ 'providerpayment.Dirección fiscal' | translate }}</label>
            </div>
            <app-feedback [formGroup]="profilePaymentForm" field="businessAddress" validator="required" feedback="{{ 'providerpayment.Dirección fiscal requerida' | translate }}" [refresh]="refreshValidators"></app-feedback>
        </div>


        <div class="col-12 col-md-6">
            <div class="field pb-2">
                 <select formControlName="businessDocumentType" name="businessDocumentType">
                    <option value="">{{ 'providerpayment.Selecciona la opción' | translate }}</option>
                    <option value="CIF">{{ 'providerpayment.CIF' | translate }}</option>
                    <option value="NIF">{{ 'providerpayment.NIF' | translate }}</option>
                    <option value="VAT">{{ 'providerpayment.VAT' | translate }}</option>
                    <option value="DNI">{{ 'providerpayment.DNI' | translate }}</option>
                    <option value="Pasport">{{ 'providerpayment.Pasaporte' | translate }}</option>
                 </select>
                <label for="businessDocumentType">{{ 'providerpayment.Tipo de documento' | translate }}</label>
            </div>
            <app-feedback [formGroup]="profilePaymentForm" field="businessDocumentType" validator="required" feedback="{{ 'providerpayment.Tipo de documento requerido' | translate }}" [refresh]="refreshValidators"></app-feedback>
        </div>

        <div class="col-12 col-md-6">
            <div class="field pb-2">
                <input formControlName="businessDocument" type="text" name="businessDocument" id="businessDocument" placeholder="{{ 'providerpayment.Número de documento' | translate }}" value="">
                <label for="businessDocument">{{ 'providerpayment.Número de documento' | translate }}</label>
            </div>
            <app-feedback [formGroup]="profilePaymentForm" field="businessDocument" validator="required" feedback="{{ 'providerpayment.Número de documento requerido' | translate }}" [refresh]="refreshValidators"></app-feedback>
        </div>



        <div class="col-12 col-md-6 d-flex mt-4">
            <div class="checkbox-custom mr-2">
                <input formControlName="businessZeroVat" type="checkbox" name="businessZeroVat" id="businessZeroVat" value="">
                <div class="checkbox-custom-visible"></div>
            </div>
            <label for="businessZeroVat">{{ 'providerpayment.Exento de IVA' | translate }}</label>
        </div>

    </div>

    <div class="row mb-5 pb-5">
        <div class="col-12 col-md-6 offset-md-6 col-lg-4 offset-lg-8">
            <input (click)="savePayment()" type="submit" value="{{ 'providerpayment.Guardar datos' | translate }}" class="btn btn-full-width btn-primary mb-3 mb-sm-0" [disabled]="isDisabled" />
        </div>
    </div>
</form>