import { Component, Input, Output, EventEmitter, OnInit, ViewChild } from '@angular/core';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { FormUtils } from 'src/app/ui/components/utils/form.utils';
import { Country } from 'src/app/business/models/master/country/country.model';
import { City } from 'src/app/business/models/master/city/city.model';
import { ServerErrorManager } from 'src/app/ui/components/utils/server-error-manager.utils';
import { AppConstants } from 'src/app/domain/common/app.constants';
import { GenericException } from 'src/app/domain/exceptions/generic.exception';
import { UserMasterInteractor } from 'src/app/domain/interactor/user/user-master.interactor';
import { MessagePanelComponent } from '../../message-panel/message-panel.component';
import { Guide } from 'src/app/business/models/guide/guide.model';
import { GuideDetailsView } from 'src/app/domain/views/guide/guide-details.view';

@Component({
     selector: 'app-profile-guide-details',
     templateUrl: './details.component.html'
})
export class ProfileGuideDetailsComponent implements OnInit{

     @ViewChild(MessagePanelComponent)
     private messagePanelComponent: MessagePanelComponent;


     @Input()
     set guide(guide: Guide) {
          if ( guide ) {
               this.setGuideFormValues(guide)
          }
     }

     @Output() guideDetails = new EventEmitter<GuideDetailsView>(true);

     guideDetailsForm: FormGroup
     isDisabled = true

     countries: Array<Country>
     cities: Array<City>

     refreshValidators: boolean

     constructor(
          private userMasterInteractor: UserMasterInteractor,
          private serverErrorManager: ServerErrorManager,
     ) {

          this.refreshValidators = false;

          this.guideDetailsForm = new FormGroup({
               'id': new FormControl({ value: '', disabled: true }, [Validators.required] ),
               'companyName': new FormControl({ value: '', disabled: true }, [Validators.required, Validators.maxLength(AppConstants.TEXT_DEFAULT_MAX_LENGTH)] ),
               'country': new FormControl({ value: '', disabled: true }, [Validators.required] ),
               'city': new FormControl({ value: '', disabled: true }, [Validators.required] ),
               'address': new FormControl({ value: '', disabled: true }, [Validators.required, Validators.maxLength(AppConstants.TEXT_DEFAULT_MAX_LENGTH)] ),
               'businessDocumentType': new FormControl({ value: '', disabled: true }, [Validators.required]),
               'businessDocument': new FormControl({ value: '', disabled: true }, [Validators.required, Validators.maxLength(AppConstants.TEXT_DEFAULT_MAX_LENGTH)]),
               'phone': new FormControl({ value: '', disabled: true }, [Validators.required, Validators.maxLength(AppConstants.TEXT_DEFAULT_MAX_LENGTH)] ),
               'businessZeroVat': new FormControl({ value: false, disabled: true }, [] )
          });

     }


     ngOnInit() {
          this.userMasterInteractor.getCountriesByUser()
          .subscribe(
               (countries: Array<Country>) => {
                    this.countries = countries;
               },
               (error: GenericException) => {
                    this.serverErrorManager.manageErrorCode(error, this.messagePanelComponent);
               }
          )
     }


     toggleEdition() {

          this.isDisabled = !this.isDisabled

          if ( this.isDisabled ) {
               FormUtils.disableAllControls(this.guideDetailsForm);
          } else {
               FormUtils.enableAllControls(this.guideDetailsForm);
          }
     }


     saveDetails() {

          this.refreshValidators = true;

          if ( this.guideDetailsForm.valid ) {

               this.guideDetails.emit(
                    new GuideDetailsView(
                         this.guideDetailsForm.value.id,
                         this.guideDetailsForm.value.companyName,
                         this.guideDetailsForm.value.address,
                         this.guideDetailsForm.value.businessDocumentType,
                         this.guideDetailsForm.value.businessDocument,
                         this.guideDetailsForm.value.businessZeroVat,
                         this.guideDetailsForm.value.phone,
                         this.guideDetailsForm.value.country,
                         this.guideDetailsForm.value.city
                    )
               );

               this.toggleEdition();

          }

     }

     private setGuideFormValues( guide: Guide ) {

          this.guideDetailsForm.controls['id'].setValue(guide.id);
          this.guideDetailsForm.controls['companyName'].setValue(guide.companyName);
          this.guideDetailsForm.controls['address'].setValue(guide.businessAddress);

          this.guideDetailsForm.controls['businessDocumentType'].setValue(guide.businessDocumentType);
          this.guideDetailsForm.controls['businessDocument'].setValue(guide.businessDocument);

          this.guideDetailsForm.controls['businessZeroVat'].setValue(false);
          if ( guide.businessZeroVat == true ) {
               this.guideDetailsForm.controls['businessZeroVat'].setValue(true);
          }
          this.guideDetailsForm.controls['phone'].setValue(guide.phone);

          this.guideDetailsForm.controls['city'].setValue(guide.cityId);
          this.guideDetailsForm.controls['country'].setValue(guide.countryId);
          if ( guide.countryId ) {
               this.userMasterInteractor.getCitiesByUser(guide.countryId)
               .subscribe(
                    (cities: Array<City>) => {
                         this.cities = cities;
                    },
                    (error: GenericException) => {
                         this.serverErrorManager.manageErrorCode(error, this.messagePanelComponent);
                    }
               )
          }

     }


     onCountrySelected() {

          const selectedCountryId = this.guideDetailsForm.value.country;

          this.cities = new Array<City>();
          this.guideDetailsForm.controls['city'].setValue(null);

          if ( selectedCountryId ) {

               this.userMasterInteractor.getCitiesByUser(selectedCountryId)
               .subscribe(
                    (cities: Array<City>) => {
                         this.cities = cities;
                    },
                    (error: GenericException) => {
                         this.serverErrorManager.manageErrorCode(error, this.messagePanelComponent);
                    }
               )

          }

     }

}
