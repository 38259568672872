import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BaseService } from '../base.service';
import { ChunkGeneric } from 'src/app/domain/views/chunk-generic.view';
import { Pagination } from 'src/app/domain/views/pagination.view';
import { InvoiceDetails } from '../../models/billing/invoice-details.model';
import { TourBilling } from '../../models/billing/tour-billing.model';
import { NextBilling } from '../../models/billing/next-billing.model';
import { TourUtils } from '../../models/tour/common/tour.utils';
import { BillingUtils } from '../../models/billing/common/billing.utils';
import { CalendarUtils } from 'src/app/ui/utils/calendar.utils';
import { BookingBilling } from '../../models/billing/booking-billing.model';
import { ApiConstants } from '../../api/api.constants';
import { CustomerDetails } from '../../models/billing/customer-details.model';
import { BookingUtils } from '../../models/book/common/booking.utils';
import { InvoiceFullDetails } from '../../models/billing/invoice-full-details.model';
import { ProviderApiEndpointsUtils } from '../../api/endpoints/provider-api-endponints.utils';
import { ProviderGuideApiEndpointsUtils } from '../../api/endpoints/provider-guide-api-endponints.utils';
import { GuideUtils } from '../../models/guide/common/guide.utils';
import { GuideBillingStats } from '../../models/billing/billing-stats.model';
import { TourResume } from '../../models/tour/tour-resume.model';
import { GuideBillingTourStats } from '../../models/billing/billing-tour-stats.model';

@Injectable({
  providedIn: 'root'
})
export class ProviderBillingService extends BaseService {


  findInvoices(page: number): Observable<ChunkGeneric<InvoiceDetails>> {

    return super.sendGetRequestWithRefresh(ProviderApiEndpointsUtils.getProviderFindInvoicesEndpoint(page), ProviderApiEndpointsUtils.getProviderFindInvoicesEndpointAcceptVersion())
      .pipe(
        map((response: any) => {

          const invoices = new Array<InvoiceDetails>();

          response.items.map((invoiceApi: any) => {

            invoices.push(BillingUtils.getInvoiceFromApi(invoiceApi));

          });

          return new ChunkGeneric<InvoiceDetails>(invoices, new Pagination(response.total, response.currentPage, response.chunkSize));

        })
      )
  }



  findBilling(dateInit: string, dateEnd: string, keywords: string, page: number): Observable<ChunkGeneric<BookingBilling>> {

    return super.sendGetRequestWithRefresh(
      ProviderApiEndpointsUtils.getProviderFindBillingEndpoint(dateInit, dateEnd, keywords, page),
      ProviderApiEndpointsUtils.getProviderFindBillingEndpointAcceptVersion()
    )
      .pipe(
        map((response: any) => {

          const bookingBillings = new Array<BookingBilling>();

          response.items.map((bookingBillingApi: any) => {

            const bookingBilling: BookingBilling = new BookingBilling(
              bookingBillingApi.orderBooking.id,
              bookingBillingApi.orderBooking.event.tour.id,
              bookingBillingApi.orderBooking.event.tour.i18n.translation.name,
              CalendarUtils.fromFormattedDateToDDMMYYYY(bookingBillingApi.orderBooking.event.day, ApiConstants.API_DATE_FORMAT_DD_MM_YYYY),
              bookingBillingApi.orderBooking.event.hour,
              ( ApiConstants.API_INVOICE_STATE_INVOICED == bookingBillingApi.state ) ? BillingUtils.getInvoiceFromApi(bookingBillingApi.invoice) : null,
              BillingUtils.getBillingDetailsFromApi(bookingBillingApi.info),
              new CustomerDetails(bookingBillingApi.customer.id, bookingBillingApi.customer.email, bookingBillingApi.customer.name, bookingBillingApi.customer.surname, bookingBillingApi.customer.phone),
              BookingUtils.getStateFromApi(bookingBillingApi.orderBooking.state),
              bookingBillingApi.guide ? GuideUtils.getGuideResumeFromApi(bookingBillingApi.guide) : null
            );

            bookingBillings.push(bookingBilling);

          })


          return new ChunkGeneric<BookingBilling>(bookingBillings, new Pagination(response.total, response.currentPage, response.chunkSize));
        })
      )
  }



  findNextBilling(localeI18N: string): Observable<NextBilling> {

    return super.sendGetRequestWithRefresh(ProviderApiEndpointsUtils.getProviderFindNextBillingEndpoint(), ProviderApiEndpointsUtils.getProviderFindNextBillingEndpointAcceptVersion())
      .pipe(
        map((response: any) => {

          const tourBillings = new Array<TourBilling>();
          let nextBillingTotal: number = 0;

          response.map((tourBillingApi: any) => {

            const tourBilling = new TourBilling(
              TourUtils.getTourResumeFromApi(tourBillingApi.tour, localeI18N),
              BillingUtils.getBillingDetailsFromApi(tourBillingApi.info)
            );

            nextBillingTotal += tourBilling.billingDetails.commission;

            tourBillings.push(tourBilling);

          });

          return new NextBilling(tourBillings, nextBillingTotal);

        })
      )
  }


  invoiceDownload(invoiceId: number): Observable<string> {

    return super.sendBlobRequestWithRefresh(ProviderApiEndpointsUtils.getProviderInvoiceDownloadEndpoint(invoiceId), ProviderApiEndpointsUtils.getProviderInvoiceDownloadEndpointAcceptVersion())
      .pipe(
        map((response: any) => {

          return response;

        })
      )
  }



  findInvoice(invoiceId: number): Observable<InvoiceFullDetails> {

    return super.sendGetRequestWithRefresh(ProviderApiEndpointsUtils.getProviderFindInvoiceEndpoint(invoiceId), ProviderApiEndpointsUtils.getProviderFindInvoiceEndpointAcceptVersion())
      .pipe(
        map((response: any) => {

          return new InvoiceFullDetails(
               BillingUtils.getInvoiceIssuerFromApi(response.issuer),
               BillingUtils.getInvoiceFromApi(response),
               null
          );

        })
      )
  }


  prepareInvoicePayment(invoiceId: number): Observable<string> {

    return super.sendPostRequestWithRefresh(ProviderApiEndpointsUtils.getProviderPrepareInvoicePaymentEndpoint(invoiceId), {}, ProviderApiEndpointsUtils.getProviderPrepareInvoicePaymentEndpointAcceptVersion())
      .pipe(
        map((response: any) => {

          return response.stripeClientSecret;

        })
      )
  }


  findGuideBilling(dateInit: string, dateEnd: string, guideId: number, keywords: string, page: number): Observable<ChunkGeneric<BookingBilling>> {

       return super.sendGetRequestWithRefresh(
            ProviderGuideApiEndpointsUtils.getProviderFindGuideBillingEndpoint(dateInit, dateEnd, guideId, keywords, page),
            ProviderGuideApiEndpointsUtils.getProviderFindGuideBillingEndpointAcceptVersion()
       )
       .pipe(
            map((response: any) => {

                 const bookingBillings = new Array<BookingBilling>();


                 response.items.map((bookingBillingApi: any) => {

                      const bookingBilling: BookingBilling = new BookingBilling(
                           bookingBillingApi.orderBooking.id,
                           bookingBillingApi.orderBooking.event.tour.id,
                           bookingBillingApi.orderBooking.event.tour.i18n.translation.name,
                           CalendarUtils.fromFormattedDateToDDMMYYYY(bookingBillingApi.orderBooking.event.day, ApiConstants.API_DATE_FORMAT_DD_MM_YYYY),
                           bookingBillingApi.orderBooking.event.hour,
                           null,
                           BillingUtils.getBillingDetailsFromApi(bookingBillingApi.info),
                           new CustomerDetails(bookingBillingApi.customer.id, null, bookingBillingApi.customer.name, bookingBillingApi.customer.surname, null),
                           BookingUtils.getStateGuideCommisionFromApi(bookingBillingApi.info.state),
                           bookingBillingApi.guide ? GuideUtils.getGuideResumeFromApi(bookingBillingApi.guide) : null
                      );


                      bookingBillings.push(bookingBilling);

                 });

                 return new ChunkGeneric<BookingBilling>(bookingBillings, new Pagination(response.total, response.currentPage, response.chunkSize));
            })
       )
  }




  findGuideBillingStats(dateInit: string, dateEnd: string, guideId: number, keywords: string, localeI18N: string): Observable<GuideBillingStats> {

    return super.sendGetRequestWithRefresh(
         ProviderGuideApiEndpointsUtils.getProviderFindGuideBillingStatsEndpoint(dateInit, dateEnd, guideId, keywords),
         ProviderGuideApiEndpointsUtils.getProviderFindGuideBillingStatsAcceptVersion()
    )
    .pipe(
        map((billingGuideStatsApi: any) => {

            const totalCommissions = billingGuideStatsApi.totalCommissions;
            const guideBillingToursStats = new Array<GuideBillingTourStats>();

            billingGuideStatsApi.toursDetailsStats.map((tourDetailsStats: any) => {

                const tour: TourResume = TourUtils.getTourResumeFromApi(tourDetailsStats.tour, localeI18N);

                const guideBillingTourStats: GuideBillingTourStats = new GuideBillingTourStats(
                      tour.id,
                      tour.name,
                      tourDetailsStats.billingGuideTourStats.people,
                      tourDetailsStats.billingGuideTourStats.commissions
                );

                guideBillingToursStats.push(guideBillingTourStats);

            })


            return new GuideBillingStats(guideBillingToursStats, totalCommissions);
        })
    )
}


}
