import { Pipe, PipeTransform } from '@angular/core';
import { GuideState } from 'src/app/business/models/guide/common/guide-state.constants';

@Pipe({
  name: 'provideGuideStateClass'
})
export class ProvideGuideStateClassPipe implements PipeTransform {

  transform(classLabels: string, state: GuideState): String {

    let result = classLabels;
    if ( state == GuideState.Active ) {
        result = result + " " + 'normal';
   } else if ( state == GuideState.Inactive ) {
        result = result + " " + 'remove';
    }

    return result ;

  }

}
