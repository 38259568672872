<form class="form-boxes m-0 p-0" action="" [formGroup]="cancelBookingForm">

    <div class="modal-header pb-0">
        <button type="button" class="close" aria-label="Close" (click)="onCoseModal()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body mb-5">
        <h5 class="modal-title">{{ 'cancelBooking.Cancelación de reserva' | translate}}</h5>
        <p>{{ 'cancelBooking.El número de cancelaciones puede afectar al posicionamiento del tour' | translate}}</p>
        <div class="row mt-5">
            <div class="col-12 col-lg-12">
                <div class="field pb-2">
                     <select formControlName="reason" name="reason">
                         <option *ngFor="let cancelationReason of cancelationReasons" value="{{cancelationReason}}">{{ '' | cancelationReasonLabelPipe: cancelationReason | translate }}</option>
                     </select>
                    <label for="reason">{{ 'cancelBooking.Motivo' | translate }}</label>
                </div>
                <app-feedback [formGroup]="cancelBookingForm" field="reason" validator="required" feedback="{{ 'cancelBooking.Motivo de cancelación requerido' | translate }}" [refresh]="refreshValidators"></app-feedback>
            </div>

            <div class="col-12 col-lg-12">
                <div class="field pb-2">
                    <input formControlName="message" type="text">
                    <label for="message">{{ 'cancelBooking.Mensaje para el cliente' | translate }}</label>
                </div>
                <app-feedback [formGroup]="cancelBookingForm" field="message" validator="required" feedback="{{ 'cancelBooking.Mensaje requerido' | translate }}" [refresh]="refreshValidators"></app-feedback>
            </div>

        </div>
    </div>
    <div class="modal-footer border-top-0">
        <input (click)="onCancelBooking()" type="submit" value="{{ 'cancelBooking.Guardar datos' | translate }}" class="btn bg-primary-color mb-3 mb-sm-0 mr-4 modal-confirm" />
        <input (click)="onCoseModal()" type="submit" value="{{ 'cancelBooking.Cancelar' | translate }}" class="btn mb-3 mb-sm-0 modal-cancel" />
    </div>
</form>



<app-message-panel></app-message-panel>