import { ProviderState } from './provider-state.constants';
import { ApiConstants } from 'src/app/business/api/api.constants';
import { ProviderRegistrationState } from './provider-registration-state.constants';
import { Provider } from '../provider.model';
import { ImageUtils } from '../../image/common/image.utils';
import { ProviderResume } from '../provider-resume.model';
import { PaymentPlatformUtils } from '../../master/payment-platform/payment-platform.utils';
import { ProviderGuidesManagement } from './provider-guides-management.constants';

export class ProviderUtils {

     public static getStateFromApi(state: number): ProviderState {

          if ( state == ApiConstants.API_PROVIDER_STATE_ACTIVE ) {
               return ProviderState.Active;
          } else {
               return ProviderState.Inactive;
          }
     }


     public static getRegistrationStateFromApi(registrationState: number): ProviderRegistrationState {

          if ( registrationState == ApiConstants.API_PROVIDER_STATE_REGISTRATION_PENDING_SIGN_UP ) {
               return ProviderRegistrationState.PendingSingUp;
          } else if ( registrationState == ApiConstants.API_PROVIDER_STATE_REGISTRATION_PENDING_APPROVAL ) {
               return ProviderRegistrationState.PendingAproval;
          } else if ( registrationState == ApiConstants.API_PROVIDER_STATE_REGISTRATION_CONFIRMED ) {
               return ProviderRegistrationState.RegistrationConfirmed;
          } else if ( registrationState == ApiConstants.API_PROVIDER_STATE_UNREGISTRATION_REQUESTED ) {
               return ProviderRegistrationState.UnregistrationRequested;
          } else {
               return ProviderRegistrationState.UnregistrationConfirmed;
          }
     }


     public static getProviderFromApi(providerFromApi: any): Provider {

          return new Provider(
               providerFromApi.id,
               providerFromApi.languageId,
               providerFromApi.companyName,
               providerFromApi.contactPerson,
               providerFromApi.phone,
               providerFromApi.countryId,
               providerFromApi.countryName,
               providerFromApi.cityId,
               providerFromApi.cityName,
               providerFromApi.web,
               providerFromApi.contactEmail,
               providerFromApi.bookingNotificationEmailActive,
               providerFromApi.bookingNotificationEmail,
               providerFromApi.bookingNotificationEmail2,
               providerFromApi.businessName,
               providerFromApi.businessDocumentType,
               providerFromApi.businessDocument,
               providerFromApi.businessAddress,
               providerFromApi.businessZeroVat,
               providerFromApi.stripeAccountId,
               PaymentPlatformUtils.getPaymentPlatformFromApi(providerFromApi.paymentPlatform),
               providerFromApi.paymentCurrency,
               providerFromApi.iban,
               ProviderUtils.getStateFromApi(providerFromApi.state),
               ProviderUtils.getRegistrationStateFromApi(providerFromApi.registrationState),
               ProviderUtils.getGuidesManagementFromApi(providerFromApi.guidesManagement),
               providerFromApi.canReceivePayments,
               providerFromApi.canCreateStripeAccount,
               providerFromApi.canGetStripeLink,
               providerFromApi.canUpdateStripeAccount,
               providerFromApi.canRemoveStripeAccount,
               ImageUtils.getImageDetailsFromApi(providerFromApi.image)
          )

     }

     public static getProviderResumeFromApi(providerResumeFromApi: any): ProviderResume {
          return new ProviderResume(
               providerResumeFromApi.id,
               providerResumeFromApi.username,
               providerResumeFromApi.email,
               providerResumeFromApi.companyName,
               providerResumeFromApi.countryName,
               providerResumeFromApi.cityName,
               providerResumeFromApi.contactPerson,
               ProviderUtils.getStateFromApi(providerResumeFromApi.state),
               ProviderUtils.getRegistrationStateFromApi(providerResumeFromApi.registrationState)
          )
     }



     public static getGuidesManagementFromApi(guidesManagementApi: number): ProviderGuidesManagement {

          if ( guidesManagementApi == ApiConstants.API_PROVIDER_GUIDES_MANAGEMENT_FULL ) {
               return ProviderGuidesManagement.Full;
          } else {
               return ProviderGuidesManagement.Disabled;
          }
     }

}
