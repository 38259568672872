import { Component, OnInit, ViewChild } from '@angular/core';
import { marker as I18N } from '@biesbjerg/ngx-translate-extract-marker';
import { ActivatedRoute } from '@angular/router';
import { NavigationConstants } from 'src/app/ui/commons/navigation.constants';
import { ServerErrorManager } from 'src/app/ui/components/utils/server-error-manager.utils';
import { MessagePanelComponent } from 'src/app/ui/components/shared/message-panel/message-panel.component';
import { EventDetails } from 'src/app/business/models/book/event-details.model';
import { BookFareEditionView } from 'src/app/domain/views/book/book-fare-tuple.view';
import { Book } from 'src/app/business/models/book/book.model';
import { BookFare } from 'src/app/business/models/book/book-fare.model';
import { ServerErrorExceptionCase } from 'src/app/domain/views/server-error-manager-exception-case.view';
import { AppConstants } from 'src/app/domain/common/app.constants';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { BookContactDetailsView } from 'src/app/domain/views/book/book-contact-details.view';
import { GenericException } from 'src/app/domain/exceptions/generic.exception';
import { TranslateService } from '@ngx-translate/core';
import { CancelBookingModalComponent } from 'src/app/ui/components/shared/booking/detail/cancel-booking/cancel-booking-modal.component';
import { BookCacnelationDetailsView } from 'src/app/domain/views/book/book-cancelation-details.view';
import { EditBookContactDetailsModalComponent } from 'src/app/ui/components/shared/booking/detail/edit-booking/edit-book-contact-details-modal.component';
import { AdminBookInteractor } from 'src/app/domain/interactor/admin/book.interactor';


@Component({
     selector: 'app-admin-detail-booking',
     templateUrl: './detail.component.html'
})
export class AdminDetailBookingComponent implements OnInit {


     @ViewChild(MessagePanelComponent)
     private messagePanelComponent: MessagePanelComponent;

     desktopVersion: boolean
     initialWindowSize: number

     tourId: number;
     date: string;
     hour: string;
     keywords: string;

     eventDetails: EventDetails;

     constructor(
          private activatedRoute: ActivatedRoute,
          private modalService: NgbModal,
          private adminBookInteractor: AdminBookInteractor,
          private serverErrorManager: ServerErrorManager,
          private translateService: TranslateService
     ) {

          this.desktopVersion = true;

          this.activatedRoute.queryParams.subscribe( queryParams => {

               this.date = queryParams[NavigationConstants.NAVIGATION_ADMIN_BOOK_EVENT_DATE];
               this.hour = queryParams[NavigationConstants.NAVIGATION_ADMIN_BOOK_EVENT_HOUR];

               this.activatedRoute.params.subscribe( params => {
                    this.tourId = params[ NavigationConstants.NAVIGATION_ADMIN_BOOK_EVENT_TOUR_ID ];

                    this.findEventDetails();

               });

          });

     }

     ngOnInit() {
          this.initialWindowSize = window.innerWidth;
     }

     onDesktopVersionUpdated( isDesktopVersion: boolean ) {
          this.desktopVersion = isDesktopVersion;
     }


     findEventDetails() {

          if ( this.messagePanelComponent ) {
               this.messagePanelComponent.showLoading();
          }

          this.adminBookInteractor.findEventDetails(this.tourId, this.date, this.hour, this.keywords)
          .subscribe(
               (eventDetails: EventDetails) => {
                    this.eventDetails = eventDetails;
                    MessagePanelComponent.hideLoading();
               },
               (error: GenericException) => {
                    this.serverErrorManager.manageErrorCode(error, this.messagePanelComponent);
               }
          )

     }


     onKeywordsUpdated() {
          this.findEventDetails();
     }


     onUpdateContactDetails(book: Book) {
          const modalComponent: NgbModalRef = this.modalService.open(EditBookContactDetailsModalComponent, {ariaLabelledBy: 'modal-basic-title', backdrop: 'static'});
          modalComponent.componentInstance.book = book;
          modalComponent.result.then((bookContactDetailsView: BookContactDetailsView) => {

               if ( bookContactDetailsView ) {

                    this.messagePanelComponent.showLoading();

                    this.adminBookInteractor.updateEventBookContactDetails(bookContactDetailsView.bookId, bookContactDetailsView.email, bookContactDetailsView.name, bookContactDetailsView.surname, bookContactDetailsView.phone)
                    .subscribe(
                         () => {

                              book.email = bookContactDetailsView.email;
                              book.name = bookContactDetailsView.name;
                              book.surname = bookContactDetailsView.surname;
                              book.phone = bookContactDetailsView.phone;

                              MessagePanelComponent.hideLoading();
                              this.messagePanelComponent.showSuccessMessage(I18N('editbookcontact.Información actualizada'));

                         },
                         (error: GenericException) => {

                              MessagePanelComponent.hideLoading();
                              this.serverErrorManager.manageErrorCode(error, this.messagePanelComponent);

                         }
                    )

               }

          });
     }

     onResendConfirmationEmail(book: Book) {
          this.messagePanelComponent.showConfirmationMessage(I18N('detailbooking.¿Seguro que quieres reenviar el correo de la reserva al cliente?'), () => {
               this.messagePanelComponent.showLoading();
               this.adminBookInteractor.resendConfirmationEmail(book.id)
               .subscribe(
                    () => {
                         MessagePanelComponent.hideLoading();
                         this.messagePanelComponent.showSuccessMessage(I18N('detailbooking.Correo de confirmación reenviado'));
                    },
                    (error: GenericException) => {
                         this.serverErrorManager.manageErrorCode(error, this.messagePanelComponent);
                    }
               )

          });
     }

     onCancelBooking(book: Book) {

          const modalComponent: NgbModalRef = this.modalService.open(CancelBookingModalComponent, {ariaLabelledBy: 'modal-basic-title', backdrop: 'static'});
          modalComponent.componentInstance.bookId = book.id;
          modalComponent.result.then((bookCacnelationDetailsView: BookCacnelationDetailsView) => {

               if ( bookCacnelationDetailsView ) {

                    this.messagePanelComponent.showLoading();

                    this.adminBookInteractor.cancelBooking(bookCacnelationDetailsView.bookId, bookCacnelationDetailsView.message, bookCacnelationDetailsView.cancelationReason)
                    .subscribe(
                         () => {

                              MessagePanelComponent.hideLoading();
                              this.messagePanelComponent.showSuccessMessage(I18N('cancelBooking.Reserva cancelada correctamente'),
                              () => {
                                   this.findEventDetails();
                              }
                         );


                    },
                    (error: GenericException) => {

                         MessagePanelComponent.hideLoading();
                         this.serverErrorManager.manageErrorCode(error, this.messagePanelComponent);

                    });

               }
          });

     }


     onUpdateShows(bookFareEdition: BookFareEditionView) {

          const currentShows = bookFareEdition.shows;

          this.translateService.get(I18N('detailbooking.Por favor, indica un número válido')).subscribe(
               (messageTranslated: string) => {

                    const validateEmptyLabel = messageTranslated;

                    this.translateService.get(I18N('detailbooking.Por favor, indica un número válido de asistentes')).subscribe(
                         (messageTranslated: string) => {

                              const validateZeroLabel = messageTranslated;

                              this.messagePanelComponent.showInputMessage(
                                   I18N('detailbooking.Edición de asistencia'),
                                   I18N('detailbooking.Confirma el número de asistentes al tour'),
                                   MessagePanelComponent.MESSAGE_INPUT_TYPE_NUMBER,
                                   currentShows.toString(),
                                   (value: string) => {
                                        if ( !value ) {
                                             return validateEmptyLabel;
                                        } else {
                                             const shows = parseInt(value);
                                             if ( shows < 0 ) {
                                                  return validateZeroLabel;
                                             }
                                        }
                                   },
                                   (value: string) => {
                                        const shows = parseInt(value);
                                        const updatedShowsValueDiff = bookFareEdition.shows - shows;

                                        const bookFareEdited: BookFareEditionView = new BookFareEditionView(
                                             bookFareEdition.tourId,
                                             bookFareEdition.date,
                                             bookFareEdition.hour,
                                             bookFareEdition.bookId,
                                             bookFareEdition.fareId,
                                             bookFareEdition.isChildren,
                                             bookFareEdition.isAdult,
                                             bookFareEdition.people,
                                             shows
                                        );

                                        this.messagePanelComponent.showLoading();
                                        this.adminBookInteractor.updateEventBookFareShows(bookFareEdited)
                                        .subscribe(
                                             () => {

                                                  MessagePanelComponent.hideLoading();

                                                  this.eventDetails.bookings.map( (book: Book) => {
                                                       if ( book.id == bookFareEdited.bookId ) {
                                                            book.fares.map( (bookFare: BookFare ) => {

                                                                 if (
                                                                      ( bookFare.isAdult == bookFareEdited.isAdult && bookFareEdited.isAdult == true )
                                                                      || ( bookFare.isChildren == bookFareEdited.isChildren && bookFareEdited.isChildren == true )
                                                                      || (
                                                                           ( bookFare.isChildren == bookFareEdited.isChildren && bookFareEdited.isChildren == false )
                                                                           && ( bookFare.isAdult == bookFareEdited.isAdult && bookFareEdited.isAdult == false )
                                                                           && ( bookFare.id == bookFareEdited.fareId )
                                                                      )
                                                                 ) {
                                                                      bookFare.shows = bookFare.shows - updatedShowsValueDiff;
                                                                      book.shows = book.shows - updatedShowsValueDiff;
                                                                      this.eventDetails.shows = this.eventDetails.shows - updatedShowsValueDiff;
                                                                 }
                                                            });

                                                       }
                                                  })

                                                  this.messagePanelComponent.showSuccessMessage(I18N('detailbooking.Información actualizada'));

                                             },
                                             (error: GenericException) => {

                                                  MessagePanelComponent.hideLoading();

                                                  const exceptionCases = new Array<ServerErrorExceptionCase>();

                                                  const exceptionCodes = new Array<number>();
                                                  exceptionCodes.push(AppConstants.ERROR_CLIENT_BAD_REQUEST);
                                                  exceptionCases.push( new ServerErrorExceptionCase(
                                                       exceptionCodes,
                                                       new Array<string>(),
                                                       () => {
                                                            this.messagePanelComponent.showErrorMessage(I18N('detailbooking.El período de edición de asistencia ha finalizado.'))
                                                       }
                                                  ));

                                                  this.serverErrorManager.manageErrorCode(error, this.messagePanelComponent, exceptionCases);

                                             }
                                        )
                                   }
                              )
                         }
                    )
               }
          )
     }



}
