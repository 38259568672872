import { Component, ViewChild, Input } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TourResumeAdmin } from 'src/app/business/models/tour/tour-resume-admin.model';
import { AdminTourInteractor } from 'src/app/domain/interactor/admin/tour.interactor';
import { MessagePanelComponent } from 'src/app/ui/components/shared/message-panel/message-panel.component';
import { ServerErrorManager } from 'src/app/ui/components/utils/server-error-manager.utils';

import { marker as I18N }               from '@biesbjerg/ngx-translate-extract-marker';
import { GenericException } from 'src/app/domain/exceptions/generic.exception';
import { ProviderTourInteractor } from 'src/app/domain/interactor/provider/tour.interactor';


@Component({
  selector: 'app-provider-edit-tour-guide-sales-commission-modal-component',
  templateUrl: './edit-commission-guide-sales-modal.html'
})

export class ProviderEditTourGuideSalesCommissionModalComponent {

  @ViewChild(MessagePanelComponent, { static: false })
  private messagePanelComponent: MessagePanelComponent;

  commissionForm: FormGroup;
  refreshValidators: boolean;
  defaultCommissionFare: number;


  @Input()
  set tourResume(tourResume: TourResumeAdmin) {
    if (tourResume) {
      this.commissionForm.get('tourId').setValue(tourResume.id);
      this.defaultCommissionFare = 0;

      if ( tourResume.guideSalesCommission != null ) {
           this.commissionForm.get('commission').setValue(tourResume.guideSalesCommission);
      }
    }
  }



  constructor(
    private providerTourInteractor: ProviderTourInteractor,
    private serverErrorManager: ServerErrorManager,
    private modal: NgbActiveModal) {

    this.refreshValidators = false;

    this.commissionForm = new FormGroup({
      'tourId': new FormControl(''),
      'commission': new FormControl('', [Validators.min(0)]),
    })

  }

  ngOnInit() {
  }


  closeModal() {
    this.modal.close();
  }


  saveCommission() {
    this.refreshValidators = true;

    if (this.commissionForm.valid) {

      this.messagePanelComponent.showLoading();

      const tourId: number = this.commissionForm.get('tourId').value;
      const commission: number = this.commissionForm.get('commission').value;

      this.providerTourInteractor.updateTourGuideSalesCommission(tourId, commission)
        .subscribe(
          () => {

            MessagePanelComponent.hideLoading();
            this.messagePanelComponent.showSuccessMessage(I18N('editcommission.Información actualizada'));
            this.modal.close(commission);

          },
          (error: GenericException) => {

            MessagePanelComponent.hideLoading();
            this.serverErrorManager.manageErrorCode(error, this.messagePanelComponent);

          }
        )

    }

  }


}




