import { Component, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'app-window-size-controller',
  templateUrl: './window-size-controller.component.html'
})
export class WindowSizeControllerComponent {

  @Output() isDesktopVersion = new EventEmitter<boolean>(true);

  @Input()
  set initialSize(initialSize: number) {
    if ( initialSize && initialSize > 0 ) {
      this.updateScreenVersion(initialSize);
    }
  }


  constructor() {
  }

  onResize(event: any) {
    this.updateScreenVersion(event.target.innerWidth);
  }


  private updateScreenVersion(width: number) {
      this.isDesktopVersion.emit(width > 900);
  }

}
