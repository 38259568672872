import { CompanyName } from '../master/company/company-name.model';
import { Tour } from './tour.model';

export class TourProvider {

  private _provider: CompanyName;
  private _tour: Tour;

  constructor(
    provider: CompanyName,
    tour: Tour
  ) {
    this._provider = provider;
    this._tour = tour;

  }

  get provider(): CompanyName {
    return this._provider;
  }

  get tour(): Tour {
    return this._tour;
  }



}
