import { Pagination } from './pagination.view';

export class ChunkGeneric<T> {

    private _items: Array<T>;
    private _pagination: Pagination;

    constructor(items: Array<T>, pagination: Pagination) {
        this._items = items;
        this._pagination = pagination;
    }

    get items(): Array<T> {
        return this._items;
    }

    get pagination(): Pagination {
        return this._pagination;
    }

}
