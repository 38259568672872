import { Language } from '../master/language/language.model';
import { TourType } from '../master/tour/type/type.model';
import { City } from '../master/city/city.model';
import { Country } from '../master/country/country.model';
import { Fare } from './fare.model';
import { TourState } from './common/tour-state.constants';
import { ImageDetails } from '../image/image-details.model';

export class TourResume {

    private _id: number;
    private _name: string;
    private _nameMain: string;
    private _descriptionShort: string;
    private _descriptionShortMain: string;
    private _languageTourGiven: Language;
    private _type: TourType;
    private _country: Country;
    private _city: City;
    private _duration: number;
    private _isFree: boolean;
    private _fares: Array<Fare>;
    private _firstImage: ImageDetails;
    private _bid: number;
    private _bidMin: number;
    private _bidBlocked: boolean;
    private _customCommissionFare: number;
    private _defaultCommissionFare: number;
    private _guideSalesCommission: number;
    private _guideServiceFee: number;
    private _state: TourState;


    constructor(
        id: number,
        name: string,
        nameMain: string,
        descriptionShort: string,
        descriptionShortMain: string,
        languageTourGiven: Language,
        type: TourType,
        country: Country,
        city: City,
        duration: number,
        isFree: boolean,
        fares: Array<Fare>,
        image: ImageDetails,
        bid: number,
        bidMin: number,
        bidBlocked: boolean,
        customCommissionFare: number,
        defaultCommissionFare: number,
        guideSalesCommission: number,
        guideServiceFee: number,
        state: TourState
    ) {
        this._id = id;
        this._name = name;
        this._nameMain = nameMain;
        this._descriptionShort = descriptionShort;
        this._descriptionShortMain = descriptionShortMain;
        this._languageTourGiven = languageTourGiven;
        this._type = type;
        this._country = country;
        this._city = city;
        this._duration = duration;
        this._isFree = isFree;
        this._fares = fares;
        this._firstImage = image;
        this._bid = bid;
        this._bidMin = bidMin;
        this._bidBlocked = bidBlocked;
        this._customCommissionFare = customCommissionFare;
        this._defaultCommissionFare = defaultCommissionFare;
        this._guideSalesCommission = guideSalesCommission;
        this._guideServiceFee = guideServiceFee;
        this._state = state;

    }


    get id(): number {
      return this._id;
    }

    set id(id: number) {
      this._id = id;
    }

    get name(): string {
      return this._name;
    }

    set name(name: string) {
      this._name = name;
    }

    get nameMain(): string {
      return this._nameMain;
    }

    set nameMain(nameMain: string) {
      this._nameMain = nameMain;
    }

    get descriptionShort(): string {
      return this._descriptionShort;
    }

    set descriptionShort(descriptionShort: string) {
      this._descriptionShort = descriptionShort;
    }

    get descriptionShortMain(): string {
      return this._descriptionShortMain;
    }

    set descriptionShortMain(descriptionShortMain: string) {
      this._descriptionShortMain = descriptionShortMain;
    }

    get languageTourGiven(): Language {
      return this._languageTourGiven;
    }

    set languageTourGiven(languageTourGiven: Language) {
      this._languageTourGiven = languageTourGiven;
    }

    get type(): TourType {
      return this._type;
    }

    set type(type: TourType) {
      this._type = type;
    }

    get country(): Country {
      return this._country;
    }

    set country(country: Country) {
      this._country = country;
    }

    get city(): City {
      return this._city;
    }

    set city(city: City) {
      this._city = city;
    }

    get duration(): number {
      return this._duration;
    }

    set duration(duration: number) {
      this._duration = duration;
    }

    get isFree(): boolean {
      return this._isFree;
    }

    set isFree(isFree: boolean) {
      this._isFree = isFree;
    }

    get fares(): Array<Fare> {
      return this._fares;
    }

    set fares(fares: Array<Fare>) {
      this._fares = fares;
    }

    get firstImage(): ImageDetails {
      return this._firstImage;
    }

    set firstImage(firstImage: ImageDetails) {
      this._firstImage = firstImage;
    }

    get bid(): number {
      return this._bid;
    }

    set bid(bid: number) {
      this._bid = bid;
    }

    get bidMin(): number {
      return this._bidMin;
    }

    set bidMin(bidMin: number) {
      this._bidMin = bidMin;
    }

    get bidBlocked(): boolean {
      return this._bidBlocked;
    }

    set bidBlocked(bidBlocked: boolean) {
      this._bidBlocked = bidBlocked;
    }

    get customCommissionFare(): number {
      return this._customCommissionFare;
    }

    set customCommissionFare(customCommissionFare: number) {
      this._customCommissionFare = customCommissionFare;
    }

    get defaultCommissionFare(): number {
      return this._defaultCommissionFare;
    }

    set defaultCommissionFare(defaultCommissionFare: number) {
      this._defaultCommissionFare = defaultCommissionFare;
    }

    get guideSalesCommission(): number {
      return this._guideSalesCommission;
    }

    set guideSalesCommission(guideSalesCommission: number) {
      this._guideSalesCommission = guideSalesCommission;
    }

    get state(): TourState {
      return this._state;
    }

    set state(state: TourState) {
      this._state = state;
    }

    
    get guideServiceFee(): number {
      return this._guideServiceFee;
    }

    set guideServiceFee(guideServiceFee: number) {
      this._guideServiceFee = guideServiceFee;
    }

}