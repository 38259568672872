import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BaseService } from '../base.service';
import { Provider } from '../../models/provider/provider.model';
import { ProviderUtils } from '../../models/provider/common/provider.utils';
import { ImageDetails } from '../../models/image/image-details.model';
import { ImageUtils } from '../../models/image/common/image.utils';
import { CommonApiEndpointsUtils } from '../../api/endpoints/common-api-endponints.utils';
import { ProviderApiEndpointsUtils } from '../../api/endpoints/provider-api-endponints.utils';
import { Affiliate } from '../../models/affiliate/affiliate.model';
import { AffiliateApiEndpointsUtils } from '../../api/endpoints/affiliate-api-endponints.utils';
import { AffiliateUtils } from '../../models/affiliate/common/affiliate.utils';
import { AdminApiEndpointsUtils } from '../../api/endpoints/admin-api-endponints.utils';
import { PaymentPlatform } from '../../models/master/payment-platform/payment-platform.constants';
import { Guide } from '../../models/guide/guide.model';
import { GuideApiEndpointsUtils } from '../../api/endpoints/guide-api-endponints.utils';
import { GuideUtils } from '../../models/guide/common/guide.utils';


@Injectable({
  providedIn: 'root'
})
export class ProfileService extends BaseService {


  changePassword(oldPassword: string, newPassword: string, newPasswordConfirm: string): Observable<boolean> {
    return super.sendPutRequestWithRefresh(CommonApiEndpointsUtils.getProfileChangePasswordEndpoint(),
      { 'oldPassword': oldPassword, 'newPassword': newPassword, 'newPasswordConfirm': newPasswordConfirm },
      CommonApiEndpointsUtils.getProfileChangePasswordEndpointAcceptVersion()
    )
      .pipe(
        map(() => {
          return true
        })
      )
  }



  getProviderDetails(): Observable<Provider> {
    return super.sendGetRequestWithRefresh(ProviderApiEndpointsUtils.getProviderMeEndpoint(), ProviderApiEndpointsUtils.getProviderMeEndpointAcceptVersion())
      .pipe(
        map((response: any) => {
             return ProviderUtils.getProviderFromApi(response);

        })
      )
  }


  getAffiliateDetails(): Observable<Affiliate> {
    return super.sendGetRequestWithRefresh(AffiliateApiEndpointsUtils.getAffiliateMeEndpoint(), AffiliateApiEndpointsUtils.getAffiliateMeEndpointAcceptVersion())
      .pipe(
        map((response: any) => {
             return AffiliateUtils.getAffiliateFromApi(response);

        })
      )
  }


  getGuideDetails(localeI18N: string): Observable<Guide> {
    return super.sendGetRequestWithRefresh(GuideApiEndpointsUtils.getGuideMeEndpoint(), GuideApiEndpointsUtils.getGuideMeEndpointAcceptVersion())
      .pipe(
        map((response: any) => {
             return GuideUtils.getGuideFromApi(response, localeI18N);

        })
      )
  }


  updateProviderDetails(companyName: string, web: string, phone: string, country: string, city: string, contactPerson: string, contactEmail: string): Observable<boolean> {
    return super.sendPutRequestWithRefresh(ProviderApiEndpointsUtils.getProviderProfileUpdateDetailsEndpoint(),
      {
        'companyName': companyName,
        'web': web,
        'phone': phone,
        'countryId': country,
        'cityId': city,
        'contactPerson': contactPerson,
        'contactEmail': contactEmail
      },
      ProviderApiEndpointsUtils.getProviderProfileUpdateDetailsEndpointAcceptVersion()
    )
      .pipe(
        map(() => {
          return true;
        })
      )


  }
  updateAffiliateDetails(companyName: string, websites: Array<string>, phone: string, country: number, city: number, address: string, businessDocumentType: string, businessDocument: string, businessZeroVat: boolean): Observable<boolean> {
    return super.sendPutRequestWithRefresh(AffiliateApiEndpointsUtils.getAffiliateProfileUpdateDetailsEndpoint(), AffiliateApiEndpointsUtils.getAffiliateProfileUpdateDetailsObject(companyName, websites, phone, country, city, address, businessDocumentType, businessDocument, businessZeroVat), AffiliateApiEndpointsUtils.getAffiliateProfileUpdateDetailsEndpointAcceptVersion())
      .pipe(
        map(() => {
          return true;
        })
      )


  }


  updateGuideDetails(companyName: string, phone: string, country: number, city: number): Observable<boolean> {
    return super.sendPutRequestWithRefresh(GuideApiEndpointsUtils.getGuideProfileUpdateDetailsEndpoint(), GuideApiEndpointsUtils.getGuideProfileUpdateDetailsObject(companyName, phone, country, city), GuideApiEndpointsUtils.getGuideProfileUpdateDetailsEndpointAcceptVersion())
      .pipe(
        map(() => {
          return true;
        })
      )


  }




  updateProviderPayment(businessName: string, businessDocumentType: string, businessDocument: string, businessAddress: string, businessZeroVat: boolean): Observable<boolean> {
    return super.sendPutRequestWithRefresh(ProviderApiEndpointsUtils.getProviderProfileUpdatePaymentEndpoint(),
      ProviderApiEndpointsUtils.getProviderProfileUpdatePaymentObject(
        businessName, businessDocumentType, businessDocument, businessAddress, businessZeroVat
      ), ProviderApiEndpointsUtils.getProviderProfileUpdatePaymentEndpointAcceptVersion())
      .pipe(
        map(() => {
          return true;
        })
      )


  }


  updateProviderSettings(languageCode: string, bookingNotificationEmailActive: boolean, bookingNotificationEmail: string, bookingNotificationEmail2: string, paymentPlatform: PaymentPlatform, paymentCurrency: string, iban: string): Observable<boolean> {
    return super.sendPutRequestWithRefresh(ProviderApiEndpointsUtils.getProviderProfileUpdateSettingsEndpoint(),
          ProviderApiEndpointsUtils.getProviderProfileUpdateSettingsObject(languageCode, bookingNotificationEmailActive, bookingNotificationEmail, bookingNotificationEmail2, paymentPlatform, paymentCurrency, iban),
          ProviderApiEndpointsUtils.getProviderProfileUpdateSettingsEndpointAcceptVersion())
      .pipe(
        map(() => {
          return true
        })
      )
  }


  updateAffiliateSettings(languageCode: string, paymentCurrency: string, iban: string): Observable<boolean> {
    return super.sendPutRequestWithRefresh(AffiliateApiEndpointsUtils.getAffiliateProfileUpdateSettingsEndpoint(),
          AffiliateApiEndpointsUtils.getAffiliateProfileUpdateSettingsObject(languageCode, paymentCurrency, iban),
          AffiliateApiEndpointsUtils.getAffiliateProfileUpdateSettingsEndpointAcceptVersion())
      .pipe(
        map(() => {
          return true
        })
      )
  }


  updateGuideSettings(languageCode: string, paymentCurrency: string, iban: string): Observable<boolean> {
    return super.sendPutRequestWithRefresh(GuideApiEndpointsUtils.getGuideProfileUpdateSettingsEndpoint(),
          GuideApiEndpointsUtils.getGuideProfileUpdateSettingsObject(languageCode, paymentCurrency, iban),
          GuideApiEndpointsUtils.getGuideProfileUpdateSettingsEndpointAcceptVersion())
      .pipe(
        map(() => {
          return true
        })
      )
  }


  updateAdminSettings(languageCode: string): Observable<boolean> {
    return super.sendPutRequestWithRefresh(AdminApiEndpointsUtils.getAdminProfileUpdateSettingsEndpoint(),
          AdminApiEndpointsUtils.getAdminProfileUpdateSettingsObject(languageCode),
          AdminApiEndpointsUtils.getAdminProfileUpdateSettingsEndpointAcceptVersion())
      .pipe(
        map(() => {
          return true
        })
      )
  }


  updateAffiliateWebsites(websites: Array<string>): Observable<boolean> {
    return super.sendPutRequestWithRefresh(AffiliateApiEndpointsUtils.getAffiliateProfileUpdateWebsitesEndpoint(),
          AffiliateApiEndpointsUtils.getAffiliateProfileUpdateWebsitesObject(websites),
          AffiliateApiEndpointsUtils.getAffiliateProfileUpdateWebsitesEndpointAcceptVersion())
      .pipe(
        map(() => {
          return true
        })
      )
  }




  requestProviderUnregister(): Observable<boolean> {
    return super.sendPostRequestWithRefresh(ProviderApiEndpointsUtils.getProviderRequestUnregisterEndpoint(),
      {},
      ProviderApiEndpointsUtils.getProviderRequestUnregisterEndpointAcceptVersion())
      .pipe(
        map(() => {
          return true
        })
      )
  }



  requestAffiliateUnregister(): Observable<boolean> {
    return super.sendPostRequestWithRefresh(AffiliateApiEndpointsUtils.getAffiliateRequestUnregisterEndpoint(),
      {},
      AffiliateApiEndpointsUtils.getAffiliateRequestUnregisterEndpointAcceptVersion())
      .pipe(
        map(() => {
          return true
        })
      )
  }



  uploadLogo(image: File): Observable<ImageDetails> {
    return super.sendPostRequestWithRefresh(ProviderApiEndpointsUtils.getProviderUploadLogoEndpoint(), image, ProviderApiEndpointsUtils.getProviderUploadLogoEndpointAcceptVersion())
      .pipe(
        map((imageApi: any) => {
          return ImageUtils.getImageDetailsFromApi(imageApi);
        })
      )
  }

  deleteLogo(imageId: number) {
       return super.sendDeleteRequestWithRefresh(ProviderApiEndpointsUtils.getProviderDeleteLogoEndpoint(imageId), ProviderApiEndpointsUtils.getProviderDeleteLogoEndpointAcceptVersion())
        .pipe(
          map(() => {
            return true;
          })
        )
 }

 createProviderStripeAccount(): Observable<string> {
   return super.sendPostRequestWithRefresh(ProviderApiEndpointsUtils.getProviderProfileCreateStripeAccountEndpoint(), {}, ProviderApiEndpointsUtils.getProviderProfileCreateStripeAccountEndpointAcceptVersion())
     .pipe(
       map((response: any) => {
         return response.stripeAccountUrl;
       })
     )
 }

 createAffiliateStripeAccount(): Observable<string> {
   return super.sendPostRequestWithRefresh(AffiliateApiEndpointsUtils.getAffiliateProfileCreateStripeAccountEndpoint(), {}, AffiliateApiEndpointsUtils.getAffiliateProfileCreateStripeAccountEndpointAcceptVersion())
     .pipe(
       map((response: any) => {
         return response.stripeAccountUrl;
       })
     )
 }

 connectProviderStripeAccount(): Observable<string> {
   return super.sendGetRequestWithRefresh(ProviderApiEndpointsUtils.getProviderProfileConnectStripeAccountEndpoint(), ProviderApiEndpointsUtils.getProviderProfileConnectStripeAccountEndpointAcceptVersion())
     .pipe(
       map((response: any) => {
         return response.stripeAccountUrl;
       })
     )
 }


 connectAffiliateStripeAccount(): Observable<string> {
   return super.sendGetRequestWithRefresh(AffiliateApiEndpointsUtils.getAffiliateProfileConnectStripeAccountEndpoint(), AffiliateApiEndpointsUtils.getAffiliateProfileConnectStripeAccountEndpointAcceptVersion())
     .pipe(
       map((response: any) => {
         return response.stripeAccountUrl;
       })
     )
 }




 removeProviderStripeAccount(): Observable<boolean> {
   return super.sendDeleteRequestWithRefresh(ProviderApiEndpointsUtils.getProviderProfileRemoveStripeAccountEndpoint(), ProviderApiEndpointsUtils.getProviderProfileRemoveStripeAccountEndpointAcceptVersion())
     .pipe(
       map(() => {
         return true;
       })
     )
 }

 removeAffiliateStripeAccount(): Observable<boolean> {
   return super.sendDeleteRequestWithRefresh(AffiliateApiEndpointsUtils.getAffiliateProfileRemoveStripeAccountEndpoint(), AffiliateApiEndpointsUtils.getAffiliateProfileRemoveStripeAccountEndpointAcceptVersion())
     .pipe(
       map(() => {
         return true;
       })
     )
 }


 updateProviderStripeAccount(stripeAccountId: string): Observable<boolean> {
   return super.sendPutRequestWithRefresh(ProviderApiEndpointsUtils.getProviderProfileUpdateStripeAccountEndpoint(), ProviderApiEndpointsUtils.getProviderProfileUpdateStripeAccountObject(stripeAccountId), ProviderApiEndpointsUtils.getProviderProfileUpdateStripeAccountEndpointAcceptVersion())
     .pipe(
       map(() => {
         return true;
       })
     )
 }


 updateAffiliateStripeAccount(stripeAccountId: string): Observable<boolean> {
   return super.sendPutRequestWithRefresh(AffiliateApiEndpointsUtils.getAffiliateProfileUpdateStripeAccountEndpoint(), AffiliateApiEndpointsUtils.getAffiliateProfileUpdateStripeAccountObject(stripeAccountId), ProviderApiEndpointsUtils.getProviderProfileUpdateStripeAccountEndpointAcceptVersion())
     .pipe(
       map(() => {
         return true;
       })
     )
 }


}
