import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MessagePanelComponent } from '../../message-panel/message-panel.component';
import { ProfileInteractor } from 'src/app/domain/interactor/profile/profile.interactor';
import { FormUtils } from '../../../utils/form.utils';
import { AppConstants } from 'src/app/domain/common/app.constants';
import { marker as I18N } from '@biesbjerg/ngx-translate-extract-marker';
import { ServerErrorManager } from '../../../utils/server-error-manager.utils';
import { GenericException } from 'src/app/domain/exceptions/generic.exception';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html'
})
export class ChangePasswordComponent implements OnInit {

  @ViewChild(MessagePanelComponent)
  private messagePanelComponent: MessagePanelComponent;

  changePasswordForm: FormGroup
  isDisabled = true
  refreshValidators: boolean

  constructor(
    private profileInteractor: ProfileInteractor,
    private serverErrorManager: ServerErrorManager ) {

      this.refreshValidators = false;

      this.changePasswordForm = new FormGroup({
          'oldPassword': new FormControl({ value: '123456', disabled: true }, [Validators.required, Validators.maxLength(AppConstants.TEXT_DEFAULT_MAX_LENGTH)] ),
          'newPassword': new FormControl({ value: '123456', disabled: true }, [Validators.required, Validators.maxLength(AppConstants.TEXT_DEFAULT_MAX_LENGTH)] ),
          'retryPassword': new FormControl({ value: '123456', disabled: true }, [Validators.required, this.noEqualValidator, Validators.maxLength(AppConstants.TEXT_DEFAULT_MAX_LENGTH)] )
      })
  }



  ngOnInit() {
  }


  toggleEdition() {

        this.isDisabled = !this.isDisabled

        if ( this.isDisabled ) {
            FormUtils.disableAllControls(this.changePasswordForm)
            FormUtils.setAllControlsToValue(this.changePasswordForm, '123456')
        } else {
            FormUtils.enableAllControls(this.changePasswordForm)
            FormUtils.emptyAllControls(this.changePasswordForm)
        }
  }


  savePasswords() {

      this.refreshValidators = true;

      if ( this.changePasswordForm.valid ) {

        this.messagePanelComponent.showLoading();

        this.profileInteractor.changePassword(this.changePasswordForm.value.oldPassword, this.changePasswordForm.value.newPassword, this.changePasswordForm.value.retryPassword)
            .subscribe(
                () => {
                    MessagePanelComponent.hideLoading();
                    this.messagePanelComponent.showSuccessMessage(I18N('changepasswordprofile.Contraseña actualizada'));

                    this.toggleEdition()

                },
                (error: GenericException) => {

                  if ( error.code === AppConstants.ERROR_CLIENT_BAD_REQUEST ) {
                      this.messagePanelComponent.showErrorMessage(I18N('changepasswordprofile.La contraseña actual no es correcta'))
                  } else {
                      this.serverErrorManager.manageErrorCode(error, this.messagePanelComponent);
                  }
                }
            )
      }

  }


  noEqualValidator( control: FormControl ): { [s:string]:boolean } {
    if ( control.value != control.parent.controls['newPassword'].value ) {
        return {
            noequals: true
        }
    }
    return null;
  }

}
