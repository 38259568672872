import { AffiliateStats } from './affiliate-stats.model';

export class AffiliateDayStats {

     private _date: string;
     private _stats: AffiliateStats;

     constructor(
          date: string,
          stats: AffiliateStats

     ) {

          this._date = date;
          this._stats = stats;

     }

     get date(): string {
          return this._date;
     }

     get stats(): AffiliateStats {
          return this._stats;
     }

}
