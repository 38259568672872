<form class="form-boxes m-0 p-0" action="" [formGroup]="settingsForm">
    <!-- Acceso -->
    <div class="row mb-2">
        <div class="col-10">
            <h4 class="font-weight-bold d-flex"><img class="ic-mid mr-2" src="assets/img/ic_settings.svg">{{ 'settingsprofile.Configuración' | translate }}</h4>
            <h5>{{ 'settingsprofile.Ajustes de la plataforma' | translate }}</h5>
            <hr class="w-10 float-left mt-2 mb-4">
        </div>
        <div class="col-2 text-right">
            <a class="cursor-pointer" (click)="toggleEdition()">
                <img class="ic-mid mr-2" src="{{ isDisabled ? 'assets/img/ic_edit.svg' : 'assets/img/ic_edit-disabled.svg'}}">
            </a>
        </div>
    </div>

    <div class="row mb-3">

          <div class="col-12 col-md-6">
              <div class="field pb-2">
                  <select formControlName="language" name="language">
                      <option *ngFor="let language of languages" value="{{ language.code }}">{{ language.name }}</option>
                  </select>
                <label for="language">{{ 'settingsprofile.Idioma' | translate }}</label>
            </div>
            <app-feedback [formGroup]="settingsForm" field="language" validator="required" feedback="{{ 'settingsprofile.Idioma requerido' | translate }}" [refresh]="refreshValidators"></app-feedback>
        </div>
    </div>

    <div class="row mb-5 pb-5">
        <div class="col-12 col-md-6 offset-md-6 col-lg-4 offset-lg-8">
            <input (click)="saveSettings()" type="submit" value="{{ 'settingsprofile.Guardar datos' | translate }}" class="btn btn-full-width btn-primary mb-3 mb-sm-0" [disabled]="isDisabled" />
        </div>
    </div>
</form>



<app-message-panel></app-message-panel>