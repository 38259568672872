import { Component, ViewChild, Input } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { MessagePanelComponent } from 'src/app/ui/components/shared/message-panel/message-panel.component';
import { ServerErrorManager } from 'src/app/ui/components/utils/server-error-manager.utils';

import { GenericException } from 'src/app/domain/exceptions/generic.exception';
import { CancelationReason } from 'src/app/business/models/master/booking/cancelation-reason/cancelaltion-reason.constants';
import { UserMasterInteractor } from 'src/app/domain/interactor/user/user-master.interactor';
import { BookCacnelationDetailsView } from 'src/app/domain/views/book/book-cancelation-details.view';


@Component({
  selector: 'app-cancel-booking-modal-component',
  templateUrl: './cancel-booking-modal.component.html'
})

export class CancelBookingModalComponent {

  @ViewChild(MessagePanelComponent, { static: false })
  private messagePanelComponent: MessagePanelComponent;

  cancelBookingForm: FormGroup;
  refreshValidators: boolean
  cancelationReasons: Array<CancelationReason>;


  @Input() bookId: number;


  constructor(
    private serverErrorManager: ServerErrorManager,
    private userMasterInteractor: UserMasterInteractor,
    private modal: NgbActiveModal) {

    this.refreshValidators = false;

    this.cancelBookingForm = new FormGroup({
      'reason': new FormControl('', [Validators.required]),
      'message': new FormControl('', [Validators.required])
    });

    this.userMasterInteractor.getBookingCancelationReasons()
     .subscribe(
          ( cancelationReasons: Array<CancelationReason> ) => {
               this.cancelationReasons = cancelationReasons;
          },
          (error: GenericException) => {

            this.serverErrorManager.manageErrorCode(error, this.messagePanelComponent);

          }
     )

  }

  ngOnInit() {
  }


  onCoseModal() {
    this.modal.close();
  }


  onCancelBooking() {

    this.refreshValidators = true;

    if (this.cancelBookingForm.valid) {

      this.messagePanelComponent.showLoading();

      const cancelationReason: CancelationReason = this.cancelBookingForm.get('reason').value;
      const message: string = this.cancelBookingForm.get('message').value;

      this.modal.close(new BookCacnelationDetailsView(this.bookId, message, cancelationReason));

    }

  }

}




