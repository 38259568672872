<div class="card table-responsive" *ngFor="let tourResume of tours | paginate: pagination;">
     <div class="row mb-4">
          <div class="col-10 title">
               <h5 class="font-weight-bold">
                    {{ tourResume.name | I18NTranslationSave: tourResume.nameMain }}
               </h5>
               <div class="font-weight-normal">
                    {{ tourResume.city.name }}, {{ tourResume.country.name }}
               </div>
               <div *ngIf="tourResume.isFree" class="font-weight-normal">
                    <b>{{ 'affiliatetourlist.Free tour' | translate }}</b>
               </div>
               <span *ngIf="tourResume.state != tourStateActive" class="tag-primary inactive-tour">
                    {{ 'listbooking.Tour inactivo' | translate }}
               </span>
          </div>
          <div class="col-2 text-right">
               <label class="btn-more-vert dropdown">
                    <div class="dd-button">
                         <img class="ic-mid" src="assets/img/ic_more-vert.svg">
                    </div>

                    <input type="checkbox" class="dd-input" id="test">

                    <ul class="dd-menu">
                         <li>
                              <a *ngIf="tourResume.affiliateLinks.length > 0" (click)="onViewTourLink(tourResume.affiliateLinks)">
                                   {{ 'affiliatetourlist.Ver enlace' | translate }}
                              </a>
                              <span *ngIf="tourResume.affiliateLinks.length == 0">{{ 'affiliatetourlist.Enlace no disponible' | translate }}</span>
                         </li>
                         <li class="divider"></li>
                         <li *ngIf="tourResume.state == tourStateActive">
                              <a (click)="onCreateBookingLink(tourResume.id)">
                                   {{ 'affiliatetourlist.Crear reserva' | translate }}
                              </a>
                         </li>
                    </ul>

               </label>
          </div>
     </div>



     <div class="row mb-2">
          <div class="col-12 title">
               {{ 'affiliatetourlist.Comisión' | translate }}
          </div>
     </div>
     <div class="row mb-1">
          <div class="col-12 name-contact-provider">
               {{ tourResume.commission }} €
          </div>
     </div>

</div>