import { Component, OnInit, ViewChild } from '@angular/core';

import { MessagePanelComponent }        from 'src/app/ui/components/shared/message-panel/message-panel.component';
import { ServerErrorManager }           from 'src/app/ui/components/utils/server-error-manager.utils';

import { ChunkGeneric }                 from 'src/app/domain/views/chunk-generic.view';

import { GenericException }             from 'src/app/domain/exceptions/generic.exception';
import { BookingBilling }               from 'src/app/business/models/billing/booking-billing.model';

import { FindBillingFilterView } from 'src/app/domain/views/billing/find-invoices-filter.view';
import { GuideBillingInteractor } from 'src/app/domain/interactor/guide/billing.interactor';
import { GuideBillingStats } from 'src/app/business/models/billing/billing-stats.model';


@Component({
     selector: 'app-list-billing-guide',
     templateUrl: './list.component.html'
})
export class GuideListBillingComponent implements OnInit {

     @ViewChild(MessagePanelComponent)
     private messagePanelComponent: MessagePanelComponent;

     chunkBookingBilling: ChunkGeneric<BookingBilling>;
     guideBillingStats: GuideBillingStats;

     constructor(
          private guideBillingInteractor: GuideBillingInteractor,
          private serverErrorManager: ServerErrorManager) {
     }

     ngOnInit() {

     }


     onFindBillingTrigger(findBillingFilterView: FindBillingFilterView) {

          if (this.messagePanelComponent) {
            this.messagePanelComponent.showLoading();
          }

          this.guideBillingInteractor.findBilling(findBillingFilterView.dateInit, findBillingFilterView.dateEnd, findBillingFilterView.keywords, findBillingFilterView.pageNumber)
          .subscribe(
               (chunkBookingBilling: ChunkGeneric<BookingBilling>) => {

                    this.chunkBookingBilling = chunkBookingBilling;
                    MessagePanelComponent.hideLoading();

               },
               (error: GenericException) => {

                    MessagePanelComponent.hideLoading();
                    this.serverErrorManager.manageErrorCode(error, this.messagePanelComponent);
               }
          )


          this.guideBillingInteractor.findBillingStats(findBillingFilterView.dateInit, findBillingFilterView.dateEnd, findBillingFilterView.keywords)
          .subscribe(
               (guideBillingStats: GuideBillingStats) => {

                    this.guideBillingStats = guideBillingStats;
                    MessagePanelComponent.hideLoading();

               },
               (error: GenericException) => {

                    MessagePanelComponent.hideLoading();
                    this.serverErrorManager.manageErrorCode(error, this.messagePanelComponent);
               }
          )
     }

}
