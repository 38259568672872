import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate, Router } from '@angular/router';
import { NavigationConstants } from '../commons/navigation.constants';
import { SessionInteractor } from 'src/app/domain/interactor/session.interactor';
import { ProviderGuidesManagement } from 'src/app/business/models/provider/common/provider-guides-management.constants';

@Injectable({
  providedIn: 'root'
})
export class ProviderGuidesAuthorizedGuard implements CanActivate {

  constructor( private sessionInteractor: SessionInteractor, private router: Router) {}

  canActivate(
    _next: ActivatedRouteSnapshot,
    _state: RouterStateSnapshot): boolean {

      if ( this.sessionInteractor.loggedIn()
               && this.sessionInteractor.isProviderRole()
               && this.sessionInteractor.loggedInUserDetails().guidesManagement == ProviderGuidesManagement.Full
     ) {
          return true;
      } else if ( !this.sessionInteractor.loggedIn() ) {
          this.router.navigateByUrl( NavigationConstants.redirectUrl( NavigationConstants.getNavigationLogin(_state.url) ) );
          return false;
      } else {
          this.router.navigateByUrl(NavigationConstants.redirectUrl(NavigationConstants.NAVIGATION_FORBIDDEN));
          return false;
      }
    }

}
