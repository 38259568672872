import { TourSchedulePlanningDayFormView } from './schedule-planning-day-form.view';

export class TourSchedulePlanningFormView {


    private _days: Array<TourSchedulePlanningDayFormView>;
    private _dayExceptions: Array<TourSchedulePlanningDayFormView>;

    constructor(
      days: Array<TourSchedulePlanningDayFormView>,
      dayExceptions: Array<TourSchedulePlanningDayFormView>
    ) {

        this._days = days;
        this._dayExceptions = dayExceptions;

    }


    get days(): Array<TourSchedulePlanningDayFormView> {
        return this._days;
    }

    get dayExceptions(): Array<TourSchedulePlanningDayFormView> {
        return this._dayExceptions;
    }

}