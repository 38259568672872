import { Pipe, PipeTransform } from '@angular/core';
import { CreateTourStep } from 'src/app/domain/common/tour/tour-create-state.constants';

@Pipe({
  name: 'createTourStepClass'
})
export class ProviderCreateTourStepClassPipe implements PipeTransform {

  transform(classLabels: string, step: CreateTourStep, currentStep: CreateTourStep): String {

    let result = classLabels
    if ( step == currentStep ) {
        result = result + " " + 'in-progress';
    } else if ( step < currentStep ) {
        result = result + " " + 'complete';
    }

    return result ;

  }

}
