<div id="publicLanding">

    <div class="row bg-grey-color header-simple">
        <div class="col-7 col-md-10">
            <a (click)="onHeaderItemSelected()"><img class="logo pr-4 pr-lg-5" src="assets/img/logo-white.png"></a>
            <!-- <app-public-navigation></app-public-navigation> -->
        </div>
    </div>


    <!-- Content -->
    <div class="landing-page bg-section-gray review-thanks h-100">
        <div class="row section-review-thanks">
            <div class="col-12 col-lg-8 offset-lg-2 text-left text-lg-center  white-color dark-lg-color px-0">
            </div>
        </div>
        <div class="animated fadeIn row row-overlay">
            <div class="col-12 col-lg-8 offset-lg-2 card card-primary">
                <div class="row mb-2">
                    <div class="col-12 text-center">
                        <h1 class="font-weight-bold big">{{ 'reviewThanks.GRACIAS' | translate }}</h1>
                        <p class="text-body-big mt-4 mb-0">{{ 'reviewThanks.Tu opinión ayudará a otros usuarios a encontrar el mejor tour. Gracias por confiar en nosotros.' | translate }}</p>
                    </div>
                </div>
                <!-- RRSS -->
                <!--
                <div class="row mt-5 pt-5">
                    <div class="col-12 text-center">
                        <h5>{{ 'reviewThanks.Síguenos' | translate }}</h5>
                        <div class="row">
                            <div class="col-12 mt-3">
                                <a class="mr-3"><img class="img-fluid" src="assets/img/ic_social-twitter-simple-black.svg"></a>
                                <a class="mr-3"><img class="img-fluid" src="assets/img/ic_social-facebook-simple-black.svg"></a>
                                <a><img class="img-fluid" src="assets/img/ic_social-linkedin-simple-black.svg"></a>
                            </div>
                        </div>
                    </div>
                </div>
                -->
            </div>
        </div>

    </div>
    <!-- end content -->

    <!--<app-message-panel></app-message-panel> -->

</div>