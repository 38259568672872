import { Component, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'app-window-scroll-controller',
  templateUrl: './window-scroll-controller.component.html'
})
export class WindowScrollControllerComponent {

  @Output() pageYOffset = new EventEmitter<number>(true);

  constructor() {
  }

  onScroll(event: any) {
       let currentPageYOffset = event.target.pageYOffset;
       if ( !currentPageYOffset ) {
            currentPageYOffset = event.currentTarget.pageYOffset;
       }
      this.updateEmiters(currentPageYOffset);
  }


  private updateEmiters(currentPageYOffset: number) {
      this.pageYOffset.emit(currentPageYOffset);
  }

}
