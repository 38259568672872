import { Pipe, PipeTransform } from '@angular/core';
import { marker as I18N } from '@biesbjerg/ngx-translate-extract-marker';
import { CancelationReason } from 'src/app/business/models/master/booking/cancelation-reason/cancelaltion-reason.constants';

@Pipe({
  name: 'cancelationReasonLabelPipe'
})
export class CancelationReasonLabelPipe implements PipeTransform {

  transform(label: string, cancelationReason: CancelationReason): string {

    switch (cancelationReason) {
      case CancelationReason.CustomerRequest:
        return I18N('cancelBooking.Solicitud de cliente');
      case CancelationReason.Duplicated:
        return I18N('cancelBooking.Reserva duplicada');
      case CancelationReason.Fraudulent:
        return I18N('cancelBooking.Reserva fraudulenta');
      case CancelationReason.GuideProblem:
        return I18N('cancelBooking.Problema con el guía');
      default:
        return label;
    }

  }

}
