<div *ngIf="eventDetails" class="animated fadeIn">
    <div class="row dashboard-content-section-first dashboard-content-section mb-5">
        <div class="col-12 col-md-6 pl-0 order-1 order-lg-1">
            <h2 class="font-weight-bold">{{ 'listbooking.Listado de reservas' | translate }}</h2>
        </div>

        <div class="col-4 col-md-6 text-left text-lg-right px-0 px-lg-auto mt-3 mt-md-0 order-2 d-md-none">
            <a (click)="onGoBack()" style="line-height: 45px;">
                 <img src="assets/img/ic_arrow-back.svg">
                 {{ 'listbooking.Volver' | translate }}
            </a>
        </div>

        <div class="col col-md-6 text-left text-lg-right px-0 px-lg-auto mt-3 mt-md-0 order-3 order-lg-2">
            <input [(ngModel)]="keywords" (change)="onKeywordsUpdated()" class="searcher-primary w-100" type="search" placeholder="{{ 'detailbooking.Buscar reserva...' | translate }}">
        </div>
    </div>

    <div class="row dashboard-content-section">
         <app-list-booking-event class="w-100"
              [bookEvent]="bookEvent"
              [simplifiedVersion]="true"
              [adminRole]="false" [guideRole]="true"
              (newBooking)="onNewBookingForEvent()"
        ></app-list-booking-event>
   </div>

    <div class="row dashboard-content-section">

        <div class="col-12 px-0">

            <app-details-booking-table *ngIf="desktopVersion"
               [eventDetails]="eventDetails"
               [isGuideRole]="true"
               (updateShows)="onUpdateShows($event)"
               (newBooking)="onNewBooking($event)"
               (updateEventBookAllFareShows)="onUpdateEventBookAllFareShows($event)"
               (decreaseShows)="onDecreaseShows($event)"
               (increaseShows)="onIncreaseShows($event)"
               ></app-details-booking-table>

            <app-details-booking-card *ngIf="!desktopVersion"
               [eventDetails]="eventDetails"
               [isGuideRole]="true"
               (updateShows)="onUpdateShows($event)"
               (newBooking)="onNewBooking($event)"
               (updateEventBookAllFareShows)="onUpdateEventBookAllFareShows($event)"
               (decreaseShows)="onDecreaseShows($event)"
               (increaseShows)="onIncreaseShows($event)"
               ></app-details-booking-card>
        </div>

    </div>

</div>


<div (click)="onNewBookingForNewTour()" class="floating-button pointer bg-primary-color">
     <img src="assets/img/ic_calendar_white.svg">
</div>

<app-message-panel [preloading]="true"></app-message-panel>


<app-window-size-controller [initialSize]="initialWindowSize" (isDesktopVersion)="onDesktopVersionUpdated($event)"></app-window-size-controller>