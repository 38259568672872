<form class="form-boxes m-0 p-0" action="" [formGroup]="customLinkForm">
     <div class="modal-header pb-0">
          <button type="button" class="close" aria-label="Close" (click)="closeModal()">
               <span aria-hidden="true">&times;</span>
          </button>
     </div>
     <div class="modal-body mb-3">
          <h5 class="modal-title">{{ 'affiliatecustomlink.Genera un link personalizado' | translate}}</h5>
          <div class="row mt-3">
               <div class="col-12 col-md-12">
                    <div class="field pb-2 d-grid">
                         <textarea formControlName="link" type="text" name="link" id="link" rows="2" cols=""></textarea>
                         <label for="link">{{ 'affiliatecustomlink.URL del enlace' | translate }}</label>
                    </div>
                    <app-feedback [formGroup]="customLinkForm" field="link" validator="required" feedback="{{ 'affiliatecustomlink.Introduce una url válida' | translate }}" [refresh]="refreshValidators"></app-feedback>
                    <app-feedback [formGroup]="customLinkForm" field="link" validator="max" feedback="{{ 'affiliatecustomlink.Texto demasiado largo' | translate }}" [refresh]="refreshValidators"></app-feedback>
                    <app-feedback [formGroup]="customLinkForm" field="link" validator="pattern" feedback="{{ 'affiliatecustomlink.Introduce una url válida' | translate }}" [refresh]="refreshValidators"></app-feedback>
               </div>

               <div class="col-12 col-md-12 mb-4 text-right">
                    <input (click)="generateCustomLink()" type="submit" value="{{ 'affiliatecustomlink.Generar enlace' | translate }}" class="btn bg-primary-color mb-3 mb-sm-0 mr-4 modal-button" />
               </div>

               <div class="col-12 col-md-12">
                    <div class="field pb-2 d-grid">
                         <textarea (click)="onSelectedGenerateCustomLink($event)" formControlName="linkGenerated" type="text" name="linkGenerated" id="linkGenerated" rows="2" cols=""></textarea>
                         <label>{{ 'affiliatecustomlink.Enlace generado' | translate }}</label>
                    </div>
               </div>
          </div>
     </div>

</form>



<app-message-panel></app-message-panel>