import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BaseService } from '../base.service';
import { TourFormView } from 'src/app/domain/views/provider/tour/tour-form.view';
import { Tour } from '../../models/tour/tour.model';
import { Language } from '../../models/master/language/language.model';
import { TourUtils } from '../../models/tour/common/tour.utils';
import { TourResume } from '../../models/tour/tour-resume.model';
import { ChunkGeneric } from 'src/app/domain/views/chunk-generic.view';
import { Pagination } from 'src/app/domain/views/pagination.view';
import { ImageUtils } from '../../models/image/common/image.utils';
import { ImageDetails } from '../../models/image/image-details.model';
import { ProviderApiEndpointsUtils } from '../../api/endpoints/provider-api-endponints.utils';
import { MastersApiEndpointsUtils } from '../../api/endpoints/masters-api-endponints.utils';
import { EventBasic } from '../../models/book/event-basic.model';

@Injectable({
  providedIn: 'root'
})
export class ProviderTourService extends BaseService {


  getTourGuivenLanguages(): Observable<Array<Language>> {
    return super.sendGetRequestWithRefresh(MastersApiEndpointsUtils.getUserMasterTourGuivenLanguagesEndpoint(), MastersApiEndpointsUtils.getUserMasterTourGuivenLanguagesEndpointAcceptVersion())
      .pipe(
        map((response: any) => {

          const languages: Array<Language> = response.map((language: any) => {
            return new Language(
              language.id,
              language.code,
              language.name
            )
          })

          return languages
        })
      )
  }



  createTour(tourForm: TourFormView): Observable<number> {

    return super.sendPostRequestWithRefresh(ProviderApiEndpointsUtils.getProviderCreateTourEndpoint(), ProviderApiEndpointsUtils.getProviderCreateTourObject(tourForm), ProviderApiEndpointsUtils.getProviderCreateTourEndpointAcceptVersion())
      .pipe(
        map((response: any) => {

          return response.id;

        })
      )
  }


  updateTour(tourForm: TourFormView): Observable<number> {

    return super.sendPutRequestWithRefresh(ProviderApiEndpointsUtils.getProviderUpdateTourEndpoint(tourForm.id), ProviderApiEndpointsUtils.getProviderCreateTourObject(tourForm), ProviderApiEndpointsUtils.getProviderUpdateTourEndpointAcceptVersion())
      .pipe(
        map((response: any) => {

          return response.id;

        })
      )
  }

  updateTourI18N(tourForm: TourFormView): Observable<number> {

    return super.sendPutRequestWithRefresh(ProviderApiEndpointsUtils.getProviderUpdateTourEndpoint(tourForm.id), ProviderApiEndpointsUtils.getProviderCreateTourI18NObject(tourForm), ProviderApiEndpointsUtils.getProviderUpdateTourEndpointAcceptVersion())
      .pipe(
        map((response: any) => {

          return response.id;

        })
      )
  }


  getTour(tourId: number, tourI18NDisplayId: number): Observable<Tour> {

    return super.sendGetRequestWithRefresh(ProviderApiEndpointsUtils.getProviderTourDetailsEndpoint(tourId), ProviderApiEndpointsUtils.getProviderTourDetailsEndpointAcceptVersion())
      .pipe(
        map((tourApi: any) => {

          return TourUtils.getTourFromApi(tourApi, tourI18NDisplayId);

        })
      )
  }




  findTours(localeI18N: string, search: string, countryId: number, cityId: number, page: number): Observable<ChunkGeneric<TourResume>> {

    return super.sendGetRequestWithRefresh(ProviderApiEndpointsUtils.getProviderFindToursEndpoint(search, countryId, cityId, page), ProviderApiEndpointsUtils.getProviderFindToursEndpointAcceptVersion())
      .pipe(
        map((response: any) => {

          const toursResume = new Array<TourResume>();

          response.items.map((tourBasicApi: any) => {

            const tourResume = TourUtils.getTourResumeFromApi(tourBasicApi, localeI18N);
            toursResume.push(tourResume);
          })

          return new ChunkGeneric<TourResume>(toursResume, new Pagination(response.total, response.currentPage, response.chunkSize));

        })
      )
  }




  findAllTours(localeI18N: string): Observable<Array<TourResume>> {

    return super.sendGetRequestWithRefresh(ProviderApiEndpointsUtils.getProviderFindToursEndpoint(null, null, null, null), ProviderApiEndpointsUtils.getProviderFindToursEndpointAcceptVersion())
      .pipe(
        map((response: any) => {

          const toursResume = new Array<TourResume>();

          response.items.map((tourBasicApi: any) => {

            const tourResume = TourUtils.getTourResumeFromApi(tourBasicApi, localeI18N);
            toursResume.push(tourResume);
          })

          return toursResume;

        })
      )
  }



  activateTour(tourId: number): Observable<boolean> {

    return super.sendPostRequestWithRefresh(ProviderApiEndpointsUtils.getProviderActivateToursEndpoint(tourId), {}, ProviderApiEndpointsUtils.getProviderActivateToursEndpointAcceptVersion())
      .pipe(
        map(() => {

          return true;

        })
      )
  }


  deactivateTour(tourId: number): Observable<boolean> {

    return super.sendPostRequestWithRefresh(ProviderApiEndpointsUtils.getProviderDeactivateToursEndpoint(tourId), {}, ProviderApiEndpointsUtils.getProviderDeactivateToursEndpointAcceptVersion())
      .pipe(
        map(() => {

          return true;

        })
      )
  }


  uploadImageTour(tourId: number, image: File): Observable<ImageDetails> {

    return super.sendPostRequestWithRefresh(ProviderApiEndpointsUtils.getProviderUploadImageTourEndpoint(tourId), image, ProviderApiEndpointsUtils.getProviderUploadImageTourEndpointAcceptVersion())
      .pipe(
        map((imageApi: any) => {
          return ImageUtils.getImageDetailsFromApi(imageApi);
        })
      )
  }


  getTourImages(tourId: number): Observable<Array<ImageDetails>> {

    return super.sendGetRequestWithRefresh(ProviderApiEndpointsUtils.getProviderTourImagesEndpoint(tourId), ProviderApiEndpointsUtils.getProviderTourImagesEndpointAcceptVersion())
      .pipe(
        map((response: any) => {

          const tourImages = new Array<ImageDetails>();
          response.map((imageApi: any) => {
            tourImages.push(ImageUtils.getImageDetailsFromApi(imageApi));
          });
          return tourImages;

        })
      )
  }


  deleteTourImage(tourId: number, imageId: number): Observable<boolean> {

    return super.sendDeleteRequestWithRefresh(ProviderApiEndpointsUtils.getProviderTourDeleteImageEndpoint(tourId, imageId), ProviderApiEndpointsUtils.getProviderTourDeleteImageEndpointAcceptVersion())
      .pipe(
        map(() => {
          return true;

        })
      )
  }


  updateTourBid(tourId: number, bid: number): Observable<boolean> {

    return super.sendPutRequestWithRefresh(ProviderApiEndpointsUtils.getProviderTourUpdateBidEndpoint(tourId), ProviderApiEndpointsUtils.getProviderTourUpdateBidEndpointObject(bid), ProviderApiEndpointsUtils.getProviderTourUpdateBidEndpointAcceptVersion())
      .pipe(
        map(() => {

          return true;

        })
      )
  }


  updateTourGuideSalesCommission(tourId: number, commission: number): Observable<boolean> {

    return super.sendPutRequestWithRefresh(
      ProviderApiEndpointsUtils.getProviderTourUpdateGuideSalesCommissionEndpoint(tourId), 
      ProviderApiEndpointsUtils.getProviderTourUpdateGuideSalesCommissionObject(commission), 
      ProviderApiEndpointsUtils.getProviderTourUpdateGuideSalesCommissionAcceptVersion())
      .pipe(
        map(() => {

          return true;

        })
      )
  }


  updateTourGuideServiceFee(tourId: number, fee: number): Observable<boolean> {

    return super.sendPutRequestWithRefresh(
      ProviderApiEndpointsUtils.getProviderTourUpdateGuideServiceFeeEndpoint(tourId), 
      ProviderApiEndpointsUtils.getProviderTourUpdateGuideServiceFeeObject(fee), 
      ProviderApiEndpointsUtils.getProviderTourUpdateGuideServiceFeeAcceptVersion())
      .pipe(
        map(() => {

          return true;

        })
      )
  }



  findEvents(tourId: number, day: string): Observable<Array<EventBasic>> {

    return super.sendGetRequestWithRefresh(
      ProviderApiEndpointsUtils.getProviderToursEventsEndpoint(tourId, day),
      ProviderApiEndpointsUtils.getProviderToursEventsAcceptVersion()
    )
      .pipe(
        map((eventsApi: any) => {

          const events = new Array<EventBasic>();

          eventsApi.map((eventApi: any) => {

            events.push(TourUtils.getEventBasicFromApi(eventApi));

          })

          return events;
          
        })
      )
  }




}
