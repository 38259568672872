import { Injectable }                       from '@angular/core';
import { SessionManager } from '../managers/session-manager';
import { Observable, Observer } from 'rxjs';
import { AuthenticateService } from 'src/app/business/services/authenticate.service';
import { User } from 'src/app/business/models/user/user.model';
import { GenericException } from '../exceptions/generic.exception';


@Injectable({
  providedIn: 'root'
})
export class AuthenticateInteractor {

    constructor(private authService: AuthenticateService, private sessionManager: SessionManager) {
    }

    login(email: string, password: string): Observable<User> {

        return Observable.create((observer: Observer<User>) => {
            this.authService.loginUser(email, password)
                  .subscribe(
                      (user: User) => {

                          this.sessionManager.saveCredentials(user.email, user.roles, user.state, user.token, user.refreshToken);

                          observer.next(user);
                          observer.complete();
                      },
                      (error: GenericException) => {
                          observer.error(error);
                      }
                  )

        });
    }



    rememberPassword(email: string): Observable<boolean> {

        return Observable.create((observer: Observer<boolean>) => {
            this.authService.rememberPassword(email)
                  .subscribe(
                      (success: boolean) => {
                          observer.next(success);
                          observer.complete();
                      },
                      (error: GenericException) => {
                          observer.error(error);
                      }
                  )

        });
    }



    changePasswordRemembered(username: string, verificationCode: string, newPassword: string, newPasswordConfirm: string): Observable<boolean> {

        return Observable.create((observer: Observer<boolean>) => {
            this.authService.changePasswordRemembered(username, verificationCode, newPassword, newPasswordConfirm)
                  .subscribe(
                      (success: boolean) => {
                          observer.next(success);
                          observer.complete();
                      },
                      (error: GenericException) => {
                          observer.error(error);
                      }
                  )

        });
    }



    singupProvider(
          email: string,
          verificationCode: string,
          newPassword: string,
          newPasswordConfirm: string,
          languageId: number,
          web: string,
          phone: string,
          country: number,
          city: number,
          contactPerson: string,
          contactEmail: string,
          bookingNotificationEmailActive: boolean,
          bookingNotificationEmail: string,
          bookingNotificationEmail2: string,
          businessName: string,
          businessDocumentType: string,
          businessDocument: string,
          businessAddress: string,
          businessZeroVat: boolean
    ) {
      return Observable.create((observer: Observer<boolean>) => {
          this.authService.singupProvider(
                      email, verificationCode, newPassword, newPasswordConfirm,
                      languageId, web, phone,
                      country, city, contactPerson, contactEmail, bookingNotificationEmailActive, bookingNotificationEmail,
                      bookingNotificationEmail2, businessName, businessDocumentType, businessDocument, businessAddress, businessZeroVat)
                .subscribe(
                    (success: boolean) => {
                        observer.next(success);
                        observer.complete();
                    },
                    (error: GenericException) => {
                        observer.error(error);
                    }
                )

      });
    }


    singupAffiliate(
          email: string,
          newPassword: string,
          newPasswordConfirm: string,
          languageId: number,
          websites: Array<string>,
          phone: string,
          country: number,
          city: number,
          companyName: string,
          businessDocumentType: string,
          businessDocument: string,
          businessAddress: string,
          businessZeroVat: boolean
    ) {
      return Observable.create((observer: Observer<boolean>) => {
          this.authService.singupAffiliate(email, newPassword, newPasswordConfirm, languageId, websites, phone,
               country, city, companyName, businessDocumentType,
               businessDocument, businessAddress, businessZeroVat)
                .subscribe(
                    (success: boolean) => {
                        observer.next(success);
                        observer.complete();
                    },
                    (error: GenericException) => {
                        observer.error(error);
                    }
                )

      });
    }


}
