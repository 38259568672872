import { Injectable }                       from '@angular/core';

@Injectable({
  providedIn: 'root'
})

// A class responsible for managing an user session in the Local Storage
// It would be injectable (like a service) in other components
export class LocaleManager {

    public static defaultLocale = 'en';
    public static availableLocales = ['es', LocaleManager.defaultLocale];

    public static readonly LOCALE_LANGUAGE_CODE = 'LOCALE_LANGUAGE_CODE';


    public static setCurrentLocale(localeCode: string): boolean {

        if ( localeCode != LocaleManager.getCurrentLocale() && LocaleManager.availableLocales.includes(localeCode) ) {

            localStorage.setItem(LocaleManager.LOCALE_LANGUAGE_CODE, localeCode);
            return true;

        }

        return false;
    }


    public static getCurrentLocale(): string {

        let currentLocale = localStorage.getItem(LocaleManager.LOCALE_LANGUAGE_CODE);

        if (currentLocale == null) {

            for (const localeIndex in LocaleManager.availableLocales) {

                const localeCode = LocaleManager.availableLocales[localeIndex]

                if (navigator.language === localeCode) {
                    currentLocale = localeCode;
                    break;
                } else if (navigator.language.startsWith(localeCode)) {
                    // For example, browser has `es-CL` and the implemented locale is `es`.
                    currentLocale = localeCode;
                    break;
                } else if (localeCode.startsWith(navigator.language)) {
                    // For example, browser has `es` and the implemented locale is `es-CL`.
                    currentLocale = localeCode;
                    break;
                }
            }
            if (currentLocale == null) {
                currentLocale = LocaleManager.defaultLocale;
            }

        }
        return currentLocale;
    }


    public static resetlocale() {
        localStorage.removeItem(LocaleManager.LOCALE_LANGUAGE_CODE);
    }


}