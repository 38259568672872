<div id="publicLanding">
    <app-header-landing (menuItemSelected)="onHeaderItemSelected($event)"></app-header-landing>

    <div class="row dashboard-content-section error-page mb-5 mb-lg-auto">
        <div class="col-12">
            <div class="row mt-5 pt-5">
                <div class="col-12 col-lg-6 col-xl-6 order-lg-1 order-2 text-center text-lg-left">
                    <div class="row mb-5 pb-5 mt-4 mt-lg-auto">
                        <div class="col-12">
                            <h1>{{ 'pageError.Oops!' | translate }}</h1>
                            <p>{{ 'pageError.No tienes permiso para acceder a este contenido.' | translate }}</p>
                        </div>
                    </div>
                    <div class="row mt-5 pt-5 pb-5">
                        <div class="col-6 col-lg-6 col-xl-4">
                            <button (click)="goToDashboard()" class="btn btn-full-width btn-no-btn mb-3 mb-sm-0 dark-color" type="submit" value="notFound.Volver">
                                   <img class="ic-small mr-2" src="assets/img/ic_arrow-back.svg">
                                   {{ 'pageError.Volver' | translate }}
                              </button>
                        </div>
                        <div class="col-6 col-lg-6 col-xl-4">
                            <button (click)="goToHomeLanding()" class="btn btn-full-width btn-primary mb-3 mb-sm-0 white-color" type="submit" value="notFound.Home">
                                   {{ 'pageError.Home' | translate }}
                              </button>
                        </div>
                    </div>

                </div>
                <div class="col-12 col-lg-6 col-xl-6 my-auto text-center order-lg-2 order-1">
                    <img class="error-image" src="assets/img/error-image.png">
                </div>
            </div>
        </div>
    </div>
</div>