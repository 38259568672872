import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { BookEvent } from 'src/app/business/models/book/book-event.model';
import { ImageSize } from 'src/app/business/models/image/common/image-size.constants';
import { TourState } from 'src/app/business/models/tour/common/tour-state.constants';
import { AppConstants } from 'src/app/domain/common/app.constants';

@Component({
     selector: 'app-list-booking-event',
     templateUrl: './event.component.html'
})
export class ListBookingEventComponent implements OnInit {

     @Input() bookEvent: BookEvent;
     @Input() adminRole: boolean;
     @Input() guideRole: boolean;

     @Input() simplifiedVersion: boolean;


     @Output() viewBookDetails = new EventEmitter<BookEvent>();
     @Output() newBooking = new EventEmitter<BookEvent>();
     @Output() allowBookings = new EventEmitter<BookEvent>();
     @Output() disallowBookings = new EventEmitter<BookEvent>();
     @Output() showUpdateMaxBookings = new EventEmitter<BookEvent>();


     percentageCapacityAlert: number;
     imageMediumSize: ImageSize = ImageSize.Medium;
     tourInactiveState: TourState = TourState.Inactive;


     constructor() {
          this.percentageCapacityAlert = AppConstants.BOOKING_PERCENTAGE_CAPACITY_ALERT;
          this.simplifiedVersion = false;
     }

     ngOnInit() {

     }


     onViewBookDetails(bookEvent: BookEvent) {
          this.viewBookDetails.emit(bookEvent);
     }

     onNewBooking(bookEvent: BookEvent) {
          this.newBooking.emit(bookEvent);
     }

     onAllowBookings(bookEvent: BookEvent) {
          this.allowBookings.emit(bookEvent);
     }

     onDisallowBookings(bookEvent: BookEvent) {
          this.disallowBookings.emit(bookEvent);
     }

     onShowUpdateMaxBookings(bookEvent: BookEvent) {
          this.showUpdateMaxBookings.emit(bookEvent);
     }




}