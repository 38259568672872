import { ApiConstants } from '../api.constants';
import { ApiUtils } from '../api.utils';

export class AffiliateApiEndpointsUtils {



     public static getAffiliateMeEndpoint(): string {
          return `affiliate/affiliates`;
     }

     public static getAffiliateMeEndpointAcceptVersion(): string {
          return ApiConstants.API_HEADER_ACCEPT_V_1_0_1;
     }


     public static getAffiliateProfileUpdateSettingsEndpoint(): string {
          return 'affiliate/settings';
     }

     public static getAffiliateProfileUpdateSettingsObject( languageCode: string, paymentCurrency: string, iban: string ): any {

          return {
               'languageCode': languageCode,
               'paymentCurrency': paymentCurrency,
               'iban': iban
          }
     }

     public static getAffiliateProfileUpdateSettingsEndpointAcceptVersion(): string {
          return ApiConstants.API_HEADER_ACCEPT_V_1_0_1;
     }






     public static getAffiliateProfileUpdateDetailsEndpoint(): string {
          return 'affiliate/affiliates/companydetails';
     }

     public static getAffiliateProfileUpdateDetailsEndpointAcceptVersion(): string {
          return ApiConstants.API_HEADER_ACCEPT_V_1_0_1;
     }

     public static getAffiliateProfileUpdateDetailsObject(
          companyName: string,
          websites: Array<string>,
          phone: string,
          country: number,
          city: number,
          address: string,
          businessDocumentType: string,
          businessDocument: string,
          businessZeroVat: boolean
     ): any {

          const webs = new Array<any>();
          websites.forEach( (website: string) => {
               webs.push({ 'web': website});
          });

          return {
            'companyName': companyName,
            'webs': websites,
            'phone': phone,
            'countryId': country,
            'cityId': city,
            'address': address,
            'businessDocumentType': businessDocumentType,
            'businessDocument': businessDocument,
            'businessZeroVat': businessZeroVat
          }
     }


     public static getAffiliateProfileUpdateWebsitesEndpoint(): string {
          return 'affiliate/affiliates/websdetails';
     }

     public static getAffiliateProfileUpdateWebsitesObject( websites: Array<string> ): any {

          const webs = new Array<any>();
          websites.forEach( (website: string) => {
               webs.push({ 'web': website});
          });

          return {
            'webs': websites,
          }

     }

     public static getAffiliateProfileUpdateWebsitesEndpointAcceptVersion(): string {
          return ApiConstants.API_HEADER_ACCEPT_V_1_0_1;
     }





     public static getAffiliateFindToursEndpoint(search: string, countryId: number, cityId: number, page: number): string {
          return ApiUtils.prepareQueryRequest('affiliate/tours/', { 'search': search, 'countryId': countryId, 'cityId': cityId, 'page': page });
     }

     public static getAffiliateFindToursEndpointAcceptVersion(): string {
          return ApiConstants.API_HEADER_ACCEPT_V_1_0_1;
     }



     public static getAffiliateCustomLinkEndpoint(): string {
          return ApiUtils.prepareQueryRequest('affiliate/link/', {});
     }

     public static getAffiliateCustomLinkObject(link: string): any {
          return {
               "web": link
          }
     }

     public static getAffiliateCustomLinkEndpointAcceptVersion(): string {
          return ApiConstants.API_HEADER_ACCEPT_V_1_0_1;
     }

     public static getAffiliateFindBillingEndpoint(dateInit: string, dateEnd: string, keywords: string, page: number): string {
          return ApiUtils.prepareQueryRequest('affiliate/billing', { 'minDay': dateInit, 'maxDay': dateEnd, 'search': keywords, 'page': page });
     }

     public static getAffiliateFindBillingEndpointAcceptVersion(): string {
          return ApiConstants.API_HEADER_ACCEPT_V_1_0_1;
     }

     public static getAffiliateFindLatestBillingEndpoint(): string {
          return 'affiliate/billing/latest';
     }

     public static getAffiliateFindLatestBillingEndpointAcceptVersion(): string {
          return ApiConstants.API_HEADER_ACCEPT_V_1_0_1;
     }

     public static getAffiliateFindInvoicesEndpoint(page: number): string {
          return ApiUtils.prepareQueryRequest('affiliate/billing/invoices', { 'page': page });
     }

     public static getAffiliateFindInvoicesEndpointAcceptVersion(): string {
          return ApiConstants.API_HEADER_ACCEPT_V_1_0_1;
     }


     public static getAffiliateFindNextBillingEndpoint(): string {
          return 'affiliate/billing/next';
     }

     public static getAffiliateFindNextBillingEndpointAcceptVersion(): string {
          return ApiConstants.API_HEADER_ACCEPT_V_1_0_1;
     }

     public static getAffiliateFindInvoiceEndpoint(invoiceId: number): string {
          return `affiliate/billing/invoices/${invoiceId}`;
     }

     public static getAffiliateFindInvoiceEndpointAcceptVersion(): string {
          return ApiConstants.API_HEADER_ACCEPT_V_1_0_1;
     }



     public static getAffiliateInvoiceDownloadEndpoint(invoiceId: number): string {
          return `affiliate/billing/invoices/${invoiceId}`;
     }

     public static getAffiliateInvoiceDownloadEndpointAcceptVersion(): string {
          return ApiConstants.API_HEADER_ACCEPT_V_1_0_1_PDF;
     }



     public static getAffiliateRequestUnregisterEndpoint(): string {
          return `affiliate/affiliates/requestunregister`;
     }

     public static getAffiliateRequestUnregisterEndpointAcceptVersion(): string {
          return ApiConstants.API_HEADER_ACCEPT_V_1_0_1;
     }



     public static getAffiliateProfileCreateStripeAccountEndpoint(): string {
          return 'affiliate/affiliates/stripe/account';
     }

     public static getAffiliateProfileCreateStripeAccountEndpointAcceptVersion(): string {
          return ApiConstants.API_HEADER_ACCEPT_V_1_0_1;
     }

     public static getAffiliateProfileConnectStripeAccountEndpoint(): string {
          return 'affiliate/affiliates/stripe/link';
     }

     public static getAffiliateProfileConnectStripeAccountEndpointAcceptVersion(): string {
          return ApiConstants.API_HEADER_ACCEPT_V_1_0_1;
     }

     public static getAffiliateProfileRemoveStripeAccountEndpoint(): string {
          return 'affiliate/affiliates/stripe/account';
     }

     public static getAffiliateProfileRemoveStripeAccountEndpointAcceptVersion(): string {
          return ApiConstants.API_HEADER_ACCEPT_V_1_0_1;
     }

     public static getAffiliateProfileUpdateStripeAccountEndpoint(): string {
          return 'affiliate/affiliates/stripe/account';
     }

     public static getAffiliateProfileUpdateStripeAccountObject(stripeAccountId: string): any {
          return {
               'stripeAccountId': stripeAccountId
          }
     }

     public static getAffiliateProfileUpdateStripeAccountEndpointAcceptVersion(): string {
          return ApiConstants.API_HEADER_ACCEPT_V_1_0_1;
     }


     public static getAffiliateProfileStatsEndpoint(): string {
          return 'affiliate/billing/stats';
     }

     public static getAffiliateProfileStatsEndpointAcceptVersion(): string {
          return ApiConstants.API_HEADER_ACCEPT_V_1_0_1;
     }


     public static getAffiliateProfileDailyStatsEndpoint(year: number, month: number): string {
          let monthApi = month + '-' + year;
          return ApiUtils.prepareQueryRequest('affiliate/billing/stats/daily', { 'month': monthApi});
     }

     public static getAffiliateProfileDailyStatsEndpointAcceptVersion(): string {
          return ApiConstants.API_HEADER_ACCEPT_V_1_0_1;
     }


}


