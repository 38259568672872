import { AffiliateState } from './common/affiliate-state.constants'

export class AffiliateResume {

    private _id: number;
    private _username: string;
    private _email: string;
    private _companyName: string;
    private _country: string;
    private _city: string;
    private _contactPerson: string;
    private _phone: string;
    private _state: AffiliateState;

    constructor(
      id: number,
      username: string,
      email: string,
      companyName: string,
      country: string,
      city: string,
      contactPerson: string,
      phone: string,
      state: AffiliateState

    ) {

        this._id = id;
        this._username = username;
        this._email = email;
        this._companyName = companyName;
        this._country = country;
        this._city = city;
        this._contactPerson = contactPerson;
        this._phone = phone;
        this._state = state;

    }


    get id(): number {
        return this._id;
    }

    get username(): string {
        return this._username;
    }

    get email(): string {
        return this._email;
    }

    get companyName(): string {
        return this._companyName;
    }

    get country(): string {
        return this._country;
    }

    get city(): string {
        return this._city;
    }

    get contactPerson(): string {
        return this._contactPerson;
    }

    get phone(): string {
        return this._phone;
    }

    get state(): AffiliateState {
        return this._state;
    }

}
