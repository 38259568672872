<div class="card table-responsive" *ngFor="let guide of guides | paginate: pagination;" (click)="onGuideClick(guide.id)">
    <div class="row">
        <div class="col-12 mb-1">
            <p class="name-provider mb-2">{{ guide.companyName }}</p>
        </div>

        <div [ngClass]="'col-12 mb-3 text-left status-provider status' | provideGuideStateClass: guide.state">
            <div class="dot"></div> {{ '' | providerGuideStateName: guide.state | translate }}
        </div>

    </div>
    <div class="row">
        <div class="col-12 text-left">
            <p class="email-provider mb-2">{{ guide.username }}</p>
            <p class="email-provider mb-2">{{ guide.phone }}</p>
            <p class="email-provider mb-2">{{ guide.country }}, {{ guide.city }}</p>
        </div>
    </div>
</div>