export class GenericException extends Error {

  private _code: number;
  private _cause: string;

  constructor(code: number, errorMessage: string, cause: string = null) {
    super(errorMessage);

    this._code = code;
    this._cause = cause;
  }

  get code(): number {
    return this._code;
  }

  get cause(): string {
    return this._cause;
  }


}