import { Component, ViewChild } from '@angular/core';
import { MessagePanelComponent } from '../../../shared/message-panel/message-panel.component';
import { Provider } from 'src/app/business/models/provider/provider.model';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticateInteractor } from 'src/app/domain/interactor/authenticate.interactor';
import { AppConstants } from 'src/app/domain/common/app.constants';
import { NavigationConstants } from 'src/app/ui/commons/navigation.constants';
import { SessionInteractor } from 'src/app/domain/interactor/session.interactor';
import { MasterInteractor } from 'src/app/domain/interactor/public/master.interactor';
import { NavigatorUtils } from 'src/app/ui/utils/navigator.utils';
import { Language } from 'src/app/business/models/master/language/language.model';
import { Country } from 'src/app/business/models/master/country/country.model';
import { City } from 'src/app/business/models/master/city/city.model';
import { marker as I18N } from '@biesbjerg/ngx-translate-extract-marker';
import { ServerErrorManager } from '../../../utils/server-error-manager.utils';
import { GenericException } from 'src/app/domain/exceptions/generic.exception';


@Component({
     selector: 'app-singup',
     templateUrl: './singup.component.html'
})
export class SingupComponent {

     @ViewChild(MessagePanelComponent)
     private messagePanelComponent: MessagePanelComponent;


     currentLanguageId: number;

     provider: Provider;

     languages: Array<Language>;
     countries: Array<Country>;
     cities: Array<City>;

     providerFullForm: FormGroup;

     refreshValidators: boolean;

     constructor(
          private authenticateInteractor: AuthenticateInteractor,
          private masterInteractor: MasterInteractor,
          private sessionInteractor: SessionInteractor,
          private router: Router,
          private activatedRoute: ActivatedRoute,
          private serverErrorManager: ServerErrorManager) {

               this.refreshValidators = false;

               this.providerFullForm = new FormGroup({


                    'hash': new FormControl('', [Validators.required]),
                    'username': new FormControl('', [Validators.required, Validators.email]),

                    'password': new FormControl('', [Validators.required, Validators.maxLength(AppConstants.TEXT_DEFAULT_MAX_LENGTH)]),
                    'retryPassword': new FormControl('', [Validators.required, this.noEqualValidator, Validators.maxLength(AppConstants.TEXT_DEFAULT_MAX_LENGTH)]),

                    'language': new FormControl('', [Validators.required]),

                    'web': new FormControl('', [Validators.required, Validators.maxLength(AppConstants.TEXT_DEFAULT_MAX_LENGTH), Validators.pattern(AppConstants.REGULAR_EXPRESSION_VALIDATE_WEBSITE)]),
                    'phone': new FormControl('', [Validators.required, Validators.maxLength(AppConstants.TEXT_DEFAULT_MAX_LENGTH)]),
                    'country': new FormControl(null, [Validators.required]),
                    'city': new FormControl(null, [Validators.required]),
                    'contactPerson': new FormControl('', [Validators.required, Validators.maxLength(AppConstants.TEXT_DEFAULT_MAX_LENGTH)]),
                    'contactEmail': new FormControl('', [Validators.required, Validators.email, Validators.maxLength(AppConstants.TEXT_DEFAULT_MAX_LENGTH)]),
                    'bookingNotificationEmailActive': new FormControl(true, []),
                    'bookingNotificationEmail': new FormControl('', [Validators.required, Validators.email, Validators.maxLength(AppConstants.TEXT_DEFAULT_MAX_LENGTH)]),
                    'bookingNotificationEmail2': new FormControl('', [Validators.email, Validators.maxLength(AppConstants.TEXT_DEFAULT_MAX_LENGTH)]),

                    'businessName': new FormControl('', [Validators.required, Validators.maxLength(AppConstants.TEXT_DEFAULT_MAX_LENGTH)]),
                    'businessDocumentType': new FormControl('', [Validators.required]),
                    'businessDocument': new FormControl('', [Validators.required, Validators.maxLength(AppConstants.TEXT_DEFAULT_MAX_LENGTH)]),
                    'businessAddress': new FormControl('', [Validators.required, Validators.maxLength(256)]),
                    'privacyPolicy': new FormControl(false, [this.privacyPolicyValidator]),
                    'VATExempt': new FormControl(false, []),

               })


               this.activatedRoute.queryParams.subscribe(params => {

                    this.providerFullForm.controls['hash'].setValue(params['hash'])
                    this.providerFullForm.controls['username'].setValue(params['username'])

                    this.currentLanguageId = params['languageId']
                    this.providerFullForm.controls['language'].setValue(this.currentLanguageId)

                    const languageCode = params['languageCode']
                    if (this.sessionInteractor.setCurrentLanguage(languageCode)) {
                         NavigatorUtils.reloadApplication()
                    }

               });
          }


          ngOnInit() {

               this.masterInteractor.getLanguagesLocalized()
               .subscribe(
                    (languages: Array<Language>) => {
                         this.languages = languages
                    },
                    (error: GenericException) => {
                         this.serverErrorManager.manageErrorCode(error, this.messagePanelComponent);
                    }
               )


               this.masterInteractor.getCountriesLocalized(this.currentLanguageId)
               .subscribe(
                    (countries: Array<Country>) => {
                         this.countries = countries
                    },
                    (error: GenericException) => {
                         this.serverErrorManager.manageErrorCode(error, this.messagePanelComponent);
                    }
               )
          }




          onCountrySelected() {

               const selectedCountryId = this.providerFullForm.value.country
               this.cities = new Array<City>()
               this.providerFullForm.get('city').setValue(null);

               if (selectedCountryId != null) {

                    this.masterInteractor.getCitiesLocalized(selectedCountryId, this.currentLanguageId)
                    .subscribe(
                         (cities: Array<City>) => {
                              this.cities = cities
                         },
                         (error: GenericException) => {
                              this.serverErrorManager.manageErrorCode(error, this.messagePanelComponent);
                         }
                    )

               }

          }



          registerProvider() {

               this.refreshValidators = true;

               if (this.providerFullForm.valid) {

                    this.messagePanelComponent.showLoading();

                    this.authenticateInteractor.singupProvider(
                         this.providerFullForm.value.username,
                         this.providerFullForm.value.hash,
                         this.providerFullForm.value.password,
                         this.providerFullForm.value.retryPassword,
                         this.providerFullForm.value.language,
                         this.providerFullForm.value.web,
                         this.providerFullForm.value.phone,
                         this.providerFullForm.value.country,
                         this.providerFullForm.value.city,
                         this.providerFullForm.value.contactPerson,
                         this.providerFullForm.value.contactEmail,
                         this.providerFullForm.value.bookingNotificationEmailActive,
                         this.providerFullForm.value.bookingNotificationEmail,
                         this.providerFullForm.value.bookingNotificationEmail2,
                         this.providerFullForm.value.businessName,
                         this.providerFullForm.value.businessDocumentType,
                         this.providerFullForm.value.businessDocument,
                         this.providerFullForm.value.businessAddress,
                         this.providerFullForm.value.VATExempt)
                         .subscribe(
                              () => {
                                   MessagePanelComponent.hideLoading();
                                   this.messagePanelComponent.showSuccessMessage(I18N('singup.Información enviada. Recibirás un email de confirmación cuando se revisen los datos'), () => this.goToHomeLanding());

                              },
                              (error: GenericException) => {

                                   MessagePanelComponent.hideLoading();

                                   if (error.code === AppConstants.ERROR_CLIENT_NOT_FOUND || error.code === AppConstants.ERROR_CLIENT_UNAUTHORIZED) {
                                        this.messagePanelComponent.showErrorMessage(I18N('singup.Datos de proveedor no encontrados'))
                                   } else if (error.code === AppConstants.ERROR_CLIENT_BAD_REQUEST) {
                                        this.messagePanelComponent.showErrorMessage(I18N('singup.Los datos ya se habían enviado. La cuenta está pendiente de aprobación'))
                                   } else {
                                        this.serverErrorManager.manageErrorCode(error, this.messagePanelComponent);
                                   }
                              }
                         )

                    }
               }


               noEqualValidator(control: FormControl): { [s: string]: boolean } {
                    if (control.parent && control.value != control.parent.controls['password'].value) {
                         return {
                              noequals: true
                         }
                    }
                    return null;
               }

               privacyPolicyValidator(control: FormControl): { [s: string]: boolean } {
                    if (control.parent && !control.value) {
                         return {
                              required: true
                         }
                    }
                    return null;
               }

               onProvacyPolicyCheck() {
                    if ( this.providerFullForm.get('privacyPolicy').value) {
                         this.providerFullForm.get('privacyPolicy').setValue(false);
                    } else {
                         this.providerFullForm.get('privacyPolicy').setValue(true);
                    }
               }

               onVATExemptCheck() {
                    if ( this.providerFullForm.get('VATExempt').value) {
                         this.providerFullForm.get('VATExempt').setValue(false);
                    } else {
                         this.providerFullForm.get('VATExempt').setValue(true);
                    }
               }


               goToLogin() {
                    this.router.navigate([NavigationConstants.NAVIGATION_LOGIN]);
               }

               goToHomeLanding() {
                    this.router.navigate([NavigationConstants.getNavigationHome()]);
               }

               goToContact() {
                    this.router.navigate([NavigationConstants.getNavigationHome()], { fragment: NavigationConstants.NAVIGATION_HOME_ANCHOR_CONTACT });
               }

               goToTerms() {
                    this.router.navigate([NavigationConstants.getNavigationTerms()]);
               }


          }
