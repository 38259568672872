import { TourResume } from '../tour/tour-resume.model';

export class Review {

  private _id: number;
  private _name: string;
  private _rating: number;
  private _comments: string;
  private _orderBookingId: number;
  private _tourResume: TourResume;


  constructor(id: number, name: string, rating: number, comments: string, orderBookingId: number, tourResume: TourResume) {
    this._id = id;
    this._name = name;
    this._rating = rating;
    this._comments = comments;
    this._orderBookingId = orderBookingId;
    this._tourResume = tourResume;

  }

  get id(): number {
    return this._id;
  }

  set id(id: number) {
    this._id = id;
  }

  get name(): string {
    return this._name;
  }

  set name(name: string) {
    this._name = name;
  }

  get rating(): number {
    return this._rating;
  }

  set rating(rating: number) {
    this._rating = rating;
  }

  get comments(): string {
    return this._comments;
  }

  set comments(comments: string) {
    this._comments = comments;
  }

  get orderBookingId(): number {
    return this._orderBookingId;
  }

  set orderBookingId(orderBookingId: number) {
    this._orderBookingId = orderBookingId;
  }

  get tourResume(): TourResume {
    return this._tourResume;
  }

  set tourResume(tourResume: TourResume) {
    this._tourResume = tourResume;
  }
}
