import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { NavigationConstants } from '../commons/navigation.constants';
import { SessionInteractor } from 'src/app/domain/interactor/session.interactor';

@Injectable({
  providedIn: 'root'
})
export class AlreadyauthorizedGuard implements CanActivate {

  constructor( private sessionInteractor: SessionInteractor, private router: Router) {}

  canActivate(
    _next: ActivatedRouteSnapshot,
    _state: RouterStateSnapshot): boolean {
      if ( this.sessionInteractor.loggedIn() ) {
          this.router.navigate([ NavigationConstants.getNavigationUserZone() ]);
          return false;
      } else {
          return true;
      }
    }


}
