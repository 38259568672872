import { TourAttractionFormView } from './attraction-form.view';

export class TourDetailsFormView {


    private _name: string;
    private _nameMain: string;
    private _languageTourGivenId: number;
    private _typeId: number;
    private _duration: number;
    private _countryId: number;
    private _cityId: number;
    private _description: string;
    private _descriptionMain: string;
    private _descriptionShort: string;
    private _descriptionShortMain: string;
    private _additionalInformation: string;
    private _additionalInformationMain: string;
    private _attractions: Array<TourAttractionFormView>;


    constructor(
      name: string,
      nameMain: string,
      languageTourGivenId: number,
      typeId: number,
      duration: number,
      countryId: number,
      cityId: number,
      description: string,
      descriptionMain: string,
      descriptionShort: string,
      descriptionShortMain: string,
      additionalInformation: string,
      additionalInformationMain: string,
      attractions: Array<TourAttractionFormView>

    ) {

        this._name = name;
        this._nameMain = nameMain;
        this._languageTourGivenId = languageTourGivenId;
        this._typeId = typeId;
        this._duration = duration;
        this._countryId = countryId;
        this._cityId = cityId;
        this._description = description;
        this._descriptionMain = descriptionMain;
        this._descriptionShort = descriptionShort;
        this._descriptionShortMain = descriptionShortMain;
        this._additionalInformation = additionalInformation;
        this._additionalInformationMain = additionalInformationMain;
        this._attractions = attractions;

    }


    get name(): string {
        return this._name;
    }

    get nameMain(): string {
        return this._nameMain;
    }

    get languageTourGivenId(): number {
        return this._languageTourGivenId;
    }

    get typeId(): number {
        return this._typeId;
    }

    get duration(): number {
        return this._duration;
    }

    get cityId(): number {
        return this._cityId;
    }

    get countryId(): number {
        return this._countryId;
    }

    get description(): string {
        return this._description;
    }

    get descriptionMain(): string {
        return this._descriptionMain;
    }

    get descriptionShort(): string {
        return this._descriptionShort;
    }

    get descriptionShortMain(): string {
        return this._descriptionShortMain;
    }

    get additionalInformation(): string {
        return this._additionalInformation;
    }

    get additionalInformationMain(): string {
        return this._additionalInformationMain;
    }

    get attractions(): Array<TourAttractionFormView> {
        return this._attractions;
    }


}