<div class="card table-responsive" *ngFor="let provider of providers | paginate: pagination;" (click)="onProviderClick(provider.id)">
    <div class="row">
        <div class="col-9 mb-1">
            <p class="name-provider mb-2">{{ provider.companyName }}</p>
        </div>

        <td [ngClass]="'col-3 text-right status-provider status' | adminProviderStateClass: provider.registrationState : provider.state">
            <div class="dot"></div> {{ '' | adminProviderStateName: provider.registrationState : provider.state | translate }}
        </td>

    </div>
    <div class="row">
        <div class="col-12 text-left">
            <p class="email-provider mb-2">{{ provider.username }}</p>
            <p class="location-provider mb-5">{{ provider.city }}, <span class="text-uppercase">{{ provider.country }}</span></p>
        </div>
    </div>
    <div class="row mb-2">
        <div class="col-6 title">
            {{ 'providerslist.NOMBRE CONTACTO' | translate }}
        </div>
        <div class="col-6 title">
            {{ 'providerslist.EMAIL' | translate }}
        </div>
    </div>
    <div class="row">
        <div class="col-6 name-contact-provider">
            {{ provider.contactPerson }}
        </div>
        <div class="col-6 email-contact-provider">
            {{ provider.email }}
        </div>
    </div>
</div>