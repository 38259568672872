import { Component, OnInit, Injectable, NgModule, ViewChild, Input } from '@angular/core';
import { Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { SafeResourceUrl } from '@angular/platform-browser';
import { YtPlayerService, PlayerOptions } from 'yt-player-angular';

@Component({
  selector: 'app-provider-tutorial',
  templateUrl: './tutorial.component.html'
})

export class ProviderTutorialComponent implements OnInit {

  url: SafeResourceUrl;
  url_link = "https://www.youtube.com/embed/c7xVNr4k7J4?start=";

  url1 = "0"
  url2 = "54"
  url3 = "76"
  url4 = "142"
  url5 = "158"
  url6 = "181"
  url7 = "227"
  url8 = "309"
  url9 = "365"
  url10 = "425"
  url11 = "470"
  url12 = "584"

  constructor( private router: Router, private sanitizer: DomSanitizer, private ytPlayerService: YtPlayerService) {
   this.url = sanitizer.bypassSecurityTrustResourceUrl(this.url_link);
  }

  ngOnInit() {
  }


  onSeek(seconds) {
    this.ytPlayerService.seek(seconds);
  }


}
