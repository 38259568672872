import { Component, ViewChild, Input } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TourResumeAdmin } from 'src/app/business/models/tour/tour-resume-admin.model';
import { AdminTourInteractor } from 'src/app/domain/interactor/admin/tour.interactor';
import { MessagePanelComponent } from 'src/app/ui/components/shared/message-panel/message-panel.component';
import { ServerErrorManager } from 'src/app/ui/components/utils/server-error-manager.utils';

import { marker as I18N }               from '@biesbjerg/ngx-translate-extract-marker';
import { AppConstants } from 'src/app/domain/common/app.constants';
import { GenericException } from 'src/app/domain/exceptions/generic.exception';


@Component({
  selector: 'app-admin-edit-tour-bid-modal-component',
  templateUrl: './edit-bid-modal.component.html'
})

export class AdminEditTourBidModalComponent {

  @ViewChild(MessagePanelComponent, { static: false })
  private messagePanelComponent: MessagePanelComponent;

  bidForm: FormGroup;
  minBidLabelValue: any= { value: null};

  refreshValidators: boolean


  @Input()
  set tourResume(tourResume: TourResumeAdmin) {
    if (tourResume) {
      this.setBidFormValues(tourResume);
    }
  }



  constructor(
    private adminTourInteractor: AdminTourInteractor,
    private serverErrorManager: ServerErrorManager,
    private modal: NgbActiveModal) {

    this.refreshValidators = false;

    this.bidForm = new FormGroup({
      'tourId': new FormControl(''),
      'bid': new FormControl('', [Validators.min(0), Validators.max(AppConstants.NUMBER_MAX_VALE)]),
      'bidBlocked': new FormControl(false, []),
    })

  }

  ngOnInit() {
  }


  closeModal() {
    this.modal.close();
  }


  saveBid() {
    this.refreshValidators = true;

    if (this.bidForm.valid) {

      this.messagePanelComponent.showLoading();

      const tourId: number = this.bidForm.get('tourId').value;
      const bid: number = this.bidForm.get('bid').value;
      const bidBlocked: boolean = this.bidForm.get('bidBlocked').value;

      this.adminTourInteractor.updateTourBid(tourId, bid, bidBlocked)
        .subscribe(
          (tourResumeUpdated: TourResumeAdmin) => {

            MessagePanelComponent.hideLoading();
            this.messagePanelComponent.showSuccessMessage(I18N('editbid.Información actualizada'));
            this.modal.close(tourResumeUpdated);

          },
          (error: GenericException) => {

            MessagePanelComponent.hideLoading();
            this.serverErrorManager.manageErrorCode(error, this.messagePanelComponent);

          }
        )

    }

  }

  setBidFormValues(tourResume: TourResumeAdmin) {
    this.bidForm.get('tourId').setValue(tourResume.id);
    this.bidForm.get('bid').setValue(tourResume.bid);
    this.bidForm.get('bidBlocked').setValue(tourResume.bidBlocked);

    this.minBidLabelValue.value = tourResume.bidMin;
  }

}




