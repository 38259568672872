import { ApiConstants } from '../api.constants';
import { ApiUtils } from '../api.utils';

export class AdminProviderApiEndpointsUtils {


     public static getCreateProviderEndpoint(): string {
          return 'admin/providers';
     }

     public static getCreateProviderEndpointAcceptVersion(): string {
          return ApiConstants.API_HEADER_ACCEPT_V_1_0_1;
     }

     public static getProvidersEndpoint(page: number): string {
          return ApiUtils.prepareQueryRequest('admin/providers', { 'page': page });
     }

     public static getProvidersEndpointAcceptVersion(): string {
          return ApiConstants.API_HEADER_ACCEPT_V_1_0_1;
     }

     public static getAllProvidersEndpoint(): string {
          return 'admin/providers';
     }

     public static getAllProvidersEndpointAcceptVersion(): string {
          return ApiConstants.API_HEADER_ACCEPT_V_1_0_1;
     }

     public static getProviderDetailsEndpoint(providerId: number): string {
          return `admin/providers/${providerId}`;
     }

     public static getProviderDetailsEndpointAcceptVersion(): string {
          return ApiConstants.API_HEADER_ACCEPT_V_1_0_1;
     }

     public static getResendProviderWelcomeEndpoint(providerId: number): string {
          return `admin/providers/${providerId}/resendsignupemail`;
     }

     public static getResendProviderWelcomeEndpointAcceptVersion(): string {
          return ApiConstants.API_HEADER_ACCEPT_V_1_0_1;
     }

     public static getUpdateProviderDetailsEndpoint(providerId: number): string {
          return `admin/providers/${providerId}/companydetails`;
     }

     public static getUpdateProviderDetailsEndpointAcceptVersion(): string {
          return ApiConstants.API_HEADER_ACCEPT_V_1_0_1;
     }

     public static getUpdateProviderPaymentEndpoint(providerId: number): string {
          return `admin/providers/${providerId}/billingdetails`;
     }

     public static getUpdateProviderPaymentEndpointAcceptVersion(): string {
          return ApiConstants.API_HEADER_ACCEPT_V_1_0_1;
     }

     public static getUpdateProviderPaymentObject(
          businessName: string, businessDocumentType: string, businessDocument: string,
          businessAddress: string, businessZeroVat: boolean
     ): any {

          return {
               'businessName': businessName,
               'businessDocumentType': businessDocumentType,
               'businessDocument': businessDocument,
               'businessAddress': businessAddress,
               'businessZeroVat': businessZeroVat,
          }
     }




     public static getConfirmProviderSingupEndpoint(providerId: number): string {
          return `admin/providers/${providerId}/confirm`;
     }

     public static getConfirmProviderSingupEndpointAcceptVersion(): string {
          return ApiConstants.API_HEADER_ACCEPT_V_1_0_1;
     }

     public static getRejectProviderSingupEndpoint(providerId: number): string {
          return `admin/providers/${providerId}/reject`;
     }

     public static getRejectProviderSingupEndpointAcceptVersion(): string {
          return ApiConstants.API_HEADER_ACCEPT_V_1_0_1;
     }

     public static getUnregisterProviderEndpoint(providerId: number): string {
          return `admin/providers/${providerId}/unregister`;
     }

     public static getUnregisterProviderEndpointAcceptVersion(): string {
          return ApiConstants.API_HEADER_ACCEPT_V_1_0_1;
     }

     public static getRegisterProviderEndpoint(providerId: number): string {
          return `admin/providers/${providerId}/register`;
     }

     public static getRegisterProviderEndpointAcceptVersion(): string {
          return ApiConstants.API_HEADER_ACCEPT_V_1_0_1;
     }


     public static getCreateProviderStripeAccountEndpoint(providerId: number): string {
          return `admin/providers/${providerId}/stripe/account`;
     }

     public static getCreateProviderStripeAccountEndpointAcceptVersion(): string {
          return ApiConstants.API_HEADER_ACCEPT_V_1_0_1;
     }


     public static getRemoveProviderStripeAccountEndpoint(providerId: number): string {
          return `admin/providers/${providerId}/stripe/account`;
     }

     public static getRemoveProviderStripeAccountEndpointAcceptVersion(): string {
          return ApiConstants.API_HEADER_ACCEPT_V_1_0_1;
     }

     public static getUpdateProviderStripeAccountEndpoint(providerId: number): string {
          return `admin/providers/${providerId}/stripe/account`;
     }

     public static getUpdateProviderStripeAccountObject(stripeAccountId: string): any {

          return {
               "stripeAccountId": stripeAccountId
          };
     }

     public static getUpdateProviderStripeAccountEndpointAcceptVersion(): string {
          return ApiConstants.API_HEADER_ACCEPT_V_1_0_1;
     }


     public static getAdminProvidersNamesEndpoint(): string {
          return `admin/providers/names`;
     }

     public static getProvidersNamesWithGuideManagementEndpoint(): string {
          return ApiUtils.prepareQueryRequest('admin/providers/names', { 'guidesmanagement': ApiConstants.API_PROVIDER_GUIDES_MANAGEMENT_FULL });
     }

     public static getAdminProvidersNamesEndpointAcceptVersion(): string {
          return ApiConstants.API_HEADER_ACCEPT_V_1_0_1;
     }


}


