import { Component, ViewChild } from '@angular/core';
import { AdminProviderInteractor } from 'src/app/domain/interactor/admin/provider.interactor';
import { ActivatedRoute } from '@angular/router';
import { NavigationConstants } from 'src/app/ui/commons/navigation.constants';
import { MessagePanelComponent } from 'src/app/ui/components/shared/message-panel/message-panel.component';
import { Provider } from 'src/app/business/models/provider/provider.model';
import { ProviderDetailsView } from 'src/app/domain/views/provider/provider-details.view';
import { ProviderRegistrationState } from 'src/app/business/models/provider/common/provider-registration-state.constants';
import { marker as I18N } from '@biesbjerg/ngx-translate-extract-marker';
import { ServerErrorManager } from 'src/app/ui/components/utils/server-error-manager.utils';
import { GenericException } from 'src/app/domain/exceptions/generic.exception';
import { TranslateService } from '@ngx-translate/core';
import { NavigatorUtils } from 'src/app/ui/utils/navigator.utils';
import { AppConstants } from 'src/app/domain/common/app.constants';
import { ProfilePaymentView } from 'src/app/domain/views/profile-payment.view';
import { ProviderState } from 'src/app/business/models/provider/common/provider-state.constants';

@Component({
  selector: 'app-detail-provider',
  templateUrl: './detail.component.html'
})
export class DetailProviderComponent {

  @ViewChild(MessagePanelComponent)
  private messagePanelComponent: MessagePanelComponent;


  provider: Provider;
  isStatePending: boolean;
  isUnregisterAvailable: boolean;
  isStatePendingAproval: boolean;
  isRegisterAvailable: boolean;


  constructor(
    private adminProviderInteractor: AdminProviderInteractor,
    private activatedRoute: ActivatedRoute,
    private serverErrorManager: ServerErrorManager,
    private translateService: TranslateService) {


    this.activatedRoute.params.subscribe(params => {
      const providerId = params[NavigationConstants.NAVIGATION_ADMIN_PROVIDER_DETAIL_ID]
      this.getProviderDetails(providerId)
    });
  }





  getProviderDetails(providerId: number) {

    this.adminProviderInteractor.getProviderDetails(providerId)
      .subscribe(
        (response: Provider) => {
          this.provider = response

          MessagePanelComponent.hideLoading();

          this.isStatePending = this.provider.registrationState == ProviderRegistrationState.PendingSingUp;
          this.isStatePendingAproval = this.provider.registrationState == ProviderRegistrationState.PendingAproval;
          this.isUnregisterAvailable = this.provider.state == ProviderState.Active && this.provider.registrationState == ProviderRegistrationState.UnregistrationRequested || this.provider.registrationState == ProviderRegistrationState.RegistrationConfirmed;
          this.isRegisterAvailable = this.provider.state == ProviderState.Inactive && this.provider.registrationState == ProviderRegistrationState.UnregistrationConfirmed;

        },
        (error: GenericException) => {

          MessagePanelComponent.hideLoading();
          this.serverErrorManager.manageErrorCode(error, this.messagePanelComponent);

        }
      )
  }


  doResendWelcomeEmail() {

    this.messagePanelComponent.showLoading();
    this.adminProviderInteractor.resendWelcomeEmail(this.provider.id)
      .subscribe(
        () => {
          MessagePanelComponent.hideLoading();
          this.messagePanelComponent.showSuccessMessage(I18N('providerdetails.Mensaje enviado correctamente'));

        }, (error: GenericException) => {

          MessagePanelComponent.hideLoading();
          this.serverErrorManager.manageErrorCode(error, this.messagePanelComponent);
        }
      )
  }


  doConfirmProvider() {
    this.messagePanelComponent.showLoading();
    this.adminProviderInteractor.confirmProvider(this.provider.id)
      .subscribe(
        () => {
          MessagePanelComponent.hideLoading();
          this.messagePanelComponent.showSuccessMessage(I18N('providerdetails.Cuenta de proveedor confirmada'), () => this.getProviderDetails(this.provider.id));

        }, (error: GenericException) => {

          MessagePanelComponent.hideLoading();
          this.serverErrorManager.manageErrorCode(error, this.messagePanelComponent);
        }
      )
  }


  doRejectProvider() {


    this.translateService.get(I18N('providerdetails.Por favor, indica un mensaje para el proveedor')).subscribe(
      (messageTranslated: string) => {

        const validateEmptyLabel = messageTranslated;

        this.messagePanelComponent.showInputMessage(
          I18N('providerdetails.Notificación para el proveedor'),
          I18N('providerdetails.El proveedor recibirá este mensaje por correo electrónico'),
          MessagePanelComponent.MESSAGE_INPUT_TYPE_TEXT,
          '',
          (value: string) => {
            if (!value) {
              return validateEmptyLabel;
            }
          },
          (message: string) => {
            this.messagePanelComponent.showLoading();
            this.adminProviderInteractor.rejectProvider(this.provider.id, message)
              .subscribe(
                () => {
                  MessagePanelComponent.hideLoading();
                  this.messagePanelComponent.showSuccessMessage(I18N('providerdetails.Información notificada al proveedor'));
                },
                (error: GenericException) => {
                  MessagePanelComponent.hideLoading();
                  this.serverErrorManager.manageErrorCode(error, this.messagePanelComponent);
                }
              )
          }
        )
      }
    )
  }



  doUnregisterProvider() {

    this.messagePanelComponent.showLoading();
    this.adminProviderInteractor.unregisterProvider(this.provider.id)
      .subscribe(
        () => {
          MessagePanelComponent.hideLoading();
          this.messagePanelComponent.showSuccessMessage(I18N('providerdetails.Proveedor dado de baja y notificado por correo'), () => this.getProviderDetails(this.provider.id));

        }, (error: GenericException) => {

          MessagePanelComponent.hideLoading();
          this.serverErrorManager.manageErrorCode(error, this.messagePanelComponent);
        }
      )
  }


  doRegisterProvider() {
    this.messagePanelComponent.showLoading();
    this.adminProviderInteractor.registerProvider(this.provider.id)
      .subscribe(
        () => {
          MessagePanelComponent.hideLoading();
          this.messagePanelComponent.showSuccessMessage(I18N('providerdetails.Proveedor dado de alta correctamente.'), () => this.getProviderDetails(this.provider.id));

        }, (error: GenericException) => {

          MessagePanelComponent.hideLoading();
          this.serverErrorManager.manageErrorCode(error, this.messagePanelComponent);
        }
      )
  }


  onProviderPaymentSubmit(providerPayment: ProfilePaymentView) {

    this.messagePanelComponent.showLoading();
    this.adminProviderInteractor.updateProviderPayment(providerPayment)
      .subscribe(
        () => {
          MessagePanelComponent.hideLoading();
          this.messagePanelComponent.showSuccessMessage(I18N('providerdetails.Datos actualizados'));

        }, (error: GenericException) => {

          MessagePanelComponent.hideLoading();
          this.serverErrorManager.manageErrorCode(error, this.messagePanelComponent);
        }
      )
  }


  onProviderDetailsSubmit(providerDetails: ProviderDetailsView) {

    this.messagePanelComponent.showLoading();
    this.adminProviderInteractor.updateProviderDetails(providerDetails)
      .subscribe(
        () => {
          MessagePanelComponent.hideLoading();
          this.messagePanelComponent.showSuccessMessage(I18N('providerdetails.Datos actualizados'));

        }, (error: GenericException) => {

          MessagePanelComponent.hideLoading();
          this.serverErrorManager.manageErrorCode(error, this.messagePanelComponent);
        }
      )
  }


  onCreateConnectedStripeAccount() {
    this.messagePanelComponent.showLoading();

    this.adminProviderInteractor.createStripeAccount(this.provider.id)
      .subscribe(
        () => {
          MessagePanelComponent.hideLoading();
          this.messagePanelComponent.showSuccessMessage(
            I18N('providerdetails.Cuenta de Stripe creada correctamente. A continuación será necesario que aceptes la vinculación de la cuenta para recibir pagos.')
            , () => {
              NavigatorUtils.reloadApplication();
            }
          );

        }, (error: GenericException) => {

          MessagePanelComponent.hideLoading();
          this.serverErrorManager.manageErrorCode(error, this.messagePanelComponent);
        }
      )
  }


  onRemoveStripeAccount() {
    this.messagePanelComponent.showLoading();

    this.adminProviderInteractor.removeStripeAccount(this.provider.id)
      .subscribe(
        () => {
          MessagePanelComponent.hideLoading();
          this.messagePanelComponent.showSuccessMessage(
            I18N('providerdetails.Cuenta de Stripe desvinvulada correctamente.')
            , () => {
              NavigatorUtils.reloadApplication();
            }
          );

        }, (error: GenericException) => {

          MessagePanelComponent.hideLoading();
          this.serverErrorManager.manageErrorCode(error, this.messagePanelComponent);
        }
      )
  }

  onUpdateStripeAccount() {

    this.messagePanelComponent.showLoading();

    this.translateService.get('providerdetails.Actualiza tu información de Stripe').subscribe(
      (titleMessageTranslated: string) => {
        this.translateService.get(I18N('providerdetails.Indica el ID de tu cuenta de Stripe conectada'), {})
          .subscribe(
            (subtitleMessageTranslated: string) => {
              this.translateService.get(I18N('providerdetails.Texto demasiado largo'), {})
                .subscribe(
                  (validationTooLongMessageTranslated: string) => {

                    MessagePanelComponent.hideLoading();

                    this.messagePanelComponent.showInputMessage(
                      titleMessageTranslated,
                      subtitleMessageTranslated,
                      MessagePanelComponent.MESSAGE_INPUT_TYPE_TEXT,
                      null,
                      (stripeAccountId: string) => {
                        if (stripeAccountId.length > AppConstants.TEXT_DEFAULT_MAX_LENGTH) {
                          return validationTooLongMessageTranslated;
                        }
                      },
                      (stripeAccountId: string) => {

                        this.messagePanelComponent.showLoading();

                        this.adminProviderInteractor.updateStripeAccount(this.provider.id, stripeAccountId)
                          .subscribe(
                            () => {

                              MessagePanelComponent.hideLoading();
                              this.messagePanelComponent.showSuccessMessage(I18N('providerdetails.Información actualizada'), () => { NavigatorUtils.reloadApplication(); });

                            },
                            (error: GenericException) => {
                              MessagePanelComponent.hideLoading();
                              this.serverErrorManager.manageErrorCode(error, this.messagePanelComponent);
                            }
                          )
                      }
                    )

                  })
            })
      })
  }




}
