import { ApiConstants } from 'src/app/business/api/api.constants'
import { BillingDetails } from '../billing-details.model'
import { InvoiceDetails } from '../invoice-details.model'
import { InvoiceIssuer } from '../invoice-issuer.model'
import { CommissionType } from './commission-type.constants'
import { GuideServiceState } from './guide-service-state.constants'

export class BillingUtils {


  public static getBillingDetailsFromApi(billingDetailsApi: any): BillingDetails {


    return new BillingDetails(
      billingDetailsApi.people,
      billingDetailsApi.shows,
      billingDetailsApi.price,
      billingDetailsApi.payment,
      billingDetailsApi.commission
    )

  }

  public static getInvoiceFromApi(invoiceApi: any): InvoiceDetails {

    return new InvoiceDetails(
      invoiceApi.id,
      invoiceApi.serialNumber,
      invoiceApi.invoiceDate,
      invoiceApi.invoiceYearPeriod,
      invoiceApi.invoiceMonthPeriod,
      new BillingDetails(
        invoiceApi.people,
        invoiceApi.shows,
        invoiceApi.price,
        invoiceApi.payment,
        invoiceApi.commission
      ),
      invoiceApi.chargePending,
      invoiceApi.paymentPending,
      invoiceApi.chargeCompleted,
      invoiceApi.paymentCompleted,
      invoiceApi.settledUp,
    )

  }



  public static getInvoiceIssuerFromApi(invoiceIssuerApi: any): InvoiceIssuer {

    return new InvoiceIssuer(
      invoiceIssuerApi.name,
      invoiceIssuerApi.registration,
      invoiceIssuerApi.vat,
      invoiceIssuerApi.email,
      invoiceIssuerApi.address1,
      invoiceIssuerApi.address2
    )

  }



  public static getCommissionTypeFromApi(typeApi: number): CommissionType {

       if ( typeApi == ApiConstants.API_GUIDE_COMMISSION_TYPE_PEOPLE ) {
            return CommissionType.People;
       } else {
            return CommissionType.Total;
       }
  }


  public static getTypeApiFromCommissionType(type: CommissionType): number {

       if ( type ==  CommissionType.People) {
            return ApiConstants.API_GUIDE_COMMISSION_TYPE_PEOPLE;
       } else {
            return ApiConstants.API_GUIDE_COMMISSION_TYPE_TOTAL;
       }
  }



  public static getGuideServiceStateFromApi(stateApi: number): GuideServiceState {

    if ( stateApi == ApiConstants.API_GUIDE_SERVICE_STATE_PENDING ) {
         return GuideServiceState.Pending;
    } else if ( stateApi == ApiConstants.API_GUIDE_SERVICE_STATE_APPROVED ) {
        return GuideServiceState.Approved;
    } else if ( stateApi == ApiConstants.API_GUIDE_SERVICE_STATE_BILLED ) {
        return GuideServiceState.Billed;
    } else {
        return GuideServiceState.Deleted;
    }
  }



  public static getStateApiFromGuideServiceState(state: GuideServiceState): number {

    if ( state == GuideServiceState.Pending ) {
         return ApiConstants.API_GUIDE_SERVICE_STATE_PENDING;
    } else if ( state == GuideServiceState.Approved ) {
        return ApiConstants.API_GUIDE_SERVICE_STATE_APPROVED;
    } else if ( state == GuideServiceState.Billed ) {
        return ApiConstants.API_GUIDE_SERVICE_STATE_BILLED;
    } else {
        return ApiConstants.API_GUIDE_SERVICE_STATE_DELETED;
    }
  }

}
