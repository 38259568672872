import { Component, Input, EventEmitter, Output } from '@angular/core';
import { PaymentPlatform } from 'src/app/business/models/master/payment-platform/payment-platform.constants';
import { Provider } from 'src/app/business/models/provider/provider.model';

@Component({
  selector: 'app-stripe-profile-payment',
  templateUrl: './stripe.component.html'
})
export class ProfileStripeComponent {

  @Input() provider: Provider;
  @Input() isAdmin: boolean;


  @Output() createConnectedAccount = new EventEmitter<boolean>(true);
  @Output() connectAccount = new EventEmitter<boolean>(true);
  @Output() removeAccount = new EventEmitter<boolean>(true);
  @Output() updateAccount = new EventEmitter<boolean>(true);

  paymentPlatformStripe: PaymentPlatform = PaymentPlatform.Stripe;
  isDisabled = true;

  constructor() {
  }


  toggleEdition() {
    this.isDisabled = !this.isDisabled;
  }


  onCreateConnectedAccount() {
    this.createConnectedAccount.emit(true);
  }

  onConnectAccount() {
    this.connectAccount.emit(true);
  }

  onRemoveAccount() {
    this.removeAccount.emit(true);
  }

  onUpdateAccount() {
    this.updateAccount.emit(true);
  }




}
