import { ProviderState } from './common/provider-state.constants';
import { ProviderRegistrationState } from './common/provider-registration-state.constants';
import { ImageDetails } from '../image/image-details.model';
import { PaymentPlatform } from '../master/payment-platform/payment-platform.constants';
import { ProviderGuidesManagement } from './common/provider-guides-management.constants';

export class Provider {

  private _id: number;

  private _languageId: number;

  private _companyName: string;
  private _contactPerson: string;
  private _phone: string;

  private _countryId: number;
  private _countryName: string;

  private _cityId: number;
  private _cityName: string;

  private _web: string;
  private _contactEmail: string;

  private _bookingNotificationEmailActive: boolean;
  private _bookingNotificationEmail: string;
  private _bookingNotificationEmail2: string;

  private _businessName: string;
  private _businessDocumentType: string;
  private _businessDocument: string;
  private _businessAddress: string;
  private _businessZeroVat: boolean;

  private _stripeAccountId: string;
  private _paymentPlatform: PaymentPlatform;
  private _paymentCurrency: string;
  private _iban: string;

  private _state: ProviderState;
  private _registrationState: ProviderRegistrationState;
  private _guidesManagement: ProviderGuidesManagement;

  private _canReceivePayments: boolean;
  private _canCreateStripeAccount: boolean;
  private _canGetStripeLink: boolean;
  private _canUpdateStripeAccount: boolean;
  private _canRemoveStripeAccount: boolean;



  private _logo: ImageDetails;

  constructor(
    id: number,

    languageId: number,

    companyName: string,
    contactPerson: string,
    phone: string,

    countryId: number,
    countryName: string,

    cityId: number,
    cityName: string,

    web: string,
    contactEmail: string,

    bookingNotificationEmailActive: boolean,
    bookingNotificationEmail: string,
    bookingNotificationEmail2: string,

    businessName: string,
    businessDocumentType: string,
    businessDocument: string,
    businessAddress: string,
    businessZeroVat: boolean,

    stripeAccountId: string,
    paymentPlatform: number,
    paymentCurrency: string,
    iban: string,

    state: ProviderState,
    registrationState: ProviderRegistrationState,

    guidesManagement: ProviderGuidesManagement,

    canReceivePayments: boolean,
    canCreateStripeAccount: boolean,
    canGetStripeLink: boolean,
    canUpdateStripeAccount: boolean,
    canRemoveStripeAccount: boolean,

    logo: ImageDetails

  ) {

    this._id = id;

    this._languageId = languageId;

    this._companyName = companyName;
    this._contactPerson = contactPerson;
    this._phone = phone;

    this._countryId = countryId;
    this._countryName = countryName;

    this._cityId = cityId;
    this._cityName = cityName;

    this._web = web;
    this._contactEmail = contactEmail;

    this._bookingNotificationEmailActive = bookingNotificationEmailActive;
    this._bookingNotificationEmail = bookingNotificationEmail;
    this._bookingNotificationEmail2 = bookingNotificationEmail2;

    this._businessName = businessName;
    this._businessDocumentType = businessDocumentType;
    this._businessDocument = businessDocument;
    this._businessAddress = businessAddress;
    this._businessZeroVat = businessZeroVat;

    this._stripeAccountId = stripeAccountId;
    this._paymentPlatform = paymentPlatform;
    this._paymentCurrency = paymentCurrency;
    this._iban = iban;

    this._state = state;
    this._registrationState = registrationState;

    this._guidesManagement = guidesManagement;

    this._canReceivePayments = canReceivePayments;
    this._canCreateStripeAccount = canCreateStripeAccount;
    this._canGetStripeLink = canGetStripeLink;
    this._canUpdateStripeAccount = canUpdateStripeAccount;
    this._canRemoveStripeAccount = canRemoveStripeAccount;

    this._logo = logo;

  }


  get id(): number {
    return this._id;
  }

  get languageId(): number {
    return this._languageId;
  }

  get companyName(): string {
    return this._companyName;
  }

  get contactPerson(): string {
    return this._contactPerson;
  }

  get phone(): string {
    return this._phone;
  }

  get countryId(): number {
    return this._countryId;
  }

  get countryName(): string {
    return this._countryName;
  }

  get cityId(): number {
    return this._cityId;
  }

  get cityName(): string {
    return this._cityName;
  }

  get web(): string {
    return this._web;
  }

  get contactEmail(): string {
    return this._contactEmail;
  }

  get bookingNotificationEmailActive(): boolean {
    return this._bookingNotificationEmailActive;
  }

  get bookingNotificationEmail(): string {
    return this._bookingNotificationEmail;
  }

  get bookingNotificationEmail2(): string {
    return this._bookingNotificationEmail2;
  }

  get businessName(): string {
    return this._businessName;
  }

  get businessDocumentType(): string {
    return this._businessDocumentType;
  }

  get businessDocument(): string {
    return this._businessDocument;
  }

  get businessAddress(): string {
    return this._businessAddress;
  }

  get businessZeroVat(): boolean {
    return this._businessZeroVat;
  }

  get stripeAccountId(): string {
    return this._stripeAccountId;
  }

  get paymentPlatform(): PaymentPlatform {
    return this._paymentPlatform;
  }

  get paymentCurrency(): string {
    return this._paymentCurrency;
  }

  get iban(): string {
    return this._iban;
  }

  get state(): ProviderState {
    return this._state;
  }

  get registrationState(): ProviderRegistrationState {
    return this._registrationState;
  }

  get guidesManagement(): ProviderGuidesManagement {
    return this._guidesManagement;
  }

  get canReceivePayments(): boolean {
    return this._canReceivePayments;
  }

  get canCreateStripeAccount(): boolean {
    return this._canCreateStripeAccount;
  }

  get canGetStripeLink(): boolean {
    return this._canGetStripeLink;
  }

  get canUpdateStripeAccount(): boolean {
    return this._canUpdateStripeAccount;
  }

  get canRemoveStripeAccount(): boolean {
    return this._canRemoveStripeAccount;
  }

  get logo(): ImageDetails {
    return this._logo;
  }

}
