import { Language } from '../master/language/language.model';
import { TourType } from '../master/tour/type/type.model';
import { City } from '../master/city/city.model';
import { Country } from '../master/country/country.model';
import { Fare } from './fare.model';
import { Schedule } from './schedule.module';
import { Attraction } from './attraction.model';

export class Tour {

    private _id: number;
    private _name: string;
    private _nameMain: string;
    private _description: string;
    private _descriptionMain: string;
    private _descriptionShort: string;
    private _descriptionShortMain: string;
    private _additionalInformation: string;
    private _additionalInformationMain: string;

    private _languageTourGiven: Language;
    private _type: TourType;
    private _country: Country;
    private _city: City;
    private _duration: number;
    private _attractions: Array<Attraction>;
    private _minPeople: number;
    private _maxPeople: number;
    private _cancellationLimit: number;
    private _bookingLimit: number;
    private _maxPeoplePerBooking: number;
    private _isFree: boolean;
    private _maxFreePeople: number;
    private _maxFreePeoplePrice: number;
    private _maxFreePeopleAge: number;
    private _locationCoordX: number;
    private _locationCoordY: number;
    private _meetingPoint: string;
    private _meetingPointMain: string;
    private _locationIndications: string;
    private _locationIndicationsMain: string;
    private _locationMoreIndications: string;
    private _locationMoreIndicationsMain: string;
    private _fares: Array<Fare>;
    private _schedule: Schedule;
    private _languageMain: Language;
    private _languageI18N: Language;
    private _languageI18NAlternatives: Array<Language>;
    private _state: number;



    constructor(
        id: number,
        name: string,
        nameMain: string,
        description: string,
        descriptionMain: string,
        descriptionShort: string,
        descriptionShortMain: string,
        additionalInformation: string,
        additionalInformationMain: string,
        languageTourGiven: Language,
        type: TourType,
        country: Country,
        city: City,
        duration: number,
        attractions: Array<Attraction>,
        minPeople: number,
        maxPeople: number,
        cancellationLimit: number,
        bookingLimit: number,
        maxPeoplePerBooking: number,
        isFree: boolean,
        maxFreePeople: number,
        maxFreePeoplePrice: number,
        maxFreePeopleAge: number,
        locationCoordX: number,
        locationCoordY: number,
        meetingPoint: string,
        meetingPointMain: string,
        locationIndications: string,
        locationIndicationsMain: string,
        locationMoreIndications: string,
        locationMoreIndicationsMain: string,
        fares: Array<Fare>,
        schedule: Schedule,
        languageMain: Language,
        languageI18N: Language,
        languageMainAlternatives: Array<Language>,
        state: number
    ) {
        this._id = id;
        this._name = name;
        this._nameMain = nameMain;
        this._description = description;
        this._descriptionMain = descriptionMain;
        this._descriptionShort = descriptionShort;
        this._descriptionShortMain = descriptionShortMain;
        this._additionalInformation = additionalInformation;
        this._additionalInformationMain = additionalInformationMain;
        this._languageTourGiven = languageTourGiven;
        this._type = type;
        this._country = country;
        this._city = city;
        this._duration = duration;
        this._attractions = attractions;
        this._minPeople = minPeople;
        this._maxPeople = maxPeople;
        this._bookingLimit = bookingLimit;
        this._cancellationLimit = cancellationLimit;
        this._maxPeoplePerBooking = maxPeoplePerBooking;
        this._isFree = isFree;
        this._maxFreePeople = maxFreePeople;
        this._maxFreePeoplePrice = maxFreePeoplePrice;
        this._maxFreePeopleAge = maxFreePeopleAge;
        this._locationCoordX = locationCoordX;
        this._locationCoordY = locationCoordY;
        this._meetingPoint = meetingPoint;
        this._meetingPointMain = meetingPointMain;
        this._locationIndications = locationIndications;
        this._locationIndicationsMain = locationIndicationsMain;
        this._locationMoreIndications = locationMoreIndications;
        this._locationMoreIndicationsMain = locationMoreIndicationsMain;
        this._fares = fares;
        this._schedule = schedule;
        this._languageMain = languageMain;
        this._languageI18N = languageI18N;
        this._languageI18NAlternatives = languageMainAlternatives;
        this._state = state;
    }

    get id(): number {
        return this._id;
    }

    get name(): string {
        return this._name;
    }

    get nameMain(): string {
        return this._nameMain;
    }

    get description(): string {
        return this._description;
    }

    get descriptionMain(): string {
        return this._descriptionMain;
    }

    get descriptionShort(): string {
        return this._descriptionShort;
    }

    get descriptionShortMain(): string {
        return this._descriptionShortMain;
    }

    get additionalInformation(): string {
        return this._additionalInformation;
    }

    get additionalInformationMain(): string {
        return this._additionalInformationMain;
    }

    get languageTourGiven(): Language {
        return this._languageTourGiven;
    }

    get type(): TourType {
        return this._type;
    }

    get country(): Country {
        return this._country;
    }

    get city(): City {
        return this._city;
    }

    get duration(): number {
        return this._duration;
    }

    get attractions(): Array<Attraction> {
        return this._attractions;
    }

    get minPeople(): number {
        return this._minPeople;
    }

    get maxPeople(): number {
        return this._maxPeople;
    }

    get bookingLimit(): number {
        return this._bookingLimit;
    }

    get cancellationLimit(): number {
        return this._cancellationLimit;
    }

    get maxPeoplePerBooking(): number {
        return this._maxPeoplePerBooking;
    }

    get isFree(): boolean {
        return this._isFree;
    }

    get maxFreePeople(): number {
        return this._maxFreePeople;
    }

    get maxFreePeoplePrice(): number {
        return this._maxFreePeoplePrice;
    }

    get maxFreePeopleAge(): number {
        return this._maxFreePeopleAge;
    }

    get locationCoordX(): number {
        return this._locationCoordX;
    }

    get locationCoordY(): number {
        return this._locationCoordY;
    }

    get meetingPoint(): string {
        return this._meetingPoint;
    }

    get meetingPointMain(): string {
        return this._meetingPointMain;
    }

    get locationIndications(): string {
        return this._locationIndications;
    }

    get locationIndicationsMain(): string {
        return this._locationIndicationsMain;
    }

    get locationMoreIndications(): string {
        return this._locationMoreIndications;
    }

    get locationMoreIndicationsMain(): string {
        return this._locationMoreIndicationsMain;
    }

    get fares(): Array<Fare> {
        return this._fares;
    }

    get schedule(): Schedule {
        return this._schedule;
    }

    get languageMain(): Language {
        return this._languageMain;
    }

    get languageI18N(): Language {
        return this._languageI18N;
    }

    get languageMainAlternatives(): Array<Language> {
        return this._languageI18NAlternatives;
    }

    get state(): number {
        return this._state;
    }


}
