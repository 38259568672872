import { AffiliateDayStats } from './affiliate-day-stats.model';
import { AffiliateStats } from './affiliate-stats.model';

export class AffiliateDailyStats {

     private _stats: AffiliateStats;
     private _daysStats: Array<AffiliateDayStats>;

     constructor(
          stats: AffiliateStats,
          daysStats: Array<AffiliateDayStats>

     ) {

          this._stats = stats;
          this._daysStats = daysStats;

     }

     get stats(): AffiliateStats {
          return this._stats;
     }

     get daysStats(): Array<AffiliateDayStats> {
          return this._daysStats;
     }

}
