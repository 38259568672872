import { BookingPaymentState } from './common/booking-payment-state.constants';

export class BookingOrderPayment {


    private _id: number;
    private _price: number;
    private _stripeTransactionId: string;
    private _state: BookingPaymentState;


    constructor(
      id: number,
      price: number,
      stripeTransactionId: string,
      state: BookingPaymentState,
    ) {

        this._id = id;
        this._price = price;
        this._stripeTransactionId = stripeTransactionId;
        this._state = state;

    }

    get id(): number {
      return this._id;
    }

    get price(): number {
      return this._price;
    }

    get stripeTransactionId(): string {
      return this._stripeTransactionId;
    }

    get state(): BookingPaymentState {
      return this._state;
    }

}
