import { Component, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { NavigationConstants } from 'src/app/ui/commons/navigation.constants';
import { ViewportScroller } from '@angular/common';
import { SessionInteractor } from 'src/app/domain/interactor/session.interactor';

@Component({
     selector: 'app-header-landing',
     templateUrl: './header.component.html'
})
export class HeaderLandingComponent {

     @Output() menuItemSelected = new EventEmitter<string>();

     navbarOpen = false;
     desktopVersion: boolean;

     isScrolling: boolean;


     ngOnInit() {
          //window.addEventListener('scroll', this.scroll, true);
     }

     constructor(
          private router: Router,
          private viewportScroller: ViewportScroller,
          private sessionInteractor: SessionInteractor ) {

               this.isScrolling = false;
               this.desktopVersion = true;

     }

     goToHomeConditional() {
          if ( this.sessionInteractor.loggedIn() ) {
               this.goToDashboard();
          } else {
               this.goToHome();
          }
     }

     goToPlansLanding() {
          this.router.navigate( [ NavigationConstants.getNavigationPlansLanding() ] );
     }

     toggleNavbar() {
          this.navbarOpen = !this.navbarOpen;
     }

     scrollToTop() {
          window.scroll(0,0);
     }

     goToHome() {
          this.menuItemSelected.emit( NavigationConstants.NAVIGATION_HOME_ANCHOR_HOME );
     }

     goToFeatures() {
          this.menuItemSelected.emit( NavigationConstants.NAVIGATION_HOME_ANCHOR_FEATURES );
     }

     goToAdvantages() {
          this.menuItemSelected.emit( NavigationConstants.NAVIGATION_HOME_ANCHOR_ADVANTAGES );
     }

     goToContact() {
          this.menuItemSelected.emit( NavigationConstants.NAVIGATION_HOME_ANCHOR_CONTACT );
     }

     goToDashboard() {
         this.router.navigate([ NavigationConstants.getNavigationUserZone() ]);
     }

     onPageYOffsetChanged(pageYOffset: number) {
          if ( pageYOffset > 50 ) {
               this.isScrolling = true;
          } else {
               this.isScrolling = false;
          }
     }


}
