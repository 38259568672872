<div class="form-boxes m-0 p-0" *ngIf="provider && provider.paymentPlatform == paymentPlatformStripe">

    <div class="row mb-2">
        <div class="col-10">
            <h2 class="font-weight-bold d-flex">
                <img class="ic-mid mr-2" src="assets/img/ic_verified_user.svg">{{ 'providerstripe.Cuenta de stripe' | translate }}
            </h2>
            <h5>{{ 'providerstripe.Información sobre tu cuenta conectada de Stripe' | translate }}</h5>
            <hr class="w-10 float-left mt-2 mb-4">
        </div>
        <div class="col-2 text-right">
            <a class="cursor-pointer" (click)="toggleEdition()">
                <img class="ic-mid mr-2" src="{{ isDisabled ? 'assets/img/ic_edit.svg' : 'assets/img/ic_edit-disabled.svg'}}">
            </a>
        </div>
    </div>


    <ng-container *ngIf="provider.canCreateStripeAccount">

         <div class="row dashboard-content-section">

              <div class="col-12 card card-list">
                   <div class="row padding-lg-inside">
                        <div class="col-12 col-md-12">

                             <div class="row mt-2 mb-3">
                                  <div class="col-12 col-md-12">
                                       <div ng-reflect-ng-class="status remove" class="status remove"><div class="dot"></div> {{ 'providerstripe.Actualmente no dispones de una cuenta conectada' | translate }}</div>
                                  </div>
                            </div>

                             <div class="row mb-2">
                                  <div class="col-12 col-md-12">
                                       {{ 'providerstripe.Utilizamos tu cuenta conectada de Stripe para abonar el dinero correspondiente a tus reservas.'  | translate }}
                                       <br/>
                                       {{ 'providerstripe.Puedes iniciar el proceso de conectar tu cuenta pulsando en el botón: "Crear cuenta conectada".' | translate }}
                                       <br/>
                                       {{ 'providerstripe.Si ya dispones de una cuenta conectada con nosotros, puedes añadirla directamente utilizando esa opción.' | translate }}
                                  </div>
                             </div>

                         </div>
                    </div>
              </div>
         </div>
         <div class="row mb-5 pb-5">
             <div class="col-12 col-md-6">
                 <input (click)="onCreateConnectedAccount()" type="submit" value="{{ 'providerstripe.Crear cuenta conectada' | translate }}" class="btn btn-full-width btn-primary mb-3 mb-sm-0" [disabled]="isDisabled" />
             </div>
             <div class="col-12 col-md-6">
                 <input (click)="onUpdateAccount()" type="submit" value="{{ 'providerstripe.Añadir cuenta conectada' | translate }}" class="btn btn-full-width btn-primary mb-3 mb-sm-0" [disabled]="isDisabled" />
             </div>
         </div>

     </ng-container>




     <ng-container *ngIf="provider.canGetStripeLink">

         <div class="row dashboard-content-section">

              <div class="col-12 card card-list">
                   <div class="row padding-lg-inside">
                        <div class="col-12 col-md-12">

                             <div class="row mt-2 mb-3">
                                  <div class="col-12 col-md-12">
                                       <div ng-reflect-ng-class="status pending-normal" class="status pending-normal"><div class="dot"></div> {{ 'providerstripe.Necesitas realizar alguna acción para finalizar la configuración de tu cuenta conectada' | translate }}</div>
                                  </div>
                            </div>

                             <div class="row mb-2">
                                  <div class="col-12 col-md-12">
                                       {{ 'providerstripe.Utilizamos tu cuenta conectada de Stripe para abonar el dinero correspondiente a tus reservas.'  | translate }}
                                       <br/>
                                       {{ 'providerstripe.Necesitamos que finalices el proceso de vinculación de la cuenta o puedes reiniciar el proceso si lo prefieres.' | translate }}
                                  </div>
                             </div>

                         </div>
                    </div>
              </div>
         </div>
         <div class="row mb-5 pb-5">
             <div *ngIf="!isAdmin" class="col-12 col-md-6">
                 <input (click)="onConnectAccount()" type="submit" value="{{ 'providerstripe.Vincular cuenta de Stripe' | translate }}" class="btn btn-full-width btn-primary mb-3 mb-sm-0" [disabled]="isDisabled" />
             </div>
             <div class="col-12 col-md-6">
                 <input (click)="onRemoveAccount()"  type="submit" value="{{ 'providerstripe.Desvincular cuenta de Stripe' | translate }}" class="btn btn-full-width btn-primary mb-3 mb-sm-0" [disabled]="isDisabled" />
             </div>
         </div>

     </ng-container>






     <ng-container *ngIf="provider.canReceivePayments">

         <div class="row dashboard-content-section">

              <div class="col-12 card card-list">
                   <div class="row padding-lg-inside">
                        <div class="col-12 col-md-12">

                             <div class="row mt-2 mb-3">
                                  <div class="col-12 col-md-12">
                                       <div ng-reflect-ng-class="status normal" class="status normal"><div class="dot"></div> {{ 'providerstripe.Tu cuenta de Stripe está conectada correctamente' | translate }}</div>
                                  </div>
                            </div>

                             <div class="row mb-2">
                                  <div class="col-12 col-md-12">
                                       {{ 'providerstripe.Utilizamos tu cuenta conectada de Stripe para abonar el dinero correspondiente a tus reservas.'  | translate }}
                                  </div>
                             </div>

                         </div>
                    </div>
              </div>
         </div>
         <div class="row mb-5 pb-5">
             <div class="col-12 col-md-6">
                 <input (click)="onRemoveAccount()"  type="submit" value="{{ 'providerstripe.Desvincular cuenta de Stripe' | translate }}" class="btn btn-full-width btn-primary mb-3 mb-sm-0" [disabled]="isDisabled" />
             </div>
         </div>

     </ng-container>


</div>