import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ServerErrorManager } from 'src/app/ui/components/utils/server-error-manager.utils';
import { NavigatorUtils } from 'src/app/ui/utils/navigator.utils';
import { SessionInteractor } from 'src/app/domain/interactor/session.interactor';
import { BookingInteractor } from 'src/app/domain/interactor/public/booking.interactor';
import { marker as I18N } from '@biesbjerg/ngx-translate-extract-marker';
import { MessagePanelComponent } from 'src/app/ui/components/shared/message-panel/message-panel.component';
import { GenericException } from 'src/app/domain/exceptions/generic.exception';
import { BookingOrder } from 'src/app/business/models/book/booking-order.model';
import { StripeManager } from 'src/app/ui/utils/stripe-manager';
import { StripeCardElement } from '@stripe/stripe-js';
import { BookingOrderState } from 'src/app/business/models/book/common/booking-order-state.constants';


@Component({
     selector: 'app-booking-payment',
     templateUrl: './booking-payment.component.html'
})
export class BookingPaymentComponent implements OnInit, AfterViewInit {

     @ViewChild(MessagePanelComponent)
     private messagePanelComponent: MessagePanelComponent;

     orderBooking: BookingOrder;
     paymentState: BookingOrderState;

     isPaymentDisabled: boolean;
     card: StripeCardElement;

     bookingOrderPendingState: BookingOrderState = BookingOrderState.Pending;



     constructor(
          private activatedRoute: ActivatedRoute,
          private sessionInteractor: SessionInteractor,
          private stripeManager: StripeManager,
          private bookingInteractor: BookingInteractor,
          private serverErrorManager: ServerErrorManager) {

               this.isPaymentDisabled = true;
               this.paymentState = BookingOrderState.Pending;

               this.activatedRoute.queryParams.subscribe(params => {

                    const orderBookingId = params['orderBookingId'];
                    const verificationCode = params['hash'];
                    const languageCode = params['languageCode']
                    if ( this.sessionInteractor.setCurrentLanguage(languageCode) ) {
                         NavigatorUtils.reloadApplication();
                    }

                    this.bookingInteractor.getBookingForPayment(orderBookingId, verificationCode)
                         .subscribe(
                              (orderBooking: BookingOrder) => {
                                   this.orderBooking = orderBooking;
                                   this.paymentState = this.orderBooking.state;
                              },
                              (error: GenericException) => {
                                   MessagePanelComponent.hideLoading();
                                   this.serverErrorManager.manageErrorCode(error, this.messagePanelComponent);
                              }

                         )

               });

          }
     ngAfterViewInit() {
          this.stripeManager.existStripeComponent()
          .subscribe(
               () => {
                    this.stripeManager.createCard(
                         () => {
                              this.isPaymentDisabled = false;
                         },
                         () => {
                              this.isPaymentDisabled = true;
                         }
                    ).then(
                         (card: StripeCardElement) => {
                              this.card = card;
                         }
                    )
               }
          )
     }

          ngOnInit() { }

          onConfirmPayment() {

               this.messagePanelComponent.showLoading();

               this.stripeManager.createToken(this.card);

               this.stripeManager.confirmCardPayment(
                    this.orderBooking.payment.stripeTransactionId, this.card, this.orderBooking.customer.email,
                 () => {

                   MessagePanelComponent.hideLoading();

                   this.messagePanelComponent.showSuccessMessage(I18N('invoicepayment.Pago realizado correctamente'), () => {
                       this.paymentState = BookingOrderState.Completed;
                   });

                 }, (errorMessage: string) => {

                   MessagePanelComponent.hideLoading();

                   this.messagePanelComponent.showErrorMessage(errorMessage);

                 }
               );
          }

     }
