<div class="animated fadeIn">
    <div class="row header-dashboard align-items-lg-center align-items-start">
        <div class="col-12 col-sm-8 col-lg-6 col-xl-6 pl-0 order-1 order-lg-1">
            <h2 class="font-weight-bold">{{ 'invoiceslist.Facturación' | translate }}</h2>
            <p>{{ 'invoiceslist.Facturación del sistema de afiliados' | translate }}</p>
        </div>
    </div>

    <div class="row dashboard-content-section mb-5">
        <div class="col-12 px-0">
            <app-invoices-table *ngIf="desktopVersion"
                    [nextBilling]="nextBilling" [invoices]="invoices"
                    [canReceivePayments]="canReceivePayments"
                    [pagination]="pagination"
                    (invoiceDetails)="onInvoiceDetails($event)"
                    (invoiceDownload)="onInvoiceDownload($event)"
                    (goToProfile)="onGoToProfile()"></app-invoices-table>

            <app-invoices-cards *ngIf="!desktopVersion"
            [nextBilling]="nextBilling" [invoices]="invoices"
            [canReceivePayments]="canReceivePayments"
            [pagination]="pagination"
            (invoiceDetails)="onInvoiceDetails($event)"
            (invoiceDownload)="onInvoiceDownload($event)"
            (goToProfile)="onGoToProfile()"></app-invoices-cards>
        </div>
    </div>


    <!-- Pagination -->

    <div class="row bg-grey-mid-color" *ngIf="!noResults">
        <div class="col-12 d-flex justify-content-center">
            <pagination-controls [maxSize]="pagination.itemsPerPage" [directionLinks]="true" [autoHide]="true" [responsive]="true" [previousLabel]="paginationPreviousLabel" [nextLabel]="paginationNextLabel" (pageChange)="onPageChanged($event)"></pagination-controls>
        </div>
    </div>


    <app-message-panel [preloading]="false"></app-message-panel>


</div>

<app-window-size-controller [initialSize]="initialWindowSize" (isDesktopVersion)="onDesktopVersionUpdated($event)"></app-window-size-controller>