import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { Pagination } from 'src/app/domain/views/pagination.view';
import { InvoiceFullDetails } from 'src/app/business/models/billing/invoice-full-details.model';
import { InvoiceDetails } from 'src/app/business/models/billing/invoice-details.model';

@Component({
  selector: 'app-invoices-admin-affiliate-table',
  templateUrl: './table.component.html'
})
export class AdminAffiliateInvoicesTableComponent implements OnInit {

  @Input() invoices: Array<InvoiceFullDetails>;

  @Input() pagination: Pagination;

  @Input() noResults: boolean;

  @Output() invoiceDownload = new EventEmitter<InvoiceDetails>();

  @Output() invoiceDetails = new EventEmitter<number>();

  @Output() paymentToAffiliate = new EventEmitter<number>();

  @Output() manualSettleUp = new EventEmitter<number>();


  constructor() { }

  ngOnInit() { }


  onInvoiceDownload(invoiceDetails: InvoiceDetails) {
    this.invoiceDownload.emit(invoiceDetails);
  }

  onInvoiceDetails(invoiceId: number) {
    this.invoiceDetails.emit(invoiceId);
  }

  onPaymentToAffiliate(invoiceId: number) {
    this.paymentToAffiliate.emit(invoiceId);
  }

  onManualSettleUp(invoiceId: number) {
    this.manualSettleUp.emit(invoiceId);
  }


}
