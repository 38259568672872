<table class="table table-hover table-dark">
     <thead class="thead-dark">
          <tr>
               <th scope="col">{{ 'affiliatetourlist.Tour' | translate }}</th>
               <th scope="col">{{ 'affiliatetourlist.Idioma' | translate }}</th>
               <th scope="col">{{ 'affiliatetourlist.Comisión' | translate }}</th>
               <th scope="col"></th>
          </tr>
     </thead>
     <tbody class="">

          <tr *ngFor="let tourResume of tours | paginate: pagination;">
               <td scope="row ">
                    {{ tourResume.name | I18NTranslationSave: tourResume.nameMain }}
                    <br/>
                    {{ tourResume.city.name }}, {{ tourResume.country.name }}
                    <br/>
                    <b *ngIf="tourResume.isFree">{{ 'affiliatetourlist.Free tour' | translate }}</b>
                    <span *ngIf="tourResume.state != tourStateActive" class="tag-primary inactive-tour">
                         {{ 'listbooking.Tour inactivo' | translate }}
                    </span>
               </td>
               <td>
                    {{ tourResume.languageTourGiven.name }}
               </td>
               <td>
                    {{ tourResume.commission }} €
               </td>

               <td class="px-0">
                    <label class="btn-more-vert dropdown">
                         <div class="dd-button">
                              <img class="ic-mid" src="assets/img/ic_more-vert.svg">
                         </div>

                         <input type="checkbox" class="dd-input" id="test">

                         <ul class="dd-menu text-right">

                              <li>
                                   <a *ngIf="tourResume.affiliateLinks.length > 0" (click)="onViewTourLink(tourResume.affiliateLinks)">
                                        {{ 'affiliatetourlist.Ver enlace' | translate }}
                                   </a>
                                   <span *ngIf="tourResume.affiliateLinks.length == 0">{{ 'affiliatetourlist.Enlace no disponible' | translate }}</span>
                              </li>

                              <li class="divider"></li>

                              <li *ngIf="tourResume.state == tourStateActive">
                                   <a (click)="onCreateBookingLink(tourResume.id)">
                                        {{ 'affiliatetourlist.Crear reserva' | translate }}
                                   </a>
                              </li>

                         </ul>

                    </label>
               </td>

          </tr>

     </tbody>
</table>