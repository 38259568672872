import { BookEvent } from './book-event.model';
import { BookingOrderCustomer } from './booking-order-customer.model';
import { BookingOrderPayment } from './booking-order-payment.view';
import { BookingOrderState } from './common/booking-order-state.constants';

export class BookingOrder {


    private _id: number;
    private _totalPrice: number;
    private _payment: BookingOrderPayment;
    private _customer: BookingOrderCustomer;
    private _state: BookingOrderState;
    private _bookEvent: BookEvent;

    constructor(
      id: number,
      totalPrice: number,
      payment: BookingOrderPayment,
      customer: BookingOrderCustomer,
      state: BookingOrderState,
      bookEvent: BookEvent
    ) {

        this._id = id;
        this._totalPrice = totalPrice;
        this._payment = payment;
        this._customer = customer;
        this._state = state;
        this._bookEvent = bookEvent;

    }

    get id(): number {
      return this._id;
    }

    get totalPrice(): number {
      return this._totalPrice;
    }

    get payment(): BookingOrderPayment {
      return this._payment;
    }

    get customer(): BookingOrderCustomer {
      return this._customer;
    }

    get state(): BookingOrderState {
      return this._state;
    }

    get bookEvent(): BookEvent {
      return this._bookEvent;
    }

}
