import { Injectable } from '@angular/core';
import { Observable, Observer } from 'rxjs';
import { GenericException } from '../../exceptions/generic.exception';
import { ChunkGeneric } from '../../views/chunk-generic.view';
import { SessionInteractor } from '../session.interactor';
import { BookingBilling } from 'src/app/business/models/billing/booking-billing.model';
import { GuideBillingService } from 'src/app/business/services/guide/billing.service';
import { GuideBillingStats } from 'src/app/business/models/billing/billing-stats.model';


@Injectable({
     providedIn: 'root'
})
export class GuideBillingInteractor {

     constructor(private guideBillingService: GuideBillingService, private sessionInteractor: SessionInteractor) {
     }


     findBilling(dateInit: string, dateEnd: string, keywords: string, page: number): Observable<ChunkGeneric<BookingBilling>> {

          return Observable.create((observer: Observer<ChunkGeneric<BookingBilling>>) => {
               this.guideBillingService.findBilling(dateInit, dateEnd, keywords, page)
               .subscribe(
                    (chunkBookigBilling: ChunkGeneric<BookingBilling>) => {

                         observer.next(chunkBookigBilling);
                         observer.complete();
                    },
                    (error: GenericException) => {
                         observer.error(error);
                    }
               )

          });

     }


     findBillingStats(dateInit: string, dateEnd: string, keywords: string): Observable<GuideBillingStats> {

          return Observable.create((observer: Observer<GuideBillingStats>) => {
               this.guideBillingService.findBillingStats(dateInit, dateEnd, keywords, this.sessionInteractor.getCurrentLanguage())
               .subscribe(
                    (guideBillingStats: GuideBillingStats) => {

                         observer.next(guideBillingStats);
                         observer.complete();
                    },
                    (error: GenericException) => {
                         observer.error(error);
                    }
               )

          });

     }



/*





     findInvoices(page: number): Observable<ChunkGeneric<InvoiceDetails>> {

          return Observable.create((observer: Observer<ChunkGeneric<InvoiceDetails>>) => {
               this.affiliateBillingService.findInvoices(page)
               .subscribe(
                    (chunkInvoiceDetails: ChunkGeneric<InvoiceDetails>) => {

                         observer.next(chunkInvoiceDetails);
                         observer.complete();
                    },
                    (error: GenericException) => {
                         observer.error(error);
                    }
               )

          });

     }


     findNextBilling(): Observable<NextBilling> {

          return Observable.create((observer: Observer<NextBilling>) => {
               this.affiliateBillingService.findNextBilling(this.sessionInteractor.getCurrentLanguage())
               .subscribe(
                    (nextBilling: NextBilling) => {

                         observer.next(nextBilling);
                         observer.complete();
                    },
                    (error: GenericException) => {
                         observer.error(error);
                    }
               )

          });

     }


     findLatestBilling(maxResults: number): Observable<Array<BookingBilling>> {

          return Observable.create((observer: Observer<Array<BookingBilling>>) => {
               this.affiliateBillingService.findLatestBilling()
               .subscribe(
                    (collectionBookigBilling: Array<BookingBilling>) => {

                         let latestBilling = collectionBookigBilling;
                         if ( latestBilling.length > maxResults ) {
                              latestBilling = latestBilling.slice(0, maxResults);
                         }
                         observer.next(latestBilling);
                         observer.complete();
                    },
                    (error: GenericException) => {
                         observer.error(error);
                    }
               )

          });

     }


     invoiceDownload(invoiceId: number): Observable<Blob> {

          return Observable.create((observer: Observer<Blob>) => {
               this.affiliateBillingService.invoiceDownload(invoiceId)
               .subscribe(
                    (data: any) => {

                         const blob = new Blob([(data)], { type: 'application/vnd.ms-excel;charset=utf-8' });

                         observer.next(blob);
                         observer.complete();
                    },
                    (error: GenericException) => {
                         observer.error(error);
                    }
               )

          });

     }


     findInvoice(invoiceId: number): Observable<InvoiceFullDetails> {

          return Observable.create((observer: Observer<InvoiceFullDetails>) => {
               this.affiliateBillingService.findInvoice(invoiceId)
               .subscribe(
                    (invoiceDetails: InvoiceFullDetails) => {

                         observer.next(invoiceDetails);
                         observer.complete();
                    },
                    (error: GenericException) => {
                         observer.error(error);
                    }
               )

          });

     }



     getAffiliateStats(): Observable<AffiliateStatsDetails> {

          return Observable.create((observer: Observer<AffiliateStatsDetails>) => {
               this.affiliateBillingService.getAffiliateStats()
               .subscribe(
                    (affiliateStatsDetails: AffiliateStatsDetails) => {
                         observer.next(affiliateStatsDetails);
                         observer.complete();
                    },
                    (error: GenericException) => {
                         observer.error(error);
                    }
               )

          });
     }


     getAffiliateDailyStats(year: number, month: number): Observable<AffiliateDailyStats> {

          return Observable.create((observer: Observer<AffiliateDailyStats>) => {
               this.affiliateBillingService.getAffiliateDailyStats(year, month)
               .subscribe(
                    (affiliateDailyStats: AffiliateDailyStats) => {
                         observer.next(affiliateDailyStats);
                         observer.complete();
                    },
                    (error: GenericException) => {
                         observer.error(error);
                    }
               )

          });
     }
*/



}
