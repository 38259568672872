<table class="table table-hover table-dark table-striped">
     <thead class="thead-dark">
          <tr>
               <th scope="col">{{ 'guidelist.NOMBRE' | translate }}</th>
               <th scope="col">{{ 'guidelist.EMAIL' | translate }}</th>
               <th scope="col">{{ 'guidelist.TELÉFONO' | translate }}</th>
               <th scope="col">{{ 'guidelist.CIUDAD' | translate }}</th>
               <th scope="col">{{ 'guidelist.ESTADO' | translate }}</th>
          </tr>
     </thead>
     <tbody class="row-clickable">
          <tr *ngFor="let guide of guides | paginate: pagination;" (click)="onGuideClick(guide.id)">
               <td>{{ guide.companyName }}</td>
               <td scope="row ">{{ guide.username }}</td>
               <td>
                    {{ guide.phone }}
               </td>
               <td>{{ guide.country }}, {{ guide.city }}</td>
               <td [ngClass]="'status' | provideGuideStateClass: guide.state">
                    <div class="dot"></div> {{ '' | providerGuideStateName: guide.state | translate }}
               </td>

          </tr>

     </tbody>
</table>