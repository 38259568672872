import { Component } from '@angular/core';

@Component({
  selector: '[app-access-side-panel]',
  templateUrl: './side-panel.component.html'
})
export class AccessSidePanelComponent {

  constructor() {}


}
