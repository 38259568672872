<div class="card table-responsive" *ngFor="let bookingBilling of bookingBillings | paginate: pagination;">
    <div class="row mb-4">
        <div class="col-12 text-left">
            <p class="name-provider mb-2">{{ bookingBilling.tourNameTranslated }}</p>
        </div>
    </div>

    <div *ngIf="isGuideBilling && (isProvider || isAdmin)" class="row mb-2">
        <div class="col-12 title">
            {{ 'billinglist.Guide' | translate }}
        </div>
    </div>
    <div *ngIf="isGuideBilling && (isProvider || isAdmin)" class="row mb-3">
        <div class="col-12 name-contact-provider  font-weight-normal">
            {{ bookingBilling.guideDetails.companyName }}
        </div>
    </div>

    <div class="row mb-2">
        <div class="col-12 title">
            {{ 'billinglist.Datos reserva' | translate }}
        </div>
    </div>
    <div class="row mb-3">
        <div class="col-12 name-contact-provider  font-weight-normal">
            {{ bookingBilling.bookingId }} - {{ bookingBilling.customerDetails.name }}
            <span *ngIf="!isAffiliateBilling" >{{ bookingBilling.customerDetails.surname }}</span>
        </div>
    </div>

    <div class="row mb-2">
        <div *ngIf="!isAffiliateBilling && !isGuideBilling" class="col-4 title">
            {{ 'billinglist.Pax' | translate }}
        </div>
        <div *ngIf="!isAffiliateBilling && !isGuideBilling" class="col-4 title">
            {{ 'billinglist.Show' | translate }}
        </div>
        <div *ngIf="isGuideBilling" class="col-4 title">
            {{ 'billinglist.Nº Adultos' | translate }}
        </div>
        <div class="col-4 title">
            {{ 'billinglist.Comisión' | translate }}
        </div>
    </div>
    <div class="row mb-3">
        <div *ngIf="!isAffiliateBilling && !isGuideBilling" class="col-4 name-contact-provider font-weight-normal">
            {{ bookingBilling.billingDetails.people }}
        </div>
        <div *ngIf="!isAffiliateBilling && !isGuideBilling" class="col-4 name-contact-provider font-weight-normal">
            {{ bookingBilling.billingDetails.shows }}
        </div>
        <div *ngIf="isGuideBilling" class="col-4 name-contact-provider font-weight-normal">
            {{ bookingBilling.billingDetails.people }}
        </div>
        <div class="col-4 name-contact-provider font-weight-normal">
            {{ bookingBilling.billingDetails.commission }} &euro;
        </div>
    </div>

    <div *ngIf="!isAffiliateBilling && !isGuideBilling" class="row mb-2">
        <div class="col-12 title">
            {{ 'billinglist.Price' | translate }}
        </div>
    </div>
    <div *ngIf="!isAffiliateBilling && !isGuideBilling" class="row mb-3">
        <div class="col-12 name-contact-provider">
            {{ bookingBilling.billingDetails.price }} &euro;
        </div>
    </div>

    <hr class="divider">

    <div class="row mb-2">
        <div class="col-6 title">
            {{ 'billinglist.Fecha' | translate }}
        </div>
        <div *ngIf="!isGuideBilling" class="col-6 title">
            {{ 'billinglist.Facturación' | translate }}
        </div>

    </div>
    <div class="row">
        <div class="col-6 name-contact-provider">
            <p class=" mb-2">{{ bookingBilling.tourDate }} | {{ bookingBilling.tourHour }}</p>
        </div>
        <div *ngIf="!isGuideBilling" class="col-6 name-contact-provider">
            <span *ngIf="bookingBilling.invoiceDetails" class="status normal">
                 <div class="dot"></div> {{ 'billinglist.Completada' | translate }}
            </span>
            <span *ngIf="!bookingBilling.invoiceDetails" class="status remove">
                 <div class="dot"></div> {{ 'billinglist.Pendiente' | translate }}
            </span>

        </div>
    </div>

    <div class="row mb-2 mt-2">
        <div class="col-12 title">
            {{ 'billinglist.Estado' | translate }}
        </div>

    </div>
    <div class="row">
        <div class="col-12 name-contact-provider">
            <p class=" mb-2">{{ '' | billingStateBooking: bookingBilling.bookingState | translate }}</p>
        </div>
    </div>
</div>