import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { Pagination } from 'src/app/domain/views/pagination.view';
import { BookingBilling } from 'src/app/business/models/billing/booking-billing.model';

@Component({
  selector: 'app-billing-cards',
  templateUrl: './cards.component.html'
})
export class BillingCardsComponent implements OnInit {

  @Input() bookingBillings: Array<BookingBilling>;

  @Input() isAffiliateBilling: boolean;

  @Input() isGuideBilling: boolean;

  @Input() isAdmin: boolean;

  @Input() isProvider: boolean;

  @Input() pagination: Pagination;

  @Output() providerTrigger = new EventEmitter<number>();

  constructor() { }

  ngOnInit() { }


}
