import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'invoiceStateClassPipe'
})
export class InvoiceStateClassPipe implements PipeTransform {

  transform(classLabels: string, settledUp: boolean): String {

    let result = classLabels
    if (settledUp) {
      result = result + " " + 'normal';
    } else {
      result = result + " " + 'remove';
    }

    return result;

  }

}
