<div id="publicLanding" *ngIf="orderBooking">
     <div class="row dashboard-content-section cancel-page mb-5 mb-md-auto">
          <div class="col-12">
               <div class="row mb-4 mb-4-custom">
                    <div *ngIf="bookingOrderPendingState == paymentState" class="col-12 text-left">
                         <h1 class="font-weight-bold heading-widget mt-3 mb-0">{{ 'bookingpayment.Estás a un paso de confirmar tu reserva' | translate }}</h1>
                         <p class="content">{{ 'bookingpayment.En cuanto realices el abono recibirás un correo con toda la informaicón de la reserva.' | translate }}</p>
                    </div>

                    <div *ngIf="bookingOrderPendingState != paymentState" class="col-12 text-left">
                         <h1 class="font-weight-bold heading-widget mt-3 mb-0">{{ 'bookingpayment.¡Todo en orden!' | translate }}</h1>
                         <p class="content">{{ 'bookingpayment.No es necesario que realices ninguna acción adicional con tu reserva.' | translate }}</p>
                    </div>


               </div>
               <div class="row mb-3">
                    <div class="col-12 col-md-6">
                         <h4>{{ orderBooking.bookEvent.event.name }}</h4>
                         <p>
                              {{ orderBooking.bookEvent.event.city.name }}, {{ orderBooking.bookEvent.event.country.name }}
                              <br/>
                              {{ orderBooking.bookEvent.event.day }}, {{ orderBooking.bookEvent.event.hour }}
                         </p>
                    </div>
                    <div class="col-12 col-md-6">
                         <h4>{{ 'bookingpayment.Datos personales' | translate }}</h4>
                         <p>
                              {{ orderBooking.customer.name }} {{ orderBooking.customer.surname }}
                              <br/>
                              {{ orderBooking.customer.email }}
                              <br/>
                              {{ orderBooking.customer.phone }}
                         </p>
                    </div>
               </div>
               <div *ngIf="bookingOrderPendingState == paymentState" class="row">
                    <div class="col-12 col-md-6 col-xl-4">
                         <h4>{{ orderBooking.totalPrice }} €</h4>
                         <p>{{ 'bookingpayment.Introduce tus datos de pago' | translate }}</p>
                         <div id="card-element">
                              <!-- Elements will create input elements here -->
                         </div>
                         <div class="mt-2">
                               <button [disabled]="isPaymentDisabled" (click)="onConfirmPayment()" type="submit" value="{{ 'invoicepayment.Confirmar' | translate }}" class="btn btn-full-width btn-primary mb-3 mb-sm-0 white-color">
                                  {{ 'bookingpayment.Confirmar' | translate }}
                               </button>
                         </div>
                    </div>
               </div>
          </div>
     </div>


     <app-message-panel [preloading]="false"></app-message-panel>
</div>