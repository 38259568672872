import { Injectable }                       from '@angular/core';
import { Observable, Observer } from 'rxjs';
import { GenericException } from '../../exceptions/generic.exception';
import { CommissionType } from 'src/app/business/models/billing/common/commission-type.constants';
import { ProviderGuideServiceService as ProviderGuideServiceService } from 'src/app/business/services/provider/guide-service.service';
import { ChunkGeneric } from '../../views/chunk-generic.view';
import { GuideServiceDetails } from 'src/app/business/models/billing/service-details.model';
import { SessionInteractor } from '../session.interactor';
import { GuideServiceState } from 'src/app/business/models/billing/common/guide-service-state.constants';
import { GuideServiceStats } from 'src/app/business/models/billing/service-stats.model';


@Injectable({
  providedIn: 'root'
})
export class ProviderGuideServiceInteractor {

    constructor(
         private providerGuideServiceService: ProviderGuideServiceService,
         private sessionInteractor: SessionInteractor
    ) {
    }



    saveGuideService(guideServiceId: number, guideId: number, tourId: number, tourName: string, date: Date, hour: string, people: number, amount: number): Observable<boolean> {

        return Observable.create((observer: Observer<boolean>) => {

            if ( guideServiceId == null ) {
                this.providerGuideServiceService.createService(guideId, tourId, tourName, date, hour, people, amount)
                    .subscribe(
                        (success: boolean) => {

                            observer.next(success);
                            observer.complete();
                        },
                        (error: GenericException) => {
                            observer.error(error);
                        }
                    )
            } else {

                this.providerGuideServiceService.updateGuideService(guideServiceId, guideId, tourId, tourName, date, hour, people, amount)
                .subscribe(
                    (success: boolean) => {

                        observer.next(success);
                        observer.complete();
                    },
                    (error: GenericException) => {
                        observer.error(error);
                    }
                )
            }
        
        
        });
    }


    findGuideService(guideServiceId: number): Observable<GuideServiceDetails> {

        return Observable.create((observer: Observer<GuideServiceDetails>) => {
              this.providerGuideServiceService.findGuideService(guideServiceId, this.sessionInteractor.getCurrentLanguage())
                    .subscribe(
                       (guideServiceDetails: GuideServiceDetails) => {

                           observer.next(guideServiceDetails);
                           observer.complete();
                       },
                       (error: GenericException) => {
                           observer.error(error);
                       }
                    )

        });

    }
    




    findGuideServices(month: number, year: number, guideId: number, state: GuideServiceState, page: number): Observable<ChunkGeneric<GuideServiceDetails>> {

        return Observable.create((observer: Observer<ChunkGeneric<GuideServiceDetails>>) => {
              this.providerGuideServiceService.findGuideServices(month, year, guideId, state, page, this.sessionInteractor.getCurrentLanguage())
                    .subscribe(
                       (chunkCommissions: ChunkGeneric<GuideServiceDetails>) => {

                           observer.next(chunkCommissions);
                           observer.complete();
                       },
                       (error: GenericException) => {
                           observer.error(error);
                       }
                    )

        });

    }


    findGuideServicesStats(month: number, year: number, guideId: number): Observable<GuideServiceStats> {

        return Observable.create((observer: Observer<GuideServiceStats>) => {
            this.providerGuideServiceService.findGuideServicesStats(month, year, guideId, this.sessionInteractor.getCurrentLanguage())
                  .subscribe(
                     (guideServiceStats: GuideServiceStats) => {

                         observer.next(guideServiceStats);
                         observer.complete();
                     },
                     (error: GenericException) => {
                         observer.error(error);
                     }
                  )

      });

    }


    approveService(guideServiceId: number): Observable<boolean> {
        return Observable.create((observer: Observer<boolean>) => {
            this.providerGuideServiceService.updateGuideServiceState(guideServiceId, GuideServiceState.Approved)
                  .subscribe(
                     (result: boolean) => {
                         observer.next(result);
                         observer.complete();
                     },
                     (error: GenericException) => {
                         observer.error(error);
                     }
                  )

      });
    }


    deleteService(guideServiceId: number): Observable<boolean> {
        return Observable.create((observer: Observer<boolean>) => {
            this.providerGuideServiceService.updateGuideServiceState(guideServiceId, GuideServiceState.Deleted)
                  .subscribe(
                     (result: boolean) => {
                         observer.next(result);
                         observer.complete();
                     },
                     (error: GenericException) => {
                         observer.error(error);
                     }
                  )

      });
    }


}
