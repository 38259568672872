import { environment }                      from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { ApiConstants } from './api.constants';

export class ApiUtils {


  public static sendPostRequest<T>(http: HttpClient, query: string, payload: T, version: string) {
      const endpointUrl = `${environment.APP_BASE_URL}/${query}`;
      const headers: any = { [ApiConstants.API_HEADER_ACCEPT]: version };

      return http.post<T>( endpointUrl, payload, { headers: headers } );
  }

  public static sendGetRequest<T>(http: HttpClient, query: string, version: string) {
      const endpointUrl = `${environment.APP_BASE_URL}/${query}`;
      const headers: any = { [ApiConstants.API_HEADER_ACCEPT]: version };

      return http.get<T>( endpointUrl, { headers: headers } );
  }

  public static sendPutRequest<T>(http: HttpClient, query: string, payload: T, version: string) {
      const endpointUrl = `${environment.APP_BASE_URL}/${query}`;
      const headers: any = { [ApiConstants.API_HEADER_ACCEPT]: version };

      return http.put<T>( endpointUrl, payload, { headers: headers } );
  }

  public static sendDeleteRequest<T>(http: HttpClient, query: string, version: string) {
      const endpointUrl = `${environment.APP_BASE_URL}/${query}`;
      const headers: any = { [ApiConstants.API_HEADER_ACCEPT]: version };

      return http.delete<T>( endpointUrl, { headers: headers } );
  }

  public static sendBlobRequest<T>(http: HttpClient, query: string, version: string) {
       const endpointUrl = `${environment.APP_BASE_URL}/${query}`;
      const headers: any = { [ApiConstants.API_HEADER_ACCEPT]: version };

      return http.get<T>( endpointUrl, { headers: headers, responseType: 'blob' as 'json' } );
  }


  public static prepareQueryRequest(query: string, params: any): string {

      let queryBuilded = query;

      if (params !== null) {

          queryBuilded = queryBuilded.concat('?');

          const entriesOfFilters = Object.entries(params);
          for (const [key, value] of entriesOfFilters) {

              if (value !== undefined && value !== '' )  {
                  queryBuilded = queryBuilded.concat(key).concat('=');

                  if ( (typeof value == 'string') ) {
                      queryBuilded = queryBuilded.concat(value);
                  } else if ( (typeof value == 'number') ) {
                      queryBuilded = queryBuilded.concat(value.toString());
                  }

                  queryBuilded = queryBuilded.concat('&');
              }
          }
      }

      return queryBuilded;


  }



}
