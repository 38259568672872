<div class="animated fadeIn pt-3" [class.mb-5]="!isForReseller" [class.guide-tour-filter-container]="!isForReseller">
     <div class="row">
          <div *ngIf="!isForReseller" class="col-12 text-left pl-5 pr-5">
               <p class="font-weight-bold mb-1">{{ 'guidetours.Listado de tours' | translate }}:</p>
          </div>
     </div>
     <div class="row">
          <form class="form-boxes pl-5 pr-5 mb-0 mt-3" [class.d-none]="isForReseller" [class.sm-block]="isForReseller" [class.d-block]="!isForReseller"  [class.d-md-flex]="!isForReseller">
               <div class="col-12 col-md-4">
                    <div class="field pb-2">
                         <input (keyup.enter)="onSearchKeywordChanged()" [(ngModel)]="keywords" class="searcher-primary" type="search" placeholder="{{ 'guidetours.Busca tour por nombre o descripción' | translate }}" name="searcher">
                         <label for="search">{{ 'guidetours.Buscador' | translate }}</label>
                    </div>
               </div>
               <div class="col-12 col-md-4">
                    <div class="field pb-2">
                         <select [(ngModel)]="countryId" (change)="onCountrySelected()" name="country">
                              <option [ngValue]="null">{{ 'guidetours.Selecciona la opción' | translate }}</option>
                              <option *ngFor="let country of countries" value="{{ country.id }}">{{ country.name }}</option>
                         </select>
                         <label for="country">{{ 'guidetours.País' | translate }}</label>
                    </div>
               </div>
               <div class="col-12 col-md-4">
                    <div class="field pb-2">
                         <select [(ngModel)]="cityId" (change)="onCitySelected()" name="city">
                              <option [ngValue]="null">{{ 'guidetours.Selecciona la opción' | translate }}</option>
                              <option *ngFor="let city of cities" value="{{ city.id }}">{{ city.name }}</option>
                         </select>
                         <label for="city">{{ 'guidetours.Ciudad' | translate }}</label>
                    </div>
               </div>
          </form>
     </div>

     <div class="row empty-section" *ngIf="!tours || tours?.length == 0">
          <div class="col-12 text-center">
               <p>{{ 'guidetours.No se han encontrado resultados' | translate }}</p>
          </div>
     </div>

     <div class="row" role="button"  *ngIf="tours?.length > 0">
          <div *ngIf="!isForReseller" class="col-12 text-left pl-5 pr-5">
               <p class="mb-3">{{ 'guidetours.Resultados de búsqueda' | translate }}</p>
          </div>
          <div class="col-12 pl-5 pr-5">
               <table class="table table-hover table-dark table-striped">
                   <thead class="thead-dark">
                       <tr>
                           <th scope="col">{{ 'guidedetails.Tour' | translate }}</th>
                           <th scope="col">{{ 'guidedetails.Ciudad' | translate }}</th>
                       </tr>
                   </thead>
                   <tbody class="row-clickable">
                       <tr *ngFor="let tourResume of tours | paginate: pagination;" (click)="onTourSelected(tourResume)">
                           <td scope="row ">{{ tourResume.name | I18NTranslationSave: tourResume.nameMain }}</td>
                           <td>{{ tourResume.city.name }}, {{ tourResume.country.name }}</td>
                       </tr>
                   </tbody>
               </table>
          </div>
     </div>

     <!-- Pagination -->
     <div class="row" *ngIf="tours?.length > 0">
          <div class="col-12 d-flex justify-content-center">
               <pagination-controls [maxSize]="pagination.itemsPerPage" [directionLinks]="true" [autoHide]="true" [responsive]="true" [previousLabel]="paginationPreviousLabel" [nextLabel]="paginationNextLabel" (pageChange)="onPageChanged($event)"></pagination-controls>
          </div>
     </div>

     <app-window-size-controller [initialSize]="initialWindowSize" (isDesktopVersion)="onDesktopVersionUpdated($event)"></app-window-size-controller>


</div>