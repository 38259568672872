import { Component, OnInit, ViewChild } from '@angular/core';

import { MessagePanelComponent } from 'src/app/ui/components/shared/message-panel/message-panel.component';
import { ServerErrorManager } from 'src/app/ui/components/utils/server-error-manager.utils';

import { ChunkGeneric } from 'src/app/domain/views/chunk-generic.view';

import { GenericException } from 'src/app/domain/exceptions/generic.exception';
import { Provider } from 'src/app/business/models/provider/provider.model';
import { InvoiceDetails } from 'src/app/business/models/billing/invoice-details.model';
import { ProviderBillingInteractor } from 'src/app/domain/interactor/provider/billing.interactor';
import { ProfileInteractor } from 'src/app/domain/interactor/profile/profile.interactor';
import { NextBilling } from 'src/app/business/models/billing/next-billing.model';
import { NavigationConstants } from 'src/app/ui/commons/navigation.constants';
import { Router } from '@angular/router';
import { BillingUtils } from 'src/app/ui/utils/billing.utils';

import { FindInvoicesFilterView } from 'src/app/domain/views/billing/find-billing-filter.view';
import { NavigatorUtils } from 'src/app/ui/utils/navigator.utils';


@Component({
  selector: 'app-list-invoices-provider',
  templateUrl: './list.component.html'
})
export class ProviderListInvoicesComponent implements OnInit {

  @ViewChild(MessagePanelComponent)
  private messagePanelComponent: MessagePanelComponent;


  chunkInvoices: ChunkGeneric<InvoiceDetails>;
  canReceivePayments: boolean;
  nextBilling: NextBilling;

  constructor(
    private providerBillingInteractor: ProviderBillingInteractor,
    private profileInteractor: ProfileInteractor,
    private router: Router,
    private serverErrorManager: ServerErrorManager) {


  }



  ngOnInit() {

    this.findNextBilling();
    this.findProvider();

  }

  findProvider() {
    this.profileInteractor.getProviderDetails()
      .subscribe(
        (provider: Provider) => {
          this.canReceivePayments = provider.canReceivePayments;
        },
        (error: GenericException) => {
          this.serverErrorManager.manageErrorCode(error, this.messagePanelComponent);

        }
      )
  }


  onFindInvoicesTrigger(findInvoicesFilterView: FindInvoicesFilterView) {

    if (this.messagePanelComponent) {
      this.messagePanelComponent.showLoading();
    }

    this.providerBillingInteractor.findInvoices(findInvoicesFilterView.pageNumber)
      .subscribe(
        (chunkInvoices: ChunkGeneric<InvoiceDetails>) => {
          this.chunkInvoices = chunkInvoices;
          MessagePanelComponent.hideLoading();
        },
        (error: GenericException) => {
          MessagePanelComponent.hideLoading();
          this.serverErrorManager.manageErrorCode(error, this.messagePanelComponent);
        }
      )
  }


  findNextBilling() {
    this.providerBillingInteractor.findNextBilling()
      .subscribe(
        (nextBilling: NextBilling) => {
          this.nextBilling = nextBilling;
        },
        (error: GenericException) => {
          this.serverErrorManager.manageErrorCode(error, this.messagePanelComponent);
        }
      )
  }

  onInvoiceDetails(invoiceId: number) {

    this.router.navigate([NavigationConstants.getNavigationProviderInvoiceDetails(invoiceId)]);

  }

  onInvoiceDownload(invoiceDetails: InvoiceDetails) {

    this.messagePanelComponent.showLoading();

    this.providerBillingInteractor.invoiceDownload(invoiceDetails.id)
      .subscribe(
        (invoiceBlob: Blob) => {

          MessagePanelComponent.hideLoading();
          NavigatorUtils.downloadFile(invoiceBlob, BillingUtils.getInvoiceNameToDownload(invoiceDetails));

        },
        (error: GenericException) => {
          MessagePanelComponent.hideLoading();
          this.serverErrorManager.manageErrorCode(error, this.messagePanelComponent);
        }
      )
  }


  onGoToProfile() {
    this.router.navigate([NavigationConstants.getNavigationProviderProfile()]);
  }

}
