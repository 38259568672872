import { Component, OnInit, ViewChild } from '@angular/core';

import { MessagePanelComponent } from 'src/app/ui/components/shared/message-panel/message-panel.component';
import { ServerErrorManager } from 'src/app/ui/components/utils/server-error-manager.utils';

import { ChunkGeneric } from 'src/app/domain/views/chunk-generic.view';

import { GenericException } from 'src/app/domain/exceptions/generic.exception';
import { InvoiceDetails } from 'src/app/business/models/billing/invoice-details.model';
import { ProfileInteractor } from 'src/app/domain/interactor/profile/profile.interactor';
import { NextBilling } from 'src/app/business/models/billing/next-billing.model';
import { NavigationConstants } from 'src/app/ui/commons/navigation.constants';
import { Router } from '@angular/router';
import { BillingUtils } from 'src/app/ui/utils/billing.utils';

import { AffiliateBillingInteractor } from 'src/app/domain/interactor/affiliate/billing.interactor';
import { Affiliate } from 'src/app/business/models/affiliate/affiliate.model';
import { FindInvoicesFilterView } from 'src/app/domain/views/billing/find-billing-filter.view';
import { NavigatorUtils } from 'src/app/ui/utils/navigator.utils';
import { SessionInteractor } from 'src/app/domain/interactor/session.interactor';


@Component({
     selector: 'app-list-invoices-affiliate',
     templateUrl: './list.component.html'
})
export class AffiliateListInvoicesComponent implements OnInit {

     @ViewChild(MessagePanelComponent)
     private messagePanelComponent: MessagePanelComponent;


     chunkInvoices: ChunkGeneric<InvoiceDetails>;
     canReceivePayments: boolean;
     nextBilling: NextBilling;


     constructor(
          private affiliateBillingInteractor: AffiliateBillingInteractor,
          private profileInteractor: ProfileInteractor,
          private sessionInteractor: SessionInteractor,
          private router: Router,
          private serverErrorManager: ServerErrorManager) {

          }

          ngOnInit() {
               this.findAffiliateDetails();
               this.findNextBilling();
          }



          findAffiliateDetails() {
               this.profileInteractor.getAffiliateDetails()
               .subscribe(
                    (affiliate: Affiliate) => {
                         this.canReceivePayments = affiliate.canReceivePayments;
                    },
                    (error: GenericException) => {
                         this.serverErrorManager.manageErrorCode(error, this.messagePanelComponent);

                    }
               )
          }


          onFindInvoicesTrigger(findInvoicesFilterView: FindInvoicesFilterView) {

               if (this.messagePanelComponent) {
                    this.messagePanelComponent.showLoading();
               }

               this.affiliateBillingInteractor.findInvoices(findInvoicesFilterView.pageNumber)
               .subscribe(
                    (chunkInvoices: ChunkGeneric<InvoiceDetails>) => {

                         this.chunkInvoices = chunkInvoices;
                         MessagePanelComponent.hideLoading();
                    },
                    (error: GenericException) => {
                         MessagePanelComponent.hideLoading();
                         this.serverErrorManager.manageErrorCode(error, this.messagePanelComponent);
                    }
               )
          }


          findNextBilling() {
               this.affiliateBillingInteractor.findNextBilling()
               .subscribe(
                    (nextBilling: NextBilling) => {
                         this.nextBilling = nextBilling;
                    },
                    (error: GenericException) => {
                         this.serverErrorManager.manageErrorCode(error, this.messagePanelComponent);
                    }
               )
          }







          onInvoiceDetails(invoiceId: number) {

               this.router.navigate([NavigationConstants.getNavigationAffiliateInvoiceDetails(invoiceId)]);

          }

          onInvoiceDownload(invoiceDetails: InvoiceDetails) {

               this.messagePanelComponent.showLoading();

               this.affiliateBillingInteractor.invoiceDownload(invoiceDetails.id)
               .subscribe(
                    (invoiceBlob: Blob) => {

                         MessagePanelComponent.hideLoading();
                         NavigatorUtils.downloadFile(invoiceBlob, BillingUtils.getInvoiceNameToDownload(invoiceDetails));

                    },
                    (error: GenericException) => {
                         MessagePanelComponent.hideLoading();
                         this.serverErrorManager.manageErrorCode(error, this.messagePanelComponent);
                    }
               )
          }


          onGoToProfile() {
               if ( this.sessionInteractor.isProviderRole() ) {
                    this.router.navigate([NavigationConstants.getNavigationProviderProfile()]);
               } else {
                    this.router.navigate([NavigationConstants.getNavigationAffiliateProfile()]);
               }

          }

     }
