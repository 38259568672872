import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ProfileInteractor } from 'src/app/domain/interactor/profile/profile.interactor';
import { MasterInteractor } from 'src/app/domain/interactor/public/master.interactor';
import { Language } from 'src/app/business/models/master/language/language.model';
import { SessionInteractor } from 'src/app/domain/interactor/session.interactor';
import { NavigatorUtils } from 'src/app/ui/utils/navigator.utils';
import { marker as I18N } from '@biesbjerg/ngx-translate-extract-marker';
import { MessagePanelComponent } from 'src/app/ui/components/shared/message-panel/message-panel.component';
import { ServerErrorManager } from 'src/app/ui/components/utils/server-error-manager.utils';
import { FormUtils } from 'src/app/ui/components/utils/form.utils';
import { GenericException } from 'src/app/domain/exceptions/generic.exception';

@Component({
  selector: 'app-admin-profile-settings',
  templateUrl: './settings.component.html'
})
export class AdminProfileSettingsComponent implements OnInit {

  @ViewChild(MessagePanelComponent)
  private messagePanelComponent: MessagePanelComponent;


  languages: Array<Language>

  settingsForm: FormGroup
  isDisabled = true
  refreshValidators: boolean

  constructor(
    private profileInteractor: ProfileInteractor,
    private masterInteractor: MasterInteractor,
    private sessionInteractor: SessionInteractor,
    private serverErrorManager: ServerErrorManager) {

      this.refreshValidators = false;

      this.settingsForm = new FormGroup({
          'language': new FormControl({ value: this.sessionInteractor.getCurrentLanguage(), disabled: true }, [Validators.required] )
      })

  }



  ngOnInit() {

      this.masterInteractor.getLanguagesLocalized()
        .subscribe(
            (languages: Array<Language>) => {
                this.languages = languages
            },
            (error: GenericException) => {
                this.serverErrorManager.manageErrorCode(error, this.messagePanelComponent);
            }
        )
  }


  toggleEdition() {

        this.isDisabled = !this.isDisabled

        if ( this.isDisabled ) {
            FormUtils.disableAllControls(this.settingsForm)
        } else {
            FormUtils.enableAllControls(this.settingsForm)
        }
  }


  saveSettings() {

      this.refreshValidators = true;

      if ( this.settingsForm.valid ) {

        this.messagePanelComponent.showLoading();

        const languageCodeSelected = this.settingsForm.value.language;

        this.profileInteractor.updateAdminSettings(languageCodeSelected)
            .subscribe(
                () => {

                    MessagePanelComponent.hideLoading();

                    this.sessionInteractor.setCurrentLanguage( languageCodeSelected )
                    this.messagePanelComponent.showSuccessMessage(I18N('settingsprofile.Configuración actualizada'), () => NavigatorUtils.reloadApplication())

                    this.toggleEdition()

                },
                (error: GenericException) => {
                    this.serverErrorManager.manageErrorCode(error, this.messagePanelComponent);
                }
            )
      }

  }


}
