import { TourResume } from '../tour/tour-resume.model';
import { GuideState } from './common/guide-state.constants';

export class Guide {

  private _id: number;
  private _username: string;

  private _languageId: number;

  private _companyName: string;
  private _businessAddress: string;
  private _businessDocumentType: string;
  private _businessDocument: string;
  private _businessZeroVat: boolean;

  private _phone: string;

  private _countryId: number;
  private _countryName: string;

  private _cityId: number;
  private _cityName: string;

  private _paymentCurrency: string;
  private _iban: string;

  private _state: GuideState;

  private _managedTours: Array<TourResume>;

  private _resellerTours: Array<TourResume>;

  constructor(
    id: number,

    username: string,

    languageId: number,

    companyName: string,
    businessAddress: string,
    businessDocumentType: string,
    businessDocument: string,
    businessZeroVat: boolean,
    phone: string,

    countryId: number,
    countryName: string,

    cityId: number,
    cityName: string,

    paymentCurrency: string,
    iban: string,

    state: GuideState,

    managedTours: Array<TourResume>,

    resellerTours: Array<TourResume>,



  ) {

    this._id = id;

    this._username = username;

    this._languageId = languageId;

    this._companyName = companyName;
    this._businessAddress = businessAddress;
    this._businessDocumentType = businessDocumentType;
    this._businessDocument = businessDocument;
    this._businessZeroVat = businessZeroVat;

    this._phone = phone;

    this._countryId = countryId;
    this._countryName = countryName;

    this._cityId = cityId;
    this._cityName = cityName;

    this._paymentCurrency = paymentCurrency;
    this._iban = iban;

    this._state = state;

    this._managedTours = managedTours;

    this._resellerTours = resellerTours;

  }


  get id(): number {
    return this._id;
  }

  get username(): string {
    return this._username;
  }

  get languageId(): number {
    return this._languageId;
  }

  get companyName(): string {
    return this._companyName;
  }

  get businessAddress(): string {
       return this._businessAddress;
  }

  get businessDocumentType(): string {
      return this._businessDocumentType;
  }

  get businessDocument(): string {
     return this._businessDocument;
  }

  get businessZeroVat(): boolean {
     return this._businessZeroVat;
  }

  get phone(): string {
    return this._phone;
  }

  get countryId(): number {
    return this._countryId;
  }

  get countryName(): string {
    return this._countryName;
  }

  get cityId(): number {
    return this._cityId;
  }

  get cityName(): string {
    return this._cityName;
  }

  get paymentCurrency(): string {
    return this._paymentCurrency;
  }

  get iban(): string {
    return this._iban;
  }

  get state(): GuideState {
    return this._state;
  }

  get managedTours(): Array<TourResume> {
    return this._managedTours;
  }

  get resellerTours(): Array<TourResume> {
    return this._resellerTours;
  }

}
