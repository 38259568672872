import { Injectable } from '@angular/core';
import { Observable, Observer } from 'rxjs';
import { ProfileService } from 'src/app/business/services/profile/profile.service';
import { GenericException } from '../../exceptions/generic.exception';
import { ProviderDetailsView } from '../../views/provider/provider-details.view';
import { ImageDetails } from 'src/app/business/models/image/image-details.model';
import { Provider } from 'src/app/business/models/provider/provider.model';
import { ProfilePaymentView } from '../../views/profile-payment.view';
import { AffiliateDetailsView } from '../../views/affiliate/affiliate-details.view';
import { Affiliate } from 'src/app/business/models/affiliate/affiliate.model';
import { PaymentPlatform } from 'src/app/business/models/master/payment-platform/payment-platform.constants';
import { LoggedInUserView } from '../../views/logged-in-user.view';
import { SessionInteractor } from '../session.interactor';
import { Guide } from 'src/app/business/models/guide/guide.model';
import { GuideDetailsView } from '../../views/guide/guide-details.view';


@Injectable({
     providedIn: 'root'
})
export class ProfileInteractor {

     constructor(private profileService: ProfileService, private sessionInteractor: SessionInteractor) {
     }


     getBasicProfileDetails(): Observable<LoggedInUserView> {
          return Observable.create((observer: Observer<LoggedInUserView>) => {

               if ( this.sessionInteractor.isProviderRole() ) {
                    this.getProviderDetails()
                    .subscribe(
                         (provider: Provider) => {
                              observer.next(new LoggedInUserView(provider.id, provider.contactEmail, provider.companyName, provider.guidesManagement));
                              observer.complete();
                         },
                         () => {
                              observer.next(null);
                              observer.complete();
                         }
                    )
               } else if ( this.sessionInteractor.isAffiliateRole() ) {
                    this.getAffiliateDetails()
                    .subscribe(
                         (affiliate: Affiliate) => {
                              observer.next(new LoggedInUserView(affiliate.id, affiliate.username, affiliate.companyName, null));
                              observer.complete();
                         },
                         () => {
                              observer.next(null);
                              observer.complete();
                         }
                    )
               } else if ( this.sessionInteractor.isGuideRole() ) {
                    this.getGuideDetails()
                    .subscribe(
                         (guide: Guide) => {
                              observer.next(new LoggedInUserView(guide.id, guide.username, guide.companyName, null));
                              observer.complete();
                         },
                         () => {
                              observer.next(null);
                              observer.complete();
                         }
                    )
               } else {
                    observer.next(null);
                    observer.complete();
               }

          });
     }

     //PROVIDER
     getProviderDetails(): Observable<Provider> {
          return Observable.create((observer: Observer<Provider>) => {
               this.profileService.getProviderDetails()
               .subscribe(
                    (provider: Provider) => {
                         observer.next(provider);
                         observer.complete();
                    },
                    (error: GenericException) => {
                         observer.error(error);
                    }
               )

          });
     }

     changePassword(oldPassword: string, newPassword: string, newPasswordConfirm: string): Observable<boolean> {

          return Observable.create((observer: Observer<boolean>) => {
               this.profileService.changePassword(oldPassword, newPassword, newPasswordConfirm)
               .subscribe(
                    () => {
                         observer.next(true);
                         observer.complete();
                    },
                    (error: GenericException) => {
                         observer.error(error);
                    }
               )

          });
     }



     updateProviderDetails(providerDetails: ProviderDetailsView): Observable<boolean> {

          return Observable.create((observer: Observer<boolean>) => {
               this.profileService.updateProviderDetails(
                    providerDetails.companyName,
                    providerDetails.web, providerDetails.phone, providerDetails.country,
                    providerDetails.city, providerDetails.contactPerson, providerDetails.contactEmail)
                    .subscribe(
                         () => {
                              observer.next(true);
                              observer.complete();
                         },
                         (error: GenericException) => {
                              observer.error(error);
                         }
                    )

               }
          );
     }




     updateProviderPayment(providerPayment: ProfilePaymentView): Observable<boolean> {

          return Observable.create((observer: Observer<boolean>) => {
               this.profileService.updateProviderPayment(
                    providerPayment.businessName, providerPayment.businessDocumentType,
                    providerPayment.businessDocument, providerPayment.businessAddress, providerPayment.businessZeroVat)
                    .subscribe(
                         () => {
                              observer.next(true);
                              observer.complete();
                         },
                         (error: GenericException) => {
                              observer.error(error);
                         }
                    )

               }
          );
     }


     updateProviderSettings(languageCode: string, bookingNotificationEmailActive: boolean, bookingNotificationEmail: string, bookingNotificationEmail2: string, paymentPlatform: PaymentPlatform, paymentCurrency: string, iban: string): Observable<boolean> {

          return Observable.create((observer: Observer<boolean>) => {
               this.profileService.updateProviderSettings(languageCode, bookingNotificationEmailActive, bookingNotificationEmail, bookingNotificationEmail2, paymentPlatform, paymentCurrency, iban)
               .subscribe(
                    () => {
                         observer.next(true);
                         observer.complete();
                    },
                    (error: GenericException) => {
                         observer.error(error);
                    }
               )

          });
     }



     updateAffiliateSettings(languageCode: string, paymentCurrency: string, iban: string): Observable<boolean> {

          return Observable.create((observer: Observer<boolean>) => {
               this.profileService.updateAffiliateSettings(languageCode, paymentCurrency, iban)
               .subscribe(
                    () => {
                         observer.next(true);
                         observer.complete();
                    },
                    (error: GenericException) => {
                         observer.error(error);
                    }
               )

          });
     }


     updateAdminSettings(languageCode: string): Observable<boolean> {

          return Observable.create((observer: Observer<boolean>) => {
               this.profileService.updateAdminSettings(languageCode)
               .subscribe(
                    () => {
                         observer.next(true);
                         observer.complete();
                    },
                    (error: GenericException) => {
                         observer.error(error);
                    }
               )

          });
     }


     updateAffiliateWebsites(websites: Array<string>): Observable<boolean> {

          return Observable.create((observer: Observer<boolean>) => {
               this.profileService.updateAffiliateWebsites(websites)
               .subscribe(
                    () => {
                         observer.next(true);
                         observer.complete();
                    },
                    (error: GenericException) => {
                         observer.error(error);
                    }
               )

          });
     }



     requestProviderUnregister(): Observable<boolean> {

          return Observable.create((observer: Observer<boolean>) => {
               this.profileService.requestProviderUnregister()
               .subscribe(
                    () => {
                         observer.next(true);
                         observer.complete();
                    },
                    (error: GenericException) => {
                         observer.error(error);
                    }
               )

          });
     }



     requestAffiliateUnregister(): Observable<boolean> {

          return Observable.create((observer: Observer<boolean>) => {
               this.profileService.requestAffiliateUnregister()
               .subscribe(
                    () => {
                         observer.next(true);
                         observer.complete();
                    },
                    (error: GenericException) => {
                         observer.error(error);
                    }
               )

          });
     }

     uploadLogo(image: File) {
          return Observable.create((observer: Observer<ImageDetails>) => {
               this.profileService.uploadLogo(image)
               .subscribe(
                    (tourImage: ImageDetails) => {
                         observer.next(tourImage);
                         observer.complete();
                    },
                    (error: GenericException) => {
                         observer.error(error);
                    }
               )

          });
     }


     deleteLogo(imageId: number) {
          return Observable.create((observer: Observer<boolean>) => {
               this.profileService.deleteLogo(imageId)
               .subscribe(
                    () => {
                         observer.next(true);
                         observer.complete();
                    },
                    (error: GenericException) => {
                         observer.error(error);
                    }
               )

          });
     }
     //END PROVIDER






     //AFFILIATE
     getAffiliateDetails() {
          return Observable.create((observer: Observer<Affiliate>) => {
               this.profileService.getAffiliateDetails()
               .subscribe(
                    (affiliate: Affiliate) => {
                         observer.next(affiliate);
                         observer.complete();
                    },
                    (error: GenericException) => {
                         observer.error(error);
                    }
               )

          });
     }


     updateAffiliateDetails(affiliteDetails: AffiliateDetailsView): Observable<boolean> {

          return Observable.create((observer: Observer<boolean>) => {
               this.profileService.updateAffiliateDetails(
                    affiliteDetails.companyName,affiliteDetails.websites,
                    affiliteDetails.phone, affiliteDetails.country, affiliteDetails.city,
                    affiliteDetails.address, affiliteDetails.businessDocumentType, affiliteDetails.businessDocument, affiliteDetails.businessZeroVat)
                    .subscribe(
                         () => {
                              observer.next(true);
                              observer.complete();
                         },
                         (error: GenericException) => {
                              observer.error(error);
                         }
                    )

               }
          );
     }
     //END AFFILIATE




     //GUIDE
     getGuideDetails() {
          return Observable.create((observer: Observer<Guide>) => {
               this.profileService.getGuideDetails(this.sessionInteractor.getCurrentLanguage())
               .subscribe(
                    (guide: Guide) => {
                         observer.next(guide);
                         observer.complete();
                    },
                    (error: GenericException) => {
                         observer.error(error);
                    }
               )

          });
     }

     updateGuideDetails(guideDetails: GuideDetailsView): Observable<boolean> {

          return Observable.create((observer: Observer<boolean>) => {
               this.profileService.updateGuideDetails(
                    guideDetails.bussinesName, guideDetails.phone,
                    guideDetails.country, guideDetails.city)
                    .subscribe(
                         () => {
                              observer.next(true);
                              observer.complete();
                         },
                         (error: GenericException) => {
                              observer.error(error);
                         }
                    )

               }
          );
     }

     updateGuideSettings(languageCode: string, paymentCurrency: string, iban: string): Observable<boolean> {

          return Observable.create((observer: Observer<boolean>) => {
               this.profileService.updateGuideSettings(languageCode, paymentCurrency, iban)
               .subscribe(
                    () => {
                         observer.next(true);
                         observer.complete();
                    },
                    (error: GenericException) => {
                         observer.error(error);
                    }
               )

          });
     }
     //END GUIDE





     //STRIPE
     createProviderStripeAccount(): Observable<string> {

          return Observable.create((observer: Observer<string>) => {
               this.profileService.createProviderStripeAccount()
               .subscribe(
                    (stripeAccountUrl: string) => {
                         observer.next(stripeAccountUrl);
                         observer.complete();
                    },
                    (error: GenericException) => {
                         observer.error(error);
                    }
               )

          });
     }


     createAffiliateStripeAccount(): Observable<string> {

          return Observable.create((observer: Observer<string>) => {
               this.profileService.createAffiliateStripeAccount()
               .subscribe(
                    (stripeAccountUrl: string) => {
                         observer.next(stripeAccountUrl);
                         observer.complete();
                    },
                    (error: GenericException) => {
                         observer.error(error);
                    }
               )

          });
     }

     connectProviderStripeAccount(): Observable<string> {

          return Observable.create((observer: Observer<string>) => {
               this.profileService.connectProviderStripeAccount()
               .subscribe(
                    (stripeAccountUrl: string) => {
                         observer.next(stripeAccountUrl);
                         observer.complete();
                    },
                    (error: GenericException) => {
                         observer.error(error);
                    }
               )

          });
     }

     connectAffiliateStripeAccount(): Observable<string> {

          return Observable.create((observer: Observer<string>) => {
               this.profileService.connectAffiliateStripeAccount()
               .subscribe(
                    (stripeAccountUrl: string) => {
                         observer.next(stripeAccountUrl);
                         observer.complete();
                    },
                    (error: GenericException) => {
                         observer.error(error);
                    }
               )

          });
     }




     removeProviderStripeAccount(): Observable<boolean> {

          return Observable.create((observer: Observer<boolean>) => {
               this.profileService.removeProviderStripeAccount()
               .subscribe(
                    () => {
                         observer.next(true);
                         observer.complete();
                    },
                    (error: GenericException) => {
                         observer.error(error);
                    }
               )

          });
     }


     removeAffiliateStripeAccount(): Observable<boolean> {

          return Observable.create((observer: Observer<boolean>) => {
               this.profileService.removeAffiliateStripeAccount()
               .subscribe(
                    () => {
                         observer.next(true);
                         observer.complete();
                    },
                    (error: GenericException) => {
                         observer.error(error);
                    }
               )

          });
     }


     updateProviderStripeAccount(stripeAccountId: string): Observable<boolean> {

          return Observable.create((observer: Observer<boolean>) => {
               this.profileService.updateProviderStripeAccount(stripeAccountId)
               .subscribe(
                    () => {
                         observer.next(true);
                         observer.complete();
                    },
                    (error: GenericException) => {
                         observer.error(error);
                    }
               )

          });
     }


     updateAffiliateStripeAccount(stripeAccountId: string): Observable<boolean> {

          return Observable.create((observer: Observer<boolean>) => {
               this.profileService.updateAffiliateStripeAccount(stripeAccountId)
               .subscribe(
                    () => {
                         observer.next(true);
                         observer.complete();
                    },
                    (error: GenericException) => {
                         observer.error(error);
                    }
               )

          });
     }
     //END STRIPE




}
