//ng build --prod --aot --outputHashing=all
export const environment = {
  production: true,
  APP_BASE_URL: 'https://api.booqlever.com/api',
  APP_PAGE_TITLE: 'ReservaFreeTour',
  ACCESS_TOKEN_CLIENT_ANGULAR: 'eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJjbGllbnRBbmd1bGFyIiwicm9sZSI6IkNMSUVOVF9BTkdVTEFSIiwidG9rZW5fdHlwZSI6ImFjY2Vzc190b2tlbiIsImxhbmd1YWdlQ29kZSI6ImVzIiwiaWF0IjoxNjE0MjcyNzgzLCJ1c2VybmFtZSI6ImNsaWVudEFuZ3VsYXIifQ.n9YoZlMGs4gyUaeYtHqs0sI_Uj8d71WXpaJzsJxUKV-Avct-sB4AIMsV0sWZh-1JD0-ZQsJ5kZkrwCZNeJ0sog',
  STRIPE_API_KEY: 'pk_live_51LgoXTIvnYFiHmy6Mwl1pafhCDiMEyVz7HP3dgI55R7kn9E7z77ehRRETnBTmPpqmVxz9ckaYGXnkIq0LMrMlhYY00G7m71W7Z',
  WIDGET_URL: 'https://widget.reservafreetour.com',
  WIDGET_API_KEY: 'eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJjbGllbnRSRlQiLCJyb2xlIjoiQ0xJRU5UIiwidG9rZW5fdHlwZSI6ImFjY2Vzc190b2tlbiIsImxhbmd1YWdlQ29kZSI6ImVzIiwiaWF0IjoxNTk4ODU4NTE0LCJ1c2VybmFtZSI6ImNsaWVudFJGVCJ9.toketkMFDekSxJbuu86sx7byCb6ZgeKhXtZa8QZqPrzU6WNomULgddrn7u-gqPRJ34cBBpgeVETtjX5_Ej59IA',
  PRIMARY_COLOR: '#FF5050'
};
