import { Injectable }                       from '@angular/core';
import { Observable, Observer } from 'rxjs';
import { GenericException } from '../../exceptions/generic.exception';
import { ChunkGeneric } from '../../views/chunk-generic.view';
import { SessionInteractor } from '../session.interactor';
import { AffiliateTourService } from 'src/app/business/services/affiliate/tour.service';
import { TourResumeAffiliate } from 'src/app/business/models/tour/tour-resume-affiliate.model';


@Injectable({
     providedIn: 'root'
})
export class AffiliateTourInteractor {

     constructor(private affiliateTourService: AffiliateTourService, private sessionInteractor: SessionInteractor ) {
     }

     findTours(search: string, countryId: number, cityId: number, page: number): Observable<ChunkGeneric<TourResumeAffiliate>> {

          return Observable.create((observer: Observer<ChunkGeneric<TourResumeAffiliate>>) => {
               this.affiliateTourService.findTours(this.sessionInteractor.getCurrentLanguage(), search, countryId, cityId, page)
               .subscribe(
                    (chunkTours: ChunkGeneric<TourResumeAffiliate>) => {

                         observer.next(chunkTours);
                         observer.complete();
                    },
                    (error: GenericException) => {
                         observer.error(error);
                    }
               )

          });

     }

     generateCustomLink(link: string): Observable<string> {

          return Observable.create((observer: Observer<string>) => {
               this.affiliateTourService.generateCustomLink(link)
               .subscribe(
                    (linkGenerated: string) => {

                         observer.next(linkGenerated);
                         observer.complete();
                    },
                    (error: GenericException) => {
                         observer.error(error);
                    }
               )

          });

     }

}
