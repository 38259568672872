import { Language } from '../master/language/language.model';
import { City } from '../master/city/city.model';
import { Country } from '../master/country/country.model';
import { TourState } from './common/tour-state.constants';
import { TourAffiliateLink } from './tour-affiliate-link.model';

export class TourResumeAffiliate {

    private _id: number;
    private _name: string;
    private _nameMain: string;
    private _descriptionShort: string;
    private _descriptionShortMain: string;
    private _languageTourGiven: Language;
    private _country: Country;
    private _city: City;
    private _isFree: boolean;
    private _commission: number;
    private _affiliateLinks: Array<TourAffiliateLink>;
    private _state: TourState;


    constructor(
        id: number,
        name: string,
        nameMain: string,
        descriptionShort: string,
        descriptionShortMain: string,
        languageTourGiven: Language,
        country: Country,
        city: City,
        isFree: boolean,
        commission: number,
        affiliateLinks: Array<TourAffiliateLink>,
        state: TourState
    ) {
        this._id = id;
        this._name = name;
        this._nameMain = nameMain;
        this._descriptionShort = descriptionShort;
        this._descriptionShortMain = descriptionShortMain;
        this._languageTourGiven = languageTourGiven;
        this._country = country;
        this._city = city;
        this._isFree = isFree;
        this._commission = commission;
        this._affiliateLinks = affiliateLinks;
        this._state = state;

    }


    get id(): number {
      return this._id;
    }

    set id(id: number) {
      this._id = id;
    }

    get name(): string {
      return this._name;
    }

    set name(name: string) {
      this._name = name;
    }

    get nameMain(): string {
      return this._nameMain;
    }

    set nameMain(nameMain: string) {
      this._nameMain = nameMain;
    }

    get descriptionShort(): string {
      return this._descriptionShort;
    }

    set descriptionShort(descriptionShort: string) {
      this._descriptionShort = descriptionShort;
    }

    get descriptionShortMain(): string {
      return this._descriptionShortMain;
    }

    set descriptionShortMain(descriptionShortMain: string) {
      this._descriptionShortMain = descriptionShortMain;
    }

    get languageTourGiven(): Language {
      return this._languageTourGiven;
    }

    set languageTourGiven(languageTourGiven: Language) {
      this._languageTourGiven = languageTourGiven;
    }

    get country(): Country {
      return this._country;
    }

    set country(country: Country) {
      this._country = country;
    }

    get city(): City {
      return this._city;
    }

    set city(city: City) {
      this._city = city;
    }

    get isFree(): boolean {
      return this._isFree;
    }

    set isFree(isFree: boolean) {
      this._isFree = isFree;
    }

    get commission(): number {
      return this._commission;
    }

    set commission(commission: number) {
      this._commission = commission;
    }

    get affiliateLinks(): Array<TourAffiliateLink> {
      return this._affiliateLinks;
    }

    set affiliateLinks(affiliateLinks: Array<TourAffiliateLink>) {
      this._affiliateLinks = affiliateLinks;
    }

    get state(): TourState {
      return this._state;
    }

    set state(state: TourState) {
      this._state = state;
    }


}
