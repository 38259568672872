import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { ProviderResume } from 'src/app/business/models/provider/provider-resume.model';
import { Pagination } from 'src/app/domain/views/pagination.view';

@Component({
  selector: 'app-providers-table',
  templateUrl: './table.component.html'
})
export class ProvidersTableComponent implements OnInit {

  @Input() providers: Array<ProviderResume>

  @Input() pagination: Pagination

  @Output() providerTrigger = new EventEmitter<number>();

  constructor() { }

  ngOnInit() {

  }

  onProviderClick(providerId: number ) {
      this.providerTrigger.emit(providerId);
  }


}
