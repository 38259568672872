import { Country } from '../master/country/country.model';
import { City } from '../master/city/city.model';
import { Language } from '../master/language/language.model';
import { ImageDetails } from '../image/image-details.model';
import { Book } from './book.model';

export class EventDetails {

  private _tourId: number;
  private _name: string;
  private _languageTourGiven: Language;
  private _country: Country;
  private _city: City;
  private _isFree: boolean;
  private _image: ImageDetails;
  private _day: string;
  private _hour: string;
  private _people: number;
  private _shows: number;
  private _bookings: Array<Book>;
  private _showsCanBeUpdated: boolean;
  private _bookingsCanBeCancelled: boolean;


  constructor(
    tourId: number,
    name: string,
    languageTourGiven: Language,
    country: Country,
    city: City,
    isFree: boolean,
    image: ImageDetails,
    day: string,
    hour: string,
    people: number,
    shows: number,
    bookings: Array<Book>,
    showsCanBeUpdated: boolean,
    bookingsCanBeCancelled: boolean
  ) {
    this._tourId = tourId;
    this._name = name;
    this._languageTourGiven = languageTourGiven;
    this._country = country;
    this._city = city;
    this._isFree = isFree;
    this._image = image;
    this._day = day;
    this._hour = hour;
    this._people = people;
    this._shows = shows;
    this._bookings = bookings;
    this._showsCanBeUpdated = showsCanBeUpdated;
    this._bookingsCanBeCancelled = bookingsCanBeCancelled;
  }


  get tourId(): number {
    return this._tourId;
  }

  get name(): string {
    return this._name;
  }

  get languageTourGiven(): Language {
    return this._languageTourGiven;
  }

  get country(): Country {
    return this._country;
  }

  get city(): City {
    return this._city;
  }

  get isFree(): boolean {
    return this._isFree;
  }

  get image(): ImageDetails {
    return this._image;
  }

  get day(): string {
    return this._day;
  }

  get hour(): string {
    return this._hour;
  }

  get people(): number {
    return this._people;
  }

  get shows(): number {
    return this._shows;
  }

  set shows(shows: number) {
    this._shows = shows;
  }

  get bookings(): Array<Book> {
    return this._bookings;
  }

  set bookings(bookings: Array<Book>) {
    this._bookings = bookings;
  }

  get showsCanBeUpdated(): boolean {
    return this._showsCanBeUpdated;
  }

  get bookingsCanBeCancelled(): boolean {
   return this._bookingsCanBeCancelled;
  }


}