<div class="row bg-grey-color header-simple">
    <div class="col-7 col-md-10">
        <a (click)="goToHomeLanding()"><img class="logo pr-4 pr-lg-5 mb-4 mb-md-auto" src="assets/img/logo-white.png"></a>
        <app-public-navigation></app-public-navigation>
    </div>
</div>
<div class="row section-sign-up-bg">
    <div class="col-12 col-lg-8 offset-lg-2 text-left text-lg-center px-auto px-lg-3 px-xl-5 white-color dark-lg-color">
        <h1>{{ 'singup.Completa tu alta como proveedor' | translate }}</h1>
    </div>
</div>
<div class="animated fadeIn row row-overlay">
    <div class="col-12 col-lg-8 offset-lg-2 card card-primary">

        <form class="form-underline m-0 p-0" [formGroup]="providerFullForm">

            <div class="row mb-2">
                <div class="col-12">
                    <h2 class="font-weight-bold"><img class="ic-form-sign-up mr-2" src="assets/img/ic_lock-open.svg">{{ 'singup.Datos de acceso' | translate }}</h2>
                    <h5>{{ 'singup.Contraseña de autenticación' | translate }}</h5>
                    <hr class="w-10 float-left">
                </div>
            </div>

            <div class="row mb-5 pb-5">
                <div class="col-12 col-md-6">
                    <div class="field pb-2">
                        <input formControlName="password" type="password" autocomplete="new-password" name="newPassword" id="newPassword" placeholder="{{ 'singup.Nueva contraseña' | translate }}" value="">
                        <label for="newPassword">{{ 'singup.Contraseña' | translate }}</label>
                    </div>
                    <app-feedback [formGroup]="providerFullForm" field="password" validator="required" feedback="{{ 'singup.Contraseña nueva requerida' | translate }}" [refresh]="refreshValidators"></app-feedback>
                    <app-feedback [formGroup]="providerFullForm" field="password" validator="maxlength" feedback="{{ 'createtourdetails.Texto demasiado largo' | translate }}" [refresh]="refreshValidators"></app-feedback>
                </div>
                <div class="col-12 col-md-6">
                    <div class="field pb-2">
                        <input formControlName="retryPassword" type="password" autocomplete="new-password" name="retryPassword" id="retryPassword" placeholder="{{ 'singup.Confirmar contraseña' | translate }}" value="">
                        <label for="retryPassword">{{ 'singup.Confirmar contraseña' | translate }}</label>
                    </div>
                    <app-feedback [formGroup]="providerFullForm" field="retryPassword" validator="required" feedback="{{ 'singup.Confirmación de contraseña requerida' | translate }}" [refresh]="refreshValidators"></app-feedback>
                    <app-feedback [formGroup]="providerFullForm" field="retryPassword" validator="noequals" feedback="{{ 'singup.Contraseña nueva y su confirmación no coinciden' | translate }}" [refresh]="refreshValidators"></app-feedback>
                    <app-feedback [formGroup]="providerFullForm" field="retryPassword" validator="maxlength" feedback="{{ 'createtourdetails.Texto demasiado largo' | translate }}" [refresh]="refreshValidators"></app-feedback>
                </div>
            </div>



            <!-- settings -->
            <div class="row mb-2">
                <div class="col-10">
                    <h2 class="font-weight-bold d-flex">
                        <img class="ic-mid mr-2" src="assets/img/ic_settings.svg">{{ 'singup.Configuración' | translate }}
                    </h2>
                    <h5>{{ 'singup.Ajustes de la plataforma' | translate }}</h5>
                    <hr class="w-10 float-left mt-2 mb-4">
                </div>

            </div>

            <div class="row mb-5 pb-5">
                <div class="col-12 col-md-6">
                    <div class="field pb-2">
                        <select formControlName="language" name="language">
                            <option *ngFor="let language of languages" value="{{language.id}}">{{language.name}}</option>
                        </select>
                        <label for="language">{{ 'singup.Idioma' | translate }}</label>
                    </div>
                    <app-feedback [formGroup]="providerFullForm" field="language" validator="required" feedback="{{ 'singup.Idioma requerido' | translate }}" [refresh]="refreshValidators"></app-feedback>
                </div>
            </div>




            <!-- Empresa -->
            <div class="row mb-2">
                <div class="col-10">
                    <h2 class="font-weight-bold d-flex"><img class="ic-mid mr-2" src="assets/img/ic_user.svg">{{ 'singup.Datos de la empresa' | translate }}</h2>
                    <h5>{{ 'singup.Información de contacto y notificaciones' | translate }}</h5>
                    <hr class="w-10 float-left mt-2 mb-4">
                </div>
            </div>

            <div class="row mb-5 pb-5">

                <div class="col-12 col-md-6">
                    <div class="field pb-2">
                        <input formControlName="web" type="text" name="web" id="web" placeholder="{{ 'singup.Web' | translate }}" value="">
                        <label for="web">{{ 'singup.Web' | translate }}</label>
                    </div>
                    <app-feedback [formGroup]="providerFullForm" field="web" validator="required" feedback="{{ 'singup.Web requerida' | translate }}" [refresh]="refreshValidators"></app-feedback>
                    <app-feedback [formGroup]="providerFullForm" field="web" validator="maxlength" feedback="{{ 'createtourdetails.Texto demasiado largo' | translate }}" [refresh]="refreshValidators"></app-feedback>
                    <app-feedback [formGroup]="providerFullForm" field="web" validator="pattern" feedback="{{ 'singup.Indica una URL válida' | translate }}" [refresh]="refreshValidators"></app-feedback>
                </div>

                <div class="col-12 col-md-6">
                    <div class="field pb-2">
                        <input formControlName="phone" type="phone" name="phone" id="phone" placeholder="{{ 'singup.Teléfono' | translate }}" value="">
                        <label for="phone">{{ 'singup.Teléfono' | translate }}</label>
                    </div>
                    <app-feedback [formGroup]="providerFullForm" field="phone" validator="required" feedback="{{ 'singup.Teléfono requerido' | translate }}" [refresh]="refreshValidators"></app-feedback>
                    <app-feedback [formGroup]="providerFullForm" field="phone" validator="maxlength" feedback="{{ 'createtourdetails.Texto demasiado largo' | translate }}" [refresh]="refreshValidators"></app-feedback>
                </div>
                <div class="col-12 col-md-6">
                    <div class="field pb-2">
                        <select formControlName="country" name="country" (change)="onCountrySelected()">
                            <option [ngValue]="null">{{ 'singup.Selecciona la opción' | translate }}</option>
                            <option *ngFor="let country of countries" value="{{ country.id }}">{{ country.name }}</option>
                        </select>
                        <label for="country">{{ 'singup.País' | translate }}</label>
                    </div>
                    <app-feedback [formGroup]="providerFullForm" field="country" validator="required" feedback="{{ 'singup.País requerido' | translate }}" [refresh]="refreshValidators"></app-feedback>
                </div>
                <div class="col-12 col-md-6">
                    <div class="field pb-2">
                        <select formControlName="city" name="city">
                          <option [ngValue]="null">{{ 'singup.Selecciona la opción' | translate }}</option>
                          <option *ngFor="let city of cities" value="{{ city.id }}">{{ city.name }}</option>
                      </select>
                        <label for="city">{{ 'singup.Ciudad' | translate }}</label>
                    </div>
                    <app-feedback [formGroup]="providerFullForm" field="city" validator="required" feedback="{{ 'singup.Ciudad requerida' | translate }}" [refresh]="refreshValidators"></app-feedback>
                </div>
                <div class="col-12">
                    <div class="field pb-2">
                        <input formControlName="contactPerson" type="text" name="contactPerson" id="contactPerson" placeholder="{{ 'singup.Persona de contacto' | translate }}" value="">
                        <label for="contactPerson">{{ 'singup.Persona de contacto' | translate }}</label>
                    </div>
                    <app-feedback [formGroup]="providerFullForm" field="contactPerson" validator="required" feedback="{{ 'singup.Persona de contacto requerida' | translate }}" [refresh]="refreshValidators"></app-feedback>
                    <app-feedback [formGroup]="providerFullForm" field="contactPerson" validator="maxlength" feedback="{{ 'createtourdetails.Texto demasiado largo' | translate }}" [refresh]="refreshValidators"></app-feedback>
                </div>
                <div class="col-12 col-md-6">
                    <div class="field pb-2">
                        <input formControlName="contactEmail" type="text" name="contactEmail" id="contactEmail" placeholder="{{ 'singup.Email de contacto' | translate }}" value="">
                        <label for="contactEmail">{{ 'singup.Email de contacto' | translate }}</label>
                    </div>
                    <app-feedback [formGroup]="providerFullForm" field="contactEmail" validator="required" feedback="{{ 'singup.Email de contacto requerido' | translate }}" [refresh]="refreshValidators"></app-feedback>
                    <app-feedback [formGroup]="providerFullForm" field="contactEmail" validator="email" feedback="{{ 'singup.Email incorrecto' | translate }}" [refresh]="refreshValidators"></app-feedback>
                    <app-feedback [formGroup]="providerFullForm" field="contactEmail" validator="maxlength" feedback="{{ 'createtourdetails.Texto demasiado largo' | translate }}" [refresh]="refreshValidators"></app-feedback>
                </div>
                <div class="col-12 col-md-6">
                    <div class="field pb-2">
                        <input formControlName="bookingNotificationEmail" type="text" name="bookingNotificationEmail" id="bookingNotificationEmail" placeholder="{{ 'singup.Email para notificaciones' | translate }}">
                        <label for="bookingNotificationEmail">{{ 'singup.Email para notificaciones' | translate }}</label>
                    </div>
                    <app-feedback [formGroup]="providerFullForm" field="bookingNotificationEmail" validator="required" feedback="{{ 'singup.Email de notificaciones requerido' | translate }}" [refresh]="refreshValidators"></app-feedback>
                    <app-feedback [formGroup]="providerFullForm" field="bookingNotificationEmail" validator="email" feedback="{{ 'singup.Email incorrecto' | translate }}" [refresh]="refreshValidators"></app-feedback>
                    <app-feedback [formGroup]="providerFullForm" field="bookingNotificationEmail" validator="maxlength" feedback="{{ 'createtourdetails.Texto demasiado largo' | translate }}" [refresh]="refreshValidators"></app-feedback>
                </div>

                <div class="col-12 col-md-6">
                    <div class="field pb-2">
                        <input formControlName="bookingNotificationEmail2" type="text" name="bookingNotificationEmail2" id="bookingNotificationEmail2" placeholder="{{ 'singup.Email para notificaciones adicional' | translate }}">
                        <label for="bookingNotificationEmail">{{ 'singup.Email para notificaciones adicional' | translate }}</label>
                    </div>
                    <app-feedback [formGroup]="providerFullForm" field="bookingNotificationEmail2" validator="email" feedback="{{ 'singup.Email incorrecto' | translate }}" [refresh]="refreshValidators"></app-feedback>
                    <app-feedback [formGroup]="providerFullForm" field="bookingNotificationEmail2" validator="maxlength" feedback="{{ 'createtourdetails.Texto demasiado largo' | translate }}" [refresh]="refreshValidators"></app-feedback>
                </div>

                <div class="col-12 col-md-6">
                    <div class="field pb-2">
                        <div class="custom-control custom-checkbox mb-2 mt-4">
                            <input formControlName="bookingNotificationEmailActive" type="checkbox" class="custom-control-input" id="bookingNotificationEmailActive" name="bookingNotificationEmailActive" value="">
                            <label class="custom-control-label" for="bookingNotificationEmailActive">{{ 'singup.Recibir notificaciones de reservas' | translate }}</label>
                        </div>
                    </div>
                </div>
            </div>



            <div class="row mb-2">
                <div class="col-10">
                    <h2 class="font-weight-bold d-flex">
                        <img class="ic-mid mr-2" src="assets/img/ic_verified_user.svg">{{ 'singup.Datos fiscales' | translate }}
                    </h2>
                    <h5>{{ 'singup.Información fiscal' | translate }}</h5>
                    <hr class="w-10 float-left mt-2 mb-4">
                </div>
            </div>

            <div class="row mb-3">
                <div class="col-12">
                    <div class="field pb-2">
                        <input formControlName="businessName" type="text" name="businessName" id="businessName" placeholder="{{ 'singup.Nombre fiscal' | translate }}" value="">
                        <label for="businessName">{{ 'singup.Nombre fiscal' | translate }}</label>
                    </div>
                    <app-feedback [formGroup]="providerFullForm" field="businessName" validator="required" feedback="{{ 'singup.Nombre fiscal requerido' | translate }}" [refresh]="refreshValidators"></app-feedback>
                    <app-feedback [formGroup]="providerFullForm" field="businessName" validator="maxlength" feedback="{{ 'createtourdetails.Texto demasiado largo' | translate }}" [refresh]="refreshValidators"></app-feedback>
                </div>

                <div class="col-12 col-md-6">
                    <div class="field pb-2">
                        <select formControlName="businessDocumentType" name="businessDocumentType">
                            <option value="">{{ 'providerpayment.Selecciona la opción' | translate }}</option>
                            <option value="CIF">{{ 'providerpayment.CIF' | translate }}</option>
                            <option value="VAT">{{ 'providerpayment.VAT' | translate }}</option>
                         </select>
                        <label for="businessDocumentType">{{ 'singup.Tipo de documento' | translate }}</label>
                    </div>
                    <app-feedback [formGroup]="providerFullForm" field="businessDocumentType" validator="required" feedback="{{ 'singup.Tipo de documento requerido' | translate }}" [refresh]="refreshValidators"></app-feedback>
                </div>

                <div class="col-12 col-md-6">
                    <div class="field pb-2">
                        <input formControlName="businessDocument" type="text" name="businessDocument" id="businessDocument" placeholder="{{ 'singup.Número de documento' | translate }}" value="">
                        <label for="businessDocument">{{ 'singup.Número de documento' | translate }}</label>
                    </div>
                    <app-feedback [formGroup]="providerFullForm" field="businessDocument" validator="required" feedback="{{ 'singup.Número de documento requerido' | translate }}" [refresh]="refreshValidators"></app-feedback>
                    <app-feedback [formGroup]="providerFullForm" field="businessDocument" validator="maxlength" feedback="{{ 'createtourdetails.Texto demasiado largo' | translate }}" [refresh]="refreshValidators"></app-feedback>
                </div>
                <div class="col-12 col-md-6">
                    <div class="field pb-2">
                        <input formControlName="businessAddress" type="text" name="businessAddress" id="businessAddress" placeholder="{{ 'singup.Dirección fiscal' | translate }}" value="">
                        <label for="businessAddress">{{ 'singup.Dirección fiscal' | translate }}</label>
                    </div>
                    <app-feedback [formGroup]="providerFullForm" field="businessAddress" validator="required" feedback="{{ 'singup.Dirección fiscal requerida' | translate }}" [refresh]="refreshValidators"></app-feedback>
                    <app-feedback [formGroup]="providerFullForm" field="businessAddress" validator="maxlength" feedback="{{ 'createtourdetails.Texto demasiado largo' | translate }}" [refresh]="refreshValidators"></app-feedback>
                </div>
                <div class="col-12 col-md-6">
                    <div class="field pb-2">
                        <div class="custom-control custom-checkbox mb-2 mt-4">
                            <input formControlName="VATExempt" type="checkbox" class="custom-control-input" id="VATExempt" name="VATExempt" value="">
                            <label class="custom-control-label" for="VATExempt">{{ 'singup.Exento de IVA' | translate }}</label>
                        </div>
                    </div>
                </div>
             </div>
            <div class="row mb-3">
                <div class="col-12">
                    <div class="field pb-2">
                        <div class="custom-control custom-checkbox mb-2">
                            <input (click)="onProvacyPolicyCheck()" formControlName="privacyPolicy" type="checkbox" class="custom-control-input" id="privacyPolicy" name="privacy-policy" />
                            <label class="custom-control-label" for="privacyPolicy">{{ 'singup.He leído y acepto la ' | translate }}<a (click)="goToTerms()">{{ 'singup.Política de Privacidad' | translate }}</a>.</label>
                        </div>
                    </div>
                    <app-feedback [formGroup]="providerFullForm" field="privacyPolicy" validator="required" feedback="{{ 'singup.Es necesario que aceptes las condiciones para completar el registro' | translate }}" [refresh]="refreshValidators"></app-feedback>
                </div>
            </div>

            <div class="row mb-5 pb-5">
                <div class="col-12 col-md-6 offset-md-6 col-lg-6 offset-lg-6 col-xl-6 offset-xl-6">
                    <input (click)="registerProvider()" type="submit" value="{{ 'singup.Guardar datos' | translate }}" class="btn btn-full-width btn-primary mb-3 mb-sm-0" />
                </div>
            </div>


        </form>
    </div>
</div>



<app-message-panel></app-message-panel>