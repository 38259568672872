import { NgbDate } from '@ng-bootstrap/ng-bootstrap';

export class CalendarUtils {

    public static formatNgbDateToString( date: NgbDate ): string {
        if ( date !== null ) {
          return '' + date.day + '-' + date.month + '-' + date.year;
        }
        return '';
    }

    public static convertNgbDateToFirstDayOfMonthNgDate( date: NgbDate ): NgbDate {
        if ( date !== null ) {
          return new NgbDate(date.year, date.month, 1);
        }
        return null;
    }


}