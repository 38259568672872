<form class="form-boxes m-0 p-0" [formGroup]="providerDetailsForm">

    <!-- Empresa -->
    <div class="row mb-2">
        <div class="col-10">
            <h2 class="font-weight-bold d-flex"><img class="ic-mid mr-2" src="assets/img/ic_user.svg">{{ 'providerdetails.Datos de la empresa' | translate }}</h2>
            <h5>{{ 'providerdetails.Información de contacto y notificaciones' | translate }}</h5>
            <hr class="w-10 float-left mt-2 mb-4">
        </div>
        <div class="col-2 text-right">
            <a class="cursor-pointer" (click)="toggleEdition()">
                <img class="ic-mid mr-2" src="{{ isDisabled ? 'assets/img/ic_edit.svg' : 'assets/img/ic_edit-disabled.svg'}}">
            </a>
        </div>
    </div>

    <div class="row mb-3">
        <div class="col-12">
            <div class="field pb-2">
                <input formControlName="companyName" type="text" name="companyName" id="companyName" placeholder="{{ 'providerdetails.Nombre de la compañía' | translate }}" value="">
                <label for="companyName">{{ 'providerdetails.Nombre de la compañía' | translate }}</label>
            </div>
            <app-feedback [formGroup]="providerDetailsForm" field="companyName" validator="required" feedback="{{ 'providerdetails.Nombre de empresa requerido' | translate }}" [refresh]="refreshValidators"></app-feedback>
            <app-feedback [formGroup]="providerDetailsForm" field="companyName" validator="maxlength" feedback="{{ 'createtourdetails.Texto demasiado largo' | translate }}" [refresh]="refreshValidators"></app-feedback>
        </div>

        <div class="col-12 col-md-6">
            <div class="field pb-2">
                <input formControlName="web" type="text" name="web" id="web" placeholder="{{ 'providerdetails.Web' | translate }}" value="">
                <label for="web">{{ 'providerdetails.Web' | translate }}</label>
            </div>
            <app-feedback [formGroup]="providerDetailsForm" field="web" validator="required" feedback="{{ 'providerdetails.Web requerida' | translate }}" [refresh]="refreshValidators"></app-feedback>
            <app-feedback [formGroup]="providerDetailsForm" field="web" validator="maxlength" feedback="{{ 'createtourdetails.Texto demasiado largo' | translate }}" [refresh]="refreshValidators"></app-feedback>
        </div>

        <div class="col-12 col-md-6">
            <div class="field pb-2">
                <input formControlName="phone" type="phone" name="phone" id="phone" placeholder="{{ 'providerdetails.Teléfono' | translate }}" value="">
                <label for="phone">{{ 'providerdetails.Teléfono' | translate }}</label>
            </div>
            <app-feedback [formGroup]="providerDetailsForm" field="phone" validator="required" feedback="{{ 'providerdetails.Teléfono requerido' | translate }}" [refresh]="refreshValidators"></app-feedback>
            <app-feedback [formGroup]="providerDetailsForm" field="phone" validator="maxlength" feedback="{{ 'createtourdetails.Texto demasiado largo' | translate }}" [refresh]="refreshValidators"></app-feedback>
        </div>
        <div class="col-12 col-md-6">
            <div class="field pb-2">
                <select formControlName="country" name="country" (change)="onCountrySelected()">
                    <option [ngValue]="null">{{ 'createtourdetails.Selecciona la opción' | translate }}</option>
                    <option *ngFor="let country of countries" value="{{ country.id }}">{{ country.name }}</option>
                </select>
                <label for="country">{{ 'providerdetails.País' | translate }}</label>
            </div>
            <app-feedback [formGroup]="providerDetailsForm" field="country" validator="required" feedback="{{ 'providerdetails.País requerido' | translate }}" [refresh]="refreshValidators"></app-feedback>
        </div>
        <div class="col-12 col-md-6">
            <div class="field pb-2">
              <select formControlName="city" name="city">
                  <option [ngValue]="null">{{ 'createtourdetails.Selecciona la opción' | translate }}</option>
                  <option *ngFor="let city of cities" value="{{ city.id }}">{{ city.name }}</option>
              </select>
                <label for="city">{{ 'providerdetails.Ciudad' | translate }}</label>
            </div>
            <app-feedback [formGroup]="providerDetailsForm" field="city" validator="required" feedback="{{ 'providerdetails.Ciudad requerida' | translate }}" [refresh]="refreshValidators"></app-feedback>
        </div>
        <div class="col-12">
            <div class="field pb-2">
                <input formControlName="contactPerson" type="text" name="contactPerson" id="contactPerson" placeholder="{{ 'providerdetails.Persona de contacto' | translate }}" value="">
                <label for="contactPerson">{{ 'providerdetails.Persona de contacto' | translate }}</label>
            </div>
            <app-feedback [formGroup]="providerDetailsForm" field="contactPerson" validator="required" feedback="{{ 'providerdetails.Persona de contacto requerida' | translate }}" [refresh]="refreshValidators"></app-feedback>
            <app-feedback [formGroup]="providerDetailsForm" field="contactPerson" validator="maxlength" feedback="{{ 'createtourdetails.Texto demasiado largo' | translate }}" [refresh]="refreshValidators"></app-feedback>
        </div>
        <div class="col-12 col-md-6">
            <div class="field pb-2">
                <input formControlName="contactEmail" type="text" name="contactEmail" id="contactEmail" placeholder="{{ 'providerdetails.Email de contacto' | translate }}" value="">
                <label for="contactEmail">{{ 'providerdetails.Email de contacto' | translate }}</label>
            </div>
            <app-feedback [formGroup]="providerDetailsForm" field="contactEmail" validator="required" feedback="{{ 'providerdetails.Email de contacto requerido' | translate }}" [refresh]="refreshValidators"></app-feedback>
            <app-feedback [formGroup]="providerDetailsForm" field="contactEmail" validator="email" feedback="{{ 'providerdetails.Email incorrecto' | translate }}" [refresh]="refreshValidators"></app-feedback>
            <app-feedback [formGroup]="providerDetailsForm" field="contactEmail" validator="maxlength" feedback="{{ 'createtourdetails.Texto demasiado largo' | translate }}" [refresh]="refreshValidators"></app-feedback>
        </div>        
    </div>

    <div class="row mb-5 pb-5">
        <div class="col-12 col-md-6 offset-md-6 col-lg-4 offset-lg-8">
            <input (click)="saveDetails()" type="submit" value="{{ 'providerdetails.Guardar datos' | translate }}" class="btn btn-full-width btn-primary mb-3 mb-sm-0" [disabled]="isDisabled" />
        </div>
    </div>
</form>

<app-message-panel [preloading]="false"></app-message-panel>