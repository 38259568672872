import { Component, Input, ViewChild } from '@angular/core';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { MessagePanelComponent } from 'src/app/ui/components/shared/message-panel/message-panel.component';

import { marker as I18N }               from '@biesbjerg/ngx-translate-extract-marker';
import { NavigatorUtils } from 'src/app/ui/utils/navigator.utils';
import { TourAffiliateLink } from 'src/app/business/models/tour/tour-affiliate-link.model';

@Component({
     selector: 'app-affiliate-tour-link-modal-component',
     templateUrl: './tour-link-modal.component.html'
})

export class AffiliateTourLinkModalComponent {

     @ViewChild(MessagePanelComponent, { static: false })
     private messagePanelComponent: MessagePanelComponent;

     @Input() affiliateLinks: Array<TourAffiliateLink>;

     constructor(private modal: NgbActiveModal) {

     }

     ngOnInit() {
     }


     closeModal() {
          this.modal.close();
     }

     onSelectedTourLink(event: any) {
          event.target.select();
          NavigatorUtils.copyToClipboard(event.target.value);
          this.messagePanelComponent.showSuccessMessage(I18N('affiliatecustomlink.Enlace copiado al portapapeles'));
     }



}




