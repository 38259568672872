import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NavigationConstants } from 'src/app/ui/commons/navigation.constants';
import { ProviderResume } from 'src/app/business/models/provider/provider-resume.model';
import { Pagination } from 'src/app/domain/views/pagination.view';
import { AdminProviderInteractor } from 'src/app/domain/interactor/admin/provider.interactor';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { AdminEditReviewModalComponent } from './edit-review/edit-review-modal.component';
import { AdminBookInteractor } from 'src/app/domain/interactor/admin/book.interactor';
import { ServerErrorManager } from '../../../utils/server-error-manager.utils';
import { MessagePanelComponent } from '../../../shared/message-panel/message-panel.component';
import { ReviewsReport } from 'src/app/business/models/review/reviews-report.model';
import { AdminTourInteractor } from 'src/app/domain/interactor/admin/tour.interactor';
import { Review } from 'src/app/business/models/review/review.model';
import { TourResumeAdmin } from 'src/app/business/models/tour/tour-resume-admin.model';
import { ReviewUpdatedView } from 'src/app/domain/views/book/review-updated.view';
import { GenericException } from 'src/app/domain/exceptions/generic.exception';

import { TranslateService } from '@ngx-translate/core';
import { marker as I18N } from '@biesbjerg/ngx-translate-extract-marker';

@Component({
  selector: 'app-review',
  templateUrl: './review.component.html'
})
export class AdminReviewComponent implements OnInit {

  @ViewChild(MessagePanelComponent)
  private messagePanelComponent: MessagePanelComponent;

  providerId: number;
  tourId: number;

  providers: Array<ProviderResume>;
  tours: Array<TourResumeAdmin>

  pagination: Pagination;
  pageNumber: number;

  reviewsReport: ReviewsReport;
  noResults: boolean;

  paginationNextLabel: string;
  paginationPreviousLabel: string;

  constructor(
    private adminProviderInteractor: AdminProviderInteractor,
    private adminBookInteractor: AdminBookInteractor,
    private adminTourInteractor: AdminTourInteractor,
    private serverErrorManager: ServerErrorManager,
    private translateService: TranslateService,
    private modalService: NgbModal,
    private router: Router) {

    this.pagination = new Pagination(0, 1, 20);
    this.pageNumber = this.pagination.currentPage;
    this.providerId = null;
    this.tourId = null;
    this.noResults = true;
  }

  ngOnInit() {
    this.loadProviders();
    this.loadReviews();

    this.translateService.get(I18N('pagination.Siguiente')).subscribe(
         (messageTranslated: string) => {
              this.paginationNextLabel = messageTranslated;
         }
    )

    this.translateService.get(I18N('pagination.Previous')).subscribe(
         (messageTranslated: string) => {
              this.paginationPreviousLabel = messageTranslated;
         }
    )

  }

  loadProviders() {
    this.adminProviderInteractor.findAllProviders()
      .subscribe(
        (providers: Array<ProviderResume>) => {

          this.providers = providers;

        },
        (error: GenericException) => {
          this.serverErrorManager.manageErrorCode(error, this.messagePanelComponent);
        }
      )
  }


  loadReviews() {

    if (this.messagePanelComponent) {
      this.messagePanelComponent.showLoading();
    }
    this.adminBookInteractor.findReviews(this.providerId, this.tourId, this.pageNumber)
      .subscribe(
        (reviewsReport: ReviewsReport) => {

          this.reviewsReport = reviewsReport;
          this.pagination = new Pagination(this.reviewsReport.reviewsChunk.pagination.totalItems, this.reviewsReport.reviewsChunk.pagination.currentPage, this.reviewsReport.reviewsChunk.pagination.itemsPerPage);
          this.noResults = this.reviewsReport.reviewsChunk.items.length == 0;

          setTimeout(() => { MessagePanelComponent.hideLoading(); }, 100);

        },
        (error: GenericException) => {

          MessagePanelComponent.hideLoading();
          this.serverErrorManager.manageErrorCode(error, this.messagePanelComponent);
        }
      )
  }


  onProviderSelected() {

    this.messagePanelComponent.showLoading();
    this.tourId = null;
    this.pageNumber = 1;

    if (this.providerId) {
      this.adminTourInteractor.findAllToursByProvider(this.providerId)
        .subscribe(
          (tours: Array<TourResumeAdmin>) => {

            this.tours = tours;


          },
          (error: GenericException) => {

            this.serverErrorManager.manageErrorCode(error, this.messagePanelComponent);
          }
        )
    } else {

      this.tours = new Array<TourResumeAdmin>();

    }

    this.loadReviews();


  }

  onTourSelected() {
    this.pageNumber = 1;
    this.loadReviews();
  }

  onPageChanged(currentPage: number) {
    this.pageNumber = currentPage;
    this.loadReviews();
  }



  onEditReview(review: Review) {
    const modalComponent: NgbModalRef = this.modalService.open(AdminEditReviewModalComponent, { ariaLabelledBy: 'modal-basic-title', backdrop: 'static' });
    modalComponent.componentInstance.review = review;
    modalComponent.result.then((reviewUpdated: ReviewUpdatedView) => {
      if (reviewUpdated) {
        review.comments = reviewUpdated.comments;
        review.rating = reviewUpdated.rating;
      }
    });

  }





  onHeaderItemSelected() {
    this.router.navigate([NavigationConstants.getNavigationHome()]);
  }

  goToHomeLanding() {
    this.router.navigate([NavigationConstants.getNavigationHome()]);
  }

}
