import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { NavigationConstants } from 'src/app/ui/commons/navigation.constants';

@Component({
  selector: 'app-public-navigation',
  templateUrl: './public-navigation.component.html'
})
export class PublicNavigationComponent {

  constructor(private router: Router) {}

  goToHomeLanding() {
    this.router.navigate( [ NavigationConstants.getNavigationHome() ] );
  }

  goToContact() {
    this.router.navigate( [ NavigationConstants.getNavigationHome() ], { fragment: NavigationConstants.NAVIGATION_HOME_ANCHOR_CONTACT } );
  }

}
