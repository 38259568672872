<div class="animated fadeIn">

     <app-list-booking
          [noResults]="noResults"
          [bookEvents]="bookEvents"
          [pagination]="pagination"
          [guideRole]="true"
          (pageNumberSelected)="onPageNumberSelected($event)"
          (viewBookDetails)="onViewBookDetails($event)"
          (showToursForToday)="onShowToursForToday($event)"
          (newBooking)="onNewBooking($event)"
          (newBookingForNewTour)="onNewBookingForNewTour()"
          ></app-list-booking>


     <app-message-panel></app-message-panel>

</div>