import { Component, Input } from '@angular/core';
import { FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { TourScheduleSeasonFormView } from 'src/app/domain/views/provider/tour/schedule-season-form.view';
import { TourUtils } from 'src/app/ui/components/utils/tour/Tour.utils';
import { FormUtils } from 'src/app/ui/components/utils/form.utils';
import { CalendarUtils } from 'src/app/ui/utils/calendar.utils';


@Component({
  selector: 'app-create-tour-schedule-season',
  templateUrl: './season.component.html'
})
export class CreateTourScheduleSeasonComponent {


  @Input() refreshValidators: boolean;


  i18nTraslation: boolean;

  @Input()
  set isI18nTraslation(isI18nTraslation: boolean) {
      this.i18nTraslation = isI18nTraslation;
      this.updateInputDetails();
  }


  @Input()
  set seasonsView(seasonsView: Array<TourScheduleSeasonFormView>) {
      this.seasonInputView = seasonsView;
      this.updateInputDetails();
  }

  @Input()
  set seasonsFormArray(seasonsFormArray: FormArray) {
      this.seasonsForm = seasonsFormArray;
      this.updateInputDetails();
  }


  invalidStateForm: string = FormUtils.getFormInvalidStatus();

  seasonsForm: FormArray;
  seasonInputView: Array<TourScheduleSeasonFormView>;

  currentSeasonIndex: number;
  currentSeasonSelected: FormGroup;
  createSessionScheduleForm: FormGroup;


  updateInputDetails() {
      if ( this.seasonsForm ) {

          this.seasonsForm.clear();

          if ( this.seasonInputView ) {

              this.seasonInputView.map( (season: TourScheduleSeasonFormView) => {
                  const seasonFormGroup: FormGroup = this.getNewSeason();

                  seasonFormGroup.get('startDay').setValue(CalendarUtils.fromDateDDMMYYYYToDateHtmlInput(season.startDay));
                  seasonFormGroup.get('finishDay').setValue(CalendarUtils.fromDateDDMMYYYYToDateHtmlInput(season.finishDay));

                  if ( this.i18nTraslation ) {
                      seasonFormGroup.get('startDay').disable();
                      seasonFormGroup.get('finishDay').disable();
                  } else {
                      seasonFormGroup.get('startDay').enable();
                      seasonFormGroup.get('finishDay').enable();
                  }

                  const schedulePlanningFormGroups: [Array<FormGroup>, Array<FormGroup>]  = TourUtils.getFormGroupsFromTourSchedulePlanningFormView(season.details);
                  const days = seasonFormGroup.get('details').get('days') as FormArray;
                  const dayExceptions = seasonFormGroup.get('details').get('dayExceptions') as FormArray;

                  days.clear();
                  schedulePlanningFormGroups[0].map( (dayFormGroup: FormGroup) => {
                      days.push(dayFormGroup);
                  });

                  dayExceptions.clear();
                  schedulePlanningFormGroups[1].map( (dayExceptionFormGroup: FormGroup) => {
                      dayExceptions.push(dayExceptionFormGroup);
                  });

                  this.seasonsForm.push(seasonFormGroup);

              });

          }

          if ( this.seasonsForm.length == 0 ) {
              this.seasonsForm.push(this.getNewSeason());
          }

          this.setCurrentSeasonTab(0);

      }
  }


  constructor() {

  }


  get seasonDetailsFormGroup(): FormGroup {
      return this.currentSeasonSelected.get('details') as FormGroup;
  }

  get currentSeasonStartDay(): string {
      return this.currentSeasonSelected.get('startDay').value;
  }

  get currentSeasonFinishDay(): string {
      return this.currentSeasonSelected.get('finishDay').value;
  }

  getNewSeason(): FormGroup {
      return new FormGroup({
        'inconsistentdates': new FormControl(''),
        'inconsistentexceptiondates': new FormControl(''),
        'overlapping': new FormControl(''),
        'startDay': new FormControl('', [Validators.required, this.inconsistentDatesValidator, this.overlappingDatesValidator, this.inconsistentExceptionDatesValidator] ),
        'finishDay': new FormControl('', [Validators.required, this.inconsistentDatesValidator, this.overlappingDatesValidator, this.inconsistentExceptionDatesValidator] ),
        'details': new FormGroup({
                'days': new FormArray([], [this.weekdaysValidator]),
                'dayExceptions': new FormArray([], [this.exceptionDaysValidator])
              })
      });
  }


  onAddSeasson() {
      this.seasonsForm.push(this.getNewSeason());
      this.setCurrentSeasonTab(this.seasonsForm.length - 1);
  }

  onDeleteSeason(seasonIndex: number) {
      this.seasonsForm.removeAt(seasonIndex);

      if ( this.seasonsForm.length == 0 ) {
          this.seasonsForm.push(this.getNewSeason());
      }

      if ( seasonIndex == this.currentSeasonIndex) {
          this.setCurrentSeasonTab(0);
      }
  }

  onSeasonSelected(seasonIndex: number) {
      this.setCurrentSeasonTab(seasonIndex);

  }

  onCopySeason(seasonIndex: number) {
      const clonedSeason: FormGroup = this.seasonsForm.at(seasonIndex) as FormGroup;
      const newSeason: FormGroup = this.getNewSeason();

      newSeason.get('startDay').setValue(clonedSeason.get('startDay').value);
      newSeason.get('finishDay').setValue(clonedSeason.get('finishDay').value);

      const clonedSeasonDays = clonedSeason.get('details').get('days') as FormArray;
      const clonedSeasonDayExceptions = clonedSeason.get('details').get('dayExceptions') as FormArray;

      const newSessionDays = newSeason.get('details').get('days') as FormArray;
      const newdSessionDayExceptions = newSeason.get('details').get('dayExceptions') as FormArray;


      clonedSeasonDays.controls.map( (clonedDay: FormGroup) => {
          const newDayFormGroup: FormGroup = TourUtils.createWeekdayFormGroup(clonedDay.get('day').value);
          const clonedHours = clonedDay.get('hours') as FormArray
          const newHours = newDayFormGroup.get('hours') as FormArray
          clonedHours.controls.map( (hour: FormControl) => {
              newHours.push(new FormControl(hour.value));
          });

          newSessionDays.push(newDayFormGroup);
      })

      clonedSeasonDayExceptions.controls.map( (clonedExceptionDay: FormGroup) => {
          const newExceptionDayFormGroup: FormGroup = TourUtils.createWeekdayFormGroup(clonedExceptionDay.get('day').value);
          const clonedHours = clonedExceptionDay.get('hours') as FormArray
          const newHours = newExceptionDayFormGroup.get('hours') as FormArray
          clonedHours.controls.map( (hour: FormControl) => {
              newHours.push(new FormControl(hour.value));
          });

          newdSessionDayExceptions.push(newExceptionDayFormGroup);
      })

      this.seasonsForm.push(newSeason);
      this.setCurrentSeasonTab(this.seasonsForm.length - 1);

  }


  setCurrentSeasonTab(index: number) {
    this.currentSeasonIndex = index;
    this.currentSeasonSelected = <FormGroup> this.seasonsForm.at(this.currentSeasonIndex);
  }



  weekdaysValidator(daysControls: FormArray ): { [s:string]:boolean } {

      let hasSchedules = false;
       if ( daysControls ) {
           daysControls.controls.forEach( (control: FormControl) => {
               if ( control.get('day').value != '' ) {
                   hasSchedules = true;
               }
           });
       }

      if ( !hasSchedules ) {
         return {
             required: true
         }
      } else {
          return null;
      }
  }



  inconsistentDatesValidator(dateControl: FormArray ): { [s:string]:boolean } {

      let inconsistentDates = false;
      if ( dateControl && dateControl.parent ) {
            const startDay = dateControl.parent.get('startDay').value;
            const finishDay = dateControl.parent.get('finishDay').value;

            if ( startDay != '' &&  finishDay != '' && startDay >= finishDay ) {
                inconsistentDates = true;
            }

            //Update overlapping field
            if ( dateControl.parent ) {
                if ( inconsistentDates ) {
                    dateControl.parent.get('inconsistentdates').setErrors({ inconsistentdates: true });
                } else {
                    dateControl.parent.get('inconsistentdates').setErrors(null);
                }
            }

            //Return specific field validation
            return null;

      }
  }


  overlappingDatesValidator(dateControl: FormArray ): { [s:string]:boolean } {

      let hasOverlapping = false;
      if ( dateControl && dateControl.parent && dateControl.parent.parent ) {
            const currentSeasonFormGoup: FormGroup = dateControl.parent as FormGroup;

            const seasonsFormArray: FormArray = dateControl.parent.parent as FormArray;
            const currentStartDay: Date = CalendarUtils.fromDateHtmlInputToDate(currentSeasonFormGoup.get('startDay').value);
            const currentFinishDay: Date = CalendarUtils.fromDateHtmlInputToDate(currentSeasonFormGoup.get('finishDay').value);


            seasonsFormArray.controls.map( (seasonFormGroup: FormGroup) => {
                const startDay: Date = CalendarUtils.fromDateHtmlInputToDate(seasonFormGroup.get('startDay').value);
                const finishDay: Date = CalendarUtils.fromDateHtmlInputToDate(seasonFormGroup.get('finishDay').value);

                if (  currentSeasonFormGoup != seasonFormGroup
                      && startDay && finishDay
                      && (
                        ( startDay <= currentStartDay && finishDay >= currentStartDay )
                        || ( startDay >= currentStartDay && finishDay <= currentFinishDay )
                        || ( startDay <= currentFinishDay && finishDay >= currentFinishDay )

                      )
                ) {
                      hasOverlapping = true;
                }

            });

      }

      //Update overlapping field
      if ( dateControl.parent ) {
          if ( hasOverlapping ) {
              dateControl.parent.get('overlapping').setErrors({ overlapping: true });
          } else {
              dateControl.parent.get('overlapping').setErrors(null);
          }
      }

      //Return specific field validation
      return null;


  }


  inconsistentExceptionDatesValidator(dateControl: FormControl ): { [s:string]:boolean } {

      if ( dateControl && dateControl.parent ) {

          const sessionControl = dateControl.parent as FormGroup;

          let correctExceptionDates = true;
          const startDay: Date = CalendarUtils.fromDateHtmlInputToDate(sessionControl.get('startDay').value);
          const finishDay: Date = CalendarUtils.fromDateHtmlInputToDate(sessionControl.get('finishDay').value);

          const details: FormGroup = sessionControl.get('details') as FormGroup;
          if ( details ) {
              const dayExceptions = details.get('dayExceptions') as FormArray;
              dayExceptions.controls.forEach( (control: FormControl) => {
                  const exceptionDay = CalendarUtils.fromDaleLocalizedDDMMYYYYToDate(control.get('day').value);
                  if ( exceptionDay &&
                          ( ( exceptionDay < startDay ) || ( exceptionDay > finishDay ) )
                        ) {
                          correctExceptionDates = false;
                  }
              });
          }

          if ( sessionControl ) {
              if ( correctExceptionDates ) {
                  sessionControl.get('inconsistentexceptiondates').setErrors(null);
              } else {
                  sessionControl.get('inconsistentexceptiondates').setErrors({ inconsistentexceptiondates: true });
              }
          }

          return null;


      }

      return null;

  }

  exceptionDaysValidator(dateControl: FormArray ): { [s:string]:boolean } {

      if ( dateControl && dateControl.parent && dateControl.parent.parent ) {

          const sessionControl = dateControl.parent.parent as FormGroup;

          let correctExceptionDates = true;
          const startDay: Date = CalendarUtils.fromDateHtmlInputToDate(sessionControl.get('startDay').value);
          const finishDay: Date = CalendarUtils.fromDateHtmlInputToDate(sessionControl.get('finishDay').value);

          const details: FormGroup = sessionControl.get('details') as FormGroup;
          if ( details ) {
              const dayExceptions = details.get('dayExceptions') as FormArray;
              dayExceptions.controls.forEach( (control: FormControl) => {
                  const exceptionDay = CalendarUtils.fromDaleLocalizedDDMMYYYYToDate(control.get('day').value);
                  if ( exceptionDay &&
                          ( ( exceptionDay < startDay ) || ( exceptionDay > finishDay ) )
                        ) {
                          correctExceptionDates = false;
                  }
              });
          }

          if ( sessionControl ) {
              if ( correctExceptionDates ) {
                  sessionControl.get('inconsistentexceptiondates').setErrors(null);
              } else {
                  sessionControl.get('inconsistentexceptiondates').setErrors({ inconsistentexceptiondates: true });
              }
          }

          return null;
      }
      return null;
  }


}
