import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import Swal, { SweetAlertInput } from 'sweetalert2';
import { marker as I18N } from '@biesbjerg/ngx-translate-extract-marker';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-message-panel',
  template: ''
})
export class MessagePanelComponent implements OnInit {


  public static MESSAGE_INPUT_TYPE_TEXT: SweetAlertInput = 'text';
  public static MESSAGE_INPUT_TYPE_EMAIL: SweetAlertInput = 'email';
  public static MESSAGE_INPUT_TYPE_PASSWORD: SweetAlertInput = 'password';
  public static MESSAGE_INPUT_TYPE_NUMBER: SweetAlertInput = 'number';
  public static MESSAGE_INPUT_TYPE_TEL: SweetAlertInput = 'tel';
  public static MESSAGE_INPUT_TYPE_RANGE: SweetAlertInput = 'range';
  public static MESSAGE_INPUT_TYPE_TEXTAREA: SweetAlertInput = 'textarea';
  public static MESSAGE_INPUT_TYPE_SELECT: SweetAlertInput = 'select';
  public static MESSAGE_INPUT_TYPE_RADIO: SweetAlertInput = 'radio';
  public static MESSAGE_INPUT_TYPE_CHECKBOX: SweetAlertInput = 'checkbox';
  public static MESSAGE_INPUT_TYPE_FILE: SweetAlertInput = 'file';
  public static MESSAGE_INPUT_TYPE_URL: SweetAlertInput = 'url';


  @Input()
  set preloading( preloading: boolean) {
      if ( preloading ) {
          this.showLoading();
      }
  }

  constructor(private translateService: TranslateService) {
  }

  ngOnInit() {
  }


  showSuccessMessage(message: string, callback: () => void = null) {
    this.translateService.get(message, {})
      .subscribe(
          (messageTranslated: string) => {
              this.translateService.get(I18N('messagepanel.¡Perfecto!'), {})
                .subscribe(
                    (titleTranslated: string) => {
                      this.visualizeSuccessMessage(titleTranslated, messageTranslated, callback);
                }
              )
          }
      )

  }

  showErrorMessage(message: string, callback: () => void = null) {
    this.translateService.get(message, {})
      .subscribe(
          (messageTranslated: string) => {
              this.translateService.get(I18N('messagepanel.Opps!'), {})
                .subscribe(
                    (titleTranslated: string) => {
                      this.visualizeErrorMessage(titleTranslated, messageTranslated, callback);
                }
              )
          }
      )

  }

  showServerErrorMessage(message: string, cause: string, callback: () => void = null) {
    this.translateService.get(message, {})
      .subscribe(
          (messageTranslated: string) => {
              this.translateService.get(I18N('messagepanel.Opps!'), {})
                .subscribe(
                    (titleTranslated: string) => {
                      this.visualizeServerError(titleTranslated, messageTranslated, cause, callback);
                }
              )
          }
      )

  }


  showConfirmationMessage(message: string, callback: () => void = null, callbackCancel: () => void = null) {
    this.translateService.get(message, {})
      .subscribe(
          (messageTranslated: string) => {
              this.translateService.get(I18N('messagepanel.¡Atención!'), {})
                .subscribe(
                    (titleTranslated: string) => {
                      this.visualizeConfirmMessage(titleTranslated, messageTranslated, callback, callbackCancel);
                }
              )
          }
      )
  }


  showInputMessage(title: string, message: string, inputType: SweetAlertInput, inputValue: string, validator: (value: string) => string | null, callback: (value: string) => void) {
    this.translateService.get(message, {})
      .subscribe(
          (messageTranslated: string) => {
              this.translateService.get(title, {})
                .subscribe(
                    (titleTranslated: string) => {
                         this.visualizeInputTextMessage(titleTranslated, messageTranslated, inputType, inputValue, validator, callback);
                   }
              )
          }
      )

  }



  showLoading() {

    this.translateService.get(I18N("messagepanel.Un momento, por favor."), {})
      .subscribe(
          (messageTranslated: string) => {
            Swal.close();
            Swal.fire({
              html: messageTranslated,
              allowOutsideClick: false,
              showCancelButton: false,
              showCloseButton: false,
              showConfirmButton: false,
              onBeforeOpen: () => {
                  Swal.showLoading();
              }
            });
      });

  }

  public static hideLoading() {
       Swal.close();
 }





  private visualizeErrorMessage(title: string, message: string, callback: () => void = null) {
      if ( message != null ) {

          Swal.fire({
            icon: 'error',
            title: title,
            text: message,
            allowOutsideClick: false
          }).then(() => {
              if ( callback ) {
                  callback();
              }
          })

      }
  }


  private visualizeServerError(title: string, message: string, cause: string, callback: () => void = null) {
      if ( message != null ) {

          Swal.fire({
            icon: 'error',
            title: title,
            html: message + '<br/><br/><small>' + cause + '</small>',
            allowOutsideClick: false
          }).then(() => {
              if ( callback ) {
                  callback();
              }
          })

      }
  }


  private visualizeSuccessMessage(title: string, message: string, callback: () => void) {
      if ( message != null ) {

          Swal.fire({
            icon: 'success',
            title: title,
            text: message,
            allowOutsideClick: false
          }).then(() => {
              if ( callback ) {
                  callback();
              }
          })

      }
  }


  private visualizeConfirmMessage(title: string, message: string, callback: () => void, callbackCancel: () => void) {

      if ( message != null ) {

          Swal.fire({
            icon: 'warning',
            title: title,
            text: message,
            showCancelButton: true,
            allowOutsideClick: false
          }).then((result) => {
              if ( result.value && callback ) {
                  callback();
             } else {
                 if (callbackCancel){
                     callbackCancel();
                 }
             }
          })

      }
  }



  private visualizeInputTextMessage(title: string, message: string, inputType: SweetAlertInput, inputValue: string, validator: (value: string) => string | null, callback: (value: string) => void) {

      Swal.fire({
          title: title,
          text: message,
          input: inputType,
          inputValue: inputValue,
          inputAttributes: {
              autocapitalize: 'off'
          },
          showCancelButton: true,
          showLoaderOnConfirm: true,
          allowOutsideClick: false,
          inputValidator: (value) => {
              if ( validator ) {
                return validator(value);
              }
          },
          preConfirm: (value) => {
              callback(value);
        }
      });
  }


  /* Temporal */
  showBid() {
    Swal.fire({
      title: 'Modifica el precio de la puja',
      input: 'number',
      inputAttributes: {
        autocapitalize: 'off',
        placeholder: '1.50',
        min: '1.50'
      },
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Aceptar',
      showLoaderOnConfirm: true
    })
  }

}
