import { Component, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { MessagePanelComponent } from 'src/app/ui/components/shared/message-panel/message-panel.component';
import { ServerErrorManager } from 'src/app/ui/components/utils/server-error-manager.utils';

import { AppConstants } from 'src/app/domain/common/app.constants';
import { GenericException } from 'src/app/domain/exceptions/generic.exception';
import { AffiliateTourInteractor } from 'src/app/domain/interactor/affiliate/tour.interactor';
import { FormUtils } from 'src/app/ui/components/utils/form.utils';

import { marker as I18N }               from '@biesbjerg/ngx-translate-extract-marker';
import { NavigatorUtils } from 'src/app/ui/utils/navigator.utils';

@Component({
     selector: 'app-affiliate-create-custom-link-modal-component',
     templateUrl: './create-custom-link-modal.component.html'
})

export class AffiliateCreateCustomLinkModalComponent {

     @ViewChild(MessagePanelComponent, { static: false })
     private messagePanelComponent: MessagePanelComponent;

     customLinkForm: FormGroup;
     refreshValidators: boolean;

     constructor(
          private affiliateTourInteractor: AffiliateTourInteractor,
          private serverErrorManager: ServerErrorManager,
          private modal: NgbActiveModal) {

               this.refreshValidators = false;

               this.customLinkForm = new FormGroup({
                    'link': new FormControl({value: '', disabled: false}, [Validators.required, Validators.maxLength(AppConstants.TEXT_DEFAULT_MAX_LENGTH), Validators.pattern(AppConstants.REGULAR_EXPRESSION_VALIDATE_WEBSITE)]),
                    'linkGenerated': new FormControl({value: '', disabled: true}, []),
               })

          }

          ngOnInit() {
          }


          closeModal() {
               this.modal.close();
          }


          generateCustomLink() {
               this.refreshValidators = true;

               if (this.customLinkForm.valid) {

                    this.messagePanelComponent.showLoading();

                    const link: string = this.customLinkForm.get('link').value;

                    this.affiliateTourInteractor.generateCustomLink(link)
                    .subscribe(
                         (linkGenerated: string) => {

                              MessagePanelComponent.hideLoading();

                              this.customLinkForm.get('linkGenerated').setValue(linkGenerated);
                              FormUtils.enableAllControls(this.customLinkForm);

                         },
                         (error: GenericException) => {

                              MessagePanelComponent.hideLoading();
                              this.serverErrorManager.manageErrorCode(error, this.messagePanelComponent);

                         }
                    )

               }

          }


          onSelectedGenerateCustomLink(event: any) {
               event.target.select();
               NavigatorUtils.copyToClipboard(this.customLinkForm.get('linkGenerated').value);
               this.messagePanelComponent.showSuccessMessage(I18N('affiliatecustomlink.Enlace copiado al portapapeles'));
          }



     }




