<form class="form-boxes m-0 p-0" [formGroup]="createTourDetailsForm">

    <div class="row">
        <div class="col-12 col-md-6 col-lg-9">
            <div class="field pb-2">
                <input formControlName="name" type="text" name="name" id="name">
                <app-main-translation [i18nTraslation]="i18nTraslation" [mainTranslation]="nameMain"></app-main-translation>
                <label for="name">{{ 'createtourdetails.Nombre del tour' | translate }}</label>
            </div>
            <app-feedback [formGroup]="createTourDetailsForm" field="name" validator="required" feedback="{{ 'createtourdetails.Nombre de tour requerido' | translate }}" [refresh]="refreshValidators"></app-feedback>
            <app-feedback [formGroup]="createTourDetailsForm" field="name" validator="maxlength" feedback="{{ 'createtourdetails.Texto demasiado largo' | translate }}" [refresh]="refreshValidators"></app-feedback>
        </div>

        <div class="col-12 col-md-6 col-lg-3">
            <div class="field pb-2">
                <select formControlName="languageTourGivenId" name="languageTourGivenId" id="languageTourGivenId">
                    <option value="">{{ 'createtourdetails.Selecciona la opción' | translate }}</option>
                    <option *ngFor="let language of languages" value="{{ language.id }}">{{ language.name }} </option>
                </select>
                <label for="languageTourGivenId">{{ 'createtourdetails.Idioma tour' | translate }}</label>
            </div>
            <app-feedback [formGroup]="createTourDetailsForm" field="languageTourGivenId" validator="required" feedback="{{ 'createtourdetails.Idioma requerido' | translate }}" [refresh]="refreshValidators"></app-feedback>
        </div>
    </div>

    <div class="row">
        <div class="col-12 col-md-6 col-lg-4">
            <div class="field pb-2">
                <select formControlName="typeId" name="typeId" id="typeId">
                  <option value="">{{ 'createtourdetails.Selecciona la opción' | translate }}</option>
                  <option *ngFor="let tourType of tourTypes" value="{{ tourType.id }}">{{ tourType.name }} </option>
              </select>
                <label for="typeId">{{ 'createtourdetails.Tipo de tour' | translate }}</label>
            </div>
            <app-feedback [formGroup]="createTourDetailsForm" field="typeId" validator="required" feedback="{{ 'createtourdetails.Tipo de tour requerido' | translate }}" [refresh]="refreshValidators"></app-feedback>
        </div>

        <div class="col-12 col-md-6 col-lg-2">
            <div class="field pb-2">
                 <select formControlName="duration" class="" name="duration" id="duration">
                         <option value="60">1h</option>
                         <option value="75">1h 15min</option>
                         <option value="90">1h 30min</option>
                         <option value="105">1h 45min</option>
                         <option value="120">2h</option>
                         <option value="135">2h 15min</option>
                         <option value="150">2h 30min</option>
                         <option value="165">2h 45min</option>
                         <option value="180">3h</option>
                         <option value="195">3h 15min</option>
                         <option value="210">3h 30min</option>
                         <option value="225">3h 45min</option>
                         <option value="240">4h</option>
                         <option value="255">4h 15min</option>
                         <option value="270">4h 30min</option>
                         <option value="285">4h 45min</option>
                         <option value="300">5h</option>
                         <option value="315">5h 15min</option>
                         <option value="330">5h 30min</option>
                         <option value="345">5h 45min</option>
                         <option value="360">6h</option>
                         <option value="375">6h 15min</option>
                         <option value="390">6h 30min</option>
                         <option value="405">6h 45min</option>
                         <option value="420">7h</option>
                         <option value="435">7h 15min</option>
                         <option value="450">7h 30min</option>
                         <option value="465">7h 45min</option>
                         <option value="480">8h</option>
                         <option value="495">8h 15min</option>
                         <option value="510">8h 30min</option>
                         <option value="525">8h 45min</option>
                         <option value="540">9h</option>
                         <option value="555">9h 15min</option>
                         <option value="570">9h 30min</option>
                         <option value="585">9h 45min</option>
                         <option value="600">10h</option>
                 </select>
                <label for="duration">{{ 'createtourdetails.Duración' | translate }}</label>
            </div>
            <app-feedback [formGroup]="createTourDetailsForm" field="duration" validator="required" feedback="{{ 'createtourdetails.Duración requerida' | translate }}" [refresh]="refreshValidators"></app-feedback>
            <app-feedback [formGroup]="createTourDetailsForm" field="duration" validator="min" feedback="{{ 'createtourdetails.Duración incorrecta' | translate }}" [refresh]="refreshValidators"></app-feedback>
            <app-feedback [formGroup]="createTourDetailsForm" field="duration" validator="max" feedback="{{ 'createtourdetails.Número demasiado grande' | translate }}" [refresh]="refreshValidators"></app-feedback>
        </div>

        <div class="col-12 col-md-6 col-lg-3">
            <div class="field pb-2">
                <select formControlName="countryId" name="countryId" id="countryId" (change)="onCountrySelected()">
                  <option value="">{{ 'createtourdetails.Selecciona la opción' | translate }}</option>
                  <option *ngFor="let country of countries" value="{{ country.id }}">{{ country.name }} </option>
                </select>
                <label for="countryId">{{ 'createtourdetails.País' | translate }}</label>
            </div>
            <app-feedback [formGroup]="createTourDetailsForm" field="countryId" validator="required" feedback="{{ 'createtourdetails.País requerido' | translate }}" [refresh]="refreshValidators"></app-feedback>
        </div>
        <div class="col-12 col-md-6 col-lg-3">
            <div class="field pb-2">
                <select formControlName="cityId" name="cityId" id="cityId">
                  <option value="">{{ 'createtourdetails.Selecciona la opción' | translate }}</option>
                  <option *ngFor="let city of cities" value="{{ city.id }}">{{ city.name }} </option>
                </select>
                <label for="cityId">{{ 'createtourdetails.Ciudad' | translate }}</label>
            </div>
            <app-feedback [formGroup]="createTourDetailsForm" field="cityId" validator="required" feedback="{{ 'createtourdetails.Ciudad requerida' | translate }}" [refresh]="refreshValidators"></app-feedback>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <div class="field pb-2">
                <angular-editor id="editor1" formControlName="description" [config]="wysiwygSettings"></angular-editor>
                <app-main-translation [i18nTraslation]="i18nTraslation" [mainTranslation]="descriptionMain"></app-main-translation>
                <label for="description">{{ 'createtourdetails.Descripción del tour' | translate }}</label>
            </div>
            <app-feedback [formGroup]="createTourDetailsForm" field="description" validator="required" feedback="{{ 'createtourdetails.Descripción requerida' | translate }}" [refresh]="refreshValidators"></app-feedback>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <div class="field pb-2">
                <textarea id="" rows="2" cols="50" maxlength="150" formControlName="descriptionShort" type="text" name="descriptionShort" id="descriptionShort" placeholder="{{ 'createtourdetails.Máximo 150 caracteres' | translate }}"></textarea>
                <app-main-translation [i18nTraslation]="i18nTraslation" [mainTranslation]="descriptionShortMain"></app-main-translation>
                <label for="descriptionShort">{{ 'createtourdetails.Descripción corta del tour' | translate }}</label>
            </div>
            <app-feedback [formGroup]="createTourDetailsForm" field="descriptionShort" validator="required" feedback="{{ 'createtourdetails.Descripción corta requerida' | translate }}" [refresh]="refreshValidators"></app-feedback>
            <app-feedback [formGroup]="createTourDetailsForm" field="descriptionShort" validator="maxlength" feedback="{{ 'createtourdetails.Longitud máxima de 150 caracteres' | translate }}" [refresh]="refreshValidators"></app-feedback>
        </div>
    </div>

    <div class="row">
        <div class="col-12">
            <div class="field pb-2">
                <input formControlName="additionalInformation" type="text" name="additionalInformation" id="additionalInformation">
                <app-main-translation [i18nTraslation]="i18nTraslation" [mainTranslation]="additionalInformationMain"></app-main-translation>
                <label for="additionalInformation">{{ 'createtourdetails.Información adicional importante ' | translate }}<span class="font-italic">{{ 'createtourdetails.(Opcional)' | translate }}</span></label>
            </div>

        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <label for="attractions">{{ 'createtourdetails.Puntos de interés incluidos' | translate }}</label>
        </div>

        <ng-container formArrayName="attractions">
            <div class="col-9 col-md-6 col-lg-3" *ngFor="let attraction of attractions.controls; let atractionIndex = index" [formGroup]="attraction">
                <div class="field pb-2">
                    <input (keyup)="onAttractionChanged($event, atractionIndex)" formControlName="name" type="text" name="name" placeholder="" value="">
                    <app-main-translation [i18nTraslation]="i18nTraslation" [mainTranslation]="attraction.get('nameMain').value"></app-main-translation>
                </div>
                <app-feedback [formGroup]="attraction" field="name" validator="required" feedback="{{ 'createtourdetails.Atracción requerida' | translate }}" [refresh]="refreshValidators"></app-feedback>
                <app-feedback [formGroup]="attraction" field="name" validator="maxlength" feedback="{{ 'createtourdetails.Texto demasiado largo' | translate }}" [refresh]="refreshValidators"></app-feedback>
            </div>
        </ng-container>

        <div *ngIf="!i18nTraslation" class="col-3 col-md-6 col-lg-3 mb-4">
            <a (click)="onAddAttraction()" class="btn w-md-50 w-100 btn-tertiary mb-3 mb-sm-0 white-color px-0 px-sm-auto">
                <img class="ic-mid" src="assets/img/ic_add-white.svg">
            </a>
        </div>

        <div class="col-12">
             <app-feedback [formGroup]="createTourDetailsForm" field="attractionsValid" validator="required" feedback="{{ 'createtourdetails.Atracción requerida' | translate }}" [refresh]="refreshValidators"></app-feedback>
        </div>
    </div>

    <div class="row mb-5 pb-5 control-footer">
         <div *ngIf="hasProviderStep" class="col-6 col-md-5 col-lg-4">
            <button (click)="onBackButton()" type="submit" value="{{ 'createtourdetails.Volver' | translate }}" class="btn btn-full-width btn-no-btn mb-3 mb-sm-0 dark-color">
                 <img class="ic-small mr-2" src="assets/img/ic_arrow-back.svg">
                 {{ 'createtourdetails.Volver' | translate }}
            </button>
         </div>

        <div class="col-6 col-md-6 offset-md-6 col-lg-4 offset-lg-8">
            <button (click)="saveDetails()" type="submit" value="{{ 'createtourdetails.Siguiente' | translate }}" class="btn btn-full-width btn-primary mb-3 mb-sm-0 white-color">
                {{ 'createtourdetails.Siguiente' | translate }}
                <img class="ic-small ml-2" src="assets/img/ic_arrow-next.svg">
            </button>
        </div>
    </div>
</form>


<app-message-panel [preloading]="false"></app-message-panel>