import { UserRole } from './user-role.constants';
import { ApiConstants } from 'src/app/business/api/api.constants';

export class UserUtils {

    public static getUserRoleFromApi(apiScopes: string): Array<UserRole> {

         const userRoles: Array<UserRole> = new Array<UserRole>();
         if ( apiScopes.includes(ApiConstants.API_USER_SCOPE_ADMIN) ) {
              userRoles.push(UserRole.Admin);
         }

         if ( apiScopes.includes(ApiConstants.API_USER_SCOPE_PROVIDER) ) {
              userRoles.push(UserRole.Provider);
         }

         if ( apiScopes.includes(ApiConstants.API_USER_SCOPE_AFFILIATE) ) {
              userRoles.push(UserRole.Affiliate);
         }

         if ( apiScopes.includes(ApiConstants.API_USER_SCOPE_GUIDE) ) {
              userRoles.push(UserRole.Guide);
         }

         return userRoles;
    }


}
