import { ApiConstants } from 'src/app/business/api/api.constants';
import { PaymentPlatform } from './payment-platform.constants';

export class PaymentPlatformUtils {

     public static getPaymentPlatformFromApi(paymentPlatform: number): PaymentPlatform {

          if ( paymentPlatform == ApiConstants.API_PAYMENT_PLATFORM_WISE ) {
               return PaymentPlatform.Wise;
          } else if ( paymentPlatform == ApiConstants.API_PAYMENT_PLATFORM_STRIPE ) {
               return PaymentPlatform.Stripe;
          } else {
               return PaymentPlatform.Transference;
          }
     }


     public static getApiPaymentPlatformFromPaymentPlatform(paymentPlatform: PaymentPlatform): number {

          if ( paymentPlatform == PaymentPlatform.Wise ) {
               return ApiConstants.API_PAYMENT_PLATFORM_WISE;
          } else if ( paymentPlatform == PaymentPlatform.Stripe ) {
               return ApiConstants.API_PAYMENT_PLATFORM_STRIPE;
          } else {
               return ApiConstants.API_PAYMENT_PLATFORM_TRANSFERENCE;
          }
     }
}
