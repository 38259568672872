import { Component, OnInit, ViewChild } from '@angular/core';

import { MessagePanelComponent }        from 'src/app/ui/components/shared/message-panel/message-panel.component';
import { ServerErrorManager }           from 'src/app/ui/components/utils/server-error-manager.utils';

import { ChunkGeneric }                 from 'src/app/domain/views/chunk-generic.view';

import { GenericException }             from 'src/app/domain/exceptions/generic.exception';
import { BookingBilling }               from 'src/app/business/models/billing/booking-billing.model';

import { AffiliateBillingInteractor } from 'src/app/domain/interactor/affiliate/billing.interactor';
import { FindBillingFilterView } from 'src/app/domain/views/billing/find-invoices-filter.view';


@Component({
     selector: 'app-list-billing-affiliate',
     templateUrl: './list.component.html'
})
export class AffiliateListBillingComponent implements OnInit {

     @ViewChild(MessagePanelComponent)
     private messagePanelComponent: MessagePanelComponent;

     chunkBookingBilling: ChunkGeneric<BookingBilling>;

     constructor(
          private affiliateBillingInteractor: AffiliateBillingInteractor,
          private serverErrorManager: ServerErrorManager) {

     }

     ngOnInit() {
     }


     onFindBillingTrigger(findBillingFilterView: FindBillingFilterView) {

          if (this.messagePanelComponent) {
            this.messagePanelComponent.showLoading();
          }

          this.affiliateBillingInteractor.findBilling(findBillingFilterView.dateInit, findBillingFilterView.dateEnd, findBillingFilterView.keywords, findBillingFilterView.pageNumber)
          .subscribe(
               (chunkBookingBilling: ChunkGeneric<BookingBilling>) => {

                    this.chunkBookingBilling = chunkBookingBilling;
                    MessagePanelComponent.hideLoading();

               },
               (error: GenericException) => {

                    MessagePanelComponent.hideLoading();
                    this.serverErrorManager.manageErrorCode(error, this.messagePanelComponent);
               }
          )
     }

}
