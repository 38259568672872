import { Pipe, PipeTransform } from '@angular/core';
import { AffiliateState } from 'src/app/business/models/affiliate/common/affiliate-state.constants';

@Pipe({
  name: 'adminAffiliateStateClass'
})
export class AdminAffiliateStateClassPipe implements PipeTransform {

  transform(classLabels: string, state: AffiliateState): String {

    let result = classLabels;
    if ( state == AffiliateState.Active ) {
        result = result + " " + 'normal';
   } else if ( state == AffiliateState.Inactive ) {
        result = result + " " + 'remove';
    }

    return result ;

  }

}
