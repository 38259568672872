import { Component, Input, Output, EventEmitter, OnInit, ViewChild } from '@angular/core';
import { FormGroup, Validators, FormControl, FormArray } from '@angular/forms';
import { FormUtils } from 'src/app/ui/components/utils/form.utils';
import { Country } from 'src/app/business/models/master/country/country.model';
import { City } from 'src/app/business/models/master/city/city.model';
import { ServerErrorManager } from 'src/app/ui/components/utils/server-error-manager.utils';
import { AppConstants } from 'src/app/domain/common/app.constants';
import { GenericException } from 'src/app/domain/exceptions/generic.exception';
import { UserMasterInteractor } from 'src/app/domain/interactor/user/user-master.interactor';
import { MessagePanelComponent } from '../../message-panel/message-panel.component';
import { Affiliate } from 'src/app/business/models/affiliate/affiliate.model';
import { AffiliateDetailsView } from 'src/app/domain/views/affiliate/affiliate-details.view';
import { BrowserConstants } from 'src/app/ui/commons/browser.constants';

@Component({
     selector: 'app-profile-affiliate-details',
     templateUrl: './details.component.html'
})
export class ProfileAffiliateDetailsComponent implements OnInit{

     @ViewChild(MessagePanelComponent)
     private messagePanelComponent: MessagePanelComponent;


     @Input()
     set affiliate(affiliate: Affiliate) {
          if ( affiliate ) {
               this.setAffiliateFormValues(affiliate)
          }
     }

     @Output() affiliateDetails = new EventEmitter<AffiliateDetailsView>(true);

     affiliateDetailsForm: FormGroup
     isDisabled = true

     countries: Array<Country>
     cities: Array<City>

     refreshValidators: boolean

     constructor(
          private userMasterInteractor: UserMasterInteractor,
          private serverErrorManager: ServerErrorManager,
     ) {

          this.refreshValidators = false;

          this.affiliateDetailsForm = new FormGroup({
               'id': new FormControl({ value: '', disabled: true }, [Validators.required] ),
               'companyName': new FormControl({ value: '', disabled: true }, [Validators.required, Validators.maxLength(AppConstants.TEXT_DEFAULT_MAX_LENGTH)] ),
               'address': new FormControl({ value: '', disabled: true }, [Validators.required, Validators.maxLength(AppConstants.TEXT_DEFAULT_MAX_LENGTH)] ),
               'country': new FormControl({ value: '', disabled: true }, [Validators.required] ),
               'city': new FormControl({ value: '', disabled: true }, [Validators.required] ),
               'businessDocumentType': new FormControl({ value: '', disabled: true }, [Validators.required]),
               'businessDocument': new FormControl({ value: '', disabled: true }, [Validators.required, Validators.maxLength(AppConstants.TEXT_DEFAULT_MAX_LENGTH)]),
               'phone': new FormControl({ value: '', disabled: true }, [Validators.required, Validators.maxLength(AppConstants.TEXT_DEFAULT_MAX_LENGTH)] ),
               'businessZeroVat': new FormControl({ value: false, disabled: true }, [] ),
               'websites': new FormArray([])
          });

     }


     ngOnInit() {
          this.userMasterInteractor.getCountriesByUser()
          .subscribe(
               (countries: Array<Country>) => {
                    this.countries = countries;
               },
               (error: GenericException) => {
                    this.serverErrorManager.manageErrorCode(error, this.messagePanelComponent);
               }
          )
     }


     toggleEdition() {

          this.isDisabled = !this.isDisabled

          if ( this.isDisabled ) {
               FormUtils.disableAllControls(this.affiliateDetailsForm);
          } else {
               FormUtils.enableAllControls(this.affiliateDetailsForm);
          }
     }


     saveDetails() {

          this.refreshValidators = true;

          if ( this.affiliateDetailsForm.valid && this.websitesValid() ) {

               const websites: Array<string> = new Array<string>();
               this.websites.controls.map( (website: FormGroup) => {
                    let urlValue = website.get('url').value;
                    if ( urlValue ) {
                         urlValue = urlValue.trim();
                    }
                    if ( urlValue != '' ) {
                         websites.push(urlValue);
                    }
               });

               this.affiliateDetails.emit(
                    new AffiliateDetailsView(
                         this.affiliateDetailsForm.value.id,
                         this.affiliateDetailsForm.value.companyName,
                         this.affiliateDetailsForm.value.phone,
                         this.affiliateDetailsForm.value.country,
                         this.affiliateDetailsForm.value.city,
                         this.affiliateDetailsForm.value.address,
                         this.affiliateDetailsForm.value.businessDocumentType,
                         this.affiliateDetailsForm.value.businessDocument,
                         this.affiliateDetailsForm.value.businessZeroVat,
                         websites
                    )
               );

               this.toggleEdition();

          }

     }

     get websites(): FormArray {
          return this.affiliateDetailsForm.get('websites') as FormArray;
     }


     private setAffiliateFormValues( affiliate: Affiliate ) {

          this.affiliateDetailsForm.controls['id'].setValue(affiliate.id);
          this.affiliateDetailsForm.controls['companyName'].setValue(affiliate.companyName);
          this.affiliateDetailsForm.controls['address'].setValue(affiliate.businessAddress);

          this.affiliateDetailsForm.controls['businessDocumentType'].setValue(affiliate.businessDocumentType);
          this.affiliateDetailsForm.controls['businessDocument'].setValue(affiliate.businessDocument);
          this.affiliateDetailsForm.controls['businessZeroVat'].setValue(affiliate.businessZeroVat);
          this.affiliateDetailsForm.controls['phone'].setValue(affiliate.phone);

          this.affiliateDetailsForm.controls['city'].setValue(affiliate.cityId);
          this.affiliateDetailsForm.controls['country'].setValue(affiliate.countryId);
          if ( affiliate.countryId ) {
               this.userMasterInteractor.getCitiesByUser(affiliate.countryId)
               .subscribe(
                    (cities: Array<City>) => {
                         this.cities = cities;
                    },
                    (error: GenericException) => {
                         this.serverErrorManager.manageErrorCode(error, this.messagePanelComponent);
                    }
               )
          }


          this.websites.clear();
          if ( affiliate.webs.length > 0 ) {
               for ( const website of affiliate.webs ) {
                    this.websites.controls.push(
                         new FormGroup({
                              'url': new FormControl({ value: website, disabled: this.isDisabled }, [Validators.pattern(AppConstants.REGULAR_EXPRESSION_VALIDATE_WEBSITE), Validators.maxLength(AppConstants.TEXT_DEFAULT_MAX_LENGTH)])
                         })
                    );
               }
          } else {
               this.onAddWebsite();
          }

     }



     onAddWebsite() {
          let existEmptyWebsites = false;
          this.websites.controls.map( (websiteControl: FormControl) => {
               const urlValue = websiteControl.get('url').value.trim();
               if ( urlValue == '' ) {
                    existEmptyWebsites = true;
               }
          })
          if ( !existEmptyWebsites ) {
               this.websites.controls.push(
                    new FormGroup({
                         'url': new FormControl({ value: '', disabled: this.isDisabled }, [Validators.pattern(AppConstants.REGULAR_EXPRESSION_VALIDATE_WEBSITE), Validators.maxLength(AppConstants.TEXT_DEFAULT_MAX_LENGTH)])
                    })
               );
          }
     }

     onWebsiteChanged(event: any, websiteIndex: number) {
          if ( event.keyCode == BrowserConstants.KEYBOARD_BACKSPACE_CODE && this.websites.at(websiteIndex) && this.websites.at(websiteIndex).get('url').value == '' && websiteIndex > 0) {
               this.websites.removeAt(websiteIndex);
          }
     }

     onRemoveWebsite(websiteIndex: number) {
          //Remove if not the last item and rest otherwise
          if (this.websites.length == 1) {
               this.websites.at(websiteIndex).setValue('');
          } else {
               this.websites.removeAt(websiteIndex);
          }
     }


     onCountrySelected() {

          const selectedCountryId = this.affiliateDetailsForm.value.country;

          this.cities = new Array<City>();
          this.affiliateDetailsForm.controls['city'].setValue(null);

          if ( selectedCountryId ) {

               this.userMasterInteractor.getCitiesByUser(selectedCountryId)
               .subscribe(
                    (cities: Array<City>) => {
                         this.cities = cities;
                    },
                    (error: GenericException) => {
                         this.serverErrorManager.manageErrorCode(error, this.messagePanelComponent);
                    }
               )

          }

     }

     websitesValid(): boolean {

          let isWebsitesValid = true;
          this.websites.controls.map( (website: FormGroup) => {
               if ( !website.get('url').valid ) {
                    isWebsitesValid = false;
               }
          });

          return isWebsitesValid;

     }


}
