import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BaseService } from '../base.service';
import { TourUtils } from '../../models/tour/common/tour.utils';
import { Tour } from '../../models/tour/tour.model';
import { CommonApiEndpointsUtils } from '../../api/endpoints/common-api-endponints.utils';

@Injectable({
  providedIn: 'root'
})
export class PublicTourService extends BaseService {


     getTour(tourId: number, languageCode: string, tourI18NDisplay: number): Observable<Tour> {

       return super.sendGetRequestWithRefresh(CommonApiEndpointsUtils.getPublicTourDetailsEndpoint(tourId, languageCode), CommonApiEndpointsUtils.getPublicTourDetailsEndpointAcceptVersion())
         .pipe(
           map((tourApi: any) => {

             return TourUtils.getTourFromApi(tourApi, tourI18NDisplay);

           })
         )
     }


}
