export class Pagination {

    private _totalItems: number;
    private _currentPage: number;
    private _itemsPerPage: number;

    constructor(totalItems: number, currentPage: number, itemsPerPage: number) {
        this._totalItems = totalItems;
        this._currentPage = currentPage;
        this._itemsPerPage = itemsPerPage;
    }

    get itemsPerPage(): number {
        return this._itemsPerPage;
    }

    get currentPage(): number {
        return this._currentPage;
    }

    get totalItems(): number {
        return this._totalItems;
    }



}
