import { Component, Input, EventEmitter, Output } from '@angular/core';
import { CalendarView, CalendarEvent, CalendarMonthViewDay } from 'angular-calendar';
import * as moment from 'moment-timezone';

import { RRule, Weekday } from 'rrule'

import { SessionInteractor } from 'src/app/domain/interactor/session.interactor';
import { TourScheduleWeekdaysType } from 'src/app/domain/common/tour/tour-schedule-weekdays.constants';
import { AppConstants } from 'src/app/domain/common/app.constants';
import { ScheduleDateWeekdayView } from 'src/app/domain/views/provider/tour/schedule-date-weekday.view';
import { Subject } from 'rxjs/internal/Subject';
import { CalendarUtils } from 'src/app/ui/utils/calendar.utils';


const colors: any = {
  red: {
    primary: '#ad2121',
    secondary: '#FAE3E3'
  },
  blue: {
    primary: '#1e90ff',
    secondary: '#D1E8FF'
  },
  yellow: {
    primary: '#e3bc08',
    secondary: '#FDF1BA'
  }
};


@Component({
  selector: 'app-create-tour-schedule-calendar',
  templateUrl: './calendar.component.html'
})
export class CreateTourScheduleCalendarComponent {

  @Output() calendarSelectedDate = new EventEmitter<ScheduleDateWeekdayView>(true);

  @Input()
  set minCalendarDate(minCalendarDate: Date) {
      this.minDate = minCalendarDate;
      this.updateAndRefresh();
  }

  @Input()
  set maxCalendarDate(maxCalendarDate: Date) {
      this.maxDate = maxCalendarDate;
      this.updateAndRefresh();
  }




  @Input()
  set weekdaysIncluded(weekdaysIncluded: Array<TourScheduleWeekdaysType>) {

    this.weekdaysDays = weekdaysIncluded;
    this.calendarEvents = new Array<CalendarEvent>();
    this.updateAndRefresh();

  }


  @Input()
  set exceptionsIncluded(exceptionsIncluded: Array<string>) {

    this.exceptionDates = exceptionsIncluded;
    this.calendarEvents = new Array<CalendarEvent>();
    this.updateAndRefresh();

  }


  //States
  weekdaysDays: Array<TourScheduleWeekdaysType>;
  exceptionDates: Array<string>;
  minDate: Date;
  maxDate: Date;


  currentSelectedViewDay: CalendarMonthViewDay;


  //Calendar settings
  weekStartOnDay: number;
  viewDate: Date;
  calendarEvents: Array<CalendarEvent>;
  view: CalendarView;
  currentLocale: string;

  refreshCalendar: Subject<any> = new Subject();

  constructor(private sessionInteractor: SessionInteractor) {

      this.viewDate = new Date();
      this.view = CalendarView.Month;
      this.weekStartOnDay = AppConstants.CALENDAR_WEEK_START_ON_DAY;

      this.calendarEvents = new Array<CalendarEvent>();
      //this.weekdaysDays = new Array<TourScheduleWeekdaysType>();
      this.exceptionDates = new Array<string>();

      this.currentLocale = this.sessionInteractor.getCurrentLanguage();

  }


  onDateCalendarSelected(event: any) {

      const oldCurrentSelectedViewDay = this.currentSelectedViewDay;
      this.currentSelectedViewDay = event.day;

      //If is valid and
      //Not previus selected or selected is not the previus selected
      if ( this.isDateValid(this.currentSelectedViewDay.date) &&
            (
              !oldCurrentSelectedViewDay || ( oldCurrentSelectedViewDay && (this.currentSelectedViewDay.date != oldCurrentSelectedViewDay.date) )
            )
          ) {

              this.currentSelectedViewDay.cssClass = 'cal-day-selected';
              this.calendarSelectedDate.emit(new ScheduleDateWeekdayView(CalendarUtils.fromCalendarWeekdayToTourWeekday(this.currentSelectedViewDay.day), this.currentSelectedViewDay.date));

      } else {
          this.currentSelectedViewDay = null;
          this.calendarSelectedDate.emit(null);
      }

  }



  onBeforeCalendarRender({ body }: { body: CalendarMonthViewDay[] }): void {
      body.forEach(day => {
          if ( !this.isDateValid(day.date) ) {
              day.cssClass = 'cal-disabled';
          }
      });
  }


  onMonthNavigation() {
      this.currentSelectedViewDay = null;
      this.calendarSelectedDate.emit(null);
  }


  private updateAndRefresh() {
      this.calendarEvents.length = 0;
      this.updateWeekdays();
      this.updateDayExceptions();
      this.refreshCalendar.next();
  }


  private updateWeekdays() {

      if ( this.weekdaysDays && this.minDate && this.maxDate ) {

          const ruleWeekdays: Array<Weekday> = this.weekdaysDays.map( (weekday) => {
              return CalendarUtils.fromTourWeekdayToCalendarRuleWeekday(weekday);
          })

          if ( ruleWeekdays.length > 0 ) {

              //Create general rules
              const rule = new RRule({
                  freq: RRule.WEEKLY,
                  wkst: RRule.MO,
                  byweekday: ruleWeekdays,
                  interval: 1,
                  dtstart: CalendarUtils.fromDateToUTCDate(this.minDate),
                  until: CalendarUtils.fromDateToUTCDate(this.maxDate)
              });


              //Include rules on calendar
              rule.all().forEach(date => {

                    this.calendarEvents.push({
                        title: '',
                        //color: colors.blue,
                        start: moment(date).toDate()
                    });
              });
          }
      }
  }

  private updateDayExceptions() {

      this.exceptionDates.forEach( dateException => {

          this.calendarEvents.push({
              title: '',
              color: colors.yellow,
              start: CalendarUtils.fromDaleLocalizedDDMMYYYYToDate(dateException)
          });

      });

  }


  private isDateValid(date: Date) {
      //Is a valid date or has exceptions
      const validDate = date >= CalendarUtils.getStartofDay(this.minDate) && date <= CalendarUtils.getEndOfDay(this.maxDate);
      let hasExceptions = false;
      this.exceptionDates.map( (exceptionDate: string) => {
          const dateAsDDMMYY = CalendarUtils.fromDateToDaleLocalizedDDMMYYYY(date);
          if ( exceptionDate == dateAsDDMMYY ) {
              hasExceptions = true;
          }
      })

      return validDate || hasExceptions;
  }


}
