import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { TourDetailsFormView } from 'src/app/domain/views/provider/tour/details-form.view';
import { TourFormView } from 'src/app/domain/views/provider/tour/tour-form.view';
import { TourUsersFormView } from 'src/app/domain/views/provider/tour/users-form.view';
import { TourLocationFormView } from 'src/app/domain/views/provider/tour/location-form.view';
import { CreateTourStep } from 'src/app/domain/common/tour/tour-create-state.constants';
import { TourStepFormView } from 'src/app/domain/views/provider/tour/sept-form.view';
import { ProviderTourInteractor } from 'src/app/domain/interactor/provider/tour.interactor';
import { MessagePanelComponent } from 'src/app/ui/components/shared/message-panel/message-panel.component';
import { TourScheduleFormView } from 'src/app/domain/views/provider/tour/schedule-form.view';
import { marker as I18N } from '@biesbjerg/ngx-translate-extract-marker';
import { Language } from 'src/app/business/models/master/language/language.model';
import { ActivatedRoute, Router } from '@angular/router';
import { NavigationConstants } from 'src/app/ui/commons/navigation.constants';
import { Tour } from 'src/app/business/models/tour/tour.model';
import { TourUtils } from 'src/app/ui/components/utils/tour/Tour.utils';
import { ServerErrorManager } from 'src/app/ui/components/utils/server-error-manager.utils';
import { GenericException } from 'src/app/domain/exceptions/generic.exception';
import { UserMasterInteractor } from 'src/app/domain/interactor/user/user-master.interactor';

@Component({
     selector: 'app-create-tour',
     templateUrl: './create.component.html'
})
export class CreateTourComponent implements OnInit {


     @ViewChild(MessagePanelComponent)
     private messagePanelComponent: MessagePanelComponent;


     model: NgbDateStruct;
     date: {year: number, month: number};

     tourForm: TourFormView;


     i18nTraslation: boolean;

     isDetailsSection: boolean;
     isUsersSection: boolean;
     isLocationSection: boolean;
     isDateSection: boolean;

     currentFormStep: CreateTourStep;

     tourLanguagesDisplayed: Array<Language>;

     languageIdI18N: number;


     constructor(
          private providerTourInteractor: ProviderTourInteractor,
          private userMasterInteractor: UserMasterInteractor,
          private activatedRoute: ActivatedRoute,
          private router: Router,
          private serverErrorManager: ServerErrorManager
     ) {


          this.userMasterInteractor.getTourDisplayedLanguages()
          .subscribe(
               (languages: Array<Language>) => {
                    this.tourLanguagesDisplayed = languages;
                    if ( !this.languageIdI18N ) {
                         this.languageIdI18N = this.tourLanguagesDisplayed[0].id;
                    }
               },
               (error: GenericException) => {
                    this.serverErrorManager.manageErrorCode(error, this.messagePanelComponent);
               }
          )


          this.updateFormStepActive(CreateTourStep.detailsStep);

          this.tourForm = new TourFormView();
          this.i18nTraslation = false;

          this.activatedRoute.params.subscribe( params => {
               const tourId = params[ NavigationConstants.NAVIGATION_ADMIN_TOUR_UPDATE_ID ];



               //Load tour details if needed
               if ( tourId ) {
                    this.getTourDetailsAndReload(tourId, null);
               }

          });

     }

     ngOnInit() {

     }


     getTourDetailsAndReload(tourId: number, languageI18N: number) {
          this.providerTourInteractor.getTour(tourId, languageI18N)
          .subscribe(
               (tour: Tour) => {

                    this.tourForm = TourUtils.getTourFromViewToUpdate(tour, null);
                    this.languageIdI18N = this.tourForm.languageIdI18N;
                    this.i18nTraslation = tour.languageI18N.id != tour.languageMain.id;
                    this.updateFormStepActive(CreateTourStep.detailsStep);
               },
               (error) => {
                    this.serverErrorManager.manageErrorCode(error, this.messagePanelComponent);
               }
          )
     }




     onTourDetailsNext(tourDetails: TourStepFormView<TourDetailsFormView>) {

          this.tourForm.tourDetails = tourDetails.tourFormStep;

          if ( tourDetails.isNextStep ) {
               this.updateFormStepActive(CreateTourStep.usersStep);
          }


     }

     onTourUsersNext(tourUsers: TourStepFormView<TourUsersFormView>) {

          this.tourForm.tourUsers = tourUsers.tourFormStep;

          if ( tourUsers.isNextStep ) {
               this.updateFormStepActive(CreateTourStep.locationStep);
          } else {
               this.updateFormStepActive(CreateTourStep.detailsStep);
          }


     }

     onTourLocationNext(tourLocation: TourStepFormView<TourLocationFormView>) {

          this.tourForm.tourLocation = tourLocation.tourFormStep;

          if ( tourLocation.isNextStep ) {
               this.updateFormStepActive(CreateTourStep.scheduleStep);
          } else {
               this.updateFormStepActive(CreateTourStep.usersStep);
          }

     }


     onTourScheduleEmitter(tourSchedule: TourStepFormView<TourScheduleFormView>) {

          this.tourForm.tourSchedule = tourSchedule.tourFormStep;
          this.tourForm.languageIdI18N = this.languageIdI18N;

          if ( tourSchedule.isNextStep ) {

               this.messagePanelComponent.showLoading();

               this.providerTourInteractor.updateTour(this.tourForm, this.i18nTraslation)
               .subscribe(
                    () => {

                         MessagePanelComponent.hideLoading();
                         this.messagePanelComponent.showSuccessMessage(I18N('createtour.Datos guardados correctamente'), () => {
                              this.updateFormStepActive(CreateTourStep.detailsStep);
                              this.router.navigate( [ NavigationConstants.getNavigationProviderListTours() ] );
                         });

                    },
                    (error: GenericException) => {

                         MessagePanelComponent.hideLoading();
                         this.serverErrorManager.manageErrorCode(error, this.messagePanelComponent);

                    }
               )


          } else {
               this.updateFormStepActive(CreateTourStep.locationStep);
          }


     }




     selectToday() {
          //this.model = this.calendar.getToday();
     }



     updateFormStepActive(formPartUpdated: CreateTourStep) {
          this.currentFormStep = formPartUpdated;
          this.isDetailsSection = this.currentFormStep == CreateTourStep.detailsStep;
          this.isUsersSection = this.currentFormStep == CreateTourStep.usersStep;
          this.isLocationSection = this.currentFormStep == CreateTourStep.locationStep;
          this.isDateSection = this.currentFormStep == CreateTourStep.scheduleStep;
     }


     onTourI18NChange(languageIdI18N: number) {
          if ( this.languageIdI18N != languageIdI18N ) {
               this.messagePanelComponent.showConfirmationMessage(I18N('createtour.Los cambios no guardados en este idioma se perderán.'), () => {
                    const tourId = this.tourForm.id;
                    if ( tourId ) {
                         this.getTourDetailsAndReload(this.tourForm.id, languageIdI18N);
                    } else {
                         this.languageIdI18N = languageIdI18N;
                    }
               });
          }
     }



}
