export class TourStepFormView<T> {

    private _isNextStep: boolean
    private _tourFormStep: T

    constructor(isNextStep: boolean, tourFormStep: T) {
        this._isNextStep = isNextStep
        this._tourFormStep = tourFormStep
    }

    get isNextStep(): boolean {
        return this._isNextStep;
    }

    get tourFormStep(): T {
        return this._tourFormStep;
    }

}