<div class="row">
    <div class="col-12">
        <div class="wizard-progress">
             <div *ngIf="showProviderStep" [ngClass]=" 'step' | createTourStepClass: providerStep : currentFormStep">
                 <div class="node"></div>
                 <span>{{ 'createtour.Proveedor' | translate }}</span>
             </div>
            <div [ngClass]=" 'step' | createTourStepClass: detailsStep : currentFormStep">
                <div class="node"></div>
                <span>{{ 'createtour.Qué' | translate }}</span>
            </div>
            <div [ngClass]=" 'step' | createTourStepClass: usersStep : currentFormStep">
                <div class="node"></div>
                <span>{{ 'createtour.Quién' | translate }}</span>
            </div>
            <div [ngClass]=" 'step' | createTourStepClass: locationStep : currentFormStep">
                <div class="node"></div>
                <span>{{ 'createtour.Dónde' | translate }}</span>
            </div>
            <div [ngClass]=" 'step' | createTourStepClass: scheduleStep : currentFormStep">
                <div class="node"></div>
                <span>{{ 'createtour.Cuándo' | translate }}</span>
            </div>
        </div>
    </div>
</div>