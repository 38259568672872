import { GuideBillingTourStats } from './billing-tour-stats.model';

export class GuideBillingStats {

     private _toursDetailsStats: Array<GuideBillingTourStats>;
     private _totalCommissions: number;

     constructor(
          toursDetailsStats: Array<GuideBillingTourStats>,
          totalCommissions: number
     ) {
          this._toursDetailsStats = toursDetailsStats;
          this._totalCommissions = totalCommissions;
     }

     get toursDetailsStats(): Array<GuideBillingTourStats> {
          return this._toursDetailsStats;
     }

     get totalCommissions(): number {
          return this._totalCommissions;
     }

}