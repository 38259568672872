<div class="col-12 mt-5">
  <div class="row">
    <div class="col-12 text-left pl-md-0 pr-md-3 px-0">
      <h2 class="font-weight-bold mb-4">{{ 'invoiceslist.Historial de facturación' | translate }}</h2>
    </div>
  </div>

  <div class="row empty-section" *ngIf="noResults">
    <div class="col-12 text-center my-auto">
      <img class="logo mb-4" src="assets/img/logo_opacity-21.png">
      <p>{{ 'invoiceslist.No se han encontrado resultados' | translate }}</p>
    </div>
  </div>
</div>

<div class="col-12 px-0" *ngIf="!noResults">
  <div class="card table-responsive" *ngFor="let invoice of invoices | paginate: pagination;">

    <div class="row mb-3">
      <div class="col-12 text-right">

        <label class="btn-more-vert dropdown">
          <div class="dd-button">
            <img class="ic-mid" src="assets/img/ic_more-vert.svg">
          </div>

          <input type="checkbox" class="dd-input" id="test">

          <ul class="dd-menu">

            <li>
              <a (click)="onInvoiceDetails(invoice.invoiceDetails.id)">
                {{ 'invoiceslist.Ver detalles' | translate }}
              </a>
            </li>

            <li class="divider"></li>

            <li>
              <a (click)="onInvoiceDownload(invoice.invoiceDetails)">
                {{ 'invoiceslist.Descargar factura' | translate }}
              </a>
            </li>

            <li class="divider"></li>

            <li *ngIf="invoice.invoiceDetails.paymentPending">
              <a (click)="onPaymentToProvider(invoice.invoiceDetails.id)">
                {{ 'invoiceslist.Pagar al proveedor' | translate }}
              </a>
            </li>

            <li class="divider"></li>

            <li *ngIf="invoice.invoiceDetails.chargePending || invoice.invoiceDetails.paymentPending">
              <a (click)="onManualSettleUp(invoice.invoiceDetails.id)">
                {{ 'invoiceslist.Marcar como pagado' | translate }}
              </a>
            </li>

            <li class="divider"></li>

            <li *ngIf="invoice.invoiceDetails.chargePending">
              <a (click)="onResendPaymentRequest(invoice.invoiceDetails.id)">
                {{ 'invoiceslist.Enviar recordatorio' | translate }}
              </a>
            </li>


          </ul>

        </label>

      </div>
    </div>

    <div class="row mb-2">
      <div class="col-6 title">
        {{ 'invoiceslist.Fecha' | translate }}
      </div>
      <div class="col-6 title">
        {{ 'invoiceslist.Período de facturación' | translate }}
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-6 name-contact-provider">
        {{ invoice.invoiceDetails.invoiceDate }}
      </div>
      <div class="col-6 name-contact-provider">
        {{ invoice.invoiceDetails.invoiceMonthPeriod }}/{{ invoice.invoiceDetails.invoiceYearPeriod }}
      </div>
    </div>



    <div class="row mb-2">
      <div class="col-6 title">
        {{ 'invoiceslist.Número de factura' | translate }}
      </div>
      <div class="col-6 title">
        {{ 'invoiceslist.Importe' | translate }}
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-6 name-contact-provider">
        {{ invoice.invoiceDetails.serialNumber }}
      </div>
      <div class="col-6 name-contact-provider">
        {{ invoice.invoiceDetails.billingDetails.commission }} &euro;
      </div>
    </div>


    <div class="row mb-2">
      <div class="col-6 title">
        {{ 'invoiceslist.Proveedor' | translate }}
      </div>
      <div class="col-6 title">
        {{ 'invoiceslist.Estado' | translate }}
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-6 name-contact-provider">
        {{ invoice.company.companyName }}
      </div>
      <div class="col-6 name-contact-provider">
        <div [ngClass]="'status' | invoiceStateClassPipe: invoice.invoiceDetails.settledUp">
          <div class="dot"></div> {{ invoice.invoiceDetails.settledUp | invoiceStatePayment | translate }}
        </div>
      </div>
    </div>

    <div class="row mb-2">
      <div class="col-6 title">
        {{ 'invoiceslist.Tipo' | translate }}
      </div>
      <div class="col-6 title">
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-6 name-contact-provider">
        {{ invoice.invoiceDetails.chargePending | invoicePaymentTypeAdminPipe: invoice.invoiceDetails.chargeCompleted : invoice.invoiceDetails.paymentPending : invoice.invoiceDetails.paymentCompleted | translate }}
        {{ invoice.invoiceDetails.billingDetails.payment | invoicePaymentTypeAmountPipe }}
      </div>
      <div class="col-6 name-contact-provider">

      </div>
    </div>

  </div>
</div>