import { ApiConstants } from '../api.constants';
import { ApiUtils } from '../api.utils';
import { BillingUtils } from '../../models/billing/common/billing.utils';
import { CalendarUtils } from 'src/app/ui/utils/calendar.utils';
import { GuideServiceState } from '../../models/billing/common/guide-service-state.constants';

export class AdminGuideApiEndpointsUtils {


     public static getAdminGuidesNamesEndpoint(providerId: number): string {
          return ApiUtils.prepareQueryRequest('admin/guides/names', { 'providerId': providerId });
     }

     public static getAdminGuidesNamesEndpointAcceptVersion(): string {
          return ApiConstants.API_HEADER_ACCEPT_V_1_0_1;
     }


     public static getAdminCreateGuideServiceEndpoint(): string {
          return `admin/guides/services`;
     }

     public static getAdminCreateGuideServiceObject(
          guideId: number, tourId: number, tourName: string, date: Date, hour: string, people: number, amount: number
     ): any {

          const dateApi = CalendarUtils.fromDateToFormatString(date, ApiConstants.API_DATE_FORMAT_DD_MM_YYYY);
          return {
            'guideId': guideId,
            'tourId': tourId,
            'tourName': tourName,
            'day': dateApi,
            'hour': hour,
            'people': people,
            'amount': amount
          }
     }

     public static getAdminCreateGuideServiceAcceptVersion(): string {
          return ApiConstants.API_HEADER_ACCEPT_V_1_0_1;
     }



     public static getAdminFindGuideServicesEndpoint(month: number, year: number, providerId: number, guideId: number, state: GuideServiceState, page: number): string {

          let date = null;
          if ( month != null && year != null ) {
               date = month + '-' + year;
          }

          let stateApi = null;
          if ( state != null ) {
               stateApi = BillingUtils.getStateApiFromGuideServiceState(state);
          }

          return ApiUtils.prepareQueryRequest('admin/guides/services', { 'month': date, 'providerId': providerId, 'guideId': guideId, 'state': stateApi, 'page': page });
     }

     public static getAdminFindGuideServicesEndpointAcceptVersion(): string {
          return ApiConstants.API_HEADER_ACCEPT_V_1_0_1;
     }


     public static getAdminUpdateGuideServiceEndpoint(guideServiceId: number): string {
          return `admin/guides/services/${guideServiceId}`;
     }

     public static getAdminUpdateGuideServiceAcceptVersion(): string {
          return ApiConstants.API_HEADER_ACCEPT_V_1_0_1;
     }




     public static getAdminFindGuideServiceEndpoint(guideServiceId: number): string {

          return `admin/guides/services/${guideServiceId}`;
     }

     public static getAdminFindGuideServiceEndpointAcceptVersion(): string {
          return ApiConstants.API_HEADER_ACCEPT_V_1_0_1;
     }


     public static getAdminFindGuideServicesStatsEndpoint(month: number, year: number, providerId: number, guideId: number): string {

          let date = null;
          if ( month != null && year != null ) {
               date = month + '-' + year;
          }
          return ApiUtils.prepareQueryRequest('admin/guides/services/stats', { 'month': date, 'providerId': providerId, 'guideId': guideId });
     }

     public static getAdminFindGuideServicesStatsAcceptVersion(): string {
          return ApiConstants.API_HEADER_ACCEPT_V_1_0_1;
     }




     public static getUpdateGuideServiceStateEndpoint(guideServiceId: number): string {
          return `admin/guides/services/${guideServiceId}/state`;
     }

     public static getUpdateGuideServiceStateObject(
          state: GuideServiceState
     ): any {

          const stateApi = BillingUtils.getStateApiFromGuideServiceState(state);
          return {
            'state': stateApi
          }
     }

     public static getUpdateGuideServiceStateAcceptVersion(): string {
          return ApiConstants.API_HEADER_ACCEPT_V_1_0_1;
     }



     public static getFindToursForManagementEndpoint(guideId: number): string {
          return `admin/guides/${guideId}/managedtours`;
     }

     public static getFindToursForManagementAcceptVersion(): string {
          return ApiConstants.API_HEADER_ACCEPT_V_1_0_1;
     }

}