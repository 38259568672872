import { RouterModule, Routes } from '@angular/router';

import { NavigationConstants } from 'src/app/ui/commons/navigation.constants';
import { ProviderComponent } from './provider.component';
import { ProviderDashboardComponent } from './dashboard/dashboard.component';
import { ProviderProfileComponent } from './profile/profile.component';
import { ProviderAuthorizedGuard } from 'src/app/ui/guards/providerauthorized.guard';
import { ListToursComponent } from './tour/list/list.component';
import { CreateTourComponent } from './tour/create/create.component';
import { ProviderListBookingComponent } from './booking/list/list.component';
import { DetailBookingComponent } from './booking/detail/detail.component';
import { TourGalleryManagmentComponent } from './tour/gallery/gallery-managment.component';
import { ProviderTutorialComponent } from './tutorial/tutorial.component';
import { ProviderListBillingComponent } from './billing/list/list.component';
import { ProviderListInvoicesComponent } from './billing/invoices/list/list.component';
import { ProviderInvoicdeDetailsComponent } from './billing/invoices/details/invoice-details.component';
import { CreateGuideComponent } from './guide/create/create.component';
import { ListGuidesComponent } from './guide/list/list.component';
import { DetailGuideComponent } from './guide/detail/detail.component';
import { ProviderGuidesAuthorizedGuard } from 'src/app/ui/guards/providerguideauthorized.guard';
import { ProviderListGuideServiceComponent } from './guide/billing/services/list/list.component';
import { ProviderGuideListSalesComponent } from './guide/billing/commissions/list/list.component';
import { ProviderCreateGuideServiceComponent } from './guide/billing/services/create/create.component';


const providerRoutes: Routes = [
    {
        path: NavigationConstants.NAVIGATION_PROVIDER,
        component: ProviderComponent,
        children: [
            { path: NavigationConstants.NAVIGATION_PROVIDER_DASHBOARD, component: ProviderDashboardComponent },
            { path: NavigationConstants.NAVIGATION_PROVIDER_PROFILE, component: ProviderProfileComponent },
            { path: NavigationConstants.NAVIGATION_PROVIDER_LIST_TOURS, component: ListToursComponent },
            { path: NavigationConstants.NAVIGATION_PROVIDER_CREATE_TOUR, component: CreateTourComponent },
            { path: NavigationConstants.NAVIGATION_PROVIDER_UPDATE_TOUR, component: CreateTourComponent },
            { path: NavigationConstants.NAVIGATION_PROVIDER_TOUR_GALLERY_MANAGMENT, component: TourGalleryManagmentComponent },
            { path: NavigationConstants.NAVIGATION_PROVIDER_LIST_BOOKING, component: ProviderListBookingComponent },
            { path: NavigationConstants.NAVIGATION_PROVIDER_DETAIL_BOOKING, component: DetailBookingComponent },
            { path: NavigationConstants.NAVIGATION_PROVIDER_TUTORIAL, component: ProviderTutorialComponent },
            { path: NavigationConstants.NAVIGATION_PROVIDER_BILLING, component: ProviderListBillingComponent },
            { path: NavigationConstants.NAVIGATION_PROVIDER_INVOICES, component: ProviderListInvoicesComponent },
            { path: NavigationConstants.NAVIGATION_PROVIDER_INVOICE_DETAILS, component: ProviderInvoicdeDetailsComponent },
            { path: NavigationConstants.NAVIGATION_PROVIDER_LIST_GUIDES, component: ListGuidesComponent, canActivate: [ ProviderGuidesAuthorizedGuard ] },
            { path: NavigationConstants.NAVIGATION_PROVIDER_CREATE_GUIDE, component: CreateGuideComponent, canActivate: [ ProviderGuidesAuthorizedGuard ] },
            { path: NavigationConstants.NAVIGATION_PROVIDER_GUIDE_DETAIL, component: DetailGuideComponent, canActivate: [ ProviderGuidesAuthorizedGuard ] },
            { path: NavigationConstants.NAVIGATION_PROVIDER_GUIDE_BILLING, component: ProviderGuideListSalesComponent, canActivate: [ ProviderGuidesAuthorizedGuard ] },
            { path: NavigationConstants.NAVIGATION_PROVIDER_GUIDE_SERVICES, component: ProviderListGuideServiceComponent, canActivate: [ ProviderGuidesAuthorizedGuard ] },
            { path: NavigationConstants.NAVIGATION_PROVIDER_GUIDE_SERVICE_CREATE, component: ProviderCreateGuideServiceComponent, canActivate: [ ProviderGuidesAuthorizedGuard ] },
            { path: NavigationConstants.NAVIGATION_PROVIDER_GUIDE_SERVICE_UPDATE, component: ProviderCreateGuideServiceComponent, canActivate: [ ProviderGuidesAuthorizedGuard ] }


        ],
        canActivate: [ ProviderAuthorizedGuard ]
    }
];


export const PROVIDER_ROUTES = RouterModule.forChild( providerRoutes );
