import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticateInteractor } from 'src/app/domain/interactor/authenticate.interactor';
import { MessagePanelComponent } from '../../../shared/message-panel/message-panel.component';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { AppConstants } from 'src/app/domain/common/app.constants';
import { NavigationConstants } from 'src/app/ui/commons/navigation.constants';
import { marker as I18N } from '@biesbjerg/ngx-translate-extract-marker';
import { ServerErrorManager } from '../../../utils/server-error-manager.utils';
import { GenericException } from 'src/app/domain/exceptions/generic.exception';

@Component({
  selector: 'app-remember-password',
  templateUrl: './remember-password.component.html'
})
export class RememberPasswordComponent implements OnInit {

  @ViewChild(MessagePanelComponent)
  private messagePanelComponent: MessagePanelComponent;

  rememberPasswordForm: FormGroup;
  refreshValidators: boolean;

  constructor(
    private authenticateInteractor: AuthenticateInteractor,
    private router: Router,
    private serverErrorManager: ServerErrorManager) {

      this.refreshValidators = false;

      this.rememberPasswordForm = new FormGroup({
        'email':  new FormControl(
                  '',
                  [
                    Validators.required,
                    Validators.email,
                    Validators.maxLength(AppConstants.TEXT_DEFAULT_MAX_LENGTH)
                  ])
      });
  }

  ngOnInit() {
  }


  rememberPassword() {

      this.refreshValidators = true;

      if ( this.rememberPasswordForm.valid ) {

        this.messagePanelComponent.showLoading();

        this.authenticateInteractor.rememberPassword(this.rememberPasswordForm.value.email)
            .subscribe(
                (success: boolean) => {

                    MessagePanelComponent.hideLoading();

                    if ( success ) {
                        this.messagePanelComponent.showSuccessMessage(I18N('rememberpasssword.Por favor, revisa tu email'), () => this.goToLogin());

                    } else {
                        this.messagePanelComponent.showErrorMessage(I18N('rememberpasssword.No existe una cuenta vinculada a este email'));
                    }
                },
                (error: GenericException) => {

                    MessagePanelComponent.hideLoading();

                    if ( error.code === AppConstants.ERROR_CLIENT_NOT_FOUND ) {
                        this.messagePanelComponent.showErrorMessage(I18N('rememberpasssword.No existe una cuenta vinculada a este email'));
                    } else {
                        this.serverErrorManager.manageErrorCode(error, this.messagePanelComponent);
                    }
                }
            )
      }
  }


  goToLogin() {
      this.router.navigate([ NavigationConstants.NAVIGATION_LOGIN ]);
  }

  goToHomeLanding() {
    this.router.navigate( [ NavigationConstants.getNavigationHome() ] );
  }

  goToContact() {
    this.router.navigate( [ NavigationConstants.getNavigationHome() ], { fragment: NavigationConstants.NAVIGATION_HOME_ANCHOR_CONTACT } );
  }

}
