import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { NavigationConstants } from '../commons/navigation.constants';
import { SessionInteractor } from 'src/app/domain/interactor/session.interactor';

@Injectable({
     providedIn: 'root'
})
export class UserZoneRedirectGuard implements CanActivate {

     constructor( private sessionInteractor: SessionInteractor, private router: Router) {}

     canActivate(
          _next: ActivatedRouteSnapshot,
          _state: RouterStateSnapshot): boolean {

               if ( !this.sessionInteractor.loggedIn() ) {
                    this.router.navigateByUrl( NavigationConstants.redirectUrl( NavigationConstants.getNavigationLogin(_state.url) ) );
               } else if ( this.sessionInteractor.isProviderRole() ) {
                    this.router.navigateByUrl(NavigationConstants.redirectUrl(NavigationConstants.getNavigationProviderListBooking()));
               } else if ( this.sessionInteractor.isAdminRole() ) {
                    this.router.navigateByUrl(NavigationConstants.redirectUrl(NavigationConstants.getNavigationAdminListProviders()));
               } else if ( this.sessionInteractor.isAffiliateRole() ) {
                    this.router.navigateByUrl(NavigationConstants.redirectUrl(NavigationConstants.getNavigationAffiliateDashboard()));
               } else if ( this.sessionInteractor.isGuideRole() ) {
                    this.router.navigateByUrl(NavigationConstants.redirectUrl(NavigationConstants.getNavigationGuideListBookings()));
               } else {
                    this.router.navigateByUrl(NavigationConstants.redirectUrl(NavigationConstants.NAVIGATION_FORBIDDEN));
               }

               return false;

          }


     }
