import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { NavigationConstants } from 'src/app/ui/commons/navigation.constants';
import { Router, ActivatedRoute } from '@angular/router';
import { ServerErrorManager } from 'src/app/ui/components/utils/server-error-manager.utils';
import { NavigatorUtils } from 'src/app/ui/utils/navigator.utils';
import { SessionInteractor } from 'src/app/domain/interactor/session.interactor';
import { BookingInteractor } from 'src/app/domain/interactor/public/booking.interactor';
import { marker as I18N } from '@biesbjerg/ngx-translate-extract-marker';
import { MessagePanelComponent } from 'src/app/ui/components/shared/message-panel/message-panel.component';
import { ServerErrorExceptionCase } from 'src/app/domain/views/server-error-manager-exception-case.view';
import { AppConstants } from 'src/app/domain/common/app.constants';
import { GenericException } from 'src/app/domain/exceptions/generic.exception';


@Component({
     selector: 'app-booking-cancelation',
     templateUrl: './booking-cancelation.component.html'
})
export class BookingCancelationComponent implements OnInit, AfterViewInit {

     @ViewChild(MessagePanelComponent)
     private messagePanelComponent: MessagePanelComponent;

     booking: number;
     hash: string;
     bookCanceled: boolean;
     bookNotCanceled: boolean;

     constructor(
          private router: Router,
          private activatedRoute: ActivatedRoute,
          private sessionInteractor: SessionInteractor,
          private bookingInteractor: BookingInteractor,
          private serverErrorManager: ServerErrorManager) {

               this.bookCanceled = false;
               this.bookNotCanceled = false;

               this.activatedRoute.queryParams.subscribe(params => {

                    this.booking = params['booking'];
                    this.hash = params['hash'];
                    const languageCode = params['languageCode']
                    if ( this.sessionInteractor.setCurrentLanguage(languageCode) ) {
                         NavigatorUtils.reloadApplication();
                    }

               });

          }

          ngOnInit() { }

           ngAfterViewInit() {

               this.messagePanelComponent.showConfirmationMessage("¿Seguro que quieres cancelar la reserva?", () => {

                    this.messagePanelComponent.showLoading();

                    this.bookingInteractor.cancelBooking(this.booking, this.hash)
                    .subscribe(
                         () => {
                              MessagePanelComponent.hideLoading();
                              this.bookCanceled = true;
                         },
                         (error: GenericException) => {

                              MessagePanelComponent.hideLoading();

                              const exceptionCases = new Array<ServerErrorExceptionCase>();

                              const exceptionCodes = new Array<number>();
                              exceptionCodes.push(AppConstants.ERROR_CLIENT_BAD_REQUEST);
                              exceptionCodes.push(AppConstants.ERROR_CLIENT_NOT_FOUND);
                              exceptionCodes.push(AppConstants.ERROR_SERVER_FAILURE);
                              exceptionCases.push( new ServerErrorExceptionCase(
                                  exceptionCodes,
                                  new Array<string>(),
                                  () => {
                                      this.messagePanelComponent.showErrorMessage(
                                           I18N('bookingcancelation.No hemos podido procesar la cancelación. Por favor, revisa el enlace de tu correo.'),
                                           () => {
                                              this.goToHomeLanding();
                                          }
                                      );
                                  }
                              ));

                              this.serverErrorManager.manageErrorCode(error, this.messagePanelComponent, exceptionCases);
                         }
                    )
               }, () => {
                    this.bookNotCanceled = true;
               });

          }

          goToHomeLanding() {
               NavigatorUtils.goToReservaFreeTourPage();
          }

          onHeaderItemSelected(anchor: string) {
               this.router.navigate( [ NavigationConstants.getNavigationHome() ], { fragment: anchor } );
          }

     }
