import { Injectable }                       from '@angular/core';
import { Observable, Observer } from 'rxjs';
import { GenericException } from '../../exceptions/generic.exception';
import { ChunkGeneric } from '../../views/chunk-generic.view';
import { BookEvent } from 'src/app/business/models/book/book-event.model';
import { EventDetails } from 'src/app/business/models/book/event-details.model';
import { BookFareEditionView } from '../../views/book/book-fare-tuple.view';
import { Book } from 'src/app/business/models/book/book.model';
import { BookingState } from 'src/app/business/models/book/common/booking-state.constants';
import { GuideBookService } from 'src/app/business/services/guide/book.service';
import { BookingGuideConfirmationState } from 'src/app/business/models/book/common/booking-guide-confirmation-state.constants';


@Injectable({
     providedIn: 'root'
})
export class GuideBookInteractor {

     constructor(private guideBookService: GuideBookService ) {
     }


     findBookEvents(day: string, page: number): Observable<ChunkGeneric<BookEvent>> {

          return Observable.create((observer: Observer<ChunkGeneric<BookEvent>>) => {
               this.guideBookService.findBookEvents(day, page)
               .subscribe(
                    (chunkBookEvents: ChunkGeneric<BookEvent>) => {

                         observer.next(chunkBookEvents);
                         observer.complete();
                    },
                    (error: GenericException) => {
                         observer.error(error);
                    }
               )

          });

     }



     findEventDetails(tourId: number, date: string, hour: string, keywords: string): Observable<EventDetails> {

          return Observable.create((observer: Observer<EventDetails>) => {
               this.guideBookService.findBookings(tourId, date, hour, keywords)
               .subscribe(
                    (eventDetails: EventDetails) => {

                         const bookingsConfirmed: Array<Book> = new Array<Book>();
                         const bookingsRest: Array<Book> = new Array<Book>();

                         eventDetails.bookings.map( (book: Book) => {
                              if ( book.state == BookingState.Confirmed ) {
                                   bookingsConfirmed.push(book);
                              } else {
                                   bookingsRest.push(book);
                              }
                         })

                         eventDetails.bookings = bookingsConfirmed.concat(bookingsRest);

                         observer.next(eventDetails);
                         observer.complete();
                    },
                    (error: GenericException) => {
                         observer.error(error);
                    }
               )

          });

     }

     findBookEvent(tourId: number, date: string, hour: string): Observable<BookEvent> {

          return Observable.create((observer: Observer<BookEvent>) => {
               this.guideBookService.findBookEvent(tourId, date, hour)
               .subscribe(
                    (bookEvent: BookEvent) => {

                         observer.next(bookEvent);
                         observer.complete();
                    },
                    (error: GenericException) => {
                         observer.error(error);
                    }
               )

          });

     }

     updateEventBookFareShows(bookFareEditionView: BookFareEditionView): Observable<boolean> {

          return Observable.create((observer: Observer<boolean>) => {
               this.guideBookService.updateEventBookFareShows(bookFareEditionView)
               .subscribe(
                    () => {

                         observer.next(true);
                         observer.complete();
                    },
                    (error: GenericException) => {
                         observer.error(error);
                    }
               )

          });
     }


     updateEventBookAllFareShows(tourId: number, date: string, hour: string, bookId: number, bookingGuideConfirmationState: BookingGuideConfirmationState): Observable<boolean> {

          return Observable.create((observer: Observer<boolean>) => {
               this.guideBookService.updateEventBookAllFaresShows(tourId, date, hour, bookId, bookingGuideConfirmationState)
               .subscribe(
                    () => {

                         observer.next(true);
                         observer.complete();
                    },
                    (error: GenericException) => {
                         observer.error(error);
                    }
               )

          });
     }


}
