<div class="animated fadeIn">

     <app-list-booking
          [noResults]="noResults"
          [bookEvents]="bookEvents"
          [pagination]="pagination"
          (dateAsStringSelected)="onDateAsStringSelected($event)"
          (countryIdSelected)="onCountryIdSelected($event)"
          (cityIdSelected)="onCityIdSelected($event)"
          (tourIdSelected)="onTourIdSelected($event)"
          (pageNumberSelected)="onPageNumberSelected($event)"
          (viewBookDetails)="onViewBookDetails($event)"
          (allowBooking)="onAllowBooking($event)"
          (disallowBooking)="onDisallowBooking($event)"
          (showUpdateMaxBookings)="onShowUpdateMaxBookings($event)"
          ></app-list-booking>


     <app-message-panel></app-message-panel>

</div>