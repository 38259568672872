import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'I18NTranslationSave'
})
export class I18NTranslationSavePipe implements PipeTransform {

  transform(label: string, mainTranslation: string): String {

    let result = label;
    if ( !label ) {
        result = mainTranslation;
    }

    return result ;

  }

}
