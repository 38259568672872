<form class="form-boxes m-0 p-0">

     <!-- Empresa -->
     <div class="row mb-2">
          <div class="col-10">
               <h2 class="font-weight-bold d-flex"><img class="ic-mid mr-2" src="assets/img/ic_menu_tour.svg">{{ 'guidedetails.Tours' | translate }}</h2>
               <h5>{{ 'guidedetails.Tours gestionados por el guía' | translate }}</h5>
               <hr class="w-10 float-left mt-2 mb-4">
          </div>
          <div class="col-2 text-right">
               <a class="cursor-pointer" (click)="toggleEdition()">
                    <img class="ic-mid mr-2" src="{{ isDisabled ? 'assets/img/ic_edit.svg' : 'assets/img/ic_edit-disabled.svg'}}">
               </a>
          </div>
     </div>


     <app-tours-search *ngIf="!isDisabled" [tours]="providerTours" [pagination]="providerToursPagination" (tourFilterTrigger)="onProviderTourFilter($event)" (tourSelectedTrigger)="onProviderTourSelected($event)"></app-tours-search>


     <div *ngIf="!providerToursSelected || providerToursSelected?.length == 0" class="row mb-3">
          <div class="col-12 pt-2 pb-2">
               {{ 'guidedetails.El guía no tiene asignado tours para gestionar' | translate }}
          </div>
     </div>
     <div *ngIf="providerToursSelected && providerToursSelected?.length > 0" class="row mb-3">
          <div class="col-12 pt-2 pb-2">
               <table class="table table-hover table-dark table-striped">
                   <thead class="thead-dark">
                       <tr>
                           <th scope="col">{{ 'guidedetails.Tour' | translate }}</th>
                           <th scope="col">{{ 'guidedetails.Ciudad' | translate }}</th>
                           <th scope="col"></th>
                       </tr>
                   </thead>
                   <tbody class="row-clickable">
                       <tr *ngFor="let tourResume of providerToursSelected">
                           <td scope="row ">{{ tourResume.name | I18NTranslationSave: tourResume.nameMain }}</td>
                           <td>{{ tourResume.city.name }}, {{ tourResume.country.name }}</td>
                           <td>
                                <a *ngIf="!isDisabled" (click)="onProviderTourRemove(tourResume)" placement="bottom" ngbTooltip="{{ 'guidetours.Quitar tour' | translate }}" tooltipClass="tooltip-info">
                                    <img src="assets/img/ic_bin.svg">
                                </a>
                           </td>
                       </tr>

                   </tbody>
               </table>
          </div>
     </div>



     <div class="row mb-5 pb-5">
          <div class="col-12 col-md-6 offset-md-6 col-lg-4 offset-lg-8">
               <input (click)="saveDetails()" type="submit" value="{{ 'guidedetails.Guardar datos' | translate }}" class="btn btn-full-width btn-primary mb-3 mb-sm-0" [disabled]="isDisabled" />
          </div>
     </div>



</form>

<app-message-panel [preloading]="false"></app-message-panel>