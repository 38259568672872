<div class="row bg-white">
    <div class="animated fadeInLeft col-12 col-lg-6 col-xl-5 section-login-form order-2 order-lg-1">
        <div class="row text-right">
            <div class="col-12">
                <app-language-picker [extraClass]="'d-none d-lg-inline'"></app-language-picker>
            </div>
        </div>
        <div class="row mb-5 pb-3">
             <div class="col-12">
                  <a (click)="goToHomeLanding()">
                      <img class="logo" src="assets/img/logo.png" />
                </a>
             </div>
        </div>

        <div class="row mb-3">
            <div class="col-12">
                <h2>{{ 'rememberpasssword.Restablecer tu contraseña para acceder' | translate }}</h2>
                <h5>{{ 'rememberpasssword.Introduce tu email y te enviaremos un link para restablecer tu contraseña' | translate }}.</h5>
            </div>
        </div>

        <form action="" [formGroup]="rememberPasswordForm" class="form-underline">

            <div class="row">
                <div class="col-12">
                    <div class="field">
                        <input formControlName="email" type="text" name="email" id="email" placeholder="{{ 'rememberpasssword.Email' | translate }}" value="">
                        <label for="email">{{ 'rememberpasssword.Email' | translate }}</label>
                    </div>
                    <app-feedback [formGroup]="rememberPasswordForm" field="email" validator="required" feedback="{{ 'rememberpasssword.Email requerido' | translate }}" [refresh]="refreshValidators"></app-feedback>
                    <app-feedback [formGroup]="rememberPasswordForm" field="email" validator="email" feedback="{{ 'rememberpasssword.Email no válido' | translate }}" [refresh]="refreshValidators"></app-feedback>
                    <app-feedback [formGroup]="rememberPasswordForm" field="email" validator="maxlength" feedback="{{ 'createtourdetails.Texto demasiado largo' | translate }}" [refresh]="refreshValidators"></app-feedback>
                </div>
            </div>

            <div class="row">
                <div class="col-12 col-sm-6">
                    <input type="submit" (click)="rememberPassword()" value="{{ 'rememberpasssword.enviar' | translate }}" class="btn btn-full-width btn-primary mb-3 mb-sm-0" />
                </div>
                <div class="col-12 col-sm-6">
                    <a (click)="goToLogin()" class="btn btn-full-width btn-secondary">{{ 'rememberpasssword.volver' | translate }}</a>
                </div>
            </div>

        </form>
    </div>

    <div app-access-side-panel class="col-12 col-lg-6 col-xl-7 section-login-bg d-flex align-items-start flex-column order-1 order-lg-2"></div>
</div>

<app-message-panel></app-message-panel>