import { Pipe, PipeTransform } from '@angular/core';
import { marker as I18N } from '@biesbjerg/ngx-translate-extract-marker';
import { GuideServiceState } from 'src/app/business/models/billing/common/guide-service-state.constants';

@Pipe({
  name: 'i18NGuideServiceStatePipe'
})
export class  I18NGuideServiceStatePipe implements PipeTransform {

  transform(state: GuideServiceState): string {

      switch ( state ) {
          case GuideServiceState.Approved:
                return I18N('guideservicestatelabel.Aprobada');
          case GuideServiceState.Deleted:
               return I18N('guideservicestatelabel.Cancelada');
          case GuideServiceState.Billed:
               return I18N('guideservicestatelabel.Confirmada');
          default:
               return I18N('guideservicestatelabel.Pendiente');
      }

  }

}
