import { Component, ViewChild, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import { MessagePanelComponent } from 'src/app/ui/components/shared/message-panel/message-panel.component';
import { ServerErrorManager } from 'src/app/ui/components/utils/server-error-manager.utils';

import { marker as I18N } from '@biesbjerg/ngx-translate-extract-marker';
import { StripeManager } from 'src/app/ui/utils/stripe-manager';
import { StripeCardElement } from '@stripe/stripe-js';
import { ActivatedRoute, Router } from '@angular/router';
import { NavigationConstants } from 'src/app/ui/commons/navigation.constants';
import { ProviderBillingInteractor } from 'src/app/domain/interactor/provider/billing.interactor';
import { GenericException } from 'src/app/domain/exceptions/generic.exception';
import { InvoiceFullDetails } from 'src/app/business/models/billing/invoice-full-details.model';
import { NavigatorUtils } from 'src/app/ui/utils/navigator.utils';
import { BillingUtils } from 'src/app/ui/utils/billing.utils';
import { Provider } from 'src/app/business/models/provider/provider.model';
import { ProfileInteractor } from 'src/app/domain/interactor/profile/profile.interactor';


@Component({
  selector: 'app-invoice-details',
  templateUrl: './invoice-details.component.html'
})

export class ProviderInvoicdeDetailsComponent implements OnInit {

  @ViewChild(MessagePanelComponent, { static: false })
  private messagePanelComponent: MessagePanelComponent;

  paymentErrors: string;
  invoicePaymentForm: FormGroup;
  refreshValidators: boolean;

  provider: Provider;

  card: any;

  invoiceId: number;
  invoiceFullDetails: InvoiceFullDetails;


  constructor(
    private providerBillingInteractor: ProviderBillingInteractor,
    private serverErrorManager: ServerErrorManager,
    private stripeManager: StripeManager,
    private profileInteractor: ProfileInteractor,
    private router: Router,
    private activatedRoute: ActivatedRoute) {

    this.refreshValidators = false;

    this.invoicePaymentForm = new FormGroup({
      'creditCardOwner': new FormControl('', [Validators.required]),
      'creditCard': new FormControl('', [Validators.required]),
    });


    this.activatedRoute.params.subscribe(params => {
      this.invoiceId = params[NavigationConstants.NAVIGATION_ADMIN_INVOICE_ID];

      if (this.messagePanelComponent) {
        this.messagePanelComponent.showLoading();
      }

      this.providerBillingInteractor.findInvoice(this.invoiceId)
        .subscribe(
          (invoiceFullDetails: InvoiceFullDetails) => {
            MessagePanelComponent.hideLoading();
            this.invoiceFullDetails = invoiceFullDetails;

            if (this.invoiceFullDetails.invoiceDetails.chargePending) {

              this.stripeManager.existStripeComponent()
                .subscribe(
                  () => {
                    this.stripeManager.createCard(
                      () => {
                        this.invoicePaymentForm.get('creditCard').setValue('Valid');
                        this.invoicePaymentForm.get('creditCard').setErrors(null);
                        this.paymentErrors = null;
                      },
                      (errorMessage) => {
                        this.invoicePaymentForm.get('creditCard').setErrors({ 'notcomplete': true });
                        this.paymentErrors = errorMessage;
                      }
                    ).then(
                      (card: StripeCardElement) => {
                        this.card = card;
                      }
                    )
                  }
                )

            }


          },
          (error: GenericException) => {
            MessagePanelComponent.hideLoading();
            this.serverErrorManager.manageErrorCode(error, this.messagePanelComponent);
          }
        )
    });



    this.profileInteractor.getProviderDetails()
      .subscribe(
        (provider: Provider) => {
          this.provider = provider;
        },
        (error: GenericException) => {
          this.serverErrorManager.manageErrorCode(error, this.messagePanelComponent);

        }
      )


  }

  ngOnInit() {



  }


  validateCardFieds() {
    this.stripeManager.createToken(this.card);
  }


  onInvoiceDownload() {

    this.messagePanelComponent.showLoading();

    this.providerBillingInteractor.invoiceDownload(this.invoiceId)
      .subscribe(
        (invoiceBlob: Blob) => {

          MessagePanelComponent.hideLoading();

          // IE doesn't allow using a blob object directly as link href
          // instead it is necessary to use msSaveOrOpenBlob
          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveBlob(invoiceBlob);
            return;
          }

          // For other browsers:
          // Create a link pointing to the ObjectURL containing the blob.
          const downloadURL = URL.createObjectURL(invoiceBlob);

          var anchor = document.createElement("a");
          anchor.download = BillingUtils.getInvoiceNameToDownload(this.invoiceFullDetails.invoiceDetails);
          anchor.href = downloadURL;
          anchor.click();


        },
        (error: GenericException) => {
          MessagePanelComponent.hideLoading();
          this.serverErrorManager.manageErrorCode(error, this.messagePanelComponent);
        }
      )
  }



  onConfirmPayment() {

    this.refreshValidators = true;

    this.validateCardFieds();

    if (this.invoicePaymentForm.valid) {

      this.messagePanelComponent.showLoading();

      this.providerBillingInteractor.prepareInvoicePayment(this.invoiceId)
        .subscribe(
          (stripeTransactionId: string) => {

            this.stripeManager.confirmCardPayment(
              stripeTransactionId, this.card, this.provider.bookingNotificationEmail,
              () => {

                MessagePanelComponent.hideLoading();

                this.messagePanelComponent.showSuccessMessage(I18N('invoicepayment.Pago realizado correctamente'), () => {
                  NavigatorUtils.reloadApplication();
                });

              }, (errorMessage: string) => {

                MessagePanelComponent.hideLoading();

                this.messagePanelComponent.showErrorMessage(errorMessage);

              }
            );

          },
          (error: GenericException) => {
            this.serverErrorManager.manageErrorCode(error, this.messagePanelComponent);

          }
        )


    }

  }


  onGoToProfile() {
    this.router.navigate([NavigationConstants.getNavigationProviderProfile()]);
  }

}




