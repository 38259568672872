
export class GuideToursFilterView {

    private _keywords: string;
    private _countryId: number;
    private _cityId: number;
    private _pageNumber: number;

    constructor(
      keywords: string,
      countryId: number,
      cityId: number,
      pageNumber: number
    ) {
        this._keywords = keywords;
        this._countryId = countryId;
        this._cityId = cityId;
        this._pageNumber = pageNumber;
    }

    get keywords(): string {
        return this._keywords;
    }

    get countryId(): number {
        return this._countryId;
    }

    get cityId(): number {
        return this._cityId;
    }

    get pageNumber(): number {
        return this._pageNumber;
    }

}