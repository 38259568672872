<div class="animated fadeIn">
    <div class="row header-dashboard align-items-lg-center align-items-start">
        <div class="col-12 pl-0">
            <h2 class="font-weight-bold">{{ 'profile.Perfil' | translate }}</h2>
            <p>{{ 'profile.Revisa y edita tus datos' | translate }}</p>
        </div>
    </div>

    <div class="row dashboard-content-section">
        <div class="col-12 px-0">

            <app-change-password></app-change-password>
            <app-admin-profile-settings></app-admin-profile-settings>

        </div>
    </div>
</div>