import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BaseService } from '../base.service';
import { ChunkGeneric } from 'src/app/domain/views/chunk-generic.view';
import { Pagination } from 'src/app/domain/views/pagination.view';
import { BookEvent } from '../../models/book/book-event.model';
import { EventDetails } from '../../models/book/event-details.model';
import { BookFareEditionView } from 'src/app/domain/views/book/book-fare-tuple.view';
import { CancelationReason } from '../../models/master/booking/cancelation-reason/cancelaltion-reason.constants';
import { BookingUtils } from '../../models/book/common/booking.utils';
import { ProviderApiEndpointsUtils } from '../../api/endpoints/provider-api-endponints.utils';

@Injectable({
     providedIn: 'root'
})
export class ProviderBookService extends BaseService {


     findBookEvents(day: string, countryId: number, cityId: number, tourId: number, page: number): Observable<ChunkGeneric<BookEvent>> {

          return super.sendGetRequestWithRefresh(ProviderApiEndpointsUtils.getProviderFindBookEventsEndpoint(day, countryId, cityId, tourId, page), ProviderApiEndpointsUtils.getProviderFindBookEventsEndpointAcceptVersion())
          .pipe(
               map((response: any) => {

                    const bookEvents = new Array<BookEvent>();

                    response.items.map((bookEventApi: any) => {

                         bookEvents.push(BookingUtils.getBookEventFromApi(bookEventApi));

                    })

                    return new ChunkGeneric<BookEvent>(bookEvents, new Pagination(response.total, response.currentPage, response.chunkSize));

               })
          )
     }





     findBookings(tourId: number, date: string, hour: string, keywords: string): Observable<EventDetails> {

          return super.sendGetRequestWithRefresh(ProviderApiEndpointsUtils.getProviderFindBookingsEndpoint(tourId, date, hour, keywords), ProviderApiEndpointsUtils.getProviderFindBookingsEndpointAcceptVersion())
          .pipe(
               map((bookEventDetailsApi: any) => {

                    return BookingUtils.getBookEventDetailsFromApi(bookEventDetailsApi);

               })
          )
     }

     updateEventBookFareShows(bookFareEditionView: BookFareEditionView): Observable<boolean> {

          return super.sendPostRequestWithRefresh(ProviderApiEndpointsUtils.getProviderUpdateBookingsEndpoint(bookFareEditionView.tourId, bookFareEditionView.date, bookFareEditionView.hour, bookFareEditionView.bookId),
          ProviderApiEndpointsUtils.getProviderUpdateBookingsObject(bookFareEditionView.isChildren, bookFareEditionView.isAdult, bookFareEditionView.fareId, bookFareEditionView.shows),
          ProviderApiEndpointsUtils.getProviderUpdateBookingsEndpointAcceptVersion())
          .pipe(
               map(() => {
                    return true;
               })
          )
     }

     blockEventBooking(block: boolean, tourId: number, date: string, hour: string): Observable<boolean> {

          return super.sendPostRequestWithRefresh(ProviderApiEndpointsUtils.getBlockEventBookingEndpoint(tourId, date, hour),
          ProviderApiEndpointsUtils.getBlockEventBookingEndpointObject(block),
          ProviderApiEndpointsUtils.getBlockEventBookingEndpointAcceptVersion())
          .pipe(
               map(() => {
                    return true;
               })
          )
     }


     updateEventMaxBooking(tourId: number, date: string, hour: string, maxBookings: number): Observable<boolean> {

          return super.sendPutRequestWithRefresh(ProviderApiEndpointsUtils.getUpdateEventMaxBookingEndpoint(tourId, date, hour),
          ProviderApiEndpointsUtils.getUpdateEventMaxBookingObject(maxBookings),
          ProviderApiEndpointsUtils.getUpdateEventMaxBookingEndpointAcceptVersion())
          .pipe(
               map(() => {
                    return true;
               })
          )
     }


     updateEventBookContactDetails(bookId: number, email: string, name: string, surname: string, phone: string): Observable<boolean> {

          return super.sendPutRequestWithRefresh(ProviderApiEndpointsUtils.getProviderUpdateBookingsContactDetailsEndpoint(bookId),
          ProviderApiEndpointsUtils.getProviderUpdateBookingsContactDetailsObject(email, name, surname, phone),
          ProviderApiEndpointsUtils.getProviderUpdateBookingsContactDetailsEndpointAcceptVersion())
          .pipe(
               map(() => {
                    return true;
               })
          )
     }


     resendConfirmationEmail(bookId: number): Observable<boolean> {

          return super.sendPostRequestWithRefresh(ProviderApiEndpointsUtils.getProviderResendBookConfirmationEmailEndpoint(bookId),
          {},
          ProviderApiEndpointsUtils.getProviderResendBookConfirmationEmailEndpointAcceptVersion())
          .pipe(
               map(() => {
                    return true;
               })
          )
     }


     cancelBooking(bookId: number, message: string, cancelationReason: CancelationReason): Observable<boolean> {

          return super.sendPostRequestWithRefresh(ProviderApiEndpointsUtils.getProviderCancelBookingEndpoint(bookId),
          ProviderApiEndpointsUtils.getProviderCancelBookingEndpointObject(message, cancelationReason),
          ProviderApiEndpointsUtils.getProviderCancelBookingEndpointAcceptVersion())
          .pipe(
               map(() => {
                    return true;
               })
          )
     }


}
