import { BookFare } from './book-fare.model';
import { BookingGuideConfirmationState } from './common/booking-guide-confirmation-state.constants';
import { BookingState } from './common/booking-state.constants';

export class Book {

  private _id: number;
  private _email: string;
  private _name: string;
  private _surname: string;
  private _phone: string;
  private _people: number;
  private _shows: number;
  private _fares: Array<BookFare>;
  private _state: BookingState;
  private _guideConfirmationState: BookingGuideConfirmationState;


  constructor(
    id: number,
    email: string,
    name: string,
    surname: string,
    phone: string,
    people: number,
    shows: number,
    fares: Array<BookFare>,
    state: BookingState,
    guideConfirmationState: BookingGuideConfirmationState
  ) {
    this._id = id;
    this._email = email;
    this._name = name;
    this._surname = surname;
    this._phone = phone;
    this._people = people;
    this._shows = shows;
    this._fares = fares;
    this._state = state;
    this._guideConfirmationState = guideConfirmationState;
  }

  get id(): number {
    return this._id;
  }

  get email(): string {
    return this._email;
  }

  get name(): string {
    return this._name;
  }

  get surname(): string {
    return this._surname;
  }

  get phone(): string {
    return this._phone;
  }

  get shows(): number {
    return this._shows;
  }

  set shows(shows: number) {
    this._shows = shows;
  }

  get people(): number {
    return this._people;
  }

  get fares(): Array<BookFare> {
    return this._fares;
  }

  get state(): BookingState {
    return this._state;
  }

  get guideConfirmationState(): BookingGuideConfirmationState {
    return this._guideConfirmationState;
  }



  set id(id: number) {
    this._id = id;
  }

  set email(email: string) {
    this._email = email;
  }

  set name(name: string) {
    this._name = name;
  }

  set surname(surname: string) {
    this._surname = surname;
  }

  set phone(phone: string) {
    this._phone = phone;
  }

  set people(people: number) {
    this._people = people;
  }

  set fares(fares: Array<BookFare>) {
    this._fares = fares;
  }

  set state(state: BookingState) {
    this._state = state;
  }

  set guideConfirmationState(guideConfirmationState: BookingGuideConfirmationState) {
    this._guideConfirmationState = guideConfirmationState;
  }

}
