import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-provider-dashboard',
  templateUrl: './dashboard.component.html'
})

export class ProviderDashboardComponent implements OnInit {


  constructor( ) {
  }

  ngOnInit() {
  }

}
