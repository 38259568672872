<form class="form-boxes m-0 p-0" action="" [formGroup]="bookContactDetailsForm">

    <!-- <div class="row mb-3">
    <div class="col-12 col-md-12">
      <h2>{{ 'editbookcontact.Modifica los datos de contacto' | translate}}</h2>
    </div>
  </div>
  <div class="row mb-3">

    <div class="col-12 col-md-6">
      <div class="field pb-2">
        <input formControlName="email" type="email">
        <label for="email">{{ 'editbookcontact.Email' | translate }}</label>
      </div>
      <app-feedback [formGroup]="bookContactDetailsForm" field="email" validator="required" feedback="{{ 'editbookcontact.Email requerido' | translate }}" [refresh]="refreshValidators"></app-feedback>
      <app-feedback [formGroup]="bookContactDetailsForm" field="email" validator="email" feedback="{{ 'editbookcontact.Email incorrecto' | translate }}" [refresh]="refreshValidators"></app-feedback>
      <app-feedback [formGroup]="bookContactDetailsForm" field="email" validator="maxlength" feedback="{{ 'editbookcontact.Valor demasiado largo' | translate }}" [refresh]="refreshValidators"></app-feedback>
    </div>

    <div class="col-12 col-md-12">
      <div class="field pb-2">
        <input formControlName="name" type="text">
        <label for="name">{{ 'editbookcontact.Nombre' | translate }}</label>
      </div>
      <app-feedback [formGroup]="bookContactDetailsForm" field="name" validator="required" feedback="{{ 'editbookcontact.Nombre requerido' | translate }}" [refresh]="refreshValidators"></app-feedback>
      <app-feedback [formGroup]="bookContactDetailsForm" field="name" validator="maxlength" feedback="{{ 'editbookcontact.Valor demasiado largo' | translate }}" [refresh]="refreshValidators"></app-feedback>
    </div>

    <div class="col-12 col-md-12">
      <div class="field pb-2">
        <input formControlName="surname" type="text">
        <label for="surname">{{ 'editbookcontact.Apellido' | translate }}</label>
      </div>
      <app-feedback [formGroup]="bookContactDetailsForm" field="surname" validator="required" feedback="{{ 'editbookcontact.Apellido requerido' | translate }}" [refresh]="refreshValidators"></app-feedback>
      <app-feedback [formGroup]="bookContactDetailsForm" field="surname" validator="maxlength" feedback="{{ 'editbookcontact.Valor demasiado largo' | translate }}" [refresh]="refreshValidators"></app-feedback>
    </div>

    <div class="col-12 col-md-12">
      <div class="field pb-2">
        <input formControlName="phone" type="text">
        <label for="phone">{{ 'editbookcontact.Teléfono' | translate }}</label>
      </div>
      <app-feedback [formGroup]="bookContactDetailsForm" field="phone" validator="required" feedback="{{ 'editbookcontact.Teléfono requerido' | translate }}" [refresh]="refreshValidators"></app-feedback>
      <app-feedback [formGroup]="bookContactDetailsForm" field="phone" validator="maxlength" feedback="{{ 'editbookcontact.Valor demasiado largo' | translate }}" [refresh]="refreshValidators"></app-feedback>
    </div>

  </div>

  <div class="row mb-5 pb-5">
    <div class="col-12 col-md-6 offset-md-6 col-lg-4 offset-lg-8">
      <input (click)="saveBookingContactDetails()" type="submit" value="{{ 'editbookcontact.Guardar datos' | translate }}" class="btn btn-full-width btn-primary mb-3 mb-sm-0" />
    </div>
    <div class="col-12 col-md-6 offset-md-6 col-lg-4 offset-lg-8">
      <input (click)="closeModal()" type="submit" value="{{ 'editbookcontact.Cancelar' | translate }}" class="btn btn-full-width btn-primary mb-3 mb-sm-0" />
    </div>
  </div> -->

    <div class="modal-header pb-0">
        <button type="button" class="close" aria-label="Close" (click)="closeModal()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body mb-5">
        <h5 class="modal-title">{{ 'editbookcontact.Modifica los datos de contacto' | translate}}</h5>
        <div class="row mt-5">
            <div class="col-12 col-lg-6">
                <div class="field pb-2">
                    <input formControlName="email" type="email">
                    <label for="email">{{ 'editbookcontact.Email' | translate }}</label>
                </div>
                <app-feedback [formGroup]="bookContactDetailsForm" field="email" validator="required" feedback="{{ 'editbookcontact.Email requerido' | translate }}" [refresh]="refreshValidators"></app-feedback>
                <app-feedback [formGroup]="bookContactDetailsForm" field="email" validator="email" feedback="{{ 'editbookcontact.Email incorrecto' | translate }}" [refresh]="refreshValidators"></app-feedback>
                <app-feedback [formGroup]="bookContactDetailsForm" field="email" validator="maxlength" feedback="{{ 'editbookcontact.Valor demasiado largo' | translate }}" [refresh]="refreshValidators"></app-feedback>
            </div>

            <div class="col-12 col-lg-6">
                <div class="field pb-2">
                    <input formControlName="name" type="text">
                    <label for="name">{{ 'editbookcontact.Nombre' | translate }}</label>
                </div>
                <app-feedback [formGroup]="bookContactDetailsForm" field="name" validator="required" feedback="{{ 'editbookcontact.Nombre requerido' | translate }}" [refresh]="refreshValidators"></app-feedback>
                <app-feedback [formGroup]="bookContactDetailsForm" field="name" validator="maxlength" feedback="{{ 'editbookcontact.Valor demasiado largo' | translate }}" [refresh]="refreshValidators"></app-feedback>
            </div>

            <div class="col-12 col-lg-6">
                <div class="field pb-2">
                    <input formControlName="surname" type="text">
                    <label for="surname">{{ 'editbookcontact.Apellido' | translate }}</label>
                </div>
                <app-feedback [formGroup]="bookContactDetailsForm" field="surname" validator="required" feedback="{{ 'editbookcontact.Apellido requerido' | translate }}" [refresh]="refreshValidators"></app-feedback>
                <app-feedback [formGroup]="bookContactDetailsForm" field="surname" validator="maxlength" feedback="{{ 'editbookcontact.Valor demasiado largo' | translate }}" [refresh]="refreshValidators"></app-feedback>
            </div>

            <div class="col-12 col-lg-6">
                <div class="field pb-2">
                    <input formControlName="phone" type="text">
                    <label for="phone">{{ 'editbookcontact.Teléfono' | translate }}</label>
                </div>
                <app-feedback [formGroup]="bookContactDetailsForm" field="phone" validator="required" feedback="{{ 'editbookcontact.Teléfono requerido' | translate }}" [refresh]="refreshValidators"></app-feedback>
                <app-feedback [formGroup]="bookContactDetailsForm" field="phone" validator="maxlength" feedback="{{ 'editbookcontact.Valor demasiado largo' | translate }}" [refresh]="refreshValidators"></app-feedback>
            </div>
        </div>
    </div>
    <div class="modal-footer border-top-0">
        <input (click)="saveBookingContactDetails()" type="submit" value="{{ 'editbookcontact.Guardar datos' | translate }}" class="btn bg-primary-color mb-3 mb-sm-0 mr-4 modal-confirm" />
        <input (click)="closeModal()" type="submit" value="{{ 'editbookcontact.Cancelar' | translate }}" class="btn mb-3 mb-sm-0 modal-cancel" />
    </div>
</form>



<app-message-panel></app-message-panel>