<div class="col-12 p-relative" *ngIf="bookEvent"  [class.mb-5]="simplifiedVersion" [class.card]="!simplifiedVersion" [class.card-list]="!simplifiedVersion">
    <div class="row padding-lg-inside">
        <div [class.d-none]="simplifiedVersion" class="col-12 col-lg-3 pl-0 pr-0 pr-lg-4 cursor-pointer" (click)="onViewBookDetails(bookEvent)">
            <img class="img-card-list" src="{{ bookEvent.event.image?.medium.url | defaultPlaceholderImage : imageMediumSize }}">
        </div>
        <div class="col-9 col-lg-8 px-auto px-lg-0 padding-xs-inside cursor-pointer" [class.col-12]="guideRole" (click)="onViewBookDetails(bookEvent)">
            <div class="row">
                <div class="col-12 mt-0 mt-lg-2">
                    <h5 class="font-weight-bold">{{ bookEvent.event.name }}</h5>
                    <p [class.d-none]="simplifiedVersion" class="text-body-small text-uppercase grey-color font-weight-semibold">{{ bookEvent.event.languageTourGiven.name}} - {{ bookEvent.event.city.name}}, {{ bookEvent.event.country.name}}</p>
                    <p [class.d-none]="simplifiedVersion" *ngIf="adminRole" class="text-body-small text-uppercase grey-color font-weight-semibold">{{ bookEvent.event.provider.companyName}}</p>
                </div>
            </div>
            <div class="row mt-1">
                <div class="col-auto">
                    <p class="text-body-mid">
                         <img class="mr-2" src="assets/img/ic_calendar_corporative.svg">
                         {{ bookEvent.event.day }}
                    </p>
                </div>
                <div class="col-auto">
                    <p class="text-body-mid">
                         <img class="mr-2" src="assets/img/ic_clock_corporative.svg">
                         {{ bookEvent.event.hour}}
                    </p>
                </div>
                <div [class.d-none]="simplifiedVersion" class="col-auto">
                    <p class="text-body-mid">
                         <img class="mr-2" src="assets/img/ic_tour_corporative.svg">
                         <span *ngIf="bookEvent.event.isFree">{{ 'listbooking.gratis' | translate }}</span>
                         <span *ngIf="!bookEvent.event.isFree">{{ 'listbooking.de pago' | translate }}</span>
                    </p>
                </div>
                <div class="col-auto d-md-none">
                    <p class="text-body-mid">
                         <img class="mr-2" src="assets/img/ic_pax-total_corporative.svg">
                         {{ bookEvent.capacity }} {{ 'listbooking.Capacidad' | translate }}
                    </p>
                </div>
            </div>
            <div *ngIf="bookEvent.bookingsAreBlocked" class="row">
                <div class="col-12 mt-4">
                    <span class="tag-primary label-closed mr-3">{{ 'listbooking.Evento cerrado' | translate }}</span>
                </div>
            </div>
            <div *ngIf="bookEvent.event.state == tourInactiveState" class="row">
                <div class="col-12 mt-4">
                    <span  class="tag-primary inactive-tour">{{ 'listbooking.Tour inactivo' | translate }}</span>
                </div>
            </div>
        </div>
        <div class="col-3 col-lg-1 text-right padding-xs-inside pr-lg-0" [class.d-none]="guideRole">

             <a *ngIf="guideRole" (click)="onNewBooking(bookEvent)" title="{{ 'bookEvent.Nueva reserva' | translate }}">
                  <img class="mr-2 w-100" style="max-width: 45px;" src="assets/img/ic_calendar_corporative.svg">
             </a>

            <label *ngIf="!guideRole" class="btn-more-vert dropdown">
                <div class="dd-button">
                    <img class="ic-mid" src="assets/img/ic_more-vert.svg">
                </div>

                <input type="checkbox" class="dd-input">

                <ul class="dd-menu">
                  <li>
                    <a *ngIf="!guideRole && bookEvent.bookingsAreBlocked" (click)="onAllowBookings(bookEvent)">{{ 'bookEvent.Abrir reservas' | translate }}</a>
                    <a *ngIf="!guideRole &&!bookEvent.bookingsAreBlocked" (click)="onDisallowBookings(bookEvent)">{{ 'bookEvent.Cerrar reservas' | translate }}</a>
                  </li>
                  <li class="divider"></li>
                  <li>
                      <a *ngIf="!guideRole" (click)="onShowUpdateMaxBookings(bookEvent)">{{ 'bookEvent.Límite de reservas' | translate }}</a>
                  </li>
                </ul>

            </label>
        </div>
    </div>
    <div [class.d-none]="simplifiedVersion" class="row">
        <div class="col-12 px-0 px-lg-auto bg-bottom-card-pax">
            <div class="line-status active-booking" [ngClass]="{'warning-booking': bookEvent.percentageCapacity >= percentageCapacityAlert }" [ngStyle]="{'width': bookEvent.percentageCapacity + '%'}"></div>
        </div>
    </div>
    <div class="row bottom-card-pax cursor-pointer" (click)="onViewBookDetails(bookEvent)">
        <div class="col-6 col-md-4 text-center align-middle border-right">
             <div class="row no-gutters">
                  <div class="col-auto mr-2">
                      <img class="mr-0 mr-sm-3 mx-sm-auto ic-mid" src="assets/img/ic_pax-booking.svg">
                  </div>
                  <div class="col-auto">
                      <div class="label-shows">
                           {{ 'listbooking.Reservas' | translate }}
                           <span class="font-weight-bold ml-2">
                                {{ bookEvent.bookings }}
                           </span>
                      </div>
                  </div>
            </div>
        </div>
        <div class="col-6 col-md-4 text-center align-middle">
             <div class="row no-gutters">
                  <div class="col-auto mr-2">
                      <img class="mr-0 mr-sm-3 mx-sm-auto ic-mid" src="assets/img/ic_pax-shows.svg">
                  </div>
                  <div class="col-auto">
                      <div class="label-shows">
                           {{ 'listbooking.Shows' | translate }}
                           <span class="text-success font-weight-bold ml-2">
                                {{ bookEvent.shows }}
                           </span>
                      </div>
                  </div>
            </div>
        </div>
        <div class="col-6 d-none d-md-block col-md-4 text-center align-middle  border-left">
             <div class="row no-gutters">
                  <div class="col-auto mr-2">
                      <img class="mr-0 mr-sm-3 mx-sm-auto ic-mid" src="assets/img/ic_pax-total.svg">
                  </div>
                  <div class="col-auto">
                      <div class="label-shows">
                           {{ 'listbooking.Capacidad' | translate }}
                           <span class="font-weight-bold ml-2">
                                {{ bookEvent.availability }}
                           </span>
                      </div>
                  </div>
            </div>
        </div>
    </div>

</div>