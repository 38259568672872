import { Injectable }                       from '@angular/core';
import { Observable, Observer } from 'rxjs';
import { GenericException } from '../../exceptions/generic.exception';
import { ChunkGeneric } from '../../views/chunk-generic.view';
import { ProviderBookService } from 'src/app/business/services/provider/book.service';
import { BookEvent } from 'src/app/business/models/book/book-event.model';
import { EventDetails } from 'src/app/business/models/book/event-details.model';
import { BookFareEditionView } from '../../views/book/book-fare-tuple.view';
import { CancelationReason } from 'src/app/business/models/master/booking/cancelation-reason/cancelaltion-reason.constants';
import { Book } from 'src/app/business/models/book/book.model';
import { BookingState } from 'src/app/business/models/book/common/booking-state.constants';


@Injectable({
     providedIn: 'root'
})
export class ProviderBookInteractor {

     constructor(private providerBookService: ProviderBookService ) {
     }


     findBookEvents(day: string, countryId: number, cityId: number, tourId: number, page: number): Observable<ChunkGeneric<BookEvent>> {

          return Observable.create((observer: Observer<ChunkGeneric<BookEvent>>) => {
               this.providerBookService.findBookEvents(day, countryId, cityId, tourId, page)
               .subscribe(
                    (chunkTours: ChunkGeneric<BookEvent>) => {

                         observer.next(chunkTours);
                         observer.complete();
                    },
                    (error: GenericException) => {
                         observer.error(error);
                    }
               )

          });

     }


     findEventDetails(tourId: number, date: string, hour: string, keywords: string): Observable<EventDetails> {

          return Observable.create((observer: Observer<EventDetails>) => {
               this.providerBookService.findBookings(tourId, date, hour, keywords)
               .subscribe(
                    (eventDetails: EventDetails) => {

                         const bookingsConfirmed: Array<Book> = new Array<Book>();
                         const bookingsRest: Array<Book> = new Array<Book>();

                         eventDetails.bookings.map( (book: Book) => {
                              if ( book.state == BookingState.Confirmed ) {
                                   bookingsConfirmed.push(book);
                              } else {
                                   bookingsRest.push(book);
                              }
                         })

                         eventDetails.bookings = bookingsConfirmed.concat(bookingsRest);

                         observer.next(eventDetails);
                         observer.complete();
                    },
                    (error: GenericException) => {
                         observer.error(error);
                    }
               )

          });

     }


     updateEventBookFareShows(bookFareEditionView: BookFareEditionView): Observable<boolean> {

          return Observable.create((observer: Observer<boolean>) => {
               this.providerBookService.updateEventBookFareShows(bookFareEditionView)
               .subscribe(
                    () => {

                         observer.next(true);
                         observer.complete();
                    },
                    (error: GenericException) => {
                         observer.error(error);
                    }
               )

          });
     }



     updateEventBookContactDetails(bookId: number, email: string, name: string, surname: string, phone: string): Observable<boolean> {

          return Observable.create((observer: Observer<boolean>) => {
               this.providerBookService.updateEventBookContactDetails(bookId, email, name, surname, phone)
               .subscribe(
                    () => {

                         observer.next(true);
                         observer.complete();
                    },
                    (error: GenericException) => {
                         observer.error(error);
                    }
               )

          });
     }


     allowEventBooking(tourId: number, date: string, hour: string): Observable<boolean> {

          return Observable.create((observer: Observer<boolean>) => {
               this.providerBookService.blockEventBooking(false, tourId, date, hour)
               .subscribe(
                    () => {

                         observer.next(true);
                         observer.complete();
                    },
                    (error: GenericException) => {
                         observer.error(error);
                    }
               )

          });

     }


     disallowEventBooking(tourId: number, date: string, hour: string): Observable<boolean> {

          return Observable.create((observer: Observer<boolean>) => {
               this.providerBookService.blockEventBooking(true, tourId, date, hour)
               .subscribe(
                    () => {

                         observer.next(true);
                         observer.complete();
                    },
                    (error: GenericException) => {
                         observer.error(error);
                    }
               )

          });

     }



     updateEventMaxBooking(tourId: number, date: string, hour: string, maxBookings: number): Observable<boolean> {

          return Observable.create((observer: Observer<boolean>) => {
               this.providerBookService.updateEventMaxBooking(tourId, date, hour, maxBookings)
               .subscribe(
                    () => {

                         observer.next(true);
                         observer.complete();
                    },
                    (error: GenericException) => {
                         observer.error(error);
                    }
               )

          });

     }


     resendConfirmationEmail(bookId: number): Observable<boolean> {

          return Observable.create((observer: Observer<boolean>) => {
               this.providerBookService.resendConfirmationEmail(bookId)
               .subscribe(
                    () => {

                         observer.next(true);
                         observer.complete();
                    },
                    (error: GenericException) => {
                         observer.error(error);
                    }
               )

          });

     }



     cancelBooking(bookId: number, message: string, cancelationReason: CancelationReason): Observable<boolean> {

          return Observable.create((observer: Observer<boolean>) => {
               this.providerBookService.cancelBooking(bookId, message, cancelationReason)
               .subscribe(
                    () => {

                         observer.next(true);
                         observer.complete();
                    },
                    (error: GenericException) => {
                         observer.error(error);
                    }
               )

          });

     }


}
