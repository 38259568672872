import { Component, Input } from '@angular/core';
import { FormGroup, FormArray, FormControl } from '@angular/forms';
import { TourSchedulePlanningFormView } from 'src/app/domain/views/provider/tour/schedule-planning-form.view';
import { TourUtils } from 'src/app/ui/components/utils/tour/Tour.utils';


@Component({
  selector: 'app-create-tour-schedule-fixed',
  templateUrl: './fixed.component.html'
})
export class CreateTourScheduleFixedComponent {

  @Input() refreshValidators: boolean;

  i18nTraslation: boolean;

  @Input()
  set isI18nTraslation(isI18nTraslation: boolean) {
      this.i18nTraslation = isI18nTraslation;
  }


  @Input()
  set fixedFormGroup(fixedFormGroup: FormGroup) {
      this.formGroupDetails = fixedFormGroup;
      this.updateInputDetails();
  };
  @Input()
  set fixedView(fixedView: TourSchedulePlanningFormView) {
      this.fixedInputView = fixedView;
      this.updateInputDetails();
  }

  formGroupDetails: FormGroup;
  fixedInputView: TourSchedulePlanningFormView;

  updateInputDetails() {

      if ( this.formGroupDetails ) {

          if ( !this.formGroupDetails.get('days') ) {
              this.formGroupDetails.addControl('days', new FormArray([], [this.weekdaysValidator]));
          }

          if ( !this.formGroupDetails.get('dayExceptions') ) {
              this.formGroupDetails.addControl('dayExceptions', new FormArray([]));
          }

          if ( this.fixedInputView ) {
              const schedulePlanningFormGroups: [Array<FormGroup>, Array<FormGroup>]  = TourUtils.getFormGroupsFromTourSchedulePlanningFormView(this.fixedInputView);

              const days = this.formGroupDetails.get('days') as FormArray;
              const dayExceptions = this.formGroupDetails.get('dayExceptions') as FormArray;

              days.clear();
              schedulePlanningFormGroups[0].map( (dayFormGroup: FormGroup) => {
                  days.push(dayFormGroup);
              });

              dayExceptions.clear();
              schedulePlanningFormGroups[1].map( (dayExceptionFormGroup: FormGroup) => {
                  dayExceptions.push(dayExceptionFormGroup);
              });

          }
      }

  }

  constructor() {
  }


  weekdaysValidator(daysControls: FormArray ): { [s:string]:boolean } {

      let hasSchedules = false;
       if ( daysControls ) {
           daysControls.controls.forEach( (control: FormControl) => {
               if ( control.get('day').value != '' ) {
                   hasSchedules = true;
               }
           });
       }

      if ( !hasSchedules ) {
         return {
             required: true
         }
      } else {
          return null;
      }
 }

  schedulesValid() {

      let hasSchedules = false;

      if ( this.formGroupDetails.get('days') ) {

          const daysControls = this.formGroupDetails.get('days') as FormArray;
          daysControls.controls.forEach( (control: FormControl) => {
              if ( control.get('day').value != '' ) {
                  hasSchedules = true;
              }
          });

      }

      if ( this.formGroupDetails.get('period') && hasSchedules ) {
          this.formGroupDetails.get('period').setErrors({'required': true});
      } else {
          this.formGroupDetails.get('period').setErrors(null);
      }
  }


}
