<div class="animated fadeIn">
    <div class="row header-dashboard header-wizard-progress align-items-lg-center align-items-start">
        <div class="col-12 col-sm-8 pl-sm-0">
            <h2 class="font-weight-bold">{{ 'createtour.Crear tour' | translate }}</h2>
            <p>{{ 'createtour.Descripción sobre crear tour' | translate }}</p>
        </div>
        <div *ngIf="tourLanguagesDisplayed" class="col-12 col-sm-4 text-sm-right">
            <div class="btn-group btn-group-toggle lang-dashboard" data-toggle="buttons">
                <label *ngFor="let language of tourLanguagesDisplayed" (click)="onTourI18NChange(language.id)" class="btn btn-secondary" [ngClass]="{'active': language.id == languageIdI18N}">
                    {{ language.code }}
                </label>
            </div>
        </div>
    </div>

    <app-create-tour-steps [currentFormStep]="currentFormStep" [showProviderStep]="true"></app-create-tour-steps>

    <div class="row dashboard-content-section">
        <div class="col-12 px-0">
            <app-create-tour-provider *ngIf="isProviderSection" [isI18nTraslation]="i18nTraslation" [providerId]="tourForm.providerId" (tourProviderEmitter)="onTourProviderNext($event)"></app-create-tour-provider>
            <app-create-tour-details *ngIf="isDetailsSection" [hasProviderStep]="true" [isI18nTraslation]="i18nTraslation" [tourDetails]="tourForm.tourDetails" (tourDetailsEmitter)="onTourDetailsNext($event)"></app-create-tour-details>
            <app-create-tour-users *ngIf="isUsersSection" [isI18nTraslation]="i18nTraslation" [tourUsers]="tourForm.tourUsers" (tourUsersEmitter)="onTourUsersNext($event)"></app-create-tour-users>
            <app-create-tour-location *ngIf="isLocationSection" [isI18nTraslation]="i18nTraslation" [tourLocation]="tourForm.tourLocation" (tourLocationEmitter)="onTourLocationNext($event)"></app-create-tour-location>
            <app-create-tour-schedule *ngIf="isDateSection" [isI18nTraslation]="i18nTraslation" [tourSchedule]="tourForm.tourSchedule" (tourScheduleEmitter)="onTourScheduleEmitter($event)"></app-create-tour-schedule>

        </div>
    </div>


    <app-message-panel></app-message-panel>

</div>