import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BaseService } from '../base.service';
import { TourUtils } from '../../models/tour/common/tour.utils';
import { ChunkGeneric } from 'src/app/domain/views/chunk-generic.view';
import { Pagination } from 'src/app/domain/views/pagination.view';
import { AffiliateApiEndpointsUtils } from '../../api/endpoints/affiliate-api-endponints.utils';
import { TourResumeAffiliate } from '../../models/tour/tour-resume-affiliate.model';

@Injectable({
     providedIn: 'root'
})
export class AffiliateTourService extends BaseService {


     findTours(localeI18N: string, search: string, countryId: number, cityId: number, page: number): Observable<ChunkGeneric<TourResumeAffiliate>> {

          return super.sendGetRequestWithRefresh(AffiliateApiEndpointsUtils.getAffiliateFindToursEndpoint(search, countryId, cityId, page), AffiliateApiEndpointsUtils.getAffiliateFindToursEndpointAcceptVersion())
          .pipe(
               map((response: any) => {

                    const toursResume = new Array<TourResumeAffiliate>();

                    response.items.map((tourBasicApi: any) => {

                         const tourResume = TourUtils.getTourResumeAffiliateFromApi(tourBasicApi, localeI18N);
                         toursResume.push(tourResume);
                    })

                    return new ChunkGeneric<TourResumeAffiliate>(toursResume, new Pagination(response.total, response.currentPage, response.chunkSize));

               })
          )
     }


     generateCustomLink(link: string): Observable<string> {

          return super.sendPostRequestWithRefresh(AffiliateApiEndpointsUtils.getAffiliateCustomLinkEndpoint(), AffiliateApiEndpointsUtils.getAffiliateCustomLinkObject(link), AffiliateApiEndpointsUtils.getAffiliateCustomLinkEndpointAcceptVersion())
          .pipe(
               map((response: any) => {
                    return response.url
               })
          )
     }


}
