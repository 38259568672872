export class GuideServiceTourStats {

     private _tourId: number;
     private _tourName: string;
     private _people: number;
     private _commissions: number;

     constructor(
          tourId: number,
          tourName: string,
          people: number,
          commissions: number,
     ) {
          this._tourId = tourId;
          this._tourName = tourName;
          this._people = people;
          this._commissions = commissions;
     }

     get tourId(): number {
          return this._tourId;
     }

     get tourName(): string {
          return this._tourName;
     }

     get people(): number {
          return this._people;
     }

     get commissions(): number {
          return this._commissions;
     }

}