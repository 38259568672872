import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { NgxPaginationModule } from 'ngx-pagination';
import { SharedModule } from 'src/app/shared.module';
import { AffiliateComponent } from './affiliate.component';
import { AFFILIATE_ROUTES } from './affiliate.routes';
import { AffiliateInvoicdeDetailsComponent } from './billing/invoices/details/invoice-details.component';
import { AffiliateListInvoicesComponent } from './billing/invoices/list/list.component';
import { AffiliateListBillingComponent } from './billing/list/list.component';
import { AffiliateDashboardComponent } from './dashboard/dashboard.component';
import { AffiliateProfileComponent } from './profile/profile.component';
import { AffiliateProfileSettingsComponent } from './profile/settings/settings.component';
import { AffiliateToursCardsComponent } from './tour/list/componets/cards/cards.component';
import { AffiliateCreateCustomLinkModalComponent } from './tour/list/componets/create-custom-link/create-custom-link-modal.component';
import { AffiliateToursTableComponent } from './tour/list/componets/table/table.component';
import { AffiliateTourLinkModalComponent } from './tour/list/componets/tour-link/tour-link-modal.component';
import { AffiliateListToursComponent } from './tour/list/list.component';


@NgModule({
  declarations: [
       AffiliateComponent,
       AffiliateProfileComponent,
       AffiliateProfileSettingsComponent,
       AffiliateListToursComponent,
       AffiliateToursTableComponent,
       AffiliateToursCardsComponent,
       AffiliateCreateCustomLinkModalComponent,
       AffiliateTourLinkModalComponent,
       AffiliateListBillingComponent,
       AffiliateListInvoicesComponent,
       AffiliateInvoicdeDetailsComponent,
       AffiliateDashboardComponent
  ],
  exports: [
  ],
  imports: [
    AFFILIATE_ROUTES,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserModule,
    NgxPaginationModule,
    NgbModule,
    NgxChartsModule
  ]
})
export class AffiliateModule { }
