export class InvoiceIssuer {

  private _name: string;
  private _registration: string;
  private _vat: string;
  private _email: string;
  private _address1: string;
  private _address2: string;


  constructor(
    name: string,
    registration: string,
    vat: string,
    email: string,
    address1: string,
    address2: string
  ) {
    this._name = name;
    this._registration = registration;
    this._vat = vat;
    this._email = email;
    this._address1 = address1;
    this._address2 = address2;
  }


  get name(): string {
    return this._name;
  }

  get registration(): string {
    return this._registration;
  }

  get vat(): string {
    return this._vat;
  }

  get email(): string {
    return this._email;
  }

  get address1(): string {
    return this._address1;
  }

  get address2(): string {
    return this._address2;
  }

}
