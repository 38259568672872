<form class="form-boxes m-0 p-0" [formGroup]="createTourUsersForm">
    <div class="row">
        <div class="col-12 col-md-4">
            <div class="field pb-2">
                <input formControlName="minPax" type="number" name="minPax" id="minPax" placeholder="{{ 'createtourusers.Sin limitaciones' | translate }}">
                <label for="minPax">{{ 'createtourusers.Mínimo pax.' | translate }}</label>
            </div>
            <app-feedback [formGroup]="createTourUsersForm" field="minPax" validator="min" feedback="{{ 'createtourusers.Mínimo incorrecto' | translate }}" [refresh]="refreshValidators"></app-feedback>
            <app-feedback [formGroup]="createTourUsersForm" field="minPax" validator="max" feedback="{{ 'createtourusers.Número demasiado grande' | translate }}" [refresh]="refreshValidators"></app-feedback>
        </div>

        <div class="col-12 col-md-4 ">
            <div class="field pb-2">
                <input formControlName="maxPax" type="number" name="maxPax" id="maxPax">
                <label for="maxPax">{{ 'createtourusers.Máximo pax.' | translate }}</label>
            </div>
            <app-feedback [formGroup]="createTourUsersForm" field="maxPax" validator="required" feedback="{{ 'createtourusers.Máximo requerido' | translate }}" [refresh]="refreshValidators"></app-feedback>
            <app-feedback [formGroup]="createTourUsersForm" field="maxPax" validator="min" feedback="{{ 'createtourusers.Máximo incorrecto' | translate }}" [refresh]="refreshValidators"></app-feedback>
            <app-feedback [formGroup]="createTourUsersForm" field="maxPax" validator="max" feedback="{{ 'createtourusers.Número demasiado grande' | translate }}" [refresh]="refreshValidators"></app-feedback>
        </div>

        <div class="col-12 col-md-4">
            <div class="field pb-2">
                <select formControlName="cancellationLimit" name="cancellationLimit" id="cancellationLimit">
                    <option value="0">{{ 'createtourusers.En cualquier momento' | translate }}</option>
                    <option value="720">{{ 'createtourusers.Cancelación 12 horas antes' | translate }}</option>
                    <option value="1440">{{ 'createtourusers.Cancelación 24 horas antes' | translate }}</option>
                    <option value="2880">{{ 'createtourusers.Cancelación 48 horas antes' | translate }}</option>
                </select>
                <label for=" ">{{ 'createtourusers.Política de cancelación' | translate }}</label>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-12 col-md-6 col-lg-4">
            <div class="field pb-2">
                <input formControlName="maxPeoplePerBooking" type="number" name="maxPeoplePerBooking" id="maxPeoplePerBooking" placeholder="">
                <label for="maxFreePeople">{{ 'createtourusers.Max. personas en la misma reserva' | translate }}</label>
            </div>
            <app-feedback [formGroup]="createTourUsersForm" field="maxPeoplePerBooking" validator="required" feedback="{{ 'createtourusers.Máximo incorrecto' | translate }}" [refresh]="refreshValidators"></app-feedback>
            <app-feedback [formGroup]="createTourUsersForm" field="maxPeoplePerBooking" validator="min" feedback="{{ 'createtourusers.Máximo incorrecto' | translate }}" [refresh]="refreshValidators"></app-feedback>
            <app-feedback [formGroup]="createTourUsersForm" field="maxPeoplePerBooking" validator="max" feedback="{{ 'createtourusers.Número demasiado grande' | translate }}" [refresh]="refreshValidators"></app-feedback>
        </div>
        <div class="col-12 col-md-6 col-lg-4">
            <div class="field pb-2">
                <select formControlName="bookingLimit" name="bookingLimit" id="bookingLimit">
                    <option value="0">{{ 'createtourusers.En el momento' | translate }}</option>
                    <option value="180">{{ 'createtourusers.Con 3 horas de antelación' | translate }}</option>
                    <option value="480">{{ 'createtourusers.Con 8 horas de antelación' | translate }}</option>
                    <option value="720">{{ 'createtourusers.Con 12 horas de antelación' | translate }}</option>
                </select>
                <label for="bookingLimit">{{ 'createtourusers.Anticipación de la reserva' | translate }}</label>
            </div>
            <app-feedback [formGroup]="createTourUsersForm" field="bookingLimit" validator="required" feedback="{{ 'createtourusers.Indica un límite de tiempo para realizar la reserva' | translate }}" [refresh]="refreshValidators"></app-feedback>
        </div>
    </div>



    <div class="row pb-2">
        <div class="col-12">
            <label for="freeTour">{{ 'createtourusers.Tour gratuito' | translate }}</label>
        </div>
        <div class="col-12 col-md-4 mb-4">
            <div class="btn-group btn-group-toggle w-100" data-toggle="buttons">
                <label class="btn btn-secondary ml-0" [ngClass]="{'active': isFreeSelected}" (click)="onFreeSelected()">
                  {{ 'createtourusers.Si' | translate }}
                </label>
                <label class="btn btn-secondary" [ngClass]="{'active': !isFreeSelected}" (click)="onNotFreeSelected()">
                  {{ 'createtourusers.No' | translate }}
                </label>
            </div>
        </div>
    </div>


    <!-- // Gratis: si -->
    <div *ngIf="isFreeSelected" class="row">
        <div class="col-12 col-md-6 col-lg-4">
            <div class="field pb-2">
                <input formControlName="maxFreePeople" type="number" name="maxFreePeople" id="maxFreePeople" placeholder="{{ 'createtourusers.Sin limitaciones' | translate }}">
                <label for="maxFreePeople">{{ 'createtourusers.Pax. reserva gratis' | translate }}</label>
            </div>
            <app-feedback [formGroup]="createTourUsersForm" field="maxFreePeople" validator="min" feedback="{{ 'createtourusers.Máximo incorrecto' | translate }}" [refresh]="refreshValidators"></app-feedback>
            <app-feedback [formGroup]="createTourUsersForm" field="maxFreePeople" validator="max" feedback="{{ 'createtourusers.Número demasiado grande' | translate }}" [refresh]="refreshValidators"></app-feedback>
            <app-feedback [formGroup]="createTourUsersForm" field="maxFreePeopleValid" validator="invalidPeople" feedback="{{ 'createtourusers.Si estableces un precio deber indicar un límite de personas' | translate }}" [refresh]="refreshValidators"></app-feedback>
            <app-feedback [formGroup]="createTourUsersForm" field="maxFreePeopleValid" validator="invalidLimit" feedback="{{ 'createtourusers.Valor mayor que el límite de una reserva' | translate }}" [refresh]="refreshValidators"></app-feedback>
        </div>

        <div class="col-12 col-md-6 col-lg-4">
            <div class="field pb-2">
                <input formControlName="maxFreePeoplePrice" type="number" name="maxFreePeoplePrice" id="maxFreePeoplePrice" placeholder="{{ 'createtourusers.Sin limitaciones' | translate }}">
                <label for="maxFreePeoplePrice">{{ 'createtourusers.Precio/pax superado el límite' | translate }}</label>
            </div>
            <app-feedback [formGroup]="createTourUsersForm" field="maxFreePeoplePrice" validator="min" feedback="{{ 'createtourusers.Precio incorrecto' | translate }}" [refresh]="refreshValidators"></app-feedback>
            <app-feedback [formGroup]="createTourUsersForm" field="maxFreePeoplePrice" validator="max" feedback="{{ 'createtourusers.Número demasiado grande' | translate }}" [refresh]="refreshValidators"></app-feedback>
            <app-feedback [formGroup]="createTourUsersForm" field="maxFreePeopleValid" validator="invalidPrice" feedback="{{ 'createtourusers.Si estableces un límite de personas deber indicar un precio' | translate }}" [refresh]="refreshValidators"></app-feedback>
        </div>

        <div class="col-12 col-md-6 col-lg-4">
            <div class="field pb-2">
                <input formControlName="maxFreePeopleAge" type="number" name="maxFreePeopleAge" id="maxFreePeopleAge" placeholder="">
                <label for="maxFreePeopleAge">{{ 'createtourusers.¿Hasta que edad se consideran niños?' | translate }}</label>
            </div>
            <app-feedback [formGroup]="createTourUsersForm" field="maxFreePeopleAge" validator="min" feedback="{{ 'createtourusers.Edad incorrecta' | translate }}" [refresh]="refreshValidators"></app-feedback>
            <app-feedback [formGroup]="createTourUsersForm" field="maxFreePeopleAge" validator="max" feedback="{{ 'createtourusers.Edad incorrecta' | translate }}" [refresh]="refreshValidators"></app-feedback>
            <app-feedback [formGroup]="createTourUsersForm" field="maxFreePeopleAge" validator="required" feedback="{{ 'createtourusers.Edad incorrecta' | translate }}" [refresh]="refreshValidators"></app-feedback>
        </div>
    </div>


    <div *ngIf="!isFreeSelected" class="row mb-4 d-block d-lg-none">
        <div class="col-12">
            <a (click)="addFare()" class="btn w-md-50 w-100 btn-tertiary mb-3 mb-sm-0 white-color my-0">
                <img class="ic-mid" src="assets/img/ic_add-white.svg">
            </a>
        </div>
    </div>


    <ng-container *ngIf="!isFreeSelected" formArrayName="fares">
        <div class="row" *ngFor="let fare of fares.controls; first as fareFirst; last as fareLast; index as fareIndex" [formGroup]="fare">
             <div class="col-12">
                  <app-main-translation [i18nTraslation]="i18nTraslation" [mainTranslation]="fare.get('fareNameMain').value"></app-main-translation>
             </div>
            <div class="col-6 col-lg-4">
                <div class="field pb-2">
                    <input formControlName="fareName" type="text" name="fareName" placeholder="{{ 'fare.Ejemplo: Adultos' | translate }}">
                    <label for="fareName">{{ 'createtourusers.Nombre de tarifa' | translate }}</label>
                </div>
                <app-feedback [formGroup]="fare" field="fareName" validator="required" feedback="{{ 'createtourusers.Nombre de tarifa requerido' | translate }}" [refresh]="refreshValidators"></app-feedback>
                <app-feedback [formGroup]="fare" field="fareName" validator="maxlength" feedback="{{ 'createtourdetails.Texto demasiado largo' | translate }}" [refresh]="refreshValidators"></app-feedback>
            </div>
            <div class="col-3 col-lg-2">
                <div class="field pb-2">
                    <input formControlName="farePrice" type="number" name="farePrice" placeholder="{{ 'createtourusers.Gratis' | translate }}">
                    <label for="farePrice">{{ 'createtourusers.Precio' | translate }}</label>
                </div>
                <app-feedback [formGroup]="fare" field="farePrice" validator="required" feedback="{{ 'createtourusers.Precio requerido' | translate }}" [refresh]="refreshValidators"></app-feedback>
                <app-feedback [formGroup]="fare" field="farePrice" validator="min" feedback="{{ 'createtourusers.Precio incorrecto' | translate }}" [refresh]="refreshValidators"></app-feedback>
                <app-feedback [formGroup]="fare" field="farePrice" validator="max" feedback="{{ 'createtourusers.Número demasiado grande' | translate }}" [refresh]="refreshValidators"></app-feedback>
            </div>

            <div *ngIf="!fareFirst && !i18nTraslation" class="col-auto my-auto" (click)="removeFare(fareIndex)">
                <img class="ic-mid opacity-3 mt-1" src="assets/img/ic_remove.svg">
            </div>

            <div *ngIf="fareLast && !i18nTraslation" class="col-12 col-md-4 col-lg-2 d-none d-lg-block my-auto">
                <a (click)="addFare()" class="btn w-md-50 w-100 btn-tertiary mb-3 mb-sm-0 white-color my-0">
                    <img class="ic-mid" src="assets/img/ic_add-white.svg">
                </a>
            </div>
        </div>
    </ng-container>

    <div class="row mb-5 pb-5 control-footer">
        <div class="col-6 col-md-5 col-lg-4">
            <button (click)="onBackButton()" type="submit" value="{{ 'createtourdetails.Volver' | translate }}" class="btn btn-full-width btn-no-btn mb-3 mb-sm-0 dark-color">
                <img class="ic-small mr-2" src="assets/img/ic_arrow-back.svg">
                {{ 'createtourdetails.Volver' | translate }}
            </button>
        </div>

        <div class="col-6 col-md-5 offset-md-2 col-lg-4 offset-lg-4">
            <button (click)="saveUsers()" type="submit" value="{{ 'createtourdetails.Siguiente' | translate }}" class="btn btn-full-width btn-primary mb-3 mb-sm-0 white-color">
                {{ 'createtourdetails.Siguiente' | translate }}
                <img class="ic-small ml-2" src="assets/img/ic_arrow-next.svg">
            </button>
        </div>
    </div>


</form>