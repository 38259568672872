<!-- Footer -->
<div id="footer" class="landing-page footer bg-grey-color p-relative">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12 col-lg-6 col-xl-6 mb-5 mb-lg-auto">
                <div class="footer-col">
                    <img class="img-fluid ic-footer mb-5" src="assets/img/logo-white.png">
                    <p class="white-color">{{ 'footerHome.Booqlever es una plataforma de gestión de reservas para proveedores del sector turístico.' | translate }}</p>
                </div>
            </div>
            <div class="col-12 col-lg-6 col-xl-3 offset-xl-3">
                <div class="footer-col">
                    <div class="row">
                        <div class="col-12">
                            <h2 class="white-color mb-3 mb-lg-auto pb-3">{{ 'footerHome.Contacto' | translate }}</h2>
                            <!-- <p class="white-color"><span class="font-weight-bold">{{ 'footerHome.Teléfono:' | translate }}</span> +34 123 456789</p> -->
                            <p class="white-color"><span class="font-weight-bold">{{ 'footerHome.Email:' | translate }}</span> {{ 'footerHome.info@booqlever.com' | translate }}</p>
                        </div>
                    </div>
                    <div class="row justify-content-start">
                         <!--
                        <div class="col-auto">
                            <a><img class="img-fluid" src="assets/img/ic_social_facebook.svg" alt="{{ 'footerHome.Logo de Facebook' | translate }}"></a>
                        </div>
                        <div class="col-auto">
                            <a><img class="img-fluid" src="assets/img/ic_social_linkedin.svg" alt="{{ 'footerHome.Logo de Linkedin' | translate }}"></a>
                        </div>
                        <div class="col-auto">
                            <a><img class="img-fluid" src="assets/img/ic_social_twitter.svg" alt="{{ 'footerHome.Logo de Twitter' | translate }}"></a>
                        </div>
                        -->
                    </div>
                </div>
            </div>
            <hr class="divider-footer" />
        </div>
    </div>
</div>
<!-- end of footer -->


<!-- Copyright -->
<div class="landing-page copyright bg-grey-color text-center">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-12">
                <p class="white-color font-weight-bold pb-0 p-small">{{ 'footerHome.© 2020 All rights reserved.' | translate }}</p>
            </div>
        </div>
    </div>
</div>
<!-- end of copyright -->