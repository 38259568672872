<form class="form-boxes m-0 p-0" action="" [formGroup]="settingsForm">
    <!-- Acceso -->
    <div class="row mb-2">
        <div class="col-10">
            <h4 class="font-weight-bold d-flex"><img class="ic-mid mr-2" src="assets/img/ic_settings.svg">{{ 'settingsprofile.Configuración' | translate }}</h4>
            <h5>{{ 'settingsprofile.Ajustes de la plataforma' | translate }}</h5>
            <hr class="w-10 float-left mt-2 mb-4">
        </div>
        <div class="col-2 text-right">
            <a class="cursor-pointer" (click)="toggleEdition()">
                <img class="ic-mid mr-2" src="{{ isDisabled ? 'assets/img/ic_edit.svg' : 'assets/img/ic_edit-disabled.svg'}}">
            </a>
        </div>
    </div>

    <div class="row mb-3">

        <div class="col-12 col-md-6">
            <div class="field pb-2">
                <select formControlName="language" name="language">
                      <option *ngFor="let language of languages" value="{{ language.code }}">{{ language.name }}</option>
                  </select>
                <label for="language">{{ 'settingsprofile.Idioma' | translate }}</label>
            </div>
            <app-feedback [formGroup]="settingsForm" field="language" validator="required" feedback="{{ 'settingsprofile.Idioma requerido' | translate }}" [refresh]="refreshValidators"></app-feedback>
        </div>
        <div class="col-12 col-md-6">
            <div class="field pb-2">
                <input formControlName="bookingNotificationEmail" type="text" name="bookingNotificationEmail" id="bookingNotificationEmail" placeholder="{{ 'providerdetails.Email para notificaciones' | translate }}">
                <label for="bookingNotificationEmail">{{ 'settingsprofile.Email para notificaciones' | translate }}</label>
            </div>
            <app-feedback [formGroup]="settingsForm" field="bookingNotificationEmail" validator="required" feedback="{{ 'settingsprofile.Email de notificaciones requerido' | translate }}" [refresh]="refreshValidators"></app-feedback>
            <app-feedback [formGroup]="settingsForm" field="bookingNotificationEmail" validator="email" feedback="{{ 'settingsprofile.Email incorrecto' | translate }}" [refresh]="refreshValidators"></app-feedback>
            <app-feedback [formGroup]="settingsForm" field="bookingNotificationEmail" validator="maxlength" feedback="{{ 'settingsprofile.Texto demasiado largo' | translate }}" [refresh]="refreshValidators"></app-feedback>
        </div>

        <div class="col-12 col-md-6">
            <div class="field pb-2">
                <input formControlName="bookingNotificationEmail2" type="text" name="bookingNotificationEmail2" id="bookingNotificationEmail2" placeholder="{{ 'providerdetails.Email para notificaciones adicional' | translate }}">
                <label for="bookingNotificationEmail">{{ 'settingsprofile.Email para notificaciones adicional' | translate }}</label>
            </div>
            <app-feedback [formGroup]="settingsForm" field="bookingNotificationEmail2" validator="email" feedback="{{ 'settingsprofile.Email incorrecto' | translate }}" [refresh]="refreshValidators"></app-feedback>
            <app-feedback [formGroup]="settingsForm" field="bookingNotificationEmail2" validator="maxlength" feedback="{{ 'settingsprofile.Texto demasiado largo' | translate }}" [refresh]="refreshValidators"></app-feedback>
        </div>
        <div class="col-12 col-md-6 d-flex mt-4">
            <div class="checkbox-custom mr-2">
                <input formControlName="bookingNotificationEmailActive" type="checkbox" name="bookingNotificationEmailActive" id="bookingNotificationEmailActive" value="">
                <div class="checkbox-custom-visible"></div>
            </div>
            <label for="bookingNotificationEmailActive">{{ 'settingsprofile.Recibir notificaciones de reservas' | translate }}</label>
        </div>

  </div>

   <div class="row mb-3">
      <div class="col-12">
          <label for="paymentMethod">{{ 'settingsprofile.Forma de pago' | translate }}</label>
          <p *ngIf="isPaymentPlatformTransference">{{ 'settingsprofile.Transferencia bancaria mensual una vez superado el importe mínimo de facturación.' | translate }}</p>
          <p *ngIf="isPaymentPlatformWise">{{ 'settingsprofile.Recibirás un email con los pasos a seguir para recibir tu dinero una vez superado el importe mínimo de facturación.' | translate }}</p>
          <p *ngIf="!isPaymentPlatformTransference && !isPaymentPlatformWise">{{ 'settingsprofile.Crea y vincula tu cuenta de Stripe para recibir tu dinero una vez superado el importe mínimo de facturación.' | translate }}</p>
      </div>
  </div>

  <div class="row mb-3">
        <div class="col-12 col-md-6">
            <div class="field pb-2">
                <select (change)="onUpdatedPaymentPlatform()" formControlName="paymentPlatform" name="paymentPlatform">
                      <!-- <option value="{{ paymentPlatformWise }}">{{ 'settingsprofile.wise' | translate }}</option> -->
                      <option value="{{ paymentPlatformStripe }}">{{ 'settingsprofile.stirpe' | translate }}</option>
                      <option value="{{ paymentPlatformTransference }}">{{ 'settingsprofile.transference' | translate }}</option>
                  </select>
                <label for="paymentPlatform">{{ 'settingsprofile.Plataforma de pagos' | translate }}</label>
            </div>
            <app-feedback [formGroup]="settingsForm" field="paymentPlatform" validator="required" feedback="{{ 'settingsprofile.Plataforma de pagos requerida' | translate }}" [refresh]="refreshValidators"></app-feedback>
        </div>
        <div class="col-12 col-md-6" *ngIf="isPaymentPlatformWise || isPaymentPlatformTransference">
            <div class="field pb-2">
                <select formControlName="currency" name="currency">
                      <option *ngFor="let currency of currencies" value="{{ currency }}">{{ currency }}</option>
                  </select>
                <label for="currency">{{ 'settingsprofile.Divisa' | translate }}</label>
            </div>
            <app-feedback [formGroup]="settingsForm" field="currency" validator="required" feedback="{{ 'settingsprofile.Divisa requerido' | translate }}" [refresh]="refreshValidators"></app-feedback>
        </div>
        <div class="col-12 col-md-6" *ngIf="isPaymentPlatformTransference">
             <div class="field pb-2">
                  <input formControlName="iban" type="text" name="iban" id="iban" placeholder="{{ 'settingsprofile.IBAN cuenta bancaria' | translate }}" value="" (change)="onIbanUpdated()">
                  <label for="companyName">{{ 'settingsprofile.IBAN cuenta bancaria' | translate }}</label>
             </div>
             <app-feedback [formGroup]="settingsForm" field="iban" validator="required" feedback="{{ 'settingsprofile.IBAN requerido' | translate }}" [refresh]="refreshValidators"></app-feedback>
             <app-feedback [formGroup]="settingsForm" field="iban" validator="pattern" feedback="{{ 'settingsprofile.IBAN incorrecto' | translate }}" [refresh]="refreshValidators"></app-feedback>
        </div>


    </div>

    <div class="row mb-5 pb-5">
        <div class="col-12 col-md-6 offset-md-6 col-lg-4 offset-lg-8">
            <input (click)="saveSettings()" type="submit" value="{{ 'settingsprofile.Guardar datos' | translate }}" class="btn btn-full-width btn-primary mb-3 mb-sm-0" [disabled]="isDisabled" />
        </div>
    </div>
</form>



<app-message-panel></app-message-panel>