<app-list-services
     [isGuide]="true"
     [chunkCommissions]="guideServices"
     [guideServiceStats]="guideServiceStats"
     (filterCommissions)="onFilterCommissions($event)"
     (createCommission)="onCreateCommission()"
     (editService)="onEditService($event)"
     (deleteService)="onDeleteService($event)"
></app-list-services>

<app-message-panel [preloading]="true"></app-message-panel>