import { ProviderState } from './common/provider-state.constants';
import { ProviderRegistrationState } from './common/provider-registration-state.constants';

export class ProviderResume {

    private _id: number;
    private _username: string;
    private _email: string;
    private _companyName: string;
    private _country: string;
    private _city: string;
    private _contactPerson: string;
    private _state: ProviderState;
    private _registrationState: ProviderRegistrationState;

    constructor(
      id: number,
      username: string,
      email: string,
      companyName: string,
      country: string,
      city: string,
      contactPerson: string,
      state: ProviderState,
      registrationState: ProviderRegistrationState
    ) {

        this._id = id;
        this._username = username;
        this._email = email;
        this._companyName = companyName;
        this._country = country;
        this._city = city;
        this._contactPerson = contactPerson;
        this._state = state;
        this._registrationState = registrationState;

    }


    get id(): number {
        return this._id;
    }

    get username(): string {
        return this._username;
    }

    get email(): string {
        return this._email;
    }

    get companyName(): string {
        return this._companyName;
    }

    get country(): string {
        return this._country;
    }

    get city(): string {
        return this._city;
    }

    get contactPerson(): string {
        return this._contactPerson;
    }

    get state(): ProviderState {
        return this._state;
    }

    get registrationState(): ProviderRegistrationState {
        return this._registrationState;
    }

}
