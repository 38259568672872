<div class="animated fadeIn">
    <div class="row header-dashboard align-items-lg-center align-items-start">
        <div class="col-12 pl-0">
            <h2 class="font-weight-bold">{{ 'affiliateprofile.Perfil' | translate }}</h2>
            <p>{{ 'affiliateprofile.Revisa y edita tus datos' | translate }}</p>
        </div>
    </div>

    <div class="row dashboard-content-section">
        <div class="col-12 px-0">

            <app-change-password></app-change-password>
            <app-affiliate-profile-settings [affiliate]="affiliate"></app-affiliate-profile-settings>
            <app-profile-affiliate-details [affiliate]="affiliate" (affiliateDetails)="onAffiliateDetailsSubmit($event)"></app-profile-affiliate-details>
            <app-stripe-profile-payment [provider]="affiliate"
                    (createConnectedAccount)="onCreateConnectedStripeAccount()"
                    (connectAccount)="onConnectAccount()"
                    (removeAccount)="onRemoveStripeAccount()"
                    (updateAccount)="onUpdateStripeAccount()"></app-stripe-profile-payment>

            <!--
            <app-provider-profile-settings [provider]="affiliate"></app-provider-profile-settings>

            <app-profile-provider-details [provider]="affiliate" (providerDetails)="onAffiliateDetailsSubmit($event)"></app-profile-provider-details>
            <app-profile-payment [provider]="affiliate" (profilePayment)="onAffiliatePaymentSubmit($event)"></app-profile-payment>
            <app-stripe-profile-payment [provider]="affiliate"
                    (createConnectedAccount)="onCreateConnectedStripeAccount()"
                    (removeAccount)="onRemoveStripeAccount()"
                    (connectAccount)="onConnectAccount()"
                    (updateAccount)="onUpdateStripeAccount()">
            </app-stripe-profile-payment>
            -->

            <hr>
            <div class="row mt-3 mb-5">
                <div class="col-12 text-center">
                    <a (click)="onUnregisterRequest()" class="link-terciary text-uppercase text-underline cursor-pointer mb-sm-0">{{ 'affiliateprofile.Quiero dar de baja el servicio' | translate }}</a>
                </div>
            </div>
        </div>
    </div>
</div>

<app-message-panel [preloading]="true"></app-message-panel>