import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'tourCommissionFarePipe'
})
export class TourCommissionFarePipe implements PipeTransform {

  transform(customCommissionFare: number, defaultCommissionFare: number): number {

       let tourCommission = customCommissionFare;
       if ( tourCommission == null ) {
            tourCommission = defaultCommissionFare;
       }

       return tourCommission * 100;


  }

}
