
export class BookContactDetailsView {

     private _bookId: number;
     private _email: string;
     private _name: string;
     private _surname: string;
     private _phone: string;


     constructor(
          bookId: number,
          email: string,
          name: string,
          surname: string,
          phone: string

     ) {

          this._bookId = bookId;
          this._email = email;
          this._name = name;
          this._surname = surname;
          this._phone = phone;

     }

     get bookId(): number {
          return this._bookId;
     }

     get email(): string {
          return this._email;
     }

     get name(): string {
          return this._name;
     }

     get surname(): string {
          return this._surname;
     }

     get phone(): string {
          return this._phone;
     }


}