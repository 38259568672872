import { SchedulePlanning } from './schedule-planning.model';

export class ScheduleSeason {


    private _startDay: string;
    private _finishDay: string;
    private _details: SchedulePlanning;

    constructor(
      startDay: string,
      finishDay: string,
      details: SchedulePlanning
    ) {

        this._startDay = startDay;
        this._finishDay = finishDay;
        this._details = details;

    }


    get startDay(): string {
        return this._startDay;
    }

    get finishDay(): string {
        return this._finishDay;
    }

    get details(): SchedulePlanning {
        return this._details;
    }

}