import { CompanyBillingDetailsView } from 'src/app/domain/views/billing/company-billing-details.view';
import { CommissionType } from './common/commission-type.constants';
import { GuideServiceState } from './common/guide-service-state.constants';

export class GuideServiceDetails {

     private _id: number;
     private _tourId: number;
     private _tourNameTranslated: string;
     private _guide: CompanyBillingDetailsView;
     private _provider: CompanyBillingDetailsView;
     private _people: number;
     private _commissionPax: number;
     private _price: number;
     private _type: CommissionType;
     private _day: string;
     private _hour: string;
     private _state: GuideServiceState;     
     private _canApprove: boolean;
     private _canEdit: boolean;
     private _canRemove: boolean;

     constructor(
          id: number,
          tourId: number,
          tourNameTranslated: string,
          guide: CompanyBillingDetailsView,
          provider: CompanyBillingDetailsView,
          people: number,
          commissionPax: number,
          price: number,
          type: CommissionType,
          day: string,
          hour: string,
          state: GuideServiceState,
          canApprove: boolean,
          canEdit: boolean,
          canRemove: boolean,
     ) {
          this._id = id;
          this._tourId = tourId;
          this._tourNameTranslated = tourNameTranslated;
          this._guide = guide;
          this._provider = provider;
          this._people = people;
          this._commissionPax = commissionPax;
          this._price = price;
          this._type = type;
          this._day = day;
          this._hour = hour;
          this._state = state;
          this._canApprove = canApprove;
          this._canEdit = canEdit;
          this._canRemove = canRemove;
     }

     get id(): number {
          return this._id;
     }

     get tourId(): number {
          return this._tourId;
     }

     get tourNameTranslated(): string {
          return this._tourNameTranslated;
     }

     get guide(): CompanyBillingDetailsView {
          return this._guide;
     }

     get provider(): CompanyBillingDetailsView {
          return this._provider;
     }

     get people(): number {
          return this._people;
     }

     get commissionPax(): number {
          return this._commissionPax;
     }

     get price(): number {
          return this._price;
     }

     get type(): CommissionType {
          return this._type;
     }

     get day(): string {
          return this._day;
     }

     get hour(): string {
          return this._hour;
     }

     get state(): GuideServiceState {
          return this._state;
     }

     get canApprove(): boolean {
          return this._canApprove;
     }

     get canEdit(): boolean {
          return this._canEdit;
     }

     get canRemove(): boolean {
          return this._canRemove;
     }

     set state(state: GuideServiceState) {
          this._state = state;
     }


}