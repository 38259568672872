import { RouterModule, Routes } from '@angular/router';
import { NavigationConstants } from 'src/app/ui/commons/navigation.constants';
import { AffiliateAuthorizedGuard } from 'src/app/ui/guards/affiliateauthorized.guard';

import { AffiliateComponent } from './affiliate.component';
import { AffiliateInvoicdeDetailsComponent } from './billing/invoices/details/invoice-details.component';
import { AffiliateListInvoicesComponent } from './billing/invoices/list/list.component';
import { AffiliateListBillingComponent } from './billing/list/list.component';
import { AffiliateDashboardComponent } from './dashboard/dashboard.component';
import { AffiliateProfileComponent } from './profile/profile.component';
import { AffiliateListToursComponent } from './tour/list/list.component';


const affiliateRoutes: Routes = [
     {
          path: NavigationConstants.NAVIGATION_AFFILIATE,
          component: AffiliateComponent,
          children: [
               { path: NavigationConstants.NAVIGATION_AFFILIATE_DASHBOARD, component: AffiliateDashboardComponent },
               { path: NavigationConstants.NAVIGATION_AFFILIATE_PROFILE, component: AffiliateProfileComponent },
               { path: NavigationConstants.NAVIGATION_AFFILIATE_LIST_TOURS, component: AffiliateListToursComponent },
               { path: NavigationConstants.NAVIGATION_AFFILIATE_BILLING, component: AffiliateListBillingComponent },
               { path: NavigationConstants.NAVIGATION_PROVIDER_INVOICES, component: AffiliateListInvoicesComponent },
               { path: NavigationConstants.NAVIGATION_PROVIDER_INVOICE_DETAILS, component: AffiliateInvoicdeDetailsComponent }
          ],
          canActivate: [ AffiliateAuthorizedGuard ]
     }
];


export const AFFILIATE_ROUTES = RouterModule.forChild( affiliateRoutes );
