import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { SessionInteractor } from 'src/app/domain/interactor/session.interactor';
import { MessagePanelComponent } from '../shared/message-panel/message-panel.component';
import { ServerErrorExceptionCase } from 'src/app/domain/views/server-error-manager-exception-case.view';
import { AppConstants } from 'src/app/domain/common/app.constants';
import { NavigationConstants } from '../../commons/navigation.constants';
import { marker as I18N } from '@biesbjerg/ngx-translate-extract-marker';
import { GenericException } from 'src/app/domain/exceptions/generic.exception';

@Injectable({
  providedIn: 'root'
})
export class ServerErrorManager {

    constructor(
      private sessionInteractor: SessionInteractor,
      private router: Router) {
    }


    manageErrorCode(error: GenericException, messagePanelComponent: MessagePanelComponent, exceptionCases: Array<ServerErrorExceptionCase> = null) {

        let isExcptionCase = false;

        if ( exceptionCases ) {


             for (let exceptionCaseIter = 0; exceptionCaseIter < exceptionCases.length; exceptionCaseIter++) {
                 const exceptonCase: ServerErrorExceptionCase = exceptionCases[exceptionCaseIter];
                 if ( exceptonCase.codes.includes( error.code ) ) {

                       if ( ( exceptonCase.causes.length == 0 ) || (error.cause && exceptonCase.causes.includes( error.cause ) ) ) {
                           exceptonCase.callback();
                           isExcptionCase = true;
                           break;
                      }
              }
           }
        }

        if ( !isExcptionCase ) {

            if ( error.code == AppConstants.ERROR_CLIENT_UNAUTHORIZED ) {

                this.showErrorMessage(error, messagePanelComponent, () => {
                    this.sessionInteractor.logout();
                    this.router.navigate([ NavigationConstants.getNavigationLogin() ]);
                })
           } else if ( error.code == AppConstants.ERROR_CLIENT_FORBIDDEN ) {

                this.showErrorMessage(error, messagePanelComponent, () => {
                    this.router.navigate( [ NavigationConstants.getNavigationForbidden() ] );
                })
            } else {

                this.showErrorMessage(error, messagePanelComponent);
            }


        }

    }



    private showErrorMessage(error: GenericException, messagePanelComponent: MessagePanelComponent, callback: () => void = null) {

        let message = null

        if ( error.code == AppConstants.ERROR_SERVER_FAILURE ) {
            message = I18N('servererrormanager.Se ha producido un error inesperado');
        } else if ( error.code == AppConstants.ERROR_CLIENT_FORBIDDEN) {
            message = I18N('servererrormanager.No tienes permisos para acceder a esta informaicón');
        } else if ( error.code == AppConstants.ERROR_CLIENT_UNAUTHORIZED ) {
            message = I18N('servererrormanager.No hemos podido recuperar tu sesión. Por favor, vuelve a loguearte');
        } else  if ( error.code == AppConstants.ERROR_CLIENT_NOT_FOUND ) {
            message = I18N('servererrormanager.Información no encontrada');
        } else if ( error.code == AppConstants.ERROR_CLIENT_BAD_REQUEST || error.code == AppConstants.ERROR_SERVER_UNKNOWN) {
            message = I18N('servererrormanager.Error de comunicación con el servidor');
        }

        if ( message != null ) {
          messagePanelComponent.showServerErrorMessage(message, error.message, callback);
        }

    }

}