import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseService } from '../base.service';
import { map } from 'rxjs/operators';
import { CompanyName } from '../../models/master/company/company-name.model';
import { AdminGuideApiEndpointsUtils } from '../../api/endpoints/admin-guide-api-endponints.utils';
import { TourResume } from '../../models/tour/tour-resume.model';
import { TourUtils } from '../../models/tour/common/tour.utils';


@Injectable({
     providedIn: 'root'
})
export class AdminGuideService extends BaseService {


     getGuidesNames(providerId: number): Observable<Array<CompanyName>> {

          return super.sendGetRequestWithRefresh(AdminGuideApiEndpointsUtils.getAdminGuidesNamesEndpoint(providerId), AdminGuideApiEndpointsUtils.getAdminGuidesNamesEndpointAcceptVersion())
          .pipe(
               map((response: any) => {

                    const guides: Array<CompanyName> = response.map((guide: any) => {
                         return new CompanyName(
                              guide.id,
                              guide.companyName
                         )
                    })

                    return guides;

               })
          )

     }



     findAllToursForManagement(guideId: number, localeI18N: string): Observable<Array<TourResume>> {

          return super.sendGetRequestWithRefresh(
               AdminGuideApiEndpointsUtils.getFindToursForManagementEndpoint(guideId), 
               AdminGuideApiEndpointsUtils.getFindToursForManagementAcceptVersion())
          .pipe(
          map((response: any) => {

               const toursResume = new Array<TourResume>();

               response.map((tourBasicApi: any) => {

               const tourResume = TourUtils.getTourResumeFromApi(tourBasicApi, localeI18N);
               toursResume.push(tourResume);
               });

               return toursResume;

          })
          )
     }



     

}
