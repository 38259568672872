import { Component, ViewChild, OnInit } from '@angular/core';
import { MessagePanelComponent } from 'src/app/ui/components/shared/message-panel/message-panel.component';
import { ServerErrorManager } from 'src/app/ui/components/utils/server-error-manager.utils';
import { ActivatedRoute } from '@angular/router';
import { NavigationConstants } from 'src/app/ui/commons/navigation.constants';
import { GenericException } from 'src/app/domain/exceptions/generic.exception';
import { InvoiceFullDetails } from 'src/app/business/models/billing/invoice-full-details.model';
import { AdminBillingInteractor } from 'src/app/domain/interactor/admin/billing.interactor';
import { BillingUtils } from 'src/app/ui/utils/billing.utils';
import { NavigatorUtils } from 'src/app/ui/utils/navigator.utils';


@Component({
     selector: 'app-affiliate-invoice-details-admin',
     templateUrl: './invoice-details.component.html'
})

export class AdminAffiliateInvoicdeDetailsComponent implements OnInit {

     @ViewChild(MessagePanelComponent, { static: false })
     private messagePanelComponent: MessagePanelComponent;

     invoiceId: number;
     invoiceFullDetails: InvoiceFullDetails;


     constructor(
          private adminBillingInteractor: AdminBillingInteractor,
          private serverErrorManager: ServerErrorManager,
          private activatedRoute: ActivatedRoute) {

               this.activatedRoute.params.subscribe(params => {
                    this.invoiceId = params[NavigationConstants.NAVIGATION_ADMIN_INVOICE_ID];

                    if (this.messagePanelComponent) {
                         this.messagePanelComponent.showLoading();
                    }

                    this.adminBillingInteractor.findAffiliateInvoice(this.invoiceId)
                    .subscribe(
                         (invoiceFullDetails: InvoiceFullDetails) => {
                              MessagePanelComponent.hideLoading();
                              this.invoiceFullDetails = invoiceFullDetails;

                         },
                         (error: GenericException) => {
                              MessagePanelComponent.hideLoading();
                              this.serverErrorManager.manageErrorCode(error, this.messagePanelComponent);
                         }
                    )
               });

          }

          ngOnInit() {



          }


          onInvoiceDownload() {

               this.messagePanelComponent.showLoading();

               this.adminBillingInteractor.invoiceAffiliateDownload(this.invoiceId)
               .subscribe(
                    (invoiceBlob: Blob) => {

                         MessagePanelComponent.hideLoading();
                         NavigatorUtils.downloadFile(invoiceBlob, BillingUtils.getInvoiceNameToDownload(this.invoiceFullDetails.invoiceDetails));

                    },
                    (error: GenericException) => {
                         MessagePanelComponent.hideLoading();
                         this.serverErrorManager.manageErrorCode(error, this.messagePanelComponent);
                    }
               )
          }


     }




