import { GuideServiceState } from './guide-service-state.constants'

export class GuideServiceUtils {


  public static getGuideServiceStates(): Array<GuideServiceState> {

    return new Array(
      GuideServiceState.Pending,
      GuideServiceState.Approved,
      //GuideServiceState.Billed,
      GuideServiceState.Deleted
    );

  }

}
