export class BookingOrderCustomer {


    private _id: number;
    private _email: string;
    private _name: string;
    private _surname: string;
    private _phone: string;

    constructor(
      id: number,
      email: string,
      name: string,
      surname: string,
      phone: string,
    ) {

        this._id = id;
        this._email = email;
        this._name = name;
        this._surname = surname;
        this._phone = phone;

    }

    get id(): number {
         return this._id;
    }

    get email(): string {
         return this._email;
    }

    get name(): string {
         return this._name;
    }

    get surname(): string {
         return this._surname;
    }

    get phone(): string {
         return this._phone;
    }

}
