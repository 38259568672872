import { Component, OnInit, ViewChild } from '@angular/core';
import { ChunkGeneric } from 'src/app/domain/views/chunk-generic.view';

import { Router } from '@angular/router';
import { NavigationConstants } from 'src/app/ui/commons/navigation.constants';
import { ProviderResume } from 'src/app/business/models/provider/provider-resume.model';
import { Pagination } from 'src/app/domain/views/pagination.view';
import { AdminProviderInteractor } from 'src/app/domain/interactor/admin/provider.interactor';
import { TranslateService } from '@ngx-translate/core';
import { marker as I18N } from '@biesbjerg/ngx-translate-extract-marker';


@Component({
  selector: 'app-list-providers',
  templateUrl: './list.component.html'
})
export class ListProvidersComponent implements OnInit  {

  providers: Array<ProviderResume>;
  pagination: Pagination;

  desktopVersion: boolean;
  initialWindowSize: number;

  paginationNextLabel: string;
  paginationPreviousLabel: string;


  constructor(
       private adminProviderInteractor: AdminProviderInteractor,
       private translateService: TranslateService,
       private router: Router ) {

      this.pagination = new Pagination(0, 1, 20);
      this.providers = new Array<ProviderResume>();
      this.desktopVersion = true;

  }



  ngOnInit() {

      this.initialWindowSize = window.innerWidth;
      this.loadProviders(1);

      this.translateService.get(I18N('pagination.Siguiente')).subscribe(
           (messageTranslated: string) => {
                this.paginationNextLabel = messageTranslated;
           }
      )

      this.translateService.get(I18N('pagination.Previous')).subscribe(
           (messageTranslated: string) => {
                this.paginationPreviousLabel = messageTranslated;
           }
      )


  }


  onDesktopVersionUpdated( isDesktopVersion: boolean ) {
      this.desktopVersion = isDesktopVersion;
  }



  createProvider() {
      this.router.navigate( [ NavigationConstants.getNavigationAdminCreateProvider() ] );
  }


  onPageChanged( currentPage: number ) {
      this.loadProviders(currentPage);
  }


  loadProviders(currentPage: number) {
      this.adminProviderInteractor.findProviders(currentPage)
        .subscribe(
            (chunkResult: ChunkGeneric<ProviderResume>) => {

                this.pagination = new Pagination(chunkResult.pagination.totalItems, chunkResult.pagination.currentPage, chunkResult.pagination.itemsPerPage);
                this.providers = chunkResult.items

            },
            () => {

            }

        )
  }


  onProviderClick( providerId: number ) {

      this.router.navigate([ NavigationConstants.getNavigationAdminProviderDetail(providerId) ]);

  }

}
