<div class="animated fadeIn">
    <div class="row header-dashboard header-wizard-progress align-items-lg-center align-items-start">
        <div class="col-12 pl-0">
            <h2 class="font-weight-bold">{{ 'galleryManagment.Galería de imágenes' | translate }}</h2>
            <p>{{ 'galleryManagment.Descripción sobre galería de imágenes' | translate }}</p>
        </div>
    </div>
    <div class="row dashboard-content-section">
        <div class="col-12 px-0">
            <form class="form-boxes m-0 p-0">
                <div class="row pb-5">
                    <div class="col-12">
                        <label for="">{{ 'galleryManagment.Imágenes del tour' | translate }}</label>
                        <div class="custom-file browse-file">
                            <div class="custom-file-label" for="customFile" name="customFile">{{ 'galleryManagment.Click aquí para subir' | translate }}</div>
                            <input (change)="onImageSelected($event)" type="file" class="custom-file-input" accept="image/*">
                        </div>
                        <div *ngIf="fileInputError" class="pt-2 form-control-valid-feedback"> {{ fileInputError | translate }} </div>
                    </div>
                </div>
                <div *ngIf="tourImages" class="row gallery-managment">
                    <div class="col-12 col-md-4 col-lg-3 mb-4" *ngFor="let tourImage of tourImages">
                        <img class="text-center grey-color" src="{{ tourImage.medium.url | defaultPlaceholderImage : imageMediumSize }}">
                        <p class="text-right grey-color mt-2">
                            <a (click)="onDeleteImage(tourImage.id)" class="link-terciary">
                                <img class="ic-delete-img mr-1" src="assets/img/ic_delete_grey.svg">{{ 'galleryManagment.Eliminar' | translate }}
                            </a>
                        </p>
                    </div>
                </div>
                <div *ngIf="tourImages?.length == 0" class="row empty-section">
                    <div class="col-12 text-center my-auto">
                        <img class="logo mb-4" src="assets/img/logo_opacity-21.png">
                        <p>{{ 'galleryManagment.Todavía no hay imágenes del tour. ¡Añade alguna! :)' | translate }}</p>
                    </div>
                </div>
            </form>
        </div>
    </div>

</div>


<app-message-panel></app-message-panel>