import { Component, Output, EventEmitter, OnInit, ViewChild, Input } from '@angular/core';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { TourLocationFormView } from 'src/app/domain/views/provider/tour/location-form.view';
import { MapsAPILoader, MouseEvent } from '@agm/core';
import { TourStepFormView } from 'src/app/domain/views/provider/tour/sept-form.view';
import { AppConstants } from 'src/app/domain/common/app.constants';

@Component({
  selector: 'app-create-tour-location',
  templateUrl: './location.component.html'
})
export class CreateTourLocationComponent implements OnInit {

  createTourLocationForm: FormGroup;
  refreshValidators: boolean;

  latitude: number = 0;
  longitude: number = 0;
  zoom:number = 3;


  i18nTraslation: boolean;

  @Input()
  set isI18nTraslation(isI18nTraslation: boolean) {
      this.i18nTraslation = isI18nTraslation;
  }


  @Input()
  set tourLocation(tourLocation: TourLocationFormView) {
      if ( tourLocation ) {
          this.createTourLocationForm.get('meetingPoint').setValue(tourLocation.meetingPoint);
          this.createTourLocationForm.get('meetingPointMain').setValue(tourLocation.meetingPointMain);
          this.createTourLocationForm.get('locationCoordX').setValue(tourLocation.locationCoordX);
          this.createTourLocationForm.get('locationCoordY').setValue(tourLocation.locationCoordY);
          this.createTourLocationForm.get('locationIndications').setValue(tourLocation.locationIndications);
          this.createTourLocationForm.get('locationIndicationsMain').setValue(tourLocation.locationIndicationsMain);
          this.createTourLocationForm.get('locationMoreIndications').setValue(tourLocation.locationMoreIndications);
          this.createTourLocationForm.get('locationMoreIndicationsMain').setValue(tourLocation.locationMoreIndicationsMain);

          if ( this.i18nTraslation ) {
              this.createTourLocationForm.get('locationCoordX').disable();
              this.createTourLocationForm.get('locationCoordY').disable();
          } else {
              this.createTourLocationForm.get('locationCoordX').enable();
              this.createTourLocationForm.get('locationCoordY').enable();
          }

          this.latitude = tourLocation.locationCoordX;
          this.longitude = tourLocation.locationCoordY;
      }
  }


  @Output() tourLocationEmitter = new EventEmitter<TourStepFormView<TourLocationFormView>>(true)


  constructor(private mapsAPILoader: MapsAPILoader) {

      this.refreshValidators = false;

      this.createTourLocationForm = new FormGroup({
          'meetingPoint': new FormControl('', [Validators.required, Validators.maxLength(AppConstants.TEXT_DEFAULT_MAX_LENGTH)] ),
          'meetingPointMain': new FormControl(''),
          'locationCoordX': new FormControl('', [Validators.required, Validators.max(AppConstants.NUMBER_MAX_VALE)] ),
          'locationCoordY': new FormControl('', [Validators.required, Validators.max(AppConstants.NUMBER_MAX_VALE)] ),
          'locationIndications': new FormControl(''),
          'locationIndicationsMain': new FormControl(''),
          'locationMoreIndications': new FormControl(''),
          'locationMoreIndicationsMain': new FormControl('')
      })

  }

  ngOnInit() {
      this.mapsAPILoader.load().then(() => {
          if ('geolocation' in navigator) {
              navigator.geolocation.getCurrentPosition((position) => {
                  if ( !this.latitude || !this.longitude  ) {
                      this.latitude = position.coords.latitude;
                      this.longitude = position.coords.longitude;
                  }

                  this.zoom = 15;
              }, (error) => {
                  console.log(error);
              });
          }
      })
  }


  get meetingPointMain(): string {
      return this.createTourLocationForm.get('meetingPointMain').value;
  }

  get locationIndicationsMain(): string {
      return this.createTourLocationForm.get('locationIndicationsMain').value;
  }

  get locationMoreIndicationsMain(): string {
      return this.createTourLocationForm.get('locationMoreIndicationsMain').value;
  }

  saveLocation() {

      this.refreshValidators = true

      if ( this.createTourLocationForm.valid ) {
          this.emitEventWithFormDetails(true)
      }
  }


  markerDragEnd(event: MouseEvent) {
    this.latitude = event.coords.lat;
    this.longitude = event.coords.lng;

    this.createTourLocationForm.get('locationCoordX').setValue(this.latitude);
    this.createTourLocationForm.get('locationCoordY').setValue(this.longitude);
  }


  onBackButton() {
      this.emitEventWithFormDetails(false);
  }

  onLocationCoordsUpdated() {
      this.latitude = this.createTourLocationForm.get('locationCoordX').value;
      this.longitude = this.createTourLocationForm.get('locationCoordY').value;
  }



  private emitEventWithFormDetails(isNextStep: boolean) {

      const tourLocation = new TourLocationFormView(
          this.createTourLocationForm.value.meetingPoint,
          this.createTourLocationForm.value.meetingPointMain,
          this.createTourLocationForm.value.locationCoordX,
          this.createTourLocationForm.value.locationCoordY,
          this.createTourLocationForm.value.locationIndications,
          this.createTourLocationForm.value.locationIndicationsMain,
          this.createTourLocationForm.value.locationMoreIndications,
          this.createTourLocationForm.value.locationMoreIndicationsMain
      )

      this.tourLocationEmitter.emit(new TourStepFormView(isNextStep, tourLocation))

  }






}
