<form class="form-boxes m-0 p-0" action="" [formGroup]="reviewForm">
    <div class="modal-body mb-5">
        <h5 class="modal-title">{{ 'editReview.Edita la review' | translate}}</h5>
        <div class="row mt-5">
            <div class="col-12">

                <div class="row mt-4">
                    <div class="col-12 d-flex justify-content-center">
                        <div class="rating">
                            <!--elements are in reversed order, to allow "previous sibling selectors" in CSS-->
                            <input type="radio" name="rating" value="5" id="ratinge5" [checked]="this.currentRating == 5" (change)="onRatingChanged(5)"><label for="ratinge5"><img src="assets/img/ic_star.svg"></label>
                            <input type="radio" name="rating" value="4" id="ratinge4" [checked]="this.currentRating == 4" (change)="onRatingChanged(4)"><label for="ratinge4"><img src="assets/img/ic_star.svg"></label>
                            <input type="radio" name="rating" value="3" id="ratinge3" [checked]="this.currentRating == 3" (change)="onRatingChanged(3)"><label for="ratinge3"><img src="assets/img/ic_star.svg"></label>
                            <input type="radio" name="rating" value="2" id="ratinge2" [checked]="this.currentRating == 2" (change)="onRatingChanged(2)"><label for="ratinge2"><img src="assets/img/ic_star.svg"></label>
                            <input type="radio" name="rating" value="1" id="ratinge1" [checked]="this.currentRating == 1" (change)="onRatingChanged(1)"><label for="ratinge1"><img src="assets/img/ic_star.svg"></label>
                        </div>
                    </div>
                    <input formControlName="rating" type="number" name="rating" style="display: none;" />
                    <app-feedback [formGroup]="reviewForm" field="rating" validator="required" feedback="{{ 'review.Califica la experiencia' | translate }}" [refresh]="refreshValidators" class="mx-auto pt-3"></app-feedback>
                    <app-feedback [formGroup]="reviewForm" field="rating" validator="min" feedback="{{ 'review.Califica la experiencia' | translate }}" [refresh]="refreshValidators" class="mx-auto pt-3"></app-feedback>
                    <app-feedback [formGroup]="reviewForm" field="rating" validator="max" feedback="{{ 'review.Califica la experiencia' | translate }}" [refresh]="refreshValidators" class="mx-auto pt-3"></app-feedback>
                </div>

                <div class="row mt-5">
                    <div class="col-12">
                        <div class="field pb-2 d-grid">
                            <textarea formControlName="comments" type="text" name="comments" id="comments" rows="3" cols=""></textarea>
                            <label for="comments">{{ 'review.Escribe tu opinión:' | translate }}<span class="font-italic font-weight-normal">{{ '(Opcional)' | translate }}</span></label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer border-top-0">
        <input (click)="saveReview()" type="submit" value="{{ 'editReview.Guardar datos' | translate }}" class="btn bg-primary-color mb-3 mb-sm-0 mr-4 modal-confirm" />
        <input (click)="closeModal()" type="submit" value="{{ 'editReview.Cancelar' | translate }}" class="btn mb-3 mb-sm-0 modal-cancel" />
    </div>


</form>



<app-message-panel></app-message-panel>