import { ChunkGeneric } from 'src/app/domain/views/chunk-generic.view';
import { Review } from './review.model';

export class ReviewsReport {

  private _travelers: number;
  private _averageRating: number;
  private _totalReviews: number;
  private _reviewsChunk: ChunkGeneric<Review>;

  constructor(travelers: number, averageRating: number, totalReviews: number, reviewsChunk: ChunkGeneric<Review>) {
    this._travelers = travelers;
    this._averageRating = averageRating;
    this._totalReviews = totalReviews;
    this._reviewsChunk = reviewsChunk;
  }

  get travelers(): number {
    return this._travelers;
  }

  set travelers(travelers: number) {
    this._travelers = travelers;
  }

  get averageRating(): number {
    return this._averageRating;
  }

  set averageRating(averageRating: number) {
    this._averageRating = averageRating;
  }

  get totalReviews(): number {
    return this._totalReviews;
  }

  set totalReviews(totalReviews: number) {
    this._totalReviews = totalReviews;
  }

  get reviewsChunk(): ChunkGeneric<Review> {
    return this._reviewsChunk;
  }

  set reviewsChunk(reviewsChunk: ChunkGeneric<Review>) {
    this._reviewsChunk = reviewsChunk;
  }

}
