import { Pipe, PipeTransform } from '@angular/core';
import { marker as I18N } from '@biesbjerg/ngx-translate-extract-marker';

@Pipe({
  name: 'invoiceStatePayment'
})
export class InvoiceStatePaymentPipe implements PipeTransform {

  transform(settledUp: boolean): string {

    if ( !settledUp ) {
      return I18N('invoiceState.Pendiente');
    }
    return I18N('invoiceState.Completada');

  }

}
