<form class="form-boxes m-0 p-0" action="" [formGroup]="comissionForm">
     <div class="modal-header pb-0">
          <button type="button" class="close" aria-label="Close" (click)="closeModal()">
               <span aria-hidden="true">&times;</span>
          </button>
     </div>
     <div class="modal-body mb-5">
          <h5 class="modal-title">{{ 'editaffiliatecommission.Modifica la comisión del afiliado' | translate}}</h5>
          <div class="row mt-5">
               <div class="col-12 col-md-12 d-flex mb-5">
                    <div class="checkbox-custom mr-2">
                         <input (change)="onChangeDefaultCommission()" formControlName="isDefaultCommission" type="checkbox" id="isDefaultCommission" />
                         <div class="checkbox-custom-visible"></div>
                    </div>
                    <label for="isDefaultCommission">{{ 'editaffiliatecommission.Usar comisión por defecto' | translate }}</label>
               </div>
               <div *ngIf="!usingDefaulutCommission" class="col-12 col-md-6">
                    <div class="field pb-2">
                         <input formControlName="commission" type="number">
                         <label for="language">
                              {{ 'editaffiliatecommission.Comisión personalizada' | translate }}
                              <span *ngIf="usingPercentage">(%)</span>
                              <span *ngIf="!usingPercentage">(€)</span>
                         </label>
                    </div>
                    <app-feedback [formGroup]="comissionForm" field="commission" validator="max" feedback="{{ 'editaffiliatecommission.Número demasiado grande' | translate }}" [refresh]="refreshValidators"></app-feedback>
                    <app-feedback [formGroup]="comissionForm" field="commission" validator="min" feedback="{{ 'editaffiliatecommission.Número menor que 0' | translate }}" [refresh]="refreshValidators"></app-feedback>
                    <app-feedback [formGroup]="comissionForm" field="isPercentageValid" validator="mustPercentageValid" feedback="{{ 'editaffiliatecommission.Porcentaje debe ser un número entre 0 y 1' | translate }}" [refresh]="refreshValidators"></app-feedback>
               </div>
               <div *ngIf="!usingDefaulutCommission" class="col-12 col-md-12 d-flex">
                    <div class="checkbox-custom mr-2">
                         <input (change)="onChangeIsPercentage()" formControlName="isPercentage" type="checkbox" id="isPercentage" />
                         <div class="checkbox-custom-visible"></div>
                    </div>
                    <label for="isPercentage">{{ 'editaffiliatecommission.Usar valor en porcentaje (desmarcado será el importe exacto en euros)' | translate }}</label>
               </div>
          </div>
     </div>
     <div class="modal-footer border-top-0">
          <input (click)="saveCommission()" type="submit" value="{{ 'editaffiliatecommission.Guardar datos' | translate }}" class="btn bg-primary-color mb-3 mb-sm-0 mr-4 modal-confirm" />

          <input (click)="closeModal()" type="submit" value="{{ 'editaffiliatecommission.Cancelar' | translate }}" class="btn mb-3 mb-sm-0 modal-cancel" />

     </div>


</form>



<app-message-panel></app-message-panel>