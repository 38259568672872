import { ApiConstants } from '../api.constants';

export class AdminApiEndpointsUtils {

     public static getAdminProfileUpdateSettingsEndpoint(): string {
          return 'admin/settings';
     }

     public static getAdminProfileUpdateSettingsObject( languageCode: string ): any {

          return {
               'languageCode': languageCode
          }
     }

     public static getAdminProfileUpdateSettingsEndpointAcceptVersion(): string {
          return ApiConstants.API_HEADER_ACCEPT_V_1_0_1;
     }


}


