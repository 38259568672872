export class TourFareFormView {

    private _id: number;
    private _name: string;
    private _nameMain: string;
    private _price: number;

    constructor(
      id: number,
      name: string,
      nameMain: string,
      price: number,
    ) {

        this._id = id;
        this._name = name;
        this._nameMain = nameMain;
        this._price = price;

    }


    get id(): number {
        return this._id;
    }

    get name(): string {
        return this._name;
    }

    get nameMain(): string {
        return this._nameMain;
    }

    get price(): number {
        return this._price;
    }

}