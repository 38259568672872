<app-create-tour-schedule-weekdays (selectedWeekdayEvent)="onSelectedWeekdayEvent($event)" [forceWeekdaySelection]="weekday" [weekdaysIncluded]="weekdaysIncluded"></app-create-tour-schedule-weekdays>

<div class="col-12">
    <app-feedback [formGroup]="planningFormGroup" field="days" validator="required" feedback="{{ 'singup.Es necesario que indiques las jornadas para un tour' | translate }}" [refresh]="refreshValidators"></app-feedback>
</div>

<app-create-tour-schedule-weekday-hours [isI18nTraslation]="i18nTraslation" [weekdayFormGroup]="currentWeekdayFormGroup" (weekdayHoursDefined)="onWeekdayUpdatedHours($event)"></app-create-tour-schedule-weekday-hours>


<div class="row">
    <div class="col-12">
        <label for="">{{ 'createtourdate.Días con horarios especiales' | translate }}</label>
    </div>
</div>
<div class="row mx-0 details-calendar">
    <div class="col-12 col-sm-8 col-lg-6 col-xl-5 form-calendar-especial-days">
        <app-create-tour-schedule-calendar [minCalendarDate]="minCalendarDate" [maxCalendarDate]="maxCalendarDate" [weekdaysIncluded]="weekdaysIncluded" [exceptionsIncluded]="exceptionsIncluded" (calendarSelectedDate)="onCalendarSelectedDate($event)"></app-create-tour-schedule-calendar>
    </div>

    <div class="col-12 col-sm-4 col-lg-6 col-xl-5 mb-4 mb-md-auto">
        <app-create-tour-schedule-exception-day [isI18nTraslation]="i18nTraslation" [exceptionDayFormGroup]="currentExceptionFormGroup" (manageExceptionDay)="onManageExceptionDay($event)"></app-create-tour-schedule-exception-day>
    </div>
</div>