import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MessagePanelComponent } from 'src/app/ui/components/shared/message-panel/message-panel.component';
import { ChunkGeneric } from 'src/app/domain/views/chunk-generic.view';
import { Pagination } from 'src/app/domain/views/pagination.view';
import { InvoiceDetails } from 'src/app/business/models/billing/invoice-details.model';
import { NextBilling } from 'src/app/business/models/billing/next-billing.model';
import { TranslateService } from '@ngx-translate/core';
import { marker as I18N } from '@biesbjerg/ngx-translate-extract-marker';
import { FindInvoicesFilterView } from 'src/app/domain/views/billing/find-billing-filter.view';


@Component({
     selector: 'app-list-invoices',
     templateUrl: './list.component.html'
})
export class ListInvoicesComponent implements OnInit {

     @ViewChild(MessagePanelComponent)
     private messagePanelComponent: MessagePanelComponent;


     @Input() nextBilling: NextBilling;
     @Input() canReceivePayments: boolean;
     @Input()
     set chunkInvoices(chunkInvoices: ChunkGeneric<InvoiceDetails>) {
          if ( chunkInvoices ) {
               this.pagination = new Pagination(chunkInvoices.pagination.totalItems, chunkInvoices.pagination.currentPage, chunkInvoices.pagination.itemsPerPage);
               this.pageNumber = this.pagination.currentPage;
               this.invoices = chunkInvoices.items;
               this.noResults = this.invoices.length == 0;
          }
     }


     @Output() findInvoicesTrigger = new EventEmitter<FindInvoicesFilterView>();
     @Output() goToProfile = new EventEmitter<boolean>();
     @Output() invoiceDownload = new EventEmitter<InvoiceDetails>();
     @Output() invoiceDetails = new EventEmitter<number>();


     pageNumber: number;
     pagination: Pagination;

     invoices: Array<InvoiceDetails>;

     noResults: boolean;

     desktopVersion: boolean;
     initialWindowSize: number;

     paginationNextLabel: string;
     paginationPreviousLabel: string;

     constructor(
          private translateService: TranslateService) {

               this.pagination = new Pagination(0, 1, 20);
               this.pageNumber = this.pagination.currentPage;
               this.noResults = true;

               this.desktopVersion = true;

          }



          ngOnInit() {
               this.initialWindowSize = window.innerWidth;

               this.translateService.get(I18N('pagination.Siguiente')).subscribe(
                    (messageTranslated: string) => {
                         this.paginationNextLabel = messageTranslated;
                    }
               )

               this.translateService.get(I18N('pagination.Previous')).subscribe(
                    (messageTranslated: string) => {
                         this.paginationPreviousLabel = messageTranslated;
                    }
               )

               this.findInvoices();

          }


          onDesktopVersionUpdated(isDesktopVersion: boolean) {
               this.desktopVersion = isDesktopVersion;
          }

          findInvoices() {
               this.findInvoicesTrigger.emit(new FindInvoicesFilterView(this.pageNumber, null));
          }


          onPageChanged(currentPage: number) {
               this.pageNumber = currentPage;
               this.findInvoices();
          }


          onInvoiceDetails(invoiceId: number) {

               this.invoiceDetails.emit(invoiceId);

          }

          onInvoiceDownload(invoiceDetails: InvoiceDetails) {

               this.invoiceDownload.emit(invoiceDetails);
          }


          onGoToProfile() {
               this.goToProfile.emit(true);
          }

     }
