<div class="animated fadeIn">
     <div class="row header-dashboard align-items-lg-center align-items-start">
          <div class="col-12 pl-0">
               <h2 class="font-weight-bold">{{ 'providerprofile.Perfil' | translate }}</h2>
               <p>{{ 'providerprofile.Revisa y edita tus datos' | translate }}</p>
          </div>
     </div>

     <div class="row dashboard-content-section">
          <div class="col-12 px-0">

               <app-change-password></app-change-password>
               <app-provider-profile-settings [provider]="provider"></app-provider-profile-settings>


               <div class="mb-5 pb-5">

                    <!-- Empresa -->
                    <div class="row mb-2">
                         <div class="col-10">
                              <h2 class="font-weight-bold d-flex"><img class="ic-mid mr-2" src="assets/img/ic_user.svg">{{ 'providerdetails.Logo' | translate }}</h2>
                              <h5>{{ 'providerdetails.Imagen para mostrar en las reservas' | translate }}</h5>
                              <hr class="w-10 float-left mt-2 mb-4">
                         </div>
                         <div class="col-2 text-right">
                              <a class="cursor-pointer" (click)="onToggleLogoEdition()">
                                   <img class="ic-mid mr-2" src="{{ fileInputDisabled ? 'assets/img/ic_edit.svg' : 'assets/img/ic_edit-disabled.svg'}}">
                              </a>
                         </div>
                    </div>

                    <div class="row gallery-managment" *ngIf="logo">
                         <div class="col-12 col-md-6 mb-4">
                              <img class="img-fluid" src="{{ logo.original.url }}">
                              <p class="text-right grey-color mt-2">
                                   <a (click)="onDeleteLogo()" class="btn btn-full-width btn-tertiary bg-grey-color" [ngClass]="fileInputDisabled ? 'disabled' : ''">
                                        {{ 'providerprofile.Eliminar' | translate }}
                                   </a>
                              </p>
                         </div>
                    </div>

                    <form class="form-boxes m-0 p-0" *ngIf="!logo">
                         <div class="row pb-5">
                              <div class="col-12 col-md-6 col-lg-4">
                                   <div class="custom-file browse-file">
                                        <input (change)="onImageSelected($event)" type="file" class="custom-file-input" accept="image/*" [disabled]="fileInputDisabled">
                                        <div class="custom-file-label" for="customFile" name="customFile">{{ 'providerprofile.Añadir imagen' | translate }}</div>
                                   </div>
                                   <div *ngIf="fileInputError" class="pt-2 form-control-valid-feedback"> {{ fileInputError | translate }} </div>
                              </div>
                         </div>
                    </form>

               </div>

               <app-profile-provider-details [provider]="provider" (providerDetails)="onProviderDetailsSubmit($event)"></app-profile-provider-details>
               <app-profile-payment [provider]="provider" (profilePayment)="onProviderPaymentSubmit($event)"></app-profile-payment>
               <app-profile-affiliate-websites *ngIf="isAffiliate" [affiliate]="affiliate" (affiliateWebsites)="onAffiliateWebsitesSubmit($event)"></app-profile-affiliate-websites>
               <app-stripe-profile-payment [provider]="provider"
               (createConnectedAccount)="onCreateConnectedStripeAccount()"
               (removeAccount)="onRemoveStripeAccount()"
               (connectAccount)="onConnectAccount()"
               (updateAccount)="onUpdateStripeAccount()">
          </app-stripe-profile-payment>



          <hr>
          <div class="row mt-3 mb-5">
               <div class="col-12 text-center">
                    <a (click)="onUnregisterRequest()" class="link-terciary text-uppercase text-underline cursor-pointer mb-sm-0">{{ 'providerprofile.Quiero dar de baja el servicio' | translate }}</a>
               </div>
          </div>
     </div>
</div>
</div>

<app-message-panel [preloading]="true"></app-message-panel>