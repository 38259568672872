import { Component, OnInit, ViewChild } from '@angular/core';
import { MessagePanelComponent } from 'src/app/ui/components/shared/message-panel/message-panel.component';
import { ActivatedRoute, Router } from '@angular/router';
import { NavigationConstants } from 'src/app/ui/commons/navigation.constants';
import { marker as I18N } from '@biesbjerg/ngx-translate-extract-marker';
import { ServerErrorManager } from 'src/app/ui/components/utils/server-error-manager.utils';
import { GenericException } from 'src/app/domain/exceptions/generic.exception';
import { ProviderGuideInteractor } from 'src/app/domain/interactor/provider/guide.interactor';
import { CompanyName } from 'src/app/business/models/master/company/company-name.model';
import { TourResume } from 'src/app/business/models/tour/tour-resume.model';
import { GuideServiceDetails } from 'src/app/business/models/billing/service-details.model';
import { ServiceEditionView } from 'src/app/domain/views/services/service-edition.view';
import { AdminGuideInteractor } from 'src/app/domain/interactor/admin/guide.interactor';
import { AdminProviderInteractor } from 'src/app/domain/interactor/admin/provider.interactor';
import { AdminGuideServiceInteractor } from 'src/app/domain/interactor/admin/guide-service.interactor';
import { ServiceTourEventFilter } from 'src/app/domain/views/services/service-tour-event-filter';
import { EventBasic } from 'src/app/business/models/book/event-basic.model';
import { AdminTourInteractor } from 'src/app/domain/interactor/admin/tour.interactor';

@Component({
     selector: 'app-admin-create-guide-service',
     templateUrl: './create.component.html'
})
export class AdminCreateGuideServiceComponent implements OnInit {

     @ViewChild(MessagePanelComponent)
     private messagePanelComponent: MessagePanelComponent;

     guideServiceDetails: GuideServiceDetails;
     tours: Array<TourResume>;
     guides: Array<CompanyName>;
     providers: Array<CompanyName>;
     events: Array<EventBasic>;

     constructor(
          private router: Router,
          private adminGuideInteractor: AdminGuideInteractor,
          private adminTourInteractor: AdminTourInteractor,
          private adminProviderInteractor: AdminProviderInteractor,
          private adminGuideServiceInteractor: AdminGuideServiceInteractor,
          private serverErrorManager: ServerErrorManager,
          private activatedRoute: ActivatedRoute
     ) {

               this.tours = new Array<TourResume>();
               this.guides = new Array<CompanyName>();
               this.providers = new Array<CompanyName>();
               this.events = new Array<EventBasic>();

               this.activatedRoute.params.subscribe( params => {
                    const guideServiceId = params[ NavigationConstants.NAVIGATION_GUIDE_SERVICE_ID ];
     
                    //Load service details if needed
                    if ( guideServiceId ) {
                         this.findGuideServiceAndReload(guideServiceId);
                    }
     
               });               

     }



     findGuideServiceAndReload(guideServiceId: number) {

          this.adminGuideServiceInteractor.findGuideService(guideServiceId)
          .subscribe(
               (guideServiceDetails: GuideServiceDetails) => {

                   this.guideServiceDetails = guideServiceDetails

                    //preload combos field for edition
                    this.onProviderSelected(this.guideServiceDetails.provider.companyId);
                    this.onGuideSelected(this.guideServiceDetails.guide.companyId);
                    this.onTourAndDateSelected(new ServiceTourEventFilter(this.guideServiceDetails.tourId, this.guideServiceDetails.day));

               },
               (error) => {
                    this.serverErrorManager.manageErrorCode(error, this.messagePanelComponent);
               }
          )

          
          
     }

     ngOnInit() {

          this.adminProviderInteractor.getProvidersNamesWithGuideManagement()
          .subscribe(
               ( providers: Array<CompanyName> ) => {

                    this.providers = providers;
               },
               (error: GenericException) => {
                    this.serverErrorManager.manageErrorCode(error, this.messagePanelComponent);

               }
          )

          

     }

     onGuideServicesUpdated(serviceEditionView: ServiceEditionView) {

          this.messagePanelComponent.showLoading();

          this.adminGuideServiceInteractor.saveGuideService(serviceEditionView.guideServiceId, serviceEditionView.guideId, serviceEditionView.tourId, serviceEditionView.tourName, serviceEditionView.date, serviceEditionView.hour, serviceEditionView.people, serviceEditionView.amount)
          .subscribe(
               () => {

                    MessagePanelComponent.hideLoading();
                    this.messagePanelComponent.showSuccessMessage(I18N('createcommission.Información guardada correctamente'), () => this.onGuideServicesNavigation());

               },
               (error: GenericException) => {

                    MessagePanelComponent.hideLoading();
                    this.serverErrorManager.manageErrorCode(error, this.messagePanelComponent);

               }
          )

     }


     onGuideServicesNavigation() {
          this.router.navigate( [ NavigationConstants.getNavigationAdminGuideServices() ] );
     }


     onProviderSelected(providerId: number) {

          this.guides = new Array<CompanyName>();
          if ( providerId ) {

               this.messagePanelComponent.showLoading();

               this.adminGuideInteractor.getGuidesNames(providerId)
               .subscribe(
                    ( guides: Array<CompanyName> ) => {

                         this.guides = guides;
                         MessagePanelComponent.hideLoading();

                    },
                    (error: GenericException) => {
                         MessagePanelComponent.hideLoading();
                         this.serverErrorManager.manageErrorCode(error, this.messagePanelComponent);

                    }
               )
          }
     }


     onGuideSelected(guideId: number) {

          this.messagePanelComponent.showLoading();

          if ( guideId == null ) {

               this.tours = Array<TourResume>();
               MessagePanelComponent.hideLoading();
               return;

          }

          this.adminGuideInteractor.findAllToursForManagement(guideId)
          .subscribe(
               (tours: Array<TourResume>) => {

                    this.tours = tours;
                    MessagePanelComponent.hideLoading();

               },
               (error: GenericException) => {

                    MessagePanelComponent.hideLoading();
                    this.serverErrorManager.manageErrorCode(error, this.messagePanelComponent);

               }
          )
     }



     onTourAndDateSelected(serviceTourEventFilter: ServiceTourEventFilter) {

          this.messagePanelComponent.showLoading();

          if ( serviceTourEventFilter == null || serviceTourEventFilter.day == null || serviceTourEventFilter.tourId == null ) {

               this.events = Array<EventBasic>();
               MessagePanelComponent.hideLoading();
               return;

          }

          this.adminTourInteractor.findEvents(serviceTourEventFilter.tourId, serviceTourEventFilter.day)
          .subscribe(
               (events: Array<EventBasic>) => {

                    this.events = events;
                    MessagePanelComponent.hideLoading();

               },
               (error: GenericException) => {

                    MessagePanelComponent.hideLoading();
                    this.serverErrorManager.manageErrorCode(error, this.messagePanelComponent);

               }
          )
     }


}