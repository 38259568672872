import { Component, ViewChild } from '@angular/core';
import { MessagePanelComponent } from '../../../shared/message-panel/message-panel.component';
import { ProfileInteractor } from 'src/app/domain/interactor/profile/profile.interactor';
import { ProviderDetailsView } from 'src/app/domain/views/provider/provider-details.view';
import { marker as I18N } from '@biesbjerg/ngx-translate-extract-marker';
import { ServerErrorManager } from '../../../utils/server-error-manager.utils';
import { AppConstants } from 'src/app/domain/common/app.constants';
import { ImageDetails } from 'src/app/business/models/image/image-details.model';
import { ImageSize } from 'src/app/business/models/image/common/image-size.constants';
import { GenericException } from 'src/app/domain/exceptions/generic.exception';
import { NavigatorUtils } from 'src/app/ui/utils/navigator.utils';
import { TranslateService } from '@ngx-translate/core';
import { ServerErrorExceptionCase } from 'src/app/domain/views/server-error-manager-exception-case.view';
import { ProfilePaymentView } from 'src/app/domain/views/profile-payment.view';
import { FormGroup } from '@angular/forms';
import { Provider } from 'src/app/business/models/provider/provider.model';
import { SessionInteractor } from 'src/app/domain/interactor/session.interactor';
import { Affiliate } from 'src/app/business/models/affiliate/affiliate.model';

@Component({
     selector: 'app-profile-provider',
     templateUrl: './profile.component.html'
})
export class ProviderProfileComponent {


     @ViewChild(MessagePanelComponent)
     private messagePanelComponent: MessagePanelComponent;

     isAffiliate: boolean;

     provider: Provider;
     affiliate: Affiliate;

     fileInputError: string;
     fileInputDisabled: boolean;

     logo: ImageDetails;
     imageMediumSize: ImageSize = ImageSize.Medium;

     affiliateDetailsForm: FormGroup;
     affiliateDetailsFormIsDisabled: boolean = true;
     affiliateDetailsFormRefreshValidators: boolean;


     constructor(
          private profileInteractor: ProfileInteractor,
          private sessionInteractor: SessionInteractor,
          private serverErrorManager: ServerErrorManager,
          private translateService: TranslateService ) {

               this.fileInputDisabled = true;

               this.isAffiliate = this.sessionInteractor.isAffiliateRole();

               this.profileInteractor.getProviderDetails()
               .subscribe(
                    (response: Provider) => {

                         MessagePanelComponent.hideLoading();

                         this.provider = response;
                         this.logo = this.provider.logo;

                    },
                    (error: GenericException) => {

                         MessagePanelComponent.hideLoading();
                         this.serverErrorManager.manageErrorCode(error, this.messagePanelComponent);

                    }
               )


               if ( this.isAffiliate ) {
                    this.profileInteractor.getAffiliateDetails()
                    .subscribe(
                         (response: Affiliate) => {

                              MessagePanelComponent.hideLoading();
                              this.affiliate = response;

                         },
                         (error: GenericException) => {

                              MessagePanelComponent.hideLoading();
                              this.serverErrorManager.manageErrorCode(error, this.messagePanelComponent);

                         }
                    )
               }


          }


          onProviderPaymentSubmit(providerPayment: ProfilePaymentView) {
               this.messagePanelComponent.showLoading();
               this.profileInteractor.updateProviderPayment(providerPayment)
               .subscribe(
                    () => {
                         MessagePanelComponent.hideLoading();
                         this.messagePanelComponent.showSuccessMessage(I18N('providerprofile.Datos actualizados'));

                    }, (error: GenericException) => {

                         MessagePanelComponent.hideLoading();
                         this.serverErrorManager.manageErrorCode(error, this.messagePanelComponent);
                    }
               )
          }


          onProviderDetailsSubmit(providerDetails: ProviderDetailsView) {
               this.messagePanelComponent.showLoading();
               this.profileInteractor.updateProviderDetails(providerDetails)
               .subscribe(
                    () => {
                         MessagePanelComponent.hideLoading();
                         this.messagePanelComponent.showSuccessMessage(I18N('providerprofile.Datos actualizados'));

                    }, (error: GenericException) => {

                         MessagePanelComponent.hideLoading();
                         this.serverErrorManager.manageErrorCode(error, this.messagePanelComponent);
                    }
               )
          }


          onAffiliateWebsitesSubmit(websites: Array<string>) {
               this.messagePanelComponent.showLoading();
               this.profileInteractor.updateAffiliateWebsites(websites)
               .subscribe(
                    () => {
                         MessagePanelComponent.hideLoading();
                         this.messagePanelComponent.showSuccessMessage(I18N('providerprofile.Datos actualizados'));

                    }, (error: GenericException) => {

                         MessagePanelComponent.hideLoading();
                         this.serverErrorManager.manageErrorCode(error, this.messagePanelComponent);
                    }
               )
          }



          onUnregisterRequest() {

               this.messagePanelComponent.showLoading();

               this.profileInteractor.requestProviderUnregister()
               .subscribe(
                    () => {
                         MessagePanelComponent.hideLoading();
                         this.messagePanelComponent.showSuccessMessage(I18N('providerprofile.Solicitud enviada. Pronto recibirás la confirmación en tu email'));

                    }, (error: GenericException) => {

                         MessagePanelComponent.hideLoading();
                         this.serverErrorManager.manageErrorCode(error, this.messagePanelComponent);
                    }
               )
          }


          onToggleLogoEdition() {
               this.fileInputDisabled = !this.fileInputDisabled;
          }

          onImageSelected(event: any) {
               this.fileInputError = null;
               if ( event.target.files.length > 0 ) {
                    const image: File = event.target.files[0];
                    if ( image.size > AppConstants.MAX_FILE_UPLOAD_SIZE ) {
                         this.fileInputError = I18N('providerprofile.El tamaño máximo de archivo son 8 MB');
                    } else {
                         this.messagePanelComponent.showLoading();
                         this.profileInteractor.uploadLogo(image)
                         .subscribe(
                              (logo: ImageDetails) => {
                                   MessagePanelComponent.hideLoading();
                                   this.logo = logo;
                              },
                              (error: GenericException) => {
                                   MessagePanelComponent.hideLoading();
                                   this.serverErrorManager.manageErrorCode(error, this.messagePanelComponent);
                              }
                         )
                    }
               }
          }

          onDeleteLogo() {
               this.messagePanelComponent.showLoading();
               this.profileInteractor.deleteLogo(this.logo.id)
               .subscribe(
                    () => {
                         this.logo = null;
                         MessagePanelComponent.hideLoading();
                    },
                    (error: GenericException) => {
                         MessagePanelComponent.hideLoading();
                         this.serverErrorManager.manageErrorCode(error, this.messagePanelComponent);
                    }
               )
          }



          onCreateConnectedStripeAccount() {
               this.messagePanelComponent.showLoading();

               this.profileInteractor.createProviderStripeAccount()
               .subscribe(
                    (stripeAccountUrl: string) => {
                         MessagePanelComponent.hideLoading();
                         this.messagePanelComponent.showSuccessMessage(
                              I18N('providerprofile.Cuenta de Stripe creada correctamente. A continuación será necesario que aceptes la vinculación de la cuenta para recibir pagos.')
                              , () => {
                                   NavigatorUtils.goToExternalURL(stripeAccountUrl);
                              }
                         );

                    }, (error: GenericException) => {

                         MessagePanelComponent.hideLoading();
                         this.serverErrorManager.manageErrorCode(error, this.messagePanelComponent);
                    }
               )
          }


          onConnectAccount() {
               this.messagePanelComponent.showLoading();

               this.profileInteractor.connectProviderStripeAccount()
               .subscribe(
                    (stripeAccountUrl: string) => {
                         MessagePanelComponent.hideLoading();
                         this.messagePanelComponent.showSuccessMessage(
                              I18N('providerprofile.A continuación será necesario que aceptes la vinculación de la cuenta para recibir pagos.')
                              , () => {
                                   NavigatorUtils.goToExternalURL(stripeAccountUrl);
                              }
                         );

                    }, (error: GenericException) => {

                         MessagePanelComponent.hideLoading();

                         const exceptionCases = new Array<ServerErrorExceptionCase>();

                         const exceptionCodes = new Array<number>();
                         exceptionCodes.push(AppConstants.ERROR_CLIENT_BAD_REQUEST);
                         exceptionCases.push( new ServerErrorExceptionCase(
                              exceptionCodes,
                              new Array<string>(),
                              () => {
                                   this.messagePanelComponent.showErrorMessage(
                                        I18N('providerprofile.No hemos podido finalizar la acción. Es posible que la información se esté actualizando. Por favor, inténtalo de nuevo.'),
                                        () => {
                                             NavigatorUtils.reloadApplication();
                                        })
                                   }
                              )
                         );

                         this.serverErrorManager.manageErrorCode(error, this.messagePanelComponent, exceptionCases);

                    }
               )
          }


          onRemoveStripeAccount() {
               this.messagePanelComponent.showLoading();

               this.profileInteractor.removeProviderStripeAccount()
               .subscribe(
                    () => {
                         MessagePanelComponent.hideLoading();
                         this.messagePanelComponent.showSuccessMessage(
                              I18N('providerprofile.Cuenta de Stripe desvinvulada correctamente.')
                              , () => {
                                   NavigatorUtils.reloadApplication();
                              }
                         );

                    }, (error: GenericException) => {

                         MessagePanelComponent.hideLoading();
                         this.serverErrorManager.manageErrorCode(error, this.messagePanelComponent);
                    }
               )
          }

          onUpdateStripeAccount() {

               this.messagePanelComponent.showLoading();

               this.translateService.get('providerprofile.Actualiza tu información de Stripe').subscribe(
                    (titleMessageTranslated: string) => {
                         this.translateService.get(I18N('providerprofile.Indica el ID de tu cuenta de Stripe conectada'), {})
                         .subscribe(
                              (subtitleMessageTranslated: string) => {
                                   this.translateService.get(I18N('providerprofile.Texto demasiado largo'), {})
                                   .subscribe(
                                        (validationTooLongMessageTranslated: string) => {

                                             MessagePanelComponent.hideLoading();

                                             this.messagePanelComponent.showInputMessage(
                                                  titleMessageTranslated,
                                                  subtitleMessageTranslated,
                                                  MessagePanelComponent.MESSAGE_INPUT_TYPE_TEXT,
                                                  null,
                                                  (stripeAccountId: string) => {
                                                       if (stripeAccountId.length > AppConstants.TEXT_DEFAULT_MAX_LENGTH) {
                                                            return validationTooLongMessageTranslated;
                                                       }
                                                  },
                                                  (stripeAccountId: string) => {

                                                       this.messagePanelComponent.showLoading();

                                                       this.profileInteractor.updateProviderStripeAccount(stripeAccountId)
                                                       .subscribe(
                                                            () => {

                                                                 MessagePanelComponent.hideLoading();
                                                                 this.messagePanelComponent.showSuccessMessage(I18N('providerprofile.Información actualizada'), () => { NavigatorUtils.reloadApplication(); });

                                                            },
                                                            (error: GenericException) => {
                                                                 MessagePanelComponent.hideLoading();
                                                                 this.serverErrorManager.manageErrorCode(error, this.messagePanelComponent);
                                                            }
                                                       )
                                                  }
                                             )

                                        }
                                   )
                              }
                         )
                    }
               )
          }



}
