import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseService } from '../base.service';
import { map } from 'rxjs/operators';
import { TourResume } from '../../models/tour/tour-resume.model';
import { GuideServiceDetails } from '../../models/billing/service-details.model';
import { TourUtils } from '../../models/tour/common/tour.utils';
import { CompanyBillingDetailsView } from 'src/app/domain/views/billing/company-billing-details.view';
import { BillingUtils } from '../../models/billing/common/billing.utils';
import { CalendarUtils } from 'src/app/ui/utils/calendar.utils';
import { ChunkGeneric } from 'src/app/domain/views/chunk-generic.view';
import { Pagination } from 'src/app/domain/views/pagination.view';
import { ApiConstants } from '../../api/api.constants';
import { GuideServiceState } from '../../models/billing/common/guide-service-state.constants';
import { GuideServiceStats } from '../../models/billing/service-stats.model';
import { GuideServiceTourStats } from '../../models/billing/service-tour-stats.model';
import { AdminGuideApiEndpointsUtils } from '../../api/endpoints/admin-guide-api-endponints.utils';


@Injectable({
     providedIn: 'root'
})
export class AdminGuideServiceService extends BaseService {


     createService(guideId: number, tourId: number, tourName: string, date: Date, hour: string, people: number, amount: number): Observable<boolean> {
          return super.sendPostRequestWithRefresh(
               AdminGuideApiEndpointsUtils.getAdminCreateGuideServiceEndpoint(), 
               AdminGuideApiEndpointsUtils.getAdminCreateGuideServiceObject(guideId, tourId, tourName, date, hour, people, amount), 
               AdminGuideApiEndpointsUtils.getAdminCreateGuideServiceAcceptVersion())
          .pipe(
               map(() => {
                    return true;
               })
          )
     }




     findGuideServices(month: number, year: number, providerId: number, guideId: number, state: GuideServiceState, page: number, localeI18N: string): Observable<ChunkGeneric<GuideServiceDetails>> {

          return super.sendGetRequestWithRefresh(
               AdminGuideApiEndpointsUtils.getAdminFindGuideServicesEndpoint(month, year, providerId, guideId, state, page),
               AdminGuideApiEndpointsUtils.getAdminFindGuideServicesEndpointAcceptVersion()
          )
          .pipe(
               map((response: any) => {

                    const guideServicesDetails = new Array<GuideServiceDetails>();

                    response.items.map((guideServiceDetailsApi: any) => {


                         let tourId: number = null;
                         let tourName: string = null;

                         if ( guideServiceDetailsApi.tour ) {
                              const tour: TourResume = TourUtils.getTourResumeFromApi(guideServiceDetailsApi.tour, localeI18N)
                              tourId = tour.id;
                              tourName = tour.name ? tour.name : tour.nameMain;
                         } else {
                              tourName = guideServiceDetailsApi.tourExternalName;
                         }
                         
                         const guideServiceDetails: GuideServiceDetails = new GuideServiceDetails(
                              guideServiceDetailsApi.info.guideServiceId,
                              tourId,
                              tourName,
                              new CompanyBillingDetailsView(guideServiceDetailsApi.guide.id, guideServiceDetailsApi.guide.businessName),
                              new CompanyBillingDetailsView(guideServiceDetailsApi.provider.id, guideServiceDetailsApi.provider.businessName),
                              guideServiceDetailsApi.info.people,
                              guideServiceDetailsApi.info.commissionPax,
                              guideServiceDetailsApi.info.price,
                              BillingUtils.getCommissionTypeFromApi(guideServiceDetailsApi.info.type),
                              CalendarUtils.fromFormattedDateToDDMMYYYY(guideServiceDetailsApi.info.day, ApiConstants.API_DATE_FORMAT_DD_MM_YYYY),
                              guideServiceDetailsApi.info.hour,
                              BillingUtils.getGuideServiceStateFromApi(guideServiceDetailsApi.info.state),
                              guideServiceDetailsApi.info.approveEnabled,
                              guideServiceDetailsApi.info.editEnabled,
                              guideServiceDetailsApi.info.deleteEnabled
                         );

                         guideServicesDetails.push(guideServiceDetails);

                    });

                    return new ChunkGeneric<GuideServiceDetails>(guideServicesDetails, new Pagination(response.total, response.currentPage, response.chunkSize));
               })
          )
     }




     findGuideServicesStats(month: number, year: number, providerId: number, guideId: number, localeI18N: string): Observable<GuideServiceStats> {

          return super.sendGetRequestWithRefresh(
               AdminGuideApiEndpointsUtils.getAdminFindGuideServicesStatsEndpoint(month, year, providerId, guideId),
               AdminGuideApiEndpointsUtils.getAdminFindGuideServicesStatsAcceptVersion()
          )
          .pipe(
               map((serviceGuideStatsApi: any) => {

                    const totalCommissionsToCollect = serviceGuideStatsApi.totalCommissionsToCollect;
                    const totalCommissionsToPay = serviceGuideStatsApi.totalCommissionsToPay;
                    const guideServiceToursStats = new Array<GuideServiceTourStats>();

                    serviceGuideStatsApi.toursDetailsStats.map((tourDetailsStatsApi: any) => {

                         let tourId: number = null;
                         let tourName: string = null;

                         if ( tourDetailsStatsApi.tour ) {
                              const tour: TourResume = TourUtils.getTourResumeFromApi(tourDetailsStatsApi.tour, localeI18N)
                              tourId = tour.id;
                              tourName = tour.name ? tour.name : tour.nameMain;
                         } else {
                              tourName = tourDetailsStatsApi.serviceGuideTourStats.tourName;
                         }
                         
                         const guideServiceTourStats: GuideServiceTourStats = new GuideServiceTourStats(
                              tourId,
                              tourName,
                              tourDetailsStatsApi.serviceGuideTourStats.people,
                              tourDetailsStatsApi.serviceGuideTourStats.commissions
                         );

                         guideServiceToursStats.push(guideServiceTourStats);

                    });

                    return new GuideServiceStats(guideServiceToursStats, totalCommissionsToCollect, totalCommissionsToPay);
               })
          )
     }





     findGuideService(guideServiceId: number, localeI18N: string): Observable<GuideServiceDetails> {

          return super.sendGetRequestWithRefresh(
               AdminGuideApiEndpointsUtils.getAdminFindGuideServiceEndpoint(guideServiceId),
               AdminGuideApiEndpointsUtils.getAdminFindGuideServiceEndpointAcceptVersion()
          )
          .pipe(
               map((guideServiceDetailsApi: any) => {


                    let tourId: number = null;
                    let tourName: string = null;

                    if ( guideServiceDetailsApi.tour ) {
                         const tour: TourResume = TourUtils.getTourResumeFromApi(guideServiceDetailsApi.tour, localeI18N)
                         tourId = tour.id;
                         tourName = tour.name ? tour.name : tour.nameMain;
                    } else {
                         tourName = guideServiceDetailsApi.tourExternalName;
                    }
                    
                    const guideServiceDetails: GuideServiceDetails = new GuideServiceDetails(
                         guideServiceDetailsApi.info.guideServiceId,
                         tourId,
                         tourName,
                         new CompanyBillingDetailsView(guideServiceDetailsApi.guide.id, guideServiceDetailsApi.guide.businessName),
                         new CompanyBillingDetailsView(guideServiceDetailsApi.provider.id, guideServiceDetailsApi.provider.businessName),
                         guideServiceDetailsApi.info.people,
                         guideServiceDetailsApi.info.commissionPax,
                         guideServiceDetailsApi.info.price,
                         BillingUtils.getCommissionTypeFromApi(guideServiceDetailsApi.info.type),
                         CalendarUtils.fromFormattedDateToDDMMYYYY(guideServiceDetailsApi.info.day, ApiConstants.API_DATE_FORMAT_DD_MM_YYYY),
                         guideServiceDetailsApi.info.hour,
                         BillingUtils.getGuideServiceStateFromApi(guideServiceDetailsApi.info.state),
                         guideServiceDetailsApi.info.approveEnabled,
                         guideServiceDetailsApi.info.editEnabled,
                         guideServiceDetailsApi.info.deleteEnabled
                    );

                    return guideServiceDetails;
                    

               })
          )
     }



     updateGuideServiceState(guideServiceId: number, state: GuideServiceState): Observable<boolean> {
          return super.sendPutRequestWithRefresh(
               AdminGuideApiEndpointsUtils.getUpdateGuideServiceStateEndpoint(guideServiceId), 
               AdminGuideApiEndpointsUtils.getUpdateGuideServiceStateObject(state),
               AdminGuideApiEndpointsUtils.getUpdateGuideServiceStateAcceptVersion()
          )
          .pipe(
               map(() => {
                    return true;
               })
          )
     }



     updateGuideService(guideServiceId: number, guideId: number, tourId: number, tourName: string, date: Date, hour: string, people: number, amount: number): Observable<boolean> {
          return super.sendPutRequestWithRefresh(
               AdminGuideApiEndpointsUtils.getAdminUpdateGuideServiceEndpoint(guideServiceId), 
               AdminGuideApiEndpointsUtils.getAdminCreateGuideServiceObject(guideId, tourId, tourName, date, hour, people, amount), 
               AdminGuideApiEndpointsUtils.getAdminUpdateGuideServiceAcceptVersion()
          ).pipe(
               map(() => {
                    return true;
               })
          )
     }
     


}