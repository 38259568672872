import { Component, OnInit, ViewChild } from '@angular/core';

import { ChunkGeneric } from 'src/app/domain/views/chunk-generic.view';
import { Pagination } from 'src/app/domain/views/pagination.view';
import { Country } from 'src/app/business/models/master/country/country.model';
import { City } from 'src/app/business/models/master/city/city.model';
import { TourState } from 'src/app/business/models/tour/common/tour-state.constants';
import { MessagePanelComponent } from 'src/app/ui/components/shared/message-panel/message-panel.component';
import { ServerErrorManager } from 'src/app/ui/components/utils/server-error-manager.utils';
import { marker as I18N } from '@biesbjerg/ngx-translate-extract-marker';
import { TranslateService } from '@ngx-translate/core';
import { GenericException } from 'src/app/domain/exceptions/generic.exception';
import { UserMasterInteractor } from 'src/app/domain/interactor/user/user-master.interactor';
import { AffiliateTourInteractor } from 'src/app/domain/interactor/affiliate/tour.interactor';
import { TourResumeAffiliate } from 'src/app/business/models/tour/tour-resume-affiliate.model';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { AffiliateCreateCustomLinkModalComponent } from './componets/create-custom-link/create-custom-link-modal.component';
import { AffiliateTourLinkModalComponent } from './componets/tour-link/tour-link-modal.component';
import { TourAffiliateLink } from 'src/app/business/models/tour/tour-affiliate-link.model';
import { NavigatorUtils } from 'src/app/ui/utils/navigator.utils';
import { SessionInteractor } from 'src/app/domain/interactor/session.interactor';
import { ProfileInteractor } from 'src/app/domain/interactor/profile/profile.interactor';
import { Affiliate } from 'src/app/business/models/affiliate/affiliate.model';


@Component({
     selector: 'app-affiliate-list-tours',
     templateUrl: './list.component.html'
})
export class AffiliateListToursComponent implements OnInit {

     @ViewChild(MessagePanelComponent)
     private messagePanelComponent: MessagePanelComponent;


     affiliate: Affiliate;

     countryId: number;
     cityId: number;
     keywords: string;
     pageNumber: number;

     tourStateActive: TourState = TourState.Active;

     currentCountrySelected: number;

     tours: Array<TourResumeAffiliate>;
     pagination: Pagination;

     countries: Array<Country>;
     cities: Array<City>;

     noResults: boolean;

     paginationNextLabel: string;
     paginationPreviousLabel: string;

     desktopVersion: boolean;
     initialWindowSize: number;

     constructor(
          private affiliateTourInteractor: AffiliateTourInteractor,
          private profileInteractor: ProfileInteractor,
          private userMasterInteractor: UserMasterInteractor,
          private translateService: TranslateService,
          private sessionInteractor: SessionInteractor,
          private modalService: NgbModal,
          private serverErrorManager: ServerErrorManager) {

               this.pagination = new Pagination(0, 1, 20);
               this.tours = new Array<TourResumeAffiliate>();
               this.pageNumber = this.pagination.currentPage;
               this.noResults = true;
               this.countryId = null;
               this.cityId = null;

               this.desktopVersion = true;

          }

          ngOnInit() {

               this.initialWindowSize = window.innerWidth;

               this.profileInteractor.getAffiliateDetails()
               .subscribe(
                    (response: Affiliate) => {

                         MessagePanelComponent.hideLoading();

                         this.affiliate = response;

                    },
                    (error: GenericException) => {

                         MessagePanelComponent.hideLoading();
                         this.serverErrorManager.manageErrorCode(error, this.messagePanelComponent);

                    }
               )


               this.findTours();

               this.userMasterInteractor.getCountriesByUser()
               .subscribe(
                    ( countries: Array<Country> ) => {
                         this.countries = countries;
                    },
                    (error: GenericException) => {
                         this.serverErrorManager.manageErrorCode(error, this.messagePanelComponent);

                    }
               )

               this.translateService.get(I18N('pagination.Siguiente')).subscribe(
                    (messageTranslated: string) => {
                         this.paginationNextLabel = messageTranslated;
                    }
               )

               this.translateService.get(I18N('pagination.Previous')).subscribe(
                    (messageTranslated: string) => {
                         this.paginationPreviousLabel = messageTranslated;
                    }
               )

          }


          findTours() {

               if ( this.messagePanelComponent ) {
                    this.messagePanelComponent.showLoading();
               }

               this.affiliateTourInteractor.findTours(this.keywords, this.countryId, this.cityId, this.pageNumber)
               .subscribe(
                    (chunkTours: ChunkGeneric<TourResumeAffiliate>) => {
                         this.pagination = new Pagination(chunkTours.pagination.totalItems, chunkTours.pagination.currentPage, chunkTours.pagination.itemsPerPage);
                         this.tours = chunkTours.items;
                         this.noResults = this.tours.length == 0;

                         MessagePanelComponent.hideLoading();
                    },
                    (error: GenericException) => {
                         MessagePanelComponent.hideLoading();
                         this.serverErrorManager.manageErrorCode(error, this.messagePanelComponent);
                    }
               )
          }

          onPageChanged( currentPage: number ) {
               this.pageNumber = currentPage;
               this.findTours();
          }


          onSearchKeywordChanged() {
               this.findTours();
          }


          onCountrySelected() {

               if ( this.countryId != this.currentCountrySelected ) {

                    this.currentCountrySelected = this.countryId;
                    this.cities = new Array<City>();
                    this.cityId = null;

                    if ( this.countryId ) {
                         this.userMasterInteractor.getCitiesByUser( this.countryId )
                         .subscribe(
                              (cities: Array<City>) => {
                                   this.cities = cities;
                              },
                              (error: GenericException) => {
                                   this.serverErrorManager.manageErrorCode(error, this.messagePanelComponent);
                              }
                         );
                    }

                    this.findTours();

               }
          }


          onCitySelected() {
               this.findTours();
          }


          onDesktopVersionUpdated(isDesktopVersion: boolean) {
               this.desktopVersion = isDesktopVersion;
          }


          onViewTourLink(affiliateLinks: Array<TourAffiliateLink>) {
               const modalComponent: NgbModalRef = this.modalService.open(AffiliateTourLinkModalComponent, {ariaLabelledBy: 'modal-basic-title', backdrop: 'static'});
               modalComponent.componentInstance.affiliateLinks = affiliateLinks;
          }

          onGenerateCustomLink() {
               this.modalService.open(AffiliateCreateCustomLinkModalComponent, {ariaLabelledBy: 'modal-basic-title', backdrop: 'static'});
          }

          onCreateBookingLink(tourId: number) {
               NavigatorUtils.goToWidgetAsExternalAffiliateIdentity(tourId, this.sessionInteractor.getCurrentLanguage(), this.affiliate?.id);
          }

     }
