<div class="animated fadeIn">
    <div class="row header-dashboard align-items-lg-center align-items-start">
        <div class="col-12 col-sm-8 col-lg-6 col-xl-6 pl-0 order-1 order-lg-1">
            <h2 class="font-weight-bold">{{ 'affiliatetourlist.Listado de tours' | translate }}</h2>
            <p>{{ 'affiliatetourlist.Estos son los tours disponibles' | translate }}</p>
        </div>
        <div class="col-12 col-sm-4 col-lg-6 col-xl-6 text-left text-sm-right px-0 px-lg-auto order-2 order-lg-3">
            <button (click)="onGenerateCustomLink()" class="btn btn-primary">
                <img class="mr-2" src="assets/img/ic_add-white.svg">
                <span>{{ 'affiliatetourlist.Enlace personalizado' | translate }}</span>
            </button>
        </div>
    </div>


    <div class="row dashboard-content-section mb-5 mb-md-auto">
        <div class="col-12">
            <div class="row">
                <div class="col-12 text-left pl-md-0 pr-md-3 px-0">
                    <h2 class="font-weight-bold mb-4">{{ 'affiliatetourlist.Filtros' | translate }}</h2>
                </div>
            </div>
            <div class="row">
                <form class="form-boxes d-block d-md-flex m-0 p-0">
                    <div class="col-12 col-md-4 my-auto pr-md-3 pl-md-0 px-0">
                        <div class="field pb-2">
                            <input (keyup.enter)="onSearchKeywordChanged()" [(ngModel)]="keywords" class="searcher-primary" type="search" placeholder="{{ 'affiliatetourlist.Busca tour por nombre o descripción' | translate }}" name="searcher">
                            <label for="search">{{ 'affiliatetourlist.Buscador' | translate }}</label>
                        </div>
                    </div>
                    <div class="col-12 col-md-4 pl-md-0 pr-md-3 px-0">
                        <div class="field pb-2">
                            <select [(ngModel)]="countryId" (change)="onCountrySelected()" name="country">
                                <option [ngValue]="null">{{ 'affiliatetourlist.Selecciona la opción' | translate }}</option>
                                <option *ngFor="let country of countries" value="{{ country.id }}">{{ country.name }}</option>
                            </select>
                            <label for="country">{{ 'affiliatetourlist.País' | translate }}</label>
                        </div>
                    </div>
                    <div class="col-12 col-md-4 px-0 px-md-auto">
                        <div class="field pb-2">
                            <select [(ngModel)]="cityId" (change)="onCitySelected()" name="city">
                                <option [ngValue]="null">{{ 'affiliatetourlist.Selecciona la opción' | translate }}</option>
                                <option *ngFor="let city of cities" value="{{ city.id }}">{{ city.name }}</option>
                            </select>
                            <label for="city">{{ 'affiliatetourlist.Ciudad' | translate }}</label>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>

    <div class="row empty-section" *ngIf="noResults">
        <div class="col-12 text-center my-auto">
            <img class="logo mb-4" src="assets/img/logo_opacity-21.png">
            <p>{{ 'affiliatetourlist.No se han encontrado resultados' | translate }}</p>
        </div>
    </div>

    <div class="row dashboard-content-section" *ngIf="!noResults">
        <div class="col-12 px-0">
             <app-affiliate-tours-table *ngIf="desktopVersion"
               [tours]="tours" [tourStateActive]="tourStateActive" [pagination]="pagination" (viewTourLink)="onViewTourLink($event)"
               (generateCustomLink)="onGenerateCustomLink()" (createBookingLink)="onCreateBookingLink($event)"></app-affiliate-tours-table>
             <app-affiliate-tours-cards *ngIf="!desktopVersion"
               [tours]="tours" [tourStateActive]="tourStateActive" [pagination]="pagination" (viewTourLink)="onViewTourLink($event)"
               (generateCustomLink)="onGenerateCustomLink()" (createBookingLink)="onCreateBookingLink($event)"></app-affiliate-tours-cards>

        </div>
    </div>


    <!-- Pagination -->

    <div class="row bg-grey-mid-color" *ngIf="!noResults">
        <div class="col-12 d-flex justify-content-center">
            <pagination-controls [maxSize]="pagination.itemsPerPage" [directionLinks]="true" [autoHide]="true" [responsive]="true" [previousLabel]="paginationPreviousLabel" [nextLabel]="paginationNextLabel" (pageChange)="onPageChanged($event)"></pagination-controls>
        </div>
    </div>


    <app-message-panel [preloading]="true"></app-message-panel>


    <app-window-size-controller [initialSize]="initialWindowSize" (isDesktopVersion)="onDesktopVersionUpdated($event)"></app-window-size-controller>


</div>