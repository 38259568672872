import { FormGroup } from '@angular/forms';

export class FormUtils {


  public static getFormValidStatus() {
      return 'VALID';
  }

  public static getFormInvalidStatus() {
      return 'INVALID';
  }

  public static disableAllControls(formGroup: FormGroup) {
      Object.keys(formGroup.controls).forEach(key => {
          formGroup.controls[key].disable()
      });
  }


  public static enableAllControls(formGroup: FormGroup) {
      Object.keys(formGroup.controls).forEach(key => {
          formGroup.controls[key].enable()
      });
  }

  public static emptyAllControls(formGroup: FormGroup) {
      Object.keys(formGroup.controls).forEach(key => {
          formGroup.controls[key].setValue('')
      });
  }


  public static setAllControlsToValue(formGroup: FormGroup, defaultValue: string) {
      Object.keys(formGroup.controls).forEach(key => {
          formGroup.controls[key].setValue(defaultValue)
      });
  }


}
