import { Pipe, PipeTransform } from '@angular/core';
import { ImageSize } from 'src/app/business/models/image/common/image-size.constants';

@Pipe({
  name: 'defaultPlaceholderImage'
})
export class DefaultPlaceholderImage implements PipeTransform {

  transform(url: string, size: ImageSize): String {

    let result = url;

    if ( !result ) {
        switch ( size ) {
          case ImageSize.Original || ImageSize.Big:
              return 'assets/img/placeholder-big.jpg';
          case ImageSize.Medium:
              return 'assets/img/placeholder-medium.jpg';
          case ImageSize.Small:
              return 'assets/img/placeholder-small.jpg';
          default:
              return 'assets/img/placeholder-big.jpg';
        }
    }

    return result ;

  }

}
