import { PaymentPlatform } from '../master/payment-platform/payment-platform.constants';
import { AffiliateState } from './common/affiliate-state.constants';

export class Affiliate {

  private _id: number;
  private _username: string;

  private _languageId: number;

  private _companyName: string;
  private _phone: string;

  private _countryId: number;
  private _countryName: string;

  private _cityId: number;
  private _cityName: string;

  private _webs: Array<string>;

  private _businessName: string;
  private _businessDocumentType: string;
  private _businessDocument: string;
  private _businessAddress: string;
  private _businessZeroVat: boolean;

  private _stripeAccountId: string;
  private _paymentPlatform: PaymentPlatform;
  private _paymentCurrency: string;
  private _iban: string;

  private _state: AffiliateState;

  private _canReceivePayments: boolean;
  private _canCreateStripeAccount: boolean;
  private _canGetStripeLink: boolean;
  private _canUpdateStripeAccount: boolean;
  private _canRemoveStripeAccount: boolean;

  constructor(
    id: number,

    username: string,

    languageId: number,

    companyName: string,
    phone: string,

    countryId: number,
    countryName: string,

    cityId: number,
    cityName: string,

    webs: Array<string>,

    businessName: string,
    businessDocumentType: string,
    businessDocument: string,
    businessAddress: string,
    businessZeroVat: boolean,

    stripeAccountId: string,
    paymentPlatform: PaymentPlatform,
    paymentCurrency: string,
    iban: string,

    state: AffiliateState,

    canReceivePayments: boolean,
    canCreateStripeAccount: boolean,
    canGetStripeLink: boolean,
    canUpdateStripeAccount: boolean,
    canRemoveStripeAccount: boolean,


  ) {

    this._id = id;

    this._username = username;

    this._languageId = languageId;

    this._companyName = companyName;
    this._phone = phone;

    this._countryId = countryId;
    this._countryName = countryName;

    this._cityId = cityId;
    this._cityName = cityName;

    this._webs = webs;

    this._businessName = businessName;
    this._businessDocumentType = businessDocumentType;
    this._businessDocument = businessDocument;
    this._businessAddress = businessAddress;
    this._businessZeroVat = businessZeroVat;

    this._stripeAccountId = stripeAccountId;
    this._paymentPlatform = paymentPlatform;
    this._paymentCurrency = paymentCurrency;
    this._iban = iban;

    this._state = state;

    this._canReceivePayments = canReceivePayments;
    this._canCreateStripeAccount = canCreateStripeAccount;
    this._canGetStripeLink = canGetStripeLink;
    this._canUpdateStripeAccount = canUpdateStripeAccount;
    this._canRemoveStripeAccount = canRemoveStripeAccount;

  }


  get id(): number {
    return this._id;
  }

  get username(): string {
    return this._username;
  }

  get languageId(): number {
    return this._languageId;
  }

  get companyName(): string {
    return this._companyName;
  }

  get phone(): string {
    return this._phone;
  }

  get countryId(): number {
    return this._countryId;
  }

  get countryName(): string {
    return this._countryName;
  }

  get cityId(): number {
    return this._cityId;
  }

  get cityName(): string {
    return this._cityName;
  }

  get webs(): Array<string> {
    return this._webs;
  }

  get businessName(): string {
    return this._businessName;
  }

  get businessDocumentType(): string {
    return this._businessDocumentType;
  }

  get businessDocument(): string {
    return this._businessDocument;
  }

  get businessAddress(): string {
    return this._businessAddress;
  }

  get businessZeroVat(): boolean {
    return this._businessZeroVat;
  }

  get stripeAccountId(): string {
    return this._stripeAccountId;
  }

  get paymentPlatform(): PaymentPlatform {
    return this._paymentPlatform;
  }

  get paymentCurrency(): string {
    return this._paymentCurrency;
  }

  get iban(): string {
    return this._iban;
  }

  get state(): AffiliateState {
    return this._state;
  }

  get canReceivePayments(): boolean {
    return this._canReceivePayments;
  }

  get canCreateStripeAccount(): boolean {
    return this._canCreateStripeAccount;
  }

  get canGetStripeLink(): boolean {
    return this._canGetStripeLink;
  }

  get canUpdateStripeAccount(): boolean {
    return this._canUpdateStripeAccount;
  }

  get canRemoveStripeAccount(): boolean {
    return this._canRemoveStripeAccount;
  }

}
