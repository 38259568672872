import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ChunkGeneric } from 'src/app/domain/views/chunk-generic.view';
import { Pagination } from 'src/app/domain/views/pagination.view';
import { BaseService } from '../base.service';
import { map } from 'rxjs/operators';
import { AffiliateResume } from '../../models/affiliate/affiliate-resume.model';
import { AffiliateUtils } from '../../models/affiliate/common/affiliate.utils';
import { Affiliate } from '../../models/affiliate/affiliate.model';
import { AdminAffiliateApiEndpointsUtils } from '../../api/endpoints/admin-affiliate-api-endponints.utils';
import { CompanyName } from '../../models/master/company/company-name.model';


@Injectable({
     providedIn: 'root'
})
export class AdminAffiliateService extends BaseService {


     createAffiliate(companyName: string, email: string, languageId: number, cityId: number): Observable<boolean> {
          return super.sendPostRequestWithRefresh(AdminAffiliateApiEndpointsUtils.getCreateAffiliateEndpoint(), AdminAffiliateApiEndpointsUtils.getCreateAffiliateObject(companyName, email, languageId, cityId), AdminAffiliateApiEndpointsUtils.getCreateAffiliateEndpointAcceptVersion())
          .pipe(
               map(() => {
                    return true;
               })
          )
     }


     findAffiliates(page: number): Observable<ChunkGeneric<AffiliateResume>> {

          return super.sendGetRequestWithRefresh(AdminAffiliateApiEndpointsUtils.getAffiliatesEndpoint(page), AdminAffiliateApiEndpointsUtils.getAffiliatesEndpointAcceptVersion())
          .pipe(
               map((response: any) => {

                    const pagination: Pagination = new Pagination(response.total, response.currentPage, response.chunkSize);
                    const affiliates: Array<AffiliateResume> = response.items.map((affiliateResumeApi: any) => {
                         return AffiliateUtils.getAffiliateResumeFromApi(affiliateResumeApi);
                    })

                    return new ChunkGeneric<AffiliateResume>(affiliates, pagination)

               })
          )

     }


     findAllAffiliates(): Observable<Array<AffiliateResume>> {

          return super.sendGetRequestWithRefresh(AdminAffiliateApiEndpointsUtils.getAllAffiliatesEndpoint(), AdminAffiliateApiEndpointsUtils.getAllAffiliatesEndpointAcceptVersion())
          .pipe(
               map((response: any) => {

                    const affiliates: Array<AffiliateResume> = response.map((affiliateResumeApi: any) => {
                         return AffiliateUtils.getAffiliateResumeFromApi(affiliateResumeApi);
                    })

                    return affiliates;

               })
          )

     }


     getAffiliatesNames(): Observable<Array<CompanyName>> {

          return super.sendGetRequestWithRefresh(AdminAffiliateApiEndpointsUtils.getAdminAffiliatesNamesEndpoint(), AdminAffiliateApiEndpointsUtils.getAdminAffiliatesNamesEndpointAcceptVersion())
          .pipe(
               map((response: any) => {

                    const affiliates: Array<CompanyName> = response.map((affiliate: any) => {
                         return new CompanyName(
                              affiliate.id,
                              affiliate.companyName
                         )
                    })

                    return affiliates;

               })
          )

     }



     getAffiliateDetalis(affiliateId: number): Observable<Affiliate> {

          return super.sendGetRequestWithRefresh(AdminAffiliateApiEndpointsUtils.getAffiliateDetailsEndpoint(affiliateId), AdminAffiliateApiEndpointsUtils.getAffiliateDetailsEndpointAcceptVersion())
          .pipe(
               map((response: any) => {

                    return AffiliateUtils.getAffiliateFromApi(response);

               })
          )

     }



     resendWelcomeEmail(affiliateId: number): Observable<boolean> {

          return super.sendPostRequestWithRefresh(AdminAffiliateApiEndpointsUtils.getResendAffiliateWelcomeEndpoint(affiliateId), {}, AdminAffiliateApiEndpointsUtils.getResendAffiliateWelcomeEndpointAcceptVersion())
          .pipe(
               map(() => {
                    return true
               })
          )

     }


     updateAffiliateDetails(affiliateId: number, companyName: string, websites: Array<string>, phone: string, country: number, city: number, address: string, businessDocumentType: string, businessDocument: string, businessZeroVat: boolean): Observable<boolean> {
          return super.sendPutRequestWithRefresh(AdminAffiliateApiEndpointsUtils.getUpdateAffiliateDetailsEndpoint(affiliateId), AdminAffiliateApiEndpointsUtils.getUpdateAffiliateDetailsObject(companyName, websites, phone, country, city, address, businessDocumentType, businessDocument, businessZeroVat), AdminAffiliateApiEndpointsUtils.getUpdateAffiliateDetailsEndpointAcceptVersion())
          .pipe(
               map(() => {
                    return true;
               })
          )


     }




     confirmAffiliate(affiliateId: number): Observable<boolean> {
          return super.sendPostRequestWithRefresh(AdminAffiliateApiEndpointsUtils.getConfirmAffiliateSingupEndpoint(affiliateId), {}, AdminAffiliateApiEndpointsUtils.getConfirmAffiliateSingupEndpointAcceptVersion())
          .pipe(
               map(() => {
                    return true;
               })
          )
     }


     rejectAffiliate(affiliateId: number, message: string): Observable<boolean> {
          return super.sendPostRequestWithRefresh(AdminAffiliateApiEndpointsUtils.getRejectAffiliateSingupEndpoint(affiliateId), { 'message': message }, AdminAffiliateApiEndpointsUtils.getRejectAffiliateSingupEndpointAcceptVersion())
          .pipe(
               map(() => {
                    return true;
               })
          )
     }


     unregisterAffiliate(affiliateId: number): Observable<boolean> {
          return super.sendPostRequestWithRefresh(AdminAffiliateApiEndpointsUtils.getUnregisterAffiliateEndpoint(affiliateId), {}, AdminAffiliateApiEndpointsUtils.getUnregisterAffiliateEndpointAcceptVersion())
          .pipe(
               map(() => {
                    return true;
               })
          )
     }

     registerAffiliate(affiliateId: number): Observable<boolean> {
          return super.sendPostRequestWithRefresh(AdminAffiliateApiEndpointsUtils.getRegisterAffiliateEndpoint(affiliateId), {}, AdminAffiliateApiEndpointsUtils.getRegisterAffiliateEndpointAcceptVersion())
          .pipe(
               map(() => {
                    return true;
               })
          )
     }


     createStripeAccount(affiliateId: number): Observable<string> {
          return super.sendPostRequestWithRefresh(AdminAffiliateApiEndpointsUtils.getCreateAffiliateStripeAccountEndpoint(affiliateId), {}, AdminAffiliateApiEndpointsUtils.getCreateAffiliateStripeAccountEndpointAcceptVersion())
          .pipe(
               map((response: any) => {
                    return response.stripeAccountUrl;
               })
          )
     }


     removeStripeAccount(affiliateId: number): Observable<boolean> {
          return super.sendDeleteRequestWithRefresh(AdminAffiliateApiEndpointsUtils.getRemoveAffiliateStripeAccountEndpoint(affiliateId), AdminAffiliateApiEndpointsUtils.getRemoveAffiliateStripeAccountEndpointAcceptVersion())
          .pipe(
               map(() => {
                    return true;
               })
          )
     }


     updateStripeAccount(affiliateId: number, stripeAccountId: string): Observable<boolean> {
          return super.sendPutRequestWithRefresh(AdminAffiliateApiEndpointsUtils.getUpdateAffiliateStripeAccountEndpoint(affiliateId), AdminAffiliateApiEndpointsUtils.getUpdateAffiliateStripeAccountObject(stripeAccountId), AdminAffiliateApiEndpointsUtils.getUpdateAffiliateStripeAccountEndpointAcceptVersion())
          .pipe(
               map(() => {
                    return true;
               })
          )
     }

}
