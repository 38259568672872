<div class="animated fadeIn">
    <div class="row header-dashboard align-items-lg-center align-items-start">
        <div class="col-12 col-sm-8 col-lg-6 col-xl-6 pl-0 order-1 order-lg-1">
            <h2 class="font-weight-bold">{{ 'billinglist.Desglose de reservas' | translate }}</h2>
            <p>{{ 'billinglist.Facturación detallada por reserva' | translate }}</p>
        </div>
    </div>

    <div class="row dashboard-content-section mb-5">
        <div class="col-12">
            <div class="row">
                <div class="col-12 text-left pl-md-0 pr-md-3 px-0">
                    <h2 class="font-weight-bold mb-4">{{ 'billinglist.Filtros' | translate }}</h2>
                </div>
            </div>
            <div class="row">
                <form class="form-boxes d-block d-md-flex m-0 p-0">
                    <div class="col-12 col-md-3 pl-md-0 pr-md-3 px-0">
                        <div class="field pb-2">
                            <div class="form-inline daterange">
                                <div class="form-group hidden">
                                    <div class="input-group">
                                        <input name="datepicker" class="form-control" ngbDatepicker #datepicker="ngbDatepicker" [autoClose]="'outside'" (dateSelect)="onDateSelection($event)" [displayMonths]="calendarDisplayMonths" [dayTemplate]="t" outsideDays="hidden" [startDate]="fromDate">
                                        <ng-template #t let-date let-focused="focused">
                                            <span class="custom-day" [class.focused]="focused" [class.range]="isRange(date)" [class.faded]="isHovered(date) || isInside(date)" (mouseenter)="hoveredDate = date" (mouseleave)="hoveredDate = null">
                                            {{ date.day }}
                                            </span>
                                        </ng-template>
                                    </div>
                                </div>
                                <div class="form-group mb-1 mb-lg-auto w-100">
                                    <div class="input-group w-100">
                                        <input #dpFromDate class="form-control" placeholder="dd-mm-yyyy" name="dpFromDate" [value]="formatter.format(fromDate)" (input)="fromDate = validateInput(fromDate, dpFromDate.value)" disabled="disabled">
                                        <input #dpToDate class="form-control" placeholder="dd-mm-yyyy" name="dpToDate" [value]="formatter.format(toDate)" (input)="toDate = validateInput(toDate, dpToDate.value)" disabled="disabled">
                                        <div class="input-group-append">
                                            <button class="btn btn-outline-secondary calendar-open" (click)="datepicker.toggle()" type="button">
                                                <img class="ic-sort-by-order mb-1" src="assets/img/ic_calendar.svg">
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <label for="date">{{ 'billinglist.Date' | translate }}</label>
                        </div>


                    </div>


                    <div *ngIf="isAdmin && companyNames" class="col-12 col-md-3 pl-md-0 pr-md-3 px-0">
                        <div class="field pb-2">
                            <select [(ngModel)]="companyId" (change)="onCompanySelected()" name="companyNames">
                                <option [ngValue]="null">{{ 'billinglist.Selecciona la opción' | translate }}</option>
                                <option *ngFor="let companyName of companyNames" value="{{ companyName.id }}">{{ companyName.companyName }}</option>
                            </select>
                            <label for="city">{{ 'billinglist.Empresa' | translate }}</label>
                        </div>
                    </div>


                    <div *ngIf="(isAdmin && isGuideBilling) || (isProvider && isGuideBilling && guideNames)" class="col-12 col-md-3 pl-md-0 pr-md-3 px-0">
                        <div class="field pb-2">
                            <select [(ngModel)]="guideId" (change)="onGuideSelected()" name="guideNames">
                                <option [ngValue]="null">{{ 'billinglist.Selecciona la opción' | translate }}</option>
                                <option *ngFor="let guideName of guideNames" value="{{ guideName.id }}">{{ guideName.companyName }}</option>
                            </select>
                            <label for="city">{{ 'billinglist.Guide' | translate }}</label>
                        </div>
                    </div>

                    <div class="col-12 col-md-3 pl-md-0 pr-md-3 px-0">
                        <div class="field pb-2">
                            <input (keyup.enter)="onSearchKeywordChanged()" [(ngModel)]="keywords" class="searcher-primary" type="search" placeholder="{{ 'billinglist.Email, nombre, teléfono o ID de reserva' | translate }}" name="searcher">
                            <label for="search">{{ 'billinglist.Buscador' | translate }}</label>
                        </div>
                    </div>

                </form>
            </div>
        </div>
    </div>
    <div class="row empty-section" *ngIf="noResults">
        <div class="col-12 text-center my-auto">
            <img class="logo mb-4" src="assets/img/logo_opacity-21.png">
            <p>{{ 'billinglist.No se han encontrado resultados' | translate }}</p>
        </div>
    </div>


    <div class="row dashboard-content-section" *ngIf="!noResults && guideBillingStats && isGuideBilling">
        <div class="col-12">
            <div class="row">
              <div class="col-12 text-left pl-md-0 pr-md-3 px-0">
                <h2 class="font-weight-bold mb-4">{{ 'billinglist.Resumen' | translate }}</h2>
              </div>
              <table class="table table-hover table-dark mb-3">
                <thead class="thead-dark">
                  <tr>
                    <th width="60%" scope="col">{{ 'billinglist.Tour' | translate }}</th>
                    <th width="15%" scope="col" class="text-center">{{ 'billinglist.Asistentes' | translate }}</th>
                    <th width="15%" scope="col" class="price-width text-center">{{ 'billinglist.Total' | translate }}</th>
                  </tr>
                </thead>
                <tbody class="">
                  <tr *ngFor="let toursDetailsStats of guideBillingStats.toursDetailsStats">
                    <td scope="row" class="font-weight-bold">{{ toursDetailsStats.tourName }}</td>
                    <td class="text-center">{{ toursDetailsStats.people }}</td>
                    <td class="price-width text-center" style="white-space: nowrap;">{{ toursDetailsStats.commissions }} €</td>
                  </tr>
                </tbody>
              </table>
              <table class="table table-hover table-dark">
                <tbody class="total">
                  <tr>
                    <td width="85%" scope="row" colspan="2" class="font-weight-bold text-right pr-5 border-0">{{ 'billinglist.Total' | translate }}</td>
                    <td width="15%" class="price-width text-center border-0" style="white-space: nowrap;">{{ guideBillingStats.totalCommissions }} €</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
    </div>

    <div class="row dashboard-content-section" *ngIf="!noResults">
        <div class="col-12 px-0">
            <app-billing-table *ngIf="desktopVersion" [bookingBillings]="bookingBillings" [isAffiliateBilling]="isAffiliateBilling" [isGuideBilling]="isGuideBilling" [isProvider]="isProvider" [isAdmin]="isAdmin" [pagination]="pagination"></app-billing-table>
            <app-billing-cards *ngIf="!desktopVersion" [bookingBillings]="bookingBillings" [isAffiliateBilling]="isAffiliateBilling" [isGuideBilling]="isGuideBilling" [isProvider]="isProvider" [isAdmin]="isAdmin" [pagination]="pagination"></app-billing-cards>
        </div>
    </div>

    <!--  Pagination -->
    <div class="row bg-grey-mid-color" *ngIf="!noResults">
        <div class="col-12 d-flex justify-content-center">
            <pagination-controls [maxSize]="pagination.itemsPerPage" [directionLinks]="true" [autoHide]="true" [responsive]="true" [previousLabel]="paginationPreviousLabel" [nextLabel]="paginationNextLabel" (pageChange)="onPageChanged($event)"></pagination-controls>
        </div>
    </div>

</div>


<app-window-size-controller [initialSize]="initialWindowSize" (isDesktopVersion)="onDesktopVersionUpdated($event)"></app-window-size-controller>