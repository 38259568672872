import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { BookEvent } from 'src/app/business/models/book/book-event.model';
import { CalendarUtils } from 'src/app/ui/utils/calendar.utils';
import { GenericException } from 'src/app/domain/exceptions/generic.exception';
import { ChunkGeneric } from 'src/app/domain/views/chunk-generic.view';
import { Pagination } from 'src/app/domain/views/pagination.view';
import { NavigationConstants } from 'src/app/ui/commons/navigation.constants';
import { MessagePanelComponent } from 'src/app/ui/components/shared/message-panel/message-panel.component';
import { ServerErrorManager } from 'src/app/ui/components/utils/server-error-manager.utils';
import { GuideBookInteractor } from 'src/app/domain/interactor/guide/book.interactor';
import { NavigatorUtils } from 'src/app/ui/utils/navigator.utils';
import { SessionInteractor } from 'src/app/domain/interactor/session.interactor';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GuideBookingsToursResellerComponent } from 'src/app/ui/components/shared/booking/tours-reseller/tours-reseller.component';



@Component({
     selector: 'app-guide-list-booking',
     templateUrl: './list.component.html'
})
export class GuideListBookingComponent implements OnInit {

     @ViewChild(MessagePanelComponent)
     private messagePanelComponent: MessagePanelComponent;

     pagination: Pagination;
     pageNumber: number;

     isToday: boolean;

     bookEvents: Array<BookEvent>;

     noResults: boolean;

     constructor(
          private router: Router,
          private guideBookInteractor: GuideBookInteractor,
          private sessionInteractor: SessionInteractor,
          private modalService: NgbModal,
          private serverErrorManager: ServerErrorManager
     ) {

          this.pagination = new Pagination(0, 1, 20);
          this.pageNumber = this.pagination.currentPage;
          this.isToday = true;

          this.noResults = true;
     }

     ngOnInit() {
          this.findBookEvents();
     }


     findBookEvents() {

          if (this.messagePanelComponent) {
               this.messagePanelComponent.showLoading();
          }

          let dateSelected = new Date();
          if ( !this.isToday ) {
               dateSelected = CalendarUtils.getDateWithExtraDays(dateSelected, 1);
          }
          const dateAsString: string = CalendarUtils.fromDateToDaleLocalizedDDMMYYYY(dateSelected);

          this.guideBookInteractor.findBookEvents(dateAsString, this.pageNumber)
          .subscribe(
               (chunkTours: ChunkGeneric<BookEvent>) => {
                    this.pagination = new Pagination(chunkTours.pagination.totalItems, chunkTours.pagination.currentPage, chunkTours.pagination.itemsPerPage);
                    this.bookEvents = chunkTours.items;
                    this.noResults = this.bookEvents.length == 0;

                    MessagePanelComponent.hideLoading();
               },
               (error: GenericException) => {
                    MessagePanelComponent.hideLoading();
                    this.serverErrorManager.manageErrorCode(error, this.messagePanelComponent);
               }
          )
     }

     onPageNumberSelected(pageNumber: number) {
          this.pageNumber = pageNumber;
          this.findBookEvents();
     }

     onViewBookDetails(bookEvent: BookEvent) {

          this.router.navigateByUrl(NavigationConstants.redirectUrl(NavigationConstants.getNavigationGuideDetailBooking(bookEvent.event.tourId, bookEvent.event.day, bookEvent.event.hour)));

     }

     onShowToursForToday(isToday: boolean) {
          if ( isToday != this.isToday ) {
               this.isToday = isToday;
               this.pageNumber = 1;
               this.findBookEvents();
          }
     }

     onNewBooking(bookEvent: BookEvent) {
          NavigatorUtils.goToWidgetAsExternalGuideIdentity(bookEvent.event.tourId, this.sessionInteractor.getCurrentLanguage(), bookEvent.event.day, bookEvent.event.hour, this.sessionInteractor.loggedInUserDetails().id);
     }

     onNewBookingForNewTour() {
          this.modalService.open(GuideBookingsToursResellerComponent, {ariaLabelledBy: 'modal-basic-title'});
     }

}