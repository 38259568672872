import { Component, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { NavigationConstants } from 'src/app/ui/commons/navigation.constants';
import { SessionInteractor } from 'src/app/domain/interactor/session.interactor';

@Component({
     selector: 'app-header',
     templateUrl: './header.component.html'
})
export class HeaderComponent {

     public static NAVBAR_ATTRIBUTE_HTML_ID: string = 'navbarDropdown';

     @HostListener('document:click', ['$event'])
     documentClick(event: any) {
         if ( event.target.id != HeaderComponent.NAVBAR_ATTRIBUTE_HTML_ID
              && !event.target.closest('#' + HeaderComponent.NAVBAR_ATTRIBUTE_HTML_ID)
         ) {
              this.showProfileMenu = false;
         }
     }

     name: string;
     email: string;
     isAdminRole: boolean;
     isProviderRole: boolean;
     isAffiliateRole: boolean;
     isGuideRole: boolean;
     showProfileMenu: boolean;

     constructor( private sessionInteractor: SessionInteractor, private router: Router) {

          this.email = this.sessionInteractor.loggedInUserDetails().email;
          this.name = this.sessionInteractor.loggedInUserDetails().name;
          this.isAdminRole = this.sessionInteractor.isAdminRole();
          this.isProviderRole = this.sessionInteractor.isProviderRole();
          this.isAffiliateRole = this.sessionInteractor.isAffiliateRole();
          this.isGuideRole = this.sessionInteractor.isGuideRole();

          this.showProfileMenu = false;

     }

     goToProfile() {
          if ( this.isAdminRole ) {
               this.router.navigate([ NavigationConstants.getNavigationAdminProfile() ]);
          } else if ( this.isProviderRole ) {
               this.router.navigate([ NavigationConstants.getNavigationProviderProfile() ]);
          } else if ( this.isAffiliateRole ) {
               this.router.navigate([ NavigationConstants.getNavigationAffiliateProfile() ]);
          } else if ( this.isGuideRole ) {
               this.router.navigate([ NavigationConstants.getNavigationGuideProfile() ]);
          }
     }

     goToDashboard() {
          this.router.navigate([ NavigationConstants.getNavigationUserZone() ]);
     }

     onProfileMenu() {
          this.showProfileMenu = !this.showProfileMenu;
     }

     logout() {
          this.sessionInteractor.logout();
          this.router.navigate([NavigationConstants.NAVIGATION_LOGIN]);
     }

}
