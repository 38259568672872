import { Component, OnInit, ViewChild } from '@angular/core';
import { marker as I18N } from '@biesbjerg/ngx-translate-extract-marker';
import { ActivatedRoute, Router } from '@angular/router';
import { NavigationConstants } from 'src/app/ui/commons/navigation.constants';
import { ServerErrorManager } from 'src/app/ui/components/utils/server-error-manager.utils';
import { MessagePanelComponent } from 'src/app/ui/components/shared/message-panel/message-panel.component';
import { EventDetails } from 'src/app/business/models/book/event-details.model';
import { BookFareEditionView } from 'src/app/domain/views/book/book-fare-tuple.view';
import { Book } from 'src/app/business/models/book/book.model';
import { BookFare } from 'src/app/business/models/book/book-fare.model';
import { ServerErrorExceptionCase } from 'src/app/domain/views/server-error-manager-exception-case.view';
import { AppConstants } from 'src/app/domain/common/app.constants';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { GenericException } from 'src/app/domain/exceptions/generic.exception';
import { TranslateService } from '@ngx-translate/core';
import { GuideBookInteractor } from 'src/app/domain/interactor/guide/book.interactor';
import { SessionInteractor } from 'src/app/domain/interactor/session.interactor';
import { NavigatorUtils } from 'src/app/ui/utils/navigator.utils';
import { BookConfirmEditionView } from 'src/app/domain/views/book/book-confirm-edition.view';
import { BookEvent } from 'src/app/business/models/book/book-event.model';
import { GuideBookingsToursResellerComponent } from 'src/app/ui/components/shared/booking/tours-reseller/tours-reseller.component';


@Component({
     selector: 'app-guide-detail-booking',
     templateUrl: './detail.component.html'
})
export class GuideDetailBookingComponent implements OnInit {


     @ViewChild(MessagePanelComponent)
     private messagePanelComponent: MessagePanelComponent;

     desktopVersion: boolean
     initialWindowSize: number

     tourId: number;
     date: string;
     hour: string;
     keywords: string;

     eventDetails: EventDetails;
     bookEvent: BookEvent;

     constructor(
          private activatedRoute: ActivatedRoute,
          private guideBookInteractor: GuideBookInteractor,
          private serverErrorManager: ServerErrorManager,
          private sessionInteractor: SessionInteractor,
          private modalService: NgbModal,
          private router: Router,
          private translateService: TranslateService
     ) {

          this.desktopVersion = true;

          this.activatedRoute.queryParams.subscribe( queryParams => {

               this.date = queryParams[NavigationConstants.NAVIGATION_ADMIN_BOOK_EVENT_DATE];
               this.hour = queryParams[NavigationConstants.NAVIGATION_ADMIN_BOOK_EVENT_HOUR];

               this.activatedRoute.params.subscribe( params => {
                    this.tourId = params[ NavigationConstants.NAVIGATION_ADMIN_BOOK_EVENT_TOUR_ID ];

                    this.findEventDetails();

               });

          });

     }

     ngOnInit() {
          this.initialWindowSize = window.innerWidth;
          this.findBookEvent();
     }

     onDesktopVersionUpdated( isDesktopVersion: boolean ) {
          this.desktopVersion = isDesktopVersion;
     }


     findBookEvent() {
          this.guideBookInteractor.findBookEvent(this.tourId, this.date, this.hour)
          .subscribe(
               (bookEvent: BookEvent) => {
                    this.bookEvent = bookEvent;
                    MessagePanelComponent.hideLoading();
               },
               (error: GenericException) => {
                    this.serverErrorManager.manageErrorCode(error, this.messagePanelComponent);
               }
          )
     }


     findEventDetails() {

          if ( this.messagePanelComponent ) {
               this.messagePanelComponent.showLoading();
          }

          this.guideBookInteractor.findEventDetails(this.tourId, this.date, this.hour, this.keywords)
          .subscribe(
               (eventDetails: EventDetails) => {
                    this.eventDetails = eventDetails;
                    MessagePanelComponent.hideLoading();
               },
               (error: GenericException) => {
                    this.serverErrorManager.manageErrorCode(error, this.messagePanelComponent);
               }
          )

     }


     onKeywordsUpdated() {
          this.findEventDetails();
     }


     onNewBooking(book: Book) {
          const modalComponent: NgbModalRef = this.modalService.open(GuideBookingsToursResellerComponent, {ariaLabelledBy: 'modal-basic-title'});
          modalComponent.componentInstance.book = book;
     }

     onNewBookingForNewTour() {
          this.modalService.open(GuideBookingsToursResellerComponent, {ariaLabelledBy: 'modal-basic-title'});
     }

     onNewBookingForEvent() {
          NavigatorUtils.goToWidgetAsExternalGuideIdentity(this.bookEvent.event.tourId, this.sessionInteractor.getCurrentLanguage(), this.bookEvent.event.day, this.bookEvent.event.hour, this.sessionInteractor.loggedInUserDetails().id);
     }


     onUpdateEventBookAllFareShows(bookConfirmEditionView: BookConfirmEditionView) {

          this.messagePanelComponent.showLoading();

          this.guideBookInteractor.updateEventBookAllFareShows(this.tourId, this.date, this.hour, bookConfirmEditionView.bookId, bookConfirmEditionView.bookingGuideConfirmationState)
          .subscribe(
               () => {

                    this.messagePanelComponent.showSuccessMessage(I18N('detailbooking.Información actualizada'), () => {
                         this.findEventDetails();
                         this.findBookEvent();
                    });

               },
               (error: GenericException) => {

                    MessagePanelComponent.hideLoading();
                    this.serverErrorManager.manageErrorCode(error, this.messagePanelComponent);

               }
          )
     }



     onDecreaseShows(bookFareEdition: BookFareEditionView) {
          if ( bookFareEdition.shows > 0 ) {
               this.messagePanelComponent.showLoading();

               const shows = bookFareEdition.shows - 1;
               const updatedShowsValueDiff: number = bookFareEdition.shows - shows;

               const bookFareEdited: BookFareEditionView = new BookFareEditionView(
                    bookFareEdition.tourId,
                    bookFareEdition.date,
                    bookFareEdition.hour,
                    bookFareEdition.bookId,
                    bookFareEdition.fareId,
                    bookFareEdition.isChildren,
                    bookFareEdition.isAdult,
                    bookFareEdition.people,
                    shows
               );

               this.updateEventBookFareShows(bookFareEdited, updatedShowsValueDiff, false);

          }
     }

     onIncreaseShows(bookFareEdition: BookFareEditionView) {

          this.messagePanelComponent.showLoading();

          const shows = bookFareEdition.shows + 1;
          const updatedShowsValueDiff: number = bookFareEdition.shows - shows;

          const bookFareEdited: BookFareEditionView = new BookFareEditionView(
               bookFareEdition.tourId,
               bookFareEdition.date,
               bookFareEdition.hour,
               bookFareEdition.bookId,
               bookFareEdition.fareId,
               bookFareEdition.isChildren,
               bookFareEdition.isAdult,
               bookFareEdition.people,
               shows
          );

          this.updateEventBookFareShows(bookFareEdited, updatedShowsValueDiff, false);

     }



     onUpdateShows(bookFareEdition: BookFareEditionView) {

          const currentShows = bookFareEdition.shows;

          this.translateService.get(I18N('detailbooking.Por favor, indica un número válido')).subscribe(
               (messageTranslated: string) => {

                    const validateEmptyLabel = messageTranslated;

                    this.translateService.get(I18N('detailbooking.Por favor, indica un número válido de asistentes')).subscribe(
                         (messageTranslated: string) => {

                              const validateZeroLabel = messageTranslated;

                              this.messagePanelComponent.showInputMessage(
                                   I18N('detailbooking.Edición de asistencia'),
                                   I18N('detailbooking.Confirma el número de asistentes al tour'),
                                   MessagePanelComponent.MESSAGE_INPUT_TYPE_NUMBER,
                                   currentShows.toString(),
                                   (value: string) => {
                                        if ( !value ) {
                                             return validateEmptyLabel;
                                        } else {
                                             const shows = parseInt(value);
                                             if ( shows < 0 ) {
                                                  return validateZeroLabel;
                                             }
                                        }
                                   },
                                   (value: string) => {
                                        const shows = parseInt(value);
                                        const updatedShowsValueDiff: number = bookFareEdition.shows - shows;

                                        const bookFareEdited: BookFareEditionView = new BookFareEditionView(
                                             bookFareEdition.tourId,
                                             bookFareEdition.date,
                                             bookFareEdition.hour,
                                             bookFareEdition.bookId,
                                             bookFareEdition.fareId,
                                             bookFareEdition.isChildren,
                                             bookFareEdition.isAdult,
                                             bookFareEdition.people,
                                             shows
                                        );

                                        this.updateEventBookFareShows(bookFareEdited, updatedShowsValueDiff, true);

                                   }
                              )
                         }
                    )
               }
          )


     }



     updateEventBookFareShows(bookFareEdited: BookFareEditionView, updatedShowsValueDiff: number, showSuccessMessage: boolean) {
          this.messagePanelComponent.showLoading();
          this.guideBookInteractor.updateEventBookFareShows(bookFareEdited)
          .subscribe(
               () => {

                    MessagePanelComponent.hideLoading();

                    this.eventDetails.bookings.map( (book: Book) => {
                         if ( book.id == bookFareEdited.bookId ) {
                              book.fares.map( (bookFare: BookFare ) => {

                                   if (
                                        ( bookFare.isAdult == bookFareEdited.isAdult && bookFareEdited.isAdult == true )
                                        || ( bookFare.isChildren == bookFareEdited.isChildren && bookFareEdited.isChildren == true )
                                        || (
                                             ( bookFare.isChildren == bookFareEdited.isChildren && bookFareEdited.isChildren == false )
                                             && ( bookFare.isAdult == bookFareEdited.isAdult && bookFareEdited.isAdult == false )
                                             && ( bookFare.id == bookFareEdited.fareId )
                                        )
                                   ) {
                                        bookFare.shows = bookFare.shows - updatedShowsValueDiff;
                                        book.shows = book.shows - updatedShowsValueDiff;
                                        this.eventDetails.shows = this.eventDetails.shows - updatedShowsValueDiff;
                                   }
                              });

                         }
                    });

                    this.findBookEvent();

                    if ( showSuccessMessage ) {
                         this.messagePanelComponent.showSuccessMessage(I18N('detailbooking.Información actualizada'));
                    }

               },
               (error: GenericException) => {

                    MessagePanelComponent.hideLoading();

                    const exceptionCases = new Array<ServerErrorExceptionCase>();

                    const exceptionCodes = new Array<number>();
                    exceptionCodes.push(AppConstants.ERROR_CLIENT_BAD_REQUEST);
                    exceptionCases.push( new ServerErrorExceptionCase(
                         exceptionCodes,
                         new Array<string>(),
                         () => {
                              this.messagePanelComponent.showErrorMessage(I18N('detailbooking.El período de edición de asistencia ha finalizado.'))
                         }
                    ));

                    this.serverErrorManager.manageErrorCode(error, this.messagePanelComponent, exceptionCases);

               }
          )
     }


     onGoBack() {
          this.router.navigate([NavigationConstants.getNavigationGuideListBookings()]);
     }

}
