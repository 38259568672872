import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NavigationConstants } from 'src/app/ui/commons/navigation.constants';
import { MessagePanelComponent } from 'src/app/ui/components/shared/message-panel/message-panel.component';
import { marker as I18N } from '@biesbjerg/ngx-translate-extract-marker';
import { ServerErrorManager } from 'src/app/ui/components/utils/server-error-manager.utils';
import { GenericException } from 'src/app/domain/exceptions/generic.exception';
import { Guide } from 'src/app/business/models/guide/guide.model';
import { ProviderGuideInteractor } from 'src/app/domain/interactor/provider/guide.interactor';
import { GuideState } from 'src/app/business/models/guide/common/guide-state.constants';
import { GuideDetailsView } from 'src/app/domain/views/guide/guide-details.view';

@Component({
  selector: 'app-detail-guide',
  templateUrl: './detail.component.html'
})
export class DetailGuideComponent implements OnInit {

  @ViewChild(MessagePanelComponent)
  private messagePanelComponent: MessagePanelComponent;

  guide: Guide;
  isStatePending: boolean;

  constructor(
    private providerGuideInteractor: ProviderGuideInteractor,
    private activatedRoute: ActivatedRoute,
    private serverErrorManager: ServerErrorManager) {


         this.activatedRoute.params.subscribe(params => {
           const guideId = params[NavigationConstants.NAVIGATION_PROVIDER_GUIDE_DETAIL_ID]
           this.getGuideDetails(guideId)
         });

  }


  ngOnInit(): void {

  }



  getGuideDetails(guideId: number) {

    this.providerGuideInteractor.getGuideDetails(guideId)
      .subscribe(
        (guide: Guide) => {
          this.guide = guide;

          MessagePanelComponent.hideLoading();

          this.isStatePending = this.guide.state == GuideState.Inactive;

        },
        (error: GenericException) => {

          MessagePanelComponent.hideLoading();
          this.serverErrorManager.manageErrorCode(error, this.messagePanelComponent);

        }
      )
  }

  doUnregisterGuide() {

    this.messagePanelComponent.showLoading();
    this.providerGuideInteractor.unregisterGuide(this.guide.id)
      .subscribe(
        () => {
          MessagePanelComponent.hideLoading();
          this.messagePanelComponent.showSuccessMessage(I18N('guidedetails.Guía dado de baja y notificado por correo.'), () => this.getGuideDetails(this.guide.id));

        }, (error: GenericException) => {

          MessagePanelComponent.hideLoading();
          this.serverErrorManager.manageErrorCode(error, this.messagePanelComponent);
        }
      )
  }


  doRegisterGuide() {
    this.messagePanelComponent.showLoading();
    this.providerGuideInteractor.registerGuide(this.guide.id)
      .subscribe(
        () => {
          MessagePanelComponent.hideLoading();
          this.messagePanelComponent.showSuccessMessage(I18N('guidedetails.Guía dado de alta correctamente.'), () => this.getGuideDetails(this.guide.id));

        }, (error: GenericException) => {

          MessagePanelComponent.hideLoading();
          this.serverErrorManager.manageErrorCode(error, this.messagePanelComponent);
        }
      )
  }


  onGuideDetailsSubmit(guideDetails: GuideDetailsView) {

       console.log(guideDetails.businessZeroVat);

    this.messagePanelComponent.showLoading();
    this.providerGuideInteractor.updateGuideDetails(guideDetails)
      .subscribe(
        () => {
          MessagePanelComponent.hideLoading();
          this.messagePanelComponent.showSuccessMessage(I18N('guidedetails.Datos actualizados'));

        }, (error: GenericException) => {

          MessagePanelComponent.hideLoading();
          this.serverErrorManager.manageErrorCode(error, this.messagePanelComponent);
        }
      )
  }



}
