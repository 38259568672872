<div *ngIf="exceptionDayFormGroup" [formGroup]="exceptionDayFormGroup" class="row row mt-4 mt-lg-2">

    <div class="col-12 day-selected-form" formArrayName="hours">
        <div class="row">
            <div class="col-12">
                <div class="day-selected">{{ day }}</div>
            </div>
        </div>
        <div class="row mb-2" *ngFor="let hour of hours.controls; index as hourIndex">
            <div class="col-8">
                <div class="field mb-0">
                    <input (change)="onHourUpdated()" [formControl]="hour" type="time">
                </div>
            </div>
            <div class="col-auto my-auto">
                <img *ngIf="!i18nTraslation" (click)="onRemoveHour(hourIndex)" class="ic-mid opacity-3" src="assets/img/ic_remove.svg">
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <a *ngIf="!i18nTraslation" (click)="onAddHour()" class="btn w-md-50 w-100 btn-tertiary mb-3 mb-sm-0 white-color">
                    <img class="ic-mid" src="assets/img/ic_add-white.svg">
                </a>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <a *ngIf="!i18nTraslation" (click)="onExceptionReset()" class="btn w-md-50 w-100 font-weight-bold btn-tertiary mb-3 mb-sm-0 white-color delete-special-days">
                      {{ 'createtourdate.Eliminar horarios especiales' | translate }}
                  </a>
            </div>
        </div>
    </div>
</div>