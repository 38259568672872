<div class="animated fadeIn">
    <div class="row header-dashboard align-items-lg-center align-items-start">
        <div class="col-12 col-sm-8 col-lg-6 col-xl-6 pl-0 order-1 order-lg-1">
            <h2 class="font-weight-bold">{{ 'affiliatelist.Listado de afiliados' | translate }}</h2>
            <p>{{ 'affiliatelist.Detalles de usuarios de tipo afiliado' | translate }}</p>
        </div>
        <div class="col-12 col-sm-4 col-lg-6 col-xl-6 text-left text-sm-right px-0 px-lg-auto order-2 order-lg-3">
            <button class="btn btn-primary px-sm-3 px-md-4" name="{{ 'affiliatelist.Añadir afiliado' | translate }}" (click)="createAffiliate()">
                <img class="mr-2" src="assets/img/ic_add-white.svg">
                <span>{{ 'affiliatelist.Afiliado' | translate }}</span>
            </button>
        </div>
        <!-- <div class="col-12 col-lg-6 col-xl-3 text-left text-lg-right px-0 px-lg-auto mt-3 mt-lg-0 order-3 order-lg-2">
            <input class="searcher-primary" type="search" placeholder="{{ 'providerslist.Buscar proveedores' | translate }}...">
        </div> -->
    </div>

    <div class="row dashboard-content-section">

        <div class="col-12 px-0">


            <app-affiliates-table *ngIf="desktopVersion" [affiliates]="affiliates" [pagination]="pagination" (affiliateTrigger)="onAffiliateClick($event)"></app-affiliates-table>
            <app-affiliates-cards *ngIf="!desktopVersion" [affiliates]="affiliates" [pagination]="pagination" (affiliateTrigger)="onAffiliateClick($event)"></app-affiliates-cards>

        </div>

    </div>

    <!-- Pagination -->

    <div class="row">
        <div class="col-12 d-flex justify-content-center">
            <pagination-controls [maxSize]="pagination.itemsPerPage" [directionLinks]="true" [autoHide]="true" [responsive]="true" [previousLabel]="paginationPreviousLabel" [nextLabel]="paginationNextLabel" (pageChange)="onPageChanged($event)"></pagination-controls>
        </div>
    </div>

</div>



<app-window-size-controller [initialSize]="initialWindowSize" (isDesktopVersion)="onDesktopVersionUpdated($event)"></app-window-size-controller>