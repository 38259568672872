<div class="row row-eq-height bg-white">
    <div class="animated fadeInLeft col-12 col-lg-6 col-xl-5 section-login-form order-2 order-lg-1">
        <div class="row text-right">
            <div class="col-12">
                <app-language-picker [extraClass]="'d-none d-lg-inline'"></app-language-picker>
            </div>
        </div>
        <div class="row mb-5 pb-3">
            <div class="col-12">
                <a (click)="goToHomeLanding()">
                    <img class="logo" src="assets/img/logo.png" />
                </a>
            </div>
        </div>

        <div class="row mb-3">
            <div class="col-12">
                <h2> {{ 'login.Inicia sesión para acceder a tu cuenta' | translate }} </h2>
                <h5> {{ 'login.Bienvenido a la mejor central de reservas del mundo' | translate }}. </h5>
            </div>
        </div>

        <form action="" [formGroup]="loginForm" class="form-underline">

            <div class="row">
                <div class="col-12">

                    <div class="field">
                        <input formControlName="email" type="text" name="email" id="email" placeholder="{{ 'login.Email' | translate }}" value="">
                        <label for="email">{{ 'login.Email' | translate }}</label>
                    </div>
                    <app-feedback [formGroup]="loginForm" field="email" validator="required" feedback="{{ 'login.Email requerido' | translate }}" [refresh]="refreshValidators"></app-feedback>
                    <app-feedback [formGroup]="loginForm" field="email" validator="email" feedback="{{ 'login.Email no válido' | translate }}" [refresh]="refreshValidators"></app-feedback>
                    <app-feedback [formGroup]="loginForm" field="email" validator="maxlength" feedback="{{ 'createtourdetails.Texto demasiado largo' | translate }}" [refresh]="refreshValidators"></app-feedback>

                    <div class="field">
                        <input formControlName="password" type="password" name="password" id="password" placeholder="{{ 'login.Password' | translate }}" value="">
                        <label for="password">{{ 'login.Password' | translate }}</label>
                    </div>
                    <app-feedback [formGroup]="loginForm" field="password" validator="required" feedback="{{ 'login.Password requerido' | translate }}" [refresh]="refreshValidators"></app-feedback>
                    <app-feedback [formGroup]="loginForm" field="password" validator="maxlength" feedback="{{ 'createtourdetails.Texto demasiado largo' | translate }}" [refresh]="refreshValidators"></app-feedback>

                    <div class="subfield text-right mb-4">
                        <a (click)="goToRememberPassword()" class="link-terciary">{{ 'login.¿Olvidate tu contraseña?' | translate }}</a>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-12 col-sm-6">
                    <input type="submit" (click)="onUserLogin()" value="{{ 'login.login' | translate }}" class="btn btn-full-width btn-primary mb-3 mb-sm-0" />
                </div>
            </div>
        </form>
    </div>
     <div app-access-side-panel class="col-12 col-lg-6 col-xl-7 section-login-bg d-flex align-items-start flex-column order-1 order-lg-2"></div>
</div>


<app-message-panel></app-message-panel>