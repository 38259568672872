import { Component, Input } from '@angular/core';
import { FormArray, FormGroup, FormControl } from '@angular/forms';
import { TourScheduleWeekdaysType } from 'src/app/domain/common/tour/tour-schedule-weekdays.constants';
import { ScheduleDateWeekdayView } from 'src/app/domain/views/provider/tour/schedule-date-weekday.view';
import { CalendarUtils } from 'src/app/ui/utils/calendar.utils';
import { AppTourConstants } from 'src/app/domain/common/tour/tour.constants';
import { TourUtils } from 'src/app/ui/components/utils/tour/Tour.utils';


@Component({
  selector: 'app-create-tour-schedule-details',
  templateUrl: './details.component.html'
})
export class CreateTourScheduleDetailsComponent {


  i18nTraslation: boolean;

  @Input() refreshValidators: boolean;


  @Input()
  set isI18nTraslation(isI18nTraslation: boolean) {
      this.i18nTraslation = isI18nTraslation;
  }


  @Input()
  set formGroupDetails(formGroupDetails: FormGroup) {

      if ( formGroupDetails ) {

          this.planningFormGroup = formGroupDetails;

          this.weekdaysIncluded = TourUtils.getDaysFromTourSchedulePlanningFormArray(this.days);
          this.exceptionsIncluded = TourUtils.getExceptionDaysFromTourSchedulePlanningFormArray(this.dayExceptions);

          this.onSelectedWeekdayEvent(TourScheduleWeekdaysType.Monday);


      }

  }

  @Input()
  set minDay(minDay: string) {
      this.minCalendarHtmlInputDate = minDay;
      this.prepareCalendarDates();
  }

  @Input()
  set maxDay(maxDay: string) {
      this.maxCalendarHtmlInputDate = maxDay;
      this.prepareCalendarDates();
  }

  planningFormGroup: FormGroup;

  weekday: TourScheduleWeekdaysType;
  currentWeekdayFormGroup: FormGroup;
  weekdaysIncluded: Array<TourScheduleWeekdaysType>;

  minCalendarHtmlInputDate: string;
  maxCalendarHtmlInputDate: string;

  minCalendarDate: Date;
  maxCalendarDate: Date;

  currentExceptionFormGroup: FormGroup;
  exceptionsIncluded: Array<string>;


  constructor() {
      this.weekdaysIncluded = new Array<TourScheduleWeekdaysType>();
      this.exceptionsIncluded = new Array<string>();
      this.prepareCalendarDates();

  }


  private prepareCalendarDates() {
      if ( this.minCalendarHtmlInputDate ) {
          this.minCalendarDate = CalendarUtils.fromDateHtmlInputToDate(this.minCalendarHtmlInputDate);
      } else {
          this.minCalendarDate = CalendarUtils.getCurrentDate();
      }

      if ( this.maxCalendarHtmlInputDate ) {
          this.maxCalendarDate = CalendarUtils.fromDateHtmlInputToDate(this.maxCalendarHtmlInputDate);
      } else {
          this.maxCalendarDate = CalendarUtils.getDateWithExtraYears(CalendarUtils.getCurrentDate(), AppTourConstants.CREATE_TOUR_FIXED_SCHEDULED_MAX_YEARS);
      }

  }


  get days(): FormArray {
      return this.planningFormGroup.get('days') as FormArray;
  }

  get dayExceptions(): FormArray {
      return this.planningFormGroup.get('dayExceptions') as FormArray;
  }



  createWeekdayFormGroup(day: any) {

      return new FormGroup({
          'day': new FormControl(day),
          'hours': new FormArray([new FormControl('')])
      })
  }


  findWeekdayFormGroup(date: any, list: FormArray): [number, FormGroup]  {
      let result = null;
      list.controls.forEach( (control, index) => {
          if ( control.value.day == date ) {
              result = [index, control];
          }
      });
      return result;
  }




  onSelectedWeekdayEvent(weekday: TourScheduleWeekdaysType) {
      this.onCalendarSelectedDate(null);
      this.weekday = weekday;
      const weekdayTuple = this.findWeekdayFormGroup(this.weekday, this.days);
      if ( !weekdayTuple ) {
          this.currentWeekdayFormGroup = this.createWeekdayFormGroup(this.weekday);
      } else {
          this.currentWeekdayFormGroup = weekdayTuple[1];
      }
  }


  onWeekdayUpdatedHours(weekdayHoursDefined: boolean) {

      const currentTuple = this.findWeekdayFormGroup(this.weekday, this.days);
      let updatedWeekdaysIncluded = this.weekdaysIncluded.slice();

      if ( currentTuple ) {
          this.days.removeAt(currentTuple[0]);
          const currentWeekday = this.weekday;
          updatedWeekdaysIncluded = updatedWeekdaysIncluded.filter( function( iteratedWeekday: TourScheduleWeekdaysType ) {
              return currentWeekday != iteratedWeekday;
          });
      }

      if ( weekdayHoursDefined ) {
          this.days.push(this.currentWeekdayFormGroup);
          updatedWeekdaysIncluded.push(this.weekday);
      }

      //We need to update fill var in order to binding detection
      this.weekdaysIncluded = updatedWeekdaysIncluded.slice();

  }


  onCalendarSelectedDate(dateWeekday: ScheduleDateWeekdayView) {

      if ( dateWeekday ) {

          const currentExceptionTuple = this.findWeekdayFormGroup(CalendarUtils.fromDateToDaleLocalizedDDMMYYYY(dateWeekday.date), this.dayExceptions);
          if ( currentExceptionTuple ) {
              this.currentExceptionFormGroup = currentExceptionTuple[1];
          } else {

              //Create new exception control
              this.currentExceptionFormGroup = this.createWeekdayFormGroup(CalendarUtils.fromDateToDaleLocalizedDDMMYYYY(dateWeekday.date));

              //Include general hours if exist
              const currentWeekdayTuple = this.findWeekdayFormGroup(dateWeekday.day, this.days);
              if ( currentWeekdayTuple ) {
                  const generalHours = currentWeekdayTuple[1].get('hours') as FormArray
                  const selectedHours = this.currentExceptionFormGroup.get('hours') as FormArray
                  const clonedHours = new FormArray([]);
                  generalHours.value.map( (hour: string) => {
                      clonedHours.push(new FormControl(hour));
                  })
                  selectedHours.controls = clonedHours.controls;
              }
          }

          if ( this.i18nTraslation ) {
              const selectedHours = this.currentExceptionFormGroup.get('hours') as FormArray
              selectedHours.controls.map( (hourControl: FormControl) => {
                    hourControl.disable();
              })
          }

      } else {
          this.currentExceptionFormGroup = null;
      }


  }


  onManageExceptionDay(includeExceptionDay: boolean) {

      const exceptionDateSelected = this.currentExceptionFormGroup.get('day').value;
      let updatedExceptionsIncluded = this.exceptionsIncluded.slice();

      const currentExceptionTuple = this.findWeekdayFormGroup(exceptionDateSelected, this.dayExceptions);
      if ( currentExceptionTuple ) {

          this.dayExceptions.removeAt(currentExceptionTuple[0]);
          updatedExceptionsIncluded = updatedExceptionsIncluded.filter( function( exceptionDate: string ) {
              return exceptionDate != exceptionDateSelected;
          });
      }

      if ( includeExceptionDay ) {

          this.dayExceptions.push(this.currentExceptionFormGroup);
          updatedExceptionsIncluded.push(exceptionDateSelected);
      } else {
          this.currentExceptionFormGroup = null;
      }

      //We need to update fill var in order to binding detection
      this.exceptionsIncluded = updatedExceptionsIncluded.slice();


  }




}
