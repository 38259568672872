export class BookFare {

     private _id: number;
     private _isChildren: boolean;
     private _isAdult: boolean;
     private _name: string;
     private _nameMain: string;
     private _quantity: number;
     private _shows: number;


     constructor(
          id: number,
          isChildren: boolean,
          isAdult: boolean,
          name: string,
          nameMain: string,
          quantity: number,
          shows: number
     ) {
          this._id = id;
          this._isChildren = isChildren;
          this._isAdult = isAdult;
          this._name = name;
          this._nameMain = nameMain;
          this._quantity = quantity;
          this._shows = shows;
     }


     get id(): number {
          return this._id;
     }

     get isChildren(): boolean {
          return this._isChildren;
     }

     get isAdult(): boolean {
          return this._isAdult;
     }

     get name(): string {
          return this._name;
     }

     get nameMain(): string {
          return this._nameMain;
     }

     get quantity(): number {
          return this._quantity;
     }

     get shows(): number {
          return this._shows;
     }

     set shows(shows: number) {
          this._shows = shows;
     }


}