import { Component, AfterViewInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NavigationConstants } from 'src/app/ui/commons/navigation.constants';
import { marker as I18N } from '@biesbjerg/ngx-translate-extract-marker';
import { SessionInteractor } from 'src/app/domain/interactor/session.interactor';
import { ServerErrorManager } from 'src/app/ui/components/utils/server-error-manager.utils';
import { NavigatorUtils } from 'src/app/ui/utils/navigator.utils';
import { TourInteractor } from 'src/app/domain/interactor/public/tour.interactor';
import { Tour } from 'src/app/business/models/tour/tour.model';
import { MessagePanelComponent } from 'src/app/ui/components/shared/message-panel/message-panel.component';
import { ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AppConstants } from 'src/app/domain/common/app.constants';
import { BookingInteractor } from 'src/app/domain/interactor/public/booking.interactor';
import { ServerErrorExceptionCase } from 'src/app/domain/views/server-error-manager-exception-case.view';
import { GenericException } from 'src/app/domain/exceptions/generic.exception';

@Component({
     selector: 'app-review',
     templateUrl: './review.component.html'
})
export class ReviewComponent implements AfterViewInit {

     @ViewChild(MessagePanelComponent)
     private messagePanelComponent: MessagePanelComponent;

     tourId: number;
     bookingId: number;
     languageId: number;
     hash: string;
     rating: number;
     tour: Tour;

     reviewForm: FormGroup;
     refreshValidators: boolean


     constructor(
          private router: Router,
          private activatedRoute: ActivatedRoute,
          private sessionInteractor: SessionInteractor,
          private tourInteractor: TourInteractor,
          private bookingInteractor: BookingInteractor,
          private serverErrorManager: ServerErrorManager) {

               this.refreshValidators = false;

               this.activatedRoute.queryParams.subscribe(params => {

                    this.tourId = params['tourId'];
                    this.bookingId = params['orderBookingId'];
                    this.hash = params['hash'];
                    this.rating = params['rating'];
                    this.languageId = params['languageId'];

                    const languageCode = params['languageCode']
                    if ( this.sessionInteractor.setCurrentLanguage(languageCode) ) {
                         NavigatorUtils.reloadApplication();
                    }


                    this.reviewForm = new FormGroup({
                      'rating': new FormControl(this.rating, [ Validators.required, Validators.min(1), Validators.max(5) ]),
                      'comments': new FormControl('')
                    })

               });
          }

     ngAfterViewInit() {
          this.tourInteractor.getTour(this.tourId, this.languageId)
               .subscribe(
                    (tour: Tour) => {
                         this.tour = tour;
                         MessagePanelComponent.hideLoading();
                    },
                    (error: GenericException) => {
                         MessagePanelComponent.hideLoading();
                         this.serverErrorManager.manageErrorCode(error, this.messagePanelComponent);

                    }
               );
     }


     onHeaderItemSelected() {
          this.router.navigate( [ NavigationConstants.getNavigationHome() ] );
     }

     goToReviewThanks() {

     }


     sendReview() {

       this.refreshValidators = true;

       if (this.reviewForm.valid) {

         this.messagePanelComponent.showLoading();

         const finalRating: number = this.reviewForm.get('rating').value;
         const comments: string = this.reviewForm.get('comments').value;

         this.bookingInteractor.rateBooking(this.bookingId, this.hash, finalRating, comments)
           .subscribe(
             () => {

               MessagePanelComponent.hideLoading();

               this.router.navigate([NavigationConstants.getNavigationReviewThanks()]);

             },
             (error: GenericException) => {

                  MessagePanelComponent.hideLoading();

                  const exceptionCases = new Array<ServerErrorExceptionCase>();

                  const exceptionCodes = new Array<number>();
                  exceptionCodes.push(AppConstants.ERROR_CLIENT_BAD_REQUEST);
                  exceptionCodes.push(AppConstants.ERROR_CLIENT_NOT_FOUND);
                  exceptionCodes.push(AppConstants.ERROR_SERVER_FAILURE);
                  exceptionCases.push( new ServerErrorExceptionCase(
                      exceptionCodes,
                      new Array<string>(),
                      () => {
                         this.messagePanelComponent.showErrorMessage(
                              I18N('review.No hemos podido procesar la valoración.'),
                              () => {
                                 this.goToHomeLanding();
                              }
                         );
                      }
                  ));

                  this.serverErrorManager.manageErrorCode(error, this.messagePanelComponent, exceptionCases);
             }
           )
       }

     }


     goToHomeLanding() {
          this.router.navigate( [ NavigationConstants.getNavigationHome() ] );
     }

}
