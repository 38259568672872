import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';
import { EventDetails } from 'src/app/business/models/book/event-details.model';
import { BookFareEditionView } from 'src/app/domain/views/book/book-fare-tuple.view';
import { Book } from 'src/app/business/models/book/book.model';
import { BookFare } from 'src/app/business/models/book/book-fare.model';
import { BookingState } from 'src/app/business/models/book/common/booking-state.constants';
import { BookingGuideConfirmationState } from 'src/app/business/models/book/common/booking-guide-confirmation-state.constants';
import { BookConfirmEditionView } from 'src/app/domain/views/book/book-confirm-edition.view';

@Component({
     selector: 'app-details-booking-card',
     templateUrl: './cards.component.html'
})
export class DetailBookingCardsComponent implements OnInit {

     @Input() eventDetails: EventDetails;

     @Input() isGuideRole: boolean;

     @Output() updateShows = new EventEmitter<BookFareEditionView>();

     @Output() increaseShows = new EventEmitter<BookFareEditionView>();

     @Output() decreaseShows = new EventEmitter<BookFareEditionView>();

     @Output() updateContactDetails = new EventEmitter<Book>();

     @Output() resendConfirmationEmail = new EventEmitter<Book>();

     @Output() cancelBooking = new EventEmitter<Book>();

     @Output() newBooking = new EventEmitter<Book>();

     @Output() updateEventBookAllFareShows = new EventEmitter<BookConfirmEditionView>();

     stateConfirmed: BookingState;

     bookingGuideConfirmationStateShowed: BookingGuideConfirmationState;
     bookingGuideConfirmationStateNotShowed: BookingGuideConfirmationState;

     constructor() {
          this.stateConfirmed = BookingState.Confirmed;

          this.bookingGuideConfirmationStateShowed = BookingGuideConfirmationState.Showed;
          this.bookingGuideConfirmationStateNotShowed = BookingGuideConfirmationState.NotShowed;
     }

     ngOnInit() {
     }

     onEditShows(book: Book, bookFare: BookFare) {

          this.updateShows.emit(
               new BookFareEditionView(
                    this.eventDetails.tourId,
                    this.eventDetails.day,
                    this.eventDetails.hour,
                    book.id,
                    bookFare.id,
                    bookFare.isChildren,
                    bookFare.isAdult,
                    bookFare.quantity,
                    bookFare.shows
               )
          )
     }

     onDecreaseShowsForBooking(book: Book, bookFare: BookFare) {
          this.decreaseShows.emit(new BookFareEditionView(
                    this.eventDetails.tourId,
                    this.eventDetails.day,
                    this.eventDetails.hour,
                    book.id,
                    bookFare.id,
                    bookFare.isChildren,
                    bookFare.isAdult,
                    bookFare.quantity,
                    bookFare.shows
               )
          );
     }

     onIncreaseShowsForBooking(book: Book, bookFare: BookFare) {
          this.increaseShows.emit(new BookFareEditionView(
                    this.eventDetails.tourId,
                    this.eventDetails.day,
                    this.eventDetails.hour,
                    book.id,
                    bookFare.id,
                    bookFare.isChildren,
                    bookFare.isAdult,
                    bookFare.quantity,
                    bookFare.shows
               )
          );
     }

     onEditContactDetails(book: Book) {
          this.updateContactDetails.emit(book);
     }

     onResendConfirmationEmail(book: Book) {
          this.resendConfirmationEmail.emit(book);
     }

     onCancelBooking(book: Book) {
          this.cancelBooking.emit(book);
     }

     onNewBooking(book: Book) {
          this.newBooking.emit(book);
     }

     onGuideSetAsShowed(book: Book) {
          let bookingGuideConfirmationState: BookingGuideConfirmationState = BookingGuideConfirmationState.Showed;
          //If uncheck the option
          if ( book.guideConfirmationState == BookingGuideConfirmationState.Showed ) {
               bookingGuideConfirmationState = null;
          }

          this.updateEventBookAllFareShows.emit(new BookConfirmEditionView(book.id, bookingGuideConfirmationState));
     }

     onGuideSetAsNotShowed(book: Book) {
          let bookingGuideConfirmationState: BookingGuideConfirmationState = BookingGuideConfirmationState.NotShowed;
          //If uncheck the option
          if ( book.guideConfirmationState == BookingGuideConfirmationState.NotShowed ) {
               bookingGuideConfirmationState = null;
          }

          this.updateEventBookAllFareShows.emit(new BookConfirmEditionView(book.id, bookingGuideConfirmationState));
     }

}
