import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseService } from '../base.service';
import { map } from 'rxjs/operators';
import { ChunkGeneric } from 'src/app/domain/views/chunk-generic.view';
import { Pagination } from 'src/app/domain/views/pagination.view';
import { TourResume } from '../../models/tour/tour-resume.model';
import { TourUtils } from '../../models/tour/common/tour.utils';
import { GuideTourApiEndpointsUtils } from '../../api/endpoints/guide-tour-api-endponints.utils';
import { EventBasic } from '../../models/book/event-basic.model';


@Injectable({
     providedIn: 'root'
})
export class GuideTourService extends BaseService {


    findToursForReselling(localeI18N: string, search: string, countryId: number, cityId: number, page: number): Observable<ChunkGeneric<TourResume>> {

      return super.sendGetRequestWithRefresh(GuideTourApiEndpointsUtils.getFindToursForResellingEndpoint(search, countryId, cityId, page), GuideTourApiEndpointsUtils.getFindToursForResellingEndpointAcceptVersion())
        .pipe(
          map((response: any) => {

            const toursResume = new Array<TourResume>();

            response.items.map((tourBasicApi: any) => {

              const tourResume = TourUtils.getTourResumeFromApi(tourBasicApi, localeI18N);
              toursResume.push(tourResume);
            })

            return new ChunkGeneric<TourResume>(toursResume, new Pagination(response.total, response.currentPage, response.chunkSize));

          })
        )
    }



    findAllToursForManagement(localeI18N: string): Observable<Array<TourResume>> {

      return super.sendGetRequestWithRefresh(GuideTourApiEndpointsUtils.getFindToursForManagementEndpoint(), GuideTourApiEndpointsUtils.getFindToursForManagementEndpointAcceptVersion())
        .pipe(
          map((response: any) => {

            const toursResume = new Array<TourResume>();

            response.map((tourBasicApi: any) => {
              const tourResume = TourUtils.getTourResumeFromApi(tourBasicApi, localeI18N);
              toursResume.push(tourResume);
            });

            return toursResume;

          })
        )
    }


    findEvents(tourId: number, day: string): Observable<Array<EventBasic>> {

      return super.sendGetRequestWithRefresh(
        GuideTourApiEndpointsUtils.getGuideToursEventsEndpoint(tourId, day),
        GuideTourApiEndpointsUtils.getGuideToursEventsAcceptVersion()
      )
        .pipe(
          map((eventsApi: any) => {
  
            const events = new Array<EventBasic>();
  
            eventsApi.map((eventApi: any) => {
  
              events.push(TourUtils.getEventBasicFromApi(eventApi));
  
            })
  
            return events;
            
          })
        )
    }
    


}
