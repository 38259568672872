import { ImageThumb } from './image-thumb.model';

export class ImageDetails {

    private _id: number;
    private _name: string;
    private _position: number;
    private _original: ImageThumb;
    private _big: ImageThumb;
    private _medium: ImageThumb;
    private _small: ImageThumb;

    constructor(id: number, name: string, position: number, original: ImageThumb, big: ImageThumb, medium: ImageThumb, small: ImageThumb) {
        this._id = id;
        this._name = name;
        this._position = position;
        this._original = original;
        this._big = big;
        this._medium = medium;
        this._small = small;
    }

    get id(): number {
      return this._id;
    }

    set id(id: number) {
      this._id = id;
    }

    get name(): string {
      return this._name;
    }

    set name(name: string) {
      this._name = name;
    }

    get position(): number {
      return this._position;
    }

    set position(position: number) {
      this._position = position;
    }

    get original(): ImageThumb {
      return this._original;
    }

    set original(original: ImageThumb) {
      this._original = original;
    }

    get big(): ImageThumb {
      return this._big;
    }

    set big(big: ImageThumb) {
      this._big = big;
    }

    get medium(): ImageThumb {
      return this._medium;
    }

    set medium(medium: ImageThumb) {
      this._medium = medium;
    }

    get small(): ImageThumb {
      return this._small;
    }

    set small(small: ImageThumb) {
      this._small = small;
    }

}
