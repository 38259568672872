import { CancelationReason } from 'src/app/business/models/master/booking/cancelation-reason/cancelaltion-reason.constants';

export class BookCacnelationDetailsView {

     private _bookId: number;
     private _message: string;
     private _cancelationReason: CancelationReason;

     constructor(
          bookId: number,
          message: string,
          cancelationReason: CancelationReason
     ) {

          this._bookId = bookId;
          this._message = message;
          this._cancelationReason = cancelationReason;

     }


     get bookId(): number {
          return this._bookId;
     }

     get message(): string {
          return this._message;
     }

     get cancelationReason(): CancelationReason {
          return this._cancelationReason;
     }


}