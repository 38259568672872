export class FindBillingFilterView {

     private _dateInit: string;
     private _dateEnd: string;
     private _keywords: string;
     private _pageNumber: number;
     private _companyId: number;
     private _guideId: number;

     constructor(
          dateInit: string,
          dateEnd: string,
          keywords: string,
          pageNumber: number,
          companyId: number,
          guideId: number,
     ) {

          this._dateInit = dateInit;
          this._dateEnd = dateEnd;
          this._keywords = keywords;
          this._pageNumber = pageNumber;
          this._companyId = companyId;
          this._guideId = guideId;

     }

     get dateInit(): string {
          return this._dateInit;
     }

     get dateEnd(): string {
          return this._dateEnd;
     }

     get keywords(): string {
          return this._keywords;
     }

     get pageNumber(): number {
          return this._pageNumber;
     }

     get companyId(): number {
          return this._companyId;
     }

     get guideId(): number {
          return this._guideId;
     }

}