import { Component, ViewChild } from '@angular/core';
import { MessagePanelComponent } from '../../../shared/message-panel/message-panel.component';
import { Provider } from 'src/app/business/models/provider/provider.model';
import { FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthenticateInteractor } from 'src/app/domain/interactor/authenticate.interactor';
import { AppConstants } from 'src/app/domain/common/app.constants';
import { NavigationConstants } from 'src/app/ui/commons/navigation.constants';
import { MasterInteractor } from 'src/app/domain/interactor/public/master.interactor';
import { Language } from 'src/app/business/models/master/language/language.model';
import { marker as I18N } from '@biesbjerg/ngx-translate-extract-marker';
import { ServerErrorManager } from '../../../utils/server-error-manager.utils';
import { GenericException } from 'src/app/domain/exceptions/generic.exception';
import { BrowserConstants } from 'src/app/ui/commons/browser.constants';
import { Country } from 'src/app/business/models/master/country/country.model';
import { City } from 'src/app/business/models/master/city/city.model';
import { ServerErrorExceptionCase } from 'src/app/domain/views/server-error-manager-exception-case.view';
import { ApiConstants } from 'src/app/business/api/api.constants';
import { SessionInteractor } from 'src/app/domain/interactor/session.interactor';


@Component({
     selector: 'app-singup-affiliate',
     templateUrl: './singup.component.html'
})
export class SingupAffiliateComponent {

     @ViewChild(MessagePanelComponent)
     private messagePanelComponent: MessagePanelComponent;


     currentLanguageId: number;

     provider: Provider;

     languages: Array<Language>;
     countries: Array<Country>;
     cities: Array<City>;

     affiliateFullForm: FormGroup;

     refreshValidators: boolean;

     constructor(
          private authenticateInteractor: AuthenticateInteractor,
          private masterInteractor: MasterInteractor,
          private sessionInteractor: SessionInteractor,
          private router: Router,
          private serverErrorManager: ServerErrorManager) {

               this.refreshValidators = false;

               this.affiliateFullForm = new FormGroup({


                    'email': new FormControl('', [Validators.required, Validators.email]),

                    'password': new FormControl('', [Validators.required, Validators.maxLength(AppConstants.TEXT_DEFAULT_MAX_LENGTH)]),
                    'retryPassword': new FormControl('', [Validators.required, this.noEqualValidator, Validators.maxLength(AppConstants.TEXT_DEFAULT_MAX_LENGTH)]),


                    'companyName': new FormControl('', [Validators.required, Validators.maxLength(AppConstants.TEXT_DEFAULT_MAX_LENGTH)]),
                    'address': new FormControl('', [Validators.required, Validators.maxLength(256)]),

                    'country': new FormControl(null, [Validators.required]),
                    'city': new FormControl(null, [Validators.required]),

                    'businessDocumentType': new FormControl('', [Validators.required]),
                    'businessDocument': new FormControl('', [Validators.required, Validators.maxLength(AppConstants.TEXT_DEFAULT_MAX_LENGTH)]),
                    'phone': new FormControl('', [Validators.required, Validators.maxLength(AppConstants.TEXT_DEFAULT_MAX_LENGTH)]),
                    'VATExempt': new FormControl(false, []),

                    'language': new FormControl(null, [Validators.required]),

                    'privacyPolicy': new FormControl(false, [this.privacyPolicyValidator]),

                    'websites': new FormArray([])

               })


          }


          ngOnInit() {

               this.masterInteractor.getLanguagesLocalized()
               .subscribe(
                    (languages: Array<Language>) => {
                         this.languages = languages;

                         this.currentLanguageId = 1;
                         this.languages.forEach( ( language: Language ) => {
                              if ( this.sessionInteractor.getCurrentLanguage() == language.code ) {
                                   this.currentLanguageId = language.id;
                              }
                         });

                         this.masterInteractor.getCountriesLocalized(this.currentLanguageId)
                         .subscribe(
                              (countries: Array<Country>) => {
                                   this.countries = countries
                              },
                              (error: GenericException) => {
                                   this.serverErrorManager.manageErrorCode(error, this.messagePanelComponent);
                              }
                         );


                    },
                    (error: GenericException) => {
                         this.serverErrorManager.manageErrorCode(error, this.messagePanelComponent);
                    }
               )




               this.onAddWebsite();
          }


          get websites(): FormArray {
               return this.affiliateFullForm.get('websites') as FormArray;
          }



          registerAffiliate() {

               this.refreshValidators = true;

               if ( this.affiliateFullForm.valid && this.websitesValid() ) {

                    this.messagePanelComponent.showLoading();

                    const websites: Array<string> = new Array<string>();
                    this.websites.controls.map( (website: FormGroup) => {
                         let urlValue = website.get('url').value;
                         if ( urlValue ) {
                              urlValue = urlValue.trim();
                         }
                         if ( urlValue != '' ) {
                              websites.push(urlValue);
                         }
                    });

                    this.authenticateInteractor.singupAffiliate(
                         this.affiliateFullForm.value.email,
                         this.affiliateFullForm.value.password,
                         this.affiliateFullForm.value.retryPassword,
                         this.affiliateFullForm.value.language,
                         websites,
                         this.affiliateFullForm.value.phone,
                         this.affiliateFullForm.value.country,
                         this.affiliateFullForm.value.city,
                         this.affiliateFullForm.value.companyName,
                         this.affiliateFullForm.value.businessDocumentType,
                         this.affiliateFullForm.value.businessDocument,
                         this.affiliateFullForm.value.address,
                         this.affiliateFullForm.value.VATExempt)
                         .subscribe(
                              () => {
                                   MessagePanelComponent.hideLoading();
                                   this.messagePanelComponent.showSuccessMessage(I18N('singup.Información enviada. Recibirás un email de confirmación cuando se revisen los datos'), () => this.goToHomeLanding());

                              },
                              (error: GenericException) => {

                                   MessagePanelComponent.hideLoading();


                                   const exceptionCases = new Array<ServerErrorExceptionCase>();


                                   const exceptionCodes = new Array<number>();
                                   const exceptionCauses = new Array<string>();
                                   exceptionCauses.push(ApiConstants.API_ERROR_CAUSE_DUPLICATE_INSTANCE_FOUND);
                                   exceptionCases.push(new ServerErrorExceptionCase(
                                        exceptionCodes,
                                        exceptionCauses,
                                        () => {
                                             this.messagePanelComponent.showErrorMessage(I18N('singup.Los datos ya se habían enviado. Es porible que la cuenta esté pendiente de aprobación'));
                                        }
                                   ));

                                   this.serverErrorManager.manageErrorCode(error, this.messagePanelComponent);

                              }
                         )

                    }
               }


               noEqualValidator(control: FormControl): { [s: string]: boolean } {
                    if (control.parent && control.value != control.parent.controls['password'].value) {
                         return {
                              noequals: true
                         }
                    }
                    return null;
               }

               privacyPolicyValidator(control: FormControl): { [s: string]: boolean } {
                    if (control.parent && !control.value) {
                         return {
                              required: true
                         }
                    }
                    return null;
               }

               onProvacyPolicyCheck() {
                    if ( this.affiliateFullForm.get('privacyPolicy').value) {
                         this.affiliateFullForm.get('privacyPolicy').setValue(false);
                    } else {
                         this.affiliateFullForm.get('privacyPolicy').setValue(true);
                    }
               }

               onVATExemptCheck() {
                    if ( this.affiliateFullForm.get('VATExempt').value) {
                         this.affiliateFullForm.get('VATExempt').setValue(false);
                    } else {
                         this.affiliateFullForm.get('VATExempt').setValue(true);
                    }
               }


               goToLogin() {
                    this.router.navigate([NavigationConstants.NAVIGATION_LOGIN]);
               }

               goToHomeLanding() {
                    this.router.navigate([NavigationConstants.getNavigationHome()]);
               }

               goToContact() {
                    this.router.navigate([NavigationConstants.getNavigationHome()], { fragment: NavigationConstants.NAVIGATION_HOME_ANCHOR_CONTACT });
               }

               goToTerms() {
                    this.router.navigate([NavigationConstants.getNavigationTerms()]);
               }



               onAddWebsite() {
                    let existEmptyWebsites = false;
                    this.websites.controls.map( (websiteControl: FormControl) => {
                         const urlValue = websiteControl.get('url').value.trim();
                         if ( urlValue == '' ) {
                              existEmptyWebsites = true;
                         }
                    })
                    if ( !existEmptyWebsites ) {
                         this.websites.controls.push(
                              new FormGroup({
                                   'url': new FormControl('', [Validators.pattern(AppConstants.REGULAR_EXPRESSION_VALIDATE_WEBSITE), Validators.maxLength(AppConstants.TEXT_DEFAULT_MAX_LENGTH)])
                              })
                         );
                    }
               }

               onWebsiteChanged(event: any, websiteIndex: number) {
                    if ( event.keyCode == BrowserConstants.KEYBOARD_BACKSPACE_CODE && this.websites.at(websiteIndex) && this.websites.at(websiteIndex).get('url').value == '' && websiteIndex > 0) {
                         this.websites.removeAt(websiteIndex);
                    }
               }

               onRemoveWebsite(websiteIndex: number) {
                    //Remove if not the last item and rest otherwise
                    if (this.websites.length == 1) {
                         this.websites.at(websiteIndex).setValue('');
                    } else {
                         this.websites.removeAt(websiteIndex);
                    }
               }

               onCountrySelected() {

                    const selectedCountryId = this.affiliateFullForm.value.country
                    this.cities = new Array<City>()
                    this.affiliateFullForm.get('city').setValue(null);

                    if (selectedCountryId != null) {

                         this.masterInteractor.getCitiesLocalized(selectedCountryId, this.currentLanguageId)
                         .subscribe(
                              (cities: Array<City>) => {
                                   this.cities = cities
                              },
                              (error: GenericException) => {
                                   this.serverErrorManager.manageErrorCode(error, this.messagePanelComponent);
                              }
                         )

                    }

               }


               websitesValid(): boolean {

                    let isWebsitesValid = true;
                    this.websites.controls.map( (website: FormGroup) => {
                         if ( !website.get('url').valid ) {
                              isWebsitesValid = false;
                         }
                    });

                    return isWebsitesValid;

               }


          }
