import { Pipe, PipeTransform } from '@angular/core';
import { marker as I18N } from '@biesbjerg/ngx-translate-extract-marker';
import { BookingState } from 'src/app/business/models/book/common/booking-state.constants';

@Pipe({
  name: 'billingStateBooking'
})
export class BillingStateBookingPipe implements PipeTransform {

  transform(_defaultValue: string, state: BookingState): string {

    let result = _defaultValue;
    if ( state == BookingState.Pending ) {
        result = I18N('bookingstate.Pendiente')
   } else if ( state == BookingState.Confirmed ) {
        result = I18N('bookingstate.Confirmada')
   } else if ( state == BookingState.CanceledByUser ) {
          result = I18N('bookingstate.Cancelada por usuario')
    } else if ( state == BookingState.CanceledByProvider ) {
        result = I18N('bookingstate.Cancelada por proveedor')
    } else if ( state == BookingState.Canceled ) {
        result = I18N('bookingstate.Cancelada')
    }

    return result ;

  }

}
