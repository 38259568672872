import { Pipe, PipeTransform } from '@angular/core';
import { marker as I18N } from '@biesbjerg/ngx-translate-extract-marker';
import { CommissionType } from 'src/app/business/models/billing/common/commission-type.constants';
import { GuideServiceState } from 'src/app/business/models/billing/common/guide-service-state.constants';

@Pipe({
     name: 'serviceStateLabelPipe'
})
export class ServiceStateLabelPipe implements PipeTransform {

     transform(state: GuideServiceState): string {

          let result: string = I18N('guideservicestatelabel.Pendiente');
          if ( state == GuideServiceState.Approved ) {
               result = I18N('guideservicestatelabel.Aprobada');
          } else if ( state == GuideServiceState.Billed ) {
               result = I18N('guideservicestatelabel.Confirmada');
          } else if ( state == GuideServiceState.Deleted ) {
               result = I18N('guideservicestatelabel.Cancelada');
          }

          return result ;

     }

}
