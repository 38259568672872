import { Component, OnInit, ViewChild } from '@angular/core';

import { MessagePanelComponent }        from 'src/app/ui/components/shared/message-panel/message-panel.component';
import { ServerErrorManager }           from 'src/app/ui/components/utils/server-error-manager.utils';

import { ChunkGeneric }                 from 'src/app/domain/views/chunk-generic.view';

import { GenericException }             from 'src/app/domain/exceptions/generic.exception';
import { AdminBillingInteractor }       from 'src/app/domain/interactor/admin/billing.interactor';
import { BookingBilling }               from 'src/app/business/models/billing/booking-billing.model';

import { AdminAffiliateInteractor } from 'src/app/domain/interactor/admin/affiliate.interactor';
import { CompanyName } from 'src/app/business/models/master/company/company-name.model';
import { FindBillingFilterView } from 'src/app/domain/views/billing/find-invoices-filter.view';


@Component({
     selector: 'app-affiliate-list-billing-admin',
     templateUrl: './list.component.html'
})
export class AdminAffiliateListBillingComponent implements OnInit {

     @ViewChild(MessagePanelComponent)
     private messagePanelComponent: MessagePanelComponent;

     chunkBookingBilling: ChunkGeneric<BookingBilling>;
     affiliates: Array<CompanyName>;

     constructor(
          private adminBillingInteractor: AdminBillingInteractor,
          private adminAffiliateInteractor: AdminAffiliateInteractor,
          private serverErrorManager: ServerErrorManager) {


     }



     ngOnInit() {

          this.adminAffiliateInteractor.getAffiliatesNames()
          .subscribe(
               ( affiliates: Array<CompanyName> ) => {

                    this.affiliates = affiliates;
               },
               (error: GenericException) => {
                    this.serverErrorManager.manageErrorCode(error, this.messagePanelComponent);

               }
          )

     }


     onFindBillingTrigger(findBillingFilterView: FindBillingFilterView) {

          if (this.messagePanelComponent) {
            this.messagePanelComponent.showLoading();
          }

          this.adminBillingInteractor.findAffiliateBilling(findBillingFilterView.dateInit, findBillingFilterView.dateEnd, findBillingFilterView.companyId, findBillingFilterView.keywords, findBillingFilterView.pageNumber)
          .subscribe(
               (chunkBookingBilling: ChunkGeneric<BookingBilling>) => {

                    this.chunkBookingBilling = chunkBookingBilling;
                    MessagePanelComponent.hideLoading();
               },
               (error: GenericException) => {
                    MessagePanelComponent.hideLoading();
                    this.serverErrorManager.manageErrorCode(error, this.messagePanelComponent);
               }
          )
     }


}
