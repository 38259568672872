import { Component, Input, Output, EventEmitter, OnInit, ViewChild } from '@angular/core';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { Provider } from 'src/app/business/models/provider/provider.model';
import { FormUtils } from 'src/app/ui/components/utils/form.utils';
import { ProviderDetailsView } from 'src/app/domain/views/provider/provider-details.view';
import { Country } from 'src/app/business/models/master/country/country.model';
import { City } from 'src/app/business/models/master/city/city.model';
import { ServerErrorManager } from 'src/app/ui/components/utils/server-error-manager.utils';
import { AppConstants } from 'src/app/domain/common/app.constants';
import { GenericException } from 'src/app/domain/exceptions/generic.exception';
import { UserMasterInteractor } from 'src/app/domain/interactor/user/user-master.interactor';
import { MessagePanelComponent } from '../../message-panel/message-panel.component';

@Component({
  selector: 'app-profile-provider-details',
  templateUrl: './details.component.html'
})
export class ProfileProviderDetailsComponent implements OnInit{

  @ViewChild(MessagePanelComponent)
  private messagePanelComponent: MessagePanelComponent;


  @Input()
  set provider(provider: Provider) {
      if ( provider ) {
          this.setProviderFormValues(provider)
      }
  }

  @Output() providerDetails = new EventEmitter<ProviderDetailsView>(true);

  providerDetailsForm: FormGroup
  isDisabled = true

  countries: Array<Country>
  cities: Array<City>

  refreshValidators: boolean

  constructor(
    private userMasterInteractor: UserMasterInteractor,
    private serverErrorManager: ServerErrorManager,
   ) {

      this.refreshValidators = false;

      this.providerDetailsForm = new FormGroup({
          'id': new FormControl({ value: '', disabled: true }, [Validators.required] ),
          'companyName': new FormControl({ value: '', disabled: true }, [Validators.required, Validators.maxLength(AppConstants.TEXT_DEFAULT_MAX_LENGTH)] ),
          'web': new FormControl({ value: '', disabled: true }, [Validators.required, Validators.maxLength(AppConstants.TEXT_DEFAULT_MAX_LENGTH)] ),
          'phone': new FormControl({ value: '', disabled: true }, [Validators.required, Validators.maxLength(AppConstants.TEXT_DEFAULT_MAX_LENGTH)] ),
          'country': new FormControl({ value: '', disabled: true }, [Validators.required] ),
          'city': new FormControl({ value: '', disabled: true }, [Validators.required] ),
          'contactPerson': new FormControl({ value: '', disabled: true }, [Validators.required, Validators.maxLength(AppConstants.TEXT_DEFAULT_MAX_LENGTH)] ),
          'contactEmail': new FormControl({ value: '', disabled: true }, [Validators.required, Validators.email, Validators.maxLength(AppConstants.TEXT_DEFAULT_MAX_LENGTH)] )
      })

  }


  ngOnInit() {
      this.userMasterInteractor.getCountriesByUser()
        .subscribe(
            (countries: Array<Country>) => {
                this.countries = countries;
            },
            (error: GenericException) => {
                this.serverErrorManager.manageErrorCode(error, this.messagePanelComponent);
            }
        )
  }


  toggleEdition() {

        this.isDisabled = !this.isDisabled

        if ( this.isDisabled ) {
            FormUtils.disableAllControls(this.providerDetailsForm);
        } else {
            FormUtils.enableAllControls(this.providerDetailsForm);
        }
  }


  saveDetails() {

      this.refreshValidators = true;

      if ( this.providerDetailsForm.valid ) {

          this.providerDetails.emit(
              new ProviderDetailsView(
                  this.providerDetailsForm.value.id,
                  this.providerDetailsForm.value.companyName,
                  this.providerDetailsForm.value.contactPerson,
                  this.providerDetailsForm.value.phone,
                  this.providerDetailsForm.value.country,
                  this.providerDetailsForm.value.city,
                  this.providerDetailsForm.value.web,
                  this.providerDetailsForm.value.contactEmail
              )
          );

          this.toggleEdition();

      }

  }


  private setProviderFormValues( provider: Provider ) {

      this.providerDetailsForm.controls['id'].setValue(provider.id);
      this.providerDetailsForm.controls['companyName'].setValue(provider.companyName);
      this.providerDetailsForm.controls['web'].setValue(provider.web);
      this.providerDetailsForm.controls['phone'].setValue(provider.phone);
      this.providerDetailsForm.controls['country'].setValue(provider.countryId);
      this.providerDetailsForm.controls['city'].setValue(provider.cityId);
      this.providerDetailsForm.controls['contactPerson'].setValue(provider.contactPerson);
      this.providerDetailsForm.controls['contactEmail'].setValue(provider.contactEmail);


      if ( provider.countryId ) {
          this.userMasterInteractor.getCitiesByUser(provider.countryId)
            .subscribe(
                (cities: Array<City>) => {
                    this.cities = cities;
                },
                (error: GenericException) => {
                    this.serverErrorManager.manageErrorCode(error, this.messagePanelComponent);
                }
            )
      }

  }


  onCountrySelected() {

    const selectedCountryId = this.providerDetailsForm.value.country;

    this.cities = new Array<City>();
    this.providerDetailsForm.controls['city'].setValue(null);

    if ( selectedCountryId ) {

        this.userMasterInteractor.getCitiesByUser(selectedCountryId)
          .subscribe(
              (cities: Array<City>) => {
                  this.cities = cities;
              },
              (error: GenericException) => {
                  this.serverErrorManager.manageErrorCode(error, this.messagePanelComponent);
              }
          )

    }

  }


}
