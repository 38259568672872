<div *ngIf="!isGuideRole" class="card table-responsive bg-grey-light-card-color">
    <div class="row">
        <div class="offset-3 col-5 offset-sm-6 col-sm-3 title-secondary text-center mb-3 pr-0 pr-sm-3">
            {{ 'detailbooking.Reservas' | translate }}
        </div>

        <div class="col-4 col-sm-2 title-secondary text-center mb-3">
            {{ 'detailbooking.Shows' | translate }}
        </div>
        <div class="col-sm-1"></div>
    </div>
    <div class="row">
        <div class="col-3 col-sm-6 text-left pr-0 pr-sm-3">
            <p class="name-provider mb-2">TOTAL</p>
        </div>
        <div class="col-5 col-sm-3 text-center text-body-secondary  pr-0 pr-sm-3">
            {{ eventDetails.people }}
        </div>
        <div class="col-4 col-sm-2 text-center text-body-secondary green-color">
            {{ eventDetails.shows }}
        </div>
        <div class="col-sm-1"></div>
    </div>
</div>

<div class="row empty-section" *ngIf="!eventDetails.bookings.length">
    <div class="col-12 text-center my-auto">
        <img class="logo mb-4" src="assets/img/logo_opacity-21.png">
        <p>{{ 'detailbooking.Todavía no existen reservas para el día seleccionado' | translate }}</p>
    </div>
</div>

<table class="table table-hover table-dark table-booking" *ngIf="eventDetails.bookings.length">
    <thead class="thead-dark">
        <tr>
            <th scope="col"></th>
            <th scope="col"> {{ 'detailbooking.Nombre' | translate }} </th>
            <th scope="col"> {{ 'detailbooking.Email' | translate }} </th>
            <th scope="col"> {{ 'detailbooking.Teléfono' | translate }} </th>
            <th scope="col" class="text-center"> {{ 'detailbooking.Reservas' | translate }} </th>
            <th scope="col" class="text-center"> {{ 'detailbooking.Shows' | translate }} </th>
            <th scope="col"></th>
            <th scope="col"></th>
            <th scope="col"></th>
        </tr>
    </thead>
    <tbody class="">
        <ng-container *ngFor="let book of eventDetails.bookings">

            <tr
               [class.bg-light]="stateConfirmed != book.state"
               [class.bg-green-light]="isGuideRole && book.guideConfirmationState == bookingGuideConfirmationStateShowed"
               [class.bg-red-light]="isGuideRole && book.guideConfirmationState == bookingGuideConfirmationStateNotShowed"
               >
                <td class="px-0 text-center">
                    <a *ngIf="!isGuideRole && stateConfirmed == book.state" (click)="onEditContactDetails(book)">
                        <img class="ic-mid" src="assets/img/ic_edit.svg">
                    </a>

                    <a *ngIf="isGuideRole" (click)="onGuideSetAsShowed(book)">
                        <img  class="mr-1 guide-booking-confirm-show-action" src="assets/img/ic_list.svg">
                    </a>
                    <a *ngIf="isGuideRole" (click)="onGuideSetAsNotShowed(book)">
                        <img class="ml-1 guide-booking-confirm-show-action" src="assets/img/ic_cancel.svg">
                    </a>

                </td>
                <td>
                    {{ book.name }} {{ book.surname }}
                </td>

                <td>
                    {{ book.email }}
                </td>
                <td>
                    {{ book.phone }}
                </td>
                <td class="font-weight-bold text-center">{{ book.people }}</td>
                <td class="font-weight-bold text-center">
                     {{ book.shows }}
                </td>
                <td class="px-0 text-center">
                    <a *ngIf="!isGuideRole && stateConfirmed == book.state" (click)="onResendConfirmationEmail(book)" placement="bottom" ngbTooltip="{{ 'detailbooking.Reenviar email de confirmación' | translate }}" tooltipClass="tooltip-info">
                        <img class="ic-mid" src="assets/img/ic_resend-mail.svg">
                    </a>
                    <a *ngIf="isGuideRole" (click)="onNewBooking(book)" placement="bottom" ngbTooltip="{{ 'detailbooking.Reservar otro tour' | translate }}" tooltipClass="tooltip-info">
                        <img class="ic-mid" src="assets/img/ic_calendar_black.svg">
                    </a>
                </td>
                <td class="px-0 text-center">
                    <a *ngIf="!isGuideRole && stateConfirmed == book.state && eventDetails.bookingsCanBeCancelled" (click)="onCancelBooking(book)" placement="bottom" ngbTooltip="{{ 'detailbooking.Cancelar reserva' | translate }}" tooltipClass="tooltip-info">
                        <img class="ic-mid" src="assets/img/ic_cancel.svg">
                    </a>
                    <a *ngIf="!isGuideRole && stateConfirmed == book.state && !eventDetails.bookingsCanBeCancelled" placement="bottom" ngbTooltip="{{ 'detailbooking.Ha expirado el tiempo de cancelación' | translate }}" tooltipClass="tooltip-info-shows">
                        <img class="ic-mid" src="assets/img/ic_info_outlined.svg">
                    </a>
                    <a *ngIf="stateConfirmed != book.state" placement="bottom" ngbTooltip="{{ 'detailbooking.Reserva cancelada' | translate }}" tooltipClass="tooltip-info-shows">
                        <img class="ic-mid" src="assets/img/ic_info_outlined.svg">
                    </a>
                    <!-- If cancel booking
                        <span class="tag-primary cancelled-booking">Cancelada</span> -->
                </td>
                <td class="pr-0 text-left">
                     <a *ngIf="stateConfirmed == book.state" (click)="onToggleBook(book.id)">
                          <img class="ic-mid" src="{{ booksExpanded.includes(book.id) ? 'assets/img/ic_expand-more.svg' : './assets/img/ic_expand.svg'}}">
                     </a>
                </td>
            </tr>
            <ng-container *ngIf="stateConfirmed == book.state && booksExpanded.includes(book.id)">
                <tr class="tr-collapse" *ngFor="let fare of book.fares">
                    <td colspan="3"></td>
                    <td *ngIf="fare.isChildren" class="text-left opacity-75">{{ 'detailbooking.Niños' | translate }}</td>
                    <td *ngIf="fare.isAdult" class="text-left opacity-75">{{ 'detailbooking.Adultos' | translate }}</td>
                    <td *ngIf="!fare.isChildren && !fare.isAdult" class="text-left opacity-75">{{ fare.name | I18NTranslationSave: fare.nameMain }}</td>
                    <td class="text-center">{{ fare.quantity }}</td>
                    <td class="text-center" [attr.colspan]="isGuideRole && eventDetails.showsCanBeUpdated ? 3: 1">
                         <a *ngIf="isGuideRole && eventDetails.showsCanBeUpdated" (click)="onDecreaseShowsForBooking(book, fare)" class="guide-booking-modify-show-action mr-3">
                             <img src="assets/img/ic_minus.svg">
                        </a>
                        <span class="shows-number">{{ fare.shows }}</span>
                        <a *ngIf="isGuideRole && eventDetails.showsCanBeUpdated" (click)="onIncreaseShowsForBooking(book, fare)" class="guide-booking-modify-show-action ml-3">
                             <img src="assets/img/ic_plus.svg">
                        </a>
                    </td>
                    <td class="px-0 text-center" [attr.colspan]="!isGuideRole || !eventDetails.showsCanBeUpdated ? 3: 1">
                        <a *ngIf="eventDetails.showsCanBeUpdated" (click)="onEditShows(book, fare)">
                            <img class="ic-mid" src="assets/img/ic_edit.svg">
                        </a>
                        <a *ngIf="!eventDetails.showsCanBeUpdated" placement="bottom" ngbTooltip="{{ 'detailbooking.Ha expirado el tiempo de edición' | translate }}" tooltipClass="tooltip-info-shows">
                            <img class="ic-mid" src="assets/img/ic_info_outlined.svg">
                        </a>
                    </td>
                </tr>
            </ng-container>
        </ng-container>

        <tr>

            <td></td>
            <td class="font-weight-bold text-uppercase text-left" scope="row" colspan="3">Total</td>
            <td class="font-weight-bold text-center">{{ eventDetails.people }}</td>
            <td class="font-weight-bold green-color text-center">{{ eventDetails.shows }}</td>
            <td></td>
            <td></td>
            <td></td>
        </tr>


    </tbody>
</table>