import { Component, OnInit, ViewChild } from '@angular/core';
import { MessagePanelComponent } from 'src/app/ui/components/shared/message-panel/message-panel.component';
import { ActivatedRoute, Router } from '@angular/router';
import { NavigationConstants } from 'src/app/ui/commons/navigation.constants';
import { marker as I18N } from '@biesbjerg/ngx-translate-extract-marker';
import { ServerErrorManager } from 'src/app/ui/components/utils/server-error-manager.utils';
import { GenericException } from 'src/app/domain/exceptions/generic.exception';
import { TourResume } from 'src/app/business/models/tour/tour-resume.model';
import { GuideServiceDetails } from 'src/app/business/models/billing/service-details.model';
import { ServiceEditionView } from 'src/app/domain/views/services/service-edition.view';
import { GuideServiceInteractor } from 'src/app/domain/interactor/guide/service.interactor';
import { GuideTourInteractor } from 'src/app/domain/interactor/guide/tour.interactor';
import { EventBasic } from 'src/app/business/models/book/event-basic.model';
import { ServiceTourEventFilter } from 'src/app/domain/views/services/service-tour-event-filter';

@Component({
     selector: 'app-guide-create-guide-service',
     templateUrl: './create.component.html'
})
export class GuideCreateGuideServiceComponent implements OnInit {

     @ViewChild(MessagePanelComponent)
     private messagePanelComponent: MessagePanelComponent;

     guideServiceDetails: GuideServiceDetails;
     tours: Array<TourResume>;
     events: Array<EventBasic>;

     constructor(
          private router: Router,
          private guideServiceInteractor: GuideServiceInteractor,
          private guideTourInteractor: GuideTourInteractor,
          private serverErrorManager: ServerErrorManager,
          private activatedRoute: ActivatedRoute
     ) {

               this.tours = new Array<TourResume>();
               this.events = new Array<EventBasic>();

               this.activatedRoute.params.subscribe( params => {
                    const guideServiceId = params[ NavigationConstants.NAVIGATION_GUIDE_SERVICE_ID ];
     
                    //Load service details if needed
                    if ( guideServiceId ) {
                         this.findGuideServiceAndReload(guideServiceId);
                    }
     
               });               

     }



     findGuideServiceAndReload(guideServiceId: number) {

          this.guideServiceInteractor.findGuideService(guideServiceId)
          .subscribe(
               (guideServiceDetails: GuideServiceDetails) => {

                   this.guideServiceDetails = guideServiceDetails;
                   this.onTourAndDateSelected(new ServiceTourEventFilter(this.guideServiceDetails.tourId, this.guideServiceDetails.day));
               },
               (error) => {
                    this.serverErrorManager.manageErrorCode(error, this.messagePanelComponent);
               }
          )
     }

     ngOnInit() {


          this.guideTourInteractor.findAllToursForManagement()
          .subscribe(
               (tours: Array<TourResume>) => {
                    this.tours = tours;
               },
               (error: GenericException) => {
                    this.serverErrorManager.manageErrorCode(error, this.messagePanelComponent);
               }
          );

     }

     onGuideServicesUpdated(serviceEditionView: ServiceEditionView) {

          this.messagePanelComponent.showLoading();

          this.guideServiceInteractor.saveGuideService(serviceEditionView.guideServiceId, serviceEditionView.tourId, serviceEditionView.tourName, serviceEditionView.date, serviceEditionView.hour, serviceEditionView.people, serviceEditionView.amount)
          .subscribe(
               () => {

                    MessagePanelComponent.hideLoading();
                    this.messagePanelComponent.showSuccessMessage(I18N('createcommission.Información guardada correctamente'), () => this.onGuideServicesNavigation());

               },
               (error: GenericException) => {

                    MessagePanelComponent.hideLoading();
                    this.serverErrorManager.manageErrorCode(error, this.messagePanelComponent);

               }
          )

     }


     onGuideServicesNavigation() {
          this.router.navigate( [ NavigationConstants.getNavigationGuideServices() ] );
     }



     onTourAndDateSelected(serviceTourEventFilter: ServiceTourEventFilter) {

          this.messagePanelComponent.showLoading();

          if ( serviceTourEventFilter == null || serviceTourEventFilter.day == null || serviceTourEventFilter.tourId == null ) {

               this.events = Array<EventBasic>();
               MessagePanelComponent.hideLoading();
               return;

          }

          this.guideTourInteractor.findEvents(serviceTourEventFilter.tourId, serviceTourEventFilter.day)
          .subscribe(
               (events: Array<EventBasic>) => {

                    this.events = events;
                    MessagePanelComponent.hideLoading();

               },
               (error: GenericException) => {

                    MessagePanelComponent.hideLoading();
                    this.serverErrorManager.manageErrorCode(error, this.messagePanelComponent);

               }
          )
     }


}