export class CompanyBillingDetailsView {

     private _companyId: number;
     private _companyName: string;

     constructor(
          companyId: number,
          companyName: string
     ) {

          this._companyId = companyId;
          this._companyName = companyName;

     }

     get companyId(): number {
          return this._companyId;
     }

     get companyName(): string {
          return this._companyName;
     }

}