import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { CommissionType } from 'src/app/business/models/billing/common/commission-type.constants';
import { CompanyName } from 'src/app/business/models/master/company/company-name.model';
import { TourResume } from 'src/app/business/models/tour/tour-resume.model';
import { NgbDate, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { CalendarUtils } from 'src/app/ui/utils/calendar.utils';
import { GuideServiceDetails } from 'src/app/business/models/billing/service-details.model';
import { ServiceEditionView } from 'src/app/domain/views/services/service-edition.view';
import { ServiceTourEventFilter } from 'src/app/domain/views/services/service-tour-event-filter';
import { EventBasic } from 'src/app/business/models/book/event-basic.model';

@Component({
     selector: 'app-create-guide-service',
     templateUrl: './create.component.html'
})
export class CreateGuideServiceComponent implements OnInit {

     @Output() guideServicesNavigation = new EventEmitter<boolean>();
     @Output() guideSelected = new EventEmitter<number>();
     @Output() providerSelected = new EventEmitter<number>();
     @Output() guideServicesUpdated = new EventEmitter<ServiceEditionView>();
     @Output() tourAndDateSelected = new EventEmitter<ServiceTourEventFilter>();
     

     @Input()
     set guideServiceDetails(guideServiceDetails: GuideServiceDetails) {
          if ( guideServiceDetails ) {
               this.createCommissionForm.get('id').setValue(guideServiceDetails.id);
               this.createCommissionForm.get('guideId').setValue(guideServiceDetails.guide.companyId);
               this.createCommissionForm.get('providerId').setValue(guideServiceDetails.provider.companyId);
               this.createCommissionForm.get('providerId').disable();
               
               this.createCommissionForm.get('date').setValue(CalendarUtils.fromDateDDMMYYYYToNgbDate(guideServiceDetails.day));
               this.dateFormatted = guideServiceDetails.day;
               
               this.createCommissionForm.get('people').setValue(guideServiceDetails.people);
               this.createCommissionForm.get('amount').setValue(guideServiceDetails.price);


               this.createCommissionForm.get('hour').setValue(guideServiceDetails.hour);
               this.createCommissionForm.get('hourAsString').setValue(guideServiceDetails.hour);
               
               

               if ( guideServiceDetails.tourId != null ) {
                    this.createCommissionForm.get('tourId').setValue(guideServiceDetails.tourId);
                    this.onTourSelected();
               } else {
                    this.createCommissionForm.get('tourName').setValue(guideServiceDetails.tourNameTranslated);
                    this.externalTour = true;
                    this.onAddExternalTour();
               }
               
               this.isEdition = true;

          }
     }


     
     @Input() 
     set tours(tours: Array<TourResume>) {
          this.toursManaged = tours;
          this.onTourSelected();
     }

     @Input() guides: Array<CompanyName>;


     @Input() 
     set events(events: Array<EventBasic>) {

          this.eventsList = events;

          if ( events && events.length > 0 ) {
               this.createCommissionForm.get('hour').setValidators([Validators.required]);
               this.createCommissionForm.get('hourAsString').setValidators([]);

               let hasCurrenthour: boolean = false;
               let hour = this.createCommissionForm.get('hour').value;
               events.forEach( (eventBasic: EventBasic) => {
                    if ( hour == eventBasic.hour ) {
                         hasCurrenthour = true;
                    }
               });

               if ( !hasCurrenthour ) {
                    this.createCommissionForm.get('hour').setValue(null);
               }

          } else {
               this.createCommissionForm.get('hourAsString').setValidators([Validators.required]);
               this.createCommissionForm.get('hour').setValidators([]);
          }

          this.createCommissionForm.get('hour').updateValueAndValidity();    
          this.createCommissionForm.get('hourAsString').updateValueAndValidity();
               
     }


     @Input() 
     set providers(providers: Array<CompanyName>) {
          if ( providers ) {
               this.providersList = providers;
               this.createCommissionForm.get('providerId').setValidators([Validators.required]);
               this.createCommissionForm.get('providerId').updateValueAndValidity();               
          }
     }

     @Input() 
     set isGuide(isGuide: boolean) {
          if ( isGuide ) {
               this.createCommissionForm.get('guideId').setValidators([]);               
          }
     }
     

     toursManaged: Array<TourResume>;
     providersList: Array<CompanyName>;
     eventsList: Array<EventBasic>;

     date: NgbDate;
     dateFormatted: string;

     createCommissionForm: FormGroup;
     refreshValidators: boolean;

     isEdition: boolean;

     ifFreeTourService: boolean;
     externalTour: boolean;

     constructor(private formatter: NgbDateParserFormatter ) {

               this.refreshValidators = false;
               this.isEdition = false;

               this.ifFreeTourService = true;

               this.toursManaged = new Array<TourResume>();

               this.createCommissionForm = new FormGroup({
                    'id': new FormControl('', [] ),
                    'guideId': new FormControl(null, [Validators.required] ),
                    'providerId': new FormControl(null, [] ),
                    'tourId': new FormControl(null, [Validators.required] ),
                    'tourName': new FormControl(null, [] ),
                    'date': new FormControl('', [Validators.required] ),
                    'hour': new FormControl(null, [Validators.required] ),
                    'hourAsString': new FormControl(null, [Validators.required] ),                    
                    'people': new FormControl('', [Validators.required, Validators.min(1), Validators.pattern("^[0-9]*$")] ),
                    'amount': new FormControl('', [Validators.min(0)] )
               });


     }

     ngOnInit() {
          
     }


     createCommission() {

          this.refreshValidators = true;

          if ( this.createCommissionForm.valid ) {

               let guideServiceId = this.createCommissionForm.get('id').value;
               if ( guideServiceId == '' ) {
                    guideServiceId = null;
               }
               const guideId = this.createCommissionForm.get('guideId').value;
               const tourId = this.createCommissionForm.get('tourId').value;
               const tourName = this.createCommissionForm.get('tourName').value;
               
               const dateInputHtml = this.createCommissionForm.get('date').value;
               const date: Date = CalendarUtils.fromDateHtmlInputToDate(this.formatter.format(dateInputHtml));       
               
               const people = this.createCommissionForm.get('people').value;
               const amount = this.createCommissionForm.get('amount').value;

               const hour = this.createCommissionForm.get('hour').value ? this.createCommissionForm.get('hour').value : this.createCommissionForm.get('hourAsString').value

               this.guideServicesUpdated.emit(new ServiceEditionView(guideServiceId, guideId, tourId, tourName, date, hour, people, amount));

          }


     }



     onProviderSelected() {
          this.createCommissionForm.get('guideId').setValue(null);
          this.providerSelected.emit(this.createCommissionForm.get('providerId').value);
     }


     onGuideSelected() {
          this.createCommissionForm.get('tourId').setValue(null);
          this.guideSelected.emit(this.createCommissionForm.get('guideId').value);
     }


     onTourSelected() {

          this.ifFreeTourService = false;

          this.createCommissionForm.get('amount').setValidators(null);
          this.createCommissionForm.get('amount').setValidators([Validators.required, Validators.min(0)]);
          this.createCommissionForm.get('amount').updateValueAndValidity();

          const tourId = this.createCommissionForm.get('tourId').value;          
          if ( tourId != null )  {
               this.toursManaged.map( (tourResume: TourResume) => {
                    if ( tourId == tourResume.id ) {
                         this.ifFreeTourService = tourResume.isFree;  
                         
                         if ( this.ifFreeTourService ) {
                              this.createCommissionForm.get('amount').setValidators(null);
                              this.createCommissionForm.get('amount').setValidators([Validators.min(0)]);
                              this.createCommissionForm.get('amount').updateValueAndValidity();
                         }
                    }
               })
          }


          this.onTourAndDateSelected();

     }


     onTourAndDateSelected() {
          const tourId = this.createCommissionForm.get('tourId').value;
          this.tourAndDateSelected.emit(new ServiceTourEventFilter(tourId, this.dateFormatted));
     }



     getGuideCommissionTypes(): Array<CommissionType> {
          const types = new Array<CommissionType>();
          types.push(CommissionType.People);
          types.push(CommissionType.Total);
 
          return types;
     }


     goToGuideServices() {
          this.guideServicesNavigation.emit(true);
     }



     onAddExternalTour() {
          this.externalTour = true;
          this.createCommissionForm.get('tourId').setValue(null);
          this.createCommissionForm.get('tourId').setValidators(null);
          this.createCommissionForm.get('tourId').updateValueAndValidity();
          this.createCommissionForm.get('tourName').setValidators([Validators.required]);
          this.createCommissionForm.get('tourName').updateValueAndValidity();
          this.onTourSelected();
     }

     onAddManagedTour() {
          this.externalTour = false;
          this.createCommissionForm.get('tourName').setValidators(null);
          this.createCommissionForm.get('tourName').updateValueAndValidity();
          this.createCommissionForm.get('tourId').setValidators([Validators.required]);
          this.createCommissionForm.get('tourId').updateValueAndValidity();
     }


     onDateSelection(date: NgbDate) {

          this.dateFormatted = null;
          if ( date != null ) {
               const currentDate: Date = CalendarUtils.fromDateHtmlInputToDate(this.formatter.format(date));               
               this.dateFormatted = CalendarUtils.fromDateToDaleLocalizedDDMMYYYY(currentDate);
          } 

          this.onTourAndDateSelected();
     }


     onDateChanged() {

          const dateInputHtml = this.createCommissionForm.get('date').value;
          
          this.dateFormatted = null;
          if ( dateInputHtml != null ) {
               const currentDate: Date = CalendarUtils.fromDateHtmlInputToDate(this.formatter.format(dateInputHtml));               
               this.dateFormatted = CalendarUtils.fromDateToDaleLocalizedDDMMYYYY(currentDate);
          }

          this.onTourAndDateSelected();
     }


     onHourChanged() {
          this.createCommissionForm.get('hourAsString').setValue(null);
     }

     onHourAsStringChanged() {
          this.createCommissionForm.get('hour').setValue(null);
     }
     

}