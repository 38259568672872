import { Component, ViewChild, Input } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TourResumeAdmin } from 'src/app/business/models/tour/tour-resume-admin.model';
import { AdminTourInteractor } from 'src/app/domain/interactor/admin/tour.interactor';
import { MessagePanelComponent } from 'src/app/ui/components/shared/message-panel/message-panel.component';
import { ServerErrorManager } from 'src/app/ui/components/utils/server-error-manager.utils';

import { marker as I18N }               from '@biesbjerg/ngx-translate-extract-marker';
import { GenericException } from 'src/app/domain/exceptions/generic.exception';
import { ProviderTourInteractor } from 'src/app/domain/interactor/provider/tour.interactor';


@Component({
  selector: 'app-provider-edit-tour-guide-service-fee-modal-component',
  templateUrl: './edit-fee-guide-service-modal.html'
})

export class ProviderEditTourGuideServiceFeeModalComponent {

  @ViewChild(MessagePanelComponent, { static: false })
  private messagePanelComponent: MessagePanelComponent;

  serviceFeeForm: FormGroup;
  refreshValidators: boolean;


  @Input()
  set tourResume(tourResume: TourResumeAdmin) {
    if (tourResume) {
      this.serviceFeeForm.get('tourId').setValue(tourResume.id);

      if ( tourResume.guideSalesCommission != null ) {
           this.serviceFeeForm.get('fee').setValue(tourResume.guideServiceFee);
      }
    }
  }



  constructor(
    private providerTourInteractor: ProviderTourInteractor,
    private serverErrorManager: ServerErrorManager,
    private modal: NgbActiveModal) {

    this.refreshValidators = false;

    this.serviceFeeForm = new FormGroup({
      'tourId': new FormControl(''),
      'fee': new FormControl('', [Validators.min(0)]),
    })

  }

  ngOnInit() {
  }


  closeModal() {
    this.modal.close();
  }


  saveFee() {
    this.refreshValidators = true;

    if (this.serviceFeeForm.valid) {

      this.messagePanelComponent.showLoading();

      const tourId: number = this.serviceFeeForm.get('tourId').value;
      const fee: number = this.serviceFeeForm.get('fee').value;

      this.providerTourInteractor.updateTourGuideServiceFee(tourId, fee)
        .subscribe(
          () => {

            MessagePanelComponent.hideLoading();
            this.messagePanelComponent.showSuccessMessage(I18N('editcommission.Información actualizada'));
            this.modal.close(fee);

          },
          (error: GenericException) => {

            MessagePanelComponent.hideLoading();
            this.serverErrorManager.manageErrorCode(error, this.messagePanelComponent);

          }
        )

    }

  }


}




