import { CompanyBillingDetailsView } from 'src/app/domain/views/billing/company-billing-details.view';
import { InvoiceDetails } from './invoice-details.model';
import { InvoiceIssuer } from './invoice-issuer.model';

export class InvoiceFullDetails {

  private _issuer: InvoiceIssuer;
  private _invoiceDetails: InvoiceDetails;
  private _company: CompanyBillingDetailsView;


  constructor(
    issuer: InvoiceIssuer,
    invoiceDetails: InvoiceDetails,
    company: CompanyBillingDetailsView
  ) {
    this._issuer = issuer;
    this._invoiceDetails = invoiceDetails;
    this._company = company;
  }

  get issuer(): InvoiceIssuer {
    return this._issuer;
  }

  get invoiceDetails(): InvoiceDetails {
    return this._invoiceDetails;
  }

  get company(): CompanyBillingDetailsView {
    return this._company;
  }


}
