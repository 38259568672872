import { Pipe, PipeTransform } from '@angular/core';
import { marker as I18N } from '@biesbjerg/ngx-translate-extract-marker';

@Pipe({
  name: 'i18NMonthFromNumber'
})
export class  I18NMonthFromNumberPipe implements PipeTransform {

  transform(month: number): string {

      switch ( month ) {
          case 1:
                return I18N('common.month.enero');
          case 2:
               return I18N('common.month.febrero');
          case 3:
               return I18N('common.month.marzo');
          case 4:
               return I18N('common.month.abril');
          case 5:
               return I18N('common.month.mayo');
          case 6:
               return I18N('common.month.junio');
          case 7:
               return I18N('common.month.julio');
          case 8:
               return I18N('common.month.agosto');
          case 9:
               return I18N('common.month.septiembre');
          case 10:
               return I18N('common.month.octubre');
          case 11:
               return I18N('common.month.noviembre');
          case 12:
               return I18N('common.month.diciembre');
          default:
               return I18N('common.month.enero');
      }

  }

}
