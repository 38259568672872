import { TourSchedulePlanningFormView } from './schedule-planning-form.view';

export class TourScheduleSeasonFormView {


    private _startDay: string;
    private _finishDay: string;
    private _details: TourSchedulePlanningFormView;

    constructor(
      startDay: string,
      finishDay: string,
      details: TourSchedulePlanningFormView
    ) {

        this._startDay = startDay;
        this._finishDay = finishDay;
        this._details = details;

    }


    get startDay(): string {
        return this._startDay;
    }

    get finishDay(): string {
        return this._finishDay;
    }

    get details(): TourSchedulePlanningFormView {
        return this._details;
    }

}