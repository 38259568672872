import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { loadStripe, Stripe, StripeCardElement } from '@stripe/stripe-js';
import { marker as I18N } from '@biesbjerg/ngx-translate-extract-marker';
import { Observable, Observer } from 'rxjs';


@Injectable({
  providedIn: 'root'
})

export class StripeManager {

  private readonly STRIPE_API_KEY = environment.STRIPE_API_KEY;

  stripe: Stripe;


  cardHtmlId = 'card-element';


  cardStyle = {
    base: {
      color: "#212121",
      iconColor: '#424242',
      fontWeight: '500',
      fontFamily: 'Montserrat, sans-serif',
      fontSize: '16px',
      fontSmoothing: 'antialiased',
      '::placeholder': {
        color: '#42424250',
      },
    },
    invalid: {
      iconColor: '#F50658',
      color: '#F50658',
    },
  };

  constructor() {
  }




  existStripeComponent(): Observable<boolean> {


      return Observable.create((observer: Observer<boolean>) => {

           const cardHtmlIdForMutaion = this.cardHtmlId;

           var mutationObserver = new MutationObserver(function(mutations) {

              if (document.getElementById(cardHtmlIdForMutaion)) {

                   mutationObserver.disconnect();

                   observer.next(true);
                   observer.complete();

               }
           });

           mutationObserver.observe(document, {attributes: true, childList: true, characterData: true, subtree:true});

      });

  }


  async createCard(onChangeSuccess: () => void, onChangeError: (errorMessage: string) => void): Promise<StripeCardElement> {

    this.stripe = await loadStripe(this.STRIPE_API_KEY);

    const elements = this.stripe.elements();

    const card = elements.create('card', { style: this.cardStyle });
    card.on('change', ({ error }) => {
      if (error) {
        onChangeError(error.message);
      } else {
        onChangeSuccess();
      }

    });

    card.mount('#' + this.cardHtmlId);

    return card;

  }

  createToken(card: StripeCardElement) {
    this.stripe.createToken(card);
  }


  confirmCardPayment(stripeClientSecret: string,
    card: StripeCardElement,
    email: string,
    onPaymentSuccess: (transactionId: string) => void,
    onPaymentError: (errorMessage: string) => void) {

    try {
      this.stripe.confirmCardPayment(stripeClientSecret, {

        payment_method: {
          card: card,
          billing_details: {
            email: email
          }
        }

      }).then(function(result) {
        if (result.error) {

          /*
          Error types:
          card_error
          api_connection_error
          api_error
          authentication_error
          idempotency_error
          invalid_request_error
          rate_limit_error
          */

          let errorMessage: string = I18N('stripe.No hemos podido completar la transacción.');
          if (result.error.type === 'card_error') {
            errorMessage = I18N('stripe.La tarjeta no es válida o no permite la operación.')
          } else if (result.error.type === 'rate_limit_error') {
            errorMessage = I18N('stripe.Se ha superado el límite de intentos. Por favor, pruebe más tarde.')
          } else if (result.error.type === 'validation_error') {
            errorMessage = I18N('stripe.Los datos de la tarjeta no son correctos.')
          }

          onPaymentError(errorMessage);

        } else {

          // The payment has been processed!
          if (result.paymentIntent.status === 'succeeded') {

            onPaymentSuccess(result.paymentIntent.id);

          } else if (result.paymentIntent.status === 'requires_action') {

            onPaymentError(I18N('stripe.No hemos podido completar la transacción. Por favor, revisa la información de tu tarjeta.'));

          } else if (result.paymentIntent.status === 'requires_payment_method') {

            onPaymentError(I18N('stripe.No hemos podido completar la transacción. Por favor, revisa la información de tu tarjeta.'));

          } else if (result.paymentIntent.status === 'processing') {

            onPaymentError(I18N('stripe.Tu entidad bancaria necesita procesar el pedido. Te notificaremos por email cuando haya sido aprobado.'));

          }

        }
      });
    } catch (error) {
      console.log(error);
      onPaymentError(I18N('stripe.No hemos podido iniciar el proceso de pago. Por favor, inténtalo de nuevo más tarde.'));
    }

  }

}