<div class="animated fadeIn" *ngIf="reviewsReport">
    <div class="row header-dashboard align-items-lg-center align-items-start">
        <div class="col-12 col-sm-8 col-lg-6 col-xl-6 pl-0 order-1 order-lg-1">
            <h2 class="font-weight-bold">{{ 'reviews.Reviews' | translate }}</h2>
            <p>{{ 'reviews.Listado de reviews' | translate }}</p>
        </div>
    </div>

    <div class="row dashboard-content-section mb-5 mb-md-auto">
        <div class="col-12 col-lg-6 col-xl-7">
            <div class="row">
                <div class="col-12 text-left pl-md-0 pr-md-3 px-0">
                    <h2 class="font-weight-bold mb-4">{{ 'review.Filtros' | translate }}</h2>
                </div>
            </div>
            <div class="row">
                <form class="form-boxes d-block d-md-flex m-0 p-0">
                    <div class="col-12 col-md-6 col-lg-5 pl-md-0 pr-md-3 px-0">
                        <div class="field pb-2">
                             <select [(ngModel)]="providerId" name="provider" (change)="onProviderSelected()">
                                <option [ngValue]="null" selected>{{ 'review.Selecciona la opción' | translate }}</option>
                                <option *ngFor="let provider of providers" value="{{ provider.id }}">{{ provider.companyName }}</option>
                            </select>
                            <label for="provider">{{ 'review.Proveedor' | translate }}</label>
                        </div>
                    </div>
                    <div class="col-12 col-md-6 col-lg-5 px-0 px-md-auto">
                        <div class="field pb-2">
                             <select [(ngModel)]="tourId" name="tour" (change)="onTourSelected()">
                                <option [ngValue]="null" selected>{{ 'review.Selecciona la opción' | translate }}</option>
                                <option *ngFor="let tour of tours" value="{{ tour.id }}">{{ tour.name | I18NTranslationSave: tour.nameMain }}</option>
                            </select>
                            <label for="city">{{ 'review.Tour' | translate }}</label>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <div class="col-12 col-lg-6 col-xl-5 mt-auto px-0 px-lg-auto">
            <div class="card card-review">
                <div class="row">
                    <div class="col-6 d-grid">
                         <h2 class="font-weight-bold">{{ reviewsReport.travelers }}</h2>
                         <p class="grey-color font-weight-semibold mb-0">{{ 'review.Total viajeros' | translate }}</p>
                    </div>
                    <div class="col-6 d-block text-right">
                        <div class="d-flex justify-content-end">
                             <h4 class="mr-3 font-weight-bold mb-0">{{ reviewsReport.averageRating | number: '1.0-2' }}</h4>
                            <div class="rating rating-small align-items-center no-hoverable">
                                <!--elements are in reversed order, to allow "previous sibling selectors" in CSS-->
                                <input type="radio" name="rating" value="5" id="5" disabled [checked]="reviewsReport.averageRating > 4.5"><label for="5"><img src="assets/img/ic_star.svg"></label>
                                <input type="radio" name="rating" value="4" id="4" disabled [checked]="reviewsReport.averageRating > 3.5 && reviewsReport.averageRating < 4.5"><label for="4"><img src="assets/img/ic_star.svg"></label>
                                <input type="radio" name="rating" value="3" id="3" disabled [checked]="reviewsReport.averageRating > 2.5 && reviewsReport.averageRating < 3.5"><label for="3"><img src="assets/img/ic_star.svg"></label>
                                <input type="radio" name="rating" value="2" id="2" disabled [checked]="reviewsReport.averageRating > 1.5 && reviewsReport.averageRating < 2.5"><label for="2"><img src="assets/img/ic_star.svg"></label>
                                <input type="radio" name="rating" value="1" id="1" disabled [checked]="reviewsReport.averageRating > 0 && reviewsReport.averageRating < 1.5"><label for="1"><img src="assets/img/ic_star.svg"></label>
                            </div>
                        </div>
                        <p class="grey-color font-weight-semibold mb-0">{{ this.reviewsReport.totalReviews }} {{ 'review.opiniones' | translate }} </p>
                    </div>
                </div>
            </div>
        </div>
    </div>


    <div class="row empty-section" *ngIf="noResults">
        <div class="col-12 text-center my-auto">
            <img class="logo mb-4" src="assets/img/logo_opacity-21.png">
            <p>{{ 'review.No se han encontrado resultados' | translate }}</p>
        </div>
    </div>

    <!-- *ngIf="!noResults" -->
    <div class="row dashboard-content-section"  *ngIf="!noResults">

         <div *ngFor="let review of reviewsReport.reviewsChunk.items | paginate: pagination;" class="col-12 card card-list">
            <div class="row padding-lg-inside">
                <div class="col-12 px-auto px-lg-0 padding-xs-inside">
                    <div class="row">
                        <div class="col-12 col-md-6 mt-0 mt-lg-2">
                             <h5 class="font-weight-bold primary-color">{{ review.tourResume.name | I18NTranslationSave: review.tourResume.nameMain }}</h5>
                             <h5 class="font-weight-bold mt-3">{{ review.name }}</h5>
                             <p class="grey-color">{{ review.tourResume.city.name }}, {{ review.tourResume.country.name }}</p>
                        </div>

                        <div class="col-12 col-md-6 text-left text-md-right">
                            <div class="rating rating-small">
                                <!--elements are in reversed order, to allow "previous sibling selectors" in CSS-->
                                <input type="radio" [name]="'rating' + review.id " value="5" id="5" disabled [checked]="review.rating == 5"><label for="5"><img src="assets/img/ic_star.svg"></label>
                                <input type="radio" [name]="'rating' + review.id " value="4" id="4" disabled [checked]="review.rating == 4"><label for="4"><img src="assets/img/ic_star.svg"></label>
                                <input type="radio" [name]="'rating' + review.id " value="3" id="3" disabled [checked]="review.rating == 3"><label for="3"><img src="assets/img/ic_star.svg"></label>
                                <input type="radio" [name]="'rating' + review.id " value="2" id="2" disabled [checked]="review.rating == 2"><label for="2"><img src="assets/img/ic_star.svg"></label>
                                <input type="radio" [name]="'rating' + review.id " value="1" id="1" disabled [checked]="review.rating == 1"><label for="1"><img src="assets/img/ic_star.svg"></label>
                            </div>
                            <p class="grey-color text-body-small mt-2">{{ review.tourResume.providerName }}</p>
                       </div>

                    </div>
                    <div class="row">
                        <div class="col-12">
                            <p class="text-body-mid">
                                 {{ review.comments }}
                            </p>
                        </div>
                    </div>
                    <hr class="divider-secondary mx-auto">
                    <div class="row">
                        <div class="col-auto">
                            <p class="grey-color">
                                 <a (click)="onEditReview(review)"><img class="ic-mid-xs mr-1" src="assets/img/ic_edit-grey.svg">{{ 'review.Editar' | translate }}</a>
                            </p>
                        </div>

                        <!--
                        <div class="col-auto">
                            <p class="grey-color">
                                <a><img class="ic-mid-xs mr-1" src="assets/img/ic_remove-grey.svg">{{ 'review.Eliminar' | translate }}</a>
                            </p>
                        </div>
                        -->
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12">
             <div class="row bg-grey-mid-color" *ngIf="!noResults">
                 <div class="col-12 d-flex justify-content-center">
                      <pagination-controls [maxSize]="pagination.itemsPerPage" [directionLinks]="true" [autoHide]="true" [responsive]="true" [previousLabel]="paginationPreviousLabel" [nextLabel]="paginationNextLabel" (pageChange)="onPageChanged($event)"></pagination-controls>
                 </div>
             </div>
        </div>

    </div>


    <app-message-panel [preloading]="true"></app-message-panel>


</div>