import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';

import { MessagePanelComponent } from 'src/app/ui/components/shared/message-panel/message-panel.component';
import { ServerErrorManager } from 'src/app/ui/components/utils/server-error-manager.utils';
import { CalendarUtils } from 'src/app/ui/utils/calendar.utils';
import { BookEvent } from 'src/app/business/models/book/book-event.model';
import { Pagination } from 'src/app/domain/views/pagination.view';
import { Country } from 'src/app/business/models/master/country/country.model';
import { CalendarView, CalendarMonthViewDay } from 'angular-calendar';
import { AppConstants } from 'src/app/domain/common/app.constants';
import { SessionInteractor } from 'src/app/domain/interactor/session.interactor';
import { City } from 'src/app/business/models/master/city/city.model';
import { GenericException } from 'src/app/domain/exceptions/generic.exception';
import { UserMasterInteractor } from 'src/app/domain/interactor/user/user-master.interactor';

import { TranslateService } from '@ngx-translate/core';
import { marker as I18N } from '@biesbjerg/ngx-translate-extract-marker';
import { CompanyName } from 'src/app/business/models/master/company/company-name.model';


@Component({
     selector: 'app-list-booking',
     templateUrl: './list.component.html'
})
export class ListBookingComponent implements OnInit {

     @ViewChild(MessagePanelComponent)
     private messagePanelComponent: MessagePanelComponent;

     @Input() pagination: Pagination;
     @Input() bookEvents: Array<BookEvent>;
     @Input() noResults: boolean;
     @Input() providerNames: Array<CompanyName>;
     @Input() adminRole: boolean;
     @Input() guideRole: boolean;


     @Output() dateAsStringSelected = new EventEmitter<string>();
     @Output() countryIdSelected = new EventEmitter<number>();
     @Output() cityIdSelected = new EventEmitter<number>();
     @Output() tourIdSelected = new EventEmitter<number>();
     @Output() providerIdSelected = new EventEmitter<number>();
     @Output() pageNumberSelected = new EventEmitter<number>();
     @Output() forceReload = new EventEmitter<boolean>();
     @Output() viewBookDetails = new EventEmitter<BookEvent>();
     @Output() allowBooking = new EventEmitter<BookEvent>();
     @Output() disallowBooking = new EventEmitter<BookEvent>();
     @Output() showToursForToday = new EventEmitter<boolean>();
     @Output() newBooking = new EventEmitter<BookEvent>();
     @Output() showUpdateMaxBookings = new EventEmitter<BookEvent>();
     @Output() newBookingForNewTour = new EventEmitter<boolean>();



     countries: Array<Country>;
     cities: Array<City>;

     pageNumber: number;

     dateAsString: string;
     countryId: number;
     cityId: number;
     tourId: number;
     providerId: number;

     viewDate: Date;
     currentLocale: string;
     weekStartOnDay: number;
     view: CalendarView;

     currentSelectedViewDay: CalendarMonthViewDay;
     lastCountryIdSelected: number;
     lastCityIdSelected: number;

     paginationNextLabel: string;
     paginationPreviousLabel: string;

     constructor(
          private userMasterInteractor: UserMasterInteractor,
          private translateService: TranslateService,
          private sessionInteractor: SessionInteractor,
          private serverErrorManager: ServerErrorManager) {

          const currentDate = CalendarUtils.getCurrentDate();
          this.dateAsString = CalendarUtils.fromDateToDaleLocalizedDDMMYYYY(currentDate);

          this.countryId = null;
          this.cityId = null;
          this.tourId = null;
          this.providerId = null;


          this.viewDate = new Date();
          this.view = CalendarView.Month;
          this.weekStartOnDay = AppConstants.CALENDAR_WEEK_START_ON_DAY;
          this.currentLocale = this.sessionInteractor.getCurrentLanguage();

     }

     ngOnInit() {

          this.dateAsStringSelected.emit(this.dateAsString);

          this.userMasterInteractor.getCountriesByUser()
          .subscribe(
               (countries: Array<Country>) => {
                    this.countries = countries;
               },
               (error: GenericException) => {
                    this.serverErrorManager.manageErrorCode(error, this.messagePanelComponent);
               }
          );

          this.translateService.get(I18N('pagination.Siguiente')).subscribe(
               (messageTranslated: string) => {
                    this.paginationNextLabel = messageTranslated;
               }
          )

          this.translateService.get(I18N('pagination.Previous')).subscribe(
               (messageTranslated: string) => {
                    this.paginationPreviousLabel = messageTranslated;
               }
          )

     }



     onViewBookDetails(bookEvent: BookEvent) {

          this.viewBookDetails.emit(bookEvent);

     }


     onDateCalendarSelected(event: any) {

          if (this.currentSelectedViewDay) {
               this.currentSelectedViewDay.cssClass = '';
          }

          this.currentSelectedViewDay = event.day;
          this.currentSelectedViewDay.cssClass = 'cal-day-selected';

          this.dateAsString = CalendarUtils.fromDateToDaleLocalizedDDMMYYYY(event.day.date);
          this.dateAsStringSelected.emit(this.dateAsString);


     }

     onCountrySelected() {
          if (this.lastCountryIdSelected != this.countryId) {

               this.lastCountryIdSelected = this.countryId;
               this.cityId = null;
               this.lastCityIdSelected = null;

               if (this.countryId) {
                    this.userMasterInteractor.getCitiesByUser(this.countryId)
                    .subscribe(
                         (cities: Array<City>) => {
                              this.cities = cities;
                         }, (error: GenericException) => {
                              this.serverErrorManager.manageErrorCode(error, this.messagePanelComponent);
                         }
                    )
               } else {
                    this.cities = new Array<City>();
               }

               this.countryIdSelected.emit(this.countryId);

          }
     }

     onCitySelected() {
          if (this.lastCityIdSelected != this.cityId) {

               this.lastCityIdSelected = this.cityId;

               this.cityIdSelected.emit(this.cityId);
          }
     }

     onPageChanged(currentPage: number) {
          this.pageNumber = currentPage;
          this.pageNumberSelected.emit(this.pageNumber);
     }


     onProviderSelected() {
          this.providerIdSelected.emit(this.providerId);
     }



     onBeforeCalendarRender({ body }: { body: CalendarMonthViewDay[] }): void {
          const currentDate = CalendarUtils.fromDateToDaleLocalizedDDMMYYYY(this.viewDate);
          body.forEach(day => {
               if (CalendarUtils.fromDateToDaleLocalizedDDMMYYYY(day.date) == currentDate) {
                    this.currentSelectedViewDay = day;
                    this.currentSelectedViewDay.cssClass = 'cal-day-selected';
               }
          });
     }

     onAllowBookings(bookEvent: BookEvent) {
          this.allowBooking.emit(bookEvent);

     }
     onDisallowBookings(bookEvent: BookEvent) {
          this.disallowBooking.emit(bookEvent);
     }

     onToursForToday(isToday: boolean) {
          this.showToursForToday.emit(isToday);
     }

     onNewBooking(bookEvent: BookEvent) {
          this.newBooking.emit(bookEvent);
     }

     onNewBookingForNewTour() {
          this.newBookingForNewTour.emit(true);
     }

     onShowUpdateMaxBookings(bookEvent: BookEvent) {
          this.showUpdateMaxBookings.emit(bookEvent);
     }

}
