import { Injectable }                       from '@angular/core';
import { Observable, Observer } from 'rxjs';
import { ChunkGeneric } from '../../views/chunk-generic.view';
import { GenericException } from '../../exceptions/generic.exception';
import { AdminAffiliateService } from 'src/app/business/services/admin/affiliate.service';
import { AffiliateResume } from 'src/app/business/models/affiliate/affiliate-resume.model';
import { Affiliate } from 'src/app/business/models/affiliate/affiliate.model';
import { AffiliateDetailsView } from '../../views/affiliate/affiliate-details.view';
import { CompanyName } from 'src/app/business/models/master/company/company-name.model';


@Injectable({
  providedIn: 'root'
})
export class AdminAffiliateInteractor {

    constructor(private adminAffiliateService: AdminAffiliateService) {
    }


    createAffiliate(companyName: string, email: string, languageId: number, cityId: number): Observable<boolean> {

        return Observable.create((observer: Observer<boolean>) => {
            this.adminAffiliateService.createAffiliate(companyName, email, languageId, cityId)
                  .subscribe(
                      (success: boolean) => {

                          observer.next(success);
                          observer.complete();
                      },
                      (error: GenericException) => {
                          observer.error(error);
                      }
                  )

        });
    }


    findAffiliates( page: number ): Observable<ChunkGeneric<AffiliateResume>> {

        return Observable.create((observer: Observer<ChunkGeneric<AffiliateResume>>) => {
            this.adminAffiliateService.findAffiliates(page)
                  .subscribe(
                      (chunkResult: ChunkGeneric<AffiliateResume>) => {

                          observer.next(chunkResult);
                          observer.complete();
                      },
                      (error: GenericException) => {
                          observer.error(error);
                      }
                  )

        });
    }



    findAllAffiliates(): Observable<Array<AffiliateResume>> {

        return Observable.create((observer: Observer<Array<AffiliateResume>>) => {
            this.adminAffiliateService.findAllAffiliates()
                  .subscribe(
                      (affiliates: Array<AffiliateResume>) => {

                          observer.next(affiliates);
                          observer.complete();
                      },
                      (error: GenericException) => {
                          observer.error(error);
                      }
                  )

        });
    }


    getAffiliatesNames(): Observable<Array<CompanyName>> {

        return Observable.create((observer: Observer<Array<CompanyName>>) => {
            this.adminAffiliateService.getAffiliatesNames()
                  .subscribe(
                      (affiliates: Array<CompanyName>) => {

                          observer.next(affiliates);
                          observer.complete();
                      },
                      (error: GenericException) => {
                          observer.error(error);
                      }
                  )

        });
    }


    getAffiliateDetails( affiliateId: number ): Observable<Affiliate> {

        return Observable.create((observer: Observer<Affiliate>) => {
            this.adminAffiliateService.getAffiliateDetalis(affiliateId)
                  .subscribe(
                      (affiliate: Affiliate) => {
                          observer.next(affiliate);
                          observer.complete();
                      },
                      (error: GenericException) => {
                          observer.error(error);
                      }
                  )

        });
    }



    resendWelcomeEmail( providerId: number ): Observable<boolean> {

        return Observable.create((observer: Observer<boolean>) => {
            this.adminAffiliateService.resendWelcomeEmail(providerId)
                  .subscribe(
                      () => {
                          observer.next(true);
                          observer.complete();
                      },
                      (error: GenericException) => {
                          observer.error(error);
                      }
                  )

        });
    }



    updateAffiliateDetails(affiliteDetails: AffiliateDetailsView): Observable<boolean> {

        return Observable.create((observer: Observer<boolean>) => {
            this.adminAffiliateService.updateAffiliateDetails(
                              affiliteDetails.id, affiliteDetails.companyName,
                              affiliteDetails.websites, affiliteDetails.phone, affiliteDetails.country,
                              affiliteDetails.city, affiliteDetails.address, affiliteDetails.businessDocumentType,
                              affiliteDetails.businessDocument, affiliteDetails.businessZeroVat)
                  .subscribe(
                      () => {
                          observer.next(true);
                          observer.complete();
                      },
                      (error: GenericException) => {
                          observer.error(error);
                      }
                  )

        });
    }


    confirmAffiliate(providerId: number): Observable<boolean> {

        return Observable.create((observer: Observer<boolean>) => {
            this.adminAffiliateService.confirmAffiliate(providerId)
                  .subscribe(
                      () => {
                          observer.next(true);
                          observer.complete();
                      },
                      (error: GenericException) => {
                          observer.error(error);
                      }
                  )

        });
    }


    rejectAffiliate(providerId: number, message: string): Observable<boolean> {

        return Observable.create((observer: Observer<boolean>) => {
            this.adminAffiliateService.rejectAffiliate(providerId, message)
                  .subscribe(
                      () => {
                          observer.next(true);
                          observer.complete();
                      },
                      (error: GenericException) => {
                          observer.error(error);
                      }
                  )

        });
    }



    unregisterAffiliate(providerId: number): Observable<boolean> {

        return Observable.create((observer: Observer<boolean>) => {
            this.adminAffiliateService.unregisterAffiliate(providerId)
                  .subscribe(
                      () => {
                          observer.next(true);
                          observer.complete();
                      },
                      (error: GenericException) => {
                          observer.error(error);
                      }
                  )

        });
    }


    registerAffiliate(providerId: number): Observable<boolean> {

        return Observable.create((observer: Observer<boolean>) => {
            this.adminAffiliateService.registerAffiliate(providerId)
                  .subscribe(
                      () => {
                          observer.next(true);
                          observer.complete();
                      },
                      (error: GenericException) => {
                          observer.error(error);
                      }
                  )

        });
    }


    createStripeAccount(providerId: number): Observable<string> {

        return Observable.create((observer: Observer<string>) => {
            this.adminAffiliateService.createStripeAccount(providerId)
                  .subscribe(
                      (stripeAccountUrl: string) => {
                          observer.next(stripeAccountUrl);
                          observer.complete();
                      },
                      (error: GenericException) => {
                          observer.error(error);
                      }
                  )

        });
    }


    removeStripeAccount(providerId: number): Observable<boolean> {

        return Observable.create((observer: Observer<boolean>) => {
            this.adminAffiliateService.removeStripeAccount(providerId)
                  .subscribe(
                      () => {
                          observer.next(true);
                          observer.complete();
                      },
                      (error: GenericException) => {
                          observer.error(error);
                      }
                  )

        });
    }


    updateStripeAccount(providerId: number, stripeAccountId: string): Observable<boolean> {

        return Observable.create((observer: Observer<boolean>) => {
            this.adminAffiliateService.updateStripeAccount(providerId, stripeAccountId)
                  .subscribe(
                      () => {
                          observer.next(true);
                          observer.complete();
                      },
                      (error: GenericException) => {
                          observer.error(error);
                      }
                  )

        });
    }





}
