export class ServerErrorExceptionCase {


    private _codes: Array<number>;
    private _causes: Array<string>;
    private _callback: () => void;

    constructor(codes: Array<number>, causes: Array<string>, callback: () => void) {
        this._codes = codes;
        this._causes = causes;
        this._callback = callback;
    }

    get codes(): Array<number> {
      return this._codes;
    }

    get causes(): Array<string> {
      return this._causes;
    }

    set codes(codes: Array<number>) {
      this._codes = codes;
    }

    get callback(): ()=>void {
      return this._callback;
    }

    set callback(callback: ()=>void) {
      this._callback = callback;
    }





}
