import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CommonApiEndpointsUtils } from '../../api/endpoints/common-api-endponints.utils';
import { BookingOrder } from '../../models/book/booking-order.model';
import { BookingUtils } from '../../models/book/common/booking.utils';
import { BaseService } from '../base.service';

@Injectable({
  providedIn: 'root'
})
export class BookService extends BaseService {


  cancelBooking(orderBookingId: number, verificationCode: string): Observable<boolean> {
    return super.sendPostRequest(CommonApiEndpointsUtils.getCancelBookingEndpoint(), CommonApiEndpointsUtils.getCancelBookingObject(orderBookingId, verificationCode), CommonApiEndpointsUtils.getCancelBookingEndpointAcceptVersion())
      .pipe(
        map(() => {

          return true;

        })
      )
  }


  rateBooking(orderBookingId: number, verificationCode: string, rate: number, comments: string): Observable<boolean> {
    return super.sendPostRequest(CommonApiEndpointsUtils.getRateBookingEndpoint(), CommonApiEndpointsUtils.getRateBookingObject(orderBookingId, verificationCode, rate, comments), CommonApiEndpointsUtils.getRateBookingEndpointAcceptVersion())
      .pipe(
        map(() => {

          return true;

        })
      )
  }


  getBookingForPayment(orderBookingId: number, verificationCode: string, localeI18N: string): Observable<BookingOrder> {
    return super.sendGetRequest(CommonApiEndpointsUtils.getBookingForPaymentEndpoint(orderBookingId, verificationCode), CommonApiEndpointsUtils.getBookingForPaymentEndpointAcceptVersion())
      .pipe(
        map((response: any) => {

             return BookingUtils.getBookingOrderFromApi(response, localeI18N);

        })
      )
  }


}
