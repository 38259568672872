import { Injectable }                       from '@angular/core';
import { LocaleManager } from '../managers/locale-manager';
import { SessionManager } from '../managers/session-manager';
import { TranslateService } from '@ngx-translate/core';
import { LoggedInUserView } from '../views/logged-in-user.view';


@Injectable({
  providedIn: 'root'
})
export class SessionInteractor {

    constructor( private sessionManager: SessionManager, private translate: TranslateService ) {}


    loggedIn(): boolean {
        return this.sessionManager.isLoggedIn();
    }

    loggedInUserDetails(): LoggedInUserView  {
         if ( this.sessionManager.isLoggedIn() && this.sessionManager.getEmail() ) {
              let userId: number = null;
              if ( this.sessionManager.getUserId() ) {
                   userId = this.sessionManager.getUserId()
              }

              let userName: string = null;
              if ( this.sessionManager.getUserName() ) {
                   userName = this.sessionManager.getUserName()
              }

              let guidesManagement: number = null;
              if ( this.sessionManager.getGuidesManagement() ) {
                   guidesManagement = this.sessionManager.getGuidesManagement()
              }

              return new LoggedInUserView(userId, this.sessionManager.getEmail(), userName, guidesManagement);
        } else {
          return null
        }
    }

    updateLoggedInUserDetails(loggedInUserView: LoggedInUserView) {
         if ( loggedInUserView.id ) {
              this.sessionManager.updateUserId(loggedInUserView.id);
         }
         if ( loggedInUserView.name ) {
              this.sessionManager.updateUserName(loggedInUserView.name);
         }

         this.sessionManager.updateGuidesManagement(loggedInUserView.guidesManagement);

    }


    logout() {
        this.sessionManager.removeCredentials();
        LocaleManager.resetlocale()
    }

    isAdminRole(): boolean {
        return this.sessionManager.isAdminRole()
    }

    isProviderRole(): boolean {
        return this.sessionManager.isProviderRole()
    }

    isAffiliateRole(): boolean {
        return this.sessionManager.isAffiliateRole()
    }

    isGuideRole(): boolean {
        return this.sessionManager.isGuideRole()
    }


    setCurrentLanguage(languageCode: string): boolean {
        const result = LocaleManager.setCurrentLocale(languageCode);
        this.translate.setDefaultLang(languageCode);
        return result
    }

    getCurrentLanguage(): string {
        return LocaleManager.getCurrentLocale()
    }


}
