import { SchedulePlanningDay } from './schedule-planning-day.model';

export class SchedulePlanning {


    private _days: Array<SchedulePlanningDay>;
    private _dayExceptions: Array<SchedulePlanningDay>;

    constructor(
      days: Array<SchedulePlanningDay>,
      dayExceptions: Array<SchedulePlanningDay>
    ) {

        this._days = days;
        this._dayExceptions = dayExceptions;

    }


    get days(): Array<SchedulePlanningDay> {
        return this._days;
    }

    get dayExceptions(): Array<SchedulePlanningDay> {
        return this._dayExceptions;
    }

}