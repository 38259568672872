<div class="d-flex">

    <app-sidebar></app-sidebar>
    <div class="dashboard-panel">
        <app-header></app-header>

        <div class="container-fluid">
            <router-outlet></router-outlet>
        </div>
    </div>

</div>