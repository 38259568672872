import { Pipe, PipeTransform } from '@angular/core';
import { marker as I18N } from '@biesbjerg/ngx-translate-extract-marker';

@Pipe({
  name: 'invoicePaymentTypeProviderPipe'
})
export class InvoicePaymentTypeProviderPipe implements PipeTransform {

  transform(chargePending: boolean, chargeCompleted: boolean, paymentPending: boolean, paymentCompleted: boolean): string {

    if (chargePending || chargeCompleted) {
      return I18N('invoiceState.A pagar');
    } else if (paymentPending || paymentCompleted) {
      return I18N('invoiceState.A cobrar');
    } else {
      return I18N('invoiceState.Sin moviento');
    }

  }

}
