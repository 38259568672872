import { Injectable }                       from '@angular/core';
import { Observable, Observer } from 'rxjs';
import { CancelationReason } from 'src/app/business/models/master/booking/cancelation-reason/cancelaltion-reason.constants';
import { City } from 'src/app/business/models/master/city/city.model';
import { Country } from 'src/app/business/models/master/country/country.model';
import { Language } from 'src/app/business/models/master/language/language.model';
import { TourType } from 'src/app/business/models/master/tour/type/type.model';
import { UserMasterService } from 'src/app/business/services/user/master.service';
import { AppConstants } from '../../common/app.constants';
import { GenericException } from '../../exceptions/generic.exception';


@Injectable({
  providedIn: 'root'
})
export class UserMasterInteractor {


    constructor( private userMasterService: UserMasterService ) {}



    getLanguagesByUser(): Observable<Array<Language>> {

        return Observable.create((observer: Observer<Array<Language>>) => {
            this.userMasterService.getLanguages()
                  .subscribe(
                      (languages: Array<Language>) => {

                          observer.next(languages);
                          observer.complete();
                      },
                      (error: GenericException) => {
                          observer.error(error);
                      }
                  )

        });
    }


    getCountriesByUser(): Observable<Array<Country>> {

        return Observable.create((observer: Observer<Array<Country>>) => {
            this.userMasterService.getCountries()
                  .subscribe(
                      (countries: Array<Country>) => {

                          observer.next(countries);
                          observer.complete();
                      },
                      (error: GenericException) => {
                          observer.error(error);
                      }
                  )

        });
    }


    getCitiesByUser(countryId: number): Observable<Array<City>> {

        return Observable.create((observer: Observer<Array<City>>) => {
              this.userMasterService.getCitiesByCountry(countryId)
                    .subscribe(
                        (cities: Array<City>) => {

                            observer.next(cities);
                            observer.complete();
                        },
                        (error: GenericException) => {
                            observer.error(error);
                        }
                    )

        });
    }


    getTourDisplayedLanguages(): Observable<Array<Language>> {

        return Observable.create((observer: Observer<Array<Language>>) => {
              this.userMasterService.getTourDisplayedLanguages()
                    .subscribe(
                        (languages: Array<Language>) => {

                            languages.sort((a, b) => {
                                 if ( a.code == AppConstants.TOUR_DISPLAY_LANGUAGE_DEFAULT ) {
                                      return -1;
                                 } else if ( b.code == AppConstants.TOUR_DISPLAY_LANGUAGE_DEFAULT ) {
                                      return 1
                                 }
                            });

                            observer.next(languages);
                            observer.complete();
                        },
                        (error: GenericException) => {
                            observer.error(error);
                        }
                    )

        });
    }


    getTourTypes(): Observable<Array<TourType>> {

        return Observable.create((observer: Observer<Array<TourType>>) => {
              this.userMasterService.getTourTypes()
                    .subscribe(
                        (tourTypes: Array<TourType>) => {

                            observer.next(tourTypes);
                            observer.complete();
                        },
                        (error: GenericException) => {
                            observer.error(error);
                        }
                    )

        });
    }


    getBookingCancelationReasons(): Observable<Array<CancelationReason>> {

        return Observable.create((observer: Observer<Array<CancelationReason>>) => {
            this.userMasterService.getBookingCancelationReasons()
                  .subscribe(
                      (cancelationReasons: Array<CancelationReason>) => {

                          observer.next(cancelationReasons);
                          observer.complete();
                      },
                      (error: GenericException) => {
                          observer.error(error);
                      }
                  )

        });
    }

}
