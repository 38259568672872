import { ApiConstants } from 'src/app/business/api/api.constants';
import { ImageSize } from './image-size.constants';
import { ImageDetails } from '../image-details.model';
import { ImageThumb } from '../image-thumb.model';

export class ImageUtils {

    public static getImageSizeFromApi(apiSize: string): ImageSize {

        switch ( apiSize ) {

          case ApiConstants.API_IMAGE_SIZE_ORIGINAL:
              return ImageSize.Original;

          case ApiConstants.API_IMAGE_SIZE_BIG:
              return ImageSize.Big;

          case ApiConstants.API_IMAGE_SIZE_MEDIUM:
              return ImageSize.Medium

          case ApiConstants.API_IMAGE_SIZE_SMALL:
              return ImageSize.Small

          default:
              return null;

        }

    }


    public static getImageDetailsFromApi(apiImage: any): ImageDetails {

        let imageDetails = null;


        if ( apiImage ) {

            let imageOriginal: ImageThumb = null;
            let imageBig: ImageThumb = null;
            let imageMedium: ImageThumb = null;
            let imageSmall: ImageThumb = null;

            apiImage.sizes.map( (imageThumbApi: any) => {

                const imageThumb:ImageThumb = new ImageThumb(ImageUtils.getImageSizeFromApi(imageThumbApi.size), imageThumbApi.isOriginal, imageThumbApi.height, imageThumbApi.width, imageThumbApi.url);

                switch ( imageThumb.size ) {
                  case ImageSize.Original:
                      imageOriginal = imageThumb;
                      break;
                  case ImageSize.Big:
                      imageBig = imageThumb;
                      break;
                  case ImageSize.Medium:
                      imageMedium = imageThumb;
                      break;
                  case ImageSize.Small:
                      imageSmall = imageThumb;
                      break;
                  default:
                    break;
                }

            });

            if ( !imageBig ) {
                imageBig = imageOriginal;
            }

            if ( !imageMedium ) {
                imageMedium = imageOriginal;
            }

            if ( !imageSmall ) {
                imageSmall = imageOriginal;
            }

            let position = 0;
            if ( apiImage.position ) {
                 position = apiImage.position;
            }


            imageDetails = new ImageDetails(apiImage.id, apiImage.name, position, imageOriginal, imageBig, imageMedium, imageSmall);
        }


        return imageDetails;
    }


}
