<div id="publicLanding">
    <app-header-landing (menuItemSelected)="onHeaderItemSelected($event)"></app-header-landing>

    <div class="row dashboard-content-section cancel-page mb-5 mb-md-auto">
        <div class="col-12">
            <div class="row mt-5 pt-5">
                <div class="col-12 col-md-6 col-xl-6 order-md-1 order-2 text-center text-md-left">
                    <div class="row mb-5 pb-5 mt-4 mt-md-auto">
                        <div class="col-12" *ngIf="bookCanceled">
                            <h1>{{ 'bookingcancelation.¡Qué pena!' | translate }}</h1>
                            <p>{{ 'bookingcancelation.Tu reserva ha sido cancelada correctamente. ¡Esperamos verte pronto!' | translate }}</p>
                        </div>
                        <div class="col-12" *ngIf="bookNotCanceled">
                            <h1>{{ 'bookingcancelation.¡Perfecto!' | translate }}</h1>
                            <p>{{ 'bookingcancelation.Tu reserva sigue vigente. ¡Nos vemos pronto!' | translate }}</p>
                        </div>
                    </div>
                    <div class="row mt-5 pb-5">
                        <div class="col-6 col-md-6 col-xl-4">
                            <button (click)="goToHomeLanding()" class="btn btn-full-width btn-primary mb-3 mb-sm-0 white-color" type="submit" value="notFound.Home">
                              {{ 'bookingcancelation.Home' | translate }}
                         </button>
                        </div>
                    </div>

                </div>
                <div class="col-12 col-md-6 col-xl-6 text-center order-md-2 order-1">
                    <img class="cancel-order-image" src="assets/img/ic_cancel-order.svg">
                </div>
            </div>
        </div>
    </div>


    <app-message-panel [preloading]="true"></app-message-panel>
</div>