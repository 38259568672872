import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BaseService } from '../base.service';
import { ChunkGeneric } from 'src/app/domain/views/chunk-generic.view';
import { Pagination } from 'src/app/domain/views/pagination.view';
import { BookingBilling } from '../../models/billing/booking-billing.model';
import { BookingUtils } from '../../models/book/common/booking.utils';
import { GuideApiEndpointsUtils } from '../../api/endpoints/guide-api-endponints.utils';
import { GuideBillingStats } from '../../models/billing/billing-stats.model';
import { GuideBillingTourStats } from '../../models/billing/billing-tour-stats.model';
import { TourResume } from '../../models/tour/tour-resume.model';
import { TourUtils } from '../../models/tour/common/tour.utils';

@Injectable({
     providedIn: 'root'
})
export class GuideBillingService extends BaseService {


     findBilling(dateInit: string, dateEnd: string, keywords: string, page: number): Observable<ChunkGeneric<BookingBilling>> {

          return super.sendGetRequestWithRefresh(
               GuideApiEndpointsUtils.getGuideFindBillingEndpoint(dateInit, dateEnd, keywords, page),
               GuideApiEndpointsUtils.getGuideFindBillingEndpointAcceptVersion()
          )
          .pipe(
               map((response: any) => {

                    const bookingBillings = new Array<BookingBilling>();

                    response.items.map((bookingBillingApi: any) => {

                         const bookingBilling: BookingBilling = BookingUtils.getGuideBookingBillingFromApi(bookingBillingApi);
                         bookingBillings.push(bookingBilling);

                    })


                    return new ChunkGeneric<BookingBilling>(bookingBillings, new Pagination(response.total, response.currentPage, response.chunkSize));
               })
          )
     }




     findBillingStats(dateInit: string, dateEnd: string, keywords: string, localeI18N: string): Observable<GuideBillingStats> {

          return super.sendGetRequestWithRefresh(
               GuideApiEndpointsUtils.getGuideFindBillingStatsEndpoint(dateInit, dateEnd, keywords),
               GuideApiEndpointsUtils.getGuideFindBillingStatsAcceptVersion()
          )
          .pipe(
               map((billingGuideStatsApi: any) => {

                    const totalCommissions = billingGuideStatsApi.totalCommissions;
                    const guideBillingToursStats = new Array<GuideBillingTourStats>();

                    billingGuideStatsApi.toursDetailsStats.map((tourDetailsStats: any) => {

                         const tour: TourResume = TourUtils.getTourResumeFromApi(tourDetailsStats.tour, localeI18N);

                         const guideBillingTourStats: GuideBillingTourStats = new GuideBillingTourStats(
                              tour.id,
                              tour.name,
                              tourDetailsStats.billingGuideTourStats.people,
                              tourDetailsStats.billingGuideTourStats.commissions
                         );

                         guideBillingToursStats.push(guideBillingTourStats);

                    })


                    return new GuideBillingStats(guideBillingToursStats, totalCommissions);
               })
          )
     }


/*




     findLatestBilling(): Observable<Array<BookingBilling>> {

          return super.sendGetRequestWithRefresh(
               AffiliateApiEndpointsUtils.getAffiliateFindLatestBillingEndpoint(),
               AffiliateApiEndpointsUtils.getAffiliateFindLatestBillingEndpointAcceptVersion()
          )
          .pipe(
               map((response: any) => {

                    const bookingBillings = new Array<BookingBilling>();
                    response.map((bookingBillingApi: any) => {

                         const bookingBilling: BookingBilling = BookingUtils.getBookingBillingFromApi(bookingBillingApi);
                         bookingBillings.push(bookingBilling);

                    })

                    return bookingBillings;
               })
          )
     }



     findInvoices(page: number): Observable<ChunkGeneric<InvoiceDetails>> {

          return super.sendGetRequestWithRefresh(AffiliateApiEndpointsUtils.getAffiliateFindInvoicesEndpoint(page), AffiliateApiEndpointsUtils.getAffiliateFindInvoicesEndpointAcceptVersion())
          .pipe(
               map((response: any) => {

                    const invoices = new Array<InvoiceDetails>();

                    response.items.map((invoiceApi: any) => {

                         invoices.push(BillingUtils.getInvoiceFromApi(invoiceApi));

                    });

                    return new ChunkGeneric<InvoiceDetails>(invoices, new Pagination(response.total, response.currentPage, response.chunkSize));

               })
          )
     }



     findNextBilling(localeI18N: string): Observable<NextBilling> {

          return super.sendGetRequestWithRefresh(AffiliateApiEndpointsUtils.getAffiliateFindNextBillingEndpoint(), AffiliateApiEndpointsUtils.getAffiliateFindNextBillingEndpointAcceptVersion())
          .pipe(
               map((response: any) => {

                    const tourBillings = new Array<TourBilling>();
                    let nextBillingTotal: number = 0;

                    response.map((tourBillingApi: any) => {

                         const tourBilling = new TourBilling(
                              TourUtils.getTourResumeFromApi(tourBillingApi.tour, localeI18N),
                              BillingUtils.getBillingDetailsFromApi(tourBillingApi.info)
                         );

                         nextBillingTotal += tourBilling.billingDetails.commission;

                         tourBillings.push(tourBilling);

                    });

                    return new NextBilling(tourBillings, nextBillingTotal);

               })
          )
     }


     invoiceDownload(invoiceId: number): Observable<string> {

          return super.sendBlobRequestWithRefresh(AffiliateApiEndpointsUtils.getAffiliateInvoiceDownloadEndpoint(invoiceId), AffiliateApiEndpointsUtils.getAffiliateInvoiceDownloadEndpointAcceptVersion())
          .pipe(
               map((response: any) => {

                    return response;

               })
          )
     }



     findInvoice(invoiceId: number): Observable<InvoiceFullDetails> {

          return super.sendGetRequestWithRefresh(AffiliateApiEndpointsUtils.getAffiliateFindInvoiceEndpoint(invoiceId), AffiliateApiEndpointsUtils.getAffiliateFindInvoiceEndpointAcceptVersion())
          .pipe(
               map((response: any) => {

                    return new InvoiceFullDetails(
                         BillingUtils.getInvoiceIssuerFromApi(response.issuer),
                         BillingUtils.getInvoiceFromApi(response),
                         null
                    );

               })
          )
     }


     getAffiliateStats(): Observable<AffiliateStatsDetails> {
       return super.sendGetRequestWithRefresh(AffiliateApiEndpointsUtils.getAffiliateProfileStatsEndpoint(), AffiliateApiEndpointsUtils.getAffiliateProfileStatsEndpointAcceptVersion())
         .pipe(
           map((response: any) => {
                return AffiliateUtils.getAffiliateStatsFromApi(response);

           })
         )
     }


     getAffiliateDailyStats(year: number, month: number): Observable<AffiliateDailyStats> {
       return super.sendGetRequestWithRefresh(AffiliateApiEndpointsUtils.getAffiliateProfileDailyStatsEndpoint(year, month), AffiliateApiEndpointsUtils.getAffiliateProfileDailyStatsEndpointAcceptVersion())
         .pipe(
           map((response: any) => {
                return AffiliateUtils.getAffiliateDailyStatsFromApi(response);

           })
         )
     }
*/

}
