
import { Input, Component } from '@angular/core';
import { CreateTourStep } from 'src/app/domain/common/tour/tour-create-state.constants';

@Component({
  selector: 'app-create-tour-steps',
  templateUrl: './steps.component.html'
})
export class CreateTourStepsComponent {

  @Input() currentFormStep: CreateTourStep;

  @Input() showProviderStep: boolean;

  providerStep: CreateTourStep;
  detailsStep: CreateTourStep;
  usersStep: CreateTourStep;
  locationStep: CreateTourStep;
  scheduleStep: CreateTourStep;
  reviewStep: CreateTourStep;

  constructor() {
       this.providerStep = CreateTourStep.providerStep;
      this.detailsStep = CreateTourStep.detailsStep;
      this.usersStep = CreateTourStep.usersStep;
      this.locationStep = CreateTourStep.locationStep;
      this.scheduleStep = CreateTourStep.scheduleStep;
      this.reviewStep = CreateTourStep.reviewStep;
  }

}
