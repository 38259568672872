import { Component, OnInit, Output, EventEmitter, Input, ViewChild } from '@angular/core';
import { FormGroup, Validators, FormControl, FormArray } from '@angular/forms';
import { Country } from 'src/app/business/models/master/country/country.model';
import { City } from 'src/app/business/models/master/city/city.model';
import { Language } from 'src/app/business/models/master/language/language.model';
import { ProviderTourInteractor } from 'src/app/domain/interactor/provider/tour.interactor';
import { TourType } from 'src/app/business/models/master/tour/type/type.model';
import { TourDetailsFormView } from 'src/app/domain/views/provider/tour/details-form.view';
import { TourAttractionFormView } from 'src/app/domain/views/provider/tour/attraction-form.view';
import { TourStepFormView } from 'src/app/domain/views/provider/tour/sept-form.view';
import { BrowserConstants } from 'src/app/ui/commons/browser.constants';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { ServerErrorManager } from 'src/app/ui/components/utils/server-error-manager.utils';
import { MessagePanelComponent } from 'src/app/ui/components/shared/message-panel/message-panel.component';
import { AppConstants } from 'src/app/domain/common/app.constants';
import { GenericException } from 'src/app/domain/exceptions/generic.exception';
import { UserMasterInteractor } from 'src/app/domain/interactor/user/user-master.interactor';

@Component({
  selector: 'app-create-tour-details',
  templateUrl: './details.component.html'
})
export class CreateTourDetailsComponent implements OnInit {

  @ViewChild(MessagePanelComponent)
  private messagePanelComponent: MessagePanelComponent;

  createTourDetailsForm: FormGroup;
  refreshValidators: boolean;

  countries: Array<Country>;
  cities: Array<City>;
  languages: Array<Language>;
  tourTypes: Array<TourType>;

  lastSelectedCountryId: number;

  i18nTraslation: boolean;
  tourDetailsInput: TourDetailsFormView;

  wysiwygSettings: AngularEditorConfig = {
      editable: true,
      height: 'auto',
      maxHeight: 'auto',
      width: 'auto',
      translate: 'no',
      enableToolbar: true,
      showToolbar: true,
      defaultParagraphSeparator: 'p',
      sanitize: true,
      toolbarPosition: 'top',
      toolbarHiddenButtons: [
        ['undo', 'redo', 'subscript', 'superscript', 'justifyLeft', 'justifyCenter', 'justifyRight', 'justifyFull', 'fontName'],
        ['backgroundColor', 'customClasses', 'link', 'unlink', 'insertImage', 'insertVideo', 'insertHorizontalRule', 'removeFormat', 'toggleEditorMode']
      ]
};

  @Input()
  set isI18nTraslation(isI18nTraslation: boolean) {
      this.i18nTraslation = isI18nTraslation;
      this.refreshCreateTourDetailsForm();
  }


  @Input()
  set tourDetails(tourDetails: TourDetailsFormView) {
      this.tourDetailsInput = tourDetails;
      this.refreshCreateTourDetailsForm();
  }

  @Input() hasProviderStep: boolean;


  refreshCreateTourDetailsForm() {
    if ( this.tourDetailsInput ) {

        this.createTourDetailsForm.get('name').setValue(this.tourDetailsInput.name);
        this.createTourDetailsForm.get('nameMain').setValue(this.tourDetailsInput.nameMain);

        this.createTourDetailsForm.get('languageTourGivenId').setValue(this.tourDetailsInput.languageTourGivenId);
        if ( this.i18nTraslation ) {
            this.createTourDetailsForm.get('languageTourGivenId').disable();
        } else {
            this.createTourDetailsForm.get('languageTourGivenId').enable();
        }

        this.createTourDetailsForm.get('typeId').setValue(this.tourDetailsInput.typeId);
        if ( this.i18nTraslation ) {
            this.createTourDetailsForm.get('typeId').disable();
        } else {
            this.createTourDetailsForm.get('typeId').enable();
        }

        this.createTourDetailsForm.get('duration').setValue(this.tourDetailsInput.duration);
        if ( this.i18nTraslation ) {
            this.createTourDetailsForm.get('duration').disable();
        } else {
            this.createTourDetailsForm.get('duration').enable();
        }

        this.createTourDetailsForm.get('countryId').setValue(this.tourDetailsInput.countryId);
        if ( this.i18nTraslation ) {
            this.createTourDetailsForm.get('countryId').disable();
        } else {
            this.createTourDetailsForm.get('countryId').enable();
        }

        this.createTourDetailsForm.get('cityId').setValue(this.tourDetailsInput.cityId);
        if ( this.i18nTraslation ) {
            this.createTourDetailsForm.get('cityId').disable();
        } else {
            this.createTourDetailsForm.get('cityId').enable();
        }

        this.createTourDetailsForm.get('description').setValue(this.tourDetailsInput.description);
        this.createTourDetailsForm.get('descriptionMain').setValue(this.tourDetailsInput.descriptionMain);
        this.createTourDetailsForm.get('descriptionShort').setValue(this.tourDetailsInput.descriptionShort);
        this.createTourDetailsForm.get('descriptionShortMain').setValue(this.tourDetailsInput.descriptionShortMain);
        this.createTourDetailsForm.get('additionalInformation').setValue(this.tourDetailsInput.additionalInformation);
        this.createTourDetailsForm.get('additionalInformationMain').setValue(this.tourDetailsInput.additionalInformationMain);

        this.attractions.clear()
        if ( this.tourDetailsInput.attractions ) {
            for ( const attraction of this.tourDetailsInput.attractions ) {
                const attractionFormGroup = new FormGroup({
                    'id': new FormControl(attraction.id),
                    'name': new FormControl(attraction.name),
                    'nameMain': new FormControl(attraction.nameMain)
                });

                if ( this.i18nTraslation ) {
                    attractionFormGroup.get('name').setValidators(Validators.required);
                }

                this.attractions.push(attractionFormGroup);
            }
        }

        this.lastSelectedCountryId = this.tourDetailsInput.countryId;
        this.onCountrySelected();
    }
  }


  @Output() tourDetailsEmitter = new EventEmitter<TourStepFormView<TourDetailsFormView>>(true);


  constructor(
    private userMasterInteractor: UserMasterInteractor,
    private providerTourInteractor: ProviderTourInteractor,
    private serverErrorManager: ServerErrorManager ) {

      this.refreshValidators = false;

      this.createTourDetailsForm = new FormGroup({
          'name': new FormControl('', [Validators.required, Validators.maxLength(AppConstants.TEXT_DEFAULT_MAX_LENGTH)] ),
          'nameMain': new FormControl(''),
          'languageTourGivenId': new FormControl('', [Validators.required] ),
          'typeId': new FormControl('', [Validators.required] ),
          'duration': new FormControl('', [Validators.required, Validators.min(1), Validators.max(AppConstants.NUMBER_MAX_VALE)] ),
          'countryId': new FormControl('', [Validators.required] ),
          'cityId': new FormControl('', [Validators.required] ),
          'description': new FormControl('', [Validators.required] ),
          'descriptionMain': new FormControl(''),
          'descriptionShort': new FormControl('', [Validators.required, Validators.maxLength(150)] ),
          'descriptionShortMain': new FormControl(''),
          'additionalInformation': new FormControl(''),
          'additionalInformationMain': new FormControl(''),
          'attractions': new FormArray([]),
          'attractionsValid': new FormControl(''),
     }, this.attractionsValid);

      this.onAddAttraction();


  }



  ngOnInit() {

      this.providerTourInteractor.getTourGuivenLanguages()
        .subscribe(
            ( languages: Array<Language> ) => {
                this.languages = languages;
            },
            (error: GenericException) => {
                this.serverErrorManager.manageErrorCode(error, this.messagePanelComponent);
            }
        )

      this.userMasterInteractor.getTourTypes()
          .subscribe(
              ( tourTypes: Array<TourType> ) => {
                  this.tourTypes = tourTypes;
              },
              (error: GenericException) => {
                  this.serverErrorManager.manageErrorCode(error, this.messagePanelComponent);
              }
          )

      this.userMasterInteractor.getCountriesByUser()
          .subscribe(
              ( countries: Array<Country> ) => {
                  this.countries = countries;
              },
              (error: GenericException) => {
                  this.serverErrorManager.manageErrorCode(error, this.messagePanelComponent);
              }
          )

  }


  get nameMain(): string {
      return this.createTourDetailsForm.get('nameMain').value;
  }

  get descriptionMain(): string {
      return this.createTourDetailsForm.get('descriptionMain').value;
  }

  get descriptionShortMain(): string {
      return this.createTourDetailsForm.get('descriptionShortMain').value;
  }

  get additionalInformationMain(): string {
      return this.createTourDetailsForm.get('additionalInformationMain').value;
  }



  saveDetails() {

      this.refreshValidators = true;

      if ( this.createTourDetailsForm.valid ) {
          this.emitEventWithFormDetails(true);
      }
  }


  onBackButton() {
      this.emitEventWithFormDetails(false);
  }


  private emitEventWithFormDetails(isNextStep: boolean) {

      const attractions = Array<TourAttractionFormView>();
      const attractionsFormArray = this.createTourDetailsForm.get('attractions') as FormArray;
      attractionsFormArray.controls.map( (attraction: FormGroup) => {
          const attractionId = attraction.get('id').value;
          let attractionValue = attraction.get('name').value;
          if ( attractionValue ) {
               attractionValue = attractionValue.trim();
          }
          const attractionValueMain = attraction.get('nameMain').value;
          if ( attractionValue && attractionValue != '' ) {
              attractions.push(new TourAttractionFormView(attractionId, attractionValue, attractionValueMain));
          }
      });

      const tourDetailsFormView = new TourDetailsFormView(
          this.createTourDetailsForm.get('name').value,
          this.createTourDetailsForm.get('nameMain').value,
          this.createTourDetailsForm.get('languageTourGivenId').value,
          this.createTourDetailsForm.get('typeId').value,
          this.createTourDetailsForm.get('duration').value,
          this.createTourDetailsForm.get('countryId').value,
          this.createTourDetailsForm.get('cityId').value,
          this.createTourDetailsForm.get('description').value,
          this.createTourDetailsForm.get('descriptionMain').value,
          this.createTourDetailsForm.get('descriptionShort').value,
          this.createTourDetailsForm.get('descriptionShortMain').value,
          this.createTourDetailsForm.get('additionalInformation').value,
          this.createTourDetailsForm.get('additionalInformationMain').value,
          attractions,
      )

      this.tourDetailsEmitter.emit(new TourStepFormView(isNextStep, tourDetailsFormView));

  }


  get attractions(): FormArray {
      return this.createTourDetailsForm.get('attractions') as FormArray;
  }

  onAddAttraction() {
      let existEmptyAttractions = false;
      this.attractions.controls.map( (attractionFormGroup: FormGroup) => {
           const attractionValue = attractionFormGroup.get('name').value.trim();
          if ( attractionValue == '' ) {
              existEmptyAttractions = true;
          }
      })

      if ( !existEmptyAttractions ) {
          this.attractions.push(new FormGroup({
              'id': new FormControl(''),
              'name': new FormControl('', [Validators.maxLength(AppConstants.TEXT_DEFAULT_MAX_LENGTH)]),
              'nameMain': new FormControl('')
          }));
      }
  }

  onAttractionChanged(event: any, atractionIndex: number) {
      if ( !this.i18nTraslation && event.keyCode == BrowserConstants.KEYBOARD_BACKSPACE_CODE && this.attractions.at(atractionIndex) && this.attractions.at(atractionIndex).get('name').value == '' && atractionIndex > 0) {
          this.attractions.removeAt(atractionIndex);
      }
  }


  onCountrySelected() {

    if ( this.lastSelectedCountryId != this.createTourDetailsForm.get('countryId').value ) {
        this.lastSelectedCountryId = this.createTourDetailsForm.value.countryId;
        this.cities = new Array<City>();
        this.createTourDetailsForm.controls['cityId'].setValue('');
    }

    if ( this.lastSelectedCountryId ) {

        this.userMasterInteractor.getCitiesByUser( this.lastSelectedCountryId )
          .subscribe(
              (cities: Array<City>) => {
                  this.cities = cities
              },
              (error: GenericException) => {
                  this.serverErrorManager.manageErrorCode(error, this.messagePanelComponent);
              }
          )

    }

  }


  attractionsValid( formGroup: FormGroup ): { [s:string]:boolean } {
     const attractions = formGroup.get('attractions') as FormArray;
     let hasAttractions = false;
     attractions.controls.map( (attractionFormGroup: FormGroup) => {
          const attractionValue = attractionFormGroup.get('name').value;
          if ( attractionValue && attractionValue.trim() != '' ) {
               hasAttractions = true;
          }
     })

     if ( !hasAttractions ) {
           formGroup.get('attractionsValid').setErrors({required: true});
     } else {
         formGroup.get('attractionsValid').setErrors(null);
     }

      return null;
  }


}
