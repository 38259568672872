import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { MessagePanelComponent } from '../message-panel/message-panel.component';
import { MasterInteractor } from 'src/app/domain/interactor/public/master.interactor';
import { SessionInteractor } from 'src/app/domain/interactor/session.interactor';
import { Language } from 'src/app/business/models/master/language/language.model';
import { ServerErrorManager } from '../../utils/server-error-manager.utils';
import { NavigatorUtils } from 'src/app/ui/utils/navigator.utils';
import { GenericException } from 'src/app/domain/exceptions/generic.exception';

@Component({
  selector: 'app-language-picker',
  templateUrl: './language-picker.component.html'
})
export class LanguagePickerComponent implements OnInit {

  @ViewChild(MessagePanelComponent)
  private messagePanelComponent: MessagePanelComponent;

  @Input() extraClass: string;

  @Input() separator: boolean;

  languages: Array<Language>
  currentLanguageCode: string;

  constructor(
    private masterInteractor: MasterInteractor,
    private sessionInteractor: SessionInteractor,
    private serverErrorManager: ServerErrorManager ) {

      this.currentLanguageCode = this.sessionInteractor.getCurrentLanguage();

      this.masterInteractor.getLanguagesLocalized()
          .subscribe(
              (languages: Array<Language>) => {
                  this.languages = languages;
              },
              (error: GenericException) => {
                  this.serverErrorManager.manageErrorCode(error, this.messagePanelComponent);
              }
          )
  }


  ngOnInit() {
  }


  onChangeLanguage(languageCode: string) {
      if ( this.sessionInteractor.setCurrentLanguage(languageCode) ) {
          this.messagePanelComponent.showLoading();
          NavigatorUtils.reloadApplication();
      }
  }


}
