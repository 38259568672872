import { Component, OnInit, ViewChild } from '@angular/core';

import { Router } from '@angular/router';
import { NavigationConstants } from 'src/app/ui/commons/navigation.constants';
import { ProviderTourInteractor } from 'src/app/domain/interactor/provider/tour.interactor';
import { ChunkGeneric } from 'src/app/domain/views/chunk-generic.view';
import { TourResume } from 'src/app/business/models/tour/tour-resume.model';
import { Pagination } from 'src/app/domain/views/pagination.view';
import { Country } from 'src/app/business/models/master/country/country.model';
import { City } from 'src/app/business/models/master/city/city.model';
import { TourState } from 'src/app/business/models/tour/common/tour-state.constants';
import { MessagePanelComponent } from 'src/app/ui/components/shared/message-panel/message-panel.component';
import { ServerErrorManager } from 'src/app/ui/components/utils/server-error-manager.utils';
import { ImageSize } from 'src/app/business/models/image/common/image-size.constants';
import { marker as I18N } from '@biesbjerg/ngx-translate-extract-marker';
import { TranslateService } from '@ngx-translate/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { GenericException } from 'src/app/domain/exceptions/generic.exception';
import { UserMasterInteractor } from 'src/app/domain/interactor/user/user-master.interactor';
import { ProviderEditTourGuideSalesCommissionModalComponent } from './edit-commission-guide-sales/edit-commission-guide-sales-modal';
import { ProviderEditTourGuideServiceFeeModalComponent } from './edit-fee-guide-service/edit-fee-guide-service-modal';


@Component({
     selector: 'app-list-tours',
     templateUrl: './list.component.html'
})
export class ListToursComponent implements OnInit {

     @ViewChild(MessagePanelComponent)
     private messagePanelComponent: MessagePanelComponent;


     imageMediumSize: ImageSize = ImageSize.Medium;

     countryId: number;
     cityId: number;
     keywords: string;
     pageNumber: number;

     tourStateActive: TourState = TourState.Active;

     currentCountrySelected: number;

     tours: Array<TourResume>;
     pagination: Pagination;

     countries: Array<Country>;
     cities: Array<City>;

     noResults: boolean;

     paginationNextLabel: string;
     paginationPreviousLabel: string;

     constructor(
          private providerTourInteractor: ProviderTourInteractor,
          private userMasterInteractor: UserMasterInteractor,
          private translateService: TranslateService,
          private modalService: NgbModal,
          private router: Router,
          private serverErrorManager: ServerErrorManager) {

               this.pagination = new Pagination(0, 1, 20);
               this.tours = new Array<TourResume>();
               this.pageNumber = this.pagination.currentPage;
               this.noResults = true;
               this.countryId = null;
               this.cityId = null;

          }

          ngOnInit() {
               this.findTours();

               this.userMasterInteractor.getCountriesByUser()
               .subscribe(
                    ( countries: Array<Country> ) => {
                         this.countries = countries;
                    },
                    (error: GenericException) => {
                         this.serverErrorManager.manageErrorCode(error, this.messagePanelComponent);

                    }
               )

               this.translateService.get(I18N('pagination.Siguiente')).subscribe(
                    (messageTranslated: string) => {
                         this.paginationNextLabel = messageTranslated;
                    }
              )

              this.translateService.get(I18N('pagination.Previous')).subscribe(
                    (messageTranslated: string) => {
                         this.paginationPreviousLabel = messageTranslated;
                    }
              )

          }


          findTours() {
               this.providerTourInteractor.findTours(this.keywords, this.countryId, this.cityId, this.pageNumber)
               .subscribe(
                    (chunkTours: ChunkGeneric<TourResume>) => {
                         this.pagination = new Pagination(chunkTours.pagination.totalItems, chunkTours.pagination.currentPage, chunkTours.pagination.itemsPerPage);
                         this.tours = chunkTours.items;
                         this.noResults = this.tours.length == 0;
                    },
                    (error: GenericException) => {
                         this.serverErrorManager.manageErrorCode(error, this.messagePanelComponent);
                    }
               )
          }

          onPageChanged( currentPage: number ) {
               this.pageNumber = currentPage;
               this.findTours();
          }


          onGoToEditTour( tourId: number ) {

               this.router.navigate([ NavigationConstants.getNavigationProviderUpdateTour(tourId) ]);

          }

          onGoToManageGallery( tourId: number ) {
               this.router.navigate([ NavigationConstants.getNavigationProviderTourGalleryManagment(tourId) ]);
          }

          onTogleTourState( tourId: number ) {

               this.messagePanelComponent.showLoading();

               this.tours.map( (tour: TourResume) => {
                    if ( tour.id == tourId ) {
                         if ( tour.state == TourState.Active ) {
                              this.providerTourInteractor.deactivateTour(tourId)
                              .subscribe(
                                   () => {
                                        MessagePanelComponent.hideLoading();
                                        tour.state = TourState.Inactive;
                                   },
                                   (error: GenericException) => {
                                        MessagePanelComponent.hideLoading();
                                        this.serverErrorManager.manageErrorCode(error, this.messagePanelComponent);
                                   }
                              )
                         } else {
                              this.providerTourInteractor.activateTour(tourId)
                              .subscribe(
                                   () => {
                                        MessagePanelComponent.hideLoading();
                                        tour.state = TourState.Active;
                                   },
                                   (error: GenericException) => {
                                        MessagePanelComponent.hideLoading();
                                        this.serverErrorManager.manageErrorCode(error, this.messagePanelComponent);
                                   }
                              )
                         }
                    }
               })

          }


          onSearchKeywordChanged() {
               this.findTours();
          }


          onCountrySelected() {

               if ( this.countryId != this.currentCountrySelected ) {

                    this.currentCountrySelected = this.countryId;
                    this.cities = new Array<City>();
                    this.cityId = null;

                    if ( this.countryId ) {
                         this.userMasterInteractor.getCitiesByUser( this.countryId )
                         .subscribe(
                              (cities: Array<City>) => {
                                   this.cities = cities;
                              },
                              (error: GenericException) => {
                                   this.serverErrorManager.manageErrorCode(error, this.messagePanelComponent);
                              }
                         );
                    }

                    this.findTours();

               }
          }


          onCitySelected() {
               this.findTours();
          }


          onCreateTourClick() {
               this.router.navigate( [ NavigationConstants.getNavigationProviderCreateTour() ] );
          }

          onShowBid(tourResume: TourResume) {

               if (tourResume.bidBlocked){

                    this.messagePanelComponent.showErrorMessage(I18N('tourlist.Puja fijada por el administrador'));

               } else {

                    this.translateService.get('tourlist.El valor mínimo de la ciudad es 2', {value: tourResume.bidMin}).subscribe(
                        (minCityPriceMessageTranslated: string) => {
                            this.translateService.get(I18N('tourlist.Modifica el precio de la puja'), {})
                            .subscribe(
                                (updateBidPriceMessageTranslated: string) => {
                                        this.translateService.get(I18N('tourlist.Por favor, indica una puja válida.'), {})
                                        .subscribe(

                                            (selectValidBidPriceMessageTranslated: string) => {
                                                this.messagePanelComponent.showInputMessage(
                                                    updateBidPriceMessageTranslated,
                                                    minCityPriceMessageTranslated,
                                                    MessagePanelComponent.MESSAGE_INPUT_TYPE_NUMBER,
                                                    tourResume.bid.toString(),
                                                    (value: string) => {
                                                            if ( !value ) {
                                                                return selectValidBidPriceMessageTranslated;
                                                            } else {
                                                                const newBid = parseFloat(value);
                                                                if ( newBid < tourResume.bidMin ) {
                                                                    return selectValidBidPriceMessageTranslated;
                                                                }
                                                            }
                                                    },
                                                    (value: string) => {
                                                            const newBid = parseFloat(value);
                                                            this.providerTourInteractor.updateTourBid(tourResume.id, newBid)
                                                            .subscribe(
                                                                () => {

                                                                    this.messagePanelComponent.showSuccessMessage(I18N('tourlist.Información actualizada'));
                                                                    tourResume.bid = newBid;

                                                                },
                                                                (error: GenericException) => {

                                                                    this.serverErrorManager.manageErrorCode(error, this.messagePanelComponent);

                                                                }
                                                            )
                                                        }
                                                )

                                            }
                                        )
                                }
                            )
                        }
                    )

               }


          }


          onEditGuideSalesCommision(tourResume: TourResume) {
               const modalComponent: NgbModalRef = this.modalService.open(ProviderEditTourGuideSalesCommissionModalComponent, {ariaLabelledBy: 'modal-basic-title', backdrop: 'static'});
               modalComponent.componentInstance.tourResume = tourResume;
               modalComponent.result.then((commission: number) => {
                    tourResume.guideSalesCommission = commission;
               });
          }



          onEditGuideServiceFee(tourResume: TourResume) {
               const modalComponent: NgbModalRef = this.modalService.open(ProviderEditTourGuideServiceFeeModalComponent, {ariaLabelledBy: 'modal-basic-title', backdrop: 'static'});
               modalComponent.componentInstance.tourResume = tourResume;
               modalComponent.result.then((fee: number) => {
                    tourResume.guideServiceFee = fee;
               });
          }

     }
