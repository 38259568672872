import { ImageSize } from './common/image-size.constants';

export class ImageThumb {

    private _size: ImageSize;
    private _isOriginal: boolean;
    private _height: number;
    private _width: number;
    private _url: string;

    constructor(size: ImageSize, isOriginal: boolean, height: number, width: number, url: string) {
        this._size = size;
        this._isOriginal = isOriginal;
        this._height = height;
        this._width = width;
        this._url = url;
    }

    get size(): ImageSize {
      return this._size;
    }

    set size(size: ImageSize) {
      this._size = size;
    }

    get isOriginal(): boolean {
      return this._isOriginal;
    }

    set isOriginal(isOriginal: boolean) {
      this._isOriginal = isOriginal;
    }

    get height(): number {
      return this._height;
    }

    set height(height: number) {
      this._height = height;
    }

    get width(): number {
      return this._width;
    }

    set width(width: number) {
      this._width = width;
    }

    get url(): string {
      return this._url;
    }

    set url(url: string) {
      this._url = url;
    }

}
