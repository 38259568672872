import { TourScheduleWeekdaysType } from 'src/app/domain/common/tour/tour-schedule-weekdays.constants';

export class ScheduleDateWeekdayView {


    private _day: TourScheduleWeekdaysType;
    private _date: Date;

    constructor(
      day: TourScheduleWeekdaysType,
      date: Date
    ) {

        this._day = day;
        this._date = date;

    }


    get day(): TourScheduleWeekdaysType {
        return this._day;
    }

    get date(): Date {
        return this._date;
    }

}