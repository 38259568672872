<form class="form-boxes m-0 p-0" action="" [formGroup]="changePasswordForm">
    <!-- Acceso -->
    <div class="row mb-2">
        <div class="col-10">
            <h4 class="font-weight-bold d-flex"><img class="ic-mid mr-2" src="assets/img/ic_lock-open.svg">{{ 'changepasswordprofile.Datos de acceso' | translate }}</h4>
            <h5>{{ 'changepasswordprofile.Puedes cambiar tu contraseña de acceso' | translate }}</h5>
            <hr class="w-10 float-left mt-2 mb-4">
        </div>
        <div class="col-2 text-right">
            <a class="cursor-pointer" (click)="toggleEdition()">
                <img class="ic-mid mr-2" src="{{ isDisabled ? 'assets/img/ic_edit.svg' : 'assets/img/ic_edit-disabled.svg'}}">
            </a>
        </div>
    </div>

    <div class="row mb-3">
        <div class="col-12 col-md-4">
            <div class="field pb-2">
                <input formControlName="oldPassword" type="password" autocomplete="new-password" name="oldPassword" id="oldPassword" placeholder="{{ 'changepasswordprofile.Contraseña actual' | translate }}" value="">
                <label for="password">{{ 'changepasswordprofile.Contraseña actual' | translate }}</label>
            </div>
            <app-feedback [formGroup]="changePasswordForm" field="oldPassword" autocomplete="new-password" validator="required" feedback="{{ 'changepasswordprofile.Contraseña actual requerida' | translate }}" [refresh]="refreshValidators"></app-feedback>
            <app-feedback [formGroup]="changePasswordForm" field="oldPassword" validator="maxlength" feedback="{{ 'createtourdetails.Texto demasiado largo' | translate }}" [refresh]="refreshValidators"></app-feedback>

        </div>
        <div class="col-12 col-md-4">
            <div class="field pb-2">
                <input formControlName="newPassword" type="password" name="newPassword" autocomplete="new-password" id="newPassword" placeholder="{{ 'changepasswordprofile.Nueva contraseña' | translate }}" value="">
                <label for="password">{{ 'changepasswordprofile.Nueva contraseña' | translate }}</label>
            </div>
            <app-feedback [formGroup]="changePasswordForm" field="newPassword" validator="required" feedback="{{ 'changepasswordprofile.Contraseña nueva requerida' | translate }}" [refresh]="refreshValidators"></app-feedback>
            <app-feedback [formGroup]="changePasswordForm" field="newPassword" validator="maxlength" feedback="{{ 'createtourdetails.Texto demasiado largo' | translate }}" [refresh]="refreshValidators"></app-feedback>
        </div>
        <div class="col-12 col-md-4">
            <div class="field pb-2">
                <input formControlName="retryPassword" type="password" name="retryPassword" id="retryPassword" placeholder="{{ 'changepasswordprofile.Confirmar contraseña' | translate }}" value="">
                <label for="passwordRetry">{{ 'changepasswordprofile.Confirmar contraseña' | translate }}</label>
            </div>
            <app-feedback [formGroup]="changePasswordForm" field="retryPassword" validator="required" feedback="{{ 'changepasswordprofile.Confirmación de contraseña requerida' | translate }}" [refresh]="refreshValidators"></app-feedback>
            <app-feedback [formGroup]="changePasswordForm" field="retryPassword" validator="noequals" feedback="{{ 'changepasswordprofile.Contraseña nueva y su confirmación no coinciden' | translate }}" [refresh]="refreshValidators"></app-feedback>
            <app-feedback [formGroup]="changePasswordForm" field="retryPassword" validator="maxlength" feedback="{{ 'createtourdetails.Texto demasiado largo' | translate }}" [refresh]="refreshValidators"></app-feedback>
        </div>
    </div>

    <div class="row mb-5 pb-5">
        <div class="col-12 col-md-6 offset-md-6 col-lg-4 offset-lg-8">
            <input (click)="savePasswords()" type="submit" value="{{ 'changepasswordprofile.Guardar datos' | translate }}" class="btn btn-full-width btn-primary mb-3 mb-sm-0" [disabled]="isDisabled" />
        </div>
    </div>
</form>



<app-message-panel></app-message-panel>