export class CompanyName {

    private _id: number;
    private _companyName: string;


    constructor(
      id: number,
      companyName: string
    ) {

        this._id = id;
        this._companyName = companyName;
    }


    get id(): number {
        return this._id;
    }

    get companyName(): string {
        return this._companyName;
    }

}
