import { ApiConstants } from 'src/app/business/api/api.constants';
import { CancelationReason } from './cancelation-reason/cancelaltion-reason.constants';

export class BookingUtils {

  public static getCancelationReasonFromApi(apiCancelationReason: any): CancelationReason {

    switch (apiCancelationReason.key) {
      case ApiConstants.API_CANCELATION_REASON_GUIDE_PROBLEM:
        return CancelationReason.GuideProblem;
      case ApiConstants.API_CANCELATION_REASON_FRAUDULENT:
        return CancelationReason.Fraudulent;
      case ApiConstants.API_CANCELATION_REASON_DUPLICATED:
        return CancelationReason.Duplicated;
      case ApiConstants.API_CANCELATION_REASON_CUSTOMER_REQUEST:
        return CancelationReason.CustomerRequest;
      default:
        return null;

    }

  }



  public static getApyTypeFromCancelationReason(cancelationReason: CancelationReason): CancelationReason {


    if (cancelationReason == CancelationReason.GuideProblem) {
      return ApiConstants.API_CANCELATION_REASON_GUIDE_PROBLEM;
    } else if (cancelationReason == CancelationReason.Fraudulent) {
      return ApiConstants.API_CANCELATION_REASON_FRAUDULENT;
    } else if (cancelationReason == CancelationReason.Duplicated) {
      return ApiConstants.API_CANCELATION_REASON_DUPLICATED;
    } else if (cancelationReason == CancelationReason.CustomerRequest) {
      return ApiConstants.API_CANCELATION_REASON_CUSTOMER_REQUEST;
    }

    return null;

  }


}
