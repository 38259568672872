import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Injectable }                                   from '@angular/core';

import { Observable, throwError }                       from 'rxjs';
import { catchError }                                   from 'rxjs/operators';

import { SessionManager }                               from 'src/app/domain/managers/session-manager';
import { ApiConstants } from './api.constants';
import { GenericException } from 'src/app/domain/exceptions/generic.exception';
import { AppConstants } from 'src/app/domain/common/app.constants';

import { environment }                      from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class AppInterceptor implements HttpInterceptor {

    constructor(private sessionManager: SessionManager) { }


    // ----------------------------------------------------------------------------------
    // PRIVATE methods
    // ----------------------------------------------------------------------------------

    // Handles errors at a Global level
    private handleError(error: HttpErrorResponse) {

        let errorMsg: string = `The backend returned an unsucessful response. Code: ${error.status}. Error message was: '${error.statusText}'`;
        let errorCause: string = null;

        if ( error.error && error.error.cause ) {
             if ( error.error.cause.code ) {
                  errorCause = error.error.cause.code;
             }

             if ( error.error.cause.message ) {
                  errorMsg = error.error.cause.message;
             }

        }

        // return an observable with a user-facing error code and message
        switch (error.status) {

            case ApiConstants.ERROR_SERVER_UNKNOWN: // Special case
                return throwError(new GenericException(AppConstants.ERROR_SERVER_UNKNOWN, 'Unexpected error', errorCause));

            case ApiConstants.ERROR_CLIENT_BAD_REQUEST:
                return throwError(new GenericException(AppConstants.ERROR_CLIENT_BAD_REQUEST, errorMsg, errorCause));

            case ApiConstants.ERROR_CLIENT_UNAUTHORIZED:
                return throwError(new GenericException(AppConstants.ERROR_CLIENT_UNAUTHORIZED, errorMsg, errorCause));

            case ApiConstants.ERROR_CLIENT_FORBIDDEN:
                return throwError(new GenericException(AppConstants.ERROR_CLIENT_FORBIDDEN, errorMsg, errorCause));

            case ApiConstants.ERROR_CLIENT_NOT_FOUND:
                return throwError(new GenericException(AppConstants.ERROR_CLIENT_NOT_FOUND, errorMsg, errorCause));

            default:
                return throwError(new GenericException(AppConstants.ERROR_SERVER_FAILURE, errorMsg, errorCause));
        }
    };


    // Mutates the http requests by setting a Content-Type into our Header requests
    private setContentTypeToRequest(request: HttpRequest<any>, content: string) {
        return request.clone({
            setHeaders: {
                'Content-Type': `${content}`
            }
        });
    }


    // Mutates the http requests by adding a Token into our Authorization Header requests
    private addTokenToRequest(request: HttpRequest<any>, token: string) {
        return request.clone({
            setHeaders: {
                'Authorization': `Bearer ${token}`
            }
        });
    }


    // ----------------------------------------------------------------------------------
    // Implement 'intercept' method to intercept and globally configure our Http requests
    // ----------------------------------------------------------------------------------

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        // Mutate request by adding Content-type header for all our Http requests
        //request = this.setContentTypeToRequest(request, 'application/json; charset=UTF-8');

        // Mutate request by adding Token to our Http requests only when a Token exists

        if ( request.url.includes( ApiConstants.API_ENDPOINTS_CLIENT_ANGULAR_PATH ) ) {
            request = this.addTokenToRequest(request, environment.ACCESS_TOKEN_CLIENT_ANGULAR );
        } else if (this.sessionManager.getToken()) {
            request = this.addTokenToRequest(request, this.sessionManager.getToken());
        }

        // Send the intercepted request
        return next.handle(request)
                    // handle at a Global level the response errors for our Http requests
                    .pipe(catchError(this.handleError));
    }

}
