import { Component, ViewChild, Input } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TourResumeAdmin } from 'src/app/business/models/tour/tour-resume-admin.model';
import { AdminTourInteractor } from 'src/app/domain/interactor/admin/tour.interactor';
import { MessagePanelComponent } from 'src/app/ui/components/shared/message-panel/message-panel.component';
import { ServerErrorManager } from 'src/app/ui/components/utils/server-error-manager.utils';

import { marker as I18N }               from '@biesbjerg/ngx-translate-extract-marker';
import { AppConstants } from 'src/app/domain/common/app.constants';
import { GenericException } from 'src/app/domain/exceptions/generic.exception';


@Component({
     selector: 'app-admin-edit-commission-affiliate-modal-component',
     templateUrl: './edit-commission-affiliate-modal.component.html'
})

export class AdminEditTourAffiliateCommisionModalComponent {

     @ViewChild(MessagePanelComponent, { static: false })
     private messagePanelComponent: MessagePanelComponent;

     comissionForm: FormGroup;
     refreshValidators: boolean;
     usingDefaulutCommission: boolean;
     usingPercentage: boolean;


     @Input()
     set tourResume(tourResume: TourResumeAdmin) {
          if (tourResume) {
               this.setCommissionFormValues(tourResume);
          }
     }



     constructor(
          private adminTourInteractor: AdminTourInteractor,
          private serverErrorManager: ServerErrorManager,
          private modal: NgbActiveModal) {

               this.refreshValidators = false;

               this.comissionForm = new FormGroup({
                    'tourId': new FormControl(''),
                    'isDefaultCommission': new FormControl(false, []),
                    'commission': new FormControl('', [Validators.min(0), Validators.max(AppConstants.NUMBER_MAX_VALE)]),
                    'isPercentage': new FormControl(false, []),
                    'isPercentageValid': new FormControl('')
               },
               {
                    validators: [this.isPercentageValid]
               })

          }

          ngOnInit() {
          }


          closeModal() {
               this.modal.close();
          }


          saveCommission() {
               this.refreshValidators = true;

               if (this.comissionForm.valid) {

                    this.messagePanelComponent.showLoading();

                    const tourId: number = this.comissionForm.get('tourId').value;
                    const commission: number = this.comissionForm.get('commission').value;
                    const isPercentage: boolean = this.comissionForm.get('isPercentage').value;
                    const isDefaultCommission: boolean = this.comissionForm.get('isDefaultCommission').value;

                    this.adminTourInteractor.updateTourAffiliateCommission(tourId, isDefaultCommission, commission, isPercentage)
                    .subscribe(
                         (tourResumeUpdated: TourResumeAdmin) => {

                              MessagePanelComponent.hideLoading();
                              this.messagePanelComponent.showSuccessMessage(I18N('editbid.Información actualizada'));
                              this.modal.close(tourResumeUpdated);

                         },
                         (error: GenericException) => {

                              MessagePanelComponent.hideLoading();
                              this.serverErrorManager.manageErrorCode(error, this.messagePanelComponent);

                         }
                    )

               }

          }

          setCommissionFormValues(tourResume: TourResumeAdmin) {

               let isDefaultCommission = false;
               let commission = tourResume.customAffiliateCommission;
               let isPercentage = false;
               if ( commission == null ) {
                    commission = tourResume.customAffiliateCommissionBidFare;
                    isPercentage = true;
               }

               if ( tourResume.customAffiliateCommission == null && tourResume.customAffiliateCommissionBidFare == null ) {
                    isDefaultCommission = true;
               }

               this.comissionForm.get('tourId').setValue(tourResume.id);
               this.comissionForm.get('isDefaultCommission').setValue(isDefaultCommission);
               this.comissionForm.get('commission').setValue(commission);
               this.comissionForm.get('isPercentage').setValue(isPercentage);

               this.usingDefaulutCommission = isDefaultCommission;
               this.usingPercentage = isPercentage;

          }


          isPercentageValid(formGroup: FormGroup): null {
               const isDefaultCommission = formGroup.get('isDefaultCommission').value;
               const isPercentage = formGroup.get('isPercentage').value;
               const commission = formGroup.get('commission').value;
               const isPercentageValidFormGroup = formGroup.get('isPercentageValid');
               if ( !isDefaultCommission && isPercentage ) {
                    if ( commission < 0 || commission > 1 ) {
                         isPercentageValidFormGroup.setErrors({mustPercentageValid: true});
                    } else {
                         isPercentageValidFormGroup.setErrors(null);
                    }
               } else {
                    isPercentageValidFormGroup.setErrors(null);
               }
               return null;


          }


          onChangeDefaultCommission() {
               this.usingDefaulutCommission = this.comissionForm.get('isDefaultCommission').value;
          }

          onChangeIsPercentage() {
               this.usingPercentage = this.comissionForm.get('isPercentage').value;
          }

     }




