import { ApiConstants } from 'src/app/business/api/api.constants';
import { ImageUtils } from '../../image/common/image.utils';
import { City } from '../../master/city/city.model';
import { Country } from '../../master/country/country.model';
import { Language } from '../../master/language/language.model';
import { BookEvent } from '../book-event.model';
import { BookingState } from './booking-state.constants';
import { CalendarUtils } from 'src/app/ui/utils/calendar.utils';
import { TourUtils } from '../../tour/common/tour.utils';
import { Event } from '../event.model';
import { BookFare } from '../book-fare.model';
import { Book } from '../book.model';
import { EventDetails } from '../event-details.model';
import { ProviderUtils } from '../../provider/common/provider.utils';
import { BookingOrder } from '../booking-order.model';
import { BookingOrderState } from './booking-order-state.constants';
import { BookingOrderCustomer } from '../booking-order-customer.model';
import { BookingPaymentState } from './booking-payment-state.constants';
import { BookingOrderPayment } from '../booking-order-payment.view';
import { BookingBilling } from '../../billing/booking-billing.model';
import { BillingUtils } from '../../billing/common/billing.utils';
import { CustomerDetails } from '../../billing/customer-details.model';
import { BookingGuideConfirmationState } from './booking-guide-confirmation-state.constants';
import { GuideUtils } from '../../guide/common/guide.utils';

export class BookingUtils {

     public static getStateFromApi(state: number): BookingState {

          if ( state == ApiConstants.API_BOOKING_STATE_PENDING ) {
               return BookingState.Pending
          } else if ( state == ApiConstants.API_BOOKING_STATE_CONFIRMED ) {
               return BookingState.Confirmed
          } else if ( state == ApiConstants.API_BOOKING_STATE_CANCELED_BY_USER ) {
               return BookingState.CanceledByUser
          } else {
               return BookingState.CanceledByProvider
          }
     }



     public static getGuideConfirmationStateFromApi(state: number): BookingGuideConfirmationState {

          if ( state == null ) {
               return null;
          } else if ( state == ApiConstants.API_BOOKING_GUIDE_CONFIRMATION_STATE_SHOWED ) {
               return BookingGuideConfirmationState.Showed;
          } else {
               return BookingGuideConfirmationState.NotShowed;
          }
     }

     public static getGuideConfirmationStateApiFromBookingGuideConfirmationState(bookingGuideConfirmationState: BookingGuideConfirmationState): BookingGuideConfirmationState {

          if ( bookingGuideConfirmationState == null ) {
               return null;
          } else if ( bookingGuideConfirmationState == BookingGuideConfirmationState.Showed ) {
               return ApiConstants.API_BOOKING_GUIDE_CONFIRMATION_STATE_SHOWED
          } else {
               return ApiConstants.API_BOOKING_GUIDE_CONFIRMATION_STATE_NOT_SHOWED
          }
     }



     public static getBookEventFromApi(bookEventApi: any): BookEvent {

          const eventApi = bookEventApi.event;
          const tourApi = eventApi.tour;

          const event = new Event(
               tourApi.id,
               tourApi.i18n.translation.name,
               new Language(tourApi.languageTourGiven.id, tourApi.languageTourGiven.code, tourApi.languageTourGiven.name),
               new Country(tourApi.country.id, tourApi.country.code, tourApi.country.name),
               new City(tourApi.city.id, tourApi.city.name),
               tourApi.isFree,
               ImageUtils.getImageDetailsFromApi(tourApi.firstImage),
               CalendarUtils.fromFormattedDateToDDMMYYYY(eventApi.day, ApiConstants.API_DATE_FORMAT_DD_MM_YYYY),
               eventApi.hour,
               TourUtils.getTourStateFromApi(tourApi.state),
               ProviderUtils.getProviderResumeFromApi(tourApi.provider)
          )

          let percentageCapacity = 0;
          if ( bookEventApi.capacity > 0 ) {
               percentageCapacity = Math.floor((bookEventApi.bookings / bookEventApi.capacity) * 100);
          }
          const bookEvent = new BookEvent(
               event,
               bookEventApi.capacity,
               bookEventApi.bookings,
               bookEventApi.availability,
               bookEventApi.shows,
               percentageCapacity,
               bookEventApi.event.bookingsAreBlocked
          )

          return bookEvent;

     }




     public static getBookEventDetailsFromApi(bookEventDetailsApi: any): EventDetails {

          const eventApi = bookEventDetailsApi.event;
          const tourApi = eventApi.tour;

          let eventPeople: number = 0;
          let eventShows: number = 0;

          const bookings = new Array<Book>();
          bookEventDetailsApi.bookings.map((bookingApi: any) => {

               const bookingState: BookingState = BookingUtils.getStateFromApi(bookingApi.state);

               //ONly valid for guides and null otherwise
               const bookingGuideConfirmationState: BookingGuideConfirmationState = BookingUtils.getGuideConfirmationStateFromApi(bookingApi.guideConfirmationState);

               const bookFares = new Array<BookFare>();

               let bookPeople: number = 0;
               let bookShows: number = 0;

               bookingApi.fares.map((bookingFareApi: any) => {


                    const bookFare = new BookFare(
                         bookingFareApi.orderBookingFareId,
                         bookingFareApi.isChildren,
                         bookingFareApi.isAdult,
                         bookingFareApi.nameInProviderLanguage,
                         bookingFareApi.nameInMainLanguage,
                         bookingFareApi.quantity,
                         bookingFareApi.shows
                    );

                    bookFares.push(bookFare);

                    bookPeople += bookingFareApi.quantity;
                    bookShows += bookingFareApi.shows;

               });



               const booking = new Book(
                    bookingApi.id,
                    bookingApi.email,
                    bookingApi.name,
                    bookingApi.surname,
                    bookingApi.phone,
                    bookPeople,
                    bookShows,
                    bookFares,
                    bookingState,
                    bookingGuideConfirmationState
               );

               bookings.push(booking);

               if ( bookingState == BookingState.Confirmed ) {
                    eventPeople += bookPeople;
                    eventShows += bookShows;
               }

          });



          const eventDetails = new EventDetails(
               tourApi.id,
               tourApi.i18n.translation.name,
               new Language(tourApi.languageTourGiven.id, tourApi.languageTourGiven.code, tourApi.languageTourGiven.name),
               new Country(tourApi.country.id, tourApi.country.code, tourApi.country.name),
               new City(tourApi.city.id, tourApi.city.name),
               tourApi.isFree,
               ImageUtils.getImageDetailsFromApi(tourApi.firstImage),
               CalendarUtils.fromFormattedDateToDDMMYYYY(eventApi.day, ApiConstants.API_DATE_FORMAT_DD_MM_YYYY),
               eventApi.hour,
               eventPeople,
               eventShows,
               bookings,
               eventApi.showsCanBeUpdated,
               eventApi.bookingsCanBeCancelled
          )


          return eventDetails;

     }


     public static getStateAffiliateCommisionFromApi(state: number): BookingState {

          if ( state == ApiConstants.API_AFFILIATE_COMMISSION_STATE_CONFIRMED ) {
               return BookingState.Confirmed;
          } else {
               return BookingState.Canceled;
          }
     }



     public static getStateGuideCommisionFromApi(state: number): BookingState {

          if ( state == ApiConstants.API_GUIDE_COMMISSION_STATE_CONFIRMED ) {
               return BookingState.Confirmed;
          } else if ( state == ApiConstants.API_GUIDE_COMMISSION_STATE_PENDING ) {
               return BookingState.Pending;
          } else {
               return BookingState.Canceled;
          }
     }


     public static getStateOrderBookingFromApi(state: number): BookingOrderState {

          if ( state == ApiConstants.API_ORDER_BOOKING_STATE_PENDING ) {
               return BookingOrderState.Pending;
          } else {
               return BookingOrderState.Completed;
          }
     }

     public static getStateOrderBookingPaymentFromApi(state: number): BookingPaymentState {

          if ( state == ApiConstants.API_BOOK_PAYMENT_STATE_PENDING ) {
               return BookingPaymentState.Pending;
          } else {
               return BookingPaymentState.Completed;
          }
     }


     public static getBookingOrderCustomerFromApi(bookingOrderCustomerApi: any): BookingOrderCustomer {

          return new BookingOrderCustomer(
               bookingOrderCustomerApi.id,
               bookingOrderCustomerApi.email,
               bookingOrderCustomerApi.name,
               bookingOrderCustomerApi.surname,
               bookingOrderCustomerApi.phone
          );
     }


     public static getBookingOrderPaymentFromApi(bookingOrderPaymentsApi: any): BookingOrderPayment {

          if ( bookingOrderPaymentsApi.length == 0 ) {
               return null;
          } else {
               const bookingOrderPaymentApi = bookingOrderPaymentsApi[0];
               return new BookingOrderPayment(
                    bookingOrderPaymentApi.id,
                    bookingOrderPaymentApi.price,
                    bookingOrderPaymentApi.stripeClientSecret,
                    BookingUtils.getStateOrderBookingPaymentFromApi(bookingOrderPaymentApi.state)
               );
          }
     }


     public static getBookingOrderFromApi(bookingOrderApi: any, localeI18N: string): BookingOrder {

          let bookEvent: BookEvent = null;
          if ( bookingOrderApi.bookings && bookingOrderApi.bookings.length > 0 ) {
               bookEvent = BookingUtils.getBookEventFromApi(bookingOrderApi.bookings[0]);
          }

          return new BookingOrder(
               bookingOrderApi.id,
               bookingOrderApi.totalPrice,
               BookingUtils.getBookingOrderPaymentFromApi(bookingOrderApi.payments),
               BookingUtils.getBookingOrderCustomerFromApi(bookingOrderApi.customer),
               BookingUtils.getStateOrderBookingFromApi(bookingOrderApi.state),
               bookEvent
          )
     }


     public static getBookingBillingFromApi(bookingBillingApi: any): BookingBilling {

          return new BookingBilling(
               bookingBillingApi.orderBooking.id,
               bookingBillingApi.orderBooking.event.tour.id,
               bookingBillingApi.orderBooking.event.tour.i18n.translation.name,
               CalendarUtils.fromFormattedDateToDDMMYYYY(bookingBillingApi.orderBooking.event.day, ApiConstants.API_DATE_FORMAT_DD_MM_YYYY),
               bookingBillingApi.orderBooking.event.hour,
               ( ApiConstants.API_INVOICE_STATE_INVOICED == bookingBillingApi.state ) ? BillingUtils.getInvoiceFromApi(bookingBillingApi.invoice) : null,
               BillingUtils.getBillingDetailsFromApi(bookingBillingApi.info),
               new CustomerDetails(bookingBillingApi.customer.id, bookingBillingApi.customer.email, bookingBillingApi.customer.name, bookingBillingApi.customer.surname, bookingBillingApi.customer.phone),
               BookingUtils.getStateAffiliateCommisionFromApi(bookingBillingApi.info.state),
               bookingBillingApi.guide ? GuideUtils.getGuideResumeFromApi(bookingBillingApi.guide) : null
               
          );
     }



     public static getGuideBookingBillingFromApi(bookingBillingApi: any): BookingBilling {

          return new BookingBilling(
               bookingBillingApi.orderBooking.id,
               bookingBillingApi.orderBooking.event.tour.id,
               bookingBillingApi.orderBooking.event.tour.i18n.translation.name,
               CalendarUtils.fromFormattedDateToDDMMYYYY(bookingBillingApi.orderBooking.event.day, ApiConstants.API_DATE_FORMAT_DD_MM_YYYY),
               bookingBillingApi.orderBooking.event.hour,
               null,
               BillingUtils.getBillingDetailsFromApi(bookingBillingApi.info),
               new CustomerDetails(bookingBillingApi.customer.id, bookingBillingApi.customer.email, bookingBillingApi.customer.name, bookingBillingApi.customer.surname, bookingBillingApi.customer.phone),
               BookingUtils.getStateGuideCommisionFromApi(bookingBillingApi.info.state),
               bookingBillingApi.guide ? GuideUtils.getGuideResumeFromApi(bookingBillingApi.guide) : null
          );
     }

}
