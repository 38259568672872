import { BillingDetails } from './billing-details.model';

export class InvoiceDetails {

  private _id: number;
  private _serialNumber: number;
  private _invoiceDate: string;
  private _invoiceYearPeriod: number;
  private _invoiceMonthPeriod: number;
  private _billingDetails: BillingDetails;
  private _chargePending: boolean;
  private _chargeCompleted: boolean;
  private _paymentPending: boolean;
  private _paymentCompleted: boolean;
  private _settledUp: boolean;

  constructor(
    id: number,
    serialNumber: number,
    invoiceDate: string,
    invoiceYearPeriod: number,
    invoiceMonthPeriod: number,
    billingDetails: BillingDetails,
    chargePending: boolean,
    paymentPending: boolean,
    chargeCompleted: boolean,
    paymentCompleted: boolean,
    settledUp: boolean
  ) {
    this._id = id;
    this._serialNumber = serialNumber;
    this._invoiceDate = invoiceDate;
    this._invoiceYearPeriod = invoiceYearPeriod;
    this._invoiceMonthPeriod = invoiceMonthPeriod;
    this._billingDetails = billingDetails;
    this._chargePending = chargePending;
    this._chargeCompleted = chargeCompleted;
    this._paymentPending = paymentPending;
    this._paymentCompleted = paymentCompleted;
    this._settledUp = settledUp;
  }

  get id(): number {
    return this._id;
  }

  get serialNumber(): number {
    return this._serialNumber;
  }

  get invoiceDate(): string {
    return this._invoiceDate;
  }

  get invoiceYearPeriod(): number {
    return this._invoiceYearPeriod;
  }

  get invoiceMonthPeriod(): number {
    return this._invoiceMonthPeriod;
  }

  get billingDetails(): BillingDetails {
    return this._billingDetails;
  }

  get chargePending(): boolean {
    return this._chargePending;
  }

  get chargeCompleted(): boolean {
    return this._chargeCompleted;
  }

  get paymentPending(): boolean {
    return this._paymentPending;
  }

  get paymentCompleted(): boolean {
    return this._paymentCompleted;
  }

  get settledUp(): boolean {
    return this._settledUp;
  }


}
