import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'mainTranslationEscaped'
})
export class MainTranslationEscapedPipe implements PipeTransform {

  transform(label: string, showFullText: boolean, maxLength: number): String {

    let result = label;

    if ( label && maxLength ) {

        result = label.replace(/<\/?[^>]+(>|$)/g, "");

        if ( !showFullText ) {
            result = result.substr(0, maxLength);
        }

    }

    return result ;

  }

}
