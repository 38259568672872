<app-list-services
     [isProvider]="true"
     [providers]="providers"
     [guides]="guides"
     [chunkCommissions]="guideServices"
     [guideServiceStats]="guideServiceStats"
     (filterCommissions)="onFilterCommissions($event)"
     (createCommission)="onCreateCommission()"
     (approveService)="onApproveService($event)"
     (editService)="onEditService($event)"
     (deleteService)="onDeleteService($event)"
     (providerSelectedTrigger)="onProviderSelectedTrigger($event)"
></app-list-services>

<app-message-panel [preloading]="true"></app-message-panel>