<form class="form-boxes m-0 p-0" [formGroup]="createTourLocationForm">

    <div class="row">
        <div class="col-12 col-md-12">
            <div class="field pb-2">
                <input formControlName="meetingPoint" type="text" name="meetingPoint" id="meetingPoint">
                <app-main-translation [i18nTraslation]="i18nTraslation" [mainTranslation]="meetingPointMain"></app-main-translation>
                <label for="meetingPoint">{{ 'createtourlocation.Nombre del meeting point' | translate }}</label>
            </div>
            <app-feedback [formGroup]="createTourLocationForm" field="meetingPoint" validator="required" feedback="{{ 'createtourlocation.Nombre del meeting point requerido' | translate }}" [refresh]="refreshValidators"></app-feedback>
            <app-feedback [formGroup]="createTourLocationForm" field="meetingPoint" validator="maxlength" feedback="{{ 'createtourdetails.Texto demasiado largo' | translate }}" [refresh]="refreshValidators"></app-feedback>

        </div>
    </div>
    <div class="row">
        <div class="col-12 pb-3">
            <label for="location">{{ 'createtourlocation.Selecciona la ubicación exacta en el mapa' | translate }}</label>
        </div>
    </div>
    <div class="row">
        <div class="col-12 col-md-6">
            <div class="field pb-2">
                <input (change)="onLocationCoordsUpdated()" formControlName="locationCoordX" type="number" name="locationCoordX" id="locationCoordX" placeholder="{{ 'createtourlocation.Latitud' | translate }}">
            </div>
            <app-feedback [formGroup]="createTourLocationForm" field="locationCoordX" validator="required" feedback="{{ 'createtourlocation.Latitud requerida' | translate }}" [refresh]="refreshValidators"></app-feedback>
            <app-feedback [formGroup]="createTourLocationForm" field="locationCoordX" validator="max" feedback="{{ 'createtourdetails.Número demasiado grande' | translate }}" [refresh]="refreshValidators"></app-feedback>

        </div>
        <div class="col-12 col-md-6">
            <div class="field pb-2">
                <input (change)="onLocationCoordsUpdated()" formControlName="locationCoordY" type="number" name="locationCoordY" id="locationCoordY" placeholder="{{ 'createtourlocation.Longitud' | translate }}">
            </div>
            <app-feedback [formGroup]="createTourLocationForm" field="locationCoordY" validator="required" feedback="{{ 'createtourlocation.Longitud requerida' | translate }}" [refresh]="refreshValidators"></app-feedback>
            <app-feedback [formGroup]="createTourLocationForm" field="locationCoordY" validator="max" feedback="{{ 'createtourdetails.Número demasiado grande' | translate }}" [refresh]="refreshValidators"></app-feedback>
        </div>
    </div>
    <div class="row">
        <div class="col-12 pb-3">
            <agm-map class="map-container" [latitude]="latitude" [longitude]="longitude" [zoom]="zoom" [mapTypeControl]='true'>
                <agm-marker [latitude]="latitude" [longitude]="longitude" [markerDraggable]="!i18nTraslation" (dragEnd)="markerDragEnd($event)"></agm-marker>
            </agm-map>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <div class="field pb-2">
                <textarea id="" rows="2" cols="50" formControlName="locationIndications" type="text" name="locationIndications" id="locationIndications"></textarea>
                <app-main-translation [i18nTraslation]="i18nTraslation" [mainTranslation]="locationIndicationsMain"></app-main-translation>
                <label for="locationIndications">{{ 'createtourlocation.Indicaciones para que los usuarios puedan encontrar la actividad ' | translate }}<span class="font-italic">{{ 'createtourdetails.(Opcional)' | translate }}</span></label>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <div class="field pb-2">
                <textarea id="" rows="2" cols="50" formControlName="locationMoreIndications" type="text" name="locationMoreIndications" id="locationMoreIndications"></textarea>
                <app-main-translation [i18nTraslation]="i18nTraslation" [mainTranslation]="locationMoreIndicationsMain"></app-main-translation>
                <label for="locationMoreIndications">{{ 'createtourlocation.Información adicional que los usuarios necesitan saber para la actividad ' | translate }}<span class="font-italic">{{ 'createtourdetails.(Opcional)' | translate }}</span></label>
            </div>
        </div>
    </div>

    <div class="row mb-5 pb-5 control-footer">
        <div class="col-6 col-md-5 col-lg-4">
            <button (click)="onBackButton()" type="submit" value="{{ 'createtourlocation.Volver' | translate }}" class="btn btn-full-width btn-no-btn mb-3 mb-sm-0 dark-color">
                <img class="ic-small mr-2" src="assets/img/ic_arrow-back.svg">
                {{ 'createtourlocation.Volver' | translate }}
            </button>
        </div>

        <div class="col-6 col-md-5 offset-md-2 col-lg-4 offset-lg-4">
            <button (click)="saveLocation()" type="submit" value="{{ 'createtourlocation.Siguiente' | translate }}" class="btn btn-full-width btn-primary mb-3 mb-sm-0 white-color">
                {{ 'createtourlocation.Siguiente' | translate }}
                <img class="ic-small ml-2" src="assets/img/ic_arrow-next.svg">
            </button>
        </div>
    </div>
</form>