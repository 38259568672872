import { AffiliateDailyStats } from './affiliate-daily-stats.model';
import { AffiliateStats } from './affiliate-stats.model';

export class AffiliateStatsDetails {

     private _totalCommissionAndVat: number;
     private _nextBillingStats: AffiliateStats;
     private _dailyStats: AffiliateDailyStats;

     constructor(
          totalCommissionAndVat: number,
          nextBillingStats: AffiliateStats,
          dailyStats: AffiliateDailyStats
     ) {

          this._totalCommissionAndVat = totalCommissionAndVat;
          this._nextBillingStats = nextBillingStats;
          this._dailyStats = dailyStats;

     }

     get totalCommissionAndVat(): number {
          return this._totalCommissionAndVat;
     }

     get nextBillingStats(): AffiliateStats {
          return this._nextBillingStats;
     }

     get dailyStats(): AffiliateDailyStats {
          return this._dailyStats;
     }

}
