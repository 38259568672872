import { Component, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthenticateInteractor } from 'src/app/domain/interactor/authenticate.interactor';
import { MessagePanelComponent } from '../../../shared/message-panel/message-panel.component';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { AppConstants } from 'src/app/domain/common/app.constants';
import { NavigationConstants } from 'src/app/ui/commons/navigation.constants';
import { marker as I18N } from '@biesbjerg/ngx-translate-extract-marker';
import { SessionInteractor } from 'src/app/domain/interactor/session.interactor';
import { NavigatorUtils } from 'src/app/ui/utils/navigator.utils';
import { ServerErrorManager } from '../../../utils/server-error-manager.utils';
import { GenericException } from 'src/app/domain/exceptions/generic.exception';

@Component({
  selector: 'app-remember-password-confirmation',
  templateUrl: './remember-password-confirmation.component.html'
})
export class RememberPasswordConfirmationComponent {

  @ViewChild(MessagePanelComponent)
  private messagePanelComponent: MessagePanelComponent;

  refreshValidators: boolean;


  rememberPasswordConfirmForm: FormGroup;

  constructor(
    private authenticateInteractor: AuthenticateInteractor,
    private sessionInteractor: SessionInteractor,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private serverErrorManager: ServerErrorManager ) {

        this.refreshValidators = false;

        this.rememberPasswordConfirmForm = new FormGroup({
            'username': new FormControl('', [Validators.required] ),
            'hash': new FormControl('', [Validators.required] ),
            'newPassword': new FormControl('', [Validators.required, Validators.maxLength(AppConstants.TEXT_DEFAULT_MAX_LENGTH)] ),
            'retryPassword': new FormControl('', [Validators.required, this.noEqualValidator, Validators.maxLength(AppConstants.TEXT_DEFAULT_MAX_LENGTH)] )
        });

        this.activatedRoute.queryParams.subscribe(params => {

            this.rememberPasswordConfirmForm.controls['hash'].setValue(params['hash'])
            this.rememberPasswordConfirmForm.controls['username'].setValue(params['username'])

            const languageCode = params['languageCode']
            if ( this.sessionInteractor.setCurrentLanguage(languageCode) ) {
                NavigatorUtils.reloadApplication();
            }

        });

  }


  rememberPassword() {

      this.refreshValidators = true;

      if ( this.rememberPasswordConfirmForm.valid ) {

        this.messagePanelComponent.showLoading();

        this.authenticateInteractor.changePasswordRemembered(
                  this.rememberPasswordConfirmForm.value.username,
                  this.rememberPasswordConfirmForm.value.hash,
                  this.rememberPasswordConfirmForm.value.newPassword,
                  this.rememberPasswordConfirmForm.value.retryPassword,
                )
            .subscribe(
                () => {

                    MessagePanelComponent.hideLoading();
                    this.messagePanelComponent.showSuccessMessage(I18N('rememberpasssword.Contraseña actualizada'), () => this.goToLogin());

                },
                (error: GenericException) => {

                    MessagePanelComponent.hideLoading();

                    if ( error.code === AppConstants.ERROR_CLIENT_NOT_FOUND ) {
                      this.messagePanelComponent.showErrorMessage(I18N('rememberpasssword.No existe una cuenta vinculada a este email'));
                    } else {
                      this.serverErrorManager.manageErrorCode(error, this.messagePanelComponent);
                    }
                }
            )
      }
  }


  noEqualValidator( control: FormControl ): { [s:string]:boolean } {
    if ( control.parent && control.value != control.parent.controls['newPassword'].value ) {
        return {
            noequals: true
        };
    }
    return null;
  }


  goToLogin() {
      this.router.navigate([ NavigationConstants.NAVIGATION_LOGIN ]);
  }

  goToHomeLanding() {
    this.router.navigate( [ NavigationConstants.getNavigationHome() ] );
  }

  goToContact() {
    this.router.navigate( [ NavigationConstants.getNavigationHome() ], { fragment: NavigationConstants.NAVIGATION_HOME_ANCHOR_CONTACT } );
  }

}
