import { ApiConstants } from 'src/app/business/api/api.constants';
import { GuideState } from './guide-state.constants';
import { GuideResume } from '../guide-resume.model';
import { Guide } from '../guide.model';
import { TourResume } from '../../tour/tour-resume.model';
import { TourUtils } from '../../tour/common/tour.utils';

export class GuideUtils {

     public static getStateFromApi(state: number): GuideState {

          if ( state == ApiConstants.API_GUIDE_STATE_ACTIVE ) {
               return GuideState.Active;
          } else {
               return GuideState.Inactive;
          }
     }


     public static getGuideResumeFromApi(guideResumeFromApi: any): GuideResume {
          return new GuideResume(
               guideResumeFromApi.id,
               guideResumeFromApi.username,
               guideResumeFromApi.email,
               guideResumeFromApi.businessName,
               guideResumeFromApi.countryName,
               guideResumeFromApi.cityName,
               guideResumeFromApi.phone,
               GuideUtils.getStateFromApi(guideResumeFromApi.state),
          )
     }


     public static getGuideFromApi(guideFromApi: any, localeI18N: string): Guide {

          const managedTours: Array<TourResume> = new Array<TourResume>();
          guideFromApi.managedTours.map( (tourBasicApi: any) => {
               managedTours.push(TourUtils.getTourResumeFromApi(tourBasicApi, localeI18N));
          });

          const resellerTours: Array<TourResume> = new Array<TourResume>();
          guideFromApi.resellerTours.map( (tourBasicApi: any) => {
               resellerTours.push(TourUtils.getTourResumeFromApi(tourBasicApi, localeI18N));
          });

          return new Guide(
               guideFromApi.id,
               guideFromApi.username,
               guideFromApi.languageId,
               guideFromApi.businessName,
               guideFromApi.businessAddress,
               guideFromApi.businessDocumentType,
               guideFromApi.businessDocument,
               guideFromApi.businessZeroVat,
               guideFromApi.phone,
               guideFromApi.countryId,
               guideFromApi.countryName,
               guideFromApi.cityId,
               guideFromApi.cityName,
               guideFromApi.paymentCurrency,
               guideFromApi.iban,
               GuideUtils.getStateFromApi(guideFromApi.state),
               managedTours,
               resellerTours
          )

     }

}
