<div class="animated fadeIn">
    <div *ngIf="provider != null" class="row header-dashboard align-items-lg-center align-items-start">
        <div class="col-12">
            <div class="row mb-3 mb-lg-auto">
                <div class="col-12 col-sm-6 col-lg-9 pl-0">
                    <h2 class="font-weight-bold"> {{ provider.companyName }}</h2>
                    <div [ngClass]="'status' | adminProviderStateClass: provider.registrationState : provider.state">
                        <div class="dot"></div> {{ '' | adminProviderStateName: provider.registrationState : provider.state | translate }}
                    </div>
                </div>
                <div *ngIf="isStatePending" class="col-12 col-sm-6 col-lg-3 text-left text-sm-right mt-auto pb-2 p-0 p-auto">
                    <a (click)="doResendWelcomeEmail()" class="link-terciary text-uppercase text-underline cursor-pointer mb-sm-0">{{ 'providerdetails.Reenviar email' | translate }}</a>
                </div>
                <div *ngIf="isStatePendingAproval" class="col-12 col-sm-6 col-lg-3 text-left text-sm-right my-auto p-0 p-auto">
                    <a (click)="doConfirmProvider()" class="link-terciary text-uppercase text-underline cursor-pointer mb-sm-0">{{ 'providerdetails.Confirmar cuenta' | translate }}</a>
                </div>
            </div>
            <div class="row">
                <div *ngIf="isStatePendingAproval" class="col-12 text-left text-sm-right mt-auto pb-2 p-0 p-auto pt-2">
                    <a (click)="doRejectProvider()" class="link-terciary cursor-pointer mb-sm-0">
                        <img class="ic-mid mb-1 ic-report" src="assets/img/ic_report.svg"> {{ 'providerdetails.Solicitar información adicional' | translate }}
                    </a>
                </div>
            </div>
        </div>
    </div>

    <div class="row dashboard-content-section">
        <div class="col-12 px-0">

            <app-profile-provider-details [provider]="provider" (providerDetails)="onProviderDetailsSubmit($event)"></app-profile-provider-details>
            <app-profile-payment [provider]="provider" (profilePayment)="onProviderPaymentSubmit($event)"></app-profile-payment>
            <app-stripe-profile-payment [provider]="provider" [isAdmin]="true"
                    (createConnectedAccount)="onCreateConnectedStripeAccount()"
                    (removeAccount)="onRemoveStripeAccount()"
                    (updateAccount)="onUpdateStripeAccount()">
            </app-stripe-profile-payment>

        </div>
    </div>

    <div *ngIf="isUnregisterAvailable" class="row mt-3 mb-5 pb-3">
        <div class="col-12 text-center">
            <a (click)="doUnregisterProvider()" class="link-terciary text-uppercase text-underline cursor-pointer mb-sm-0">{{ 'providerdetails.Dar de baja el proveedor' | translate }}</a>
        </div>
    </div>

    <div *ngIf="isRegisterAvailable" class="row mt-3 mb-5 pb-3">
        <div class="col-12 text-center">
            <a (click)="doRegisterProvider()" class="link-terciary text-uppercase text-underline cursor-pointer mb-sm-0">{{ 'providerdetails.Dar de alta el proveedor' | translate }}</a>
        </div>
    </div>

</div>


<app-message-panel [preloading]="true"></app-message-panel>