<div class="animated fadeIn">
    <div class="row header-dashboard align-items-lg-center align-items-start">
        <div class="col-12 pl-0">
            <h2 class="font-weight-bold">{{ 'createaffiliate.Alta de afiliado' | translate }}</h2>
            <p>{{ 'createaffiliate.Datos básicos del afiliado' | translate }}</p>
        </div>
    </div>

    <form class="form-boxes m-0 p-0">
        <div class="row dashboard-content-section">
            <div class="col-12 px-0">
                <form action="" [formGroup]="createAffiliateForm" class="form-boxes">
                    <div class="row">
                        <div class="col-12 col-md-4">
                            <div class="field pb-2">
                                <input formControlName="companyName" name="companyName" type="text" id="companyName" placeholder="{{ 'createaffiliate.Nombre de la empresa' | translate }}" value="">
                                <label for="companyName">{{ 'createaffiliate.Nombre de la empresa' | translate }}</label>
                            </div>
                            <app-feedback [formGroup]="createAffiliateForm" field="companyName" validator="required" feedback="{{ 'createaffiliate.Company name required' | translate }}" [refresh]="refreshValidators"></app-feedback>
                            <app-feedback [formGroup]="createAffiliateForm" field="companyName" validator="maxlength" feedback="{{ 'createaffiliate.Texto demasiado largo' | translate }}" [refresh]="refreshValidators"></app-feedback>
                        </div>
                        <div class="col-12 col-md-4">
                            <div class="field">
                                <input formControlName="email" name="email" type="text" id="email" placeholder="{{ 'createaffiliate.Email' | translate }}" value="">
                                <label for="email">{{ 'createaffiliate.Email' | translate }}</label>
                            </div>
                            <app-feedback [formGroup]="createAffiliateForm" field="email" validator="required" feedback="{{ 'createaffiliate.Email required' | translate }}" [refresh]="refreshValidators"></app-feedback>
                            <app-feedback [formGroup]="createAffiliateForm" field="email" validator="email" feedback="{{ 'createaffiliate.Email not valid' | translate }}" [refresh]="refreshValidators"></app-feedback>
                            <app-feedback [formGroup]="createAffiliateForm" field="email" validator="maxlength" feedback="{{ 'createaffiliate.Texto demasiado largo' | translate }}" [refresh]="refreshValidators"></app-feedback>
                        </div>
                        <div class="col-12 col-md-4">
                            <div class="field pb-2">
                                <select formControlName="language" name="language">
                                    <option *ngFor="let language of languages" value="{{language.id}}">{{language.name}}</option>
                                </select>
                                <label for="language">{{ 'createaffiliate.Idioma' | translate }}</label>
                            </div>
                            <app-feedback [formGroup]="createAffiliateForm" field="language" validator="required" feedback="{{ 'createaffiliate.Idioma required' | translate }}" [refresh]="refreshValidators"></app-feedback>
                        </div>


                       <div class="col-12 col-md-6">
                         <div class="field pb-2">
                             <select formControlName="country" name="country" (change)="onCountrySelected()">
                                 <option [ngValue]="null">{{ 'singup.Selecciona la opción' | translate }}</option>
                                 <option *ngFor="let country of countries" value="{{ country.id }}">{{ country.name }}</option>
                             </select>
                             <label for="country">{{ 'singup.País' | translate }}</label>
                         </div>
                         <app-feedback [formGroup]="createAffiliateForm" field="country" validator="required" feedback="{{ 'singup.País requerido' | translate }}" [refresh]="refreshValidators"></app-feedback>
                     </div>
                     <div class="col-12 col-md-6">
                         <div class="field pb-2">
                             <select formControlName="city" name="city">
                               <option [ngValue]="null">{{ 'singup.Selecciona la opción' | translate }}</option>
                               <option *ngFor="let city of cities" value="{{ city.id }}">{{ city.name }}</option>
                           </select>
                             <label for="city">{{ 'singup.Ciudad' | translate }}</label>
                         </div>
                         <app-feedback [formGroup]="createAffiliateForm" field="city" validator="required" feedback="{{ 'singup.Ciudad requerida' | translate }}" [refresh]="refreshValidators"></app-feedback>
                     </div>


                    </div>

                    <div class="row mt-3">
                        <div class="col-12 col-sm-6 col-md-4 offset-md-4 col-lg-3 offset-lg-6">
                            <input type="submit" (click)="createAffiliate()" value="{{ 'createaffiliate.Crear alta' | translate }}" class="btn btn-full-width btn-primary mb-3 mb-sm-0" />
                        </div>
                        <div class="col-12 col-sm-6 col-md-4 col-lg-3">
                            <a class="btn btn-full-width btn-secondary" (click)="goToAffiliates()">{{ 'createaffiliate.Cancelar' | translate }}</a>
                        </div>
                    </div>
                </form>

            </div>
        </div>
    </form>
</div>


<app-message-panel></app-message-panel>