import { Component, OnInit, ViewChild } from '@angular/core';
import { Router }                       from '@angular/router';

import { MessagePanelComponent }        from 'src/app/ui/components/shared/message-panel/message-panel.component';
import { ServerErrorManager }           from 'src/app/ui/components/utils/server-error-manager.utils';
import { NavigationConstants }          from 'src/app/ui/commons/navigation.constants';

import { AdminProviderInteractor }      from 'src/app/domain/interactor/admin/provider.interactor';
import { AdminTourInteractor }          from 'src/app/domain/interactor/admin/tour.interactor';

import { ChunkGeneric }                 from 'src/app/domain/views/chunk-generic.view';
import { Pagination }                   from 'src/app/domain/views/pagination.view';

import { Country }                      from 'src/app/business/models/master/country/country.model';
import { City }                         from 'src/app/business/models/master/city/city.model';
import { ImageSize }                    from 'src/app/business/models/image/common/image-size.constants';
import { TourState }                    from 'src/app/business/models/tour/common/tour-state.constants';
import { TourResumeAdmin }              from 'src/app/business/models/tour/tour-resume-admin.model';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { AdminEditTourBidModalComponent } from './edit-bid/edit-bid-modal.component';
import { GenericException } from 'src/app/domain/exceptions/generic.exception';
import { AdminEditTourCommissionModalComponent } from './edit-commission/edit-commission-modal.component';

import { TranslateService } from '@ngx-translate/core';
import { marker as I18N } from '@biesbjerg/ngx-translate-extract-marker';
import { UserMasterInteractor } from 'src/app/domain/interactor/user/user-master.interactor';
import { AdminEditTourAffiliateCommisionModalComponent } from './edit-commission-affiliate/edit-commission-affiliate-modal.component';
import { CompanyName } from 'src/app/business/models/master/company/company-name.model';
import { AdminEditTourGuideSalesCommissionModalComponent } from './edit-commission-guide-sales/edit-commission-guide-sales-modal';


@Component({
     selector: 'app-list-tours',
     templateUrl: './list.component.html'
})
export class AdminListToursComponent implements OnInit {

     @ViewChild(MessagePanelComponent)
     private messagePanelComponent: MessagePanelComponent;


     imageMediumSize: ImageSize = ImageSize.Medium;

     countryId: number;
     cityId: number;
     providerId: number;
     keywords: string;
     pageNumber: number;

     tourStateActive: TourState = TourState.Active;

     currentCountrySelected: number;

     tours: Array<TourResumeAdmin>;
     pagination: Pagination;

     countries: Array<Country>;
     cities: Array<City>;
     providers: Array<CompanyName>;

     noResults: boolean;

     paginationNextLabel: string;
     paginationPreviousLabel: string;

     constructor(
          private adminTourInteractor: AdminTourInteractor,
          private adminProviderInteractor: AdminProviderInteractor,
          private userMasterInteractor: UserMasterInteractor,
          private router: Router,
          private modalService: NgbModal,
          private translateService: TranslateService,
          private serverErrorManager: ServerErrorManager) {

               this.pagination = new Pagination(0, 1, 20);
               this.tours = new Array<TourResumeAdmin>();
               this.pageNumber = this.pagination.currentPage;
               this.noResults = true;
               this.countryId = null;
               this.cityId = null;
               this.providerId = null;

          }

          ngOnInit() {
               this.findTours();

               this.userMasterInteractor.getCountriesByUser()
               .subscribe(
                    ( countries: Array<Country> ) => {
                         this.countries = countries;
                    },
                    (error: GenericException) => {
                         this.serverErrorManager.manageErrorCode(error, this.messagePanelComponent);

                    }
               )

               this.adminProviderInteractor.getProvidersNames()
               .subscribe(
                    ( providers: Array<CompanyName> ) => {

                         this.providers = providers;
                    },
                    (error: GenericException) => {
                         this.serverErrorManager.manageErrorCode(error, this.messagePanelComponent);

                    }
               )

               this.translateService.get(I18N('pagination.Siguiente')).subscribe(
                    (messageTranslated: string) => {
                         this.paginationNextLabel = messageTranslated;
                    }
               )

               this.translateService.get(I18N('pagination.Previous')).subscribe(
                    (messageTranslated: string) => {
                         this.paginationPreviousLabel = messageTranslated;
                    }
               )

          }


          findTours() {
               this.adminTourInteractor.findTours(this.keywords, this.countryId, this.cityId, this.providerId, this.pageNumber)
               .subscribe(
                    (chunkTours: ChunkGeneric<TourResumeAdmin>) => {

                         this.pagination = new Pagination(chunkTours.pagination.totalItems, chunkTours.pagination.currentPage, chunkTours.pagination.itemsPerPage);
                         this.tours = chunkTours.items;
                         this.noResults = this.tours.length == 0;
                    },
                    (error: GenericException) => {
                         this.serverErrorManager.manageErrorCode(error, this.messagePanelComponent);
                    }
               )
          }


          onPageChanged(currentPage: number) {
               this.pageNumber = currentPage;
               this.findTours();
          }


          onTogleTourState( tourId: number ) {

               this.messagePanelComponent.showLoading();

               this.tours.map( (tour: TourResumeAdmin) => {
                    if ( tour.id == tourId ) {
                         if ( tour.state == TourState.Active ) {
                              this.adminTourInteractor.deactivateTour(tourId)
                              .subscribe(
                                   () => {
                                        MessagePanelComponent.hideLoading();
                                        tour.state = TourState.Inactive;
                                   },
                                   (error: GenericException) => {
                                        MessagePanelComponent.hideLoading();
                                        this.serverErrorManager.manageErrorCode(error, this.messagePanelComponent);
                                   }
                              )
                         } else {
                              this.adminTourInteractor.activateTour(tourId)
                              .subscribe(
                                   () => {
                                        MessagePanelComponent.hideLoading();
                                        tour.state = TourState.Active;
                                   },
                                   (error: GenericException) => {
                                        MessagePanelComponent.hideLoading();
                                        this.serverErrorManager.manageErrorCode(error, this.messagePanelComponent);
                                   }
                              )
                         }
                    }
               })

          }


          onSearchKeywordChanged() {
               this.findTours();
          }


          onCountrySelected() {
               if ( this.countryId != this.currentCountrySelected ) {

                    this.currentCountrySelected = this.countryId;
                    this.cities = new Array<City>();
                    this.cityId = null;

                    if ( this.countryId ) {
                         this.userMasterInteractor.getCitiesByUser( this.countryId )
                         .subscribe(
                              (cities: Array<City>) => {
                                   this.cities = cities;
                              },
                              (error: GenericException) => {
                                   this.serverErrorManager.manageErrorCode(error, this.messagePanelComponent);
                              }
                         );
                    }

                    this.findTours();

               }
          }


          onCitySelected() {
               this.findTours();
          }

          onProviderSelected() {
               this.findTours();
          }



          onEditBid(tourResume: TourResumeAdmin) {
               const modalComponent: NgbModalRef = this.modalService.open(AdminEditTourBidModalComponent, {ariaLabelledBy: 'modal-basic-title', backdrop: 'static'});
               modalComponent.componentInstance.tourResume = tourResume;
               modalComponent.result.then((tourResumeUpdated: TourResumeAdmin) => {
                    if ( tourResumeUpdated ) {
                         tourResume.bid = tourResumeUpdated.bid;
                         tourResume.bidBlocked = tourResumeUpdated.bidBlocked;
                    }
               });
          }


          onEditCommission(tourResume: TourResumeAdmin) {
               const modalComponent: NgbModalRef = this.modalService.open(AdminEditTourCommissionModalComponent, {ariaLabelledBy: 'modal-basic-title', backdrop: 'static'});
               modalComponent.componentInstance.tourResume = tourResume;
               modalComponent.result.then((tourResumeUpdated: TourResumeAdmin) => {
                    if ( tourResumeUpdated ) {
                         tourResume.customCommissionFare = tourResumeUpdated.customCommissionFare;
                    }
               });
          }

          onEditAffiliateCommision(tourResume: TourResumeAdmin) {
               const modalComponent: NgbModalRef = this.modalService.open(AdminEditTourAffiliateCommisionModalComponent, {ariaLabelledBy: 'modal-basic-title', backdrop: 'static'});
               modalComponent.componentInstance.tourResume = tourResume;
               modalComponent.result.then((tourResumeUpdated: TourResumeAdmin) => {
                    if ( tourResumeUpdated ) {
                         tourResume.customAffiliateCommission = tourResumeUpdated.customAffiliateCommission;
                         tourResume.customAffiliateCommissionBidFare = tourResumeUpdated.customAffiliateCommissionBidFare;
                         tourResume.affiliateCommission = tourResumeUpdated.affiliateCommission;
                    }
               });
          }

          onEditGuideSalesCommision(tourResume: TourResumeAdmin) {
               const modalComponent: NgbModalRef = this.modalService.open(AdminEditTourGuideSalesCommissionModalComponent, {ariaLabelledBy: 'modal-basic-title', backdrop: 'static'});
               modalComponent.componentInstance.tourResume = tourResume;
               modalComponent.result.then((tourResumeUpdated: TourResumeAdmin) => {
                    if ( tourResumeUpdated ) {
                         tourResume.guideSalesCommission = tourResumeUpdated.guideSalesCommission;
                    }
               });
          }

          onGoToEditTour( tourId: number ) {
               this.router.navigate([ NavigationConstants.getNavigationAdminUpdateTour(tourId) ]);
          }

          onGoToManageGallery( tourId: number ) {
               this.router.navigate([ NavigationConstants.getNavigationAdminTourGalleryManagment(tourId) ]);
          }

          onCreateTourClick() {
               this.router.navigate( [ NavigationConstants.getNavigationAdminCreateTour() ] );
          }


     }
