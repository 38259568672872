import { Injectable }                       from '@angular/core';
import { Observable, Observer } from 'rxjs';
import { MasterService } from 'src/app/business/services/public/master.service';
import { Language } from 'src/app/business/models/master/language/language.model';
import { Country } from 'src/app/business/models/master/country/country.model';
import { City } from 'src/app/business/models/master/city/city.model';
import { LocaleManager } from '../../managers/locale-manager';
import { GenericException } from '../../exceptions/generic.exception';


@Injectable({
  providedIn: 'root'
})
export class MasterInteractor {


    constructor(private masterService: MasterService) {}


    getLanguagesLocalized(): Observable<Array<Language>> {

        return Observable.create((observer: Observer<Array<Language>>) => {
            this.masterService.getLanguages(LocaleManager.getCurrentLocale())
                  .subscribe(
                      (languages: Array<Language>) => {

                          observer.next(languages);
                          observer.complete();
                      },
                      (error: GenericException) => {
                          observer.error(error);
                      }
                  )

        });
    }


    getCountriesLocalized(languageId: number): Observable<Array<Country>> {

        return Observable.create((observer: Observer<Array<Country>>) => {
            this.masterService.getCountries(languageId)
                  .subscribe(
                      (countries: Array<Country>) => {

                          observer.next(countries);
                          observer.complete();
                      },
                      (error: GenericException) => {
                          observer.error(error);
                      }
                  )

        });
    }



    getCitiesLocalized(countryId: number, languageId: number): Observable<Array<City>> {

        return Observable.create((observer: Observer<Array<City>>) => {
            this.masterService.getCitiesByCountry(countryId, languageId)
                  .subscribe(
                      (cities: Array<City>) => {

                          observer.next(cities);
                          observer.complete();
                      },
                      (error: GenericException) => {
                          observer.error(error);
                      }
                  )

        });
    }



    getPaymentCurrencies(): Observable<Array<string>> {

        return Observable.create((observer: Observer<Array<string>>) => {
            this.masterService.getPaymentCurrencies()
                  .subscribe(
                      (currencies: Array<string>) => {

                          observer.next(currencies);
                          observer.complete();
                      },
                      (error: GenericException) => {
                          observer.error(error);
                      }
                  )

        });
    }


}
