<div class="animated fadeIn">
    <app-create-guide-service
        [isGuide]="true"
        [guideServiceDetails]="guideServiceDetails"
        [tours]="tours"
        [events]="events"
        (guideServicesNavigation)="onGuideServicesNavigation()"
        (guideServicesUpdated)="onGuideServicesUpdated($event)"
        (tourAndDateSelected)="onTourAndDateSelected($event)"
    ></app-create-guide-service>
</div>


<app-message-panel></app-message-panel>