import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BaseService } from '../base.service';
import { Language } from '../../models/master/language/language.model';
import { Country } from '../../models/master/country/country.model';
import { City } from '../../models/master/city/city.model';
import { TourType } from '../../models/master/tour/type/type.model';
import { BookingUtils } from '../../models/master/booking/booking.utils';
import { CancelationReason } from '../../models/master/booking/cancelation-reason/cancelaltion-reason.constants';
import { MastersApiEndpointsUtils } from '../../api/endpoints/masters-api-endponints.utils';

@Injectable({
  providedIn: 'root'
})
export class UserMasterService extends BaseService {


  getLanguages(): Observable<Array<Language>> {
    return super.sendGetRequestWithRefresh(MastersApiEndpointsUtils.getUserMasterLanguagesEndpoint(), MastersApiEndpointsUtils.getUserMasterLanguagesEndpointAcceptVersion())
      .pipe(
        map((response: any) => {

          const languages: Array<Language> = response.map((language: any) => {
            return new Language(
              language.id,
              language.code,
              language.name
            )
          })

          return languages
        })
      )
  }


  getCountries(): Observable<Array<Country>> {
    return super.sendGetRequestWithRefresh(MastersApiEndpointsUtils.getUserMasterCountriesEndpoint(), MastersApiEndpointsUtils.getUserMasterCountriesEndpointAcceptVersion())
      .pipe(
        map((response: any) => {

          const country: Array<Country> = response.map((country: any) => {
            return new Country(
              country.id,
              country.code,
              country.name
            )
          })

          return country
        })
      )
  }


  getCitiesByCountry(countryId: number): Observable<Array<City>> {
    return super.sendGetRequestWithRefresh(MastersApiEndpointsUtils.getUserMasterCitiesByCountryEndpoint(countryId), MastersApiEndpointsUtils.getUserMasterCitiesByCountryEndpointAcceptVersion())
      .pipe(
        map((response: any) => {

          const city: Array<City> = response.map((city: any) => {
            return new City(
              city.id,
              city.name
            )
          })

          return city
        })
      )
  }


  getTourTypes(): Observable<Array<TourType>> {
    return super.sendGetRequestWithRefresh(MastersApiEndpointsUtils.getProviderTourTypesEndpoint(), MastersApiEndpointsUtils.getProviderTourTypesEndpointAcceptVersion())
      .pipe(
        map((response: any) => {

          const tourTypes: Array<TourType> = response.map((tourType: any) => {
            return new TourType(
              tourType.id,
              tourType.name
            )
          })

          return tourTypes
        })
      )
  }


  getTourDisplayedLanguages(): Observable<Array<Language>> {
    return super.sendGetRequestWithRefresh(MastersApiEndpointsUtils.getUserMasterTourDisplayedLanguagesEndpoint(), MastersApiEndpointsUtils.getUserMasterTourDisplayedLanguagesEndpointAcceptVersion())
      .pipe(
        map((response: any) => {

          const languages: Array<Language> = response.map((language: any) => {
            return new Language(
              language.id,
              language.code,
              language.name
            )
          })

          return languages
        })
      )
  }



  getBookingCancelationReasons(): Observable<Array<CancelationReason>> {
    return super.sendGetRequestWithRefresh(MastersApiEndpointsUtils.getBookingCancelationReasonsEndpoint(), MastersApiEndpointsUtils.getBookingCancelationReasonsEndpointAcceptVersion())
      .pipe(
        map((response: any) => {

          const cancelations: Array<CancelationReason> = response.map((cancelationReasonApi: any) => {
               return BookingUtils.getCancelationReasonFromApi(cancelationReasonApi);
          })
          return cancelations
        })
      )
  }


}
