import { Component, OnInit, ViewChild } from '@angular/core';

import { ServerErrorManager } from 'src/app/ui/components/utils/server-error-manager.utils';
import { GenericException } from 'src/app/domain/exceptions/generic.exception';
import { MessagePanelComponent } from 'src/app/ui/components/shared/message-panel/message-panel.component';
import { ChunkGeneric } from 'src/app/domain/views/chunk-generic.view';
import { GuideServiceDetails } from 'src/app/business/models/billing/service-details.model';
import { NavigationConstants } from 'src/app/ui/commons/navigation.constants';
import { Router } from '@angular/router';
import { marker as I18N } from '@biesbjerg/ngx-translate-extract-marker';
import { GuideServiceState } from 'src/app/business/models/billing/common/guide-service-state.constants';
import { GuideServiceInteractor } from 'src/app/domain/interactor/guide/service.interactor';
import { FindServicesFilterView } from 'src/app/domain/views/services/find-services-filter.view';
import { GuideServiceStats } from 'src/app/business/models/billing/service-stats.model';


@Component({
     selector: 'app-guide-list-service',
     templateUrl: './list.component.html'
})
export class ListGuideServiceComponent implements OnInit {

     @ViewChild(MessagePanelComponent)
     private messagePanelComponent: MessagePanelComponent;

     guideServices: ChunkGeneric<GuideServiceDetails>;

     findCommissionsFilterView: FindServicesFilterView;
     guideServiceStats: GuideServiceStats;

     constructor(
          private guideServicesInteractor: GuideServiceInteractor,
          private serverErrorManager: ServerErrorManager,
          private router: Router
     ) {

     }

     ngOnInit() {
     }

     onFilterCommissions(findCommissionsFilterView: FindServicesFilterView) {

          this.findCommissionsFilterView = findCommissionsFilterView;
          this.findGuideServices();

     }


     findGuideServices() {

          if ( this.messagePanelComponent ) {
               this.messagePanelComponent.showLoading();
          }

          this.guideServicesInteractor.findGuideServices(this.findCommissionsFilterView.month, this.findCommissionsFilterView.year, this.findCommissionsFilterView.state, this.findCommissionsFilterView.pageNumber)
          .subscribe(
               ( commissions: ChunkGeneric<GuideServiceDetails> ) => {
                    this.guideServices = commissions;
                    MessagePanelComponent.hideLoading();
               },
               (error: GenericException) => {
                    MessagePanelComponent.hideLoading();
                    this.serverErrorManager.manageErrorCode(error, this.messagePanelComponent);

               }
          )


          this.guideServicesInteractor.findGuideServicesStats(this.findCommissionsFilterView.month, this.findCommissionsFilterView.year)
          .subscribe(
               ( guideServiceStats: GuideServiceStats ) => {
                    this.guideServiceStats = guideServiceStats;
               },
               (error: GenericException) => {
                    MessagePanelComponent.hideLoading();
                    this.serverErrorManager.manageErrorCode(error, this.messagePanelComponent);

               }
          )
     }


     onCreateCommission() {
          this.router.navigate( [ NavigationConstants.getNavigationGuideServiceCreate() ] );
     }


     onEditService(commissionId: number) {
          this.router.navigate( [ NavigationConstants.getNavigationGuideServiceUpdate(commissionId) ] );
     }

     onDeleteService(commissionId: number) {


          this.messagePanelComponent.showConfirmationMessage(I18N('serviceslist.¿Seguro que quieres borrar el servicio?'), () => {

               this.messagePanelComponent.showLoading();

               this.guideServicesInteractor.deleteService(commissionId)
               .subscribe(
                    ( ) => {
                         this.guideServices.items.forEach(function(guideService: GuideServiceDetails) {
                              if ( guideService.id == commissionId ) {
                                   guideService.state = GuideServiceState.Deleted;
                              }
                         })
                         MessagePanelComponent.hideLoading();
                         this.findGuideServices();
                    },
                    (error: GenericException) => {
                         MessagePanelComponent.hideLoading();
                         this.serverErrorManager.manageErrorCode(error, this.messagePanelComponent);

                    }
               )

          });


          

     }




}