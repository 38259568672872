import { Component, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormArray, FormControl } from '@angular/forms';


@Component({
  selector: 'app-create-tour-schedule-exception-day',
  templateUrl: './exception-day.component.html'
})
export class CreateTourScheduleExceptionDayComponent {


    i18nTraslation: boolean;

    @Input()
    set isI18nTraslation(isI18nTraslation: boolean) {
        this.i18nTraslation = isI18nTraslation;
    }


    @Input() exceptionDayFormGroup: FormGroup;

    @Output() manageExceptionDay = new EventEmitter<boolean>();

    constructor() {

    }

    get hours(): FormArray {
        return this.exceptionDayFormGroup.get('hours') as FormArray;
    }

    get day(): string {
        return this.exceptionDayFormGroup.get('day').value;
    }


    onAddHour() {
        let existEmptyHours = false;
        this.hours.controls.map( (hourControl: FormControl) => {
            if ( hourControl.value == '' ) {
                existEmptyHours = true;
            }
        })
        if ( !existEmptyHours ) {
            this.hours.controls.push(new FormControl(''));
            this.manageExceptionDay.emit(true);
        }
    }

    onHourUpdated() {
        this.manageExceptionDay.emit(true);
    }

    onRemoveHour(hourIndex: number) {
        //Remove if not the last item and rest otherwise
        if (this.hours.length == 1) {
            this.hours.at(hourIndex).setValue('');
        } else {
            this.hours.removeAt(hourIndex);
        }

        this.manageExceptionDay.emit(true);

    }

    onExceptionReset() {
        this.manageExceptionDay.emit(false);
    }



}
