import { Component, OnInit, ViewChild } from '@angular/core';

import { MessagePanelComponent }        from 'src/app/ui/components/shared/message-panel/message-panel.component';
import { ServerErrorManager }           from 'src/app/ui/components/utils/server-error-manager.utils';

import { ChunkGeneric }                 from 'src/app/domain/views/chunk-generic.view';

import { GenericException }             from 'src/app/domain/exceptions/generic.exception';
import { BookingBilling }               from 'src/app/business/models/billing/booking-billing.model';

import { CompanyName } from 'src/app/business/models/master/company/company-name.model';
import { FindBillingFilterView } from 'src/app/domain/views/billing/find-invoices-filter.view';
import { ProviderGuideInteractor } from 'src/app/domain/interactor/provider/guide.interactor';
import { ProviderBillingInteractor } from 'src/app/domain/interactor/provider/billing.interactor';
import { GuideBillingStats } from 'src/app/business/models/billing/billing-stats.model';


@Component({
     selector: 'app-guide-list-sales-provider',
     templateUrl: './list.component.html'
})
export class ProviderGuideListSalesComponent implements OnInit {

     @ViewChild(MessagePanelComponent)
     private messagePanelComponent: MessagePanelComponent;

     chunkBookingBilling: ChunkGeneric<BookingBilling>;
     guideBillingStats: GuideBillingStats;
     
     guides: Array<CompanyName>;

     constructor(
          private providerBillingInteractor: ProviderBillingInteractor,
          private providerGuideInteractor: ProviderGuideInteractor,
          private serverErrorManager: ServerErrorManager) {


     }



     ngOnInit() {

          this.providerGuideInteractor.getGuidesNames()
          .subscribe(
               ( guides: Array<CompanyName> ) => {

                    this.guides = guides;
               },
               (error: GenericException) => {
                    this.serverErrorManager.manageErrorCode(error, this.messagePanelComponent);

               }
          )

     }


     onFindBillingTrigger(findBillingFilterView: FindBillingFilterView) {

          if (this.messagePanelComponent) {
            this.messagePanelComponent.showLoading();
          }

          this.providerBillingInteractor.findGuideBilling(findBillingFilterView.dateInit, findBillingFilterView.dateEnd, findBillingFilterView.guideId, findBillingFilterView.keywords, findBillingFilterView.pageNumber)
          .subscribe(
               (chunkBookingBilling: ChunkGeneric<BookingBilling>) => {

                    this.chunkBookingBilling = chunkBookingBilling;
                    MessagePanelComponent.hideLoading();
               },
               (error: GenericException) => {
                    MessagePanelComponent.hideLoading();
                    this.serverErrorManager.manageErrorCode(error, this.messagePanelComponent);
               }
          )


          this.providerBillingInteractor.findGuideBillingStats(findBillingFilterView.dateInit, findBillingFilterView.dateEnd, findBillingFilterView.guideId, findBillingFilterView.keywords)
          .subscribe(
               (guideBillingStats: GuideBillingStats) => {

                    this.guideBillingStats = guideBillingStats;
                    MessagePanelComponent.hideLoading();
               },
               (error: GenericException) => {
                    MessagePanelComponent.hideLoading();
                    this.serverErrorManager.manageErrorCode(error, this.messagePanelComponent);
               }
          )


          
     }


}
