import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { Pagination } from 'src/app/domain/views/pagination.view';
import { InvoiceDetails } from 'src/app/business/models/billing/invoice-details.model';
import { NextBilling } from 'src/app/business/models/billing/next-billing.model';

@Component({
  selector: 'app-invoices-table',
  templateUrl: './table.component.html'
})
export class InvoicesTableComponent implements OnInit {

  @Input() invoices: Array<InvoiceDetails>;

  @Input() canReceivePayments: boolean;

  @Input() nextBilling: NextBilling;

  @Input() pagination: Pagination;

  @Input() noResults: boolean;

  @Output() invoiceDetails = new EventEmitter<number>();

  @Output() invoiceDownload = new EventEmitter<InvoiceDetails>();

  @Output() goToProfile = new EventEmitter<boolean>();



  constructor() { }


  ngOnInit() { }


  onInvoiceDetails(invoiceId: number) {
    this.invoiceDetails.emit(invoiceId);
  }

  onInvoiceDownload(invoiceDetails: InvoiceDetails) {
    this.invoiceDownload.emit(invoiceDetails);
  }

  onGoToProfile() {
       this.goToProfile.emit(true);
 }


}
