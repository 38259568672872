<div class="animated fadeIn">
    <div class="row header-dashboard align-items-lg-center align-items-start">
        <div class="col-12 pl-0">
            <h2 class="font-weight-bold">{{ 'guideprofile.Perfil' | translate }}</h2>
            <p>{{ 'guideprofile.Revisa y edita tus datos' | translate }}</p>
        </div>
    </div>

    <div class="row dashboard-content-section">
        <div class="col-12 px-0">

            <app-change-password></app-change-password>
            <app-guide-profile-settings [guide]="guide"></app-guide-profile-settings>
            
        </div>
    </div>
</div>

<app-message-panel [preloading]="true"></app-message-panel>