import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-main-translation',
  templateUrl: './main-translation.component.html'
})
export class MainTranslationComponent {

  mainTranslationLimit = 160;

  text: string;
  showFullText: boolean;
  showReadMore: boolean;


  @Input()
  set mainTranslation(mainTranslation: string) {
      if ( mainTranslation ) {
          this.text = mainTranslation;
          this.showReadMore = this.text.length > this.mainTranslationLimit;
      }
  }

  @Input() i18nTraslation: boolean;

  constructor() {
      this.showFullText = false;
      this.showReadMore = false;
  }

  onToggleReadMore() {
      this.showFullText = !this.showFullText;
  }




}
