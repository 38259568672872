<div class="card table-responsive" *ngFor="let commission of commissions | paginate: pagination;">
    <div class="row mb-4">
        <div class="col-12 text-left">
            <p class="name-provider mb-2">{{ commission.tourNameTranslated }}</p>
        </div>
    </div>

    <div class="row mb-2">
        <div class="col-4 title">
            {{ 'commissionslist.Fecha' | translate }}
        </div>
        <div class="col-4 title">
            {{ 'commissionslist.Nº Personas' | translate }}
        </div>
        <div class="col-4 title">
            {{ 'commissionslist.Guía' | translate }}
        </div>
    </div>
    <div class="row mb-3">
        <div class="col-4 name-contact-provider font-weight-normal">
            {{ commission.day }} | {{ commission.hour }}
        </div>
        <div class="col-4 name-contact-provider font-weight-normal">
            {{ commission.people }}
        </div>
        <div class="col-4 name-contact-provider font-weight-normal">
            {{ commission.guide.companyName }}
        </div>
    </div>

    <div class="row mb-2">
        <div class="col-12 title">
            {{ 'billinglist.Price' | translate }}
        </div>
    </div>
    <div class="row mb-3">
        <div class="col-12 name-contact-provider">
            {{ commission.price }} &euro;
        </div>
    </div>

    <hr class="divider">

    <div class="row mb-2 mt-2">
        <div class="col-12 title">
            {{ 'commissionslist.Estado' | translate }}
        </div>
    </div>
    <div class="row">
        <div class="col-12 footer-card-with-icons">
            <div [ngClass]="'text-left status' | serviceStateClassPipe: commission.state">
                <div class="dot"></div> {{ commission.state | serviceStateLabelPipe | translate }}
            </div>

            <div>
                <a *ngIf="commission.canEdit" class="table-action-icon" (click)="onEditService(commission.id)">
                    <img src="assets/img/action_icon_edit.svg" />
                </a>

                <a *ngIf="commission.canRemove" class="table-action-icon action-icon-delete" (click)="onDeleteService(commission.id)">
                    <img src="assets/img/action_icon_remove.svg" />
                </a>

                <a *ngIf="commission.canApprove" class="table-action-icon action-icon-check" (click)="onApproveService(commission.id)">
                    <img src="assets/img/action_icon_approve.svg" />
                </a>
            </div>

            
        </div>
    </div>
</div>