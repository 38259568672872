<div class="card table-responsive" *ngFor="let affiliate of affiliates | paginate: pagination;" (click)="onAffiliateClick(affiliate.id)">
    <div class="row">
        <div class="col-9 mb-1">
            <p class="name-provider mb-2">{{ affiliate.companyName }}</p>
        </div>

        <td [ngClass]="'col-3 text-right status-provider status' | adminAffiliateStateClass: affiliate.state">
            <div class="dot"></div> {{ '' | adminAffiliateStateName: affiliate.state | translate }}
        </td>

    </div>
    <div class="row">
        <div class="col-12 text-left">
            <p class="email-provider mb-2">{{ affiliate.username }}</p>
            <p class="email-provider mb-2">{{ affiliate.phone }}</p>
            <p class="email-provider mb-2">{{ affiliate.companyName }}</p>
            <p class="email-provider mb-2">{{ affiliate.country }}, {{ affiliate.city }}</p>
        </div>
    </div>
</div>