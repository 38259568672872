import { Component, Output, EventEmitter, OnInit, Input } from '@angular/core';
import { TourScheduleWeekdaysType } from 'src/app/domain/common/tour/tour-schedule-weekdays.constants';

@Component({
  selector: 'app-create-tour-schedule-weekdays',
  templateUrl: './weekdays.component.html'
})
export class CreateTourScheduleWeekdaysComponent implements OnInit {

  @Output() selectedWeekdayEvent = new EventEmitter<TourScheduleWeekdaysType>(true);

  refreshValidators: boolean;

  weekdaysTypes: Array<TourScheduleWeekdaysType>;

  @Input()
  set forceWeekdaySelection(forceWeekdaySelection: TourScheduleWeekdaysType) {
      this.onWeekdaySelected(forceWeekdaySelection);
  }

  @Input() weekdaysIncluded: Array<TourScheduleWeekdaysType>;

  weekdayActive: TourScheduleWeekdaysType;


  constructor() {

      //Initial form config
      this.refreshValidators = false;
      this.weekdaysTypes = new Array<TourScheduleWeekdaysType>(
          TourScheduleWeekdaysType.Monday,
          TourScheduleWeekdaysType.Tuesday,
          TourScheduleWeekdaysType.Wednesday,
          TourScheduleWeekdaysType.Thursday,
          TourScheduleWeekdaysType.Friday,
          TourScheduleWeekdaysType.Saturday,
          TourScheduleWeekdaysType.Sunday
        );

      this.weekdaysIncluded = new Array<TourScheduleWeekdaysType>();

  }

  ngOnInit() {
    //Initialize item
    this.onWeekdaySelected(TourScheduleWeekdaysType.Monday);

  }

  onWeekdaySelected(weekday: TourScheduleWeekdaysType) {
      if ( this.weekdayActive != weekday ) {
          this.weekdayActive = weekday;
          this.selectedWeekdayEvent.emit(this.weekdayActive);
      }
  }


}
