import { Component, OnInit, ViewChild } from '@angular/core';

import { CompanyName } from 'src/app/business/models/master/company/company-name.model';
import { ServerErrorManager } from 'src/app/ui/components/utils/server-error-manager.utils';
import { GenericException } from 'src/app/domain/exceptions/generic.exception';
import { MessagePanelComponent } from 'src/app/ui/components/shared/message-panel/message-panel.component';
import { ChunkGeneric } from 'src/app/domain/views/chunk-generic.view';
import { GuideServiceDetails } from 'src/app/business/models/billing/service-details.model';
import { NavigationConstants } from 'src/app/ui/commons/navigation.constants';
import { Router } from '@angular/router';
import { marker as I18N } from '@biesbjerg/ngx-translate-extract-marker';
import { GuideServiceState } from 'src/app/business/models/billing/common/guide-service-state.constants';
import { FindServicesFilterView } from 'src/app/domain/views/services/find-services-filter.view';
import { GuideServiceStats } from 'src/app/business/models/billing/service-stats.model';
import { AdminGuideInteractor } from 'src/app/domain/interactor/admin/guide.interactor';
import { AdminProviderInteractor } from 'src/app/domain/interactor/admin/provider.interactor';
import { AdminGuideServiceInteractor } from 'src/app/domain/interactor/admin/guide-service.interactor';


@Component({
     selector: 'app-guide-list-service-admin',
     templateUrl: './list.component.html'
})
export class AdminListGuideServiceComponent implements OnInit {

     @ViewChild(MessagePanelComponent)
     private messagePanelComponent: MessagePanelComponent;

     providers: Array<CompanyName>;
     guides: Array<CompanyName>;
     guideServices: ChunkGeneric<GuideServiceDetails>;

     guideServiceStats: GuideServiceStats;

     findCommissionsFilterView: FindServicesFilterView;

     constructor(
          private adminGuideInteractor: AdminGuideInteractor,
          private adminProviderInteractor: AdminProviderInteractor,
          private adminGuideServicesInteractor: AdminGuideServiceInteractor,
          private serverErrorManager: ServerErrorManager,
          private router: Router
     ) {

          this.guides = new Array<CompanyName>();
          this.providers = new Array<CompanyName>();

     }

     ngOnInit() {
          this.adminProviderInteractor.getProvidersNamesWithGuideManagement()
          .subscribe(
               ( providers: Array<CompanyName> ) => {

                    this.providers = providers;
               },
               (error: GenericException) => {
                    this.serverErrorManager.manageErrorCode(error, this.messagePanelComponent);

               }
          )
     }



     onProviderSelectedTrigger(providerId: number) {
          this.guides = new Array<CompanyName>();
          if ( providerId ) {
               this.adminGuideInteractor.getGuidesNames(providerId)
               .subscribe(
                    ( guides: Array<CompanyName> ) => {

                         this.guides = guides;
                    },
                    (error: GenericException) => {
                         this.serverErrorManager.manageErrorCode(error, this.messagePanelComponent);

                    }
               )
          }
     }




     onFilterCommissions(findCommissionsFilterView: FindServicesFilterView) {

          this.findCommissionsFilterView = findCommissionsFilterView;
          this.findGuideServices();

     }



     findGuideServices() {
          
          if ( this.messagePanelComponent ) {
               this.messagePanelComponent.showLoading();
          }

          this.adminGuideServicesInteractor.findGuideServices(this.findCommissionsFilterView.month, this.findCommissionsFilterView.year, this.findCommissionsFilterView.providerId, this.findCommissionsFilterView.guideId, this.findCommissionsFilterView.state, this.findCommissionsFilterView.pageNumber)
          .subscribe(
               ( commissions: ChunkGeneric<GuideServiceDetails> ) => {
                    this.guideServices = commissions;
                    MessagePanelComponent.hideLoading();
               },
               (error: GenericException) => {
                    MessagePanelComponent.hideLoading();
                    this.serverErrorManager.manageErrorCode(error, this.messagePanelComponent);

               }
          )


          this.adminGuideServicesInteractor.findGuideServicesStats(this.findCommissionsFilterView.month, this.findCommissionsFilterView.year, this.findCommissionsFilterView.providerId, this.findCommissionsFilterView.guideId)
          .subscribe(
               ( guideServiceStats: GuideServiceStats ) => {
                    this.guideServiceStats = guideServiceStats;
               },
               (error: GenericException) => {
                    MessagePanelComponent.hideLoading();
                    this.serverErrorManager.manageErrorCode(error, this.messagePanelComponent);

               }
          )

     }


     onCreateCommission() {
          this.router.navigate( [ NavigationConstants.getNavigationAdminGuideServiceCreate() ] );
     }




     onApproveService(commissionId: number) {

          this.messagePanelComponent.showConfirmationMessage(I18N('serviceslist.¿Seguro que quieres aprobar el servicio?'), () => {

               this.messagePanelComponent.showLoading();

               this.adminGuideServicesInteractor.approveService(commissionId)
               .subscribe(
                    ( ) => {
                         this.guideServices.items.forEach(function(guideService: GuideServiceDetails) {
                              if ( guideService.id == commissionId ) {
                                   guideService.state = GuideServiceState.Approved;
                              }
                         })
                         MessagePanelComponent.hideLoading();
                         this.findGuideServices();
                    },
                    (error: GenericException) => {
                         MessagePanelComponent.hideLoading();
                         this.serverErrorManager.manageErrorCode(error, this.messagePanelComponent);

                    }
               )

          });
          
          
     }

     onEditService(commissionId: number) {
          this.router.navigate( [ NavigationConstants.getNavigationAdminGuideServiceUpdate(commissionId) ] );
     }

     onDeleteService(commissionId: number) {


          this.messagePanelComponent.showConfirmationMessage(I18N('serviceslist.¿Seguro que quieres borrar el servicio?'), () => {

               this.messagePanelComponent.showLoading();

               this.adminGuideServicesInteractor.deleteService(commissionId)
               .subscribe(
                    ( ) => {
                         this.guideServices.items.forEach(function(guideService: GuideServiceDetails) {
                              if ( guideService.id == commissionId ) {
                                   guideService.state = GuideServiceState.Deleted;
                              }
                         })
                         MessagePanelComponent.hideLoading();
                         this.findGuideServices();
                    },
                    (error: GenericException) => {
                         MessagePanelComponent.hideLoading();
                         this.serverErrorManager.manageErrorCode(error, this.messagePanelComponent);

                    }
               )

          });


          

     }

}