import { Component, Input, Output, EventEmitter, OnInit, ViewChild } from '@angular/core';
import { FormGroup, Validators, FormControl, FormArray } from '@angular/forms';
import { FormUtils } from 'src/app/ui/components/utils/form.utils';
import { AppConstants } from 'src/app/domain/common/app.constants';
import { MessagePanelComponent } from '../../message-panel/message-panel.component';
import { Affiliate } from 'src/app/business/models/affiliate/affiliate.model';
import { BrowserConstants } from 'src/app/ui/commons/browser.constants';

@Component({
     selector: 'app-profile-affiliate-websites',
     templateUrl: './details.component.html'
})
export class ProfileAffiliateWebsitesComponent implements OnInit{

     @ViewChild(MessagePanelComponent)
     private messagePanelComponent: MessagePanelComponent;


     @Input()
     set affiliate(affiliate: Affiliate) {
          if ( affiliate ) {
               this.setAffiliateFormValues(affiliate)
          }
     }

     @Output() affiliateWebsites = new EventEmitter<Array<string>>(true);

     affiliateWebsitesForm: FormGroup
     isDisabled = true

     refreshValidators: boolean

     constructor() {

          this.refreshValidators = false;

          this.affiliateWebsitesForm = new FormGroup({
               'websites': new FormArray([])
          });

     }


     ngOnInit() {
     }


     toggleEdition() {

          this.isDisabled = !this.isDisabled

          if ( this.isDisabled ) {
               FormUtils.disableAllControls(this.affiliateWebsitesForm);
          } else {
               FormUtils.enableAllControls(this.affiliateWebsitesForm);
          }
     }


     saveDetails() {

          this.refreshValidators = true;

          if ( this.affiliateWebsitesForm.valid && this.websitesValid() ) {

               const websites: Array<string> = new Array<string>();
               this.websites.controls.map( (website: FormGroup) => {
                    let urlValue = website.get('url').value;
                    if ( urlValue ) {
                         urlValue = urlValue.trim();
                    }
                    if ( urlValue != '' ) {
                         websites.push(urlValue);
                    }
               });

               this.affiliateWebsites.emit(websites);

               this.toggleEdition();

          }

     }

     get websites(): FormArray {
          return this.affiliateWebsitesForm.get('websites') as FormArray;
     }


     private setAffiliateFormValues( affiliate: Affiliate ) {

          this.websites.clear();
          if ( affiliate.webs.length > 0 ) {
               for ( const website of affiliate.webs ) {
                    this.websites.controls.push(
                         new FormGroup({
                              'url': new FormControl({ value: website, disabled: this.isDisabled }, [Validators.pattern(AppConstants.REGULAR_EXPRESSION_VALIDATE_WEBSITE), Validators.maxLength(AppConstants.TEXT_DEFAULT_MAX_LENGTH)])
                         })
                    );
               }
          } else {
               this.onAddWebsite();
          }

     }



     onAddWebsite() {
          let existEmptyWebsites = false;
          this.websites.controls.map( (websiteControl: FormControl) => {
               const urlValue = websiteControl.get('url').value.trim();
               if ( urlValue == '' ) {
                    existEmptyWebsites = true;
               }
          })
          if ( !existEmptyWebsites ) {
               this.websites.controls.push(
                    new FormGroup({
                         'url': new FormControl({ value: '', disabled: this.isDisabled }, [Validators.pattern(AppConstants.REGULAR_EXPRESSION_VALIDATE_WEBSITE), Validators.maxLength(AppConstants.TEXT_DEFAULT_MAX_LENGTH)])
                    })
               );
          }
     }

     onWebsiteChanged(event: any, websiteIndex: number) {
          if ( event.keyCode == BrowserConstants.KEYBOARD_BACKSPACE_CODE && this.websites.at(websiteIndex) && this.websites.at(websiteIndex).get('url').value == '' && websiteIndex > 0) {
               this.websites.removeAt(websiteIndex);
          }
     }

     onRemoveWebsite(websiteIndex: number) {
          //Remove if not the last item and rest otherwise
          if (this.websites.length == 1) {
               this.websites.at(websiteIndex).setValue('');
          } else {
               this.websites.removeAt(websiteIndex);
          }
     }


     websitesValid(): boolean {

          let isWebsitesValid = true;
          this.websites.controls.map( (website: FormGroup) => {
               if ( !website.get('url').valid ) {
                    isWebsitesValid = false;
               }
          });

          return isWebsitesValid;

     }


}
