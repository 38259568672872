import { Component, Input, EventEmitter, Output } from '@angular/core';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { Provider } from 'src/app/business/models/provider/provider.model';
import { FormUtils } from 'src/app/ui/components/utils/form.utils';
import { AppConstants } from 'src/app/domain/common/app.constants';
import { ProfilePaymentView } from 'src/app/domain/views/profile-payment.view';
import { Affiliate } from 'src/app/business/models/affiliate/affiliate.model';

@Component({
  selector: 'app-profile-payment',
  templateUrl: './payment.component.html'
})
export class ProfilePaymentComponent {

  @Input()
  set provider(provider: Provider) {
      if ( provider ) {
          this.setProviderFormValues(provider);
      }
  }

  @Input()
  set affiliate(affiliate: Affiliate) {
      if ( affiliate ) {
          this.setAffiliateFormValues(affiliate);
      }
  }

  @Output() profilePayment = new EventEmitter<ProfilePaymentView>(true);


  profilePaymentForm: FormGroup;
  isDisabled = true;
  refreshValidators: boolean;

  constructor( ) {

      this.refreshValidators = false;

      this.profilePaymentForm = new FormGroup({
          'id': new FormControl({ value: '', disabled: true }, [Validators.required] ),
          'businessName': new FormControl({ value: '', disabled: true }, [Validators.required, Validators.maxLength(AppConstants.TEXT_DEFAULT_MAX_LENGTH)] ),
          'businessDocumentType': new FormControl({ value: '', disabled: true }, [Validators.required] ),
          'businessDocument': new FormControl({ value: '', disabled: true }, [Validators.required] ),
          'businessAddress': new FormControl({ value: '', disabled: true }, [Validators.required] ),
          'businessZeroVat': new FormControl({ value: false, disabled: true }, [] ),
      });

  }



  toggleEdition() {

        this.isDisabled = !this.isDisabled;

        if ( this.isDisabled ) {
            FormUtils.disableAllControls(this.profilePaymentForm);
        } else {
            FormUtils.enableAllControls(this.profilePaymentForm);
        }
  }


  savePayment() {

      this.refreshValidators = true;

      if ( this.profilePaymentForm.valid ) {

          this.profilePayment.emit(
              new ProfilePaymentView(
                  this.profilePaymentForm.value.id,
                  this.profilePaymentForm.value.businessName,
                  this.profilePaymentForm.value.businessDocumentType,
                  this.profilePaymentForm.value.businessDocument,
                  this.profilePaymentForm.value.businessAddress,
                  this.profilePaymentForm.value.businessZeroVat
              )
          )

          this.toggleEdition();
      }

  }


  private setProviderFormValues( provider: Provider ) {

      this.profilePaymentForm.controls['id'].setValue(provider.id);
      this.profilePaymentForm.controls['businessName'].setValue(provider.businessName);
      this.profilePaymentForm.controls['businessDocumentType'].setValue(provider.businessDocumentType);
      this.profilePaymentForm.controls['businessDocument'].setValue(provider.businessDocument);
      this.profilePaymentForm.controls['businessAddress'].setValue(provider.businessAddress);
      this.profilePaymentForm.controls['businessZeroVat'].setValue(provider.businessZeroVat);

  }


  private setAffiliateFormValues( affiliate: Affiliate ) {

      this.profilePaymentForm.controls['id'].setValue(affiliate.id);
      this.profilePaymentForm.controls['businessName'].setValue(affiliate.businessName);
      this.profilePaymentForm.controls['businessDocumentType'].setValue(affiliate.businessDocumentType);
      this.profilePaymentForm.controls['businessDocument'].setValue(affiliate.businessDocument);
      this.profilePaymentForm.controls['businessAddress'].setValue(affiliate.businessAddress);
      this.profilePaymentForm.controls['businessZeroVat'].setValue(affiliate.businessZeroVat);

  }

}
