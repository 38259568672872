<form class="form-boxes m-0 p-0" action="">
     <div class="modal-header pb-0">
          <button type="button" class="close" aria-label="Close" (click)="closeModal()">
               <span aria-hidden="true">&times;</span>
          </button>
     </div>
     <div class="modal-body mb-3">
          <h5 class="modal-title">{{ 'affiliatecustomlink.Link de afiliado al tour' | translate}}</h5>
          <div class="row mt-3" *ngFor="let affiliateLink of affiliateLinks; let affiliateIndex = index">
               <div class="col-12 col-md-12">
                    <div class="field pb-2 d-grid">
                         <textarea (click)="onSelectedTourLink($event)" type="text" name="{{ 'link' + affiliateIndex }}" id="{{ 'link' + affiliateIndex }}" rows="2" cols="">{{ affiliateLink.link }}</textarea>
                         <label>{{ 'affiliatecustomlink.Enlace al tour' | translate }} ({{ affiliateLink.languageName }})</label>
                    </div>
               </div>
          </div>
     </div>

</form>

<app-message-panel></app-message-panel>