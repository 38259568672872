import { Pipe, PipeTransform } from '@angular/core';
import { marker as I18N } from '@biesbjerg/ngx-translate-extract-marker';
import { CommissionType } from 'src/app/business/models/billing/common/commission-type.constants';
import { GuideServiceState } from 'src/app/business/models/billing/common/guide-service-state.constants';

@Pipe({
     name: 'serviceStateClassPipe'
})
export class ServiceStateClassPipe implements PipeTransform {

     transform(classLabels: string, state: GuideServiceState): string {

          let result: string = classLabels;
          if ( state == GuideServiceState.Pending ) {
               result = result + " " + "pending-normal";
          } else if ( state == GuideServiceState.Approved ) {
               result = result + " " + "normal";
          } else if ( state == GuideServiceState.Billed ) {
               result = result + " " + "confirmed";
          } else if ( state == GuideServiceState.Deleted ) {
               result = result + " " + "remove";
          }

          return result ;

     }

}
