import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BaseService } from '../base.service';
import { ChunkGeneric } from 'src/app/domain/views/chunk-generic.view';
import { Pagination } from 'src/app/domain/views/pagination.view';
import { InvoiceDetails } from '../../models/billing/invoice-details.model';
import { TourBilling } from '../../models/billing/tour-billing.model';
import { NextBilling } from '../../models/billing/next-billing.model';
import { TourUtils } from '../../models/tour/common/tour.utils';
import { BillingUtils } from '../../models/billing/common/billing.utils';
import { CalendarUtils } from 'src/app/ui/utils/calendar.utils';
import { BookingBilling } from '../../models/billing/booking-billing.model';
import { ApiConstants } from '../../api/api.constants';
import { CustomerDetails } from '../../models/billing/customer-details.model';
import { BookingUtils } from '../../models/book/common/booking.utils';
import { InvoiceFullDetails } from '../../models/billing/invoice-full-details.model';
import { AffiliateApiEndpointsUtils } from '../../api/endpoints/affiliate-api-endponints.utils';
import { AffiliateStatsDetails } from '../../models/affiliate/affiliate-stats-details.model';
import { AffiliateUtils } from '../../models/affiliate/common/affiliate.utils';
import { AffiliateDailyStats } from '../../models/affiliate/affiliate-daily-stats.model';

@Injectable({
     providedIn: 'root'
})
export class AffiliateBillingService extends BaseService {



     findBilling(dateInit: string, dateEnd: string, keywords: string, page: number): Observable<ChunkGeneric<BookingBilling>> {

          return super.sendGetRequestWithRefresh(
               AffiliateApiEndpointsUtils.getAffiliateFindBillingEndpoint(dateInit, dateEnd, keywords, page),
               AffiliateApiEndpointsUtils.getAffiliateFindBillingEndpointAcceptVersion()
          )
          .pipe(
               map((response: any) => {

                    const bookingBillings = new Array<BookingBilling>();

                    response.items.map((bookingBillingApi: any) => {

                         const bookingBilling: BookingBilling = BookingUtils.getBookingBillingFromApi(bookingBillingApi);
                         bookingBillings.push(bookingBilling);

                    })


                    return new ChunkGeneric<BookingBilling>(bookingBillings, new Pagination(response.total, response.currentPage, response.chunkSize));
               })
          )
     }


     findLatestBilling(): Observable<Array<BookingBilling>> {

          return super.sendGetRequestWithRefresh(
               AffiliateApiEndpointsUtils.getAffiliateFindLatestBillingEndpoint(),
               AffiliateApiEndpointsUtils.getAffiliateFindLatestBillingEndpointAcceptVersion()
          )
          .pipe(
               map((response: any) => {

                    const bookingBillings = new Array<BookingBilling>();
                    response.map((bookingBillingApi: any) => {

                         const bookingBilling: BookingBilling = BookingUtils.getBookingBillingFromApi(bookingBillingApi);
                         bookingBillings.push(bookingBilling);

                    })

                    return bookingBillings;
               })
          )
     }



     findInvoices(page: number): Observable<ChunkGeneric<InvoiceDetails>> {

          return super.sendGetRequestWithRefresh(AffiliateApiEndpointsUtils.getAffiliateFindInvoicesEndpoint(page), AffiliateApiEndpointsUtils.getAffiliateFindInvoicesEndpointAcceptVersion())
          .pipe(
               map((response: any) => {

                    const invoices = new Array<InvoiceDetails>();

                    response.items.map((invoiceApi: any) => {

                         invoices.push(BillingUtils.getInvoiceFromApi(invoiceApi));

                    });

                    return new ChunkGeneric<InvoiceDetails>(invoices, new Pagination(response.total, response.currentPage, response.chunkSize));

               })
          )
     }



     findNextBilling(localeI18N: string): Observable<NextBilling> {

          return super.sendGetRequestWithRefresh(AffiliateApiEndpointsUtils.getAffiliateFindNextBillingEndpoint(), AffiliateApiEndpointsUtils.getAffiliateFindNextBillingEndpointAcceptVersion())
          .pipe(
               map((response: any) => {

                    const tourBillings = new Array<TourBilling>();
                    let nextBillingTotal: number = 0;

                    response.map((tourBillingApi: any) => {

                         const tourBilling = new TourBilling(
                              TourUtils.getTourResumeFromApi(tourBillingApi.tour, localeI18N),
                              BillingUtils.getBillingDetailsFromApi(tourBillingApi.info)
                         );

                         nextBillingTotal += tourBilling.billingDetails.commission;

                         tourBillings.push(tourBilling);

                    });

                    return new NextBilling(tourBillings, nextBillingTotal);

               })
          )
     }


     invoiceDownload(invoiceId: number): Observable<string> {

          return super.sendBlobRequestWithRefresh(AffiliateApiEndpointsUtils.getAffiliateInvoiceDownloadEndpoint(invoiceId), AffiliateApiEndpointsUtils.getAffiliateInvoiceDownloadEndpointAcceptVersion())
          .pipe(
               map((response: any) => {

                    return response;

               })
          )
     }



     findInvoice(invoiceId: number): Observable<InvoiceFullDetails> {

          return super.sendGetRequestWithRefresh(AffiliateApiEndpointsUtils.getAffiliateFindInvoiceEndpoint(invoiceId), AffiliateApiEndpointsUtils.getAffiliateFindInvoiceEndpointAcceptVersion())
          .pipe(
               map((response: any) => {

                    return new InvoiceFullDetails(
                         BillingUtils.getInvoiceIssuerFromApi(response.issuer),
                         BillingUtils.getInvoiceFromApi(response),
                         null
                    );

               })
          )
     }


     getAffiliateStats(): Observable<AffiliateStatsDetails> {
       return super.sendGetRequestWithRefresh(AffiliateApiEndpointsUtils.getAffiliateProfileStatsEndpoint(), AffiliateApiEndpointsUtils.getAffiliateProfileStatsEndpointAcceptVersion())
         .pipe(
           map((response: any) => {
                return AffiliateUtils.getAffiliateStatsFromApi(response);

           })
         )
     }


     getAffiliateDailyStats(year: number, month: number): Observable<AffiliateDailyStats> {
       return super.sendGetRequestWithRefresh(AffiliateApiEndpointsUtils.getAffiliateProfileDailyStatsEndpoint(year, month), AffiliateApiEndpointsUtils.getAffiliateProfileDailyStatsEndpointAcceptVersion())
         .pipe(
           map((response: any) => {
                return AffiliateUtils.getAffiliateDailyStatsFromApi(response);

           })
         )
     }


}
