import { ViewChild } from '@angular/core';
import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { City } from 'src/app/business/models/master/city/city.model';
import { Country } from 'src/app/business/models/master/country/country.model';
import { TourResume } from 'src/app/business/models/tour/tour-resume.model';
import { GenericException } from 'src/app/domain/exceptions/generic.exception';
import { UserMasterInteractor } from 'src/app/domain/interactor/user/user-master.interactor';
import { Pagination } from 'src/app/domain/views/pagination.view';
import { MessagePanelComponent } from 'src/app/ui/components/shared/message-panel/message-panel.component';
import { ServerErrorManager } from 'src/app/ui/components/utils/server-error-manager.utils';
import { marker as I18N } from '@biesbjerg/ngx-translate-extract-marker';
import { GuideToursFilterView } from 'src/app/domain/views/guide/guide-tours-filter.view';

@Component({
     selector: 'app-tours-search',
     templateUrl: './tours-search.component.html'
})
export class ToursSearchComponent implements OnInit {

     @ViewChild(MessagePanelComponent)
     private messagePanelComponent: MessagePanelComponent;

     @Input() tours: Array<TourResume>;

     @Input() isForReseller: boolean;

     @Input() pagination: Pagination;

     @Output() tourFilterTrigger = new EventEmitter<GuideToursFilterView>();

     @Output() tourSelectedTrigger = new EventEmitter<TourResume>();




     countryId: number;
     cityId: number;
     keywords: string;
     pageNumber: number;

     toursChunk: Array<TourResume>;

     currentCountrySelected: number;

     countries: Array<Country>;
     cities: Array<City>;

     paginationNextLabel: string;
     paginationPreviousLabel: string;

     desktopVersion: boolean;
     initialWindowSize: number;

     constructor(
          private translateService: TranslateService,
          private userMasterInteractor: UserMasterInteractor,
          private serverErrorManager: ServerErrorManager,
     ) {
          this.pagination = new Pagination(0, 1, 20);
          this.tours = new Array<TourResume>();
          this.pageNumber = this.pagination.currentPage;
          this.countryId = null;
          this.cityId = null;

          this.desktopVersion = true;
     }

     ngOnInit() {

          this.initialWindowSize = window.innerWidth;

          this.userMasterInteractor.getCountriesByUser()
          .subscribe(
               ( countries: Array<Country> ) => {
                    this.countries = countries;
               },
               (error: GenericException) => {
                    this.serverErrorManager.manageErrorCode(error, this.messagePanelComponent);

               }
          )

          this.translateService.get(I18N('pagination.Siguiente')).subscribe(
               (messageTranslated: string) => {
                    this.paginationNextLabel = messageTranslated;
               }
          )

          this.translateService.get(I18N('pagination.Previous')).subscribe(
               (messageTranslated: string) => {
                    this.paginationPreviousLabel = messageTranslated;
               }
          )

          this.filterTours();

     }


     filterTours() {
          this.tourFilterTrigger.emit(new GuideToursFilterView(this.keywords, this.countryId, this.cityId, this.pageNumber));
     }


     onPageChanged( currentPage: number ) {
          this.pageNumber = currentPage;
          this.filterTours();
     }


     onSearchKeywordChanged() {
          this.filterTours();
     }


     onCountrySelected() {

          if ( this.countryId != this.currentCountrySelected ) {

               this.currentCountrySelected = this.countryId;
               this.cities = new Array<City>();
               this.cityId = null;

               if ( this.countryId ) {
                    this.userMasterInteractor.getCitiesByUser( this.countryId )
                    .subscribe(
                         (cities: Array<City>) => {
                              this.cities = cities;
                         },
                         (error: GenericException) => {
                              this.serverErrorManager.manageErrorCode(error, this.messagePanelComponent);
                         }
                    );
               }

               this.filterTours();

          }
     }


     onCitySelected() {
          this.filterTours();
     }

     onTourSelected(tourResume: TourResume) {
          this.tourSelectedTrigger.emit(tourResume);
     }


     onDesktopVersionUpdated(isDesktopVersion: boolean) {
          this.desktopVersion = isDesktopVersion;
     }


}
