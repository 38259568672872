import { Component, OnInit } from '@angular/core';
import { NavigationConstants } from 'src/app/ui/commons/navigation.constants';
import { Router } from '@angular/router';

@Component({
     selector: 'app-forbidden',
     templateUrl: './forbidden.component.html'
})
export class ForbiddenComponent implements OnInit {

     constructor(private router: Router) { }

     ngOnInit() {
     }

     goToDashboard() {
          this.router.navigate([ NavigationConstants.getNavigationUserZone() ]);
     }

     goToHomeLanding() {
          this.router.navigate( [ NavigationConstants.getNavigationHome() ] );
     }

     onHeaderItemSelected(anchor: string) {
          this.router.navigate( [ NavigationConstants.getNavigationHome() ], { fragment: anchor } );
     }

}
