<div class="row">

    <div class="col-12 col-lg-6 mb-4">
        <label for="">{{ 'createtourdate.Elige jornadas' | translate }}</label>

        <div class="btn-group btn-group-toggle w-100 days-week" data-toggle="buttons">
            <label *ngFor="let weekday of weekdaysTypes" (click)="onWeekdaySelected(weekday)" class="btn btn-secondary ml-0 px-2" [ngClass]="{'active': weekdayActive == weekday, 'included': this.weekdaysIncluded.includes(weekday)}">
              {{ '' | createTourWeekdayLabel:weekday }}
            </label>
        </div>
    </div>
    <!-- <div class="col-12">
        <app-feedback [formGroup]="seasonDetailsFormGroup" field="days" validator="required" feedback="{{ 'singup.Es necesario que indiques las jornadas para un tour' | translate }}" [refresh]="refreshValidators"></app-feedback>
    </div> -->
</div>