<form class="form-boxes m-0 p-0" [formGroup]="createTourProviderForm">

    <div class="row">
        <div class="col-12 col-md-6 col-lg-3">
            <div class="field pb-2">
                <select formControlName="providerId" name="providerId" id="providerId">
                    <option value="">{{ 'createtourdetails.Selecciona la opción' | translate }}</option>
                    <option *ngFor="let provider of providers" value="{{ provider.id }}">{{ provider.companyName }} </option>
                </select>
                <label for="providerId">{{ 'createtourdetails.Proveedor' | translate }}</label>
            </div>
            <app-feedback [formGroup]="createTourProviderForm" field="providerId" validator="required" feedback="{{ 'createtourdetails.Proveedor requerido' | translate }}" [refresh]="refreshValidators"></app-feedback>
        </div>
    </div>

    <div class="row mb-5 pb-5 control-footer">
        <div class="col-6 col-md-6 offset-md-6 col-lg-4 offset-lg-8">
            <button (click)="saveProvider()" type="submit" value="{{ 'createtourdetails.Siguiente' | translate }}" class="btn btn-full-width btn-primary mb-3 mb-sm-0 white-color">
                {{ 'createtourdetails.Siguiente' | translate }}
                <img class="ic-small ml-2" src="assets/img/ic_arrow-next.svg">
            </button>
        </div>
    </div>
</form>


<app-message-panel [preloading]="false"></app-message-panel>