import { ApiConstants } from '../api.constants';
import { ApiUtils } from '../api.utils';

export class CommonApiEndpointsUtils {


     //PUBLIC
     public static getSingupProviderEndpoint(): string {
          return 'clientangular/providers';
     }

     public static getSingupProviderEndpointAcceptVersion(): string {
          return ApiConstants.API_HEADER_ACCEPT_V_1_0_1;
     }


     public static getSingupProviderObject(
          email: string,
          verificationCode: string,
          newPassword: string,
          newPasswordConfirm: string,
          languageId: number,
          web: string,
          phone: string,
          country: number,
          city: number,
          contactPerson: string,
          contactEmail: string,
          bookingNotificationEmailActive: boolean,
          bookingNotificationEmail: string,
          bookingNotificationEmail2: string,
          businessName: string,
          businessDocumentType: string,
          businessDocument: string,
          businessAddress: string,
          businessZeroVat: boolean
     ): any {

          return {
               'email': email,
               'verificationCode': verificationCode,
               'newPassword': newPassword,
               'newPasswordConfirm': newPasswordConfirm,
               'languageId': languageId,
               'web': web,
               'phone': phone,
               'country': country,
               'cityId': city,
               'contactPerson': contactPerson,
               'contactEmail': contactEmail,
               'bookingNotificationEmailActive': bookingNotificationEmailActive,
               'bookingNotificationEmail': bookingNotificationEmail,
               'bookingNotificationEmail2': bookingNotificationEmail2,
               'businessName': businessName,
               'businessDocumentType': businessDocumentType,
               'businessDocument': businessDocument,
               'businessAddress': businessAddress,
               'businessZeroVat': businessZeroVat
          }
     }




     public static getSingupAffiliateEndpoint(): string {
          return 'auth/affiliates/register';
     }

     public static getSingupAffiliateEndpointAcceptVersion(): string {
          return ApiConstants.API_HEADER_ACCEPT_V_1_0_1;
     }


     public static getSingupAffiliateObject(
          email: string,
          newPassword: string,
          newPasswordConfirm: string,
          languageId: number,
          websites: Array<string>,
          phone: string,
          country: number,
          city: number,
          companyName: string,
          businessDocumentType: string,
          businessDocument: string,
          businessAddress: string,
          businessZeroVat: boolean
     ): any {

          const webs = new Array<any>();
          websites.forEach( (website: string) => {
               webs.push({ 'web': website});
          })

          return {
               'email': email,
               'newPassword': newPassword,
               'newPasswordConfirm': newPasswordConfirm,
               'languageId': languageId,
               'country': country,
               'cityId': city,
               'companyName': companyName,
               'businessDocumentType': businessDocumentType,
               'businessDocument': businessDocument,
               'businessAddress': businessAddress,
               'businessZeroVat': businessZeroVat,
               'phone': phone,
               'webs': webs
          }
     }





     public static getLanguagesByLanguage(languageCode: string): string {
          return ApiUtils.prepareQueryRequest('clientangular/languagesdashboard', { 'languageCode': languageCode });
     }

     public static getLanguagesByLanguageAcceptVersion(): string {
          return ApiConstants.API_HEADER_ACCEPT_V_1_0_1;
     }

     public static getCountriesByLanguage(languageId: number): string {
          return ApiUtils.prepareQueryRequest('clientangular/countries', { 'languageId': languageId });
     }

     public static getCountriesByLanguageAcceptVersion(): string {
          return ApiConstants.API_HEADER_ACCEPT_V_1_0_1;
     }


     public static getCityByCountryAndLanguage(countryId: number, languageId: number): string {
          return ApiUtils.prepareQueryRequest(`clientangular/countries/${countryId}/cities`, { 'languageId': languageId });
     }

     public static getCityByCountryAndLanguageAcceptVersion(): string {
          return ApiConstants.API_HEADER_ACCEPT_V_1_0_1;
     }

     public static getCancelBookingEndpoint(): string {
          return 'clientangular/bookings/cancel';
     }

     public static getCancelBookingEndpointAcceptVersion(): string {
          return ApiConstants.API_HEADER_ACCEPT_V_1_0_1;
     }

     public static getCancelBookingObject(orderBookingId: number, verificationCode: string): any {
          return {
               "bookingId": orderBookingId,
               "verificationCode": verificationCode
          }
     }




     public static getRateBookingEndpoint(): string {
          return 'clientangular/bookings/rate';
     }

     public static getRateBookingEndpointAcceptVersion(): string {
          return ApiConstants.API_HEADER_ACCEPT_V_1_0_1;
     }

     public static getRateBookingObject(orderBookingId: number, verificationCode: string, rate: number, comments: string): any {
          return {
               "bookingId": orderBookingId,
               "verificationCode": verificationCode,
               "rate": rate,
               "comments": comments
          }
     }


     public static getSendContactEmailEndpoint(): string {
          return 'clientangular/email';
     }

     public static getSendContactEmailEndpointAcceptVersion(): string {
          return ApiConstants.API_HEADER_ACCEPT_V_1_0_1;
     }

     public static getSendContactEmailObject(name: string, email: string, companyName: string, webUrl: string, comment: string): any {
          return {
               "name": name,
               "email": email,
               "companyName": companyName,
               "webUrl": webUrl,
               "comment": comment
          }
     }



     public static getPublicTourDetailsEndpoint(tourId: number, languageCode: string): string {
          return `clientangular/tours/${tourId}?languageCode=${languageCode}`;
     }

     public static getPublicTourDetailsEndpointAcceptVersion(): string {
          return ApiConstants.API_HEADER_ACCEPT_V_1_0_1;
     }


     public static getLoginEndpoint(): string {
          return 'auth/login';
     }

     public static getLoginEndpointAcceptVersion(): string {
          return ApiConstants.API_HEADER_ACCEPT_V_1_0_1;
     }

     public static getRefreshTokenEndpoint(): string {
          return 'auth/refreshtoken';
     }

     public static getRefreshTokenEndpointAcceptVersion(): string {
          return ApiConstants.API_HEADER_ACCEPT_V_1_0_1;
     }

     public static getRememberPasswordEndpoint(): string {
          return 'auth/sendrememberpasswordemail';
     }

     public static getRememberPasswordEndpointAcceptVersion(): string {
          return ApiConstants.API_HEADER_ACCEPT_V_1_0_1;
     }

     public static getRememberPasswordConfirmationEndpoint(): string {
          return 'clientangular/password';
     }

     public static getRememberPasswordConfirmationEndpointAcceptVersion(): string {
          return ApiConstants.API_HEADER_ACCEPT_V_1_0_1;
     }


     //PROFILE
     public static getProfileChangePasswordEndpoint(): string {
          return 'auth/changepassword';
     }

     public static getProfileChangePasswordEndpointAcceptVersion(): string {
          return ApiConstants.API_HEADER_ACCEPT_V_1_0_1;
     }




     //PUBLIC
     public static getBookingForPaymentEndpoint(orderBookingId: number, verificationCode: string): string {
          return `clientangular/bookings/${orderBookingId}/${verificationCode}`;
     }

     public static getBookingForPaymentEndpointAcceptVersion(): string {
          return ApiConstants.API_HEADER_ACCEPT_V_1_0_1;
     }

     public static getPaymentCurrenciesEndpoint(): string {
          return `clientangular/paymentcurrencies`;
     }

     public static getPaymentCurrenciesEndpointAcceptVersion(): string {
          return ApiConstants.API_HEADER_ACCEPT_V_1_0_1;
     }

}


