import { NgModule, Injector } from '@angular/core';
import { WindowSizeControllerComponent } from './ui/components/controllers/window-size-controller/window-size-controller.component';
import { FeedbackComponent } from './ui/components/shared/form/feedback/feedback.component';
import { MessagePanelComponent } from './ui/components/shared/message-panel/message-panel.component';
import { CommonModule } from '@angular/common';
import { SidebarComponent } from './ui/components/shared/sidebar/sidebar.component';
import { HeaderComponent } from './ui/components/shared/header/header.component';
import { RouterModule } from '@angular/router';
import { ChangePasswordComponent } from './ui/components/shared/profile/change-password/change-password.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { TranslationModule } from './translation.module';

import { AgmCoreModule } from '@agm/core';

import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { MainTranslationEscapedPipe } from './ui/components/pipes/main-translation-escaped.pipe';
import { MainTranslationComponent } from './ui/components/shared/form/main-translation/main-translation.component';

import { AngularEditorModule } from '@kolkov/angular-editor';
import { LanguagePickerComponent } from './ui/components/shared/language-picker/language-picker.component';
import { DefaultPlaceholderImage } from './ui/components/pipes/default-placeholder-image.pipe';
import { WindowScrollControllerComponent } from './ui/components/controllers/window-scroll-controller/window-scroll-controller.component';
import { I18NTranslationSavePipe } from './ui/components/pipes/i18n-translation-save.pipe';
import { AdminHeaderPublicNavigationComponent } from './ui/components/shared/header/public-navigation/public-navigation.component';
import { TourStateClassPipe } from './ui/components/pipes/admin/tour-state-class.pipe';
import { CreateTourDetailsComponent }           from './ui/components/shared/tour/create/details/details.component';
import { CreateTourUsersComponent }             from './ui/components/shared/tour/create/users/users.component';
import { CreateTourLocationComponent }          from './ui/components/shared/tour/create/location/location.component';
import { CreateTourScheduleComponent }          from './ui/components/shared/tour/create/schedule/schedule.component';
import { CreateTourScheduleFixedComponent }     from './ui/components/shared/tour/create/schedule/components/fixed/fixed.component';
import { CreateTourScheduleSeasonComponent }    from './ui/components/shared/tour/create/schedule/components/season/season.component';
import { CreateTourScheduleDetailsComponent }   from './ui/components/shared/tour/create/schedule/components/details/details.component';
import { CreateTourScheduleWeekdaysComponent }  from './ui/components/shared/tour/create/schedule/components/weekdays/weekdays.component';
import { CreateTourScheduleWeekdayHoursComponent } from './ui/components/shared/tour/create/schedule/components/weekday-hours/weekday-hours.component';
import { CreateTourScheduleExceptionDayComponent } from './ui/components/shared/tour/create/schedule/components/exceptions-day/exception-day.component';
import { CreateTourScheduleCalendarComponent }  from './ui/components/shared/tour/create/schedule/components/calendar/calendar.component';
import { CreateTourStepsComponent }             from './ui/components/shared/tour/create/steps/steps.component';
import { ProviderCreateTourStepClassPipe }      from './ui/components/pipes/admin/provider-create-tour-step-class.pipe';
import { ProviderCreateTourWeekdayLabelPipe }   from './ui/components/pipes/admin/provider-create-tour-weekday-label.pipe';
import { BillingCardsComponent } from './ui/components/shared/billing/list/components/cards/cards.component';
import { BillingTableComponent } from './ui/components/shared/billing/list/components/table/table.component';

import {NgxPaginationModule} from 'ngx-pagination';
import { BillingStateBookingPipe } from './ui/components/pipes/billing/billing-state-booking.pipe';
import { TourCommissionFarePipe } from './ui/components/pipes/tour/tour-commission-fare.pipe';
import { InvoiceStatePaymentPipe } from './ui/components/pipes/billing/invoice-state-payment.pipe';
import { InvoicePaymentTypeAdminPipe } from './ui/components/pipes/billing/invoice-payment-type-admin.pipe';
import { InvoicePaymentTypeProviderPipe } from './ui/components/pipes/billing/invoice-payment-type-provider.pipe';
import { InvoicePaymentTypeAmountPipe } from './ui/components/pipes/billing/invoice-type-amount.pipe';
import { CancelationReasonLabelPipe } from './ui/components/pipes/booking/booking-cancelation-reason-label.pipe';
import { InvoiceStateClassPipe } from './ui/components/pipes/billing/invoice-state-class.pipe';
import { ListBookingComponent } from './ui/components/shared/booking/list/list.component';
import { CancelBookingModalComponent } from './ui/components/shared/booking/detail/cancel-booking/cancel-booking-modal.component';
import { EditBookContactDetailsModalComponent } from './ui/components/shared/booking/detail/edit-booking/edit-book-contact-details-modal.component';
import { DetailBookingTableComponent } from './ui/components/shared/booking/detail/table/table.component';
import { NgbModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { DetailBookingCardsComponent } from './ui/components/shared/booking/detail/cards/cards.component';
import { I18NMonthFromNumberPipe } from './ui/components/pipes/i18n-month-number.pipe';
import { ProfileStripeComponent } from './ui/components/shared/profile/stripe/stripe.component';
import { ProfileProviderDetailsComponent } from './ui/components/shared/profile/provider-details/details.component';
import { ProfilePaymentComponent } from './ui/components/shared/profile/payment/payment.component';
import { ProfileAffiliateDetailsComponent } from './ui/components/shared/profile/affiliate-details/details.component';
import { ListBillingComponent } from './ui/components/shared/billing/list/list.component';
import { ProfileAffiliateWebsitesComponent } from './ui/components/shared/profile/affiliate-websites/details.component';
import { ListInvoicesComponent } from './ui/components/shared/billing/invoices/list/list.component';
import { InvoicesTableComponent } from './ui/components/shared/billing/invoices/list/components/table/table.component';
import { InvoicesCardsComponent } from './ui/components/shared/billing/invoices/list/components/cards/cards.component';
import { ProfileGuideDetailsComponent } from './ui/components/shared/profile/guide-details/details.component';
import { ToursSearchComponent } from './ui/components/shared/tour/search/tours-search.component';
import { ListBookingEventComponent } from './ui/components/shared/booking/event/event.component';
import { GuideBookingsToursResellerComponent } from './ui/components/shared/booking/tours-reseller/tours-reseller.component';
import { ListServicesComponent } from './ui/components/shared/services/list/list.component';
import { CommissionTypeLabelPipe } from './ui/components/pipes/commissions/commission-type-label.pipe';
import { CommissionsTableComponent } from './ui/components/shared/services/list/components/table/table.component';
import { ServiceStateLabelPipe } from './ui/components/pipes/commissions/service-state-label.pipe';
import { ServiceStateClassPipe } from './ui/components/pipes/commissions/service-state-class.pipe';
import { CreateGuideServiceComponent } from './ui/components/shared/services/create/create.component';
import { I18NGuideServiceStatePipe } from './ui/components/pipes/i18n-guide-service-state.pipe';
import { CommissionsCardsComponent } from './ui/components/shared/services/list/components/cards/cards.component';

@NgModule({
  declarations: [
    FeedbackComponent,
    MessagePanelComponent,
    WindowSizeControllerComponent,
    WindowScrollControllerComponent,
    SidebarComponent,
    HeaderComponent,
    AdminHeaderPublicNavigationComponent,
    ChangePasswordComponent,
    ProfileProviderDetailsComponent,
    ProfileAffiliateDetailsComponent,
    ProfilePaymentComponent,
    ProfileStripeComponent,
    ProfileAffiliateWebsitesComponent,
    MainTranslationComponent,
    LanguagePickerComponent,
    CreateTourDetailsComponent,
    CreateTourUsersComponent,
    CreateTourLocationComponent,
    CreateTourScheduleComponent,
    CreateTourScheduleFixedComponent,
    CreateTourScheduleSeasonComponent,
    CreateTourScheduleDetailsComponent,
    CreateTourScheduleWeekdaysComponent,
    CreateTourScheduleWeekdayHoursComponent,
    CreateTourScheduleExceptionDayComponent,
    CreateTourScheduleCalendarComponent,
    CreateTourStepsComponent,
    ListBookingComponent,
    CancelBookingModalComponent,
    EditBookContactDetailsModalComponent,
    DetailBookingTableComponent,
    DetailBookingCardsComponent,
    ProfileGuideDetailsComponent,
    ToursSearchComponent,
    ListBookingEventComponent,
    GuideBookingsToursResellerComponent,

    //Billing
    BillingCardsComponent,
    BillingTableComponent,
    ListBillingComponent,
    InvoicesTableComponent,
    InvoicesCardsComponent,
    ListInvoicesComponent,


    //commissions
    ListServicesComponent,
    CommissionsTableComponent,
    CommissionsCardsComponent,
    CreateGuideServiceComponent,


    //Pipes
    MainTranslationEscapedPipe,
    DefaultPlaceholderImage,
    I18NTranslationSavePipe,
    TourStateClassPipe,
    ProviderCreateTourStepClassPipe,
    ProviderCreateTourWeekdayLabelPipe,
    BillingStateBookingPipe,
    InvoiceStatePaymentPipe,
    InvoicePaymentTypeAdminPipe,
    InvoicePaymentTypeProviderPipe,
    InvoicePaymentTypeAmountPipe,
    TourCommissionFarePipe,
    CancelationReasonLabelPipe,
    InvoiceStateClassPipe,
    I18NMonthFromNumberPipe,
    I18NGuideServiceStatePipe,
    CommissionTypeLabelPipe,
    ServiceStateLabelPipe,
    ServiceStateClassPipe

  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NgbTooltipModule,
    NgxPaginationModule,
    NgbModule,
    TranslationModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyD6ZYUgcLhvmzx2b1kSR-_7Ag34bOod0CI',
      libraries: ['places']
    }),
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory
    }),
    AngularEditorModule
  ],
  exports: [
    FeedbackComponent,
    MessagePanelComponent,
    WindowSizeControllerComponent,
    WindowScrollControllerComponent,
    SidebarComponent,
    HeaderComponent,
    ChangePasswordComponent,
    ProfileProviderDetailsComponent,
    ProfileAffiliateDetailsComponent,
    ProfilePaymentComponent,
    ProfileStripeComponent,
    ProfileAffiliateWebsitesComponent,
    TranslationModule,
    AgmCoreModule,
    CalendarModule,
    MainTranslationComponent,
    AngularEditorModule,
    LanguagePickerComponent,
    CreateTourDetailsComponent,
    CreateTourUsersComponent,
    CreateTourLocationComponent,
    CreateTourScheduleComponent,
    CreateTourScheduleFixedComponent,
    CreateTourScheduleSeasonComponent,
    CreateTourScheduleDetailsComponent,
    CreateTourScheduleWeekdaysComponent,
    CreateTourScheduleWeekdayHoursComponent,
    CreateTourScheduleExceptionDayComponent,
    CreateTourScheduleCalendarComponent,
    CreateTourStepsComponent,
    ListBookingComponent,
    CancelBookingModalComponent,
    EditBookContactDetailsModalComponent,
    DetailBookingTableComponent,
    DetailBookingCardsComponent,
    ProfileGuideDetailsComponent,
    ToursSearchComponent,
    ListBookingEventComponent,
    GuideBookingsToursResellerComponent,

    //Billing
    BillingCardsComponent,
    BillingTableComponent,
    ListBillingComponent,
    InvoicesTableComponent,
    InvoicesCardsComponent,
    ListInvoicesComponent,

    //commissions
    ListServicesComponent,
    CommissionsTableComponent,
    CommissionsCardsComponent,
    CreateGuideServiceComponent,

    //Pipes
    MainTranslationEscapedPipe,
    DefaultPlaceholderImage,
    I18NTranslationSavePipe,
    TourStateClassPipe,
    ProviderCreateTourStepClassPipe,
    ProviderCreateTourWeekdayLabelPipe,
    BillingStateBookingPipe,
    InvoiceStatePaymentPipe,
    InvoicePaymentTypeAdminPipe,
    InvoicePaymentTypeProviderPipe,
    InvoicePaymentTypeAmountPipe,
    TourCommissionFarePipe,
    CancelationReasonLabelPipe,
    InvoiceStateClassPipe,
    I18NMonthFromNumberPipe,
    I18NGuideServiceStatePipe,
    CommissionTypeLabelPipe,
    ServiceStateLabelPipe,
    ServiceStateClassPipe

  ],
  bootstrap: []
})

export class SharedModule {
    constructor(private injector: Injector) {
        AppInjector = this.injector;
    }
}

export let AppInjector: Injector;


//https://www.freakyjolly.com/angular-7-6-add-google-maps-in-angular-2-plus-applications-using-angular-google-maps-module-agm-core-easily/
//https://mattlewis92.github.io/angular-bootstrap-calendar/#!?example=kitchen-sink
//https://mattlewis92.github.io/angular-calendar/docs/directives/CalendarPreviousViewDirective.html#info