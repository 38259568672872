<div id="publicLanding">
    <!-- {{ 'home.Conócenos' | translate }} -->
    <!--
    <app-header-landing (menuItemSelected)="onHeaderItemSelected($event)"></app-header-landing>
    -->

    <div class="row bg-grey-color header-simple">
        <div class="col-7 col-md-10">
            <a (click)="onHeaderItemSelected()"><img class="logo pr-4 pr-lg-5 mb-4 mb-md-auto" src="assets/img/logo-white.png"></a>
            <app-public-navigation></app-public-navigation>
        </div>
    </div>


    <!-- Content -->
    <div class="landing-page bg-section-gray h-100">
        <div class="section-primary header-content pb-5 mt-5">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12 white-color text-center text-md-left">
                        <h1 class="black-color">{{ 'privacy.Política de privacidad y condiciones de uso' | translate }}</h1>
                        <p class="black-color p-large mb-3 opacity-2">{{ 'privacy.Sus datos están seguros con nosotros' | translate }}</p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 text-center text-md-left">
                        <div class="row">
                            <div class="col-12">
                                <p>{{ 'privacy.En este apartado le informaremos sobre cómo utilizamos los datos que recogemos, cuáles son esos datos y cómo puede gestionarlos, exportarlos o eliminarlos si lo desea.' | translate }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="section-primary bg-section-gray">
            <div class="container-fluid">
                <div class="row mb-5">
                    <div class="col-12">
                        <h3 class="mb-3">{{ 'privacy.1. Legislación y definiciones' | translate }}</h3>
                        <p>{{ 'privacy.Booqlever.com actúa de conformidad con la legislación de Estonia, con domicilio social en Lõõtsatn 5-11, 11415 Tallin, Estonia. La última actualización de estas Políticas fue en XX-XX-2020' | translate }}</p>
                        <p>{{ 'privacy.En Booqlever recopilamos información sobre nuestros usuarios y clientes cada vez que se usa el servicio o se navega en nuestra página web, existen dos categorías de datos personales:' | translate }}</p>
                        <ul>
                            <li>{{ 'privacy.Los datos de empresa, usuario o afiliado, que en determinadas circunstancias pueden ser datos personales sobre el proveedor de servicios si el mismo se ha registrado como empresa o autónomo.' | translate }}</li>
                            <li>{{ 'privacy.Los clientes finales, quienes reservan servicios y compran artículos de la empresa y los proveedores de servicios utilizando el software de Booqlever.' | translate }}</li>
                        </ul>
                        <p>{{ 'privacy.*En esta política, "nosotros", "nos" y "nuestro" se refiere a Booqlever.' | translate }}</p>
                        <p>{{ 'privacy.*En esta política, "clientes" se refiere a “empresas”, “proveedores de servicios”, “afiliados”, "clientes" o cualquier persona que haga una reserva o un usuario del sistema.' | translate }}</p>
                        <p>{{ 'privacy.*En esta política "servicios" o “sistema” se refiere a la solución de reserva de citas suministrada por Booqlever.' | translate }}</p>
                    </div>
                </div>


                <div class="row mb-5">
                    <div class="col-12">
                        <h3 class="mb-3">{{ 'privacy.2. ¿Qué información personal recogemos?' | translate }}</h3>
                        <p>{{ 'privacy.Sobre empresas, proveedores de servicios o afiliados' | translate }}</p>
                        <ul>
                            <li>{{ 'privacy.Nombre completo' | translate }}</li>
                            <li>{{ 'privacy.Dirección' | translate }}</li>
                            <li>{{ 'privacy.Correo electrónico' | translate }}</li>
                            <li>{{ 'privacy.Número de teléfono' | translate }}</li>
                            <li>{{ 'privacy.IBAN' | translate }}</li>
                            <li>{{ 'privacy.Datos fiscales de la empresa/autónomo' | translate }}</li>
                            <li>{{ 'privacy.Método de pago PayPal/tarjeta de crédito*' | translate }}</li>
                        </ul>
                        <p>{{ 'privacy.Datos recolectados en línea' | translate }}</p>
                        <ul>
                            <li>{{ 'privacy.ID Cookie' | translate }}</li>
                            <li>{{ 'privacy.Dirección IP' | translate }}</li>
                            <li>{{ 'privacy.Ubicación' | translate }}</li>
                            <li>{{ 'privacy.Dispositivo' | translate }}</li>
                            <li>{{ 'privacy.Navegador' | translate }}</li>
                            <li>{{ 'privacy.Sistema operativo' | translate }}</li>
                            <li>{{ 'privacy.Datos de acceso' | translate }}</li>
                        </ul>
                        <p>{{ 'privacy.Datos de los clientes finales' | translate }}</p>
                        <ul>
                            <li>{{ 'privacy.Nombre' | translate }}</li>
                            <li>{{ 'privacy.Correo electrónico' | translate }}</li>
                            <li>{{ 'privacy.Número de teléfono' | translate }}</li>
                            <li>{{ 'privacy.Método de pago*' | translate }}</li>
                        </ul>
                        <p>{{ 'privacy.*Para su seguridad y protección, nunca almacenamos números de tarjetas de crédito en nuestros servidores. ' | translate }}</p>
                    </div>
                </div>


                <div class="row mb-5">
                    <div class="col-12">
                        <h3 class="mb-3">{{ 'privacy.3. Cómo recogemos la información' | translate }}</h3>
                        <p>{{ 'privacy.Cuando se crea una cuenta de usuario le pedimos el nombre de su compañía, dirección, número de teléfono, correo electrónico, información de la tarjeta de crédito, número de identificación fiscal y otra información sobre
                            su negocio, así como los nombres y direcciones de correo electrónico de los individuos autorizados en su cuenta. ' | translate }}</p>
                        <p>{{ 'privacy.Recopilamos información sobre usted cuando interactúa con los Servicios de Booqlever. Por ejemplo, si inicia una transacción a través de los Servicios de Booqlever, como una compra, podemos recopilar información sobre
                            usted, como su nombre, correo electrónico, número de teléfono, dirección, información de la tarjeta de crédito, así como cualquier otra información que proporcione para procesar la transacción.' | translate }}</p>
                        <p>{{ 'privacy.Si se ha suscrito a nuestra lista de correo electrónico: con su permiso, podemos enviarle correos electrónicos sobre nuestra empresa, nuevos productos y otras actualizaciones' | translate }}</p>
                        <p>{{ 'privacy.También podemos recopilar otra información personal a petición del suscriptor con el que está realizando la transacción o a través de él. También podemos almacenar la información cuando navega por nuestro sitio web,
                            su ordenador o dispositivo móvil nos proporciona datos como la dirección IP, el sistema operativo y el tipo de dispositivo a través de las Cookies.' | translate }}</p>
                        <p>{{ 'privacy.Nosotros y nuestros proveedores de servicios recopilamos información sobre su ubicación cuando usa o accede a los Servicios de Booqlever. El grado de precisión de los datos de localización varía dependiendo de la fuente
                            de dicha información.' | translate }}</p>
                    </div>
                </div>




                <div class="row mb-5">
                    <div class="col-12">
                        <h3 class="mb-3">{{ 'privacy.4. Uso de los datos personales' | translate }}</h3>
                        <p>{{ 'privacy.Sólo recopilamos sus datos para el propósito con el que usted los ha proporcionado y de la cantidad necesaria para este fin, además, siempre le pediremos un consentimiento expreso a la hora de recopilar cualquier dato
                            necesario. Éstos podrán ser utilizados para:' | translate }}</p>
                        <ul>
                            <li>{{ 'privacy.Personalizar la experiencia de usuario y permitirnos adaptar nuestros servicios a sus necesidades.' | translate }}</li>
                            <li>{{ 'privacy.Ayudar a iniciar sesión en caso de usar nuestro servicio' | translate }}</li>
                            <li>{{ 'privacy.Conocer la eficacia de las campañas de marketing como Google Ads o Facebook Ads.' | translate }}</li>
                            <li>{{ 'privacy.Ayudar a nuestros ingenieros a mejorar el servicio, basándose en la comprensión de cómo nos encuentran las personas y qué dispositivos utilizan.' | translate }}</li>
                            <li>{{ 'privacy.Evaluar la eficacia del servicio.' | translate }}</li>
                            <li>{{ 'privacy.Para procesar más rápidamente sus transacciones.' | translate }}</li>
                            <li>{{ 'privacy.Prevenir y delimitar ataques o usos fraudulentos de nuestros servicios.' | translate }}</li>
                            <li>{{ 'privacy.Para transmitirle información sobre los servicios reservados en la plataforma de reserva.' | translate }}</li>
                            <li>{{ 'privacy.Para permitir que sus actividades se comercialicen a través de empresas asociadas' | translate }}</li>
                        </ul>
                    </div>
                </div>




                <div class="row mb-5">
                    <div class="col-12">
                        <h3 class="mb-3">{{ 'privacy.5. Pagos' | translate }}</h3>
                        <p>{{ 'privacy.En Booqlever utilizamos diferentes pasarelas de pago para completar compras como pueden ser PayPal o Stripe. Puede encontrar las condiciones de privacidad de los proveedores de pago a continuación:' | translate }}</p>
                        <ul>
                            <li><a class="primary-link" href="https://stripe.com/es/connect-account/legal" target="_blank">https://stripe.com/es/connect-account/legal</a></li>
                            <li><a class="primary-link" href="https://www.paypal.com/es/webapps/mpp/ua/privacy-fu" target="_blank">https://www.paypal.com/es/webapps/mpp/ua/privacy-fu</a></li>
                        </ul>
                        <p>{{ 'privacy.La información de pago se encripta a través del Estándar de Seguridad de Datos de la Industria de Tarjetas de Pago (PCI-DSS). Los datos de la transacción de compra se almacenan sólo el tiempo necesario para completar
                            la transacción de compra. Después de que esto se completa, la información de su transacción de compra es eliminada.' | translate }}</p>
                        <p>{{ 'privacy.Todas las transacciones procesadas a través de un proveedor de gateway no se almacenan ni procesan en nuestros servidores.' | translate }}</p>
                        <p>{{ 'privacy.Todas las pasarelas de pago directo se adhieren a las normas establecidas por el PCI-DSS, gestionadas por el Consejo de Normas de Seguridad del PCI, que es un esfuerzo conjunto de marcas como Visa, MasterCard, American
                            Express y Discover.' | translate }}</p>
                        <p>{{ 'privacy.Para su seguridad y protección, nunca almacenamos números de tarjetas de crédito en nuestros servidores. Los requisitos del PCI-DSS ayudan a garantizar el manejo seguro de la información de las tarjetas de crédito por
                            nuestro sitio web y sus proveedores de servicios.' | translate }}</p>
                    </div>
                </div>





                <div class="row mb-5">
                    <div class="col-12">
                        <h3 class="mb-3">{{ 'privacy.6. ¿Utilizamos Cookies?' | translate }}</h3>
                        <p>{{ 'privacy.Si, como en todas las páginas web nosotros también utilizamos cookies. Estas cookies son pequeños archivos de texto que se almacenan en su ordenador o móvil y permiten a nuestra web "reconocerlos" y recordar sus preferencias.
                            ' | translate }}</p>
                        <p>{{ 'privacy.Cuando visita nuestra web por primera vez, suele proporcionar cierta información, como puede ser su idioma preferido. Esta información se introduce en el archivo de cookies y se envía a su navegador web. La próxima
                            vez que visite el mismo sitio web, su navegador utilizará esta información para personalizar el sitio web de acuerdo con sus preferencias individuales. ' | translate }}</p>
                        <p>{{ 'privacy.Las cookies nunca almacenan datos sensibles como pueden ser tarjetas de crédito o información personal.' | translate }}</p>
                    </div>
                </div>




                <div class="row mb-5">
                    <div class="col-12">
                        <h3 class="mb-3">{{ 'privacy.7. ¿Para qué sirven las Cookies?' | translate }}</h3>
                        <p>{{ 'privacy.En Booqlever utilizamos las cookies para diferentes propósitos: ' | translate }}</p>
                        <ul>
                            <li>{{ 'privacy.Recordar el idioma que hablas y el país en el que resides. ' | translate }}</li>
                            <li>{{ 'privacy.Ayudarte a iniciar sesión en caso de que utilices nuestro Software.' | translate }}</li>
                            <li>{{ 'privacy.Conocer la eficacia de las campañas de marketing como Google Ads o Facebook Ads.' | translate }}</li>
                            <li>{{ 'privacy.Ayudar a nuestros ingenieros a mejorar el Servicio, basándose en la comprensión de cómo nos encuentran las personas y qué dispositivos utilizan.' | translate }}</li>
                            <li>{{ 'privacy.Evaluar la eficacia de los Servicios.' | translate }}</li>
                            <li>{{ 'privacy.Prevenir y delimitar ataques o usos fraudulentos de nuestros servicios.' | translate }}</li>
                        </ul>
                    </div>
                </div>






                <div class="row mb-5">
                    <div class="col-12">
                        <h3 class="mb-3">{{ 'privacy.8. ¿Cómo manejar las Cookies?' | translate }}</h3>
                        <p>{{ 'privacy.Puede deshabilitar los archivos de cookies, a través de la configuración de su navegador. Para eliminar las cookies, establecidas por nuestro y otros sitios, puede seguir las instrucciones de la página de ayuda de su
                            navegador o sitio web. También puede bloquear la instalación de cookies en el futuro por parte de nuestro y otros sitios web.' | translate }}</p>
                        <p>{{ 'privacy.La información sobre los navegadores más utilizados se puede encontrar aquí:' | translate }}</p>
                        <ul>
                            <li><a class="primary-link" href="https://support.google.com/chrome/answer/95647?hl=es" target="_blank">{{ 'privacy.Google Chrome' | translate }}</a></li>
                            <li><a class="primary-link" href="https://support.microsoft.com/es-es/help/260971/description-of-cookies" target="_blank">{{ 'privacy.Internet Explorer' | translate }}</a></li>
                            <li><a class="primary-link" href="https://support.mozilla.org/es/kb/cookies-informacion-que-los-sitios-web-guardan-en-" target="_blank">{{ 'privacy.Mozilla Firefox' | translate }}</a></li>
                            <li><a class="primary-link" href="https://support.apple.com/es-es/guide/safari/sfri11471/mac" target="_blank">{{ 'privacy.Safari (versión de escritorio)' | translate }}</a></li>
                            <li><a class="primary-link" href="https://support.apple.com/es-es/HT201265" target="_blank">{{ 'privacy.Safari (versión para móviles)' | translate }}</a></li>
                            <li><a class="primary-link" href="https://support.google.com/nexus/answer/54068?visit_id=1-636586460409191609-3183169074&hl=es&rd=1" target="_blank">{{ 'privacy.Explorador de Android' | translate }}</a></li>
                            <li><a class="primary-link" href="https://www.opera.com/es/help" target="_blank">{{ 'privacy.Opera' | translate }}</a></li>
                            <li><a class="primary-link" href="https://www.opera.com/help/tutorials/security/privacy/" target="_blank">{{ 'privacy.Opera Mobile' | translate }}</a></li>
                        </ul>

                        <p>{{ 'privacy.Si su navegador no se encuentra en esta lista, consulte la documentación que ofrece la empresa del navegador. También puede consultar la documentación de su dispositivo móvil para obtener información sobre cómo deshabilitar
                            las cookies' | translate }}</p>
                        <p>{{ 'privacy.Como se ha mencionado anteriormente, si decide desactivar las cookies, debe saber que esto puede provocar que algunas secciones de nuestra web no se muestren de forma correcta o adaptada a sus preferencias.' | translate
                            }}
                        </p>
                    </div>
                </div>






                <div class="row mb-5">
                    <div class="col-12">
                        <h3 class="mb-3">{{ 'privacy.9. RGPD ¿Cuáles son los derechos de protección de datos y cómo pueden ejercerlos?' | translate }}</h3>
                        <p>{{ 'privacy.En virtud del Reglamento General (UE) 2016/679, de 27 de abril de 2016, sobre Protección de Datos (RGPD), se reconocen los siguientes derechos en relación con el tratamiento de los datos personales de los clientes:'
                            | translate }}
                        </p>

                        <h5 class="mb-3">{{ 'privacy.Derecho de acceso' | translate }}</h5>
                        <p>{{ 'privacy.Usted tiene derecho recibir confirmación de la existencia de datos personales, acceder a su contenido y obtener una copia.' | translate }}</p>

                        <h5 class="mb-3">{{ 'privacy.Derecho de rectificación ' | translate }}</h5>
                        <p>{{ 'privacy.Usted tiene derecho a actualizar, rectificar y / o corregir sus datos personales.' | translate }}</p>

                        <h5 class="mb-3">{{ 'privacy.Derecho al borrado / derecho al olvido y derecho a la restricción ' | translate }}</h5>
                        <p>{{ 'privacy.Usted tiene derecho a solicitar el borrado de los datos personales que hayan sido recopilados por los diferentes métodos de captación' | translate }}</p>

                        <h5 class="mb-3">{{ 'privacy.Derecho a la portabilidad de datos ' | translate }}</h5>
                        <p>{{ 'privacy.Usted tiene derecho a recibir una copia de sus datos personales proporcionados para un contrato y solicitar a Booqlever que transfiera sus datos personales a otro responsable del tratamiento.' | translate }}</p>

                        <h5 class="mb-3">{{ 'privacy.Derecho a retirar su consentimiento' | translate }}</h5>
                        <p>{{ 'privacy.Usted tiene derecho a poder retirar ese consentimiento, aunque la Compañía puede tener otros fundamentos legales para procesar datos personales con otros fines.' | translate }}</p>

                        <h5 class="mb-3">{{ 'privacy.Derecho a oponerse, en cualquier momento ' | translate }}</h5>
                        <p>{{ 'privacy.Usted tiene derecho a oponerse al procesamiento de datos personales en cualquier momento y en algunas circunstancias (en particular, cuando la Compañía no tiene que procesar los datos para cumplir con un requisito contractual
                            u otro requisito legal, o cuando la Compañía está utilizando datos personales para marketing directo).' | translate }}</p>
                        <p>{{ 'privacy.Sus derechos en relación con sus datos personales pueden estar limitados en algunas situaciones. Por ejemplo, si el cumplimiento de la solicitud del cliente revelaría datos personales sobre otra persona o si la Compañía
                            tiene un requisito legal o una base legítima convincente.' | translate }}</p>
                    </div>
                </div>






                <div class="row mb-5">
                    <div class="col-12">
                        <h3 class="mb-3">{{ 'privacy.10. Conservación y Eliminación de datos personales' | translate }}</h3>
                        <p>{{ 'privacy.Este artículo establece nuestra política de retención de datos y el procedimiento, diseñados para garantizar que cumplimos con nuestras obligaciones legales en lo referente a la conservación y eliminación de datos personales.
                            Los datos personales que utilizamos para cualquier fin o fines no deben almacenarse durante un periodo superior al necesario para dichos fines. Conservaremos sus datos personales de la siguiente manera:' | translate }}</p>
                        <p>{{ 'privacy.Todos los datos personales sobre usted, su empresa, proveedores de servicios, afiliados, productos, promociones, servicios y bienes relacionados que hayan sido almacenados en nuestro sistema se conservarán mientras
                            sea usuario de nuestro sistema. Puede editar esta información en cualquier momento y solicitar su eliminación cancelando la utilización del sistema. Puesto que almacenamos copias de seguridad de todas las bases de datos hasta
                            un máximo de sesenta días, dicha información puede existir en nuestros servidores hasta un máximo de sesenta (60) días, momento en el que se eliminarán.' | translate }}</p>
                        <p>{{ 'privacy.Sin perjuicio de las demás disposiciones de este artículo, podemos conservar sus datos personales cuando dicha conservación sea necesaria para cumplir una obligación legal a la que estemos sujetos, como las leyes contables,
                            o para proteger nuestros intereses vitales o los intereses vitales de una tercera persona física.' | translate }}</p>
                    </div>
                </div>






                <div class="row mb-5">
                    <div class="col-12">
                        <h3 class="mb-3">{{ 'privacy.11. Seguridad y Hosting ' | translate }}</h3>
                        <p>{{ 'privacy.Una de nuestras prioridades es garantizar la máxima seguridad, la integridad y la privacidad de la información personal enviada a nuestra web, revisamos y actualizamos nuestras medidas de seguridad conforme con los
                            últimos protocolos. ' | translate }}</p>
                        <p>{{ 'privacy.Sin embargo, ningún sistema es perfectamente seguro o confiable y no podemos garantizar que la transmisión de datos sea segura o esté disponible. Internet es un medio inseguro de por sí, y la confiabilidad de los servicios
                            de hospedaje, los intermediarios de Internet, su proveedor de servicios de Internet y otros proveedores de servicios no puede ser asegurada. Booqlever no garantiza la confidencialidad de las comunicaciones que se realicen a
                            través de la Plataforma, que la Plataforma sea compatible con su sistema de computación o que la Plataforma, o cualquier enlace en ella, esté libre de virus, gusanos, troyanos o dispositivos de desactivación u otro código destructivo
                            o contaminante.' | translate }}</p>
                        <p>{{ 'privacy.Usted acepta todos estos riesgos y es responsable de implementar salvaguardas para proteger la seguridad e integridad de su sistema informático. Usted es responsable de cualquier costo en el que incurra como resultado
                            de su uso de la Plataforma.' | translate }}</p>
                    </div>
                </div>






                <div class="row mb-5">
                    <div class="col-12">
                        <h3 class="mb-3">{{ 'privacy.12. Links a webs de terceros' | translate }}</h3>
                        <p>{{ 'privacy.Dentro de Booqlever podrá encontrar links que redireccionan a webs de terceros, las políticas expuestas en este documento solo se aplican en nuestro sitio web, en ningún caso se aplican en webs de terceros. Nosotros
                            no tenemos ningún control sobre el contenido o el uso de datos personales que pueda proporcionar al usar o navegar por otras webs y no nos hacemos responsables del tratamiento que puedan ejercer sobre esos datos personales.
                            Usted debe leer las políticas de privacidad de otros sitios web antes de proporcionarles cualquier información personal.' | translate }}</p>
                    </div>
                </div>






                <div class="row mb-5">
                    <div class="col-12">
                        <h3 class="mb-3">{{ 'privacy.13. Cambios en las políticas ' | translate }}</h3>
                        <p>{{ 'privacy.Nos reservamos el derecho de cambiar, modificar, añadir o eliminar partes de estas Políticas en cualquier momento. Cualquier cambio o actualización será efectivo inmediatamente después de su publicación en esta página.
                            ' | translate }}</p>
                    </div>
                </div>






                <div class="row mb-5">
                    <div class="col-12">
                        <h3 class="mb-3">{{ 'privacy.14. Contacto ' | translate }}</h3>
                        <p>{{ 'privacy.Si tiene alguna pregunta sobre esta Políticas puede contactar con nosotros por correo electrónico en info@booqlever.com' | translate }}</p>
                    </div>
                </div>

            </div>
        </div>
    </div>
    <!-- end content -->


    <app-message-panel></app-message-panel>
    <app-footer-landing></app-footer-landing>
</div>