export class AppTourConstants {


    public static CREATE_TOUR_FIXED_SCHEDULED_MAX_YEARS  = 2;
    public static CREATE_TOUR_DEFAULT_CANCELATION_LIMIT  = 0;
    public static CREATE_TOUR_DEFAULT_BOOKING_LIMIT  = 0;



}
