
    <div class="row dashboard-content-section-first dashboard-content-section">
        <div class="col-11 col-lg-12 col-xl-6 pl-0 order-1 order-lg-1">
            <h2 class="font-weight-bold">{{ 'listbooking.Listado de reservas' | translate }}</h2>
        </div>
    </div>

    <div *ngIf="guideRole" class="row mt-4" id="bookings-days-tabs">
         <div class="col-12">
              <ul ngbNav #nav="ngbNav" class="nav-tabs nav-justified">
                   <li ngbNavItem>
                        <a ngbNavLink (click)="onToursForToday(true)">{{ 'listbooking.Tours para hoy' | translate }}</a>
                   </li>
                   <li ngbNavItem>
                        <a ngbNavLink (click)="onToursForToday(false)">{{ 'listbooking.Tours para mañana' | translate }}</a>
                   </li>
              </ul>
        </div>
    </div>

    <div class="row">

        <div class="col-12 dashboard-content-section dashboard-calendar order-2 order-xl-1 mt-5 mb-5" [ngClass]="{'col-xl-8': !guideRole}">

            <div class="row empty-section" *ngIf="noResults">
                <div class="col-12 text-center my-auto">
                    <img class="logo mb-4" src="assets/img/logo_opacity-21.png">
                    <p>{{ 'listbooking.No se han encontrado reservas' | translate }}</p>
                </div>
            </div>

            <div *ngIf="!noResults">
                <div *ngFor="let bookEvent of bookEvents | paginate: pagination;" class="row">
                    <app-list-booking-event class="w-100"
                         [bookEvent]="bookEvent"
                         [adminRole]="adminRole" [guideRole]="guideRole"
                         (viewBookDetails)="onViewBookDetails($event)"
                         (newBooking)="onNewBooking($event)"
                         (allowBookings)="onAllowBookings($event)"
                         (disallowBookings)="onDisallowBookings($event)"
                         (showUpdateMaxBookings)="onShowUpdateMaxBookings($event)"
                    ></app-list-booking-event>
                </div>
            </div>
        </div>
        <div *ngIf="!guideRole" class="col-12 col-xl-4 sidebar-calendar order-1 order-xl-2">
            <div class="row">
                <div class="col-12">
                    <h2 class="font-weight-bold mb-4">{{ 'listbooking.Filtros' | translate }}</h2>
                </div>

            </div>
            <div class="row">
                <div class="col-12 form-calendar">
                    <div mwlCalendarPreviousView [(viewDate)]="viewDate" [view]="view"></div>

                    <div class="currentMonth">
                        {{ viewDate | calendarDate:(view + 'ViewTitle'):currentLocale }}
                    </div>

                    <div mwlCalendarNextView [view]="view" [(viewDate)]="viewDate"></div>

                    <mwl-calendar-month-view [viewDate]="viewDate" [weekStartsOn]="weekStartOnDay" (dayClicked)="onDateCalendarSelected($event)" (beforeViewRender)="onBeforeCalendarRender($event)">
                    </mwl-calendar-month-view>
                </div>
            </div>
            <div class="row mt-5">
                <div class="col-12">
                    <h2 class="font-weight-bold mb-4"><img class="mr-lg-2 ic-mid mb-1 mr-3" src="assets/img/ic_calendar.svg">{{ 'listbooking.Calendario' | translate }}</h2>
                </div>
                <form class="form-boxes d-block d-sm-flex d-xl-block m-0 p-0">
                    <div class="col-12 col-sm-4 col-xl-12">
                        <div class="field pb-2">
                            <select (change)="onCountrySelected()" name="country" [(ngModel)]="countryId">
                                <option [ngValue]="null">{{ 'listbooking.Todos los países' | translate }}</option>
                                <option *ngFor="let country of countries" value="{{ country.id }}">{{ country.name }}</option>
                            </select>
                            <label for="country">{{ 'listbooking.País' | translate }}</label>
                        </div>
                    </div>
                    <div class="col-12 col-sm-4 col-xl-12">
                        <div class="field pb-2">
                            <select (change)="onCitySelected()" name="city" [(ngModel)]="cityId">
                                <option [ngValue]="null">{{ 'listbooking.Todas las ciudades' | translate }}</option>
                                <option *ngFor="let city of cities" value="{{ city.id }}">{{ city.name }}</option>
                            </select>
                            <label for="city">{{ 'listbooking.Ciudad' | translate }}</label>
                        </div>
                    </div>
                    <div *ngIf="providerNames" class="col-12 col-sm-4 col-xl-12">
                        <div class="field pb-2">
                            <select (change)="onProviderSelected()" name="providerId" [(ngModel)]="providerId">
                                <option [ngValue]="null">{{ 'listbooking.Todos los proveedores' | translate }}</option>
                                <option *ngFor="let providerName of providerNames" value="{{ providerName.id }}">{{ providerName.companyName }}</option>
                            </select>
                            <label for="providerId">{{ 'listbooking.Proveedor' | translate }}</label>
                        </div>
                    </div>
                </form>
            </div>
        </div>

    </div>

    <!--  Pagination -->
    <div class="row bg-grey-mid-color" *ngIf="!noResults">
        <div class="col-12 d-flex justify-content-center">
            <pagination-controls [maxSize]="pagination.itemsPerPage" [directionLinks]="true" [autoHide]="true" [responsive]="true" [previousLabel]="paginationPreviousLabel" [nextLabel]="paginationNextLabel" (pageChange)="onPageChanged($event)"></pagination-controls>
        </div>
    </div>

    <div *ngIf="guideRole" (click)="onNewBookingForNewTour()" class="floating-button pointer bg-primary-color">
         <img src="assets/img/ic_calendar_white.svg">
    </div>

    <app-message-panel></app-message-panel>