<table class="table table-hover table-dark">
     <thead class="thead-dark">
          <tr>
               <th scope="col">{{ 'affiliateslist.USUARIO' | translate }}</th>
               <th scope="col">{{ 'affiliateslist.CONTACTO' | translate }}</th>
               <th scope="col">{{ 'affiliateslist.EMPRESA' | translate }}</th>
               <th scope="col">{{ 'affiliateslist.CIUDAD' | translate }}</th>
               <th scope="col">{{ 'affiliateslist.ESTADO' | translate }}</th>
          </tr>
     </thead>
     <tbody class="row-clickable">
          <tr *ngFor="let affiliate of affiliates | paginate: pagination;" (click)="onAffiliateClick(affiliate.id)">
               <td scope="row ">{{ affiliate.username }}</td>
               <td>
                    {{ affiliate.phone }}
               </td>
               <td>{{ affiliate.companyName }}</td>
               <td>{{ affiliate.country }}, {{ affiliate.city }}</td>
               <td [ngClass]="'status' | adminAffiliateStateClass: affiliate.state">
                    <div class="dot"></div> {{ '' | adminAffiliateStateName: affiliate.state | translate }}
               </td>

          </tr>

     </tbody>
</table>