export class AppConstants {


    public static ERROR_SERVER_UNKNOWN  = 0;
    public static ERROR_CLIENT_BAD_CREDENTIALS  = 999;
    public static ERROR_CLIENT_BAD_REQUEST      = 400;
    public static ERROR_CLIENT_UNAUTHORIZED     = 401;  // Access denied
    public static ERROR_CLIENT_FORBIDDEN        = 403;
    public static ERROR_CLIENT_NOT_FOUND        = 404;
    public static ERROR_SERVER_FAILURE          = 500;


    public static CALENDAR_WEEK_START_ON_DAY = 1;

    public static MAX_FILE_UPLOAD_SIZE = 8000000;
    public static TOUR_GALLERY_MAX_IMAGES_NUMBER = 5;


    public static APP_DATE_FORMAT_DD_MM_YYYY = 'DD/MM/YYYY';
    public static APP_DATE_DAY_FORMAT_DD = 'DD';

    public static NUMBER_MAX_VALE: number = 2147483647;
    public static TEXT_DEFAULT_MAX_LENGTH: number = 128;

    public static BOOKING_PERCENTAGE_CAPACITY_ALERT: number = 70;


    public static TOUR_DISPLAY_LANGUAGE_DEFAULT: string = 'es';


    public static BILLING_FIRST_DATE_YEAR: number = 2022;
    public static BILLING_FIRST_DATE_MONTH: number = 6;


    public static REGULAR_EXPRESSION_VALIDATE_WEBSITE: string = '(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?';

    public static REGULAR_EXPRESSION_VALIDATE_IBAN: RegExp = /^AL\d{10}[0-9A-Z]{16}$|^AD\d{10}[0-9A-Z]{12}$|^AT\d{18}$|^BH\d{2}[A-Z]{4}[0-9A-Z]{14}$|^BE\d{14}$|^BA\d{18}$|^BG\d{2}[A-Z]{4}\d{6}[0-9A-Z]{8}$|^HR\d{19}$|^CY\d{10}[0-9A-Z]{16}$|^CZ\d{22}$|^DK\d{16}$|^FO\d{16}$|^GL\d{16}$|^DO\d{2}[0-9A-Z]{4}\d{20}$|^EE\d{18}$|^FI\d{16}$|^FR\d{12}[0-9A-Z]{11}\d{2}$|^GE\d{2}[A-Z]{2}\d{16}$|^DE\d{20}$|^GI\d{2}[A-Z]{4}[0-9A-Z]{15}$|^GR\d{9}[0-9A-Z]{16}$|^HU\d{26}$|^IS\d{24}$|^IE\d{2}[A-Z]{4}\d{14}$|^IL\d{21}$|^IT\d{2}[A-Z]\d{10}[0-9A-Z]{12}$|^[A-Z]{2}\d{5}[0-9A-Z]{13}$|^KW\d{2}[A-Z]{4}22!$|^LV\d{2}[A-Z]{4}[0-9A-Z]{13}$|^LB\d{6}[0-9A-Z]{20}$|^LI\d{7}[0-9A-Z]{12}$|^LT\d{18}$|^LU\d{5}[0-9A-Z]{13}$|^MK\d{5}[0-9A-Z]{10}\d{2}$|^MT\d{2}[A-Z]{4}\d{5}[0-9A-Z]{18}$|^MR13\d{23}$|^MU\d{2}[A-Z]{4}\d{19}[A-Z]{3}$|^MC\d{12}[0-9A-Z]{11}\d{2}$|^ME\d{20}$|^NL\d{2}[A-Z]{4}\d{10}$|^NO\d{13}$|^PL\d{10}[0-9A-Z]{,16}n$|^PT\d{23}$|^RO\d{2}[A-Z]{4}[0-9A-Z]{16}$|^SM\d{2}[A-Z]\d{10}[0-9A-Z]{12}$|^SA\d{4}[0-9A-Z]{18}$|^RS\d{20}$|^SK\d{22}$|^SI\d{17}$|^ES\d{22}$|^SE\d{22}$|^CH\d{7}[0-9A-Z]{12}$|^TN59\d{20}$|^TR\d{7}[0-9A-Z]{17}$|^AE\d{21}$|^GB\d{2}[A-Z]{4}\d{14}$/;





}
