import { Event } from './event.model';

export class BookEvent {

     private _event: Event;
     private _capacity: number;
     private _bookings: number;
     private _availability: number;
     private _shows: number;
     private _percentageCapacity: number;
     private _bookingsAreBlocked: boolean;



     constructor(
          event: Event,
          capacity: number,
          bookings: number,
          availability: number,
          shows: number,
          percentageCapacity: number,
          bookingsAreBlocked: boolean
     ) {
          this._event = event;
          this._capacity = capacity;
          this._bookings = bookings;
          this._availability = availability;
          this._shows = shows;
          this._percentageCapacity = percentageCapacity;
          this._bookingsAreBlocked = bookingsAreBlocked;
     }


     get event(): Event {
          return this._event;
     }

     get capacity(): number {
          return this._capacity;
     }

     get bookings(): number {
          return this._bookings;
     }
     get availability(): number {
          return this._availability;
     }

     get shows(): number {
          return this._shows;
     }

     get percentageCapacity(): number {
          return this._percentageCapacity;
     }

     get bookingsAreBlocked(): boolean {
          return this._bookingsAreBlocked;
     }




}
