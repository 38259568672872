import { Component, OnInit, ViewChild } from '@angular/core';
import { MessagePanelComponent } from '../../../shared/message-panel/message-panel.component';
import { ServerErrorManager } from '../../../utils/server-error-manager.utils';
import { GenericException } from 'src/app/domain/exceptions/generic.exception';
import { AffiliateStatsDetails } from 'src/app/business/models/affiliate/affiliate-stats-details.model';
import { AffiliateBillingInteractor } from 'src/app/domain/interactor/affiliate/billing.interactor';
import { BookingBilling } from 'src/app/business/models/billing/booking-billing.model';
import { Pagination } from 'src/app/domain/views/pagination.view';
import { Router } from '@angular/router';
import { NavigationConstants } from 'src/app/ui/commons/navigation.constants';
import { AffiliateDailyStats } from 'src/app/business/models/affiliate/affiliate-daily-stats.model';
import { marker as I18N } from '@biesbjerg/ngx-translate-extract-marker';
import { TranslateService } from '@ngx-translate/core';
import { CalendarUtils } from 'src/app/ui/utils/calendar.utils';
import { environment }                      from 'src/environments/environment';
import { AffiliateDayStats } from 'src/app/business/models/affiliate/affiliate-day-stats.model';
import { AffiliateStats } from 'src/app/business/models/affiliate/affiliate-stats.model';

@Component({
     selector: 'app-dashboard-affiliate',
     templateUrl: './dashboard.component.html'
})
export class AffiliateDashboardComponent implements OnInit{


     @ViewChild(MessagePanelComponent)
     private messagePanelComponent: MessagePanelComponent;

     affiliateStatsDetails: AffiliateStatsDetails;
     latestBookings: Array<BookingBilling>;

     desktopVersion: boolean;
     initialWindowSize: number;
     noResults: boolean;
     pagination: Pagination;

     month: number;
     year: number;
     months: Array<number>;
     years: Array<number>;


     chartStats: AffiliateStats;
     chartData: any[];
     colorScheme = {
          domain: [environment.PRIMARY_COLOR, '#333333'],
     };

     constructor(
          private affiliateBillingInteractor: AffiliateBillingInteractor,
          private serverErrorManager: ServerErrorManager,
          private router: Router,
          private translateService: TranslateService
     ) {

          this.latestBookings = new Array<BookingBilling>();
          this.desktopVersion = true;
          this.noResults = true;

          this.years = CalendarUtils.getYearsAvailablesForBilling();
          this.year = CalendarUtils.getCurrentYear();
          this.months = CalendarUtils.getMonthsAvailablesForBillingFromYear(this.year);
          this.month = CalendarUtils.getCurrentMonth(true);

          this.affiliateBillingInteractor.getAffiliateStats()
          .subscribe(
               (affiliateStatsDetails: AffiliateStatsDetails) => {

                    MessagePanelComponent.hideLoading();
                    this.affiliateStatsDetails = affiliateStatsDetails;
                    this.initializeChartData(this.affiliateStatsDetails.dailyStats);

               },
               (error: GenericException) => {

                    MessagePanelComponent.hideLoading();
                    this.serverErrorManager.manageErrorCode(error, this.messagePanelComponent);

               }
          )

          this.affiliateBillingInteractor.findLatestBilling(5)
          .subscribe(
               (latestBookingBillings: Array<BookingBilling>) => {

                    this.latestBookings = latestBookingBillings;
                    this.noResults = latestBookingBillings.length == 0;

                    this.pagination = new Pagination(this.latestBookings.length, 1, this.latestBookings.length);

                    MessagePanelComponent.hideLoading();

               },
               (error: GenericException) => {

                    MessagePanelComponent.hideLoading();
                    this.serverErrorManager.manageErrorCode(error, this.messagePanelComponent);
               }
          )

     }
     ngOnInit(): void {
          this.initialWindowSize = window.innerWidth;
     }


     findDailyStats() {

          this.messagePanelComponent.showLoading();

          this.affiliateBillingInteractor.getAffiliateDailyStats(this.year, this.month)
          .subscribe(
               (affiliateDailyStats: AffiliateDailyStats) => {

                    MessagePanelComponent.hideLoading();
                    this.initializeChartData(affiliateDailyStats);

               },
               (error: GenericException) => {

                    MessagePanelComponent.hideLoading();
                    this.serverErrorManager.manageErrorCode(error, this.messagePanelComponent);

               }
          )
     }

     onDesktopVersionUpdated( isDesktopVersion: boolean ) {
          this.desktopVersion = isDesktopVersion;
     }

     goToAffiliationBilling() {
          this.router.navigate([NavigationConstants.getNavigationAffiliateBilling()]);
     }


     onYearSelected() {
          this.months = new Array<number>();
          this.month = null;
          if ( this.year != null ) {
               this.months = CalendarUtils.getMonthsAvailablesForBillingFromYear(this.year);
          }
     }

     onMonthSelected() {
          this.findDailyStats();
     }


     initializeChartData(dailyStates: AffiliateDailyStats) {

          this.chartStats = dailyStates.stats;

          const chartDataCommissionSeries: any[] = [];
          const chartDataBookingsSeries: any[] = [];

          dailyStates.daysStats.forEach( (dayStats: AffiliateDayStats) => {
                    chartDataCommissionSeries.push({'name': dayStats.date, 'value': dayStats.stats.totalCommissionAndVat});
                    chartDataBookingsSeries.push({'name': dayStats.date, 'value': dayStats.stats.totalBookingsNumber});
          });

          this.translateService.get(I18N('affiliatedashboard.Comisiones'), {})
            .subscribe(
                (commissionsLabelTranslated: string) => {
                    this.translateService.get(I18N('affiliatedashboard.Reservas'), {})
                    .subscribe(
                         (bookingsLabelTranslated: string) => {
                              const chartDataCommissions: any = {'name': commissionsLabelTranslated, 'series': chartDataCommissionSeries};
                              const chartDataBookings: any = {'name': bookingsLabelTranslated, 'series': chartDataBookingsSeries};
                              this.chartData = [chartDataCommissions, chartDataBookings];
                         }
                    )
                }
            )
     }
}



