import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ChunkGeneric } from 'src/app/domain/views/chunk-generic.view';
import { Pagination } from 'src/app/domain/views/pagination.view';
import { BaseService } from '../base.service';
import { map } from 'rxjs/operators';
import { ProviderResume } from '../../models/provider/provider-resume.model';
import { ProviderUtils } from '../../models/provider/common/provider.utils';
import { Provider } from '../../models/provider/provider.model';
import { AdminProviderApiEndpointsUtils } from '../../api/endpoints/admin-provider-api-endponints.utils';
import { CompanyName } from '../../models/master/company/company-name.model';


@Injectable({
  providedIn: 'root'
})
export class AdminProviderService extends BaseService {


  createProvider(companyName: string, email: string, languageId: number): Observable<boolean> {
    return super.sendPostRequestWithRefresh(AdminProviderApiEndpointsUtils.getCreateProviderEndpoint(),
      { 'email': email, 'companyName': companyName, 'languageId': languageId },
      AdminProviderApiEndpointsUtils.getCreateProviderEndpointAcceptVersion())
      .pipe(
        map(() => {
          return true;
        })
      )
  }


  findProviders(page: number): Observable<ChunkGeneric<ProviderResume>> {

    return super.sendGetRequestWithRefresh(AdminProviderApiEndpointsUtils.getProvidersEndpoint(page), AdminProviderApiEndpointsUtils.getProvidersEndpointAcceptVersion())
      .pipe(
        map((response: any) => {

          const pagination: Pagination = new Pagination(response.total, response.currentPage, response.chunkSize);
          const providers: Array<ProviderResume> = response.items.map((providerResumeApi: any) => {
            return ProviderUtils.getProviderResumeFromApi(providerResumeApi);
          })

          return new ChunkGeneric<ProviderResume>(providers, pagination)

        })
      )

  }


  findAllProviders(): Observable<Array<ProviderResume>> {

    return super.sendGetRequestWithRefresh(AdminProviderApiEndpointsUtils.getAllProvidersEndpoint(), AdminProviderApiEndpointsUtils.getAllProvidersEndpointAcceptVersion())
      .pipe(
        map((response: any) => {

          const providers: Array<ProviderResume> = response.map((providerResumeApi: any) => {
            return ProviderUtils.getProviderResumeFromApi(providerResumeApi);
          })

          return providers;

        })
      )

  }


  getProvidersNames(): Observable<Array<CompanyName>> {

    return super.sendGetRequestWithRefresh(AdminProviderApiEndpointsUtils.getAdminProvidersNamesEndpoint(), AdminProviderApiEndpointsUtils.getAdminProvidersNamesEndpointAcceptVersion())
      .pipe(
        map((response: any) => {

          const providers: Array<CompanyName> = response.map((provider: any) => {
            return new CompanyName(
              provider.id,
              provider.companyName
            )
          })

          return providers;

        })
      )

  }


  getProvidersNamesWithGuideManagement(): Observable<Array<CompanyName>> {

    return super.sendGetRequestWithRefresh(AdminProviderApiEndpointsUtils.getProvidersNamesWithGuideManagementEndpoint(), AdminProviderApiEndpointsUtils.getAdminProvidersNamesEndpointAcceptVersion())
      .pipe(
        map((response: any) => {

          const providers: Array<CompanyName> = response.map((provider: any) => {
            return new CompanyName(
              provider.id,
              provider.companyName
            )
          })

          return providers;

        })
      )

  }



  getProviderDetalis(providerId: number): Observable<Provider> {

    return super.sendGetRequestWithRefresh(AdminProviderApiEndpointsUtils.getProviderDetailsEndpoint(providerId), AdminProviderApiEndpointsUtils.getProviderDetailsEndpointAcceptVersion())
      .pipe(
        map((response: any) => {

             return ProviderUtils.getProviderFromApi(response);

        })
      )

  }



  resendWelcomeEmail(providerId: number): Observable<boolean> {

    return super.sendPostRequestWithRefresh(AdminProviderApiEndpointsUtils.getResendProviderWelcomeEndpoint(providerId), {}, AdminProviderApiEndpointsUtils.getResendProviderWelcomeEndpointAcceptVersion())
      .pipe(
        map(() => {
          return true
        })
      )

  }



  updateProviderDetails(providerId: number, companyName: string, web: string, phone: string, country: string, city: string, contactPerson: string, contactEmail: string): Observable<boolean> {
    return super.sendPutRequestWithRefresh(AdminProviderApiEndpointsUtils.getUpdateProviderDetailsEndpoint(providerId),
      {
        'companyName': companyName,
        'web': web,
        'phone': phone,
        'countryId': country,
        'cityId': city,
        'contactPerson': contactPerson,
        'contactEmail': contactEmail
      },
      AdminProviderApiEndpointsUtils.getUpdateProviderDetailsEndpointAcceptVersion())
      .pipe(
        map(() => {
          return true;
        })
      )


  }




  updateProviderPayment(
       providerId: number, businessName: string, businessDocumentType: string, businessDocument: string,
       businessAddress: string, businessZeroVat: boolean): Observable<boolean> {
    return super.sendPutRequestWithRefresh(AdminProviderApiEndpointsUtils.getUpdateProviderPaymentEndpoint(providerId),
      AdminProviderApiEndpointsUtils.getUpdateProviderPaymentObject(
        businessName, businessDocumentType, businessDocument, businessAddress, businessZeroVat
      ),
      AdminProviderApiEndpointsUtils.getUpdateProviderPaymentEndpointAcceptVersion())
      .pipe(
        map(() => {
          return true;
        })
      )


  }





  confirmProvider(providerId: number): Observable<boolean> {
    return super.sendPostRequestWithRefresh(AdminProviderApiEndpointsUtils.getConfirmProviderSingupEndpoint(providerId), {}, AdminProviderApiEndpointsUtils.getConfirmProviderSingupEndpointAcceptVersion())
      .pipe(
        map(() => {
          return true;
        })
      )
  }


  rejectProvider(providerId: number, message: string): Observable<boolean> {
    return super.sendPostRequestWithRefresh(AdminProviderApiEndpointsUtils.getRejectProviderSingupEndpoint(providerId), { 'message': message }, AdminProviderApiEndpointsUtils.getRejectProviderSingupEndpointAcceptVersion())
      .pipe(
        map(() => {
          return true;
        })
      )
  }


  unregisterProvider(providerId: number): Observable<boolean> {
    return super.sendPostRequestWithRefresh(AdminProviderApiEndpointsUtils.getUnregisterProviderEndpoint(providerId), {}, AdminProviderApiEndpointsUtils.getUnregisterProviderEndpointAcceptVersion())
      .pipe(
        map(() => {
          return true;
        })
      )
  }

  registerProvider(providerId: number): Observable<boolean> {
    return super.sendPostRequestWithRefresh(AdminProviderApiEndpointsUtils.getRegisterProviderEndpoint(providerId), {}, AdminProviderApiEndpointsUtils.getRegisterProviderEndpointAcceptVersion())
      .pipe(
        map(() => {
          return true;
        })
      )
  }


  createStripeAccount(providerId: number): Observable<string> {
    return super.sendPostRequestWithRefresh(AdminProviderApiEndpointsUtils.getCreateProviderStripeAccountEndpoint(providerId), {}, AdminProviderApiEndpointsUtils.getCreateProviderStripeAccountEndpointAcceptVersion())
      .pipe(
        map((response: any) => {
          return response.stripeAccountUrl;
        })
      )
  }


  removeStripeAccount(providerId: number): Observable<boolean> {
    return super.sendDeleteRequestWithRefresh(AdminProviderApiEndpointsUtils.getRemoveProviderStripeAccountEndpoint(providerId), AdminProviderApiEndpointsUtils.getRemoveProviderStripeAccountEndpointAcceptVersion())
      .pipe(
        map(() => {
          return true;
        })
      )
  }


  updateStripeAccount(providerId: number, stripeAccountId: string): Observable<boolean> {
    return super.sendPutRequestWithRefresh(AdminProviderApiEndpointsUtils.getUpdateProviderStripeAccountEndpoint(providerId), AdminProviderApiEndpointsUtils.getUpdateProviderStripeAccountObject(stripeAccountId), AdminProviderApiEndpointsUtils.getUpdateProviderStripeAccountEndpointAcceptVersion())
      .pipe(
        map(() => {
          return true;
        })
      )
  }





}
