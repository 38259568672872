import { Pipe, PipeTransform } from '@angular/core';
import { TourState } from 'src/app/business/models/tour/common/tour-state.constants';

@Pipe({
  name: 'tourStateClassPipe'
})
export class TourStateClassPipe implements PipeTransform {

  transform(classLabels: string, state: TourState): String {

    let result = classLabels
    if ( state == TourState.Active ) {
        result = result + " " + 'active-tour';
    } else if ( state == TourState.Inactive ) {
        result = result + " " + 'inactive-tour';
    }

    return result ;

  }

}
