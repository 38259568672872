import { Injectable }                       from '@angular/core';
import { Observable, Observer } from 'rxjs';
import { GenericException } from '../../exceptions/generic.exception';
import { CompanyName } from 'src/app/business/models/master/company/company-name.model';
import { AdminGuideService } from 'src/app/business/services/admin/guide.service';
import { TourResume } from 'src/app/business/models/tour/tour-resume.model';
import { SessionInteractor } from '../session.interactor';


@Injectable({
  providedIn: 'root'
})
export class AdminGuideInteractor {

    constructor(
        private adminGuideService: AdminGuideService,
        private sessionInteractor: SessionInteractor
    ) {
    }

    getGuidesNames(providerId: number): Observable<Array<CompanyName>> {

        return Observable.create((observer: Observer<Array<CompanyName>>) => {
            this.adminGuideService.getGuidesNames(providerId)
                  .subscribe(
                      (guides: Array<CompanyName>) => {

                          observer.next(guides);
                          observer.complete();
                      },
                      (error: GenericException) => {
                          observer.error(error);
                      }
                  )

        });
    }



    findAllToursForManagement(guideId: number): Observable<Array<TourResume>> {

        return Observable.create((observer: Observer<Array<TourResume>>) => {
              this.adminGuideService.findAllToursForManagement(guideId, this.sessionInteractor.getCurrentLanguage())
                    .subscribe(
                        (tours: Array<TourResume>) => {

                            observer.next(tours);
                            observer.complete();
                        },
                        (error: GenericException) => {
                            observer.error(error);
                        }
                    )

        });

    }

}