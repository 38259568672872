<div class="animated fadeIn">
    <div class="row header-dashboard align-items-lg-center align-items-start">
        <div class="col-12 col-sm-8 col-lg-6 col-xl-6 pl-0 order-1 order-lg-1">
            <h2 class="font-weight-bold">{{ 'guidecommissions.Desglose de servicios' | translate }}</h2>
            <p>{{ 'guidecommissions.Facturación detallada' | translate }}</p>
        </div>
        <div class="col-12 col-sm-4 col-lg-6 col-xl-6 text-left text-sm-right px-0 px-lg-auto order-2 order-lg-3">
            <button class="btn btn-primary px-sm-3 px-md-4" name="{{ 'guidecommissions.Añadir servicio' | translate }}" (click)="onCreateCommission()">
                <img class="mr-2" src="assets/img/ic_add-white.svg">
                <span>{{ 'guidecommissions.Servicio' | translate }}</span>
            </button>
        </div>
    </div>

    <div class="row dashboard-content-section mb-5">
        <div class="col-12">
            <div class="row">
                <div class="col-12 text-left pl-md-0 pr-md-3 px-0">
                    <h2 class="font-weight-bold mb-4">{{ 'guidecommissions.Filtros' | translate }}</h2>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <form class="row form-boxes d-block d-md-flex m-0 p-0">

                        <div *ngIf="providers" class="col-12 col-md-4 col-lg-3 px-0 px-md-auto">
                            <div class="field pb-2">
                                <select [(ngModel)]="providerId" (change)="onProviderSelected()" name="provider">
                                    <option [ngValue]="null">{{ 'guidecommissions.Selecciona la opción' | translate }}</option>
                                    <option *ngFor="let provider of providers" value="{{ provider.id }}">{{ provider.companyName }}</option>
                                </select>
                                <label for="provider">{{ 'guidecommissions.Provider' | translate }}</label>
                            </div>
                        </div>


                        <div *ngIf="guides" class="col-12 col-md-2 col-lg-3 px-0 px-md-auto">
                            <div class="field pb-2">
                                <select [(ngModel)]="guideId" (change)="onGuideSelected()" name="guide">
                                <option [ngValue]="null">{{ 'guidecommissions.Selecciona la opción' | translate }}</option>
                                <option *ngFor="let guide of guides" value="{{ guide.id }}">{{ guide.companyName }}</option>
                                </select>
                                <label for="guide">{{ 'guidecommissions.Guía' | translate }}</label>
                            </div>
                        </div>

                        <div class="col-12 col-md-2 col-lg-3 px-0 px-md-auto">
                            <div class="field pb-2">
                                <select [(ngModel)]="year" (change)="onYearSelected()" name="year">
                                <option *ngFor="let year of years" value="{{ year }}">{{ year }}</option>
                                </select>
                                <label for="year">{{ 'guidecommissions.Año' | translate }}</label>
                            </div>
                        </div>

                        <div class="col-12 col-md-2 col-lg-3 px-0 px-md-auto">
                            <div class="field pb-2">
                                <select [(ngModel)]="month" (change)="onMonthSelected()" name="month">
                                <option *ngFor="let month of months" value="{{ month }}">{{ month | i18NMonthFromNumber | translate }}</option>
                                </select>
                                <label for="month">{{ 'guidecommissions.Mes' | translate }}</label>
                            </div>
                        </div>

                        <div class="col-12 col-md-2 col-lg-3 px-0 px-md-auto">
                            <div class="field pb-2">
                                <select [(ngModel)]="state" (change)="onStateSelected()" name="state">
                                <option [ngValue]="null">{{ 'guidecommissions.Selecciona la opción' | translate }}</option>
                                <option *ngFor="let state of states" value="{{ state }}">{{ state | i18NGuideServiceStatePipe | translate }}</option>
                                </select>
                                <label for="month">{{ 'guidecommissions.State' | translate }}</label>
                            </div>
                        </div>
                        

                    </form>
                </div>
            </div>
        </div>
    </div>




    <div class="row empty-section" *ngIf="noResults">
        <div class="col-12 text-center my-auto">
            <img class="logo mb-4" src="assets/img/logo_opacity-21.png">
            <p>{{ 'guidecommissions.No se han encontrado resultados' | translate }}</p>
        </div>
    </div>


    <div class="row dashboard-content-section" *ngIf="!noResults">
        <div class="col-12">
            <div class="row">
              <div class="col-12 text-left pl-md-0 pr-md-3 px-0">
                <h2 class="font-weight-bold mb-4">{{ 'guidecommissions.Resumen' | translate }}</h2>
              </div>
              <table class="table table-hover table-dark mb-3">
                <thead class="thead-dark">
                  <tr>
                    <th width="60%" scope="col">{{ 'guidecommissions.Tour' | translate }}</th>
                    <th width="15%" scope="col" class="text-center">{{ 'guidecommissions.Asistentes' | translate }}</th>
                    <th width="15%" scope="col" class="price-width text-center">{{ 'guidecommissions.Total' | translate }}</th>
                  </tr>
                </thead>
                <tbody class="">
                  <tr *ngFor="let toursDetailsStats of guideServiceStats.toursDetailsStats">
                    <td scope="row" class="font-weight-bold">{{ toursDetailsStats.tourName }}</td>
                    <td class="text-center">{{ toursDetailsStats.people }}</td>
                    <td class="price-width text-center" style="white-space: nowrap;">{{ toursDetailsStats.commissions }} €</td>
                  </tr>
                </tbody>
              </table>
              <table class="table table-hover table-dark">
                <tbody class="total">
                  <tr>
                    <td width="85%" scope="row" colspan="2" class="font-weight-bold text-right pr-5 border-0">{{ 'guidecommissions.Total a pagar' | translate }}</td>
                    <td width="15%" class="price-width text-center border-0" style="white-space: nowrap;">{{ guideServiceStats.totalCommissionsToPay }} €</td>
                  </tr>
                  <tr>
                    <td width="85%" scope="row" colspan="2" class="font-weight-bold text-right pr-5 border-0">{{ 'guidecommissions.Total a cobrar' | translate }}</td>
                    <td width="15%" class="price-width text-center border-0" style="white-space: nowrap;">{{ guideServiceStats.totalCommissionsToCollect }} €</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
    </div>
    



    <div class="row dashboard-content-section" *ngIf="!noResults">
        <div class="col-12 px-0">
             <app-commissions-table 
                *ngIf="desktopVersion"
                [commissions]="commissions" 
                [isAdmin]="isAdmin" 
                [isProvider]="isProvider" 
                [isGuide]="isGuide" 
                [pagination]="pagination"
                (approveService)="onApproveService($event)"
                (editService)="onEditService($event)"
                (deleteService)="onDeleteService($event)"
                ></app-commissions-table>

            <app-commissions-cards 
                *ngIf="!desktopVersion"
                [commissions]="commissions" 
                [isAdmin]="isAdmin" 
                [isProvider]="isProvider" 
                [isGuide]="isGuide" 
                [pagination]="pagination"
                (approveService)="onApproveService($event)"
                (editService)="onEditService($event)"
                (deleteService)="onDeleteService($event)"
                ></app-commissions-cards>
        </div>
    </div>

    <!--  Pagination -->
    <div class="row bg-grey-mid-color" *ngIf="!noResults">
        <div class="col-12 d-flex justify-content-center">
            <pagination-controls [maxSize]="pagination.itemsPerPage" [directionLinks]="true" [autoHide]="true" [responsive]="true" [previousLabel]="paginationPreviousLabel" [nextLabel]="paginationNextLabel" (pageChange)="onPageChanged($event)"></pagination-controls>
        </div>
    </div>

</div>


<app-window-size-controller [initialSize]="initialWindowSize" (isDesktopVersion)="onDesktopVersionUpdated($event)"></app-window-size-controller>