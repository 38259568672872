import { GuideServiceTourStats } from './service-tour-stats.model';

export class GuideServiceStats {

     private _toursDetailsStats: Array<GuideServiceTourStats>;
     private _totalCommissionsToCollect: number;
     private _totalCommissionsToPay: number;

     constructor(
          toursDetailsStats: Array<GuideServiceTourStats>,
          totalCommissionsToCollect: number,
          totalCommissionsToPay: number
     ) {
          this._toursDetailsStats = toursDetailsStats;
          this._totalCommissionsToCollect = totalCommissionsToCollect;
          this._totalCommissionsToPay = totalCommissionsToPay;
     }

     get toursDetailsStats(): Array<GuideServiceTourStats> {
          return this._toursDetailsStats;
     }

     get totalCommissionsToCollect(): number {
          return this._totalCommissionsToCollect;
     }

     get totalCommissionsToPay(): number {
          return this._totalCommissionsToPay;
     }

}