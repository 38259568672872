<form class="form-boxes m-0 p-0" [formGroup]="affiliateWebsitesForm">

     <!-- Empresa -->
     <div class="row mb-2">
          <div class="col-10">
               <h2 class="font-weight-bold d-flex"><img class="ic-mid mr-2" src="assets/img/ic_user.svg">{{ 'providerdetails.Datos de afiliado' | translate }}</h2>
               <h5>{{ 'providerdetails.Configuración de cuenta de afiliado' | translate }}</h5>
               <hr class="w-10 float-left mt-2 mb-4">
          </div>
          <div class="col-2 text-right">
               <a class="cursor-pointer" (click)="toggleEdition()">
                    <img class="ic-mid mr-2" src="{{ isDisabled ? 'assets/img/ic_edit.svg' : 'assets/img/ic_edit-disabled.svg'}}">
               </a>
          </div>
     </div>

     <div class="row mb-5 pb-5">
          <div class="col-12">
               <div class="field pb-0">
                    <label>{{ 'affiliate.Website' | translate }}</label>
               </div>
          </div>
          <ng-container formArrayName="websites">
               <div class="col-12 col-md-6" *ngFor="let website of websites.controls; let websiteIndex = index" [formGroup]="website">
                    <div class="field pb-2">
                         <input (keyup)="onWebsiteChanged($event, websiteIndex)" formControlName="url" type="text" id="{{ 'url' + websiteIndex }}" name="{{ 'url' + websiteIndex }}" placeholder="{{ 'affiliate.Website' | translate }}" value="">
                    </div>
                    <app-feedback [formGroup]="website" field="url" validator="maxlength" feedback="{{ 'providerdetails.Texto demasiado largo' | translate }}" [refresh]="refreshValidators"></app-feedback>
                    <app-feedback [formGroup]="website" field="url" validator="pattern" feedback="{{ 'providerdetails.Indica una URL válida' | translate }}" [refresh]="refreshValidators"></app-feedback>
               </div>
          </ng-container>

          <div class="col-3 col-md-6 col-lg-3 mb-4">
               <a (click)="onAddWebsite()" class="btn w-md-50 w-100 btn-tertiary mb-3 mb-sm-0 white-color px-0 px-sm-auto"  [ngClass]="{'disabled': isDisabled}">
                    <img class="ic-mid" src="assets/img/ic_add-white.svg">
               </a>
          </div>
     </div>

     <div class="row mb-5 pb-5">
          <div class="col-12 col-md-6 offset-md-6 col-lg-4 offset-lg-8">
               <input (click)="saveDetails()" type="submit" value="{{ 'providerdetails.Guardar datos' | translate }}" class="btn btn-full-width btn-primary mb-3 mb-sm-0" [disabled]="isDisabled" />
          </div>
     </div>
</form>

<app-message-panel [preloading]="false"></app-message-panel>