import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NavigationConstants } from 'src/app/ui/commons/navigation.constants';

@Component({
     selector: 'app-not-found',
     templateUrl: './not-found.component.html'
})
export class NotFoundComponent implements OnInit {

     constructor(private router: Router) { }

     ngOnInit() {
     }

     goToDashboard() {
          this.router.navigate([ NavigationConstants.getNavigationUserZone() ]);
     }

     goToHomeLanding() {
          this.router.navigate( [ NavigationConstants.getNavigationHome() ] );
     }

     onHeaderItemSelected(anchor: string) {
          this.router.navigate( [ NavigationConstants.getNavigationHome() ], { fragment: anchor } );
     }

}
