import { Injectable } from '@angular/core';
import { Observable, Observer } from 'rxjs';
import { GenericException } from '../../exceptions/generic.exception';
import { ChunkGeneric } from '../../views/chunk-generic.view';
import { NextBilling } from 'src/app/business/models/billing/next-billing.model';
import { InvoiceDetails } from 'src/app/business/models/billing/invoice-details.model';
import { ProviderBillingService } from 'src/app/business/services/provider/billing.service';
import { SessionInteractor } from '../session.interactor';
import { BookingBilling } from 'src/app/business/models/billing/booking-billing.model';
import { InvoiceFullDetails } from 'src/app/business/models/billing/invoice-full-details.model';
import { GuideBillingStats } from 'src/app/business/models/billing/billing-stats.model';


@Injectable({
  providedIn: 'root'
})
export class ProviderBillingInteractor {

  constructor(
       private providerBillingService: ProviderBillingService, 
       private sessionInteractor: SessionInteractor) {
  }


  findInvoices(page: number): Observable<ChunkGeneric<InvoiceDetails>> {

    return Observable.create((observer: Observer<ChunkGeneric<InvoiceDetails>>) => {
      this.providerBillingService.findInvoices(page)
        .subscribe(
          (chunkInvoiceDetails: ChunkGeneric<InvoiceDetails>) => {

            observer.next(chunkInvoiceDetails);
            observer.complete();
          },
          (error: GenericException) => {
            observer.error(error);
          }
        )

    });

  }


  findNextBilling(): Observable<NextBilling> {

    return Observable.create((observer: Observer<NextBilling>) => {
      this.providerBillingService.findNextBilling(this.sessionInteractor.getCurrentLanguage())
        .subscribe(
          (nextBilling: NextBilling) => {

            observer.next(nextBilling);
            observer.complete();
          },
          (error: GenericException) => {
            observer.error(error);
          }
        )

    });

  }



  findBilling(dateInit: string, dateEnd: string, keywords: string, page: number): Observable<ChunkGeneric<BookingBilling>> {

    return Observable.create((observer: Observer<ChunkGeneric<BookingBilling>>) => {
      this.providerBillingService.findBilling(dateInit, dateEnd, keywords, page)
        .subscribe(
          (chunkBookigBilling: ChunkGeneric<BookingBilling>) => {

            observer.next(chunkBookigBilling);
            observer.complete();
          },
          (error: GenericException) => {
            observer.error(error);
          }
        )

    });

  }


  invoiceDownload(invoiceId: number): Observable<Blob> {

    return Observable.create((observer: Observer<Blob>) => {
      this.providerBillingService.invoiceDownload(invoiceId)
        .subscribe(
          (data: any) => {

            const blob = new Blob([(data)], { type: 'application/vnd.ms-excel;charset=utf-8' });

            observer.next(blob);
            observer.complete();
          },
          (error: GenericException) => {
            observer.error(error);
          }
        )

    });

  }



  prepareInvoicePayment(invoiceId: number): Observable<string> {

    return Observable.create((observer: Observer<string>) => {
      this.providerBillingService.prepareInvoicePayment(invoiceId)
        .subscribe(
          (stripeTransactionId: string) => {

            observer.next(stripeTransactionId);
            observer.complete();
          },
          (error: GenericException) => {
            observer.error(error);
          }
        )

    });

  }


  findInvoice(invoiceId: number): Observable<InvoiceFullDetails> {

    return Observable.create((observer: Observer<InvoiceFullDetails>) => {
      this.providerBillingService.findInvoice(invoiceId)
        .subscribe(
          (invoiceDetails: InvoiceFullDetails) => {

            observer.next(invoiceDetails);
            observer.complete();
          },
          (error: GenericException) => {
            observer.error(error);
          }
        )

    });

  }



  findGuideBilling(dateInit: string, dateEnd: string, guideId: number, keywords: string, page: number): Observable<ChunkGeneric<BookingBilling>> {

      return Observable.create((observer: Observer<ChunkGeneric<BookingBilling>>) => {
            this.providerBillingService.findGuideBilling(dateInit, dateEnd, guideId, keywords, page)
                  .subscribe(
                     (chunkBookigBilling: ChunkGeneric<BookingBilling>) => {

                         observer.next(chunkBookigBilling);
                         observer.complete();
                     },
                     (error: GenericException) => {
                         observer.error(error);
                     }
                  )

      });

  }



  findGuideBillingStats(dateInit: string, dateEnd: string, guideId: number, keywords: string): Observable<GuideBillingStats> {

    return Observable.create((observer: Observer<GuideBillingStats>) => {
          this.providerBillingService.findGuideBillingStats(dateInit, dateEnd, guideId, keywords, this.sessionInteractor.getCurrentLanguage())
                .subscribe(
                   (guideBillingStats: GuideBillingStats) => {

                       observer.next(guideBillingStats);
                       observer.complete();
                   },
                   (error: GenericException) => {
                       observer.error(error);
                   }
                )

    });

}


}
