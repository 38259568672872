<form class="form-boxes m-0 p-0" [formGroup]="createTourScheduleForm">

    <div class="row">
        <div class="col-12 col-lg-6 mb-4 season">
            <div class="btn-group btn-group-toggle w-100" data-toggle="buttons">
                <label (click)="onFixedTypeSelected()" class="btn btn-secondary ml-0" [ngClass]="{'active': isFixedType}">
                    {{ 'createtourdate.Todo el año' | translate }}
                </label>
                <label (click)="onSeasonTypeSelected()" class="btn btn-secondary" [ngClass]="{'active': !isFixedType}">
                    {{ 'createtourdate.Por temporada' | translate }}
                </label>
            </div>
        </div>
    </div>


    <app-create-tour-schedule-fixed *ngIf="isFixedType" [isI18nTraslation]="i18nTraslation" [fixedFormGroup]="fixedFormGroup" [fixedView]="inputFixedDetails" [refreshValidators]="refreshValidators"></app-create-tour-schedule-fixed>
    <app-create-tour-schedule-season *ngIf="!isFixedType" [isI18nTraslation]="i18nTraslation" [seasonsFormArray]="seasonsFormArray" [seasonsView]="inputSeasonsDetails" [refreshValidators]="refreshValidators"></app-create-tour-schedule-season>


    <div class="row mb-5 pb-5 control-footer">
        <div class="col-6 col-md-5 col-lg-4">
            <button (click)="onBackButton()" type="submit" value="{{ 'createtourdate.Volver' | translate }}" class="btn btn-full-width btn-no-btn mb-3 mb-sm-0 dark-color">
                <img class="ic-small mr-2" src="assets/img/ic_arrow-back.svg">
                {{ 'createtourdate.Volver' | translate }}
            </button>
        </div>

        <div class="col-6 col-md-5 offset-md-2 col-lg-4 offset-lg-4">
            <button (click)="saveDate()" type="submit" value="{{ 'createtourdate.Siguiente' | translate }}" class="btn btn-full-width btn-primary mb-3 mb-sm-0 white-color">
                {{ 'createtourdate.Guardar' | translate }}
            </button>
        </div>
    </div>

</form>