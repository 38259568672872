import { SchedulePlanning } from './schedule-planning.model';
import { ScheduleSeason } from './schedule-season.model';
import { TourScheduleType } from './common/tour-schedule-type.constants';

export class Schedule {


    private _type: TourScheduleType;
    private _fixed: SchedulePlanning;
    private _seasons: Array<ScheduleSeason>;

    constructor(
      type: TourScheduleType,
      fixed: SchedulePlanning,
      seasons: Array<ScheduleSeason>
    ) {

        this._type = type;
        this._fixed = fixed;
        this._seasons = seasons;

    }


    get type(): TourScheduleType {
        return this._type;
    }

    get fixed(): SchedulePlanning {
        return this._fixed;
    }

    get seasons(): Array<ScheduleSeason> {
        return this._seasons;
    }

}