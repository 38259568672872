import { Component, OnInit, ViewChild } from '@angular/core';
import { AuthenticateInteractor } from 'src/app/domain/interactor/authenticate.interactor';
import { Router, ActivatedRoute } from '@angular/router';
import { AppConstants } from 'src/app/domain/common/app.constants';
import { MessagePanelComponent } from '../../../shared/message-panel/message-panel.component';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { NavigationConstants } from 'src/app/ui/commons/navigation.constants';
import { User } from 'src/app/business/models/user/user.model';
import { NavigatorUtils } from 'src/app/ui/utils/navigator.utils';
import { marker as I18N } from '@biesbjerg/ngx-translate-extract-marker';
import { SessionInteractor } from 'src/app/domain/interactor/session.interactor';
import { ServerErrorManager } from '../../../utils/server-error-manager.utils';
import { ServerErrorExceptionCase } from 'src/app/domain/views/server-error-manager-exception-case.view';
import { GenericException } from 'src/app/domain/exceptions/generic.exception';
import { ProfileInteractor } from 'src/app/domain/interactor/profile/profile.interactor';
import { LoggedInUserView } from 'src/app/domain/views/logged-in-user.view';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html'
})
export class LoginComponent implements OnInit  {


  @ViewChild(MessagePanelComponent)
  private messagePanelComponent: MessagePanelComponent;

  loginForm: FormGroup;

  testMessage: string;

  refreshValidators: boolean;

  redirectUrl: string;

  constructor(private authenticateInteractor: AuthenticateInteractor,
        private sessionInteractor: SessionInteractor,
        private profileInteractor: ProfileInteractor,
        private router: Router,
        private serverErrorManager: ServerErrorManager,
        private activatedRoute: ActivatedRoute) {

      this.refreshValidators = false;

      this.loginForm = new FormGroup({
        'email':  new FormControl(
                  '',
                  [
                    Validators.required,
                    Validators.email,
                    Validators.maxLength(AppConstants.TEXT_DEFAULT_MAX_LENGTH)
                  ]),
        'password': new FormControl('', [Validators.required, Validators.maxLength(AppConstants.TEXT_DEFAULT_MAX_LENGTH)] )
      });


      this.activatedRoute.queryParams.subscribe( params => {
          this.redirectUrl = params[NavigationConstants.NAVIGATION_LOGIN_REDIRECT_PARAMETER];
      })

  }

  ngOnInit() {}


  onUserLogin() {

      this.refreshValidators = true;

      if ( this.loginForm.valid ) {

        this.messagePanelComponent.showLoading();

        this.authenticateInteractor.login(this.loginForm.value.email, this.loginForm.value.password)
            .subscribe(
                (user: User) => {
                     this.profileInteractor.getBasicProfileDetails()
                     .subscribe(
                          (loggedInUserView: LoggedInUserView) => {

                               if ( loggedInUserView ) {
                                    this.sessionInteractor.updateLoggedInUserDetails(loggedInUserView);
                               }
                               this.continueNavigationAfertLogin(user);
                          },
                          () => {
                               this.continueNavigationAfertLogin(user);
                          }
                     )

                },
                (error: GenericException) => {

                    MessagePanelComponent.hideLoading();

                    const exceptionCases = new Array<ServerErrorExceptionCase>();

                    const exceptionCodes = new Array<number>();
                    exceptionCodes.push(AppConstants.ERROR_CLIENT_BAD_REQUEST);
                    exceptionCodes.push(AppConstants.ERROR_CLIENT_UNAUTHORIZED);
                    exceptionCases.push( new ServerErrorExceptionCase(
                        exceptionCodes,
                        new Array<string>(),
                        () => {
                            this.messagePanelComponent.showErrorMessage(I18N('login.Email y/o contraseña no es correcto'));
                        }
                    ));

                    this.serverErrorManager.manageErrorCode(error, this.messagePanelComponent, exceptionCases);

                }
            )
      }

  }


  goToRememberPassword() {
      this.router.navigate([ NavigationConstants.getNavigationRememberPassword() ]);
  }

  goToHomeLanding() {
    this.router.navigate( [ NavigationConstants.getNavigationHome() ] );
  }

  goToContact() {
    this.router.navigate( [ NavigationConstants.getNavigationHome() ], { fragment: NavigationConstants.NAVIGATION_HOME_ANCHOR_CONTACT } );
  }


  continueNavigationAfertLogin(user: User) {
       MessagePanelComponent.hideLoading();
       if ( this.sessionInteractor.setCurrentLanguage(user.languageCode) ) {
          NavigatorUtils.reloadApplication();
       } else {
          if ( this.redirectUrl ) {
              this.router.navigateByUrl(this.redirectUrl);
          } else {
              this.router.navigate([ NavigationConstants.getNavigationUserZone() ]);
          }
       }
 }

}
