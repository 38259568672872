import { NgModule, LOCALE_ID } from '@angular/core';
import {TranslateLoader, TranslateModule, TranslateService} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {HttpClient, HttpClientModule} from '@angular/common/http';

import localeEs from '@angular/common/locales/es';
import localeEn from '@angular/common/locales/en';
import { registerLocaleData } from '@angular/common';
import { LocaleManager } from './domain/managers/locale-manager';
import { SessionInteractor } from './domain/interactor/session.interactor';


@NgModule({
  declarations: [
  ],
  imports: [
    HttpClientModule,
    TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        })
  ],
  exports: [
    HttpClientModule,
    TranslateModule
  ],
  bootstrap: [],
  providers: [
    { provide: LOCALE_ID, useFactory: () => LocaleManager.getCurrentLocale() }
  ],
})


export class TranslationModule {
    constructor(private translate: TranslateService, private sessionInteractor: SessionInteractor) {
        registerLocaleData(localeEs, 'es');
        registerLocaleData(localeEn, 'en');

        this.translate.setDefaultLang(this.sessionInteractor.getCurrentLanguage());
    }
}

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http);
}


//npm install @ngx-translate/core --save
//npm install @ngx-translate/http-loader --save
//npm install @biesbjerg/ngx-translate-extract --save-dev