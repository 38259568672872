import { CommissionType } from "src/app/business/models/billing/common/commission-type.constants";

export class ServiceEditionView {

     private _guideServiceId: number;
     private _guideId: number;
     private _tourId: number;
     private _tourName: string;     
     private _date: Date;
     private _hour: string;
     private _people: number;
     private _amount: number;

     constructor(
          guideServiceId: number,
          guideId: number,
          tourId: number,
          tourName: string,
          date: Date,
          hour: string,
          people: number,
          amount: number
     ) {

          this._guideServiceId = guideServiceId;
          this._guideId = guideId;
          this._tourId = tourId;
          this._tourName = tourName,
          this._date = date;
          this._hour = hour;
          this._people = people;
          this._amount = amount;

     }


     public get guideServiceId(): number {
          return this._guideServiceId;
     }
     public set guideServiceId(value: number) {
          this._guideServiceId = value;
     }

     public get guideId(): number {
          return this._guideId;
     }
     public set guideId(value: number) {
          this._guideId = value;
     }

     public get date(): Date {
          return this._date;
     }
     public set date(value: Date) {
          this._date = value;
     }

     public get hour(): string {
          return this._hour;
     }
     public set hour(hour: string) {
          this._hour = hour;
     }

     public get tourId(): number {
          return this._tourId;
     }
     public set tourId(value: number) {
          this._tourId = value;
     }

     public get tourName(): string {
          return this._tourName;
     }
     public set tourName(value: string) {
          this._tourName = value;
     }

     public get people(): number {
          return this._people;
     }
     public set people(value: number) {
          this._people = value;
     }

     public get amount(): number {
          return this._amount;
     }
     public set amount(value: number) {
          this._amount = value;
     }

}