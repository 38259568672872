<div class="animated fadeIn">
    <div class="row header-dashboard align-items-lg-center align-items-start">
        <div class="col-12 pl-0">
            <h2 class="font-weight-bold">{{ 'createcommission.Nuevo servicio' | translate }}</h2>
            <p>{{ 'createcommission.Añade un tour realizado' | translate }}</p>
        </div>
    </div>

    <form class="form-boxes m-0 p-0">
        <div class="row dashboard-content-section">
            <div class="col-12 px-0">
                <form action="" [formGroup]="createCommissionForm" class="form-boxes">
                    <div class="row">

                        <div *ngIf="providersList" class="col-12 col-md-4">
                            <div class="field pb-2">
                                <select formControlName="providerId" name="providerId" (change)="onProviderSelected()">
                                   <option [ngValue]="null">{{ 'createcommission.Selecciona la opción' | translate }}</option>
                                    <option *ngFor="let provider of providersList" value="{{provider.id}}">{{provider.companyName}}</option>
                                </select>
                                <label for="providerId">{{ 'createcommission.Proveedor' | translate }}</label>
                            </div>
                            <app-feedback [formGroup]="createCommissionForm" field="providerId" validator="required" feedback="{{ 'createcommission.Proveedor required' | translate }}" [refresh]="refreshValidators"></app-feedback>
                        </div>


                         <div *ngIf="guides" class="col-12 col-md-4">
                             <div class="field pb-2">
                                 <select formControlName="guideId" name="guideId" (change)="onGuideSelected()">
                                    <option [ngValue]="null">{{ 'createcommission.Selecciona la opción' | translate }}</option>
                                     <option *ngFor="let guide of guides" value="{{guide.id}}">{{guide.companyName}}</option>
                                 </select>
                                 <label for="guideId">{{ 'createcommission.Guide' | translate }}</label>
                             </div>
                             <app-feedback [formGroup]="createCommissionForm" field="guideId" validator="required" feedback="{{ 'createcommission.Guide required' | translate }}" [refresh]="refreshValidators"></app-feedback>
                         </div>




                         <div *ngIf="!externalTour" class="col-12 col-md-4">
                             <div class="field pb-2">
                                 <select formControlName="tourId" name="tourId" (change)="onTourSelected()">
                                    <option [ngValue]="null">{{ 'createcommission.Selecciona la opción' | translate }}</option>
                                    <option *ngFor="let tour of toursManaged" value="{{tour.id}}">{{ tour.name | I18NTranslationSave: tour.nameMain }}</option>
                                 </select>
                                 <label for="tourId">{{ 'createcommission.Tour' | translate }}</label>
                             </div>
                             <div class="field-extraaction">
                                <a (click)="onAddExternalTour()" class="primary-color">{{ 'createcommission.Añadir tour externo' | translate }}</a>
                             </div>
                             <app-feedback [formGroup]="createCommissionForm" field="tourId" validator="required" feedback="{{ 'createcommission.Tour required' | translate }}" [refresh]="refreshValidators"></app-feedback>
                         </div>



                         <div *ngIf="externalTour" class="col-12 col-md-4">
                            <div class="field pb-2">
                                <input formControlName="tourName" name="tourName" type="text" id="tourName" placeholder="{{ 'createcommission.Nombre del tour' | translate }}" value="">
                                <label for="tourName">{{ 'createcommission.Nombre del tour' | translate }}</label>
                            </div>
                            <div class="field-extraaction">
                                <a (click)="onAddManagedTour()" class="primary-color">{{ 'createcommission.Seleccionar tour habitual' | translate }}</a>
                                </div>
                            <app-feedback [formGroup]="createCommissionForm" field="tourName" validator="required" feedback="{{ 'createcommission.Nombre del tour requerido' | translate }}" [refresh]="refreshValidators"></app-feedback>
                        </div>



                         <div class="col-12 col-md-4">
                             <div class="field pb-2">
                                  <div class="form-inline daterange">
                                      <div class="form-group mb-1 mb-lg-auto w-100">
                                          <div class="input-group w-100">
                                              <input ngbDatepicker #datepicker="ngbDatepicker" class="form-control" placeholder="dd-mm-yyyy" name="date" formControlName="date" (change)="onDateChanged()" (dateSelect)="onDateSelection($event)">
                                              <div class="input-group-append">
                                                  <button class="btn btn-outline-secondary calendar-open" (click)="datepicker.toggle()" type="button">
                                                      <img class="ic-sort-by-order mb-1" src="assets/img/ic_calendar.svg">
                                                  </button>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                                 <label for="date">{{ 'createcommission.Fecha' | translate }}</label>
                             </div>
                             <app-feedback [formGroup]="createCommissionForm" field="date" validator="required" feedback="{{ 'createcommission.Fecha requerida' | translate }}" [refresh]="refreshValidators"></app-feedback>
                         </div>


                         <div *ngIf="eventsList && eventsList.length > 0" class="col-12 col-md-4">
                            <div class="field pb-2">
                                <select formControlName="hour" name="hour" (change)="onHourChanged()">
                                   <option [ngValue]="null">{{ 'createcommission.Selecciona la opción' | translate }}</option>
                                   <option *ngFor="let event of eventsList" value="{{event.hour}}">{{ event.hour }}</option>
                                </select>
                                <label for="hour">{{ 'createcommission.Hora' | translate }}</label>
                            </div>
                            <app-feedback [formGroup]="createCommissionForm" field="hour" validator="required" feedback="{{ 'createcommission.Hora required' | translate }}" [refresh]="refreshValidators"></app-feedback>
                        </div>


                        <div *ngIf="!eventsList || eventsList.length == 0" class="col-12 col-md-4">
                            <div class="field pb-2">
                                <input formControlName="hourAsString" name="hourAsString" type="time" id="hourAsString" (change)="onHourAsStringChanged()">
                                <label for="hourAsString">{{ 'createcommission.Hora' | translate }}</label>
                            </div>
                            <app-feedback [formGroup]="createCommissionForm" field="hourAsString" validator="required" feedback="{{ 'createcommission.Hora required' | translate }}" [refresh]="refreshValidators"></app-feedback>
                        </div>




                        <div class="col-12 col-md-4">
                            <div class="field pb-2">
                                <input formControlName="people" name="people" type="number" id="people" placeholder="{{ 'createcommission.Personas' | translate }}" value="">
                                <label for="people">{{ 'createcommission.Personas' | translate }}</label>
                            </div>
                            <app-feedback [formGroup]="createCommissionForm" field="people" validator="required" feedback="{{ 'createcommission.Personas requerido' | translate }}" [refresh]="refreshValidators"></app-feedback>
                            <app-feedback [formGroup]="createCommissionForm" field="people" validator="min" feedback="{{ 'createcommission.Número de personas debe ser mayor que 0' | translate }}" [refresh]="refreshValidators"></app-feedback>
                            <app-feedback [formGroup]="createCommissionForm" field="people" validator="pattern" feedback="{{ 'createcommission.Indica un número entero entre 0 y 100' | translate }}" [refresh]="refreshValidators"></app-feedback>
                        </div>



                        <div *ngIf="!ifFreeTourService" class="col-12 col-md-4">
                            <div class="field pb-2">
                                <input formControlName="amount" name="amount" type="number" id="amount" placeholder="{{ 'createcommission.Importe' | translate }}" value="">
                                <label for="amount" *ngIf="guides">{{ 'createcommission.Importe a pagar' | translate }}</label>
                                <label for="amount" *ngIf="!guides">{{ 'createcommission.Importe a cobrar' | translate }}</label>
                            </div>
                            <app-feedback [formGroup]="createCommissionForm" field="amount" validator="required" feedback="{{ 'createcommission.Comisión requerida' | translate }}" [refresh]="refreshValidators"></app-feedback>
                            <app-feedback [formGroup]="createCommissionForm" field="amount" validator="min" feedback="{{ 'createcommission.La comisión debe ser mayor o igual a 0' | translate }}" [refresh]="refreshValidators"></app-feedback>
                        </div>


                    </div>

                    <div class="row mt-3">
                        <div class="col-12 col-sm-6 col-md-4 offset-md-4 col-lg-3 offset-lg-6">
                             <a class="btn btn-full-width btn-secondary" (click)="goToGuideServices()">{{ 'createcommission.Cancelar' | translate }}</a>
                        </div>
                        <div class="col-12 col-sm-6 col-md-4 col-lg-3">
                             <input type="submit" (click)="createCommission()" value="{{ 'createcommission.Guardar servicio' | translate }}" class="btn btn-full-width btn-primary mb-3 mb-sm-0" />
                        </div>
                    </div>
                </form>

            </div>
        </div>
    </form>
</div>