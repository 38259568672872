import { Injectable }                       from '@angular/core';
import { Observable, Observer } from 'rxjs';
import { GenericException } from '../../exceptions/generic.exception';
import { BookService } from 'src/app/business/services/public/book.service';
import { BookingOrder } from 'src/app/business/models/book/booking-order.model';
import { SessionInteractor } from '../session.interactor';


@Injectable({
  providedIn: 'root'
})
export class BookingInteractor {


    constructor(private bookService: BookService, private sessionInteractor: SessionInteractor) {}

    cancelBooking(orderBookingId: number, verificationCode: string): Observable<boolean> {

        return Observable.create((observer: Observer<boolean>) => {
            this.bookService.cancelBooking(orderBookingId, verificationCode)
                  .subscribe(
                      () => {

                          observer.next(true);
                          observer.complete();
                      },
                      (error: GenericException) => {
                          observer.error(error);
                      }
                  )

        });
    }

    getBookingForPayment(orderBookingId: number, verificationCode: string): Observable<BookingOrder> {

        return Observable.create((observer: Observer<BookingOrder>) => {
            this.bookService.getBookingForPayment(orderBookingId, verificationCode, this.sessionInteractor.getCurrentLanguage())
                  .subscribe(
                      (orderBooking: BookingOrder) => {

                          observer.next(orderBooking);
                          observer.complete();
                      },
                      (error: GenericException) => {
                          observer.error(error);
                      }
                  )

        });
    }





    rateBooking(orderBookingId: number, verificationCode: string, rate: number, comments: string): Observable<boolean> {

        return Observable.create((observer: Observer<boolean>) => {
            this.bookService.rateBooking(orderBookingId, verificationCode, rate, comments)
                  .subscribe(
                      () => {

                          observer.next(true);
                          observer.complete();
                      },
                      (error: GenericException) => {
                          observer.error(error);
                      }
                  )

        });
    }


}
