<div class="animated fadeIn">
    <div class="row header-dashboard align-items-lg-center align-items-start">
        <div class="col-12 col-sm-8 col-lg-6 col-xl-6 pl-0 order-1 order-lg-1">
            <h2 class="font-weight-bold">{{ 'invoiceslist.Facturación' | translate }}</h2>
            <p>{{ 'invoiceslist.Resumen de proveedores' | translate }}</p>
        </div>
    </div>


    <div class="row dashboard-content-section mb-5">
        <div class="col-12">
            <div class="row">
                <div class="col-12 text-left pl-md-0 pr-md-3 px-0">
                    <h2 class="font-weight-bold mb-4">{{ 'invoiceslist.Filtros' | translate }}</h2>
                </div>
            </div>
            <div class="row">
                <form class="form-boxes d-block d-md-flex m-0 p-0">
                    <div class="col-12 col-md-6 col-lg-4 px-0 px-md-auto ml-md-3">
                        <div class="field pb-2">
                            <select [(ngModel)]="affiliateId" (change)="onAffiliateSelected()" name="affiliate">
                               <option [ngValue]="null">{{ 'invoiceslist.Selecciona la opción' | translate }}</option>
                               <option *ngFor="let affiliate of affiliates" value="{{ affiliate.id }}">{{ affiliate.companyName }}</option>
                            </select>
                            <label for="city">{{ 'invoiceslist.Afiliado' | translate }}</label>
                        </div>
                    </div>

                    <div class="col-12 col-md-6 col-lg-4 px-0 px-md-auto ml-md-3">
                        <div class="field pb-2">
                            <select [(ngModel)]="year" (change)="onYearSelected()" name="year">
                               <option [ngValue]="null">{{ 'invoiceslist.Selecciona la opción' | translate }}</option>
                               <option *ngFor="let year of years" value="{{ year }}">{{ year }}</option>
                            </select>
                            <label for="year">{{ 'invoiceslist.Año' | translate }}</label>
                        </div>
                    </div>

                    <div class="col-12 col-md-6 col-lg-4 px-0 px-md-auto ml-md-3">
                        <div class="field pb-2">
                            <select [(ngModel)]="month" (change)="onMonthSelected()" name="month">
                               <option [ngValue]="null">{{ 'invoiceslist.Selecciona la opción' | translate }}</option>
                               <option *ngFor="let month of months" value="{{ month }}">{{ month | i18NMonthFromNumber | translate }}</option>
                            </select>
                            <label for="month">{{ 'invoiceslist.Mes' | translate }}</label>
                        </div>
                    </div>

                </form>
            </div>
        </div>
    </div>

    <div class="row dashboard-content-section mb-5 mb-md-auto">
        <div class="col-12 px-0">
            <app-invoices-admin-affiliate-table *ngIf="desktopVersion"
                    [invoices]="invoices"
                    [pagination]="pagination"
                    (invoiceDownload)="onInvoiceDownload($event)"
                    (invoiceDetails)="onInvoiceDetails($event)"
                    (paymentToAffiliate)="onPaymentToAffiliate($event)"
                    (manualSettleUp)="onManualSettleUp($event)"
                    ></app-invoices-admin-affiliate-table>

            <app-invoices-admin-affiliate-cards *ngIf="!desktopVersion"
            [invoices]="invoices"
            [pagination]="pagination"
            (invoiceDownload)="onInvoiceDownload($event)"
            (invoiceDetails)="onInvoiceDetails($event)"
            (paymentToAffiliate)="onPaymentToAffiliate($event)"
            (manualSettleUp)="onManualSettleUp($event)"
            ></app-invoices-admin-affiliate-cards>
        </div>
    </div>


    <!-- Pagination -->

    <div class="row bg-grey-mid-color" *ngIf="!noResults">
        <div class="col-12 d-flex justify-content-center">
            <pagination-controls [maxSize]="pagination.itemsPerPage" [directionLinks]="true" [autoHide]="true" [responsive]="true" [previousLabel]="paginationPreviousLabel" [nextLabel]="paginationNextLabel" (pageChange)="onPageChanged($event)"></pagination-controls>
        </div>
    </div>


    <app-message-panel [preloading]="false"></app-message-panel>


</div>

<app-window-size-controller [initialSize]="initialWindowSize" (isDesktopVersion)="onDesktopVersionUpdated($event)"></app-window-size-controller>