import { Injectable }                   from '@angular/core';
import { Observable, Observer }         from 'rxjs';

import { GenericException }             from '../../exceptions/generic.exception';
import { ChunkGeneric }                 from '../../views/chunk-generic.view';
import { AdminBillingService } from 'src/app/business/services/admin/billing.service';
import { BookingBilling } from 'src/app/business/models/billing/booking-billing.model';
import { InvoiceFullDetails } from 'src/app/business/models/billing/invoice-full-details.model';
import { CalendarUtils } from 'src/app/ui/utils/calendar.utils';


@Injectable({
  providedIn: 'root'
})
export class AdminBillingInteractor {


    constructor( private adminBillingService: AdminBillingService ) {
    }


    findBilling(dateInit: string, dateEnd: string, providerId: number, keywords: string, page: number): Observable<ChunkGeneric<BookingBilling>> {

        return Observable.create((observer: Observer<ChunkGeneric<BookingBilling>>) => {
              this.adminBillingService.findBilling(dateInit, dateEnd, providerId, keywords, page)
                    .subscribe(
                        (chunkBookigBilling: ChunkGeneric<BookingBilling>) => {

                            observer.next(chunkBookigBilling);
                            observer.complete();
                        },
                        (error: GenericException) => {
                            observer.error(error);
                        }
                    )

        });

    }


    findAffiliateBilling(dateInit: string, dateEnd: string, affiliateId: number, keywords: string, page: number): Observable<ChunkGeneric<BookingBilling>> {

        return Observable.create((observer: Observer<ChunkGeneric<BookingBilling>>) => {
              this.adminBillingService.findAffiliateBilling(dateInit, dateEnd, affiliateId, keywords, page)
                    .subscribe(
                        (chunkBookigBilling: ChunkGeneric<BookingBilling>) => {

                            observer.next(chunkBookigBilling);
                            observer.complete();
                        },
                        (error: GenericException) => {
                            observer.error(error);
                        }
                    )

        });

    }



    findGuideBilling(dateInit: string, dateEnd: string, providerId: number, guideId: number, keywords: string, page: number): Observable<ChunkGeneric<BookingBilling>> {

        return Observable.create((observer: Observer<ChunkGeneric<BookingBilling>>) => {
              this.adminBillingService.findGuideBilling(dateInit, dateEnd, providerId, guideId, keywords, page)
                    .subscribe(
                        (chunkBookigBilling: ChunkGeneric<BookingBilling>) => {

                            observer.next(chunkBookigBilling);
                            observer.complete();
                        },
                        (error: GenericException) => {
                            observer.error(error);
                        }
                    )

        });

    }


    findInvoices(providerId: number, year: number, month: number, page: number): Observable<ChunkGeneric<InvoiceFullDetails>> {


         let minYear = null;
         let minMonth = null;
         let maxYear = null;
         let maxMonth = null;

         if ( year != null ) {
              minYear = year;
              maxYear = year;
              if ( month == null ) {
                   minMonth = CalendarUtils.getFirstMonthsAvailablesForBillingFromYear(year);
                   maxMonth = CalendarUtils.getLastMonthsAvailablesForBillingFromYear(year);
              } else {
                   minMonth = month;
                   maxMonth = month;
              }
         }


        return Observable.create((observer: Observer<ChunkGeneric<InvoiceFullDetails>>) => {
              this.adminBillingService.findInvoices(providerId, minYear, minMonth, maxYear, maxMonth, page)
                    .subscribe(
                        (chunkBookigInvoices: ChunkGeneric<InvoiceFullDetails>) => {

                            observer.next(chunkBookigInvoices);
                            observer.complete();
                        },
                        (error: GenericException) => {
                            observer.error(error);
                        }
                    )

        });

    }




    findAffiliateInvoices(affiliateId: number, year: number, month: number, page: number): Observable<ChunkGeneric<InvoiceFullDetails>> {


         let minYear = null;
         let minMonth = null;
         let maxYear = null;
         let maxMonth = null;

         if ( year != null ) {
              minYear = year;
              maxYear = year;
              if ( month == null ) {
                   minMonth = CalendarUtils.getFirstMonthsAvailablesForBillingFromYear(year);
                   maxMonth = CalendarUtils.getLastMonthsAvailablesForBillingFromYear(year);
              } else {
                   minMonth = month;
                   maxMonth = month;
              }
         }


        return Observable.create((observer: Observer<ChunkGeneric<InvoiceFullDetails>>) => {
              this.adminBillingService.findAffiliateInvoices(affiliateId, minYear, minMonth, maxYear, maxMonth, page)
                    .subscribe(
                        (chunkBookigInvoices: ChunkGeneric<InvoiceFullDetails>) => {

                            observer.next(chunkBookigInvoices);
                            observer.complete();
                        },
                        (error: GenericException) => {
                            observer.error(error);
                        }
                    )

        });

    }



    invoiceDownload(invoiceId: number): Observable<Blob> {

      return Observable.create((observer: Observer<Blob>) => {
        this.adminBillingService.invoiceDownload(invoiceId)
          .subscribe(
            (data: any) => {

              const blob = new Blob([(data)], { type: 'application/vnd.ms-excel;charset=utf-8' });

              observer.next(blob);
              observer.complete();
            },
            (error: GenericException) => {
              observer.error(error);
            }
          )

      });

    }



    invoiceAffiliateDownload(invoiceId: number): Observable<Blob> {

      return Observable.create((observer: Observer<Blob>) => {
        this.adminBillingService.invoiceAffiliateDownload(invoiceId)
          .subscribe(
            (data: any) => {

              const blob = new Blob([(data)], { type: 'application/vnd.ms-excel;charset=utf-8' });

              observer.next(blob);
              observer.complete();
            },
            (error: GenericException) => {
              observer.error(error);
            }
          )

      });

    }



    invoicePaymentRemember(invoiceId: number): Observable<boolean> {

      return Observable.create((observer: Observer<boolean>) => {
        this.adminBillingService.invoicePaymentRemember(invoiceId)
          .subscribe(
            () => {
              observer.next(true);
              observer.complete();
            },
            (error: GenericException) => {
              observer.error(error);
            }
          )

      });

    }



    invoicePaymentToProvider(invoiceId: number): Observable<boolean> {

      return Observable.create((observer: Observer<boolean>) => {
        this.adminBillingService.invoicePaymentToProvider(invoiceId)
          .subscribe(
            () => {
              observer.next(true);
              observer.complete();
            },
            (error: GenericException) => {
              observer.error(error);
            }
          )

      });

    }


    invoicePaymentToAffiliate(invoiceId: number): Observable<boolean> {

      return Observable.create((observer: Observer<boolean>) => {
        this.adminBillingService.invoicePaymentToAffiliate(invoiceId)
          .subscribe(
            () => {
              observer.next(true);
              observer.complete();
            },
            (error: GenericException) => {
              observer.error(error);
            }
          )

      });

    }


     invoiceSettleUp(invoiceId: number): Observable<boolean> {

      return Observable.create((observer: Observer<boolean>) => {
        this.adminBillingService.invoiceSettleUp(invoiceId)
          .subscribe(
            () => {
              observer.next(true);
              observer.complete();
            },
            (error: GenericException) => {
              observer.error(error);
            }
          )

      });

    }


    invoiceAffiliateSettleUp(invoiceId: number): Observable<boolean> {

     return Observable.create((observer: Observer<boolean>) => {
       this.adminBillingService.invoiceAffiliateSettleUp(invoiceId)
         .subscribe(
           () => {
            observer.next(true);
            observer.complete();
           },
           (error: GenericException) => {
            observer.error(error);
           }
         )

     });

   }


    findInvoice(invoiceId: number): Observable<InvoiceFullDetails> {

      return Observable.create((observer: Observer<InvoiceFullDetails>) => {
        this.adminBillingService.findInvoice(invoiceId)
          .subscribe(
            (invoiceDetails: InvoiceFullDetails) => {

              observer.next(invoiceDetails);
              observer.complete();
            },
            (error: GenericException) => {
              observer.error(error);
            }
          )

      });

    }



    findAffiliateInvoice(invoiceId: number): Observable<InvoiceFullDetails> {

      return Observable.create((observer: Observer<InvoiceFullDetails>) => {
        this.adminBillingService.findAffiliateInvoice(invoiceId)
          .subscribe(
            (invoiceDetails: InvoiceFullDetails) => {

              observer.next(invoiceDetails);
              observer.complete();
            },
            (error: GenericException) => {
              observer.error(error);
            }
          )

      });

    }

}
