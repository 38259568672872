import { UserRole } from './common/user-role.constants';

export class User {

     private _email: string;
     private _roles: Array<UserRole>;
     private _state: number;
     private _token: string;
     private _refreshToken: string;
     private _languageCode: string;


     constructor(email: string, roles: Array<UserRole>, state: number, token: string, refreshToken: string, languageCode: string) {
          this._email = email;
          this._roles = roles;
          this._state = state;
          this._token = token;
          this._refreshToken = refreshToken;
          this._languageCode = languageCode;
     }

     get email(): string {
          return this._email;
     }

     get roles(): Array<UserRole> {
          return this._roles;
     }

     get state(): number {
          return this._state;
     }

     get token(): string {
          return this._token;
     }

     get refreshToken(): string {
          return this._refreshToken;
     }

     get languageCode(): string {
          return this._languageCode;
     }

}
