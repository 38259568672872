import { Component, OnInit, ViewChild } from '@angular/core';
import { ServerErrorManager } from 'src/app/ui/components/utils/server-error-manager.utils';
import { GenericException } from 'src/app/domain/exceptions/generic.exception';
import { MessagePanelComponent } from 'src/app/ui/components/shared/message-panel/message-panel.component';
import { TourResume } from 'src/app/business/models/tour/tour-resume.model';
import { Pagination } from 'src/app/domain/views/pagination.view';
import { ChunkGeneric } from 'src/app/domain/views/chunk-generic.view';
import { GuideToursFilterView } from 'src/app/domain/views/guide/guide-tours-filter.view';
import { SessionInteractor } from 'src/app/domain/interactor/session.interactor';
import { NavigatorUtils } from 'src/app/ui/utils/navigator.utils';
import { GuideTourInteractor } from 'src/app/domain/interactor/guide/tour.interactor';
import { Book } from 'src/app/business/models/book/book.model';

@Component({
     selector: 'app-guide-bookings-tours-reseller',
     templateUrl: './tours-reseller.component.html'
})
export class GuideBookingsToursResellerComponent implements OnInit{

     @ViewChild(MessagePanelComponent)
     private messagePanelComponent: MessagePanelComponent;

     book: Book;
     tours: Array<TourResume>;
     toursPagination: Pagination;


     constructor(
          private guideTourInteractor: GuideTourInteractor,
          private sessionInteractor: SessionInteractor,
          private serverErrorManager: ServerErrorManager,
     ) {

              this.tours = new Array<TourResume>();
              this.toursPagination = new Pagination(0, 1, 20);
     }


     ngOnInit() {
          this.findTours(null, null, null, this.toursPagination.currentPage);
     }


     findTours(keywords: string, countryId: number, cityId: number, pageNumber: number) {
          this.guideTourInteractor.findToursForReselling(keywords, countryId, cityId, pageNumber)
             .subscribe(
                  (toursChunk: ChunkGeneric<TourResume>) => {
                       this.tours = toursChunk.items;
                       this.toursPagination = toursChunk.pagination
                  },
                  (error: GenericException) => {

                    MessagePanelComponent.hideLoading();
                    this.serverErrorManager.manageErrorCode(error, this.messagePanelComponent);

                  }
             )
    }


     onTourFilter(guideToursFilterView: GuideToursFilterView) {
          this.findTours(guideToursFilterView.keywords, guideToursFilterView.countryId, guideToursFilterView.cityId, guideToursFilterView.pageNumber);
     }


     onTourSelected(tourResume: TourResume) {
          let bookEmail: string = null;
          let bookName: string = null;
          let bookSurname: string = null;
          let bookPhone: string = null;
          if ( this.book ) {
               bookEmail = this.book.email;
               bookName = this.book.name;
               bookSurname = this.book.surname;
               bookPhone = this.book.phone;
          }
          NavigatorUtils.goToWidgetAsExternalGuideIdentityWithContactDetails(tourResume.id, this.sessionInteractor.getCurrentLanguage(), bookEmail, bookName, bookSurname, bookPhone, this.sessionInteractor.loggedInUserDetails().id);
     }


}
