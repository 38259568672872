import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { GuideResume } from 'src/app/business/models/guide/guide-resume.model';
import { Pagination } from 'src/app/domain/views/pagination.view';

@Component({
  selector: 'app-guides-cards',
  templateUrl: './cards.component.html'
})
export class GuidesCardsComponent implements OnInit {

  @Input() guides: Array<GuideResume>;

  @Input() pagination: Pagination;

  @Output() guideTrigger = new EventEmitter<number>();

  constructor() { }

  ngOnInit() {
  }


  onGuideClick(guideId: number) {
      this.guideTrigger.emit(guideId);
  }

}
