import { CalendarUtils } from 'src/app/ui/utils/calendar.utils';
import { ApiConstants } from '../api.constants';
import { ApiUtils } from '../api.utils';

export class GuideTourApiEndpointsUtils {

     public static getFindToursForResellingEndpoint(search: string, countryId: number, cityId: number, page: number): string {
          return ApiUtils.prepareQueryRequest('guide/tours/reseller', { 'search': search, 'countryId': countryId, 'cityId': cityId, 'page': page });
     }

     public static getFindToursForResellingEndpointAcceptVersion(): string {
          return ApiConstants.API_HEADER_ACCEPT_V_1_0_1;
     }




     public static getFindToursForManagementEndpoint(): string {
          return 'guide/tours/management';
     }

     public static getFindToursForManagementEndpointAcceptVersion(): string {
          return ApiConstants.API_HEADER_ACCEPT_V_1_0_1;
     }



     public static getGuideToursEventsEndpoint(tourId: number, date: string): string {
          const dayFormatted = CalendarUtils.fromDateDDMMYYYYToFormatString(date, ApiConstants.API_DATE_FORMAT_DD_MM_YYYY);
          return `guide/events/${tourId}/${dayFormatted}`;
     }

     public static getGuideToursEventsAcceptVersion(): string {
          return ApiConstants.API_HEADER_ACCEPT_V_1_0_1;
     }

}