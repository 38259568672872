import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html'
})
export class FeedbackComponent {

  @Input() formGroup: FormGroup

  @Input() field: string

  @Input() validator: string

  @Input() feedback: string

  @Input() refresh: boolean

}
