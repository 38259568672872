export class FindInvoicesFilterView {

     private _pageNumber: number;
     private _companyId: number;

     constructor(
          pageNumber: number,
          companyId: number
     ) {

          this._pageNumber = pageNumber;
          this._companyId = companyId;

     }

     get pageNumber(): number {
          return this._pageNumber;
     }

     get companyId(): number {
          return this._companyId;
     }

}