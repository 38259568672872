import { RouterModule, Routes } from '@angular/router';
import { NavigationConstants } from 'src/app/ui/commons/navigation.constants';
import { GuideAuthorizedGuard } from 'src/app/ui/guards/guideauthorized.guard';
import { GuideListBillingComponent } from './billing/commissions/list/list.component';
import { GuideDetailBookingComponent } from './booking/detail/detail.component';
import { GuideListBookingComponent } from './booking/list/list.component';
import { GuideComponent } from './guide.component';
import { GuideProfileComponent } from './profile/profile.component';
import { ListGuideServiceComponent } from './billing/services/list/list.component';
import { GuideCreateGuideServiceComponent } from './billing/services/create/create.component';


const guideRoutes: Routes = [
     {
          path: NavigationConstants.NAVIGATION_GUIDE,
          component: GuideComponent,
          children: [
               { path: NavigationConstants.NAVIGATION_GUIDE_PROFILE, component: GuideProfileComponent },
               { path: NavigationConstants.NAVIGATION_GUIDE_BILLING, component: GuideListBillingComponent },
               { path: NavigationConstants.NAVIGATION_GUIDE_LIST_BOOKING, component: GuideListBookingComponent },
               { path: NavigationConstants.NAVIGATION_PROVIDER_DETAIL_BOOKING, component: GuideDetailBookingComponent },
               { path: NavigationConstants.NAVIGATION_GUIDE_SERVICES, component: ListGuideServiceComponent },
               { path: NavigationConstants.NAVIGATION_GUIDE_SERVICE_CREATE, component: GuideCreateGuideServiceComponent },
               { path: NavigationConstants.NAVIGATION_GUIDE_SERVICE_UPDATE, component: GuideCreateGuideServiceComponent }
          ],
          canActivate: [ GuideAuthorizedGuard ]
     }
];


export const GUIDE_ROUTES = RouterModule.forChild( guideRoutes );
